import React, {
  useRef,
  useEffect,
  memo,
  useState,
  useCallback,
} from 'react';
import { areEqual } from 'react-window';
import SubmissionActivityEntry from '~/components/drawers/lead_drawer/timeline/SubmissionActivityEntry';

const Item = memo(({ index, style, data }) => {
  const itemRef = useRef(null);
  const [itemHeight, setItemHeight] = useState(data.itemSize);
  const { height, ...restStyle } = style;
  const isLoading = !data.isItemLoaded(index);
  const item = data.items[index];

  const updateHeight = useCallback((newHeight) => {
    if (Math.abs(newHeight - itemHeight) > 1) {
      setItemHeight(newHeight);
      data.setRowHeight(index, newHeight);
    }
  }, [itemHeight, data.setRowHeight, index]);

  useEffect(() => {
    if (!itemRef.current) return;

    let rafId;
    const resizeObserver = new ResizeObserver((entries) => {
      rafId = window.requestAnimationFrame(() => {
        for (const entry of entries) {
          updateHeight(entry.contentRect.height);
        }
      });
    });

    resizeObserver.observe(itemRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
      if (rafId) {
        window.cancelAnimationFrame(rafId);
      }
    };
  }, [updateHeight]);

  useEffect(() => {
    if (itemRef.current) {
      updateHeight(itemRef.current.offsetHeight);
    }
  }, [updateHeight, data.editFormState?.isFormOpen]);

  return (
    <div ref={itemRef} style={{ ...restStyle, height: 'auto' }}>
      {data.renderItem(item, isLoading, index, data.editFormState)}
      {(data.items.length === index + 1) && (data.activeTab === 'all' || data.activeTab === 'changes') && (
        <SubmissionActivityEntry lead={data.lead} />
      )}
    </div>
  );
}, areEqual);

export default Item;
