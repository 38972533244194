import React from 'react';
import NoteForm from './note_form';

const LogTextMessageForm = ({ ...props }) => (
  <NoteForm
    {...props}
    activityType="text_message"
    submitButtonText="Log Text Message"
  />
);

export default LogTextMessageForm;
