import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Form, Input }     from 'reactstrap';
import PhoneInput          from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest        from '~/lib/api_request';
import LeadHelpers       from '~/helpers/lead_helpers';
import ErrorAlert             from '~/components/shared/error_alert';

class TextMessageFormOnboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:  {},
      loading: true,
    };

    this.unmounted = false;

    autoBind(this);
  }

  componentDidMount() {
    if (_lodash.isNull(Rails.subscription)) {
      this.setState({ view: 'missing_subscription', loading: false });
    } else {
      this.loadSubscription();
      this.loadPhoneNumber();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onPhoneNumberSearch() {
    clearTimeout(this.timeoutId); // doesn't matter if it's 0
    this.timeoutId = setTimeout(() => {
      this.setState({
        availablePhoneNumbers: null,
        searching:             true,
      });

      const { phone_number_area_code } = this.state;

      APIRequest.get({
        resource: '/v1/sms_numbers/search',
        data:     { code: phone_number_area_code },
      }).end((err, res) => {
        this.setState({
          availablePhoneNumbers: res.body,
          searching:             false,
        });
      });
    }, 500);
  }

  onPhoneNumberSubmit() {
    const { serviceNumber, forwardNumber } = this.state;
    const { onPhoneSelect } = this.props;

    this.setState({
      submitting: true,
      errors:     [],
    });

    APIRequest.post({
      resource: '/v1/sms_numbers',
      data:     {
        sms_number: {
          service_number: serviceNumber,
          forward_number: forwardNumber,
        },
      },
    }).end((err, res) => {
      if (res.ok) {
        this.setState({ submitting: false });
        GlobalContainer.notify('BK Service Number registered');
        onPhoneSelect(res.body);
      } else {
        this.setState({
          submitting: false,
          errors:     res.body.errors,
        });
      }
    });
  }

  loadSubscription() {
    const { subscription } = Rails;
    const { onSubscriptionSelect } = this.props;
    if (subscription.status === 'active' && subscription.can_sms) {
      onSubscriptionSelect(subscription);

      this.setState({
        subscription,
        view:         'onboard_phone_number',
        loading:      false,
      });
    } else {
      this.setState({
        view:    'onboard_subscription',
        loading: false,
      });
    }
  }

  loadPhoneNumber() {
    const { onPhoneSelect } = this.props;

    APIRequest.get({ resource: '/v1/sms_numbers/mine' }).end(
      (err, res) => {
        if (res && res.ok) {
          onPhoneSelect(res.body);
        } else {
          if (this.unmounted) return;
          this.setState({ loading: false });
        }
      },
    );
  }

  renderPhonePicker() {
    const { availablePhoneNumbers, searching } = this.state;
    let pickerView;

    if (availablePhoneNumbers) {
      let nums;

      if (_lodash.size(availablePhoneNumbers) === 0) {
        nums = <span>No phone numbers found</span>;
      } else {
        nums = (
          <div>
            <div className="row">
              {_lodash.map(availablePhoneNumbers, (num) => (
                <div className="col-md-4" key={num}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mb5"
                    onClick={() => {
                      this.setState({
                        serviceNumber: num,
                      });
                    }}
                  >
                    {LeadHelpers.renderPhoneNumber(num)}
                  </button>
                </div>
              ))}
            </div>
            <div className="text-right mt15">
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={this.onPhoneNumberSearch}
              >
                Next 30
              </button>
            </div>
          </div>
        );
      }
      pickerView = <div>{nums}</div>;
    } else if (searching) {
      pickerView = (
        <span className="text-grey">
          <FontAwesomeIcon
            icon="far fa-spinner"
            pulse
            className="mr5"
          />
          {' '}
          Loading...
        </span>
      );
    }

    return (
      <div>
        <div className="mb15">
          <h4 className="mt5 mb10">Pick your phone number</h4>
          <p className="mb10">
            First, select the area code of the phone number that will
            be assigned to you.
          </p>
          <div className="row ml-0 mr-0">
            <div className="col-sm-2 pl0 pr0">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Input
                  placeholder="Area code ..."
                  type="number"
                  className="mr5 form-control-sm"
                  onChange={(e) => {
                    this.state.phone_number_area_code = e.target.value;
                    if (e.target.value.length !== 3) { e.target.value = e.target.value.slice(0, 3); }
                    this.onPhoneNumberSearch();
                  }}
                />
              </Form>
            </div>
          </div>
        </div>

        {pickerView}
      </div>
    );
  }

  renderPhonePairForm() {
    const {
      errors, forwardNumber, serviceNumber, submitting,
    } = this.state;

    return (
      <div>
        <h4 className="mt5">Now add your mobile number</h4>
        <div className="row mt10 mb10 ml-0 mr-0">
          <div className="col-md-6 pl0 pr0-sm-down mb10-sm-down">
            <label htmlFor="text-phone">Texts will be forwarded to</label>
            <PhoneInput
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              disableDropdown
              placeholder="Enter your mobile number"
              defaultMask="(...) ...-...."
              value={LeadHelpers.nationalFormatPhoneNumber(
                forwardNumber,
              )}
              onChange={(v) => {
                this.state.forwardNumber = v;
              }}
              specialLabel={false}
              inputProps={{
                className: classNames('phone form-control', {
                  'has-error': !!errors.forward_number,
                }),
              }}
            />
          </div>
          <div className="col-md-6 pr0 pl0-sm-down">
            <label htmlFor="text-from">Texts will come from</label>
            <div className="form-group has-feedback text-success mb5">
              <FontAwesomeIcon
                icon={['far', 'fa-check']}
                className="form-control-feedback form-control-feedback-right"
              />
              <div className="form-control text-success">
                {LeadHelpers.renderPhoneNumber(serviceNumber)}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            {!_lodash.isEmpty(errors) && <ErrorAlert
              heading="Oops, An error has occurred"
              message={errors}
            />}
          </div>
          <div className="col-4 pl-0 pr-3">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-secondary mr5"
                onClick={() => {
                  this.setState({ serviceNumber: undefined, errors: {}});
                }}
              >
                Cancel
              </button>
              {submitting ? (
                <button
                  type="button"
                  className="btn btn-success"
                  disabled
                >
                  <FontAwesomeIcon
                    icon="far fa-spinner"
                    pulse
                    className="mr5"
                  />
                  Start Texting
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={this.onPhoneNumberSubmit}
                >
                  Start Texting
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOnboardPhoneNumber() {
    const { serviceNumber } = this.state;

    if (serviceNumber) {
      return this.renderPhonePairForm();
    }
    return this.renderPhonePicker();
  }

  renderOnboardSubscription() {
    return (
      <div className="alert alert-success text-left">
        <h4 className="mt5">Set up your Text Message subscription</h4>
        <p className="mt10 mb10">
          <strong>How does it work?</strong>
          {' '}
          Just send a text here,
          replies will be forwarded to your mobile phone, and
          everything will be tracked in Brokerkit.
        </p>
        <a href="/billing" className="btn btn-success text-white">
          Change Subscription Plan
        </a>
      </div>
    );
  }

  renderMissingSubscription() {
    return (
      <div className="alert alert-success text-left">
        <h4 className="mt5">
          Add billing info to set up Text Message
        </h4>
        <p className="mt10 mb10">
          Before you can grab a phone number and start texting with
          Brokerkit, billing info needs to be added to your account.
        </p>
        <a href="/billing" className="btn btn-success text-white">
          Add Billing Info
        </a>
      </div>
    );
  }

  render() {
    const { loading, view } = this.state;

    if (loading) {
      return (
        <span className="text-grey">
          <FontAwesomeIcon
            icon="far fa-spinner"
            pulse
            className="mr5"
          />
          {' '}
          Loading
        </span>
      );
    }

    switch (view) {
      case 'missing_subscription':
        return this.renderMissingSubscription();
      case 'onboard_phone_number':
        return this.renderOnboardPhoneNumber();
      case 'onboard_subscription':
        return this.renderOnboardSubscription();
      default:
        return null;
    }
  }
}

TextMessageFormOnboarding.defaultProps = {
  onSubscriptionSelect: () => false,
  onPhoneSelect:        () => false,
};

TextMessageFormOnboarding.propTypes = {
  onSubscriptionSelect: PropTypes.func,
  onPhoneSelect:        PropTypes.func,
};

export default TextMessageFormOnboarding;
