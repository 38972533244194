import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { marked } from 'marked';
import { markedHighlight } from 'marked-highlight';
import hljs from 'highlight.js';
import { ModalStates } from '../states/ModalStates';
import TokensUsage from './TokensUsage';

const renderer = {
  paragraph(text) {
    return `<div>${text}</div><div>&nbsp;</div>`;
  },
};

marked.use(
  {
    headerIds: false,
    mangle:    false,
    gfm:       true,
    renderer,
  },
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    },
  }),
);

const GeneratingState = ({ state, markdownContentRef }) => {
  useEffect(() => {
    if (markdownContentRef.current) {
      markdownContentRef.current.innerHTML = marked(state.outputText || '');
    }
  }, [state.outputText]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          {state.status === ModalStates.GENERATING && (
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
          )}

          {state.status === ModalStates.FINISHED && (
            <FontAwesomeIcon
              icon="far fa-check-circle"
              className="mr5 text-green"
            />
          )}

          {state.inputText}
        </div>

        <div className="card-body">
          {state.outputText && (
            <div ref={markdownContentRef} className="markdown" />
          )}
        </div>
      </div>

      <div className="mt-2">
        <TokensUsage state={state} />
      </div>
    </>
  );
};

export default GeneratingState;
