import React                from 'react';
import PropTypes            from 'prop-types';

import Select               from '~/components/forms/select';
import APIRequest           from '~/lib/api_request';

class CampaignPicker extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const val = props.multi ? props.value && _lodash.map(props.value, (v) => parseInt(v, 10)) : props.value;

    this.state = {
      loading:            true,
      options:            [],
      value:              val,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const { blank } = this.props;

    APIRequest.get({
      resource: '/v1/campaigns/options',
    }).end((error, response) => {
      if (this._isMounted) {
        this.setState({ loading: false }, () => {
          if (!error) {
            let options = response.body.map((campaign) => ({
              label:          `${campaign.name} (${campaign.campaign_subscriptions_count})`,
              value:          campaign.id,
              campaign_steps: campaign.campaign_steps,
            }));

            if (blank) options = [{ value: '-blank-', label: '-blank-' }, ...options];

            this.setState({ options });
          }
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : null;
    const {
      onChange, resetWarning,
    } = this.props;

    this.setState({ value }, () => {
      onChange(selectedOption);
    });

    resetWarning(selectedOption);
  }

  render() {
    const {
      placeholder, multi, clearable, wrapperStyle, style, className,
    } = this.props;

    const {
      value, loading, options,
    } = this.state;

    return (
      <Select
        wrapperStyle={wrapperStyle}
        clearable={clearable}
        placeholder={placeholder}
        value={value}
        multi={multi}
        onChange={this.handleSelectChange}
        options={options}
        isLoading={loading}
        style={style}
        className={className}
      />
    );
  }
}

CampaignPicker.defaultProps = {
  wrapperStyle:       {},
  clearable:          false,
  className:          '',
  blank:              false,
  multi:              false,
  onChange:           () => {},
  placeholder:        'Select a Campaign...',
  style:              {},
  value:              null,
  resetWarning:       () => {},
};

CampaignPicker.propTypes = {
  wrapperStyle: PropTypes.shape({}),
  clearable:    PropTypes.bool,
  className:    PropTypes.string,
  blank:        PropTypes.bool,
  multi:        PropTypes.bool,
  onChange:     PropTypes.func,
  placeholder:  PropTypes.string,
  style:        PropTypes.shape({}),
  value:        PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  resetWarning: PropTypes.func,
};

export default CampaignPicker;
