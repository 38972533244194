export const styles = (color, isInvalid) => ({
  control: (base, { isDisabled: selectIsDisabled }) => {
    let backgroundColor;

    if (isInvalid) {
      backgroundColor = '#ffebeb';
    } else if (selectIsDisabled) {
      backgroundColor = '#e9ecef';
    } else {
      backgroundColor = 'white';
    }

    return {
      ...base,
      minHeight: 36,
      border:    isInvalid ? '1px solid #fd3737' : '1px solid #e5e8ef',
      backgroundColor,
      boxShadow: '0 1px 2px 0 rgba(106, 116, 135, 0.1)',
      ':hover':        {
        ...styles[':hover'],
        borderColor: color,
      },
    };
  },
  option: (base, { isDisabled: selectIsDisabled, isSelected: selectIsSelected }) => ({
    ...base,
    color:           selectIsSelected ? color : 'black',
    backgroundColor: 'transparent',
    cursor:          selectIsDisabled ? 'not-allowed' : 'default',
    ':hover':        {
      ...styles[':hover'],
      color,
    },
    ':active':        {
      ...styles[':active'],
      backgroundColor: 'transparent',
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingLeft:  0,
    paddingRight: 0,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingLeft:   0,
    paddingRight:  5,
    paddingTop:    5,
    paddingBottom: 5,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '2px 5px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#93a1ba',
  }),
  menu: (base) => ({
    ...base,
    zIndex:   99,
    minWidth: '100%',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: color,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: 'white',
  }),
});
