import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import APIRequest from '~/lib/api_request';
import { FeedbackStates } from '../states/FeedbackStates';

const Feedback = ({
  state,
  dispatch,
}) => {
  const feedbackHandler = (feedback) => async () => {
    if (state.feedbackState === feedback) {
      dispatch({ type: 'SET_FEEDBACK_STATE', payload: null });
    } else {
      dispatch({ type: 'SET_FEEDBACK_STATE', payload: feedback });
    }
  };

  const handleThumbsUp = feedbackHandler(FeedbackStates.UP);
  const handleThumbsDown = feedbackHandler(FeedbackStates.DOWN);

  const handleSendFeedback = async () => {
    const {
      conversationId, feedbackSending, feedbackState, feedbackText,
    } = state;

    if (feedbackSending) return null;

    dispatch({ type: 'SET_FEEDBACK_SENDING', payload: true });

    try {
      await APIRequest.post({
        resource: '/v1/ai_ratings',
        data:     {
          rating: {
            ai_conversation_id: conversationId,
            rating:             feedbackState,
            feedback:           feedbackText,
          },
        },
      });

      dispatch({ type: 'SET_FEEDBACK_TEXT', payload: '' });
      dispatch({ type: 'SET_FEEDBACK_STATE', payload: null });

      GlobalContainer.notify(
        "Thank you for your feedback. It's been sent successfully.",
        'success',
      );

      return null;
    } catch (error) {
      GlobalContainer.notify(
        'Oops! There was an issue sending your feedback. Please try again.',
        'error',
      );

      return null;
    } finally {
      dispatch({ type: 'SET_FEEDBACK_SENDING', payload: false });
    }
  };

  return (
    <>
      <div className="pull-right">
        <button
          className={`btn btn-outline-success mr-1 mb-2 ${
            state.feedbackState === FeedbackStates.UP ? 'active' : ''
          }`}
          type="button"
          onClick={handleThumbsUp}
          disabled={state.feedbackSending}
        >
          <FontAwesomeIcon icon="far fa-thumbs-up" />
        </button>
        <button
          type="button"
          className={`btn btn-outline-danger mr-1 mb-2 ${
            state.feedbackState === FeedbackStates.DOWN ? 'active' : ''
          }`}
          onClick={handleThumbsDown}
          disabled={state.feedbackSending}
        >
          <FontAwesomeIcon icon="far fa-thumbs-down" />
        </button>
      </div>

      {state.feedbackState && (
        <div className="my-3">
          <textarea
            className="form-control"
            rows={2}
            placeholder="Please provide your feedback"
            onChange={(e) => dispatch({
              type:    'SET_FEEDBACK_TEXT',
              payload: e.target.value,
            })}
          />
          <button
            type="button"
            className="btn btn-success mt-2"
            onClick={handleSendFeedback}
            disabled={state.feedbackSending}
          >
            Send Feedback
          </button>
        </div>
      )}
    </>
  );
};

Feedback.propTypes = {
  state:    PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Feedback;
