import { useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';

const DEFAULT_TIME_DURATION = 1000;
const DEFAULT_SCROLL_OFFSET = 15;
const DEBOUNCE_DELAY = 300;

const useSmoothScrollToElement = (
  location,
  setTargetActivityId,
  targetActivityId,
  items,
  loadMoreItems,
  timeoutDuration = DEFAULT_TIME_DURATION,
  scrollOffset = DEFAULT_SCROLL_OFFSET,
  scrollBehavior = 'smooth',
  scrollPosition = 'start',
) => {
  const isScrolling = useRef(false);
  const scrollLock = useRef(false);
  const listRef = useRef(null);

  const raf = window.requestAnimationFrame || ((callback) => setTimeout(callback, 16));

  const scrollToElement = useCallback(
    debounce((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        scrollLock.current = true;
        isScrolling.current = true;

        raf(() => {
          element.scrollIntoView({ behavior: scrollBehavior, block: scrollPosition });
          setTimeout(() => {
            isScrolling.current = false;
            scrollLock.current = false;
            setTargetActivityId(null);
          }, timeoutDuration);
        });
      }
    }, DEBOUNCE_DELAY),
    [scrollBehavior, scrollPosition, timeoutDuration, setTargetActivityId]
  );

  const handleLoadMore = useCallback(
    debounce(() => {
      if (scrollLock.current || isScrolling.current || !targetActivityId) return;

      const nextIndex = Math.min(items.length - 1 + scrollOffset, items.length - 1);

      if (listRef.current) {
        const { scrollToItem, resetAfterIndex } = listRef.current;

        scrollToItem?.(nextIndex, 'end');
        loadMoreItems(items.length, items.length + scrollOffset);
        resetAfterIndex?.(items.length);
      }
    }, DEBOUNCE_DELAY),
    [items, targetActivityId, loadMoreItems, scrollOffset],
  );

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (!hash || !targetActivityId) return;

    if (!isScrolling.current && targetActivityId && !scrollLock.current) {
      const element = document.getElementById(targetActivityId);
      if (element) {
        scrollToElement(targetActivityId);
      } else {
        handleLoadMore();
      }
    }
  }, [targetActivityId, items, loadMoreItems, location.hash, scrollToElement, handleLoadMore]);

  return { listRef };
};

export default useSmoothScrollToElement;
