import autoBind from 'react-autobind';
import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadDrawerActions from '~/actions/lead_drawer_actions';
import Select from '~/components/forms/dropdowns/SelectWrapper/Select';

// Constants
import { generateDateOptions } from '~/constants/dropdowns/MonthOptions';
import { RATING_OPTIONS } from '~/constants/dropdowns/ratingOptions';
import { YES_NO_OPTIONS } from '~/constants/dropdowns/yesNoOptions';

class LeadDrawerPipelineDetails extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const isOpen = localStorage.isPipelineOpen ? JSON.parse(localStorage.isPipelineOpen) : false;
    const { opened } = props;

    this.state = {
      opened,
      fields:        _lodash.pick(props.lead, 'rating', 'joined_at', 'active_listings_count', 'kpa_taken'),
      kpaTakenLabel: Rails.helpers.currentConfiguration.kpaTakenLabel,
      isOpen,
    };
  }

  onEditClick(e) {
    e.preventDefault();

    this.setState({
      opened: true,
    });
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    localStorage.isPipelineOpen = !isOpen;
  }

  onEditCloseClick(e) {
    e.preventDefault();

    this.setState({
      opened: false,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { fields } = this.state;
    const { lead, query } = this.props;

    LeadDrawerActions.updateLead(update(fields, { id: { $set: lead.id } }), query);

    this.setState({
      opened: false,
    });
  }

  setFormField(name, val) {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: val,
      },
    }));
  }

  renderContent() {
    const { lead } = this.props;
    const { kpaTakenLabel, isOpen } = this.state;

    return (
      <div>
        <div className="pull-right">
          <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={this.onCollapseClick}>
            { isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
          { Rails.abilities.manageLead && (
            <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditClick}>
              <FontAwesomeIcon icon={['far', 'fa-edit']} />
              {' '}
              Edit
            </button>
          )}
        </div>

        <h4 className="mb15">Pipeline</h4>

        <Collapse in={isOpen}>
          <div>
            <table className="table table-flush table-sm no-border">
              <tbody>
                <tr>
                  <th width="40%" className="text-grey-dark">Rating</th>
                  <td width="60%" className="text-black">{lead.rating}</td>
                </tr>
                <tr>
                  <th className="text-grey-dark">Month Joining</th>
                  <td className="text-black">{lead.joined_at && Moment(lead.joined_at).format('MMMM YYYY')}</td>
                </tr>
                <tr>
                  <th className="text-grey-dark">Active Listings</th>
                  <td className="text-black">{lead.active_listings_count}</td>
                </tr>
                <tr>
                  <th className="text-grey-dark">{kpaTakenLabel}</th>
                  <td className="text-black">{!_lodash.isNull(lead.kpa_taken) && (lead.kpa_taken ? 'Yes' : 'No')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
      </div>
    );
  }

  renderForm() {
    const { fields, kpaTakenLabel } = this.state;

    return (
      <div>
        <div className="text-right">
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditCloseClick}>
            <FontAwesomeIcon icon={['fas', 'fa-times']} />
            {' '}
            Close
          </button>
        </div>

        <form id="edit-lead-form" onSubmit={this.onFormSubmit}>
          <div className="field-group mb15">
            <label htmlFor="rating" className="d-block">Rating</label>
            <Select
              id="rating"
              name="rating"
              options={RATING_OPTIONS}
              value={fields.rating}
              onChange={(val) => this.setFormField('rating', val?.value || '')}
              isClearable
              isSearchable
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="month_joining" className="d-block">Month Joining</label>
            <Select
              id="month_joining"
              name="joined_at"
              options={generateDateOptions()}
              value={Moment(fields.joined_at).format('YYYY-MM-DD')}
              onChange={(val) => this.setFormField('joined_at', val?.value || '')}
              isClearable
              isSearchable
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="lead_active_listings_count" className="d-block">Active Listings</label>

            <input
              id="lead_active_listings_count"
              name="active_listings_count"
              type="number"
              size="4"
              className="form-control string optional"
              value={fields.active_listings_count}
              onChange={(val) => this.setFormField('active_listings_count', val.target.value)}
            />
          </div>

          <div className="field-group mb15">
            <label id="kpa-taken" className="d-block">{kpaTakenLabel}</label>
            <Select
              id="kpa-taken"
              name="kpa_taken"
              value={fields.kpa_taken}
              options={YES_NO_OPTIONS}
              onChange={(val) => this.setFormField('kpa_taken', val?.value || '')}
            />
          </div>

          <div className="form-group mb15">
            <button type="submit" className="btn btn-success">Save</button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { opened } = this.state;

    if (opened) {
      return this.renderForm();
    }
    return this.renderContent();
  }
}

LeadDrawerPipelineDetails.defaultProps = {
  lead:   null,
  query:  null,
  opened: false,
};

LeadDrawerPipelineDetails.propTypes = {
  lead:   PropTypes.shape({}),
  query:  PropTypes.shape({}),
  opened: PropTypes.bool,
};

export default LeadDrawerPipelineDetails;
