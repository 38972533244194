import TeamMemberPicker from '~/components/forms/team_fields/team_member_picker';

class TeamAffiliatePicker extends TeamMemberPicker {}

TeamAffiliatePicker.defaultProps = {
  apiResource: '/v1/team/affiliates_options',
  key:         'teamAffiliatePicker',
};

export default TeamAffiliatePicker;
