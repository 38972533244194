import React from 'react';
import ErrorMessageWithIcon from '~/components/forms/ErrorMessageWithIcon';
import PromptsList from './PromptsList';
import TokensUsage from './TokensUsage';

const InitialState = ({
  state,
  handlePromptClick,
  handleInputChange,
  selectedType,
}) => (
  <>
    <PromptsList
      handlePromptClick={handlePromptClick}
      selectedType={selectedType}
    />

    <div className="form-group">
      <textarea
        className="form-control"
        id="promt"
        placeholder="Type what to write about ..."
        rows="2"
        value={state.inputText}
        onChange={handleInputChange}
      />

      <div>
        {state.inputError && <ErrorMessageWithIcon message={state.inputError} />}
      </div>
    </div>

    <div className="mt-2">
      <TokensUsage state={state} />
    </div>
  </>
);

export default InitialState;
