import React from 'react';
import NoteForm from './note_form';

const EmailSentForm = ({ ...props }) => (
  <NoteForm
    {...props}
    activityType="email"
    submitButtonText="Log Email"
  />
);

export default EmailSentForm;
