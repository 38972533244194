import AppDispatcher            from '~/dispatchers/app_dispatcher';
import APIRequest               from '~/lib/api_request';
import TwilioProfileActionTypes from '~/types/twilio_profile_action_types';
import TwilioBundleActions      from '~/actions/twilio_bundle_actions';

let submitTwilioProfileRequest;

const TwilioProfileActions = {
  submitTwilioProfile() {
    APIRequest.abort(submitTwilioProfileRequest);

    AppDispatcher.dispatch({
      type: TwilioProfileActionTypes.SUBMIT_TWILIO_PROFILE,
    });

    submitTwilioProfileRequest = APIRequest.post({
      resource: '/v1/twilio_profile/submit',
      data:     {},
    });

    submitTwilioProfileRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TwilioProfileActionTypes.SUBMIT_TWILIO_PROFILE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    TwilioProfileActionTypes.SUBMIT_TWILIO_PROFILE_DONE,
        profile: response.body,
      });

      TwilioBundleActions.loadTwilioBundle();
    });
  },
};

export default TwilioProfileActions;
