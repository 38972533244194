import AppDispatcher      from '~/dispatchers/app_dispatcher';
import APIRequest         from '~/lib/api_request';
import AccountActionTypes from '~/types/account_action_types';

let updateAccountRequest;

const AccountActions = {
  updateAccount(user) {
    APIRequest.abort(updateAccountRequest);

    AppDispatcher.dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT,
      user,
    });

    updateAccountRequest = APIRequest.put({
      resource: `/v1/accounts/${user.id}`,
      data:     {
        account: user,
      },
    });

    updateAccountRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: AccountActionTypes.UPDATE_ACCOUNT_FAIL,
          error,
        });

        return;
      }

      // Since we updated our account, update the currentUser.
      Rails.helpers.currentUser = response.body;

      AppDispatcher.dispatch({
        type:     AccountActionTypes.UPDATE_ACCOUNT_DONE,
        response: response.body,
      });
    });
  },

  updateTeam(team) {
    APIRequest.abort(updateAccountRequest);

    AppDispatcher.dispatch({
      type: AccountActionTypes.UPDATE_TEAM,
      team,
    });

    updateAccountRequest = APIRequest.put({
      resource: `/v1/teams/${team.id}`,
      data:     {
        team,
      },
    });

    updateAccountRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: AccountActionTypes.UPDATE_TEAM_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     AccountActionTypes.UPDATE_TEAM_DONE,
        response: response.body,
      });
    });
  },

  updateLandingPage(landingPage) {
    APIRequest.abort(updateAccountRequest);

    AppDispatcher.dispatch({
      type: AccountActionTypes.UPDATE_LANDING_PAGE,
      landingPage,
    });

    updateAccountRequest = APIRequest.put({
      resource: '/v1/landing_page',
      data:     {
        landing_page: landingPage,
      },
    });

    updateAccountRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: AccountActionTypes.UPDATE_LANDING_PAGE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     AccountActionTypes.UPDATE_LANDING_PAGE_DONE,
        response: response.body,
      });
    });
  },
};

export default AccountActions;
