import autoBind            from 'react-autobind';
import shadowRoot          from 'react-shadow';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Input }           from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TaskCategorySelect from '~/components/forms/task_category_select';
import TaskPrioritySelect from '~/components/forms/task_priority_select';
import TaskStatusSelect   from '~/components/forms/task_status_select';
import CampaignStep       from '../forms/campaign_form/campaign_step';

class CampaignPreviewStep extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderStepLabel() {
    const { step, campaign } = this.props;
    const { position, action_type } = step;
    const titleizedActionType = _lodash.titleize(step.action_type);

    let stepLabel;
    const timeValue = CampaignStep.secondsToTimeValue(step.wait_time);
    const timeUnit = CampaignStep.secondsToTimeUnit(step.wait_time);
    const actionType = step.action_type === 'task' ? 'Assigned' : 'Sent';
    const timeUnitPlural = timeValue === 1 ? timeUnit.slice(0, -1) : timeUnit;

    if (position === 1) {
      if (campaign.new_engine) {
        stepLabel = `Step ${position} - ${titleizedActionType} ${actionType} (${timeValue} ${timeUnitPlural} after Start Date)`;
      } else {
        stepLabel = `Step ${position} - ${titleizedActionType} Generated on the First Day Scheduled`;
      }
    } else {
      stepLabel = `Step ${position} - ${titleizedActionType} ${actionType} (${timeValue} ${timeUnitPlural} after Step ${position - 1})`;
    }

    return <span className="mr10">{stepLabel}</span>;
  }

  renderEmail = () => {
    const { step } = this.props;

    return (
      <div>
        <div className="form-group mb15">
          <label htmlFor="step-subject" className="d-block">
            Subject:
          </label>
          <Input value={step.subject} readOnly id="step-subject" />
        </div>

        <div className="form-group mb15">
          <label className="d-block" htmlFor="stepEmailBodyPreview">
            Body:
          </label>
          <shadowRoot.div
            id="stepEmailBodyPreview"
            className="form-control font-activity"
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: step.body }}
            />
          </shadowRoot.div>
        </div>
      </div>
    );
  };

  renderMmsAttachment = () => {
    const { step } = this.props;

    if (!step.media_url) return null;

    return (
      <div className="col-4 mt-3 p0">
        <div
          className="alert alert-success p10 text-left mb5"
          key={step?.media_name}
        >
          <div className="text-break">
            <a
              target="_blank"
              href={step.media_url}
              download={step.media_name}
              crossOrigin="true"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={['far', 'fa-file']}
                className="mr10"
              />
              {step.media_name}
            </a>
          </div>
        </div>
      </div>
    );
  };

  renderSms = () => {
    const { step } = this.props;

    return (
      <div className="form-group mb15">
        <label className="d-block" htmlFor="stepTextBodyPreview">
          Body:
        </label>
        <shadowRoot.div
          id="stepTextBodyPreview"
          className="form-control font-activity"
        >
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: step.body }}
          />
        </shadowRoot.div>

        {this.renderMmsAttachment()}
      </div>
    );
  };

  renderTask = () => {
    const { step } = this.props;
    const ownerID = _lodash.isInteger(step.task_owner)
      ? step.task_owner.toString()
      : '';

    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="task-name" className="d-block">
              Task Name:
            </label>
            <Input value={step.task_name} readOnly id="task-name" />
          </div>

          <div className="form-group col-md-4">
            <span className="d-block">Task Category:</span>
            <TaskCategorySelect value={step.task_category} disabled />
          </div>

          <div className="form-group col-md-4">
            <span className="d-block">Task Due Days:</span>
            <Input value={step.task_due_days} readOnly />
          </div>
        </div>

        <div className="form-group mb15">
          <label className="d-block" htmlFor="stepTaskBodyPreview">
            Task Body:
          </label>
          <shadowRoot.div
            id="stepTaskBodyPreview"
            className="form-control font-activity"
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: step.body }}
            />
          </shadowRoot.div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <span className="d-block">Task Priority:</span>
            <TaskPrioritySelect value={step.task_priority} disabled />
          </div>

          <div className="form-group col-md-6">
            <span className="d-block">Task Status:</span>
            <TaskStatusSelect value={step.task_status} disabled />
          </div>
        </div>
      </div>
    );
  };

  renderAttachments() {
    const { step } = this.props;

    return step.attachments.map((f) => (
      <React.Fragment key={f.id}>
        <div className="row">
          <div className="col-xs-6 alert alert-success text-left mb5 w-25 ml-3">
            <div>
              <FontAwesomeIcon
                icon={['far', 'fa-file']}
                className="mr10"
              />
              {f.name}
            </div>
          </div>

          <div className="col-xs-6 text-right ml-3 p3">
            <a
              target="_blank"
              href={f.file.url}
              className="btn btn-secondary mr5 pb-2 p-2"
              download={f.name}
              crossOrigin="true"
              rel="noopener noreferrer"
            >
              Preview Attachment
            </a>
          </div>
        </div>
      </React.Fragment>
    ));
  }

  render() {
    const { step } = this.props;
    const { color, icon } = CampaignStep.updateColorAndIcon(
      step.action_type,
    );

    return (
      <div className="campaign-step">
        <span className="icon fa-stack fa-lg">
          <FontAwesomeIcon
            icon={['fas', 'fa-circle']}
            className={`fa-stack-2x ${color}`}
          />
          <FontAwesomeIcon
            icon={['far', `fa-${icon}`]}
            inverse
            className="fa-stack-1x"
          />
        </span>

        <div className="body pl-1">
          <div className="form-inline mb15">
            {this.renderStepLabel()}
          </div>

          {step.action_type === 'email' && this.renderEmail()}
          {step.action_type === 'email' && this.renderAttachments()}
          {step.action_type === 'sms' && this.renderSms()}
          {step.action_type === 'task' && this.renderTask()}
        </div>
      </div>
    );
  }
}

CampaignPreviewStep.defaultProps = {
  step: {
    body:    '',
    subject: '',
  },
};

CampaignPreviewStep.propTypes = {
  step: PropTypes.shape({
    action_type:  PropTypes.string,
    body:         PropTypes.string,
    subject:      PropTypes.string,
  }),
  campaign: PropTypes.shape({}).isRequired,
};

export default CampaignPreviewStep;
