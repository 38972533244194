import { ReduceStore }              from 'flux/utils';
import AppDispatcher                from '~/dispatchers/app_dispatcher';
import SubscriptionLimitActionTypes from '~/types/subscription_limit_action_types';

class SubscriptionLimitStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loading:                          false,
      subscriptionLimits:               {},
      videosLimitReached:               false,
      lastSubscriptionLimitStoreAction: null,
    };
  }

  loadSubscriptionLimits(state, action) {
    const newState = { ...state };

    newState.loading = true;
    newState.lastSubscriptionLimitStoreAction = 'loadSubscriptionLimits';

    return newState;
  }

  loadSubscriptionLimitsDone(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.subscriptionLimits = action.subscriptionLimits;
    newState.lastSubscriptionLimitStoreAction = 'loadSubscriptionLimitsDone';

    return newState;
  }

  loadSubscriptionLimitsFail(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.lastSubscriptionLimitStoreAction = 'loadSubscriptionLimitsFail';

    return newState;
  }

  checkIfVideosLimitReached(state, action) {
    const newState = { ...state };

    newState.loading = true;
    newState.lastSubscriptionLimitStoreAction = 'checkIfVideosLimitReached';

    return newState;
  }

  checkIfVideosLimitReachedDone(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.videosLimitReached = action.data;
    newState.lastSubscriptionLimitStoreAction = 'checkIfVideosLimitReachedDone';

    return newState;
  }

  checkIfVideosLimitReachedFail(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.lastSubscriptionLimitStoreAction = 'checkIfVideosLimitReachedFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case SubscriptionLimitActionTypes.LOAD_SUBSCRIPTION_LIMITS:
        return this.loadSubscriptionLimits(state, action);

      case SubscriptionLimitActionTypes.LOAD_SUBSCRIPTION_LIMITS_DONE:
        return this.loadSubscriptionLimitsDone(state, action);

      case SubscriptionLimitActionTypes.LOAD_SUBSCRIPTION_LIMITS_FAIL:
        return this.loadSubscriptionLimitsFail(state, action);

      case SubscriptionLimitActionTypes.CHECK_IF_VIDEOS_LIMIT_REACHED:
        return this.checkIfVideosLimitReached(state, action);

      case SubscriptionLimitActionTypes.CHECK_IF_VIDEOS_LIMIT_REACHED_DONE:
        return this.checkIfVideosLimitReachedDone(state, action);

      case SubscriptionLimitActionTypes.CHECK_IF_VIDEOS_LIMIT_REACHED_FAIL:
        return this.checkIfVideosLimitReachedFail(state, action);

      default:
        return state;
    }
  }
}

export default new SubscriptionLimitStore();
