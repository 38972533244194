import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIRequest          from '~/lib/api_request';

const TeamMemberInviteButton = ({ memberId, text }) => {
  const [sending, setSending] = useState(false);
  const [done, setDone] = useState(false);

  const onResendInvite = async (e) => {
    e.preventDefault();

    setSending(true);

    try {
      await APIRequest.post({ resource: `/v1/team/members/${memberId}/resend` });
      setDone(true);
    } finally {
      setSending(false);
    }
  };

  if (done) {
    return (
      <button type="button" disabled className="btn btn-outline-success disabled">
        <FontAwesomeIcon icon={['far', 'fa-check']} className="mr5" />
        Invite Sent
      </button>
    );
  }

  if (sending) {
    return (
      <button type="button" disabled className="btn btn-secondary disabled">
        <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
        {' '}
        Sending
      </button>
    );
  }

  return (
    <button type="button" className="btn btn-secondary" onClick={onResendInvite}>
      <FontAwesomeIcon icon={['far', 'fa-paper-plane']} />
      {' '}
      {text}
    </button>
  );
};

export default TeamMemberInviteButton;
