import React         from 'react';
import { useParams } from 'react-router-dom';

import BlankPage       from '~/components/pages/blank_page';
import FrontSideBar    from '~/components/navigation/front_side_bar';
import PreviewCampaign from './manage_campaigns_library/preview_campaign';

const PreviewCampaignLibrary = () => {
  const { id } = useParams();
  const { currentTeam } = Rails.helpers;

  if (!currentTeam) return null;

  return (
    <BlankPage title="Campaign Library - Brokerkit">
      <div className="row mb15">
        <FrontSideBar />

        <div className="right-side-page-md">
          <PreviewCampaign campaignID={id} />
        </div>
      </div>
    </BlankPage>
  );
};

PreviewCampaignLibrary.defaultProps = {};
PreviewCampaignLibrary.propTypes = {};

export default PreviewCampaignLibrary;
