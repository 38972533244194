import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import ProductionLabelSelect from '~/components/forms/lead_fields/production_label_select';

class DataTableProductionFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productions:    props.productions,
      selectingState: props.productions.length > 0 ? 'filtered' : 'none',
    };

    autoBind(this);
  }

  onProductionChange(selectedProductions) {
    this.setState({
      productions: selectedProductions,
    });
  }

  handleFilterClick(e) {
    e.preventDefault();

    this.setState({
      selectingState: 'open',
    });
  }

  handleDoneClick(e) {
    e.preventDefault();

    const { productions } = this.props;

    const isFiltering = productions.length > 0;

    this.setState({
      selectingState: isFiltering ? 'filtered' : 'none',
    });

    this.props.onChange(_lodash.map(this.state.productions, (prod) => prod.value));
  }

  handleClearClick(e) {
    e.preventDefault();

    this.setState({
      productions: [],
    });

    this.props.onChange([]);
  }

  render() {
    const { table } = this.props;
    const { selectingState, productions } = this.state;

    if (_lodash.isEmpty(table)) return null;

    let filterComponent;

    switch (selectingState) {
      case 'open':
        filterComponent = (
          <span>
            Show
            {' '}
            {table.title}
            {' '}
            agents with&nbsp;
            <ProductionLabelSelect
              value={this.state.productions}
              placeholder="Select production values"
              wrapperStyle={{ display: 'inline-table', minWidth: '200px' }}
              onChange={this.onProductionChange}
              multi
              clearable
            />
            <button type="button" className="btn btn-secondary ml5" onClick={this.handleDoneClick}>Done</button>
            <button type="button" className="btn btn-secondary ml5" onClick={this.handleClearClick}>Clear</button>
          </span>
        );
        break;
      case 'filtered':
        filterComponent = (
          <a href="#production-filter" onClick={this.handleFilterClick}>
            {table.title}
            {' '}
            agents filtered by production
          </a>
        );
        break;
      case 'none':
      default:
        filterComponent = (
          <a href="#production-filter" onClick={this.handleFilterClick}>
            Showing all
            {' '}
            {table.title}
            {' '}
            agents
          </a>
        );
    }

    return (
      <div className="text-left">
        {filterComponent}
      </div>
    );
  }
}

DataTableProductionFilter.defaultProps = {
  table:       null,
  productions: [],
  onChange:    () => false,
};

DataTableProductionFilter.propTypes = {
  table:       PropTypes.shape({}),
  productions: PropTypes.arrayOf(PropTypes.string),
  onChange:    PropTypes.func,
};

export default DataTableProductionFilter;
