import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import MlsServiceRequest from '~/lib/mls_service_request';
import Select            from '~/components/forms/select';

class SearchQueryConditionOperatorSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      options: [],
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fieldId !== this.props.fieldId) {
      this.loadOptions();
    }
  }

  componentDidMount() {
    this.loadOptions();
  }

  loadOptions() {
    const { fieldId } = this.props;
    const OPERATORS = [];
    let options = [];

    if (fieldId) {
      this.setState({ loading: true });

      MlsServiceRequest.get({
        resource: `/search_fields/${fieldId}/search_operators`,
      }).end((error, response) => {
        this.setState({ loading: false }, () => {
          let customOperators = [];

          if (!error) {
            customOperators = response.body.map((operator) => ({
              value: operator.id,
              label: `${operator.name}`,
            }));
          }

          const staticOperators = _lodash.map(OPERATORS, (e) =>  ({ value: e, label: e }));

          options = [...customOperators, ...staticOperators];
          if (this.props.blank) options = [{ value: '-blank-', label: '-blank-' }, ...options];

          this.setState({ options });
        });
      });
    } else {
      this.setState({ options });
    }
  }

  render() {
    const { loading, options } = this.state;
    return (
      <Select
        {...this.props}
        value={this.props.value}
        onChange={this.props.onChange}
        options={options}
        isLoading={loading}
      />
    );
  }
}

SearchQueryConditionOperatorSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
  blank:    false,
  fieldId:  null,
};

SearchQueryConditionOperatorSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
  blank:       PropTypes.bool,
  fieldId:     PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default SearchQueryConditionOperatorSelect;
