import autoBind   from 'react-autobind';
import React      from 'react';
import shadowRoot from 'react-shadow';

import TimelineActivityAppointment from '~/components/drawers/timeline/activity_appointment';
import AppointmentActions          from '~/actions/appointment_actions';
import LeadDrawerActions           from '~/actions/lead_drawer_actions';
import LeadHelpers                 from '~/helpers/lead_helpers';

class TimelineActivityAppointmentSent extends TimelineActivityAppointment {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onTogglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  renderView() {
    const { activity } = this.state;

    if (!activity.appointment) {
      return null;
    }

    const appointmentAt = LeadHelpers.renderAppointmentAt(activity.appointment.appointment_at);
    const content = activity.appointment.body;

    return (
      <div>
        <p className="text-grey mb5">
          for
          {' '}
          {appointmentAt}
        </p>
        {(content && (
          <shadowRoot.div className="form-control font-activity">
            <div className="content" dangerouslySetInnerHTML={{ __html: content || '' }} />
          </shadowRoot.div>
        ))}
      </div>
    );
  }
}

TimelineActivityAppointmentSent.defaultProps = {
  editable:       false,
  deletable:      true,
  title:          'Appointment Sent',
  icon:           'calendar-alt',
  prefix:         'fas',
  color:          'text-blue',
  alwaysPublic:   false,
};

export default TimelineActivityAppointmentSent;
