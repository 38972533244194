import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';
import { Input }     from 'reactstrap';

import APIRequest from '~/lib/api_request';
import Select     from '~/components/forms/select';
import RoleSelect from '~/components/forms/team_fields/role_select';

class SmsNumberPicker extends React.Component {
  constructor(props) {
    super(props);
    const value = props.value ? props.value.toString() : '';
    this.state = {
      disabled:      false,
      value,
      loading:       false,
      members:       [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = nextProps.value ? nextProps.value.toString() : '';
    this.setState({
      value,
    });
  }

  loadOptions() {
    this.setState({
      loading: true,
    });

    const request = APIRequest.get({
      resource: this.props.apiResource,
    });

    request.end((error, response) => {
      if (error) return;

      const options = response.body.map((u) => ({ user: u, value: u.id.toString(), label: u.name }));
      if (this.props.blank) options.unshift({ value: '-blank-', label: '-blank-' });

      if (!this.props.multi && this.props.clearable) {
        options.unshift({ value: '', label: 'None' });
      }

      this.setState({
        members: options,
        loading: false,
      });
    });
  }

  render() {
    const { label } = this.props;
    const { loading, members } = this.state;

    return (
      <div>
        { label
          && (
          <label className="d-block">
            {label}
          </label>
          )}
        <Select
          {...this.props}
          ref={(el) => { this.select = el; }}
          isLoading={loading}
          options={members}
          value={this.state.value}
          disabled={this.state.disabled}
          openOnFocus
          optionRenderer={this.renderOption}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

SmsNumberPicker.defaultProps = {
  name:        '',
  value:       '',
  clearable:   false,
  blank:       false,
  apiResource: '/v1/team/members',
};

SmsNumberPicker.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.string,
  clearable:   PropTypes.bool,
  apiResource: PropTypes.string,
  options:     PropTypes.array,
  blank:       PropTypes.bool,
  onChange:    PropTypes.func,
};

export default TeamMemberPicker;
