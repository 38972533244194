import AppDispatcher            from '~/dispatchers/app_dispatcher';
import APIRequest               from '~/lib/api_request';
import EmailTemplateActionTypes from '~/types/email_template_action_types';

let loadEmailTemplatesRequest;
// let loadEmailTemplateRequest;
let createEmailTemplateRequest;
let updateEmailTemplateRequest;
let deleteEmailTemplateRequest;

const EmailTemplateActions = {
  loadEmailTemplates(componentID = null) {
    APIRequest.abort(loadEmailTemplatesRequest);

    AppDispatcher.dispatch({
      type: EmailTemplateActionTypes.LOAD_EMAIL_TEMPLATES,
      componentID,
    });

    loadEmailTemplatesRequest = APIRequest.get({
      resource: '/v1/email_templates',
    });

    loadEmailTemplatesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: EmailTemplateActionTypes.LOAD_EMAIL_TEMPLATES_FAIL,
          error,
          componentID,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:           EmailTemplateActionTypes.LOAD_EMAIL_TEMPLATES_DONE,
        emailTemplates: response.body,
        componentID,
      });
    });
  },

  createEmailTemplate(emailTemplate, componentID = null) {
    APIRequest.abort(createEmailTemplateRequest);

    AppDispatcher.dispatch({
      type: EmailTemplateActionTypes.CREATE_EMAIL_TEMPLATE,
      componentID,
    });

    createEmailTemplateRequest = APIRequest.post({
      resource: '/v1/email_templates',
      data:     {
        email_template: emailTemplate,
      },
    });

    createEmailTemplateRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   EmailTemplateActionTypes.CREATE_EMAIL_TEMPLATE_FAIL,
          errors: response.body,
          componentID,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:          EmailTemplateActionTypes.CREATE_EMAIL_TEMPLATE_DONE,
        emailTemplate: response.body,
        componentID,
      });

      EmailTemplateActions.loadEmailTemplates(componentID);
    });
  },

  updateEmailTemplate(emailTemplate, componentID = null) {
    APIRequest.abort(updateEmailTemplateRequest);

    AppDispatcher.dispatch({
      type: EmailTemplateActionTypes.UPDATE_EMAIL_TEMPLATE,
      componentID,
    });

    updateEmailTemplateRequest = APIRequest.put({
      resource: `/v1/email_templates/${emailTemplate.id}`,
      data:     {
        email_template: emailTemplate,
      },
    });

    updateEmailTemplateRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   EmailTemplateActionTypes.UPDATE_EMAIL_TEMPLATE_FAIL,
          errors: response.body,
          componentID,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:          EmailTemplateActionTypes.UPDATE_EMAIL_TEMPLATE_DONE,
        emailTemplate: response.body,
        componentID,
      });

      EmailTemplateActions.loadEmailTemplates(componentID);
    });
  },

  deleteEmailTemplate(templateID, componentID = null) {
    APIRequest.abort(deleteEmailTemplateRequest);

    AppDispatcher.dispatch({
      type: EmailTemplateActionTypes.DELETE_EMAIL_TEMPLATE,
      componentID,
    });

    deleteEmailTemplateRequest = APIRequest.delete({
      resource: `/v1/email_templates/${templateID}`,
    });

    deleteEmailTemplateRequest.end((error, response) => {
      if (error) return;

      AppDispatcher.dispatch({
        type: EmailTemplateActionTypes.DELETE_EMAIL_TEMPLATE_DONE,
        componentID,
      });

      EmailTemplateActions.loadEmailTemplates(componentID);
    });
  },
};

export default EmailTemplateActions;
