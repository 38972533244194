import autoBind         from 'react-autobind';
import React            from 'react';
import { PropTypes }    from 'prop-types';
import classNames       from 'classnames';

import BlankPage    from '~/components/pages/blank_page';
import FrontSideBar from '~/components/navigation/front_side_bar';
import MlsConfigurationForm from './forms/mls_configuration_form/mls_configuration_form';

class ManageMlsConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:      {},
      loading:     false,
    };

    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    const { match } = this.props;

    return (
      <BlankPage title="Data - Brokerkit">
        <div className="row mb15">
          <FrontSideBar />

          <div className="right-side-page-md">
            <MlsConfigurationForm teamMlsId={match.params.id} />
          </div>
        </div>
      </BlankPage>
    );
  }
}

ManageMlsConfiguration.defaultProps = {
  match: {
    params: {},
  },
};

ManageMlsConfiguration.propTypes = {
  match:  PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ManageMlsConfiguration;
