import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';
import ReactDOM      from 'react-dom';
import classNames    from 'classnames';

import TeamMemberDrawer from '~/components/drawers/team_member_drawer';

class TeamMembersSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
    };

    autoBind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      query: nextProps.searchQuery,
    });
  }

  onAddMemberClick(e) {
    e.preventDefault();

    const {
      memberRole, searchQuery, currentPage, notify, drawerExternalRolesOnly,
    } = this.props;
    const { helpers } = this.context;

    helpers.openTeamMemberDrawer({
      memberRole,
      searchQuery,
      currentPage,
      notify,
      externalRolesOnly: drawerExternalRolesOnly,
    });
  }

  handleQueryChange(e) {
    const query = e.target.value || '';

    this.setState({
      query,
    });

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.props.handleSearch(query);
    }, 650);
  }

  render() {
    const { drawerExternalRolesOnly } = this.props;
    const classes = classNames(drawerExternalRolesOnly ? 'form-group' : 'form-group mr-3');

    return (
      <div className="form-inline">
        <div className={classes}>
          <input
            ref={(input) => (this.queryInput = input)}
            className="form-control"
            placeholder="Search..."
            value={this.state.query}
            onChange={this.handleQueryChange}
          />
        </div>

        { !drawerExternalRolesOnly && (
          <div className="form-group">
            <button type="button" className="btn btn-secondary" onClick={this.onAddMemberClick}>
              Add Team Member
            </button>
          </div>
        )}
      </div>
    );
  }
}

TeamMembersSearch.contextTypes = {
  helpers: PropTypes.shape({}),
};

TeamMembersSearch.defaultProps = {
  memberRole:              'staff',
  searchQuery:             '',
  currentPage:             1,
  handleSearch:            () => false,
  notify:                  false,
  drawerExternalRolesOnly: false,
};

TeamMembersSearch.propTypes = {
  memberRole:              PropTypes.string,
  searchQuery:             PropTypes.string,
  currentPage:             PropTypes.number,
  handleSearch:            PropTypes.func,
  notify:                  PropTypes.bool,
  drawerExternalRolesOnly: PropTypes.bool,
};

TeamMembersSearch.contextTypes = {
  helpers: PropTypes.shape({}),
};

export default TeamMembersSearch;
