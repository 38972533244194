import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import isEmail             from 'validator/lib/isEmail';
import isMobilePhone       from 'validator/lib/isMobilePhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Drawer               from '~/components/drawers/drawer';
import AgentActions         from '~/actions/agent_actions';
import TeamMembersActions   from '~/actions/team_members_actions';
import TeamMembersStore     from '~/stores/team_members_store';
import APIRequest           from '~/lib/api_request';
import TeamMemberDrawerForm from './team_member_drawer/team_member_drawer_form';

let approvedForm = false;

class TeamMemberDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teamMember:              props.teamMember,
      formData:                { allow_duplicates_in_org: false },
      errors:                  {},
      warnings:                {},
      sending:                 false,
      loadingLimits:           true,
      limits:                  {},
      duplicate_org_accounts:  [],
      duplicates_found_in_org: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.listener = TeamMembersStore.addListener(this.handleTeamMember);
    const { teamMember } = this.props;

    APIRequest.get({
      resource: '/v1/team_subscription_limits',
      data:     {
        role: (teamMember || { role: {} }).role,
      },
    }).end((error, response) => {
      this.setState({ loadingLimits: false }, () => {
        if (!error) {
          const limits = response.body;

          this.setState({ limits });
        }
      });
    });
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  handleFormChange(formData) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ...formData,
      },
    }));
  }

  handleTeamMember() {
    const emailErrors = {};
    const agentIDErrors = {};
    const { duplicate_org_accounts } = this.state;
    const teamMembersState = TeamMembersStore.getState();
    const {
      teamMember, lastTeamMembersStoreAction, errors, sending,
    } = teamMembersState;
    const { helpers } = this.context;

    let nextState = { teamMembersState };
    let duplicates_found_in_org = false;

    if (lastTeamMembersStoreAction === 'createTeamMemberDone'
        || lastTeamMembersStoreAction === 'updateTeamMemberDone') {
      if (teamMember) {
        if (teamMember.lead) {
          setTimeout(() => {
            helpers.openLeadDrawer({
              loadLeadID: teamMember.lead.id,
            });
          }, 500);
        } else {
          helpers.closeDrawer();
          GlobalContainer.notify(`${teamMember.name} was added successfully`);
        }

        setTimeout(() => {
          AgentActions.reloadLeadTables();
        }, 500);
      }
    }

    if (lastTeamMembersStoreAction === 'createTeamMemberFail'
        || lastTeamMembersStoreAction === 'updateTeamMemberFail') {
      if (errors.duplicate_emails) {
        errors.duplicate_emails.forEach((e, i) => {
          if (e) {
            if (e.context === 'org') {
              duplicate_org_accounts.push(`${e.match.name}: Email: ${e.value}`);
              duplicates_found_in_org = true;
            }

            emailErrors[i] = {
              duplicate: 'It looks like a contact with this email already exists.',
              context:   e.context,
              match:     e.match,
            };
          }
        });
      }

      if (errors.duplicate_agent_ids) {
        errors.duplicate_agent_ids.forEach((e, i) => {
          if (e) {
            if (e.context === 'org') {
              duplicate_org_accounts.push(`${e.match.name}: Agent ID/MLS: ${e.value}`);
              duplicates_found_in_org = true;
            }

            agentIDErrors[i] = {
              duplicate: 'It looks like this agent id already exists.',
              context:   e.context,
              match:     e.match,
            };
          }
        });
      }
      nextState = {
        ...nextState, errors: emailErrors, warnings: agentIDErrors, sending, duplicate_org_accounts, duplicates_found_in_org,
      };

      this.setState(nextState);
    }
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ sending: true });

    const {
      memberRole, searchQuery, currentPage, notify, teamMember,
    } = this.props;
    const { formData } = this.state;

    const isExistingMember = !!formData.id;

    const memberData = {
      user: {
        first_name: formData.firstName,
        last_name:  formData.lastName,
        notify,
      },
      role: formData.role,
      lead: {
        agent_id:                  formData.agentId,
        owner_id:                  formData.owner_id,
        phone:                     formData.phone,
        production_ytd:            formData.productionYtd,
        production_ltm:            formData.productionLtm,
        production_label:          formData.productionLabel,
        lead_emails_attributes:    formData.lead_emails_attributes,
        lead_agent_ids_attributes: formData.lead_agent_ids_attributes,
        allow_duplicates_in_org:   formData.allow_duplicates_in_org,
      },
    };

    const leadEmailAttributes = formData.lead_emails_attributes.filter((x, i) => !x._destroy);
    const leadAgentIdAttributes = formData.lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    if (teamMember && _lodash.isNumber(teamMember.id)) {
      memberData.user.id = teamMember.id;
    }

    if (this.validate() && !approvedForm) {
      leadEmailAttributes.forEach((leadEmail) => {
        if (!leadEmail.email) {
          leadEmail._destroy = true;
        }
      });

      leadAgentIdAttributes.forEach((leadAgentId) => {
        if (!leadAgentId.agent_id) {
          leadAgentId.multiple_listing_service_ouid = '';
        }

        if (!leadAgentId.agent_id && !leadAgentId.multiple_listing_service_ouid) {
          leadAgentId._destroy = true;
        }
      });

      this.setState({ duplicate_org_accounts: [] });

      if (isExistingMember) {
        TeamMembersActions.updateTeamMember(memberData, memberRole, searchQuery, currentPage);
      } else {
        TeamMembersActions.createTeamMember(memberData, memberRole, searchQuery, currentPage);
      }
    } else {
      this.setState({ sending: false });
    }
  }

  onCancel(e) {
    e.preventDefault();

    const { helpers } = this.context;

    helpers.closeDrawer();
  }

  validate() {
    let all_emails_blank = true;
    const { formData, limits }  = this.state;
    const errors   = {};
    const warnings = {};

    const leadEmailAttributes   = formData.lead_emails_attributes.filter((x, i) => !x._destroy);
    const leadAgentIdAttributes = formData.lead_agent_ids_attributes.filter((x, i) => !x._destroy);
    const checkedCount = leadEmailAttributes.filter((x, i) => x.main).length;

    if (!formData.firstName) {
      errors.first_name = 'This field can not be empty';
    }

    if (!formData.lastName) {
      errors.last_name = 'This field can not be empty';
    }

    if (!_lodash.isEmpty(formData.phone) && !isMobilePhone(formData.phone, ['en-US'])) {
      errors.phone = 'Is invalid';
    }

    if (!formData.role.name) {
      errors.role = 'This field can not be empty';
    }

    if (leadEmailAttributes === 0) {
      errors.emails = ['Please add email'];
    }

    if (leadEmailAttributes.length > 0 && checkedCount < 1) {
      errors.emails = ['Please select any main email'];
    }

    leadEmailAttributes.forEach((leadEmail, index) => {
      if (leadEmail.email) {
        all_emails_blank = false;

        if (!isEmail(leadEmail.email)) {
          errors[index] = { email: 'The provided email is invalid' };
        }
      }

      if (!leadEmail.email_type) {
        errors[index] = { email_type: "Can't be empty" };
      }
    });

    if (leadEmailAttributes.length === 0 || all_emails_blank) {
      errors.no_email = ['Please add an email address to create this record so that there is a way to match and update the data later and this email will function as their username'];
    }

    leadAgentIdAttributes.forEach((leadAgentId, index) => {
      if (!!leadAgentId.agent_id && !leadAgentId.multiple_listing_service_ouid) {
        warnings[index] = { multiple_listing_service_ouid: 'It is recommended that you select the MLS for this agent ID.' };
      }
    });

    if (formData.reached_users_limit && (formData.role.name === 'admin' || formData.role.name === 'staff')) {
      errors.reached_users_limit = true;
    }

    if (_lodash.size(errors) === 0 && _lodash.size(warnings) !== 0) {
      approvedForm = !approvedForm;
    } else {
      approvedForm = false;
    }

    this.setState({ errors, warnings });

    return _lodash.size(errors) === 0;
  }

  render() {
    const {
      teamMember, errors, warnings, loadingLimits, limits, sending, duplicate_org_accounts,
      duplicates_found_in_org,
    } = this.state;
    const { externalRolesOnly } = this.props;
    const saveButton = approvedForm ? 'Approve & Save' : 'Save';

    let drawerTitle = 'Add Team Member';
    if (teamMember) {
      drawerTitle = 'Edit Team Member';
    }

    return (
      <form>
        <Drawer>
          <div className="modal-header bg-white bb">
            <h5 className="modal-title">{drawerTitle}</h5>
          </div>
          <div className="modal-body">
            { loadingLimits ? (
              <div className="text-center">
                <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
              </div>
            ) : (
              <TeamMemberDrawerForm
                teamMember={teamMember}
                handleFormChange={this.handleFormChange}
                errors={errors}
                warnings={warnings}
                externalRolesOnly={externalRolesOnly}
                limits={limits}
                duplicate_org_accounts={duplicate_org_accounts}
                duplicates_found_in_org={duplicates_found_in_org}
              />
            )}
          </div>
          <div className="modal-footer bg-white bt">
            <a href="#cancel" className="text-grey mr10" onClick={this.onCancel}>Cancel</a>
            { sending ? (
              <a href="#save" className="btn btn-success btn-wide disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                Saving
              </a>
            ) : (
              <a href="#save" className="btn btn-success btn-wide" onClick={this.onSubmit}>{saveButton}</a>
            )}
          </div>
        </Drawer>
      </form>
    );
  }
}

TeamMemberDrawer.contextTypes = {
  helpers: PropTypes.shape({}),
};

TeamMemberDrawer.defaultProps = {
  memberRole:        'staff',
  searchQuery:       '',
  currentPage:       1,
  teamMember:        null,
  notify:            true,
  externalRolesOnly: false,
};

TeamMemberDrawer.propTypes = {
  memberRole:        PropTypes.string,
  searchQuery:       PropTypes.string,
  currentPage:       PropTypes.number,
  teamMember:        PropTypes.shape({}),
  notify:            PropTypes.bool,
  externalRolesOnly: PropTypes.bool,
};

export default TeamMemberDrawer;
