import autoBind from 'react-autobind';
import React from 'react';

const Notice = ({ children, color }) => (
  <div className="pt15 pl15 pr15 bg-white">
    <div className={`alert alert-${color}`}>
      {children}
    </div>
  </div>
);

class GlobalNotices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    autoBind(this);
  }

  render() {
    const { currentUser, currentTeam } = Rails.helpers;

    if (!currentTeam) return null;

    if (!currentTeam.activated_at || !currentTeam.live) {
      return (
        <Notice color="info">
          This team is inactive. After the TL logs in for the first time, it will be set to active.
        </Notice>
      );
    }

    if (!Rails.subscription || !Rails.subscription.status === 'active') {
      if (currentTeam.in_grace_period) {
        return (
          <Notice color="danger">
            You have
            {' '}
            {currentTeam.grace_period_remaining_time}
            {' '}
            left to add billing information.
            {' '}
            <a href="/billing">Add a credit card</a>
          </Notice>
        );
      }

      return (
        <Notice color="danger">
          You&apos;ll need a subscription to continue using Brokerkit.
          {' '}
          <a href="/billing">Add a credit card</a>
        </Notice>
      );
    }

    if (!currentTeam.onboarded && !!currentUser && !!currentUser.role && (currentUser.role.name === 'staff' || currentUser.role.name === 'admin')) {
      return (
        <Notice color="success">
          <span className="mr10">Welcome to Brokerkit! Finish onboarding by inviting your team so they can login</span>
          <a href="/onboard" className="btn btn-success btn-sm mr5">Invite Agents</a>
          <a href="/onboard/cancel" className="btn btn-secondary btn-sm">Dismiss</a>
        </Notice>
      );
    }

    return null;
  }
}

export default GlobalNotices;
