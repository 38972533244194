const EmailDomainActionTypes = {
  LOAD_EMAIL_DOMAINS:      'LOAD_EMAIL_DOMAINS',
  LOAD_EMAIL_DOMAINS_DONE: 'LOAD_EMAIL_DOMAINS_DONE',
  LOAD_EMAIL_DOMAINS_FAIL: 'LOAD_EMAIL_DOMAINS_FAIL',

  CREATE_EMAIL_DOMAIN:      'CREATE_EMAIL_DOMAIN',
  CREATE_EMAIL_DOMAIN_DONE: 'CREATE_EMAIL_DOMAIN_DONE',
  CREATE_EMAIL_DOMAIN_FAIL: 'CREATE_EMAIL_DOMAIN_FAIL',

  VERIFY_EMAIL_DOMAIN:      'VERIFY_EMAIL_DOMAIN',
  VERIFY_EMAIL_DOMAIN_DONE: 'VERIFY_EMAIL_DOMAIN_DONE',
  VERIFY_EMAIL_DOMAIN_FAIL: 'VERIFY_EMAIL_DOMAIN_FAIL',

  DELETE_EMAIL_DOMAIN:      'DELETE_EMAIL_DOMAIN',
  DELETE_EMAIL_DOMAIN_DONE: 'DELETE_EMAIL_DOMAIN_DONE',
  DELETE_EMAIL_DOMAIN_FAIL: 'DELETE_EMAIL_DOMAIN_FAIL',
};

export default EmailDomainActionTypes;
