import TeamMemberPicker from '~/components/forms/team_fields/team_member_picker';

class TeammatePicker extends TeamMemberPicker {}

TeammatePicker.defaultProps = {
  apiResource:  '/v1/team/teammates_options',
  key:          'teammatePicker',
  disabled:     false,
  contactOwner: false,
};

export default TeammatePicker;
