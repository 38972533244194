import React from 'react';
import PropTypes from 'prop-types';
import Select from '~/components/forms/dropdowns/SelectWrapper/Select';

const SelectPromptType = ({
  onTypeSelected,
  selectedType,
  options,
}) => {
  const handleOptionChange = (opt) => {
    const clickedOption = opt ? opt.value : '';

    onTypeSelected(clickedOption);
  };

  return (
    <Select
      id="prompt-type"
      name="prompt-type"
      isClearable
      isSearchable
      value={selectedType}
      options={options}
      onChange={(option) => handleOptionChange(option)}
    />
  );
};

SelectPromptType.propTypes = {
  onTypeSelected: PropTypes.func.isRequired,
  options:        PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
};

export default SelectPromptType;
