import React from 'react';
import { PropTypes } from 'prop-types';

class TinyUser extends React.Component {
  renderAvatar() {
    const { user } = this.props;

    if (!user) {
      return null;
    }
    const avatar = user.avatar?.tiny || user.tiny_avatar;

    return (
      <img className="rounded-circle mr5" src={avatar} alt="User avatar" />
    );
  }

  render() {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return (
      <span>
        {this.renderAvatar()}
        {user.name}
      </span>
    );
  }
}

TinyUser.defaultProps = {
  user: null,
};

TinyUser.propTypes = {
  user: PropTypes.shape({}),
};

export default TinyUser;
