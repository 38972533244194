const AppointmentActionTypes = {
  LOAD_APPOINTMENTS:      'LOAD_APPOINTMENTS',
  LOAD_APPOINTMENTS_DONE: 'LOAD_APPOINTMENTS_DONE',
  LOAD_APPOINTMENTS_FAIL: 'LOAD_APPOINTMENTS_FAIL',

  DELETE_APPOINTMENT:      'DELETE_APPOINTMENT',
  DELETE_APPOINTMENT_DONE: 'DELETE_APPOINTMENT_DONE',
  DELETE_APPOINTMENT_FAIL: 'DELETE_APPOINTMENT_FAIL',
};

export default AppointmentActionTypes;
