const DataActionTypes = {
  IDENTIFY_DUPLICATES:      'IDENTIFY_DUPLICATES',
  IDENTIFY_DUPLICATES_DONE: 'IDENTIFY_DUPLICATES_DONE',
  IDENTIFY_DUPLICATES_FAIL: 'IDENTIFY_DUPLICATES_FAIL',

  MERGE_DUPLICATES:         'MERGE_DUPLICATES',
  MERGE_DUPLICATES_DONE:    'MERGE_DUPLICATES_DONE',
  MERGE_DUPLICATES_FAIL:    'MERGE_DUPLICATES_FAIL',

  CREATE_IMPORT:         'CREATE_IMPORT',
  CREATE_IMPORT_DONE:    'CREATE_IMPORT_DONE',
  CREATE_IMPORT_FAIL:    'CREATE_IMPORT_FAIL',
};

export default DataActionTypes;
