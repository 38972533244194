import AppDispatcher     from '~/dispatchers/app_dispatcher';
import APIRequest        from '~/lib/api_request';
import ReportActionTypes from '~/types/report_action_types';

let loadReportRequest;
let loadJoinReportRequest;
let loadActivityRequest;

const ReportActions = {
  loadReport(filters = {}) {
    APIRequest.abort(loadReportRequest);

    AppDispatcher.dispatch({
      type: ReportActionTypes.LOAD_REPORT,
    });

    loadReportRequest = APIRequest.get({
      resource: '/v1/reports',
      data:     {
        filters,
      },
    });

    loadReportRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: ReportActionTypes.LOAD_REPORT_FAIL,
          error,
        });

        return;
      }
      AppDispatcher.dispatch({
        type:         ReportActionTypes.LOAD_REPORT_DONE,
        recordGroups: response.body.data,
        summaryCount: response.body.summary_count,
        summaryYTD:   response.body.summary_ytd,
      });
    });
  },

  loadJoinReport(filters = {}) {
    APIRequest.abort(loadJoinReportRequest);

    AppDispatcher.dispatch({
      type: ReportActionTypes.LOAD_JOIN_REPORT,
    });

    loadJoinReportRequest = APIRequest.get({
      resource: '/v1/join_reports',
      data:     {
        filters,
      },
    });

    loadJoinReportRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: ReportActionTypes.LOAD_JOIN_REPORT_FAIL,
          error,
        });

        return;
      }
      AppDispatcher.dispatch({
        type:         ReportActionTypes.LOAD_JOIN_REPORT_DONE,
        recordGroups: response.body.data,
      });
    });
  },

  loadActivityData(params = {}) {
    APIRequest.abort(loadActivityRequest);

    AppDispatcher.dispatch({
      type: ReportActionTypes.LOAD_ACTIVITY,
    });

    loadActivityRequest = APIRequest.get({
      resource: '/v1/activity',
      data:     { ...params },
    });

    loadActivityRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: ReportActionTypes.LOAD_ACTIVITY_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:         ReportActionTypes.LOAD_ACTIVITY_DONE,
        activityData: response.body,
        totalPages:   response.body.pagy?.total_pages || 1,
        currentPage:  response.body.pagy?.current_page || 1,
        totalItems:   response.body.pagy?.total_items || 0,
        perPage:      response.body.pagy?.per_page || 10,
        lastPage:     response.body.pagy?.last_page || 1,
        startItem:    response.body.pagy?.from || 0,
        endItem:      response.body.pagy?.to || 0,
      });
    });
  },

};

export default ReportActions;
