import React, { useState, useEffect, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import {
  InputGroup, InputGroupAddon, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _lodash from 'lodash';
import Moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import DocumentSearchFilters from './search_form/document_search_filters';

const DocumentFiltersForm = ({ handleDocumentSearch }) => {
  const history = useHistory();
  const location = useLocation();
  const queryInput = useRef(null);

  const defaultSearchValues = () => ({
    query:       '',
    status:      [],
    category:    [],
    owner_id:    [],
    uploaded_at: '',
  });

  const buildSearchData = (params = {}) => {
    const urlParams = _lodash.isEmpty(params) ? GlobalContainer.urlParams() : params;
    const { query, status, category, owner_id, uploaded_at } = urlParams.s || {};

    return {
      query:       query || '',
      status:      status || [],
      category:    category || [],
      owner_id:    owner_id || [],
      uploaded_at: uploaded_at || '',
    };
  };

  const [showFilters, setShowFilters] = useState(false);
  const [searchData, setSearchData] = useState(defaultSearchValues());
  const [filtersKey, setFiltersKey] = useState(uuidv4());

  useEffect(() => {
    const params = GlobalContainer.urlParams();
    if (params.s) {
      setSearchData(buildSearchData());
    }

    if (queryInput.current) {
      const len = queryInput.current.value.length;
      queryInput.current.focus();
      queryInput.current.setSelectionRange(len, len);
    }

    const unlisten = history.listen(() => {
      const urlParams = GlobalContainer.urlParams();
      if (_lodash.isEmpty(urlParams) || !urlParams.s) return;

      setSearchData((prevState) => ({
        ...prevState,
        ...buildSearchData(),
      }));
    });

    return () => {
      if (unlisten) unlisten();
    };
  }, [history, location]);

  useEffect(() => {
    const params = GlobalContainer.urlParams();
    if (params.s) {
      setSearchData(buildSearchData(params));
      handleDocumentSearch(buildSearchData(params));
      setFiltersKey(uuidv4());
      setShowFilters(true);
    }
  }, []);

  const doAutoComplete = useCallback(_lodash.debounce((searchData, query) => {
    handleDocumentSearch({ ...searchData, query: encodeURIComponent(query) });
  }, 850), [handleDocumentSearch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleDocumentSearch({ ...searchData, query: encodeURIComponent(searchData.query) });
  };

  const handleQueryChange = (e) => {
    const query = e.target.value || '';
    setSearchData((prevState) => ({ ...prevState, query }));
    doAutoComplete({ ...searchData, query }, query);
  };

  const handleSearchDataChange = (data) => {
    setSearchData((prevState) => ({ ...prevState, ...data }));
    handleDocumentSearch({ ...searchData, ...data });
  };

  const handleSingleSelectChange = (fieldName, fieldValue) => {
    handleSearchDataChange({ [fieldName]: fieldValue });
  };

  const handleDateSelectChange = (fieldName, fieldValue) => {
    handleSearchDataChange({ [fieldName]: Moment.isMoment(fieldValue) ? fieldValue.format('YYYY-MM-DD') : null });
  };

  const handleMultiSelectChange = (fieldName, selectedOptions) => {
    handleSearchDataChange({ [fieldName]: _lodash.map(selectedOptions, (opt) => opt.value) });
  };

  const clearSearch = () => {
    setSearchData(defaultSearchValues());
    setFiltersKey(uuidv4());
    handleDocumentSearch(defaultSearchValues());
    setShowFilters(false);
  };

  const renderFilters = () => {
    if (!showFilters) {
      return (
        <div className="form-group mb0">
          <a href="#show-filters" onClick={() => setShowFilters(true)}>
            Show advanced options
          </a>
        </div>
      );
    }

    return (
      <DocumentSearchFilters
        key={filtersKey}
        searchData={searchData}
        hideFilters={() => setShowFilters(false)}
        clearSearch={clearSearch}
        handleSingleSelectChange={handleSingleSelectChange}
        handleMultiSelectChange={handleMultiSelectChange}
        handleDateSelectChange={handleDateSelectChange}
      />
    );
  };

  return (
    <div className="search-form card p20 mb15">
      <form onSubmit={handleSubmit}>
        <div className="form-group mb5">
          <div className="row">
            <div className="col-12">
              <InputGroup className="mr5">
                <input
                  ref={queryInput}
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchData.query}
                  onChange={handleQueryChange}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary">
                    <FontAwesomeIcon icon={['far', 'fa-search']} className="mr5" />
                    {' '}
                    Search
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
        </div>

        {renderFilters()}
      </form>
    </div>
  );
};

DocumentFiltersForm.defaultProps = {
  handleDocumentSearch: () => false,
};

DocumentFiltersForm.propTypes = {
  handleDocumentSearch: PropTypes.func,
};

export default DocumentFiltersForm;
