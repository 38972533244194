import React from 'react';
import { PropTypes } from 'prop-types';
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CampaignActions from '~/actions/campaign_actions';
import CampaignStore from '~/stores/campaign_store';

import DonateCampaignOriginSelect from '~/components/forms/donate_campaign_origin_select';
import AppModal from './app_modal';

class DonateCampaignModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:     {},
      submitting: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.CampaignStoreListener = CampaignStore.addListener(this.onChange);
  }

  componentWillUnmount() {
    if (this.CampaignStoreListener) this.CampaignStoreListener.remove();
  }

  onChange() {
    const campaignState = CampaignStore.getState();
    const { lastCampaignStoreAction, message, errors } = campaignState;
    const $modal = $(this.appModal.modal);

    if (lastCampaignStoreAction === 'donateCampaignDone') {
      GlobalContainer.notify(message, 'success');

      $modal.modal('hide');
    }

    if (lastCampaignStoreAction === 'donateCampaignFail') {
      this.setState({
        errors,
        submitting: false,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { campaignId } = this.props;
    const { origin } = this.state;

    if (this.validate()) {
      this.setState({ submitting: true });
      CampaignActions.donateCampaign(campaignId, origin);
    }
  }

  handleOriginSelect = (selectedValue) => {
    this.setState({ origin: selectedValue.value });
  }

  validate() {
    const errors = {};
    const { origin } = this.state;

    if (!origin) errors.origin = "Can't be empty";
    this.setState({ errors });

    return _lodash.size(errors) === 0;
  }

  render() {
    const { errors, submitting, origin } = this.state;

    return (
      <AppModal
        containerID="secondary-modal"
        modalClass="modal modal-overlay"
        dialogClass="modal-dialog modal-lg"
        ref={(appModal) => this.appModal = appModal}
      >
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Please confirm you want to donate this campaign to the campaign library
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group mr5">
              <DonateCampaignOriginSelect
                placeholder="Please, make a selection"
                onChange={this.handleOriginSelect}
                value={origin}
                className={errors.origin ? 'has-error' : ''}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Donating ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Donate</button>
            )}
          </div>
        </form>
      </AppModal>
    );
  }
}

DonateCampaignModal.defaultProps = {};

DonateCampaignModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
};

export default DonateCampaignModal;
