import { ReduceStore }          from 'flux/utils';
import AppDispatcher            from '~/dispatchers/app_dispatcher';
import EmailTemplateActionTypes from '~/types/email_template_action_types';

class EmailTemplateStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingEmailTemplates:    false,
      creatingEmailTemplate:    false,
      updatingEmailTemplate:    false,
      emailTemplates:           null,
      emailTemplate:            null,
      emailTemplateStoreAction: null, // store last dispatch action name
      componentID:              null, // store select object instance to support multiple template selects
    };
  }

  loadEmailTemplates(state, action) {
    const newState = { ...state };

    newState.loadingEmailTemplates = true;
    newState.emailTemplateStoreAction = 'loadEmailTemplates';
    newState.componentID = action.componentID;

    return newState;
  }

  loadEmailTemplatesDone(state, action) {
    const newState = { ...state };

    newState.loadingEmailTemplates = false;
    newState.emailTemplates = action.emailTemplates;
    newState.emailTemplateStoreAction = 'loadEmailTemplatesDone';
    newState.componentID = action.componentID;

    return newState;
  }

  loadEmailTemplatesFail(state, action) {
    const newState = { ...state };

    newState.loadingEmailTemplates = false;
    newState.errors = action.errors;
    newState.emailTemplateStoreAction = 'loadEmailTemplatesFail';
    newState.componentID = action.componentID;

    return newState;
  }

  createEmailTemplate(state, action) {
    const newState = { ...state };

    newState.creatingEmailTemplate = true;
    newState.emailTemplateStoreAction = 'createEmailTemplate';
    newState.componentID = action.componentID;

    return newState;
  }

  createEmailTemplateDone(state, action) {
    const newState = { ...state };

    newState.creatingEmailTemplate = false;
    newState.emailTemplate = action.emailTemplate;
    newState.emailTemplateStoreAction = 'createEmailTemplateDone';
    newState.componentID = action.componentID;

    return newState;
  }

  createEmailTemplateFail(state, action) {
    const newState = { ...state };

    newState.creatingEmailTemplate = false;
    newState.errors = action.errors;
    newState.emailTemplateStoreAction = 'createEmailTemplateFail';
    newState.componentID = action.componentID;

    return newState;
  }

  updateEmailTemplate(state, action) {
    const newState = { ...state };

    newState.updatingEmailTemplate = true;
    newState.emailTemplateStoreAction = 'updateEmailTemplate';
    newState.componentID = action.componentID;

    return newState;
  }

  updateEmailTemplateDone(state, action) {
    const newState = { ...state };

    newState.updatingEmailTemplate = false;
    newState.emailTemplate = action.emailTemplate;
    newState.emailTemplateStoreAction = 'updateEmailTemplateDone';
    newState.componentID = action.componentID;

    return newState;
  }

  updateEmailTemplateFail(state, action) {
    const newState = { ...state };

    newState.updatingEmailTemplate = false;
    newState.errors = action.errors;
    newState.emailTemplateStoreAction = 'updateEmailTemplateFail';
    newState.componentID = action.componentID;

    return newState;
  }

  deleteEmailTemplate(state, action) {
    const newState = { ...state };

    newState.emailTemplateStoreAction = 'deleteEmailTemplate';
    newState.componentID = action.componentID;

    return newState;
  }

  deleteEmailTemplateDone(state, action) {
    const newState = { ...state };

    newState.emailTemplateStoreAction = 'deleteEmailTemplateDone';
    newState.componentID = action.componentID;

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case EmailTemplateActionTypes.LOAD_EMAIL_TEMPLATES:
        return this.loadEmailTemplates(state, action);

      case EmailTemplateActionTypes.LOAD_EMAIL_TEMPLATES_DONE:
        return this.loadEmailTemplatesDone(state, action);

      case EmailTemplateActionTypes.LOAD_EMAIL_TEMPLATES_FAIL:
        return this.loadEmailTemplatesFail(state, action);

      case EmailTemplateActionTypes.CREATE_EMAIL_TEMPLATE:
        return this.createEmailTemplate(state, action);

      case EmailTemplateActionTypes.CREATE_EMAIL_TEMPLATE_DONE:
        return this.createEmailTemplateDone(state, action);

      case EmailTemplateActionTypes.CREATE_EMAIL_TEMPLATE_FAIL:
        return this.createEmailTemplateFail(state, action);

      case EmailTemplateActionTypes.UPDATE_EMAIL_TEMPLATE:
        return this.updateEmailTemplate(state, action);

      case EmailTemplateActionTypes.UPDATE_EMAIL_TEMPLATE_DONE:
        return this.updateEmailTemplateDone(state, action);

      case EmailTemplateActionTypes.UPDATE_EMAIL_TEMPLATE_FAIL:
        return this.updateEmailTemplateFail(state, action);

      case EmailTemplateActionTypes.DELETE_EMAIL_TEMPLATE:
        return this.deleteEmailTemplate(state, action);

      case EmailTemplateActionTypes.DELETE_EMAIL_TEMPLATE_DONE:
        return this.deleteEmailTemplateDone(state, action);

      default:
        return state;
    }
  }
}

export default new EmailTemplateStore();
