import autoBind       from 'react-autobind';
import React          from 'react';
import { PropTypes }  from 'prop-types';
import { withRouter } from 'react-router-dom';

import brokerbit  from '~/lib/brokerbit';
import DomainItem from './domain_item';

class EmailDomainsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleDomainDelete(domain) {
    if (_lodash.isEmpty(domain)) return;

    brokerbit.confirmBox({
      message:  `Are you sure you want to permanently delete (${domain.name})?`,
      callback: (ok) => {
        const { deleteDomain } = this.props;

        if (ok) {
          deleteDomain(domain);
        }
      },
    });
  }

  renderDomainRows() {
    const { domains } = this.props;

    const domainRows = [];
    domains.forEach((domain, idx) => {
      domainRows.push(
        <DomainItem
          key={`domain-${domain.id}`}
          domain={domain}
          handleDomainDelete={this.handleDomainDelete}
        />,
      );
    });

    return domainRows;
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="border-0 text-uppercase" scope="col">
                Domain Name
              </th>
              <th className="border-0 text-uppercase" scope="col">
                Status
              </th>
              <th
                className="border-0 text-uppercase pull-right"
                scope="col"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>{this.renderDomainRows()}</tbody>
        </table>
      </div>
    );
  }
}

EmailDomainsTable.defaultProps = {
  domains:      [],
  deleteDomain: () => false,
};

EmailDomainsTable.propTypes = {
  history:      PropTypes.shape({}).isRequired,
  domains: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  deleteDomain: PropTypes.func,
};

export default withRouter(EmailDomainsTable);
