import React from 'react';
import { PropTypes } from 'prop-types';

import BlankPage  from './blank_page';
import ReportCard from './agent_dashboard/report_card';

class PublicReport extends React.Component {
  render() {
    return (
      <BlankPage>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ReportCard clickable={false} shareable={false} />
            </div>
          </div>
        </div>
      </BlankPage>
    );
  }
}

PublicReport.defaultProps = {
  match: {},
};

PublicReport.propTypes = {
  match: PropTypes.shape({}),
};

export default PublicReport;
