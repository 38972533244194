import { ReduceStore }                 from 'flux/utils';
import AppDispatcher                   from '~/dispatchers/app_dispatcher';
import CampaignSubscriptionActionTypes from '~/types/campaign_subscription_action_types';

class CampaignSubscriptionStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingCampaignSubscriptions:        true,
      subscriptions:                       [],
      subscription:                        null,
      lastCampaignSubscriptionStoreAction: null,
      subscriptionErrors:                  null,
    };
  }

  loadCampaignSubscriptions(state, action) {
    const newState = { ...state };

    newState.loadingCampaignSubscriptions = true;
    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'loadCampaignSubscriptions';

    return newState;
  }

  loadCampaignSubscriptionsDone(state, action) {
    const newState = { ...state };

    newState.loadingCampaignSubscriptions = false;
    newState.subscriptions = action.subscriptions;
    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'loadCampaignSubscriptionsDone';

    return newState;
  }

  loadCampaignSubscriptionsFail(state, action) {
    const newState = { ...state };

    newState.loadingCampaignSubscriptions = false;
    newState.lastCampaignSubscriptionStoreAction = 'loadCampaignSubscriptionsFail';

    return newState;
  }

  createCampaignSubscription(state, action) {
    const newState = { ...state };

    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'createCampaignSubscription';

    return newState;
  }

  createCampaignSubscriptionDone(state, action) {
    const newState = { ...state };

    newState.errors = null;
    newState.subscription = action.subscription;
    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'createCampaignSubscriptionDone';

    return newState;
  }

  createCampaignSubscriptionFail(state, action) {
    const newState = { ...state };

    newState.subscriptionErrors = action.errors;
    newState.lastCampaignSubscriptionStoreAction = 'createCampaignSubscriptionFail';

    return newState;
  }

  updateCampaignSubscription(state, action) {
    const newState = { ...state };

    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'updateCampaignSubscription';

    return newState;
  }

  updateCampaignSubscriptionDone(state, action) {
    const newState = { ...state };

    newState.errors = null;
    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'updateCampaignSubscriptionDone';

    return newState;
  }

  updateCampaignSubscriptionFail(state, action) {
    const newState = { ...state };

    newState.subscriptionErrors = action.errors;
    newState.lastCampaignSubscriptionStoreAction = 'updateCampaignSubscriptionFail';

    return newState;
  }

  deleteCampaignSubscription(state, action) {
    const newState = { ...state };

    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'deleteCampaignSubscription';

    return newState;
  }

  deleteCampaignSubscriptionDone(state, action) {
    const newState = { ...state };

    newState.errors = null;
    newState.subscriptionErrors = null;
    newState.lastCampaignSubscriptionStoreAction = 'deleteCampaignSubscriptionDone';

    return newState;
  }

  deleteCampaignSubscriptionFail(state, action) {
    const newState = {  ...state };

    newState.errors = action.errors;
    newState.lastCampaignSubscriptionStoreAction = 'deleteCampaignSubscriptionFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case CampaignSubscriptionActionTypes.LOAD_CAMPAIGN_SUBSCRIPTIONS:
        return this.loadCampaignSubscriptions(state, action);

      case CampaignSubscriptionActionTypes.LOAD_CAMPAIGN_SUBSCRIPTIONS_DONE:
        return this.loadCampaignSubscriptionsDone(state, action);

      case CampaignSubscriptionActionTypes.LOAD_CAMPAIGN_SUBSCRIPTIONS_FAIL:
        return this.loadCampaignSubscriptionsFail(state, action);

      case CampaignSubscriptionActionTypes.CREATE_CAMPAIGN_SUBSCRIPTION:
        return this.createCampaignSubscription(state, action);

      case CampaignSubscriptionActionTypes.CREATE_CAMPAIGN_SUBSCRIPTION_DONE:
        return this.createCampaignSubscriptionDone(state, action);

      case CampaignSubscriptionActionTypes.CREATE_CAMPAIGN_SUBSCRIPTION_FAIL:
        return this.createCampaignSubscriptionFail(state, action);

      case CampaignSubscriptionActionTypes.UPDATE_CAMPAIGN_SUBSCRIPTION:
        return this.updateCampaignSubscription(state, action);

      case CampaignSubscriptionActionTypes.UPDATE_CAMPAIGN_SUBSCRIPTION_DONE:
        return this.updateCampaignSubscriptionDone(state, action);

      case CampaignSubscriptionActionTypes.UPDATE_CAMPAIGN_SUBSCRIPTION_FAIL:
        return this.updateCampaignSubscriptionFail(state, action);

      case CampaignSubscriptionActionTypes.DELETE_CAMPAIGN_SUBSCRIPTION:
        return this.deleteCampaignSubscription(state, action);

      case CampaignSubscriptionActionTypes.DELETE_CAMPAIGN_SUBSCRIPTION_DONE:
        return this.deleteCampaignSubscriptionDone(state, action);

      case CampaignSubscriptionActionTypes.DELETE_CAMPAIGN_SUBSCRIPTION_FAIL:
        return this.deleteCampaignSubscriptionFail(state, action);

      default:
        return state;
    }
  }
}

export default new CampaignSubscriptionStore();
