import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer        from '~/components/global_container';
import CampaignLibraryActions from '~/actions/campaign_library_actions';
import CampaignForm           from './campaign_library_form';

class ComposingCampaignLibrary extends React.Component {
  constructor(props) {
    super(props);

    const { campaignID } = props;

    this.state = {
      loadingCampaign:    !!campaignID,
      validCampaign:      true,
      campaign:           null,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { campaignID } = this.props;

    if (campaignID) {
      CampaignLibraryActions.loadCampaign(campaignID, this.onCampaignLoad);
    }
  }

  onCampaignLoad(campaign) {
    this.setState({
      loadingCampaign: false,
      campaign,
    });
  }

  onCampaignStateChange(stateChange) {
    this.setState(stateChange);
  }

  renderCampaignForm() {
    const {
      loadingCampaign, campaign, validCampaign,
    } = this.state;

    const { history } = this.props;

    if (loadingCampaign) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    return (
      <CampaignForm
        campaign={campaign}
        validCampaign={validCampaign}
        onCampaignStateChange={this.onCampaignStateChange}
        history={history}
      />
    );
  }

  render() {
    const { validCampaign } = this.state;
    const { history } = this.props;
    const campaigns_uri = GlobalContainer.productUri('/campaign_libraries');

    return (
      <div className="card campaign">
        <div className="card-block">
          <h4 className="mb15">
            <div className="pull-right mb-3">
              <Link
                to={{
                  pathname: campaigns_uri,
                  page:     history.location.state?.page,
                }}
                className="btn btn-secondary mr5"
              >
                Cancel and Go Back
              </Link>
              <button
                type="submit"
                className="btn btn-primary"
                form="compose_campaign_form"
                disabled={!validCampaign}
              >
                Save Campaign
              </button>
            </div>
            <span className="text-nowrap">
              Compose a Campaign
            </span>
          </h4>

          {this.renderCampaignForm()}
        </div>
      </div>
    );
  }
}

ComposingCampaignLibrary.defaultProps = {
  campaignID: null,
};

ComposingCampaignLibrary.propTypes = {
  campaignID: PropTypes.string,
  history:    PropTypes.shape({}).isRequired,
};

export default withRouter(ComposingCampaignLibrary);
