import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class TaskStatusSelect extends React.Component {
  constructor(props) {
    super(props);

    const options = [
      { label: 'To Do',       value: 'to_do' },
      { label: 'In Progress', value: 'in_progress' },
      { label: 'Done',        value: 'done' },
      { label: 'Canceled',   value: 'canceled' },
    ];

    this.state = {
      options,
    };

    autoBind(this);
  }

  render() {
    const { options } = this.state;
    const { value, onChange } = this.props;

    return (
      <Select
        {...this.props}
        value={value}
        onChange={onChange}
        options={options}
      />
    );
  }
}

TaskStatusSelect.defaultProps = {
  onChange:    () => {},
  value:       '',
};

TaskStatusSelect.propTypes = {
  onChange:    PropTypes.func,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default TaskStatusSelect;
