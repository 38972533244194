import qs                   from 'qs';
import classNames           from 'classnames';
import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import LabelActions from '~/actions/label_actions';
import Tooltipable  from '~/components/effects/tooltipable';

class LabelItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      label: props.label,
    };
  }

  handleSearch(searchData) {
    const { history } = this.props;
    const searchQuery = qs.stringify({ s: searchData, _t: Rails.helpers.currentTeam.id }, { arrayFormat: 'brackets' });
    const resource = GlobalContainer.product() === 'retention' ? 'retention' : 'recruiter';

    history.push(`/${resource}/search?${decodeURI(searchQuery)}`, searchData);
  }

  render() {
    const { handleLabelClick, handleLabelDelete } = this.props;
    const { label } = this.state;

    return (
      <tr
        key={`label-${label.id}`}
        className="text-grey"
        onClick={(e) => {
          e.preventDefault();
          if (e.target.nodeName === 'TD') handleLabelClick(label);
        }}
      >
        <td>
          <strong>
            <a
              href="#edit"
              className="text-grey-dark text-no-underline"
              onClick={(e) => {
                e.preventDefault();
                handleLabelClick(label);
              }}
            >
              {label.name}
            </a>
          </strong>
          <p className="mt10">
            <small className="text-grey">
              <FontAwesomeIcon icon={['far', 'fa-users']} className="mr5" />
              {' '}
              {label.leads_count}
              {' '}
              labeled
            </small>
            <small className="text-grey ml30">
              <a
                href="#search"
                className="text-grey text-no-underline"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleSearch({ query: '', label: [label.id] });
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-paper-plane']} className="mr5" />
                {' '}
                view labeled leads
              </a>
            </small>
          </p>
        </td>
        <td className="col-2">
          <span className="pull-right">
            <Tooltipable text="Edit label">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();
                  handleLabelClick(label);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-edit']} />
              </button>
            </Tooltipable>

            <Tooltipable text="Deletes label">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleLabelDelete(label);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </Tooltipable>
          </span>
        </td>
      </tr>
    );
  }
}

LabelItem.defaultProps = {
  handleLabelClick:  () => false,
  handleLabelDelete: () => false,
};

LabelItem.propTypes = {
  history:              PropTypes.shape({}).isRequired,
  label:                PropTypes.shape({}).isRequired,
  handleLabelClick:     PropTypes.func,
  handleLabelDelete:    PropTypes.func,
};

export default withRouter(LabelItem);
