import React from 'react';
import NoteForm from './note_form';

const PhoneCallForm = ({ ...props }) => (
  <NoteForm
    {...props}
    activityType="phone_call"
    submitButtonText="Log Phone Call"
  />
);

export default PhoneCallForm;
