import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ConfirmAlertBox({
  onConfirm, message, disabled, buttonTitle,
}) {
  return (
    <div className="alert-danger text-left p-3" role="alert">
      <h4 className="alert-heading">
        <FontAwesomeIcon icon={['fas', 'fa-exclamation-triangle']} size="xs" className="mr-1" />
        Heads Up! This action is non-reversible!
        <button type="button" className="btn btn-sm btn-danger pull-right px-5" onClick={onConfirm} disabled={disabled}>
          { buttonTitle || 'Confirm' }
        </button>
      </h4>
      <br />
      <p className="text-danger">
        { message }
      </p>
    </div>
  );
}
