import classNames          from 'classnames';
import React               from 'react';
import ReactPaginate       from 'react-paginate';
import { PropTypes }       from 'prop-types';
import { Container }       from 'flux/utils';
import Moment              from 'moment';
import OverlayTrigger      from 'react-bootstrap/OverlayTrigger';
import Tooltip             from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Humanize            from 'humanize-plus';

import APIRequest          from '~/lib/api_request';
import ReportActions       from '~/actions/report_actions';
import ReportStore         from '~/stores/report_store';

import Tooltipable         from '~/components/effects/tooltipable';
import ReportTable         from './report_card/report_table';
import DataTableSortLink   from './data_table/data_table_sort_link';

import Select from '~/components/forms/dropdowns/SelectWrapper/Select';
import { TeammatePicker } from '~/components/forms/team_member_picker';

// Constants
import { generateDateOptions } from '~/constants/dropdowns/MonthOptions';
import { REPORT_DATES_OPTIONS } from '~/constants/dropdowns/ReportDatesOptions';
import { PIPELINE_LEVEL_OPTIONS, ACTIVITY_LEVEL_OPTIONS } from '~/constants/dropdowns/ReportLevelOptions';

const activityTabColum = {
  num_of_agent_joind:     [],
  num_of_appoint_set:     [],
  num_of_appoint_logged:  [],
  sum_of_completed_tasks: [],
  num_of_email_sent:      [],
  num_of_text_sent:       [],
  num_of_calls_logged:    [],
  contacts_made:          [],
  recruiting_contacts:    [],
  pipeline_prod:          [],
  recruited_prod:         [],
};

const { currentTeam, currentUser } = Rails.helpers;
const staffRole = currentUser?.role?.name === 'staff';
const restrictRecruitingStaffRoleAccess = (currentTeam && currentUser) && (currentTeam.staff_role_access_in_recruiting === 'mine' && staffRole);

class ReportCard extends React.Component {
  static getStores() {
    return [ReportStore];
  }

  static calculateState(prevState) {
    const reportStoreState = ReportStore.getState();
    return {
      ...prevState,
      reportStoreState,
      lastPage:    reportStoreState.lastPage,
      currentPage: reportStoreState.currentPage,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      currentTab:                    'recruiting',
      tabName:                       'Pipeline',
      selectedDateFilter:            { value: 'this_month', label: 'This Month' },
      selectedLevelFilter:           { value: 'account', label: 'Account' },
      showTeams:                     false,
      tableSortField:                { column: 'name', title:  'Name' },
      tableSortDirection:            null,
      ownerID:                       restrictRecruitingStaffRoleAccess ? currentUser.id : null,
      selectedLevelFilterOnPipeline: { value: 'account', label: 'Account' },
      currentPage:                   1,
      recordsPerPage:                10,
      lastPage:                      1,
    };

    this.onTeamMemberChange          = this.onTeamMemberChange.bind(this);
    this.onJoinedMonthChange         = this.onJoinedMonthChange.bind(this);
    this.handleDownloadClick         = this.handleDownloadClick.bind(this);
    this.onSortToggle                = this.onSortToggle.bind(this);
    this.handleActivityDownloadClick = this.handleActivityDownloadClick.bind(this);
    this.handlePageClick             = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.fetchReportData();
  }

  handleLevelFilterOnPipeline = (selectedOption) => {
    if (!selectedOption?.value) {
      return;
    }

    const {
      joinedMonth, ownerID,
    } = this.state;
    const reportParams = {
      month: joinedMonth,
      level: selectedOption.value,
      ...(ownerID && { owner_id: ownerID }),
    };

    this.setState({ selectedLevelFilterOnPipeline: selectedOption }, () => {
      ReportActions.loadReport(reportParams);
    });
  }

  handlePageClick = (data) => {
    const selected = data.selected + 1; // Adjusting for ReactPaginate's 0-indexed pages
    this.setState({ currentPage: selected }, () => {
      this.fetchActivityData();
    });
  };

  handleRecordsPerPageChange = (e) => {
    const recordsPerPage = parseInt(e.target.value, 10);
    this.setState({ recordsPerPage, currentPage: 1 }, () => {
      this.fetchActivityData();
    });
  }

  handleDateFilter = (selectedOption) => {
    this.setState({ selectedDateFilter: selectedOption }, () => {
      this.fetchActivityData();
    });
  }

  handleLevelFilter = (selectedOption) => {
    const showTeams = selectedOption.value === 'organization';
    this.setState({ selectedLevelFilter: selectedOption, showTeams }, () => {
      this.fetchActivityData();
    });
  }

  handleDownloadClick(e) {
    e.preventDefault();

    this.setState({ downloading: true });

    const { ownerID, selectedLevelFilterOnPipeline } = this.state;
    let url;

    if (ownerID) {
      url = `${
        Rails.apiUrl
      }/api/v1/reports.xls?filters[owner_id]=${ownerID}&filters[level]=${selectedLevelFilterOnPipeline?.value}&auth=${
        Rails.helpers.authToken
      }`;
    } else {
      url = `${Rails.apiUrl}/api/v1/reports.xls?filters[level]=${selectedLevelFilterOnPipeline?.value}&auth=${
        Rails.helpers.authToken
      }`;
    }

    window.location = url;

    setTimeout(() => {
      this.setState({ downloading: false });
    }, 5000);
  }

  handleActivityDownloadClick(e) {
    e.preventDefault();

    const {
      selectedDateFilter, selectedLevelFilter,
    } = this.state;
    const date = selectedDateFilter?.value || 'this_month';
    const level = selectedLevelFilter?.value || 'account';

    const data = {
      level,
      auth:      Rails.helpers.authToken,
      timeframe: date,
      download:  true,
    };

    const params = new URLSearchParams(data);
    const url = `${Rails.apiUrl}/api/v1/activity.xls?${params.toString()}`;

    window.open(url, '_blank');
  }

  handleShareableClick(e) {
    e.preventDefault();
    const windowReference = window.open();

    APIRequest.get({ resource: '/v1/auth/token/report' }).end((err, res) => {
      if (res.body.token) {
        windowReference.location = `/r/pipeline?auth=${res.body.token}`;
      } else {
        GlobalContainer.error(
          'Unable to authenticate for reports, please contact support',
          'error',
        );
      }
    });
  }

  onTeamMemberChange(change) {
    const { joinedMonth, selectedLevelFilterOnPipeline } = this.state;
    if (change && change.value) {
      this.setState({
        ownerID: change.value,
      }, () => {
        ReportActions.loadReport({
          owner_id: change.value,
          month:    joinedMonth,
          level:    selectedLevelFilterOnPipeline.value,
        });
      });
    } else {
      this.setState({
        ownerID: null,
      }, () => {
        ReportActions.loadReport({
          month: joinedMonth,
          level: selectedLevelFilterOnPipeline.value,
        });
      });
    }
  }

  onJoinedMonthChange(month) {
    const { ownerID } = this.state;
    const value = month?.value || '';

    this.setState({
      joinedMonth: value,
    });

    ReportActions.loadJoinReport({
      owner_id: ownerID,
      month:    value,
    });
  }

  onSortToggle(sortField, sortDirection) {
    const {
      selectedDateFilter, selectedLevelFilter, currentPage, recordsPerPage,
    } = this.state;
    const date = selectedDateFilter?.value || 'this_month';
    const level = selectedLevelFilter?.value || 'account';

    this.setState({
      tableSortField:     sortField,
      tableSortDirection: sortDirection,
    });

    Object.keys(activityTabColum).forEach((key) => activityTabColum[key] = []);
    ReportActions.loadActivityData({
      timeframe: date, level, sort_column: `${sortField.column}_${sortDirection}`, page: currentPage, per_page: recordsPerPage,
    });
  }

  manageActivityTabs = (tabName) => {
    this.setState({ tabName }, () => { tabName === 'Activity' && this.renderActivityTableData(); });
  }

  showTeamDisplayStyle = () => {
    const { showTeams } = this.state;
    const showTeamStyle = showTeams ? 'table-cell' : 'none';

    return showTeamStyle;
  }

  sumOfTableRow = (tableRowData) => {
    const columns = {
      num_of_agent_joind:     'no_of_agents_joined',
      num_of_appoint_set:     'appt_set',
      num_of_appoint_logged:  'appt_logged',
      sum_of_completed_tasks: 'num_of_completed_tasks',
      num_of_email_sent:      'emails_sent',
      num_of_text_sent:       'messages_sent',
      num_of_calls_logged:    'calls_logged',
      contacts_made:          'contacts_made',
      recruiting_contacts:    'recruiting_contacts',
      pipeline_prod:          'pipeline_prod',
      recruited_prod:         'recruited_prod',
    };

    Object.keys(activityTabColum).forEach((key) => {
      if (tableRowData[columns[key]]) {
        activityTabColum[key].push(tableRowData[columns[key]]);
      }
    });
  }

  switchTab(tabKey) {
    this.setState({
      currentTab: tabKey,
    });
  }

  fetchReportData() {
    const { ownerID, selectedLevelFilterOnPipeline } = this.state;
    let params = ownerID ? { owner_id: ownerID } : {};
    params = {
      ...params,
      level: selectedLevelFilterOnPipeline.value,
    };

    ReportActions.loadReport(params);
  }

  fetchActivityData() {
    const {
      currentPage, recordsPerPage, lastPage, selectedDateFilter, selectedLevelFilter,
    } = this.state;

    const params = {
      page:      currentPage,
      per_page:  recordsPerPage,
      timeframe: selectedDateFilter.value,
      level:     selectedLevelFilter.value,
      last_page: lastPage,
    };

    ReportActions.loadActivityData(params);
  }

  fetchSpecialRecords() {
    // Joined, Signed
    const { recordGroups } = ReportStore.getState();
    const { currentTab } = this.state;
    const { 11: joinedRecords = [], 12: signedRecords = [], ...recruitingRecords } = recordGroups;

    const retentionTabs = [
      {
        title:    'Recruiting',
        key:      'recruiting',
        selected: currentTab === 'recruiting',
        records:  recruitingRecords,
      },
      {
        title:    'Onboarding',
        key:      'onboarding',
        selected: currentTab === 'onboarding',
        records:  signedRecords,
      },
      {
        title:    'Retention',
        key:      'retention',
        selected: currentTab === 'retention',
        records:  joinedRecords,
      },
    ];

    return retentionTabs;
  }

  renderFooter = () => {
    const { activityData, currentPage, lastPage } = ReportStore.getState();

    const noOwnerData = activityData.no_owner || {};

    const totals = {
      recruiting_contacts:    0,
      num_of_completed_tasks: 0,
      emails_sent:            0,
      calls_logged:           0,
      messages_sent:          0,
      contacts_made:          0,
      appt_set:               0,
      appt_logged:            0,
      no_of_agents_joined:    0,
      pipeline_prod:          0,
      recruited_prod:         0,
    };

    activityData.records.forEach((record) => {
      Object.keys(totals).forEach((key) => {
        const valueToAdd = Number(record[key]) || 0;
        totals[key] += valueToAdd;
      });
    });

    if (currentPage === lastPage && noOwnerData) {
      Object.keys(totals).forEach((key) => {
        const additionalValue = Number(noOwnerData[key]) || 0;
        totals[key] += additionalValue;
      });
    }

    return (
      <>
        <td align="center">-</td>
        <td align="center">-</td>
        <td align="center" style={{ display: this.showTeamDisplayStyle() }}>-</td>
        <td align="center">{totals.recruiting_contacts}</td>
        <td align="center">{totals.num_of_completed_tasks}</td>
        <td align="center">{totals.emails_sent}</td>
        <td align="center">{totals.calls_logged}</td>
        <td align="center">{totals.messages_sent}</td>
        <td align="center">{totals.contacts_made}</td>
        <td align="center">{totals.appt_set}</td>
        <td align="center">{totals.appt_logged}</td>
        <td align="center">{totals.no_of_agents_joined}</td>
        <td align="center">{totals.pipeline_prod}</td>
        <td align="center">{totals.recruited_prod}</td>
      </>
    );
  }

  renderTabContent() {
    const recruitTables = this.fetchSpecialRecords();
    const { loadingJoinReport } = ReportStore.getState();
    const { clickable } = this.props;
    const { selectedLevelFilterOnPipeline } = this.state;

    const tabContent = [];
    recruitTables.forEach((recruitTable) => {
      const tabPaneClass = classNames('table-responsive tab-pane', {
        active: recruitTable.selected,
      });

      let tabContentItem;
      if (loadingJoinReport && recruitTable.key === 'retention') {
        tabContentItem = (
          <div>
            <p className="text-center pt-5 pb-2">
              <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
            </p>
            <p className="text-center pb-5">Gathering report...</p>
          </div>
        );
      } else if (recruitTable.records && recruitTable.records.length > 0) {
        tabContentItem = (
          <ReportTable
            key={`report-table-${recruitTable.key}`}
            showRating={false}
            records={recruitTable.records}
            clickable={clickable}
            showOwner={selectedLevelFilterOnPipeline?.value === 'organization'}
          />
        );
      } else if (recruitTable.key === 'recruiting' && Object.keys(recruitTable.records).length > 0) {
        tabContentItem = this.renderRecordsList();
      } else {
        tabContentItem = (
          <div>
            <p className="text-center pt-5 pb-5">No Leads Found</p>
          </div>
        );
      }

      const tabContentWrapper = (
        <div className={tabPaneClass} key={`tab-pane-${recruitTable.key}`}>
          {tabContentItem}
        </div>
      );

      tabContent.push(tabContentWrapper);
    });

    return <div className="tab-content">{tabContent}</div>;
  }

  renderSpecialRecordsList() {
    const { loadingReport, recordGroups } = ReportStore.getState();

    if (loadingReport || !recordGroups || _lodash.isEmpty(recordGroups)) return null;

    return (
      <div className="card mt15 special-records">
        <div className="card-block pt0 pb0 mt10">{this.renderTabs()}</div>
        {this.renderTabContent()}
      </div>
    );
  }

  renderRecordsList() {
    const { loadingReport, recordGroups } = ReportStore.getState();
    const { clickable } = this.props;
    const { selectedLevelFilterOnPipeline } = this.state;

    if (loadingReport || !recordGroups || _lodash.isEmpty(recordGroups)) return null;

    const reportTables = [];
    for (let i = 10; i > 0; i -= 1) {
      const records = recordGroups[i.toString()];

      if (records && records.length > 0) {
        reportTables.push(
          <>
            <div className="table-responsive card mt15">
              <ReportTable
                key={`report-table-${i}`}
                rating={i}
                records={records}
                clickable={clickable}
                showOwner={selectedLevelFilterOnPipeline?.value === 'organization'}
              />
            </div>
          </>,
        );
      }
    }

    return reportTables;
  }

  renderRecruitingTabs = () => (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item">
        <a className="nav-link active" onClick={() => this.manageActivityTabs('Pipeline')} id="pipeLine-tab" data-toggle="tab" href="#pipeLine" role="tab" aria-controls="pipeLine" aria-selected="true">
          Pipeline
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={() => this.manageActivityTabs('Activity')} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
          Activity
        </a>
      </li>
    </ul>
  )

  renderActivtyTableRecord = () => {
    const { activityData, loadingActivity } = ReportStore.getState();
    const {
      tableSortDirection, tableSortField, lastPage, currentPage,
    } = this.state;

    return (
      (!_lodash.isEmpty(activityData) || tableSortDirection != null) ? (
        <>
          <table className="table activity-table table-hover">
            <thead>
              <tr>
                <th>
                  <Tooltipable text="The name of the user in the system">
                    <DataTableSortLink
                      sortField={{ column: 'name', title: 'Name' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th style={{ display: this.showTeamDisplayStyle() }}>
                  <Tooltipable text="The name of the account if it is linked in an organization. The accounts can be named by team, office, or region to segment the data accordingly">
                    <DataTableSortLink
                      sortField={{ column: 'teams', title: 'Account Name' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of times the user logged into the system during the filtered timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'logins', title: 'Logins' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The date on which the user last logged into the system">
                    <DataTableSortLink
                      sortField={{ column: 'last_seen_at', title: 'Last Login' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>

                <th>
                  <Tooltipable text="The number of leads assigned to the user in Recruiting">
                    <DataTableSortLink
                      sortField={{ column: 'recruiting_contacts', title: 'Recruiting Leads Assigned' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of tasks completed by the user during the filtered timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'num_of_completed_tasks', title: 'Completed Tasks' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of emails sent by the user during the filtered timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'emails_sent', title: 'Emails Sent' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of calls logged in the system by the user during the filtered timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'calls_logged', title: 'Calls Logged' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of text messages sent in the system by the user during the filtered timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'messages_sent', title: 'Text Messages Sent' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of contacts in recruiting assigned to the user where the status is Contact Made or beyond">
                    <DataTableSortLink
                      sortField={{ column: 'contacts_made', title: 'Contact Made' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of appointments set by the user for recruiting leads assigned during the filtered timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'appt_set', title: 'Appointments Set' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of appointments logged by the user for recruiting leads assigned during the filterd timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'appt_logged', title: 'Appointments Logged' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The number of contacts assigned to the user moved to Signed in Recruiting and then from Onboarding to another status in Retention in the filtered timeframe">
                    <DataTableSortLink
                      sortField={{ column: 'no_of_agents_joined', title: 'New Hires' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The total production in dollar terms in the last 12 months for the agents which are in this user’s pipeline in the Recruiting or Onboarding tab">
                    <DataTableSortLink
                      sortField={{ column: 'pipeline_prod', title: 'Pipeline Prod' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
                <th>
                  <Tooltipable text="The total production in dollar terms in the last 12 months for the agents hired during the filtered timeframe and showing in the New Hires column to the left">
                    <DataTableSortLink
                      sortField={{ column: 'recruited_prod', title: 'Total $ Prod' }}
                      onSortToggle={this.onSortToggle}
                      tableSortField={tableSortField}
                      tableSortDirection={tableSortDirection}
                    />
                  </Tooltipable>
                </th>
              </tr>
            </thead>
            {!loadingActivity && (
            <>
              <tbody>
                {this.renderTableRow(activityData)}
              </tbody>
              <tfoot>
                { (lastPage === currentPage) && (
                  <tr>
                    <td>No owner</td>
                      {this.renderFooterOfOwner(activityData)}
                  </tr>
                )}
                <tr>
                  <th className="text-black">Total</th>
                  {this.renderFooter()}
                </tr>
              </tfoot>
            </>
            )}
          </table>
          {loadingActivity && (
            <div className="w-100">
              <p className="text-center pt-5 pb-2">
                <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
              </p>

              <p className="text-center pb-5">Gathering data...</p>
            </div>
          )}
          <div>
            {this.renderPagination()}
          </div>
          <div>
            {this.renderPaginationControls()}
          </div>
        </>
      ) : (
        <div>
          <p className="text-center pt-5 pb-2">
            <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
          </p>
          <p className="text-center pb-5">Gathering data...</p>
        </div>
      )
    );
  }

  renderPagination() {
    const { reportStoreState } = this.state;
    if (!reportStoreState.activityData || !reportStoreState.activityData.pagy) {
      return null;
    }

    const { pagy } = reportStoreState.activityData;

    return (
      <div className="pl20 pr20 pt10 pb10">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            { `Showing ${pagy.from} to ${pagy.to} of ${pagy.total_items} users` }
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            { pagy.total_pages > 1 && (
              <nav>
                <ReactPaginate
                  forcePage={pagy.current_page - 1}
                  pageCount={pagy.total_pages}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={2}
                  containerClassName="pagination justify-content-end"
                  activeClassName="active"
                  breakLabel="..."
                  breakLinkClassName="page-link disabled"
                  breakClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  onPageChange={this.handlePageClick}
                  previousLabel="Previous"
                  nextLabel="Next"
                />
              </nav>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderPaginationControls() {
    const { recordsPerPage } = this.state;

    return (
      <form className="mt15 d-inline">
        <span className="mr5">Show</span>
        <select
          name="selectRecordsPerPage"
          defaultValue={recordsPerPage}
          onChange={this.handleRecordsPerPageChange}
          className="form-control d-inline-block w-auto aline-middle"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <span className="ml5">items per page</span>
      </form>
    );
  }

  renderFooterOfOwner = (activityData) => {
    const {
      no_of_agents_joined, appt_set, appt_logged, num_of_completed_tasks,
      emails_sent, messages_sent, calls_logged, recruiting_contacts, contacts_made,
      pipeline_prod, recruited_prod,
    } = { ...activityData.no_owner };

    this.sumOfTableRow(activityData.no_owner);

    return (
      <>
        <td align="center">-</td>
        <td align="center">-</td>
        <td align="center" style={{ display: this.showTeamDisplayStyle() }}>-</td>
        <td align="center">{recruiting_contacts}</td>
        <td align="center">{num_of_completed_tasks}</td>
        <td align="center">{emails_sent}</td>
        <td align="center">{calls_logged}</td>
        <td align="center">{messages_sent}</td>
        <td align="center">{contacts_made}</td>
        <td align="center">{appt_set}</td>
        <td align="center">{appt_logged}</td>
        <td align="center">{no_of_agents_joined}</td>
        <td align="center">{pipeline_prod}</td>
        <td align="center">{recruited_prod}</td>
      </>
    );
  }

  renderTableRow(activityData) {
    return (
      activityData.records.map((a, index) => {
        this.sumOfTableRow(a);

        const renderTooltip = (props) => (
          <Tooltip id="teams-tooltip" {...props}>
            {a.teams}
          </Tooltip>
        );

        return (
          <tr key={index}>
            <td>{a.name}</td>
            <OverlayTrigger overlay={renderTooltip}>
              <td className="text-truncate" style={{ maxWidth: '200px', display: this.showTeamDisplayStyle() }}>
                {a.teams}
              </td>
            </OverlayTrigger>
            <td align="center">{a.logins}</td>
            <td>{_lodash.isEmpty(a.last_seen_at) ? '' : Moment(a.last_seen_at).format('MM/DD/YY')}</td>
            <td align="center">{a.recruiting_contacts}</td>
            <td align="center">{a.num_of_completed_tasks}</td>
            <td align="center">{a.emails_sent}</td>
            <td align="center">{a.calls_logged}</td>
            <td align="center">{a.messages_sent}</td>
            <td align="center">{a.contacts_made}</td>
            <td align="center">{a.appt_set}</td>
            <td align="center">{a.appt_logged}</td>
            <td align="center">{a.no_of_agents_joined}</td>
            <td align="center">{a.pipeline_prod}</td>
            <td align="center">{a.recruited_prod}</td>
          </tr>
        );
      })
    );
  }

  renderTabs() {
    const { currentTab, joinedMonth } = this.state;
    const recruitTables = this.fetchSpecialRecords();

    const tabs = [];
    recruitTables.forEach((recruitTable) => {
      const navLinkClass = classNames('nav-link', {
        active: recruitTable.selected,
      });
      let recordCount;

      if (recruitTable.records && recruitTable.records.length > 0) {
        recordCount = (
          <span className="ml5 badge badge-green">
            {recruitTable.records.length}
          </span>
        );
      }

      if (recruitTable.key === 'recruiting' && Object.keys(recruitTable.records).length > 0) {
        recordCount = (
          <span className="ml5 badge badge-green">
            {Object.values(recruitTable.records).map((r) => r.length).reduce((prev, cur) =>  prev + cur)}
          </span>
        );
      }

      const tabMenu = (
        <li className="nav-item" key={`tab-${recruitTable.key}`}>
          <a
            href={`#tab_${recruitTable.key}`}
            className={navLinkClass}
            onClick={(e) => {
              e.preventDefault();
              this.switchTab(recruitTable.key);
            }}
          >
            {recruitTable.title}
            {' '}
            {recordCount}
          </a>
        </li>
      );

      tabs.push(tabMenu);
    });

    let monthPicker;
    const monthDate = joinedMonth
      ? Moment(joinedMonth)
      : Moment().startOf('month');
    if (currentTab === 'retention') {
      monthPicker = (
        <div className="pull-right col-lg-3 col-md-5 col-sm-12 my-2 p0">
          <Select
            id="joined_month"
            name="joined_month"
            options={generateDateOptions(Moment().subtract(20, 'months'), Moment())}
            value={monthDate.format('YYYY-MM-DD')}
            onChange={this.onJoinedMonthChange}
            isSearchable
          />
        </div>
      );
    }

    return (
      <div className="nav-tabs-wrapper">
        <ul className="nav nav-tabs">{tabs}</ul>
        {monthPicker}
      </div>
    );
  }

  renderSummary() {
    const { loadingReport, summaryCount, summaryYTD } = ReportStore.getState();

    if (loadingReport) {
      return (
        <div>
          <p className="text-center pt-5 pb-2">
            <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
          </p>

          <p className="text-center pb-5">Gathering report...</p>
        </div>
      );
    }

    return (
      <div className="card-block">
        <p>
          This month
          {' '}
          <strong>{summaryCount}</strong>
          {' '}
          new agents are joining,
          with the last 12 month total production of
          {' '}
          <strong>
            $
            {Humanize.compactInteger(summaryYTD, 1)}
          </strong>
        </p>
      </div>
    );
  }

  renderActivityHtml = () => {
    const { selectedDateFilter, selectedLevelFilter } = this.state;

    return (
      <>
        <div className="card-block row pt0 pb0">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <label htmlFor="filter_by_date">Filter by date </label>
            <Select
              id="filter_by_date"
              value={selectedDateFilter.value}
              options={REPORT_DATES_OPTIONS}
              onChange={(opt) => this.handleDateFilter(opt)}
              placeholder="--- Select ---"
              isSearchable
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <label htmlFor="filter_by_level">Filter by level</label>
            <Select
              id="filter_by_level"
              value={selectedLevelFilter.value}
              options={ACTIVITY_LEVEL_OPTIONS}
              onChange={(opt) => this.handleLevelFilter(opt)}
              placeholder="--- Select ---"
              isSearchable
            />
          </div>
        </div>

        <div className="table-responsive card-block">
          {this.renderActivtyTableRecord()}
        </div>
      </>
    );
  }

  renderActivityTableData = () => {
    const { selectedDateFilter, selectedLevelFilter } = this.state;
    const date = selectedDateFilter?.value || 'this_month';
    const level = selectedLevelFilter?.value || 'account';

    ReportActions.loadActivityData({ timeframe: date, level });

    Object.keys(activityTabColum).forEach((key) => activityTabColum[key] = []);
  }

  render() {
    const { shareable } = this.props;
    const { downloading, ownerID, tabName } = this.state;
    const { selectedLevelFilterOnPipeline } = this.state;

    let downloadButton = (
      <a
        className="btn btn-sm btn-secondary mr5"
        href="#download-pipeline"
        onClick={this.handleDownloadClick}
      >
        <span>
          <FontAwesomeIcon icon={['fas', 'fa-download']} />
          {' '}
          Download Pipeline.xls
        </span>
      </a>
    );

    const downloadActivityButton = (
      <a
        className="btn btn-sm btn-secondary mr5"
        href="#download-pipeline"
        onClick={this.handleActivityDownloadClick}
      >
        <span>
          <FontAwesomeIcon icon={['fas', 'fa-download']} />
          {' '}
          Download Report
        </span>
      </a>
    );

    if (downloading) {
      downloadButton = (
        <a className="btn btn-sm btn-secondary disabled mr5">
          <span>
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
            {' '}
            Downloading...
          </span>
        </a>
      );
    }

    return (
      <>
        <div className="card-block pt0 pb0 mt10">
          {this.renderRecruitingTabs()}
        </div>

        { tabName === 'Activity' && (
          <div className="tab-pane " id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div className="card">
              <div className="card-block">
                <div className="pull-right">
                  {downloadActivityButton}
                  {shareable && !restrictRecruitingStaffRoleAccess && (
                    <a
                      className="btn btn-sm btn-secondary"
                      onClick={this.handleShareableClick}
                    >
                      <FontAwesomeIcon icon={['fas', 'fa-share-square']} />
                      {' '}
                      Get shareable link
                    </a>
                  )}
                </div>
                <h2 className="d-inline-flex mt-3 mt-md-0">Recruiting Activity Dashboard</h2>
              </div>
              {this.renderActivityHtml()}
            </div>
          </div>
        )}

        { tabName === 'Pipeline' && (
          <div className="tab-content">
            <div className="card">
              <div className="tab-pane fade show active" id="pipeLine" role="tabpanel" aria-labelledby="pipeLine-tab">
                <div className="card-block">
                  <div className="pull-right">
                    {downloadButton}
                    {shareable && !restrictRecruitingStaffRoleAccess && (
                      <a
                        className="btn btn-sm btn-secondary"
                        onClick={this.handleShareableClick}
                      >
                        <FontAwesomeIcon icon={['fas', 'fa-share-square']} />
                        {' '}
                        Get shareable link
                      </a>
                    )}
                  </div>
                  <h2 className="d-inline-flex mt-3 mt-md-0">Live Pipeline Report</h2>
                </div>

                <div className="card-block row pt0 pb0">
                  <div className="col-sm-12 col-md-6 col-lg-3">
                    <label htmlFor="owner_id">Filter by team member</label>
                    <TeammatePicker
                      id="owner_id"
                      placeholder="Filter by team members"
                      clearable
                      value={ownerID}
                      disabled={restrictRecruitingStaffRoleAccess}
                      onChange={this.onTeamMemberChange}
                      level={selectedLevelFilterOnPipeline?.value}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3">
                    <label htmlFor="filter_by_level">Filter by level </label>
                    <Select
                      id="filter_by_level"
                      value={selectedLevelFilterOnPipeline?.value}
                      options={PIPELINE_LEVEL_OPTIONS}
                      onChange={(opt) => this.handleLevelFilterOnPipeline(opt)}
                      placeholder="--- Select ---"
                    />
                  </div>
                </div>
                {this.renderSummary()}
              </div>
            </div>
            {this.renderSpecialRecordsList()}
          </div>
        )}
      </>
    );
  }
}

ReportCard.defaultProps = {
  clickable:    true,
  downloadable: true,
  shareable:    true,
  code:         null,
};

ReportCard.propTypes = {
  clickable:    PropTypes.bool,
  downloadable: PropTypes.bool,
  shareable:    PropTypes.bool,
  code:         PropTypes.string,
};

export default Container.create(ReportCard);
