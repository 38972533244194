import React, { useState }     from 'react';
import { PropTypes }           from 'prop-types';
import { Editor }              from '@tinymce/tinymce-react';
import { v4 as uuidv4 }        from 'uuid';
import AutoComplete            from '~/components/forms/HtmlEditors/mentions/AutoComplete';
import { TINYMCE_SCRIPT_PATH } from '~/constants/TinyMCE';

const MentionsEditor = ({
  id: propId,
  init: propInit,
  onKeyPress,
  ...otherProps
}) => {
  const [activityType] = useState(otherProps.activityType);
  const [id] = useState(propId || uuidv4());
  const [autoComplete, setAutoComplete] = useState(null);

  const handleOnKeyPress = (e) => {
    const delimiterIndex = ['@'].indexOf(String.fromCharCode(e.which || e.keyCode));

    onKeyPress();

    if (delimiterIndex > -1 && prevCharIsSpace()) {
      if (autoComplete == null || (autoComplete.hasFocus !== undefined && !autoComplete.hasFocus)) {
        e.preventDefault();
        setAutoComplete(new AutoComplete(tinymce.get(id), {}, activityType));
      }
    }
  };

  const prevCharIsSpace = () => {
    const ed = tinymce.get(id);
    const range = ed.selection.getRng(true);
    const text = range.startContainer.data || '';
    const character = text.charAt(Math.max(range.startOffset - 1, 0));

    return /^\s*$/.test(character);
  };

  // editor.on('keydown', (e) => {
  //   if (e.keyCode === 40 || e.keyCode === 13 || e.keyCode === 38) {
  //     console.log('prevent default');
  //     e.preventDefault();
  //   }
  // });

  return (
    <Editor
      {...otherProps}
      tinymceScriptSrc={TINYMCE_SCRIPT_PATH}
      scriptLoading={{ async: true }}
      init={propInit}
      id={id}
      onKeyPress={handleOnKeyPress}
    />
  );
};

MentionsEditor.defaultProps = {
  id:               '',
  content:          '',
  onKeyPress:       () => false,
  activityType:     'note',
};

MentionsEditor.propTypes = {
  id:               PropTypes.string,
  value:            PropTypes.string.isRequired,
  onEditorChange:   PropTypes.func.isRequired,
  onKeyPress:       PropTypes.func,
  content:          PropTypes.string,
  init:             PropTypes.shape({}).isRequired,
  onInit:           PropTypes.func.isRequired,
  activityType:     PropTypes.string,
};

export default MentionsEditor;
