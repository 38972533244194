import React                from 'react';
import PropTypes            from 'prop-types';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { captureEvent }     from '~/helpers/capture_posthog_event';

class LimitExceededWarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sms_limit:         Rails.subscription_limit.sms_limit,
      pricing_plan_name: Rails.subscription.pricing_plan_name,
    };
  }

  componentDidMount() {
    captureEvent('alerts on texting over the limit');
  }

  render() {
    const { sms_limit, pricing_plan_name } = this.state;
    const {
      selectedItemsCount, smsStats, segmentsCount, onWarningAccepted, textMessagesCount,
    } = this.props;
    const totalSegments = segmentsCount * selectedItemsCount;
    const exceedingLimit = totalSegments + (smsStats?.thisMonth ?? 0) - sms_limit;

    return (
      <div className="alert alert-warning mb-1">
        <div className="d-flex mb-2">
          <div className="text-warning mt-2">
            <FontAwesomeIcon icon={['fas', 'fa-exclamation-triangle']} className="fa-lg" />
          </div>
          <h4 className="text-left mt-2 ml-2"> Heads Up! Additional Fees Ahead! </h4>
          <button type="button" className="btn btn-sm alert-btn-warning ml-auto" onClick={onWarningAccepted}><p className="text-white font-weight-bold">Confirm</p></button>
        </div>
        <p className="text-left">
          You&apos;re about to send&nbsp;
          {textMessagesCount}
          &nbsp;text message(s) with&nbsp;
          <span className="font-weight-bold">
            {segmentsCount}
            &nbsp;text segment(s)
          </span>
          &nbsp;to&nbsp;
          <span className="font-weight-bold">
            {selectedItemsCount}
            &nbsp;contact(s)&nbsp;
          </span>
          which will surpass the texting allocation for your&nbsp;
          <span className="font-weight-bold">
            pricing plan&nbsp;
            {pricing_plan_name}
          </span>
          &nbsp;with an&nbsp;
          <span className="font-weight-bold">
            overage of&nbsp;
            {exceedingLimit}
            &nbsp;segment(s)
          </span>
          &nbsp;and generate texting usage fees. Please confirm to proceed with the text message. You can review your billing page&nbsp;
          <span className="font-weight-bold">
            <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">here</a>
          </span>
          &nbsp;to see your existing usage and/or upgrade your pricing plan or email us at support@getbrokerkit.com with questions.
        </p>
      </div>
    );
  }
}

LimitExceededWarning.defaultProps = {
  segmentsCount:      0,
  smsStats:           {},
  selectedItemsCount: 0,
  textMessagesCount:  1,
  onWarningAccepted:  () => {},
};

LimitExceededWarning.propTypes = {
  segmentsCount:      PropTypes.number,
  smsStats:           PropTypes.shape({}),
  selectedItemsCount: PropTypes.number,
  textMessagesCount:  PropTypes.number,
  onWarningAccepted:  PropTypes.func,
};

export default LimitExceededWarning;
