import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind            from 'react-autobind';
import { v4 as uuidv4 }    from 'uuid';
import ReactPaginate       from 'react-paginate';
import Tooltipable         from '~/components/effects/tooltipable';

import VideoActions from '~/actions/video_actions';
import VideoStore   from '~/stores/video_store';
import VideoItem    from './VideoItem';

import { ConsolidatedUsersWithInternalRolePicker } from '~/components/forms/team_member_picker';

const { currentUser } = Rails.helpers;

class VideoLibrary extends React.Component {
  search = _lodash.debounce(() => {
    const { search } = this.state;

    VideoActions.loadVideos({
      search: {
        query:   encodeURIComponent(search.title),
        user_id: search.user_id,
      },
    });
  }, 850)

  constructor(props) {
    super(props);

    this.state = {
      videos:        [],
      selectedVideo: null,
      search:        {
        title:   '',
        user_id: currentUser?.id,
      },
    };

    autoBind(this);
  }

  componentDidMount() {
    const { search } = this.state;

    this.videosStoreListener = VideoStore.addListener(this.onVideoStoreChange);

    VideoActions.loadVideos({
      search: {
        query:   encodeURIComponent(search.title),
        user_id: search.user_id,
      },
    });
  }

  componentWillUnmount() {
    if (this.videosStoreListener) this.videosStoreListener.remove();
  }

  onVideoStoreChange() {
    const {
      loadingVideos,
      videos,
      pagination,
      videosLimit,
      countOfVideos,
      lastVideoStoreAction,
    } = VideoStore.getState();

    if (lastVideoStoreAction === 'deleteVideoDone') {
      GlobalContainer.notify('Video was deleted');
    }

    this.setState({
      pagination,
      loadingVideos,
      videosLimit,
      countOfVideos,
      videos,
    });
  }

  onPageChange(page) {
    const { search } = this.state;
    const selectedPage = page.selected;

    VideoActions.loadVideos({
      page:   selectedPage + 1,
      search: {
        query:   encodeURIComponent(search.title),
        user_id: search.user_id,
      },
    });
  }

  handleInsertVideo = (e) => {
    e.preventDefault();

    const { selectedVideo, videos } = this.state;
    const { editor, closeModal, sms } = this.props;
    const video = _lodash.find(videos, (t) => t.id === selectedVideo);

    if (editor && !!video) {
      const thumbnail = video?.animated_thumbnail ? video.gif_thumbnail : video.thumbnail;

      if (!sms) {
        editor.insertContent(
          `<a href='/video/${video.uuid}' target='_blank'><img src=${thumbnail} /></a>`,
        );
      } else {
        editor.insertContent(
          `${process.env.WEB_APP_URL}/video/${video.uuid}`,
        );
      }

      closeModal();
    }
  }

  handleSearchChange = (e) => {
    const query = e.target.value || '';

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        title: query,
      },
    }));

    this.search();
  }

  handleSearchKeyDown = (e) => {
    const { search } = this.state;

    if (e.key === 'Enter') {
      VideoActions.loadVideos({
        search: {
          query:   encodeURIComponent(search.title),
          user_id: search.user_id,
        },
      });
    }
  }

  handleUserIdChange = (opt) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        user_id: opt?.value,
      },
    }));

    this.search();
  }

  selectVideo = (e) => {
    this.setState({ selectedVideo: Number(e.target.value) });
  }

  renderPagination = () => {
    const {
      loadingVideos, videos, pagination,
    } = this.state;

    if (_lodash.isEmpty(videos) || loadingVideos) return null;

    return (
      <div className="col">
        { pagination.total_pages > 1
          && (
            <ReactPaginate
              forcePage={pagination.current_page - 1}
              pageCount={pagination.total_pages}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-end"
              activeClassName="active"
              breakLabel="..."
              breakLinkClassName="page-link disabled"
              breakClassName="page-item"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              onPageChange={this.onPageChange}
              previousLabel={Rails.isMobile ? '<' : 'Previous'}
              nextLabel={Rails.isMobile ? '>' : 'Next'}
            />
          )}
      </div>
    );
  }

  renderCount = () => {
    const {
      loadingVideos,
      videosLimit,
      countOfVideos,
    } = this.state;
    const { currentTeam } = Rails.helpers;

    if (loadingVideos) return null;

    const count = countOfVideos || 0;
    const limits = videosLimit || 'unlimited';
    const text = currentTeam.organization_id ? 'organization' : 'account';

    return (
      <div className="col my-auto">
        {count}
        {' '}
        of
        {' '}
        {limits}
        {' '}
        in your
        {' '}
        {text}
      </div>
    );
  }

  renderSearchForm = () => {
    const { search } = this.state;

    return (
      <div className="row mb-3">
        <div className="col">
          <input
            ref={(input) => (this.queryInput = input)}
            type="text"
            className="form-control"
            placeholder="Search a Video"
            value={search.title}
            onChange={this.handleSearchChange}
            onKeyDown={this.handleSearchKeyDown}
          />
        </div>

        <div className="col">
          <ConsolidatedUsersWithInternalRolePicker
            id="video_user_id"
            placeholder="--- Filter by User ---"
            clearable
            value={search.user_id}
            onChange={(opt) => this.handleUserIdChange(opt)}
          />
        </div>
      </div>
    );
  }

  renderVideos = () => {
    const {
      videos,
      loadingVideos,
      pagination,
      search,
      selectedVideo,
    } = this.state;

    if (loadingVideos) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (_lodash.isEmpty(videos)) {
      return (
        <>
          <div className="text-grey-dark text-center">There are no videos.</div>
        </>
      );
    }

    return (
      <div id="videos-container" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        {videos.map((v) => (
          <VideoItem
            pagination={pagination}
            search={search}
            video={v}
            key={uuidv4()}
            selectedVideo={selectedVideo}
            selectVideo={this.selectVideo}
          />
        ))}
      </div>
    );
  }

  render() {
    const { selectedVideo, videos } = this.state;
    const { onChooseView, sms } = this.props;
    const video = _lodash.find(videos, (t) => t.id === selectedVideo);
    const thumbnail = video?.gif_thumbnail || video?.thumbnail;
    const tooltipText = 'Note: The hyperlink to your video will be automatically shortened when the text is delivered. This ensures a cleaner, more user-friendly experience for the recipient.';

    return (
      <>
        <div className="modal-body">
          {this.renderSearchForm()}
          {this.renderVideos()}

          <div className="px-2 pt-3">
            <div className="row">
              {this.renderCount()}
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-secondary" onClick={(e) => onChooseView(e, 'recordVideo')}>
            Back
          </button>

          <div>
            {sms ? (
              <Tooltipable placement="bottom" text={tooltipText}>
                <button
                  type="submit"
                  className={`btn btn-primary ${selectedVideo && thumbnail ? '' : 'disabled'}`}
                  onClick={selectedVideo && thumbnail ? this.handleInsertVideo : undefined}
                  disabled={!selectedVideo || !thumbnail}
                >
                  Insert video
                </button>
              </Tooltipable>
            ) : (
              <button
                type="submit"
                className={`btn btn-primary ${selectedVideo && thumbnail ? '' : 'disabled'}`}
                onClick={selectedVideo && thumbnail ? this.handleInsertVideo : undefined}
                disabled={!selectedVideo || !thumbnail}
              >
                Insert video
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

VideoLibrary.defaultProps = {
  onChooseView: () => false,
  closeModal:   () => false,
  sms:          false,
  editor:       {},
};

VideoLibrary.propTypes = {
  onChooseView: PropTypes.func,
  closeModal:   PropTypes.func,
  sms:          PropTypes.bool,
  editor:       PropTypes.shape({}),
};

export default VideoLibrary;
