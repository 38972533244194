import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import BlankPage                from '~/components/pages/blank_page';
import FrontSideBar             from  '~/components/navigation/front_side_bar';
import ComposingCampaignTrigger from './manage_campaign_triggers/composing_campaign_trigger';

class NewOrEditCampaignTrigger extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    const { match } = this.props;

    return (
      <BlankPage title="Campaign Triggers - Brokerkit">
        <div className="row mb15">
          <FrontSideBar />

          <div className="right-side-page-md">
            <ComposingCampaignTrigger triggerID={match.params.id} />
          </div>
        </div>
      </BlankPage>
    );
  }
}

NewOrEditCampaignTrigger.defaultProps = {};

NewOrEditCampaignTrigger.propTypes = {
  match:  PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default NewOrEditCampaignTrigger;
