const SmsTemplateActionTypes = {
  LOAD_SMS_TEMPLATES:       'LOAD_SMS_TEMPLATES',
  LOAD_SMS_TEMPLATES_DONE:  'LOAD_SMS_TEMPLATES_DONE',
  LOAD_SMS_TEMPLATES_FAIL:  'LOAD_SMS_TEMPLATES_FAIL',

  LOAD_SMS_TEMPLATE:        'LOAD_SMS_TEMPLATE',
  LOAD_SMS_TEMPLATE_DONE:   'LOAD_SMS_TEMPLATE_DONE',
  LOAD_SMS_TEMPLATE_FAIL:   'LOAD_SMS_TEMPLATE_FAIL',

  CREATE_SMS_TEMPLATE:      'CREATE_SMS_TEMPLATE',
  CREATE_SMS_TEMPLATE_DONE: 'CREATE_SMS_TEMPLATE_DONE',
  CREATE_SMS_TEMPLATE_FAIL: 'CREATE_SMS_TEMPLATE_FAIL',

  UPDATE_SMS_TEMPLATE:      'UPDATE_SMS_TEMPLATE',
  UPDATE_SMS_TEMPLATE_DONE: 'UPDATE_SMS_TEMPLATE_DONE',
  UPDATE_SMS_TEMPLATE_FAIL: 'UPDATE_SMS_TEMPLATE_FAIL',

  DELETE_SMS_TEMPLATE:      'DELETE_SMS_TEMPLATE',
  DELETE_SMS_TEMPLATE_DONE: 'DELETE_SMS_TEMPLATE_DONE',
  DELETE_SMS_TEMPLATE_FAIL: 'DELETE_SMS_TEMPLATE_FAIL',
};

export default SmsTemplateActionTypes;
