import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppModal from '../app_modal';
import useAIAssist from './hooks/useAIAssist';
import TokenLimitExceededCTA from './ui/TokenLimitExceededCTA';
import ModalContent from './ui/ModalContent';
import SelectPromptType from './ui/SelectPromptType';
import APIRequest from '~/lib/api_request';

const AssistModal = ({
  containerID,
  modalClass,
  dialogClass,
  editor,
  mode,
  setSubject,
}) => {
  const {
    state,
    dispatch,
    appModalRef,
    stateRef,
    markdownContentRef,
    handleInputChange,
    handlePromptClick,
    handleNewPrompt,
    handleRevisePrompt,
    handleInsert,
    handleGenerateText,
    handleRegenerate,
    handleRetry,
    fetchTokensUsage,
  } = useAIAssist(editor, mode, setSubject);
  const [tokensLimitExceeded, setTokensLimitExceeded] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [options, setOptions] = useState([]);
  const tokensLimitExceededClass = tokensLimitExceeded ? '' : 'modal-lg';

  useEffect(() => {
    stateRef.current = state;
    setTokensLimitExceeded(
      stateRef.current.subscriptionLimit !== null
      && stateRef.current.totalTokensUsed >= stateRef.current.subscriptionLimit,
    );
  }, [state]);

  useEffect(() => {
    fetchTokensUsage();
  }, []);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const fetchOptions = useCallback(() => {
    APIRequest.get({ resource: '/v1/ai_prompt_types' }).end((error, response) => {
      if (!error) {
        setOptions(response.body);
      }
    });
  }, []);

  const ModalHeader = (text, displayPromptSelector = true) => (
    <div className="modal-header">
      <h5 className="modal-title my-auto" id="bkAIGenerator">
        {text}
      </h5>

      {displayPromptSelector && (
        <div className="col-md-3 col-7 ml-auto ml-auto">
          <SelectPromptType
            onTypeSelected={setSelectedType}
            selectedType={selectedType}
            options={options}
          />
        </div>
      )}

      <button
        type="button"
        className="close mx-0 my-auto p0"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );

  return (
    <AppModal
      containerID={containerID}
      modalClass={modalClass}
      dialogClass={`${dialogClass} ${tokensLimitExceededClass}`}
      ref={appModalRef}
    >
      {(() => {
        if (state.isLoading) {
          return (
            <>
              {ModalHeader('AI Assist', false)}
              <div className="modal-body">
                <div className="text-center">
                  <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
                </div>
              </div>
            </>
          );
        } if (tokensLimitExceeded) {
          return (
            <>
              {ModalHeader('Upgrade Now For More AI Tokens! 🚀', false)}
              <TokenLimitExceededCTA state={state} />
            </>
          );
        }

        return (
          <>
            {ModalHeader('AI Assist')}
            <ModalContent
              state={state}
              dispatch={dispatch}
              markdownContentRef={markdownContentRef}
              handleInputChange={handleInputChange}
              handlePromptClick={handlePromptClick}
              handleGenerateText={handleGenerateText}
              handleInsert={handleInsert}
              handleRegenerate={handleRegenerate}
              handleRevisePrompt={handleRevisePrompt}
              handleNewPrompt={handleNewPrompt}
              handleRetry={handleRetry}
              selectedType={selectedType}
            />
          </>
        );
      })()}
    </AppModal>
  );
};

AssistModal.defaultProps = {
  containerID: '',
  modalClass:  '',
  dialogClass: '',
  editor:      {},
  mode:        'html',
  setSubject:  () => false,
};

AssistModal.propTypes = {
  containerID: PropTypes.string,
  modalClass:  PropTypes.string,
  dialogClass: PropTypes.string,
  editor:      PropTypes.shape({}),
  mode:        PropTypes.string,
  setSubject:  PropTypes.func,
};

export default AssistModal;
