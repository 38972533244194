import { ReduceStore } from 'flux/utils';
import AppDispatcher   from '~/dispatchers/app_dispatcher';
import TaskActionTypes from '~/types/task_action_types';

class TaskStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingTasks:        false,
      loadingTask:         false,
      tasks:               [],
      task:                null,
      lastTaskStoreAction: null,
      errors:              {},
      submitting:          false,
      pagination:          {},
    };
  }

  loadTasks(state, action) {
    const newState = { ...state };

    newState.loadingTasks = true;
    newState.lastTaskStoreAction = 'loadTasks';

    return newState;
  }

  loadTasksDone(state, action) {
    const newState = { ...state };

    newState.loadingTasks = false;
    newState.tasks        = action.tasks.data;
    newState.pagination   = action.tasks.pagination;
    newState.lastTaskStoreAction = 'loadTasksDone';

    return newState;
  }

  loadTasksFail(state, action) {
    const newState = { ...state };

    newState.loadingTasks = false;
    newState.errors = action.error.response.body.errors;
    newState.lastTaskStoreAction = 'loadTaskFail';

    return newState;
  }

  loadTask(state, action) {
    const newState = { ...state };

    newState.loadingTask = true;
    newState.lastTaskStoreAction = 'loadTask';

    return newState;
  }

  loadTaskDone(state, action) {
    const newState = { ...state };

    newState.loadingTask = false;
    newState.task = action.task;
    newState.lastTaskStoreAction = 'loadTaskDone';

    return newState;
  }

  loadTaskFail(state, action) {
    const newState = { ...state };

    newState.loadingTask = false;
    newState.errors = action.errors;
    newState.lastTaskStoreAction = 'loadTaskFail';

    return newState;
  }

  createTask(state, action) {
    const newState = { ...state };

    newState.lastTaskStoreAction = 'createTask';

    return newState;
  }

  createTaskDone(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.task      = action.task;
    newState.errors     = {};
    newState.lastTaskStoreAction = 'createTaskDone';

    return newState;
  }

  createTaskFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.errors     = action.error.response.body.errors;
    newState.lastTaskStoreAction = 'createTaskFail';

    return newState;
  }

  updateTask(state, action) {
    const newState = { ...state };

    newState.lastTaskStoreAction = 'updateTask';

    return newState;
  }

  updateTaskDone(state, action) {
    const newState = { ...state };

    newState.task  = action.task;
    newState.errors = {};
    newState.lastTaskStoreAction = 'updateTaskDone';

    return newState;
  }

  updateTaskFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body.errors;
    newState.lastTaskStoreAction = 'updateTaskFail';

    return newState;
  }

  deleteTask(state, action) {
    const newState = { ...state };

    newState.lastTaskStoreAction = 'deleteTask';

    return newState;
  }

  deleteTaskDone(state, action) {
    const newState = { ...state };

    newState.lastTaskStoreAction = 'deleteTaskDone';

    return newState;
  }

  deleteTaskFail(state, action) {
    const newState = { ...state };

    newState.lastTaskStoreAction = 'deleteTaskFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case TaskActionTypes.LOAD_TASKS:

        return this.loadTasks(state, action);
      case TaskActionTypes.LOAD_TASKS_DONE:

        return this.loadTasksDone(state, action);
      case TaskActionTypes.LOAD_TASKS_FAIL:

        return this.loadTasksFail(state, action);
      case TaskActionTypes.LOAD_TASK:

        return this.loadTask(state, action);
      case TaskActionTypes.LOAD_TASK_DONE:

        return this.loadTaskDone(state, action);
      case TaskActionTypes.LOAD_TASK_FAIL:

        return this.loadTaskFail(state, action);
      case TaskActionTypes.CREATE_TASK:

        return this.createTask(state, action);
      case TaskActionTypes.CREATE_TASK_DONE:

        return this.createTaskDone(state, action);
      case TaskActionTypes.CREATE_TASK_FAIL:

        return this.createTaskFail(state, action);
      case TaskActionTypes.UPDATE_TASK:

        return this.updateTask(state, action);
      case TaskActionTypes.UPDATE_TASK_DONE:

        return this.updateTaskDone(state, action);
      case TaskActionTypes.UPDATE_TASK_FAIL:

        return this.updateTaskFail(state, action);

      case TaskActionTypes.DELETE_TASK:
        return this.deleteTask(state, action);

      case TaskActionTypes.DELETE_TASK_DONE:
        return this.deleteTaskDone(state, action);

      case TaskActionTypes.DELETE_TASK_FAIL:
        return this.deleteTaskFail(state, action);

      default:
        return state;
    }
  }
}

export default new TaskStore();
