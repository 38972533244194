/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const countUnreadNotifications = /* GraphQL */ `
  query CountUnreadNotifications($UserID: Int!, $TeamID: Int!) {
    countUnreadNotifications(UserID: $UserID, TeamID: $TeamID)
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $UserID: Int!
    $TeamID: Int!
    $showOnlyUnread: Boolean
    $Type: String
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(
      UserID: $UserID
      TeamID: $TeamID
      showOnlyUnread: $showOnlyUnread
      Type: $Type
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        UserID
        TeamID
        Type
        IconType
        IsRead
        Title
        Content
        OriginatingObjectID
        OriginatingObjectType
        LeadID
        LeadName
        RecruitingStatus
        InboxConversationID
        InboxMessageID
        CreatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
