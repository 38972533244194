import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class VerifiedTextingPlanSelect extends React.Component {
  constructor(props) {
    super(props);

    const options = [
      { value: 'starter', label: 'less than 6000 text segments per day' },
      { value: 'standard', label: 'more than 6000 text segments per day' },
    ];

    this.state = {
      options,
    };

    autoBind(this);
  }

  render() {
    const { options } = this.state;
    const { value, onChange } = this.props;

    return (
      <Select
        {...this.props}
        value={value}
        onChange={onChange}
        options={options}
      />
    );
  }
}

VerifiedTextingPlanSelect.defaultProps = {
  onChange: () => {},
  value:    '',
};

VerifiedTextingPlanSelect.propTypes = {
  onChange: PropTypes.func,
  value:    PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
};

export default VerifiedTextingPlanSelect;
