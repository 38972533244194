import React            from 'react';
import { PropTypes }    from 'prop-types';
import { Tooltip }      from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: `tooltip-${uuidv4()}`,
    };
  }

  render() {
    const { id, showTooltip } = this.state;
    const { user, size } = this.props;

    if (!user || !user.avatar) {
      return null;
    }

    const avatar = user.avatar[size];

    return (
      <span>
        <img
          className="rounded-circle mr5"
          id={id}
          onMouseEnter={() => {
            this.setState({ showTooltip: true });
          }}
          onMouseLeave={() => {
            this.setState({ showTooltip: false });
          }}
          src={avatar}
          alt={user.name}
        />
        {showTooltip && (
          <Tooltip
            placement="top"
            isOpen={showTooltip}
            target={id}
          >
            {user.name}
          </Tooltip>
        )}
      </span>
    );
  }
}

UserAvatar.defaultProps = {
  user:    null,
  size:    'small',
  tooltip: true,
};

UserAvatar.propTypes = {
  user:    PropTypes.shape({}),
  size:    PropTypes.string,
  tooltip: PropTypes.bool,
};

export default UserAvatar;
