import autoBind            from 'react-autobind';
import React               from 'react';
import shadowRoot          from 'react-shadow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TimelineActivity from '~/components/drawers/timeline/activity';
import ActivityEditor   from '~/components/forms/HtmlEditors/ActivityEditor';

class TimelineActivityRetention extends TimelineActivity {
  constructor(props) {
    super(props);

    const { activity } = this.props;

    autoBind(this);
  }

  renderView() {
    const { activity } = this.state;

    return (
      <div>
        <p className="text-grey mb5">
          on
          {' '}
          {Moment(activity.occurred_at).format('dddd, MMMM Do YYYY, h:mm a')}
        </p>

        {(activity.body && (
          <shadowRoot.div className="form-control font-activity">
            <div className="content" dangerouslySetInnerHTML={{ __html: activity.body || '' }} />
          </shadowRoot.div>
        ))}
      </div>
    );
  }

  renderForm() {
    const { activity } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <ActivityEditor
          value={activity.body}
          onChange={(html) => {
            this.state.queuedChanges.body = html;
          }}
          placeholder="Enter Text"
        />
        <div className="mt5">
          <button type="submit" className="btn btn-primary btn-sm mr5">
            { this.state.sending ? (
              <span>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Saving
              </span>
            ) : 'Save' }
          </button>
          <a href="#" onClick={() => { this.setState({ queuedChanges: {}, editing: false }); }} className="btn btn-secondary btn-sm">Cancel</a>
        </div>
      </form>
    );
  }
}

TimelineActivityRetention.defaultProps = {
  editable:       true,
  deletable:      true,
  title:          'Retention Logged',
  icon:           'list-alt',
  prefix:         'far',
  color:          'text-blue',
  alwaysPublic:   false,
};

export default TimelineActivityRetention;
