const CampaignTriggerActionTypes = {
  LOAD_CAMPAIGN_TRIGGERS:      'LOAD_CAMPAIGN_TRIGGERS',
  LOAD_CAMPAIGN_TRIGGERS_DONE: 'LOAD_CAMPAIGN_TRIGGERS_DONE',
  LOAD_CAMPAIGN_TRIGGERS_FAIL: 'LOAD_CAMPAIGN_TRIGGERS_FAIL',

  CREATE_CAMPAIGN_TRIGGER:      'CREATE_CAMPAIGN_TRIGGER',
  CREATE_CAMPAIGN_TRIGGER_DONE: 'CREATE_CAMPAIGN_TRIGGER_DONE',
  CREATE_CAMPAIGN_TRIGGER_FAIL: 'CREATE_CAMPAIGN_TRIGGER_FAIL',

  UPDATE_CAMPAIGN_TRIGGER:      'UPDATE_CAMPAIGN_TRIGGER',
  UPDATE_CAMPAIGN_TRIGGER_DONE: 'UPDATE_CAMPAIGN_TRIGGER_DONE',
  UPDATE_CAMPAIGN_TRIGGER_FAIL: 'UPDATE_CAMPAIGN_TRIGGER_FAIL',

  DELETE_CAMPAIGN_TRIGGER:      'DELETE_CAMPAIGN_TRIGGER',
  DELETE_CAMPAIGN_TRIGGER_DONE: 'DELETE_CAMPAIGN_TRIGGER_DONE',
  DELETE_CAMPAIGN_TRIGGER_FAIL: 'DELETE_CAMPAIGN_TRIGGER_FAIL',
};

export default CampaignTriggerActionTypes;
