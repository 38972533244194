import { ReduceStore }        from 'flux/utils';
import AppDispatcher          from '~/dispatchers/app_dispatcher';
import TeamMembersActionTypes from '~/types/team_members_action_types';

class TeamMembersStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingMembers:             false,
      teamMembers:                {}, // store loaded members by role name as key
      notJoinedCount:             0,
      lastTeamMembersStoreAction: null,
    };
  }

  loadTeamMembers(state, action) {
    const newState = { ...state };

    newState.loadingMembers = true;
    newState.lastTeamMembersStoreAction = 'loadTeamMembers';

    return newState;
  }

  loadTeamMembersDone(state, action) {
    const newState = { ...state };

    newState.loadingMembers = false;
    newState.teamMembers[action.memberRole] = action.teamMembers;
    newState.notJoinedCount = action.teamMembers.not_joined_count;
    newState.lastTeamMembersStoreAction = 'loadTeamMembersDone';

    return newState;
  }

  loadTeamMembersFail(state, action) {
    const newState = { ...state };

    newState.loadingMembers = false;
    newState.lastTeamMembersStoreAction = 'loadTeamMembersFail';

    return newState;
  }

  createTeamMember(state, action) {
    const newState = { ...state };

    newState.lastTeamMembersStoreAction = 'createTeamMember';

    return newState;
  }

  createTeamMemberDone(state, action) {
    const newState = { ...state };

    newState.teamMember = action.teamMember;
    newState.lastTeamMembersStoreAction = 'createTeamMemberDone';

    return newState;
  }

  createTeamMemberFail(state, action) {
    const newState = { ...state };

    newState.lastTeamMembersStoreAction = 'createTeamMemberFail';
    newState.sending = false;
    newState.errors  = action.error.response.body;

    return newState;
  }

  updateTeamMember(state, action) {
    const newState = { ...state };

    newState.lastTeamMembersStoreAction = 'updateTeamMember';

    return newState;
  }

  updateTeamMemberDone(state, action) {
    const newState = { ...state };

    newState.lastTeamMembersStoreAction = 'updateTeamMemberDone';

    return newState;
  }

  updateTeamMemberFail(state, action) {
    const newState = { ...state };

    newState.lastTeamMembersStoreAction = 'updateTeamMemberFail';
    newState.sending = false;
    newState.errors  = action.error.response.body;

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case TeamMembersActionTypes.LOAD_TEAM_MEMBERS:
        return this.loadTeamMembers(state, action);

      case TeamMembersActionTypes.LOAD_TEAM_MEMBERS_DONE:
        return this.loadTeamMembersDone(state, action);

      case TeamMembersActionTypes.LOAD_TEAM_MEMBERS_FAIL:
        return this.loadTeamMembersFail(state, action);

      case TeamMembersActionTypes.CREATE_TEAM_MEMBER:
        return this.createTeamMember(state, action);

      case TeamMembersActionTypes.CREATE_TEAM_MEMBER_DONE:
        return this.createTeamMemberDone(state, action);

      case TeamMembersActionTypes.CREATE_TEAM_MEMBER_FAIL:
        return this.createTeamMemberFail(state, action);

      case TeamMembersActionTypes.UPDATE_TEAM_MEMBER:
        return this.updateTeamMember(state, action);

      case TeamMembersActionTypes.UPDATE_TEAM_MEMBER_DONE:
        return this.updateTeamMemberDone(state, action);

      case TeamMembersActionTypes.UPDATE_TEAM_MEMBER_FAIL:
        return this.updateTeamMemberFail(state, action);

      default:
        return state;
    }
  }
}

export default new TeamMembersStore();
