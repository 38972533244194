/* eslint-disable max-classes-per-file */
import autoBind            from  'react-autobind';
import React               from  'react';
import { PropTypes }       from  'prop-types';
import { Link }            from  'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import  GlobalContainer         from  '~/components/global_container';
import  CampaignLibraryActions  from  '~/actions/campaign_library_actions';
import  CampaignLibraryStore    from  '~/stores/campaign_library_store';
import  Select                  from  '~/components/forms/select';
import  CampaignLibraryTable    from  './campaign_library_table';
import ListingCampaignTypes     from './listing_campaign_types';

const SCOPES = [
  { label: 'Brokerkit',    value: 'brokerkit' },
  { label: 'Donated',      value: 'donated' },
  { label: 'Organization', value: 'organization' },
  { label: 'All',          value: 'all' },
];

const DEFAULT_SCOPE = SCOPES[0];

class ListingCampaignsLibraryScope extends React.Component {
  render() {
    const { options } = this.props;
    const { onChange } = this.props;
    const { value } = this.props;

    return (
      <Select
        value={value}
        options={options}
        onChange={(option) => onChange(option)}
      />
    );
  }
}

class ListingCampaignsLibrary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCampaigns:           false,
      campaign_libraries:         {},
      selectedScopeFilter:        DEFAULT_SCOPE.value,
      selectedCampaignTypeFilter: '',
    };
    autoBind(this);
  }

  componentDidMount() {
    this.campaignStoreListener = CampaignLibraryStore.addListener(this.onCampaignStoreChange);

    const { selectedScopeFilter, selectedCampaignTypeFilter } = this.state;

    CampaignLibraryActions.loadCampaignTypes();
    CampaignLibraryActions.loadCampaigns({
      currentPage:          browserHistory.location?.page,
      scope:                selectedScopeFilter,
      selectedCampaignType: selectedCampaignTypeFilter,
    });
  }

  componentWillUnmount() {
    if (this.campaignStoreListener) this.campaignStoreListener.remove();
  }

  onCampaignStoreChange() {
    const campaignStoreState = CampaignLibraryStore.getState();
    const {
      loadingCampaigns, campaign_libraries, lastCampaignStoreAction, campaignTypes,
    } = campaignStoreState;

    if (lastCampaignStoreAction === 'deleteCampaignDone') {
      GlobalContainer.notify(
        'Campaign deleted.',
        'success',
      );
    }

    if (lastCampaignStoreAction === 'deleteCampaignFail') {
      GlobalContainer.notify(
        'Oops, something went wrong. Try again later.',
        'error',
      );
    }

    this.setState({
      loadingCampaigns,
      campaign_libraries,
      campaignTypes,
    });
  }

  ListingCampaignsLibraryScopeOnChange(selectedOption) {
    const { selectedCampaignTypeFilter } = this.state;
    this.setState(
      { selectedScopeFilter: selectedOption.value },
      () => { CampaignLibraryActions.loadCampaigns({ scope: selectedOption.value, campaign_type: selectedCampaignTypeFilter }); },
    );
  }

  ListingCampaignTypesOnChange(selectedOption) {
    const clickedOption = selectedOption ? selectedOption.value : '';
    const { selectedScopeFilter } = this.state;
    this.setState(
      { selectedCampaignTypeFilter: clickedOption },
      () => { CampaignLibraryActions.loadCampaigns({ scope: selectedScopeFilter, campaign_type: clickedOption }); },
    );
  }

  renderCampaignsTable() {
    const {
      loadingCampaigns, campaign_libraries, selectedScopeFilter, selectedCampaignTypeFilter,
    } = this.state;

    if (loadingCampaigns) {
      return (
        <div className="text-center card-block">
          <FontAwesomeIcon testid="spinner" icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (_lodash.isEmpty(campaign_libraries)) {
      return <div className="text-grey-dark card-block">There are no campaigns. Check again later.</div>;
    }

    return (
      <CampaignLibraryTable
        loading={loadingCampaigns}
        campaign_libraries={campaign_libraries}
        selectedScopeFilter={selectedScopeFilter}
        selectedCampaignTypeFilter={selectedCampaignTypeFilter}
      />
    );
  }

  render() {
    const campaigns_uri = GlobalContainer.productUri('/campaigns');
    const { campaignTypes, selectedScopeFilter, selectedCampaignTypeFilter } = this.state;

    return (
      <div className="card campaign">
        <div className="card-block">
          <h4 className="d-inline-flex mb15">
            Campaign Library
          </h4>
          <div className="pull-right form-inline">
            <div className="mr-3 select" style={{ width: '140px' }}>
              <ListingCampaignTypes
                options={campaignTypes}
                placeholder="hello"
                value={selectedCampaignTypeFilter}
                onChange={this.ListingCampaignTypesOnChange}
              />
            </div>
            <div className="mr-3 select" style={{ width: '140px' }}>
              <ListingCampaignsLibraryScope
                options={SCOPES}
                placeholder={DEFAULT_SCOPE.name}
                value={selectedScopeFilter}
                onChange={this.ListingCampaignsLibraryScopeOnChange}
              />
            </div>
            <Link to={campaigns_uri} className="btn btn-secondary">
              Your Campaigns
            </Link>
          </div>
        </div>

        {this.renderCampaignsTable()}
      </div>
    );
  }
}

ListingCampaignsLibrary.defaultProps = {};

ListingCampaignsLibrary.propTypes = {};

export default ListingCampaignsLibrary;
