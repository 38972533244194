import classNames from 'classnames';
import autoBind from 'react-autobind';
import React from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltipable from '~/components/effects/tooltipable';
import EmailDomainActions from '~/actions/email_domain_actions';
import EmailDomainStore from '~/stores/email_domain_store';

class DomainItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      submitting: false,
    };
  }

  componentDidMount() {
    this.domainStoreListener = EmailDomainStore.addListener(
      this.onDomainStoreChange,
    );
  }

  componentWillUnmount() {
    if (this.domainStoreListener) {
      this.domainStoreListener.remove();
    }
  }

  onDomainStoreChange() {
    const domainStoreState = EmailDomainStore.getState();
    const { lastDomainStoreAction } = domainStoreState;
    let nextState;

    if (
      lastDomainStoreAction === 'verifyEmailDomainDone'
      || lastDomainStoreAction === 'verifyEmailDomainFail'
    ) {
      nextState = {
        submitting: false,
      };
    }

    this.setState(nextState);
  }

  verifyDomain() {
    const { domain } = this.props;

    if (_lodash.isEmpty(domain)) return;

    this.setState({ submitting: true }, () => {
      EmailDomainActions.verifyDomain(domain);
    });
  }

  render() {
    const { submitting } = this.state;
    const { domain, handleDomainDelete } = this.props;
    const { helpers } = this.context;
    const { sending_records, tracking_records } = domain;
    const requiredRecordsValid = (sending_records || []).concat(tracking_records || []).every(
      (record) => record?.valid === 'valid',
    );

    return (
      <tr>
        <td>
          <strong>{domain.name}</strong>
          <p className="mt10">
            <small className="text-grey">
              {Moment(domain.created_at).format(
                'MMMM Do YYYY, h:mm A',
              )}
            </small>
          </p>
        </td>
        <td>
          <span
            className={classNames('badge', {
              'badge-outline-green':  requiredRecordsValid,
              'badge-outline-danger': !requiredRecordsValid,
            })}
          >
            {requiredRecordsValid ? 'Verified' : 'Unverified'}
          </span>
        </td>
        <td>
          <span className="pull-right">
            {submitting ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm mr5"
                disabled
              >
                <FontAwesomeIcon icon="far fa-spinner" pulse />
              </button>
            ) : (
              <Tooltipable text="Authenticate Domain">
                <button
                  type="button"
                  className="btn btn-outline-success btn-sm mr5"
                  onClick={this.verifyDomain}
                >
                  <FontAwesomeIcon icon={['far', 'key']} />
                </button>
              </Tooltipable>
            )}

            <Tooltipable text="Open DNS Settings">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();

                  helpers.openEmailEmailDrawer({ domain });
                }}
              >
                <FontAwesomeIcon icon={['far', 'cog']} />
              </button>
            </Tooltipable>

            <Tooltipable text="Deletes domain">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleDomainDelete(domain);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </Tooltipable>
          </span>
        </td>
      </tr>
    );
  }
}

DomainItem.contextTypes = {
  helpers: PropTypes.shape({}),
};

DomainItem.defaultProps = {
  handleDomainDelete: () => false,
};

DomainItem.propTypes = {
  domain:             PropTypes.shape({}).isRequired,
  handleDomainDelete: PropTypes.func,
};

export default DomainItem;
