import React               from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Stats = ({
  nextStep,
  previousStep,
  totalSteps,
  step,
  disabled,
}) => (
  <div>
    <hr />

    <div className="pull-right">
      { step > 1 && <button type="button" className="btn btn-secondary mr-2 mb-2" onClick={previousStep}>Go Back</button>}
      { step < totalSteps ? <button type="button" className="btn btn-primary mb-2" onClick={nextStep}>Continue</button> : (
        <button type="button" className="btn btn-success mb-2" onClick={nextStep} disabled={disabled}>
          <FontAwesomeIcon icon={['far', 'fa-arrow-up']} />
          {' '}
          Start Importing Data
        </button>
      )}
    </div>
  </div>
);

export default Stats;
