import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import filesize             from 'filesize';

import { checkFeatureFlag }  from '~/helpers/FeatureFlagChecker';
import SmsTextEditor         from '~/components/forms/HtmlEditors/SmsTextEditor';
import ErrorMessage          from '~/components/forms/ErrorMessage';
import SmsTemplateSelect     from '~/components/forms/sms_template_select';
import TeamPhoneNumberPicker from '~/components/forms/team_fields/team_phone_number_picker';
import VerifiedTextingWarning from '~/components/warnings/VerifiedTextingWarning';
import OwnerContactWarning   from '~/components/warnings/OwnerContactWarning';
import A2pNotApprovedWarning from '~/components/warnings/A2pNotApprovedWarning';
import InsertDocumentButton   from '~/components/insert_document_button';

const MAX_MEDIA_SIZE = 5120000; // 5MB
const charsLimit = 1600;

class TextMessageForm extends React.Component {
  isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

  constructor(props) {
    super(props);

    const { step } = props;
    const smsNumber = _lodash.first(Rails.helpers.currentUser.sms_numbers);

    this.state = {
      errors: {},
      media:  {},
      sms:    {
        from:       step.from || (smsNumber ? smsNumber.service_number : ''),
        body:       step.body || '',
        media_url:  step.media_url || null,
        media_name: step.media_name || null,
      },
      enableFromField:            props.type === 'Campaign',
      numbersExists:              true,
      showVerifiedTextingWarning: false,
      showA2PWarning:             false,
    };

    autoBind(this);
  }

  handleFromChange = (opt) => {
    this.setState((prevState) => {
      const { sms } = prevState;
      const fromValue = opt?.value || null;

      if (fromValue === -1) {
        return {
          sms:                        { ...sms, from: fromValue },
          showVerifiedTextingWarning: false,
          showA2PWarning:             false,
        };
      }

      const showWarning = fromValue ? !opt.verified_texting : false;
      const showA2PWarning = fromValue ? (opt.verified_texting && !opt.a2p_approved) : false;

      return {
        sms:                        { ...sms, from: fromValue },
        showVerifiedTextingWarning: showWarning,
        showA2PWarning,
      };
    }, () => {
      const { sms } = this.state;
      const { onChange } = this.props;
      onChange(sms);
    });
  }

  handleNumbersExistsChange(numbersExists) {
    this.setState({ numbersExists });
  }

  handleBodyChange = (text) => {
    this.setState((prevState) => {
      const errors = {};

      if (charsLimit < text.length) {
        errors.body = ['You have exceeded your limit of maximum characters allowed.'];
      }

      return {
        errors: { ...prevState.errors, ...errors },
        sms:    { ...prevState.sms, body: text },
      };
    }, () => {
      const { sms } = this.state;
      const { onChange } = this.props;
      onChange(sms);
    });
  }

  handleMediaUrlChange = (media) => {
    this.setState((prevState) => {
      const media_name = media.name || media.title;
      let media_url;

      if (media.type === 'image') {
        media_url = media.small_media_url;
      }

      return {
        media: { ...prevState.media, ...media },
        sms:   {
          ...prevState.sms,
          media_url,
          media_name,
        },
      };
    }, () => {
      const { sms } = this.state;
      const { onChange } = this.props;
      onChange(sms);
    });
  }

  handleVerifiedTextingWarning = (verifiedTexting) => {
    this.setState({
      showVerifiedTextingWarning: verifiedTexting,
    });
  }

  handleA2PWarning = (a2pApproved) => {
    this.setState({
      showA2PWarning: a2pApproved,
    });
  }

  applyTemplate = (template) => {
    this.setState((prevState) => ({
      sms: {
        ...prevState.sms,
        body:      template.body,
        media_url: template.media_url,
      },
      media: {
        ...prevState.media,
        name: template.media_name,
      },
      templateBody: template.body,
      errors:       {},
    }), () => {
      const { sms } = this.state;
      const { onChange } = this.props;
      onChange(sms);
    });
  }

  removeAttachment = (e) => {
    e.preventDefault();

    const { onChange } = this.props;

    this.setState((prevState) => ({
      media: null,
      sms:   {
        ...prevState.sms,
        media_url:  null,
        media_name: null,
      },
    }), () => {
      const { sms } = this.state;

      onChange(sms);
    });
  }

  validate() {
    const errors = {};
    const {
      sms, enableFromField, media, showVerifiedTextingWarning, showA2PWarning,
    } = this.state;

    if (enableFromField && !sms.from) {
      errors.from = ["Can't be empty"];
    }

    if (enableFromField && (this.isRestrictionForUnverifiedNumbersEnabled && showVerifiedTextingWarning && showA2PWarning) && sms.from) {
      errors.from = ['This number is not verified for texting. Please choose another number.'];
      errors.unverified_number = true;
    }

    if (!sms.body) {
      errors.body = ["Can't be empty"];
    }

    if (sms.body && charsLimit < sms.body.length) {
      errors.body = ['You have exceeded your limit of maximum characters allowed.'];
    }

    if (media && media?.type !== 'image') {
      const media_size = media?.animated_thumbnail ? media.gif_byte_size : media.thumbnail_byte_size;
      let error;

      if (media_size > MAX_MEDIA_SIZE) {
        errors.media = [`File is too large (${filesize(media_size)}). Allowed ${filesize(MAX_MEDIA_SIZE)}.`];
      }
    }

    this.setState({ errors });

    return errors;
  }

  renderAttachment = () => {
    const { media, sms } = this.state;
    const { media_url } = sms;

    if (!media_url) return null;

    const name = media?.name || media?.title || sms?.media_name;

    return (
      <div className="alert alert-success p10 text-left mb5" key={name}>
        <div className="pull-right">
          <a href="#remove-attachment" onClick={this.removeAttachment}>
            <FontAwesomeIcon icon={['far', 'fa-times']} />
          </a>
        </div>
        <div className="text-break">
          <FontAwesomeIcon icon={['far', 'fa-file']} className="mr10" />
          <a href={media_url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </div>
      </div>
    );
  }

  render() {
    const {
      errors, templateBody, enableFromField, sms, numbersExists, showVerifiedTextingWarning, showA2PWarning, linksToInsert,
    } = this.state;

    if (enableFromField && !numbersExists) {
      return (
        <div className="">
          <p className="mb10">Looks like no one on your team has started using texting on Brokerkit.</p>
          <p>
            Choose a number and turn on texting by opening any lead with a phone number in
            {' '}
            <Link to="/recruiter/today" className="font-weight-bold text-underline">recruiting</Link>
          </p>
        </div>
      );
    }

    return (
      <div>
        {enableFromField && (
          <>
            {this.isRestrictionForUnverifiedNumbersEnabled && showVerifiedTextingWarning && <VerifiedTextingWarning />}
            {this.isRestrictionForUnverifiedNumbersEnabled && (sms.from === -1 || sms.from === '-1') && <OwnerContactWarning />}
            {this.isRestrictionForUnverifiedNumbersEnabled && showA2PWarning && <A2pNotApprovedWarning />}

            <div className="form-group mb15">
              <label htmlFor="sender-dropdown" className="d-block">From</label>
              <TeamPhoneNumberPicker
                className={errors.from ? 'has-error' : ''}
                placeholder="Enter sender number"
                name="phone_number"
                value={sms.from}
                onChange={this.handleFromChange}
                handleNumbersExistsChange={this.handleNumbersExistsChange}
                handleVerifiedTextingWarning={this.handleVerifiedTextingWarning}
                handleA2PWarning={this.handleA2PWarning}
                contactOwner
              />
            </div>
          </>
        )}

        <div className="form-group mb15">
          <SmsTextEditor
            mms={!!sms.media_url}
            className={errors.body ? 'tiny-mce has-error' : ''}
            placeholder="Enter your text message"
            value={sms.body}
            onChange={this.handleBodyChange}
            replaceValue={templateBody}
            handleMediaUrlChange={this.handleMediaUrlChange}
            clearReplaceValue={() => {
              this.setState({
                templateBody: null,
              });
            }}
            linksToInsert={linksToInsert}
            clearLinksToInsert={() => {
              this.setState({ linksToInsert: [] });
            }}
          />
          <div className="mt-2">
            {errors.body && <ErrorMessage message={errors.body} />}
          </div>
        </div>

        <div className="row m0">
          <div className="col-lg-4 p0 pl0-sm-down">
            <InsertDocumentButton
              setLinksToInsert={(linksToInsert) => this.setState({ linksToInsert })}
            />
          </div>

          <div className="col-lg-4 p0">
            {this.renderAttachment()}
            {errors.media && <ErrorMessage message={errors.media} />}
          </div>

          <div className="col-lg-4 p0 pl0-sm-down ml-auto">
            <SmsTemplateSelect
              body={sms.body}
              media_url={sms.media_url}
              media_name={sms.media_name}
              applyTemplate={this.applyTemplate}
              validateSmsTemplate={this.validate}
            />
          </div>
        </div>
      </div>
    );
  }
}

TextMessageForm.defaultProps = {
  step:     {},
  onChange: () => false,
};

TextMessageForm.propTypes = {
  step:     PropTypes.shape({}),
  onChange: PropTypes.func,
  type:     PropTypes.string.isRequired,
};

export default TextMessageForm;
