import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GlobalContainer from '~/components/global_container';
import CampaignActions from '~/actions/campaign_actions';
import CampaignStore   from '~/stores/campaign_store';
import CampaignsTable  from './campaigns_table';

class ListingCampaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCampaigns: false,
      campaigns:        [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.campaignStoreListener = CampaignStore.addListener(this.onCampaignStoreChange);

    CampaignActions.loadCampaigns();
  }

  componentWillUnmount() {
    if (this.campaignStoreListener) this.campaignStoreListener.remove();
  }

  onCampaignStoreChange() {
    const campaignStoreState = CampaignStore.getState();
    const { loadingCampaigns, campaigns } = campaignStoreState;

    this.setState({
      loadingCampaigns,
      campaigns,
    });
  }

  deleteCampaign(campaign) {
    if (_lodash.isEmpty(campaign)) return;

    CampaignActions.deleteCampaign(campaign);
  }

  renderCampaignsTable() {
    const { loadingCampaigns, campaigns } = this.state;

    if (loadingCampaigns) {
      return (
        <div className="text-center card-block">
          <FontAwesomeIcon testid="spinner" icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (_lodash.isEmpty(campaigns)) {
      return <div className="text-grey-dark card-block text-center">Select Compose to add a new campaign.</div>;
    }

    return <CampaignsTable loading={loadingCampaigns} campaigns={campaigns} deleteCampaign={this.deleteCampaign} />;
  }

  render() {
    const campaign_libraries_uri = GlobalContainer.productUri('/campaign_libraries');
    const campaign_triggers_uri = GlobalContainer.productUri('/campaign_triggers');
    const campaign_new_uri = GlobalContainer.productUri('/campaigns/new');

    return (
      <div className="card campaign">
        <div className="card-block">
          <h4 className="mb15">
            Smart Campaigns
            <div className="pull-right">
              <Link className="btn btn-secondary mr3" to={campaign_libraries_uri}>
                Campaign Library
              </Link>
              <Link className="btn btn-secondary mr3" to={campaign_triggers_uri}>
                Campaign Triggers
              </Link>
              <Link className="btn btn-secondary" to={campaign_new_uri}>
                <FontAwesomeIcon icon={['fas', 'fa-pencil-alt']} className="mr5" />
                {' '}
                Compose Campaign
              </Link>
            </div>
          </h4>
        </div>
        {this.renderCampaignsTable()}
      </div>
    );
  }
}

ListingCampaigns.defaultProps = {};

ListingCampaigns.propTypes = {};

export default ListingCampaigns;
