import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import ReactPaginate       from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TeamMembersActions from '~/actions/team_members_actions';
import TeamMembersStore   from '~/stores/team_members_store';
import TeamMemberItem     from './team_member_item';

class TeamMembersTable extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  componentDidMount() {
    this.listener = TeamMembersStore.addListener(this.onMemberChange);
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  onMemberChange() {
    const teamMembersState = TeamMembersStore.getState();
    if (teamMembersState.lastTeamMembersStoreAction === 'createTeamMemberDone'
        || teamMembersState.lastTeamMembersStoreAction === 'updateTeamMemberDone') {
      this.context.helpers.closeDrawer();
    }
  }

  onPageChange(page) {
    this.props.handlePageChange(page.selected);
  }

  buildPageHref(pageNumber) {
    return `#page-${pageNumber}`;
  }

  renderTeamMembers() {
    const { memberRole, loadingMembers, teamMembers } = this.props;

    const members = teamMembers[memberRole];

    if (loadingMembers) {
      return (
        <tr>
          <td colSpan={4}>
            <p className="text-center p-5">
              <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
            </p>
          </td>
        </tr>
      );
    }

    if (!loadingMembers && (!members || !members.data)) {
      return (
        <tr>
          <td colSpan={4}>
            <p className="text-center p-5 text-grey">No users found</p>
          </td>
        </tr>
      );
    }

    const memberRows = [];
    members.data.forEach((member) => {
      memberRows.push(
        <TeamMemberItem
          key={`member-${member.id}`}
          teamMember={member}
          notify={this.props.notify}
          drawerExternalRolesOnly={this.props.drawerExternalRolesOnly}
        />,
      );
    });

    return memberRows;
  }

  renderPagination() {
    const { memberRole, teamMembers } = this.props;

    const members = teamMembers[memberRole];

    if (!members || !members.data) {
      return null;
    }

    return (
      <div className="pl20 pr20 pt10 pb10">
        <div className="row">
          <div className="col-md-6">
            Showing
            {' '}
            {members.start}
            {' '}
            to
            {' '}
            {members.end}
            {' '}
            of
            {' '}
            {members.total_count}
            {' '}
            members
          </div>
          <div className="col-md-6">
            { members.total_pages > 1
            && (
            <div className="pull-right">
              <nav>
                <ReactPaginate
                  forcePage={members.current_page - 1}
                  pageCount={members.total_pages}
                  pageRangeDisplayed={Rails.isMobile ? 1 : 3}
                  marginPagesDisplayed={Rails.isMobile ? 2 : 3}
                  containerClassName="pagination"
                  activeClassName="active"
                  breakLabel="..."
                  breakLinkClassName="page-link disabled"
                  breakClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  onPageChange={this.onPageChange}
                  hrefBuilder={this.buildPageHref}
                  previousLabel={Rails.isMobile ? '<' : 'Previous'}
                  nextLabel={Rails.isMobile ? '>' : 'Next'}
                />
              </nav>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { searchQuery, banner } = this.props;

    return (
      <div className="table-responsive">
        {
          searchQuery && searchQuery.length > 0
          && (
          <p className="text-gray-dark ml20">
            <span className="mr20">
              Search results for
              {' '}
              <strong>{searchQuery}</strong>
            </span>
            <a
              href="#clear"
              className="btn btn-secondary btn-sm"
              onClick={(e) => {
                e.preventDefault();
                this.props.clearSearch();
              }}
            >
              clear search
            </a>
          </p>
          )
        }

        {banner && (
          <div className="ml15 mr15">{banner}</div>
        )}

        <table className="table table-hover no-border">
          <tbody>
            {this.renderTeamMembers()}
          </tbody>
        </table>

        {this.renderPagination()}
      </div>
    );
  }
}

TeamMembersTable.contextTypes = {
  helpers: PropTypes.shape({}),
};

TeamMembersTable.defaultProps = {
  memberRole:              '',
  loadingMembers:          false,
  teamMembers:             {},
  handlePageChange:        () => false,
  notify:                  false,
  banner:                  null,
  searchQuery:             null,
  clearSearch:             () => false,
  drawerExternalRolesOnly: false,
};

TeamMembersTable.propTypes = {
  memberRole:              PropTypes.string,
  loadingMembers:          PropTypes.bool,
  teamMembers:             PropTypes.shape({}),
  handlePageChange:        PropTypes.func,
  notify:                  PropTypes.bool,
  banner:                  PropTypes.element,
  searchQuery:             PropTypes.string,
  clearSearch:             PropTypes.func,
  drawerExternalRolesOnly: PropTypes.bool,
};

export default TeamMembersTable;
