const TeamMembersActionTypes = {
  LOAD_TEAM_MEMBERS:      'LOAD_TEAM_MEMBERS',
  LOAD_TEAM_MEMBERS_DONE: 'LOAD_TEAM_MEMBERS_DONE',
  LOAD_TEAM_MEMBERS_FAIL: 'LOAD_TEAM_MEMBERS_FAIL',

  CREATE_TEAM_MEMBER:      'CREATE_TEAM_MEMBER',
  CREATE_TEAM_MEMBER_DONE: 'CREATE_TEAM_MEMBER_DONE',
  CREATE_TEAM_MEMBER_FAIL: 'CREATE_TEAM_MEMBER_FAIL',

  UPDATE_TEAM_MEMBER:      'UPDATE_TEAM_MEMBER',
  UPDATE_TEAM_MEMBER_DONE: 'UPDATE_TEAM_MEMBER_DONE',
  UPDATE_TEAM_MEMBER_FAIL: 'UPDATE_TEAM_MEMBER_FAIL',

  MOVE_TEAM_MEMBER:      'MOVE_TEAM_MEMBER',
  MOVE_TEAM_MEMBER_DONE: 'MOVE_TEAM_MEMBER_DONE',
  MOVE_TEAM_MEMBER_FAIL: 'MOVE_TEAM_MEMBER_FAIL',

  DELETE_TEAM_MEMBER:      'DELETE_TEAM_MEMBER',
  DELETE_TEAM_MEMBER_DONE: 'DELETE_TEAM_MEMBER_DONE',
  DELETE_TEAM_MEMBER_FAIL: 'DELETE_TEAM_MEMBER_FAIL',
};

export default TeamMembersActionTypes;
