import AppDispatcher   from '~/dispatchers/app_dispatcher';
import APIRequest      from '~/lib/api_request';
import DataActionTypes from '~/types/data_action_types';

let identifyDuplicatesRequest;
let mergeDuplicatesRequest;
let createImportRequest;

const DataActions = {
  createImport(params) {
    APIRequest.abort(createImportRequest);

    AppDispatcher.dispatch({
      type: DataActionTypes.CREATE_IMPORT,
    });

    createImportRequest = APIRequest.post({
      resource: '/v1/imports',
      data:     { import: params },
    });

    createImportRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DataActionTypes.CREATE_IMPORT_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   DataActionTypes.CREATE_IMPORT_DONE,
        import: response.body,
      });
    });
  },

  identifyDuplicates(options = {}, currentPage) {
    APIRequest.abort(identifyDuplicatesRequest);

    AppDispatcher.dispatch({
      type: DataActionTypes.IDENTIFY_DUPLICATES,
    });

    identifyDuplicatesRequest = APIRequest.get({
      resource: '/v1/duplicates',
      data:     {
        duplicates_filter: options.duplicatesFilter,
        duplicates_search: options.duplicatesSearch,
        page:              currentPage,
      },
    });

    identifyDuplicatesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DataActionTypes.IDENTIFY_DUPLICATES_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:       DataActionTypes.IDENTIFY_DUPLICATES_DONE,
        duplicates: response.body,
      });
    });
  },

  mergeDuplicates(primaryLead, mergeLead, duplicatesFilter, currentPage) {
    APIRequest.abort(mergeDuplicatesRequest);

    AppDispatcher.dispatch({
      type: DataActionTypes.MERGE_DUPLICATES,
      primaryLead,
      mergeLead,
    });

    mergeDuplicatesRequest = APIRequest.put({
      resource: `/v1/duplicates/${primaryLead.id}/merge/${mergeLead.id}`,
    });

    mergeDuplicatesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   DataActionTypes.MERGE_DUPLICATES_FAIL,
          errors: response.body.errors,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: DataActionTypes.MERGE_DUPLICATES_DONE,
        lead: response.body,
      });

      DataActions.identifyDuplicates({ duplicatesFilter }, currentPage);
    });
  },
};

export default DataActions;
