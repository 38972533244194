import autoBind            from 'react-autobind';
import React               from 'react';
import ReactDOM            from 'react-dom';
import { PropTypes }       from 'prop-types';
import { withRouter }      from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                from '~/lib/brokerbit';
import CampaignLibraryActions   from '~/actions/campaign_library_actions';
import Tooltipable              from '~/components/effects/tooltipable';
import CopyCampaignLibraryModal from '~/components/modals/copy_campaign_library_modal';

class CampaignLibraryItem extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      campaign: props.campaign,
    };
  }

  handleCopyClick = (e) => {
    e.preventDefault();

    const { campaign } = this.state;

    ReactDOM.render(
      <CopyCampaignLibraryModal campaign={campaign} />, document.getElementById('primary-modal'),
    );
  };

  handleDeleteClick = (e) => {
    e.preventDefault();

    const { campaign } = this.state;

    if (_lodash.isEmpty(campaign)) return;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this campaign?',
      callback: (ok) => {
        if (ok) {
          CampaignLibraryActions.deleteCampaign(campaign);
        }
      },
    });
  }

  handleEditClick = (e) => {
    e.preventDefault();

    const { campaign } = this.state;
    const { history, currentPage } = this.props;

    if (_lodash.isEmpty(campaign)) return;

    const editUri = GlobalContainer.productUri(`/campaign_libraries/${campaign.id}/edit`);

    history.push({
      pathname: editUri,
      state:    { page: currentPage },
    });
  }

  render() {
    const { campaign } = this.state;
    const { handleCampaignClick } = this.props;
    const { currentUser } = Rails.helpers;

    let { duration } = campaign;
    if (duration < 86400) duration = 86400;
    else duration += 86400;

    const durationMessage = `lasts ${Moment().add(duration, 's').fromNow(true)}`;

    return (
      <tr key={`campaign-${campaign.id}`} className="text-grey">
        <td className="col">
          <strong className="text-wrap">
            <span className="text-grey-dark text-no-underline">
              {campaign.name}
            </span>
          </strong>

          <p className="text-wrap">
            <span className="text-grey text-no-underline campaign-desc">{campaign.description}</span>
          </p>

          <p className="mt10">
            <small className="text-grey">
              <FontAwesomeIcon
                icon={['far', 'fa-bullhorn']}
                className="mr5"
              />
              {' '}
              {campaign.campaign_steps_count}
              {' '}
              emails/texts/tasks
            </small>
            <small className="text-grey ml30">
              {campaign.new_engine ? (
                <Tooltipable text="This field specifies the duration of the campaign and includes only the waiting periods between steps and does not factor in the On and At parameters as those will depend on the start date.">
                  <>
                    <FontAwesomeIcon
                      icon={['far', 'fa-clock']}
                      className="mr5"
                    />
                    {durationMessage}
                  </>
                </Tooltipable>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={['far', 'fa-clock']}
                    className="mr5"
                  />
                  {durationMessage}
                </>
              )}
            </small>
            <small className="text-grey ml30">
              Created by
              {' '}
              {campaign.created_by}
            </small>
          </p>
        </td>
        <td className="col-2">
          <span className="pull-right">
            {currentUser.admin && (
              <>
                <Tooltipable text="Deletes Campaign">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mr5"
                    onClick={this.handleDeleteClick}
                  >
                    Delete
                  </button>
                </Tooltipable>

                <Tooltipable text="Updates Campaign">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mr5"
                    onClick={this.handleEditClick}
                  >
                    Edit
                  </button>
                </Tooltipable>
              </>
            )}

            <Tooltipable text="Preview Campaign">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();
                  handleCampaignClick(campaign);
                }}
              >
                Preview
              </button>
            </Tooltipable>

            <Tooltipable text="Copy Campaign">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={this.handleCopyClick}
              >
                Copy
              </button>
            </Tooltipable>
          </span>
        </td>
      </tr>
    );
  }
}

CampaignLibraryItem.defaultProps = {
  handleCampaignClick: () => false,
};

CampaignLibraryItem.propTypes = {
  campaign:            PropTypes.shape({}).isRequired,
  handleCampaignClick: PropTypes.func,
};

export default withRouter(CampaignLibraryItem);
