/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      ID
      UserID
      TeamID
      Type
      IconType
      IsRead
      Title
      Content
      OriginatingObjectID
      OriginatingObjectType
      LeadID
      LeadName
      RecruitingStatus
      InboxConversationID
      InboxMessageID
      CreatedAt
      __typename
    }
  }
`;
export const updateReadStatus = /* GraphQL */ `
  mutation UpdateReadStatus($input: UpdateReadStatusInput!) {
    updateReadStatus(input: $input) {
      ID
      UserID
      TeamID
      Type
      IconType
      IsRead
      Title
      Content
      OriginatingObjectID
      OriginatingObjectType
      LeadID
      LeadName
      RecruitingStatus
      InboxConversationID
      InboxMessageID
      CreatedAt
      __typename
    }
  }
`;
export const markAllAsRead = /* GraphQL */ `
  mutation MarkAllAsRead($UserID: Int!, $TeamID: Int!, $Type: String) {
    markAllAsRead(UserID: $UserID, TeamID: $TeamID, Type: $Type) {
      ID
      CreatedAt
      __typename
    }
  }
`;
