/* eslint-disable max-classes-per-file */
import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import DayPickerInput      from 'react-day-picker/DayPickerInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputWithIcon  from '~/components/forms/input_with_icon';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(1983, 0);
const toMonth = new Date(currentYear + 10, 11);

class YearMonthForm extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(e) {
    const { onChange } = this.props;

    const month = this.monthSelect ? this.monthSelect.value : null;
    const year  = this.yearSelect ? this.yearSelect.value : null;
    const selectedDate = new Date(year, month);

    onChange(Moment(selectedDate));
  }

  render() {
    const { date, localeUtils, enableYearSelect } = this.props;

    const months = localeUtils.getMonths();

    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    return (
      <div className="DayPicker-Caption form-inline">
        <select
          name="month"
          ref={(el) => (this.monthSelect = el)}
          className="form-control mr5 mb5-sm-down"
          style={{ position: 'relative', top: '-8px' }}
          onChange={this.handleChange}
          value={date.getMonth()}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>

        {enableYearSelect && (
          <select
            name="year"
            ref={(el) => (this.yearSelect = el)}
            className="form-control"
            style={{ position: 'relative', top: '-8px' }}
            onChange={this.handleChange}
            value={date.getFullYear()}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      title:  this.props.title,
    };

    if (this.props.value) {
      this.state.value = Moment(this.props.value);
    }

    autoBind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.value && props.value != this.state.value) {
      this.state.value = Moment(props.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onFocus(e) {
    e.preventDefault();
    this.input.showDayPicker();
  }

  onBlur(e) {
    e.preventDefault();
    if (!this.input.clickedInside) {
      this.input.hideDayPicker();
    }
  }

  registerButton(button) {
    this.button = button;
  }

  registerInput(input) {
    this.input = input;
  }

  toggleActivation(e) {
    e.preventDefault();
    const { active } = this.state;
    const activeState = !active;

    this.setState({
      active: activeState,
    });

    if (activeState) {
      this.input.showDayPicker();
    }
  }

  onChangeInput(event) {
    const { onChange, clearable } = this.props;

    if (!event.target.value && clearable) {
      this.setState({ value: '' });
      onChange('');
    }
  }

  handleDayChange(momentDate, modifiers) {
    const { format, onChange } = this.props;

    this.setState({
      active: false,
      title:  momentDate.format(format),
      value:  momentDate.local(),
    });

    onChange(momentDate.local(), modifiers);
  }

  changeDisplayDate(date) {
    this.input.setState({ month: date.toDate() });
  }

  renderSelectButton() {
    const { className, style } = this.props;
    const { active } = this.state;

    const btnClassName = classNames(className, 'btn btn-secondary', { active });

    return (
      <button
        type="button"
        ref={this.registerButton}
        className={classNames(btnClassName)}
        onClick={this.onFocus}
        onBlur={this.onBlur}
        style={style}
      >
        <FontAwesomeIcon icon={['far', 'fa-calendar-alt']} className="mr5" />
        {this.state.title}
      </button>
    );
  }

  renderLink() {
    const { className, title } = this.props;
    const { active } = this.state;
    return (
      <a
        ref={this.registerButton}
        href="#"
        onClick={this.onFocus}
        onBlur={this.onBlur}
        className={className}
      >
        {title}
      </a>
    );
  }

  renderInput() {
    const {
      enableYearSelect, name, placeholder, className, dateFormat, disabled,
    } = this.props;

    const momentObj = (this.state.value && typeof this.state.value === 'string') ? Moment(this.state.value) : this.state.value;

    let value = momentObj ? momentObj.format(dateFormat || 'LL') : '';
    if (momentObj && enableYearSelect === false) {
      value = momentObj.format('MMMM D');
    }
    return (
      <div>
        <InputWithIcon
          name={name}
          icon="calendar-alt"
          prefix="far"
          side="right"
          placeholder={placeholder}
          value={value}
          className={`${className} mb0`}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChangeInput}
          disabled={disabled}
        />
      </div>
    );
  }

  render() {
    const {
      disabledDays, kind, captionElement, enableYearSelect, right, style,
    } = this.props;

    let component;
    switch (kind) {
      case 'button':
        component = this.renderSelectButton;
        break;
      case 'input':
        component = this.renderInput;
        break;
      case 'link':
        component = this.renderLink;
        break;
      default:
        break;
    }
    const containerClasses = classNames('DayPickerInput', kind === 'link' ? '' : 'DayPickerInput-Block');
    const modifiedCaptionElement = captionElement || (
      ({ date, localeUtils }) => (
        <YearMonthForm
          date={date}
          localeUtils={localeUtils}
          enableYearSelect={enableYearSelect}
          onChange={this.changeDisplayDate}
        />
      )
    );

    return (
      <DayPickerInput
        ref={this.registerInput}
        component={component}
        dayPickerProps={{
          disabledDays,
          modifiedCaptionElement,
        }}
        classNames={{
          container:      containerClasses,
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay:        classNames('DayPickerInput-Overlay', right && 'DayPickerInput-OverlayRight'),
        }}
        style={style}
        format="YYYY-MM-DD"
        onDayChange={this.handleDayChange}
      />
    );
  }
}

DatePicker.defaultProps = {
  className:        '',
  format:           'MMM Do YYYY',
  kind:             'input',
  enableYearSelect: true,
  disabledDays:     {},
  style:            {},
  onChange:         () => false,
  placeholder:      '',
  title:            '',
  clearable:        false,
  disabled:         false,
};

DatePicker.propTypes = {
  placeholder:      PropTypes.string,
  title:            PropTypes.string,
  kind:             PropTypes.string,
  enableYearSelect: PropTypes.bool,
  format:           PropTypes.string,
  className:        PropTypes.string,
  disabledDays:     PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  style:            PropTypes.shape({}),
  onChange:         PropTypes.func,
  clearable:        PropTypes.bool,
  disabled:         PropTypes.bool,
};

export default DatePicker;
