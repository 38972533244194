import React                   from 'react';
import { PropTypes }           from 'prop-types';
import classNames              from 'classnames';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import autoBind                from 'react-autobind';
import { Link, BrowserRouter } from 'react-router-dom';

import brokerbit    from '~/lib/brokerbit';
import VideoActions from '~/actions/video_actions';

class VideoItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inEdit:         false,
      video:          props.video,
      newTitle:       props.video?.title,
      newDescription: props.video?.description,
      errors:         {},
    };

    autoBind(this);
  }

  onChangeTitle = (e) => {
    e.persist();

    this.setState({ newTitle: e.target.value });
  }

  onChangeDescription = (e) => {
    e.persist();

    this.setState({ newDescription: e.target.value });
  }

  editVideo = (e) => {
    e.preventDefault();

    this.setState({ inEdit: true });
  }

  handleCancelEditing = (e) => {
    e.preventDefault();

    const { video } = this.state;

    this.setState({
      video,
      inEdit:         false,
      newTitle:       video.title,
      newDescription: video.description,
    });
  }

  handleSaveVideoTitle = (e) => {
    e.preventDefault();

    const { newTitle, newDescription, video } = this.state;

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      this.setState((prevState) => ({
        video: {
          ...prevState.video,
          title:       newTitle,
          description: newDescription,
        },
      }), this.updateVideo);
    } else {
      this.setState({ errors });
    }
  }

  updateVideo = () => {
    const { video } = this.state;
    const { pagination, search } = this.props;

    VideoActions.updateVideo(video, {
      page:   pagination.current_page,
      search: {
        query:   encodeURIComponent(search?.title),
        user_id: search?.user_id,
      },
    });
  }

  deleteVideo = (e) => {
    e.preventDefault();

    const { video } = this.state;
    const { pagination, search } = this.props;

    brokerbit.confirmBox({
      message:  `Are you sure to remove the "${video.title}" video?`,
      callback: (ok) => {
        if (ok) {
          VideoActions.deleteVideo(video, {
            page:   pagination.current_page,
            search: {
              query:   encodeURIComponent(search?.title),
              user_id: search?.user_id,
            },
          });
        }
      },
    });
  }

  validate = () => {
    const { newTitle } = this.state;
    const errors = {};

    if (!newTitle) {
      errors.title = 'Is invalid';
    }

    return errors;
  }

  renderRadioButton = () => {
    const { video } = this.state;
    const { selectedVideo, selectVideo } = this.props;

    return (
      <div className="asset-radio-button mr-3">
        <div className="form-check">
          <input
            className="form-check-input position-static"
            type="radio"
            value={video.id}
            onChange={selectVideo}
            checked={selectedVideo === video.id}
          />
        </div>
      </div>
    );
  }

  renderImageAndState = () => {
    const { video } = this.state;

    return (
      <div className="asset-image-and-state">
        <div className="asset-image">
          {video.thumbnail ? (
            <Link className="" target="_blank" rel="noopener noreferrer" to={`/video/${video.uuid}`}>
              <img src={video.thumbnail} alt="Thumbnail" />
            </Link>
          ) : (
            <div className="h-100 d-flex justify-content-center align-items-center">Processing ...</div>
          )}
        </div>
      </div>
    );
  }

  renderInformation = () => {
    const {
      inEdit,
      video,
      newTitle,
      newDescription,
      errors,
    } = this.state;
    const videoCreatedAt = Moment(video.created_at).format('MMMM D, YYYY - hh:mmA');

    return (
      <div className="asset-primary-info">
        {inEdit ? (
          <>
            <div className="form-row align-items-center mb-2">
              <div className="col">
                <input
                  className={classNames('form-control', { 'has-error': !!errors.title })}
                  type="text"
                  value={newTitle}
                  onChange={this.onChangeTitle}
                />
              </div>
            </div>

            <div className="form-row align-items-center mb-2">
              <div className="col">
                <textarea
                  id="video_description"
                  name="video[description]"
                  placeholder="Add text here for the recipient viewing page ..."
                  className="form-control"
                  rows={1}
                  value={newDescription}
                  onChange={this.onChangeDescription}
                />
              </div>
            </div>

            <div className="form-row align-items-center">
              <div className="col-auto">
                <button type="button" className="btn btn-outline-success btn-sm mr-2" onClick={this.handleSaveVideoTitle}>
                  <FontAwesomeIcon icon={['fas', 'fa-check']} />
                  {' '}
                  Save
                </button>

                <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.handleCancelEditing}>
                  <FontAwesomeIcon icon={['fas', 'fa-times']} />
                  {' '}
                  Cancel
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="asset-name-date">
            <div className="asset-fine-print">{videoCreatedAt}</div>
            <div className="asset-name">{video.title}</div>
          </div>
        )}
      </div>
    );
  }

  renderAction = () => {
    const { inEdit, video } = this.state;

    return (
      <>
        {!inEdit && (
          <div className="asset-actions">
            <div className="dropdown">
              <button
                className="btn btn-secondary"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon="far fa-cog" />
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a href="#dropdownMenuButton" className="dropdown-item" onClick={this.editVideo}>Edit</a>
                <Link className="dropdown-item" target="_blank" rel="noopener noreferrer" to={`/video/${video.uuid}`}>
                  Preview
                </Link>
                <a href="#dropdownMenuButton" className="dropdown-item" onClick={this.deleteVideo}>Delete</a>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  render() {
    const { video } = this.state;
    const { selectedVideo } = this.props;

    return (
      <BrowserRouter>
        <div className="asset-item">
          <div className="asset-item-container">
            {this.renderRadioButton()}
            {this.renderImageAndState()}
            {this.renderInformation()}
            {this.renderAction()}
          </div>

          {(selectedVideo === video.id && !video.thumbnail) && (
            <div className="mt-2 alert alert-warning" role="alert">
              The thumbnail is being created, and you can&apos;t insert it until that is completed.
            </div>
          )}
        </div>
      </BrowserRouter>
    );
  }
}

VideoItem.defaultProps = {
  selectedVideo: null,
  selectVideo:   () => false,
  pagination:    {},
  search:        {},
};

VideoItem.propTypes = {
  selectedVideo: PropTypes.number,
  selectVideo:   PropTypes.func,
  pagination:    PropTypes.shape({}),
  search:        PropTypes.shape({}),
  video:         PropTypes.shape({}).isRequired,
};

export default VideoItem;
