import autoBind         from 'react-autobind';
import classNames       from 'classnames';
import React            from 'react';
import { PropTypes }    from 'prop-types';
import update           from 'immutability-helper';
import { Link }         from 'react-router-dom';

import Select from '~/components/forms/select';
import { SourceSelect } from '~/components/forms/lead_fields';
import ErrorMessage from '~/components/forms/ErrorMessage';

const leadsFieldOptions = [
  { value: 'source',              label: 'Source' },
  { value: 'appointment',         label: 'Date: Appointment' },
  { value: 'birthday',            label: 'Date: Birthday' },
  { value: 'license_exp_date',    label: 'Date: License Expiration' },
  { value: 'due_date_at',         label: 'Date: Task Due Date' },
  { value: 'last_activity_at',    label: 'Date: Last Activity' },
  { value: 'appointments_logged', label: 'Date: Appointment Logged' },
  { value: 'created_at',          label: 'Date: Contact Created' },
];

const teamFieldOptions = [
  { value: 'source',              label: 'Source' },
  { value: 'birthday',            label: 'Date: Birthday' },
  { value: 'joined_at',           label: 'Date: Joined On' },
  { value: 'license_exp_date',    label: 'Date: License Expiration' },
  { value: 'anniversary_month',   label: 'Date: Anniversary' },
  { value: 'last_seen_at',        label: 'Date: Last Team Member Sign In' },
  { value: 'due_date_at',         label: 'Date: Task Due Date' },
  { value: 'last_activity_at',    label: 'Date: Last Activity' },
  { value: 'created_at',          label: 'Date: Contact Created' },
];

const defaultOperatorOptions = [
  { value: 'is',     label: 'is' },
  { value: 'is_not', label: 'is not' },
];

const dateValueOptions = [
  { value: '1_day_prior',   label: '1 Day Prior' },
  { value: '7_day_prior',   label: '7 Days Prior' },
  { value: '1_month_prior', label: '1 Month Prior' },
  { value: 'today',         label: 'Date' },
  { value: '1_day_after',   label: '1 Day Afterward' },
  { value: '7_day_after',   label: '7 Days Afterward' },
  { value: '1_month_after', label: '1 Month Afterward' },
];

const dateFields = ['appointment', 'birthday', 'joined_at', 'license_exp_date', 'anniversary_month',
  'last_seen_at', 'due_date_at', 'last_activity_at', 'appointments_logged', 'created_at'];

class TriggerConditionForm extends React.Component {
  constructor(props) {
    super(props);

    const { condition } = props;

    this.state = {
      errors:    {},
      condition: {
        field:    condition.field || '',
        operator: condition.operator || '',
        value:    condition.value || '',
      },
    };

    autoBind(this);
  }

  operatorOptions() {
    const { condition } = this.state;

    let options;

    if (condition.field === 'source') {
      options = [
        { value: 'is',     label: 'is' },
        { value: 'is_not', label: 'is not' },
      ];
    } else if (dateFields.includes(condition.field)) {
      options = [
        { value: 'is', label: 'on' },
      ];
    } else {
      options = defaultOperatorOptions;
    }

    return options;
  }

  fieldOptions() {
    const { audience } = this.props;

    let options;

    switch (audience) {
      case 'leads':
        options = leadsFieldOptions;
        break;
      case 'team':
        options = teamFieldOptions;
        break;
    }

    return _lodash.sortBy(options, 'label');
  }

  dateValueOptions() {
    const { condition } = this.state;

    let options;

    if (condition.field === 'joined_at' || condition.field === 'last_seen_at'
        || condition.field === 'last_activity_at' || condition.field === 'appointments_logged'
        || condition.field === 'created_at') {
      return options = [
        { value: 'today',         label: 'Date' },
        { value: '1_day_after',   label: '1 Day Afterward' },
        { value: '7_day_after',   label: '7 Days Afterward' },
        { value: '1_month_after', label: '1 Month Afterward' },
      ];
    } if (condition.field === 'anniversary_month') {
      return options = [
        { value: 'today', label: 'Date' },
      ];
    }
    return options = dateValueOptions;
  }

  renderValueComponent() {
    const { condition, errors } = this.state;

    if (condition.field === 'source') {
      return (
        <SourceSelect
          className={classNames({ 'has-error': errors.value })}
          placeholder="--- Select ---"
          value={condition.value}
          onChange={this.handleValueChange}
        />
      );
    } if (dateFields.includes(condition.field)) {
      return (
        <Select
          className={classNames({ 'has-error': errors.value })}
          value={condition.value}
          options={dateValueOptions}
          options={this.dateValueOptions()}
          placeholder="--- Select ---"
          onChange={this.handleValueChange}
        />
      );
    }
    return (
      <Select
        className={classNames({ 'has-error': errors.value })}
        value={condition.value}
        placeholder="--- Select ---"
        onChange={this.handleValueChange}
      />
    );
  }

  handleFieldChange(opt) {
    const newState = update(this.state, {
      condition: {
        field: { $set: opt ? opt.value : '' },
      },
    });

    this.setState(newState);

    this.props.onChange(newState.condition);
  }

  handleOperatorChange(opt) {
    const newState = update(this.state, {
      condition: {
        operator: { $set: opt ? opt.value : '' },
      },
    });

    this.setState(newState);

    this.props.onChange(newState.condition);
  }

  handleValueChange(opt) {
    const newState = update(this.state, {
      condition: {
        value: { $set: opt ? opt.value : '' },
      },
    });

    this.setState(newState);

    this.props.onChange(newState.condition);
  }

  validate() {
    const errors = {};
    const { condition } = this.state;
    const { audience } = this.props;
    const leadsFieldOptionsArray = _lodash.map(leadsFieldOptions, 'value');
    const teamFieldOptionsArray  = _lodash.map(teamFieldOptions, 'value');

    if (audience == 'leads' && !leadsFieldOptionsArray.includes(condition.field)) {
      errors.field = ["Can't be empty"];
    }

    if (audience == 'team' && !teamFieldOptionsArray.includes(condition.field)) {
      errors.field = ["Can't be empty"];
    }

    if (!condition.field) {
      errors.field = ["Can't be empty"];
    }

    if (!condition.operator) {
      errors.operator = ["Can't be empty"];
    }

    if (!condition.value) {
      errors.value = ["Can't be empty"];
    }

    this.setState({ errors });
  }

  render() {
    const { errors, condition, operators } = this.state;

    return (
      <>
        <div className="col my-1 text-center">
          <Select
            value={condition.field}
            options={this.fieldOptions()}
            onChange={this.handleFieldChange}
            placeholder="--- Select ---"
            className={classNames({ 'has-error': errors.field })}
          />
          <div>{errors.field && <ErrorMessage message={errors.field} />}</div>
        </div>
        <div className="col my-1 text-center">
          <Select
            value={condition.operator}
            options={this.operatorOptions()}
            onChange={this.handleOperatorChange}
            placeholder="--- Select ---"
            className={classNames({ 'has-error': errors.operator })}
          />
          <div>{errors.operator && <ErrorMessage message={errors.operator} />}</div>
        </div>
        <div className="col my-1 text-center">
          {this.renderValueComponent()}
          <div>{errors.value && <ErrorMessage message={errors.value} />}</div>
        </div>
      </>
    );
  }
}

TriggerConditionForm.defaultProps = {
  condition: {},
  audience:  '',
  onChange:  () => false,
};

TriggerConditionForm.propTypes = {
  condition: PropTypes.shape({}),
  audience:  PropTypes.string,
  onChange:  PropTypes.func,
};

export default TriggerConditionForm;
