const AgentActionTypes = {
  LOAD_LEADS:                 'LOAD_LEADS',
  LOAD_LEADS_DONE:            'LOAD_LEADS_DONE',
  LOAD_LEADS_FAIL:            'LOAD_LEADS_FAIL',

  SWITCH_OWNER_SCOPE:         'SWITCH_OWNER_SCOPE',
  SWITCH_LEAD_SCOPE_SIZE:     'SWITCH_LEAD_SCOPE_SIZE',

  BULK_UPDATE_LEADS:          'BULK_UPDATE_LEADS',
  BULK_UPDATE_LEADS_DONE:     'BULK_UPDATE_LEADS_DONE',
  BULK_UPDATE_LEADS_FAIL:     'BULK_UPDATE_LEADS_FAIL',

  BULK_DELETE_LEADS:          'BULK_DELETE_LEADS',
  BULK_DELETE_LEADS_DONE:     'BULK_DELETE_LEADS_DONE',
  BULK_DELETE_LEADS_FAIL:     'BULK_DELETE_LEADS_FAIL',

  LOAD_TEAM_MEMBERS:          'LOAD_TEAM_MEMBERS',
  LOAD_TEAM_MEMBERS_DONE:     'LOAD_TEAM_MEMBERS_DONE',
  LOAD_TEAM_MEMBERS_FAIL:     'LOAD_TEAM_MEMBERS_FAIL',

  CREATE_LEAD_ACTIVITY:       'CREATE_LEAD_ACTIVITY',
  CREATE_LEAD_ACTIVITY_DONE:  'CREATE_LEAD_ACTIVITY_DONE',
  CREATE_LEAD_ACTIVITY_FAIL:  'CREATE_LEAD_ACTIVITY_FAIL',

  BULK_DELETE_TASKS:          'BULK_DELETE_TASKS',
  BULK_DELETE_TASKS_DONE:     'BULK_DELETE_TASKS_DONE',
  BULK_DELETE_TASKS_FAIL:     'BULK_DELETE_TASKS_FAIL',

  BULK_UPDATE_TASKS:          'BULK_UPDATE_TASKS',
  BULK_UPDATE_TASKS_DONE:     'BULK_UPDATE_TASKS_DONE',
  BULK_UPDATE_TASKS_FAIL:     'BULK_UPDATE_TASKS_FAIL',

  RESET_STORE:                'RESET_STORE',
};

export default AgentActionTypes;
