import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Container }       from 'flux/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit               from '~/lib/brokerbit';
import LeadHelpers             from '~/helpers/lead_helpers';
import AppointmentActions      from '~/actions/appointment_actions';
import AppointmentStore        from '~/stores/appointment_store';
import { getRestrictionScope } from '~/helpers/staffRestrictionHelper';

class AppointmentCard extends React.Component {
  static getStores() {
    return [AppointmentStore];
  }

  static calculateState(prevState) {
    return {
      appointmentStoreState: AppointmentStore.getState(),
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'today',
    };

    autoBind(this);
  }

  componentDidMount() {
    const currentRestriction = getRestrictionScope();
    const restrictStaffRoleAccessScope = currentRestriction ? 'mine' : null;
    const scope =  restrictStaffRoleAccessScope || localStorage.ownerScope || 'all';

    AppointmentActions.loadAppointments({ ownerScope: scope });
  }

  handleLeadClick(lead, defaultAction = null) {
    if (!lead) return;
    const { helpers } = this.context;

    helpers.openLeadDrawer({ loadLeadID: lead.id, defaultAction });
  }

  handleApptDeleteClick(appt) {
    if (!appt) return;

    brokerbit.confirmBox({
      message:  'Deleting removes the appointment only from Brokerkit. Be sure to update it in your Google calendar.',
      callback: (ok) => {
        if (ok) {
          AppointmentActions.deleteAppointment(appt.id);
        }
      },
    });
  }

  switchTab(tabKey) {
    this.setState({
      currentTab: tabKey,
    });
  }

  fetchAppointments() {
    const { appointmentStoreState, currentTab } = this.state;
    const {
      today, thisWeek, nextWeek, lastWeek,
    } = appointmentStoreState.appointments;

    const apptTables = [
      {
        title:        'Today',
        key:          'today',
        selected:     currentTab === 'today',
        appointments: today || [],
      },
      {
        title:        'This Week',
        key:          'this_week',
        selected:     currentTab === 'this_week',
        appointments: thisWeek || [],
      },
      {
        title:        'Next Week',
        key:          'next_week',
        selected:     currentTab === 'next_week',
        appointments: nextWeek || [],
      },
      {
        title:        'Last Week',
        key:          'last_week',
        selected:     currentTab === 'last_week',
        appointments: lastWeek || [],
      },
    ];

    return apptTables;
  }

  renderTabs() {
    const apptTables = this.fetchAppointments();
    const tabs = [];

    apptTables.forEach((apptTable) => {
      const navLinkClass = classNames('nav-link', { active: apptTable.selected });
      let apptCount;

      if (apptTable.appointments.length > 0) {
        apptCount = <span className="ml5 badge badge-green">{apptTable.appointments.length}</span>;
      }

      const tabMenu = (
        <li className="nav-item" key={`tab-${apptTable.key}`}>
          <a
            href={`#tab_${apptTable.key}`}
            className={navLinkClass}
            onClick={(e) => {
              e.preventDefault();
              this.switchTab(apptTable.key);
            }}
          >
            {apptTable.title}
            {' '}
            {apptCount}
          </a>
        </li>
      );

      tabs.push(tabMenu);
    });

    return (
      <ul className="nav nav-tabs">
        {tabs}
      </ul>
    );
  }

  renderTabContent() {
    const apptTables = this.fetchAppointments();

    const tabContent = [];
    apptTables.forEach((apptTable) => {
      const tabPaneClass = classNames('table-responsive tab-pane', { active: apptTable.selected });

      const tabContentItem = (
        <div className={tabPaneClass} key={`tab-pane-${apptTable.key}`}>
          {this.renderAppointmentTable(apptTable.appointments)}
        </div>
      );

      tabContent.push(tabContentItem);
    });

    return (
      <div className="tab-content">
        {tabContent}
      </div>
    );
  }

  renderAppointmentTable(appointments) {
    const tableRows = [];

    appointments.forEach((appointment) => {
      const { lead }   = appointment;
      const now        = Moment();
      const apptMoment = Moment(appointment.appointment_at);
      const diffDays   = apptMoment.diff(now, 'days');

      let apptDateText = apptMoment.format('MMM DD YYYY [at] h:mma');
      if (apptMoment >= Moment().startOf('day') && apptMoment < Moment().endOf('day')) {
        apptDateText = apptMoment.format('[Today at] h:mma');
      }

      let meetingButton;
      if (appointment.logged) {
        meetingButton = (
          <button type="button" name="button" className="btn btn-secondary btn-sm mb5" disabled>
            <FontAwesomeIcon icon={['far', 'fa-check']} />
            {' '}
            Logged
          </button>
        );
      } else {
        meetingButton = (
          <button
            type="button"
            className="btn btn-sm btn-success mb5"
            onClick={() => {
              this.handleLeadClick(lead, 'log-appointment');
            }}
          >
            Log Meeting
          </button>
        );
      }

      let rescheduleButton;
      if (!appointment.logged) {
        rescheduleButton = (
          <button
            type="button"
            className="btn btn-sm btn-secondary reschedule mb5"
            onClick={() => {
              this.handleLeadClick(lead, 'appointment');
            }}
          >
            Reschedule
          </button>
        );
      }

      const rowItem = (
        <tr id={`appt-${appointment.id}`} key={`appt-${appointment.id}`}>
          <td
            className="text-nowrap no-border clickable"
            onClick={() => {
              this.handleLeadClick(lead);
            }}
          >
            <span className="mr5">{lead.name}</span>
            {LeadHelpers.renderProductionBadge(lead)}
          </td>
          <td
            className="text-grey no-border clickable"
            onClick={() => {
              this.handleLeadClick(lead);
            }}
          >
            <FontAwesomeIcon icon={['far', 'fa-calendar-alt']} />
            {' '}
            {apptDateText}
          </td>
          <td className="hidden-sm-down no-border text-nowrap">
            <span className="mr5">{meetingButton}</span>
            <span className="mr5">{rescheduleButton}</span>

            <button
              type="button"
              className="btn btn-secondary btn-sm appt-destroy mb5"
              onClick={() => {
                this.handleApptDeleteClick(appointment);
              }}
            >
              <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
            </button>
          </td>
          <td className="hidden-sm-down no-border text-right">
            {LeadHelpers.renderUserAvatar(appointment.owner)}
          </td>
        </tr>
      );

      tableRows.push(rowItem);
    });

    if (appointments.length === 0) {
      return <p className="card-block text-grey">No Appointments</p>;
    }

    return (
      <table className="table appointments-table table-hover mt15">
        <tbody>
          {tableRows}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="card">
        <div className="card-block">
          <h4>Upcoming Appointments</h4>
        </div>

        <div className="card-block pt0 pb0">
          {this.renderTabs()}
        </div>

        {this.renderTabContent()}
      </div>
    );
  }
}

AppointmentCard.contextTypes = {
  helpers: PropTypes.shape({}),
};

AppointmentCard.defaultProps = {};

AppointmentCard.propTypes = {};

export default Container.create(AppointmentCard);
