import React, { useRef, useState, useEffect } from 'react';
import { DirectUpload }                       from '@rails/activestorage';
import { FontAwesomeIcon }                    from '@fortawesome/react-fontawesome';
import { Viewer, Worker, SpecialZoomLevel }   from '@react-pdf-viewer/core';
import { toolbarPlugin }                      from '@react-pdf-viewer/toolbar';
import { printPlugin }                        from '@react-pdf-viewer/print';
import { getFilePlugin }                      from '@react-pdf-viewer/get-file';
import Pubnub                                 from 'pubnub';
import LeadDrawerActions                      from '~/actions/lead_drawer_actions';
import LeadDrawerStore                        from '~/stores/lead_drawer_store';
import brokerbit                              from '~/lib/brokerbit';
import Tooltipable                            from '~/components/effects/tooltipable';

// 25 MB in bits
const MAX_FILE_SIZE = 26214400;

// 25 MB in megabytes
const MAX_FILE_SIZE_IN_MB = MAX_FILE_SIZE / (1024 * 1024);

const disableScrollPlugin = () => {
  const renderViewer = (props) => {
    const { slot } = props;

    if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
      slot.subSlot.attrs.style = { ...slot.subSlot.attrs.style, scrollbarWidth: 'thin', scrollbarColor: 'white white' };
    }

    return slot;
  };

  return {
    renderViewer,
  };
};

const disableScrollPluginInstance = disableScrollPlugin();
const workerUrl = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';
const uploadUrl = `${Rails.apiUrl}/rails/active_storage/direct_uploads`;

const pubnub = new Pubnub({
  publishKey:   process.env.PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
  uuid:         process.env.PUBNUB_DOCUMENTS_UUID,
});

const ResumeDrawer = () => {
  const isAdminOrStaff = Rails.abilities.manageLead;
  const windowTitle = document.title;

  const setWindowTitleForPrint = () => {
    const { lead } = LeadDrawerStore.getState();
    document.title = `${lead.first_name} ${lead.last_name} - Resume`;
  };

  const resetWindowTitle = () => {
    document.title = windowTitle;
  };

  useEffect(() => {
    const onMessageReceived = (payload) => {
      setResumeUrl(payload.message.document_url);
      setResume({ ...resume, document_type: 'pdf_resume' });
    };

    const leadDrawerListener = LeadDrawerStore.addListener(onLeadDrawerStoreChange);
    pubnub.addListener({ message: onMessageReceived });

    window.addEventListener('beforeprint', setWindowTitleForPrint);

    window.addEventListener('afterprint', resetWindowTitle);

    return () => {
      leadDrawerListener.remove();
      pubnub.removeListener({ message: onMessageReceived });
      window.removeEventListener('beforeprint', setWindowTitleForPrint);
      window.removeEventListener('afterprint', resetWindowTitle);
    };
  }, []);

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const printPluginInstance = printPlugin();
  const { print } = printPluginInstance;
  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  const { lead } = LeadDrawerStore.getState();
  const [resumeUrl, setResumeUrl] = useState(lead.document?.url || null);
  const resumeId = lead.document?.id || null;

  const [resume, setResume] = useState({
    id:            null,
    document_file: '',
    title:         null,
    document_type: null,
  });

  useEffect(() => {
    setResumeUrl(lead.document?.url || null);
  }, [lead]);

  const hiddenFileInput = useRef(null);

  const handleFileSelect = (e) => {
    e.preventDefault();

    hiddenFileInput.current.value = '';
    hiddenFileInput.current.click();

    hiddenFileInput.current.onchange = (event) => {
      const file = event.target.files[0];
      uploadResume(file);
    };
  };

  const checkFileTypeAndSize = (file) => {
    const fileType = file.name.split('.').pop();
    const validFileTypes = ['pdf', 'doc', 'docx'];
    const isFileTypeValid = validFileTypes.includes(fileType);
    const isSizeWithinLimit = file.size <= MAX_FILE_SIZE;

    if (!file) return null;

    if (!isFileTypeValid) {
      GlobalContainer.notify(
        `Unsupported Format: Only ${validFileTypes.join(', ').toUpperCase()} files are accepted.`,
        'error',
      );
      return null;
    }

    if (!isSizeWithinLimit) {
      GlobalContainer.notify(
        `File Size Limit Exceeded: Ensure your file is within ${MAX_FILE_SIZE_IN_MB} megabytes`,
        'error',
      );
      return null;
    }

    return fileType;
  };

  const uploadResume = (file) => {
    const fileType = checkFileTypeAndSize(file);
    if (!fileType) return;

    const upload = new DirectUpload(file, uploadUrl, {
      directUploadWillCreateBlobWithXHR: (xhr) => {
        xhr.setRequestHeader('Authentication', `${Rails.helpers.authToken}`);
      },
    });

    upload.create((error, blob) => {
      if (!error) {
        const document = ({
          id:            null,
          document_file: blob.signed_id,
          title:         file.name,
          lead_id:       lead.id,
          document_type: fileType === 'pdf' ? 'pdf_resume' : 'word_resume',
        });

        setResume(document);

        LeadDrawerActions.uploadResume({ document }, lead);
      }
    });
  };

  const onLeadDrawerStoreChange = () => {
    const storeState = LeadDrawerStore.getState();
    const { leadDrawerStoreAction } = storeState;

    const validActions = ['createResumeDone', 'updateResumeDone'];
    if (validActions.includes(leadDrawerStoreAction)) {
      const { lead: { document } } = storeState;

      const documentIsWordType = document.document_type === 'word_resume';

      if (documentIsWordType) {
        GlobalContainer.notify(
          'Document conversion in progress. Please check back in a few seconds.',
          'success',
        );
        pubnub.subscribe({
          channels: [`document_conversion-${resumeId}`],
        });
      }
    }

    if (leadDrawerStoreAction === 'deleteResumeDone') {
      setResume({
        id:            null,
        document_file: '',
        title:         null,
        document_type: null,
      });
      setResumeUrl(null);
    }
  };

  const handleDeleteResume = (e) => {
    e.preventDefault();

    if (lead.document?.url) {
      brokerbit.confirmBox({
        message:  'Are you sure you want to delete this?',
        callback: (ok) => {
          if (ok) {
            LeadDrawerActions.deleteResume(lead);
          }
        },
      });
    }
  };

  return (
    <>
      <span className="pull-right mt15">
        <Tooltipable text="Print the resume">
          <button
            type="button"
            className="btn btn-secondary btn-sm mr8"
            onClick={print}
          >
            <FontAwesomeIcon icon={['far', 'fa-print']} />
          </button>
        </Tooltipable>

        <Download>
          {(props) => (
            <Tooltipable text="Download the resume">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr8"
                onClick={props.onClick}
              >
                <FontAwesomeIcon icon={['far', 'fa-download']} />
              </button>
            </Tooltipable>
          )}
        </Download>

        { isAdminOrStaff && (
          <Tooltipable text="Upload the Resume">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr8"
              onClick={handleFileSelect}
            >
              <FontAwesomeIcon icon={['far', 'fa-upload']} />
            </button>
          </Tooltipable>
        )}

        {isAdminOrStaff && (
          <Tooltipable text="Delete the Resume">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr8"
              onClick={handleDeleteResume}
            >
              <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
            </button>
          </Tooltipable>
        )}

        <input
          type="file"
          ref={hiddenFileInput}
          hidden
        />
      </span>
      <div className="resume bg-color px-4">
        <Worker workerUrl={workerUrl}>
          <Toolbar>
            {(props) => {
              const {
                CurrentPageInput,
                GoToNextPage,
                GoToPreviousPage,
                Zoom,
                ZoomIn,
                ZoomOut,
                EnterFullScreen,
              } = props;
              return (
                <div className="p8 mb5 mt60">
                  <div className="pull-right resume toolbar resume-icon">
                    <span className="mr5">
                      <ZoomIn />
                    </span>
                    <span className="vertical-line" />
                    <span className="ml5">
                      <ZoomOut />
                    </span>
                    <span className="mr10 ml10 resume zoom-button">
                      <Zoom />
                    </span>
                    <EnterFullScreen />
                  </div>
                  <div className="resume toolbar">
                    <span className="ml10">
                      <GoToPreviousPage />
                    </span>
                    <span className="vertical-line" />
                    <span className="mr10">
                      <GoToNextPage />
                    </span>
                    <span className="mr10 toolbar-text-color">Page</span>
                    <span className="rpv-page-navigation__current-page-input">
                      <CurrentPageInput />
                    </span>
                  </div>
                </div>
              );
            }}
          </Toolbar>
          <div id={`contentToPrint_${resumeId}`} className="pl10 pr10 resume height text-center">
            {(() => {
              if (resumeUrl && lead.document?.document_type === 'pdf_resume') {
                return (
                  <Viewer
                    fileUrl={resumeUrl}
                    plugins={[toolbarPluginInstance, printPluginInstance, getFilePluginInstance, disableScrollPluginInstance]}
                    defaultScale={SpecialZoomLevel.ActualSize}
                  />
                );
              }
              return (
                <span className="resume text">
                  Upload a Resume
                </span>
              );
            })()}
          </div>
        </Worker>
      </div>
    </>
  );
};

export default ResumeDrawer;
