import bootbox from 'bootbox';

/**
 * BrokerBit application object - Store all common library methods
 * @type {Object}
 */
const brokerbit = {
  /**
   * Open confirmation box and execute callback
   * @param {Object} options - Confirmation options
   * @param {string} options.message - Confirmation message
   * @param {string} options.size - Box size
   * @param {string} options.confirmLabel - Confirm label
   * @param {string} options.cancelLabel - Cancel label
   * @param {callback} options.callback
   */
  confirmBox(options = {}) {
    const defaults = {
      title:        '',
      message:      'Are you sure?',
      size:         'small',
      confirmLabel: 'Yes',
      confirmClass: 'btn-success',
      cancelClass:  'btn-secondary',
      cancelLabel:  'No',
      callback:     () => false,
    };

    const opts = Object.assign(defaults, options);

    const showModal = (callback) => (ok) => {
      callback(ok);

      /* Hack to enable scroll on mobile, since you can't edit bootbox code
       * Issue is that bootbox, hides all modals on page, disabling scroll
       */
      setTimeout(() => {
        const $drawer = $('.drawer');
        const $page = $('.leads-pages');

        if ($drawer.length) {
          $page.addClass('modal-open');
          $drawer.modal({ keyboard: false, backdrop: 'static' });
        }
      }, 500);
    };

    bootbox.confirm({
      title:   opts.title,
      size:    opts.size,
      message: opts.message,
      buttons: {
        confirm: {
          label:     opts.confirmLabel,
          className: opts.confirmClass,
        },
        cancel: {
          label:     opts.cancelLabel,
          className: opts.cancelClass,
        },
      },
      callback: showModal(opts.callback),
    });
  },

  dialogBox(options = {}) {
    const defaults = {
      title:      '',
      message:    'Are you sure?',
      size:       'small',
      okLabel:    'Ok',
      okClass:    'btn-success',
      okCallback: () => false,
    };

    const opts = Object.assign(defaults, options);

    bootbox.dialog({
      title:   opts.title,
      size:    opts.size,
      message: opts.message,
      buttons: {
        ok: {
          label:     opts.okLabel,
          className: opts.okClass,
          callback:  opts.okCallback,
        },
      },
    });
  },

  /**
   * Compare current class and return *active* if matched
   * @param {string} currentType - Current element type
   * @param {string} selectedType - Selected element type
   * @returns {string} 'active' or ''
   */
  isActive(currentType, selectedType) {
    if (currentType === selectedType) {
      return 'active';
    }

    return '';
  },
};

export default brokerbit;
