import autoBind from 'react-autobind';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EmailDomainActions from '~/actions/email_domain_actions';
import EmailDomainStore from '~/stores/email_domain_store';
import EmailDomainsTable from './email_domains_table';
import DomainForm from './forms/domain_form';

class ListingEmailDomains extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingDomains: false,
      domains:        [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.domainStoreListener = EmailDomainStore.addListener(
      this.onDomainStoreChange,
    );

    EmailDomainActions.loadDomains();
  }

  componentWillUnmount() {
    if (this.domainStoreListener) {
      this.domainStoreListener.remove();
    }
  }

  onDomainStoreChange() {
    const domainStoreState = EmailDomainStore.getState();
    const {
      loadingDomains, lastDomainStoreAction, errors, domains,
    } = domainStoreState;

    if (lastDomainStoreAction === 'verifyEmailDomainDone') {
      GlobalContainer.notify(
        'Verifying DNS settings. This may take some time',
        'success',
      );
    }

    if (lastDomainStoreAction === 'verifyEmailDomainFail') {
      GlobalContainer.notify(errors, 'error');
    }

    this.setState({
      loadingDomains,
      domains,
    });
  }

  deleteDomain(domain) {
    if (_lodash.isEmpty(domain)) return;

    EmailDomainActions.deleteDomain(domain);
  }

  renderDomainsTable() {
    const { loadingDomains, domains } = this.state;

    if (loadingDomains) {
      return (
        <div className="text-center card-block">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (domains.length === 0) {
      return (
        <div className="text-grey-dark card-block text-center">
          You don&apos;t have any sending domains, please add...
        </div>
      );
    }

    return (
      <EmailDomainsTable
        domains={domains}
        deleteDomain={this.deleteDomain}
      />
    );
  }

  render() {
    return (
      <>
        <div className="mb15">
          <DomainForm />
        </div>

        {this.renderDomainsTable()}
      </>
    );
  }
}

ListingEmailDomains.defaultProps = {};

ListingEmailDomains.propTypes = {};

export default ListingEmailDomains;
