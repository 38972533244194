import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select-4';
import Tooltipable from '~/components/effects/tooltipable';

import Select from './NewSelect';

const CampaignStepOnSelect = (props) => {
  const { value } = props;
  const options = [
    { value: '*', label: 'Any Day' },
    { value: 'mon-fri', label: 'Mon-Fri' },
    { value: 'mon-sat', label: 'Mon-Sat' },
    { value: 'sat-sun', label: 'Sat-Sun' },
    { value: 'sun', label: 'Sunday' },
    { value: 'mon', label: 'Monday' },
    { value: 'tue', label: 'Tuesday' },
    { value: 'wed', label: 'Wednesday' },
    { value: 'thu', label: 'Thursday' },
    { value: 'fri', label: 'Friday' },
    { value: 'sat', label: 'Saturday' },
  ];

  return (
    <Select
      {...props}
      components={{
        Control: ({ children, ...rest }) => (
          <Tooltipable
            text="Choose the day(s) of the week that this step of the campaign will be executed."
            placement="top"
          >
            <components.Control {...rest}>
              {children}
            </components.Control>
          </Tooltipable>
        ),
      }}
      options={options}
      value={value}
    />
  );
};

CampaignStepOnSelect.defaultProps = {
  onChange:  () => {},
  value:     '',
  isInvalid: false,
};

CampaignStepOnSelect.propTypes = {
  onChange: PropTypes.func,
  value:    PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  isInvalid: PropTypes.bool,
};

export default CampaignStepOnSelect;
