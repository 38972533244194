import React         from 'react';
import ReactSelect   from 'react-select';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      value: this.props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ value: props.value });
  }

  onChange(val) {
    this.setState({ value: val });

    const { onChange } = this.props;
    if (onChange) { onChange(val); }
  }

  arrowRenderer() {
    return <FontAwesomeIcon icon={['fas', 'fa-angle-down']} />;
  }

  render() {
    const {
      name, creatable, clearable,
    } = this.props;

    if (creatable) {
      return (
        <ReactSelect.Creatable
          ref={(input) => { this.input = input; }}
          {...this.props}
        />
      );
    }
    return (
      <ReactSelect
        {...this.props}
        arrowRenderer={this.arrowRenderer}
        ref={(input) => { this.input = input; }}
        name={name}
        clearable={clearable}
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}

Select.defaultProps = {
  name:        '',
  value:       '',
  multi:       false,
  creatable:   false,
  clearable:   false,
  options:     [],
  loadOptions: () => false,
  onChange:    () => false,
};

Select.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  multi:       PropTypes.bool,
  creatable:   PropTypes.bool,
  clearable:   PropTypes.bool,
  options:     PropTypes.arrayOf(PropTypes.shape({})),
  loadOptions: PropTypes.func,
  onChange:    PropTypes.func,
};

export default Select;
