import autoBind      from 'react-autobind';
import React         from 'react';

import APIRequest      from '~/lib/api_request';
import BlankPage       from '~/components/pages/blank_page';
import GlobalContainer from '~/components/global_container';
import TeamMembersCore from '~/components/pages/account/team_members/team_members_core';
import AgentSideBar    from '~/components/navigation/agent_side_bar';

class OnboardApp extends React.Component {
  constructor() {
    super();
    autoBind(this);

    this.state = {
      message:
        "Hi Team, we're excited to be moving to Brokerkit, "
        + 'the latest and greatest platform for making referrals and tracking their recruiting progress. '
        + 'You also have a new landing page that you can use to get more referrals. Thanks so much!',
      teamMembersState: {
        notJoinedCount: 0,
      },
    };
  }

  handleTeamMembersStoreChange(teamMembersState) {
    this.setState({
      teamMembersState,
    });
  }

  handleSendInvitation = () => {
    const { currentTeam } = Rails.helpers;
    const { message } = this.state;

    APIRequest.post({
      resource: `/v1/teams/${currentTeam.id}/launch`,
      data:     {
        team: { welcome_message: message },
      },
    }).end((error, response) => {
      GlobalContainer.notify('Team invitations have been sent', 'success');
      browserHistory.push('/recruiter/today');
    });
  };

  render() {
    const { currentTeam } = Rails.helpers;
    const { message, teamMembersState } = this.state;
    const { notJoinedCount } = teamMembersState;

    return (
      <BlankPage>
        <div className="row">
          <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar pr-0" style={{ minWidth: 'fit-content' }}>
            <AgentSideBar/>
          </nav>

          <div className="col-md-8 col-lg-9">
            <div className="container mt100 mb100">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center mb30 pl100-lg-up pr100-lg-up">
                    <h1 className="mb5">Invite Your Agents</h1>
                    <p className="lead mb15">
                      Invite your agents and affiliates to refer leads to
                      {' '}
                      <strong>{currentTeam.name}</strong>
                      .
                      <br />
                      Once invited, they can refer new leads and view the status of
                      leads they have referred.
                    </p>
                  </div>
                  <div className="card p20 mb15">
                    <p className="lead mb5">
                      Add a custom message to the team invite:
                    </p>
                    <textarea
                      name="team[welcome_message]"
                      className="form-control"
                      rows="4"
                      defaultValue={message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                    />
                  </div>

                  <TeamMembersCore
                    title="Team Members Yet to Join"
                    tabs={['agents', 'affiliates']}
                    notify={false}
                    filter={['not_joined']}
                    drawerExternalRolesOnly
                    sidebar={false}
                    mode="onboarding"
                    per={5}
                    onTeamMembersStoreChange={this.handleTeamMembersStoreChange}
                  />

                  <div className="row mt30">
                    <div className="col-sm-6">
                      <a href="/onboard/cancel" className="btn btn-secondary">
                        Cancel and Don&#39;t Onboard
                      </a>
                    </div>
                    <div className="col-sm-6 text-right">
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={this.handleSendInvitation}
                      >
                        Send Team Invitation to
                        {' '}
                        {notJoinedCount}
                        {' '}
                        Referrers
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlankPage>
    );
  }
}

export default OnboardApp;
