import AppDispatcher                from '~/dispatchers/app_dispatcher';
import APIRequest                   from '~/lib/api_request';
import SubscriptionLimitActionTypes from '~/types/subscription_limit_action_types';

let loadSubscriptionLimitRequest;
let checkIfVideosLimitReachedRequest;

const SubscriptionLimitActions = {
  loadSubscriptionLimits() {
    APIRequest.abort(loadSubscriptionLimitRequest);

    AppDispatcher.dispatch({
      type: SubscriptionLimitActionTypes.LOAD_SUBSCRIPTION_LIMITS,
    });

    loadSubscriptionLimitRequest = APIRequest.get({
      resource: '/v1/subscription_limits',
    });

    loadSubscriptionLimitRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: SubscriptionLimitActionTypes.LOAD_SUBSCRIPTION_LIMITS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:               SubscriptionLimitActionTypes.LOAD_SUBSCRIPTION_LIMITS_DONE,
        subscriptionLimits: response.body,
      });
    });
  },

  checkIfVideosLimitReached() {
    APIRequest.abort(checkIfVideosLimitReachedRequest);

    AppDispatcher.dispatch({
      type: SubscriptionLimitActionTypes.CHECK_IF_VIDEOS_LIMIT_REACHED,
    });

    checkIfVideosLimitReachedRequest = APIRequest.get({
      resource: '/v1/videos/videos_limit',
    });

    checkIfVideosLimitReachedRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: SubscriptionLimitActionTypes.CHECK_IF_VIDEOS_LIMIT_REACHED_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: SubscriptionLimitActionTypes.CHECK_IF_VIDEOS_LIMIT_REACHED_DONE,
        data: response.body,
      });
    });
  },
};

export default SubscriptionLimitActions;
