import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import Select from './SelectWrapper/Select';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';

import APIRequest from '~/lib/api_request';

const RECRUITING_STATUSES_WITH_ONBOARDING = [
  { value: 'new_lead', label: 'New Lead' },
  { value: 'contact_attempt', label: 'Contact Attempt' },
  { value: 'contact_made', label: 'Contact Made' },
  { value: 'appt_set', label: 'Appt Set' },
  { value: 'needs_evaluated', label: 'Needs Evaluated' },
  { value: 'onboarding', label: 'Signed' },
];
const RETENTION_STATUSES = [{ value: 'onboarding', label: 'Onboarding' }, { value: 'active', label: 'Active' }];
const RECRUITING_STATUSES = RECRUITING_STATUSES_WITH_ONBOARDING.filter((status) => status.value !== 'onboarding');
const ALL_STATUSES = [...RECRUITING_STATUSES, ...RETENTION_STATUSES];

const StatusSelect = ({
  id,
  name,
  stage,
  value = '',
  placeholder = 'Select a Status',
  onChange,
  isMulti,
  isInvalid,
  isClearable,
  isSearchable,
}) => {
  const isMounted = useRef(true);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const displayCustomStatusesFlag = checkFeatureFlag(process.env.DISPLAY_CUSTOM_STATUSES_FLAG);

    if (displayCustomStatusesFlag) {
      setIsLoading(true);

      APIRequest.get({
        resource: '/v1/leads/statuses/options',
        data:     {
          status: {
            stage,
          },
        },
      }).end((error, response) => {
        if (isMounted.current) {
          if (!error) {
            const fetchedOptions = response.body.map((status) => {
              const label = stage === 'recruiting_with_shared' && status.alternate_name
                ? status.alternate_name
                : status.name;

              return { label, value: status.id };
            });
            setOptions(fetchedOptions);
          }

          setIsLoading(false);
        }
      });
    } else {
      const productOptions = GlobalContainer.product() === 'retention' ? RETENTION_STATUSES : RECRUITING_STATUSES_WITH_ONBOARDING;
      setOptions(productOptions);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Select
      id={id}
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isMulti={isMulti}
      isInvalid={isInvalid}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
    />
  );
};

StatusSelect.defaultProps = {
  id:           '',
  name:         '',
  stage:        '',
  placeholder:  'Select a Status',
  onChange:     () => {},
  value:        '',
  isMulti:      false,
  isInvalid:    false,
  isClearable:  false,
  isSearchable: false,
};

StatusSelect.propTypes = {
  id:          PropTypes.string,
  name:        PropTypes.string,
  stage:       PropTypes.string,
  placeholder: PropTypes.string,
  onChange:    PropTypes.func,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.shape({}),
  ]),
  isMulti:      PropTypes.bool,
  isInvalid:    PropTypes.bool,
  isClearable:  PropTypes.bool,
  isSearchable: PropTypes.bool,
};

export { RECRUITING_STATUSES, ALL_STATUSES };
export default StatusSelect;
