import React from 'react';

const A2pNotApprovedWarning = () => (
  <div className="alert alert-warning p-2 mb-2" role="alert">
    This number was added to an already approved verified texting campaign but the profile was not submitted. To comply with carrier texting regulations, please resubmit the profile
    {' '}
    <a target="_blank" href="/users/phone_number/edit#verified_texting" className="text-green">here</a>
    {' '}
    to enable texting from this number. Once submitted, your number will be reviewed and approved overnight, typically within 24 hours.
  </div>
);

export default A2pNotApprovedWarning;
