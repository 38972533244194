const VideoActionTypes = {
  LOAD_VIDEOS:      'LOAD_VIDEOS',
  LOAD_VIDEOS_DONE: 'LOAD_VIDEOS_DONE',
  LOAD_VIDEOS_FAIL: 'LOAD_VIDEOS_FAIL',

  LOAD_VIDEO:      'LOAD_VIDEO',
  LOAD_VIDEO_DONE: 'LOAD_VIDEO_DONE',
  LOAD_VIDEO_FAIL: 'LOAD_VIDEO_FAIL',

  CREATE_VIDEO:      'CREATE_VIDEO',
  CREATE_VIDEO_DONE: 'CREATE_VIDEO_DONE',
  CREATE_VIDEO_FAIL: 'CREATE_VIDEO_FAIL',

  UPDATE_VIDEO:      'UPDATE_VIDEO',
  UPDATE_VIDEO_DONE: 'UPDATE_VIDEO_DONE',
  UPDATE_VIDEO_FAIL: 'UPDATE_VIDEO_FAIL',

  DELETE_VIDEO:      'DELETE_VIDEO',
  DELETE_VIDEO_DONE: 'DELETE_VIDEO_DONE',
  DELETE_VIDEO_FAIL: 'DELETE_VIDEO_FAIL',
};

export default VideoActionTypes;
