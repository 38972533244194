import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import AppointmentForm from './forms/appointment_form';
import EmailForm from './forms/email_form';
import EmailSentForm from './forms/email_sent_form';
import NoteForm from './forms/note_form';
import LogAppointmentForm from './forms/log_appointment_form';
import PhoneCallForm from './forms/phone_call_form';
import TextMessageForm from './forms/text_message_form';
import LogTextMessageForm from './forms/log_text_message_form';
import AddTaskForm from './forms/add_task_form';

export const isFormNameValid = (name) => {
  const validFormNames = [
    'appointment',
    'text-message',
    'email',
    'email-sent',
    'general-note',
    'log-appointment',
    'phone-call',
    'log-text-message',
    'add-task',
  ];

  return validFormNames.includes(name);
};

const LeadDrawerForms = ({ currentForm, ...props }) => {
  const formRef = useRef(null);

  const renderForm = (name) => {
    switch (name) {
      case 'appointment':
        return <AppointmentForm {...props} />;
      case 'text-message':
        return <TextMessageForm {...props} />;
      case 'email':
        return <EmailForm {...props} />;
      case 'email-sent':
        return <EmailSentForm {...props} />;
      case 'general-note':
        return <NoteForm {...props} />;
      case 'log-appointment':
        return <LogAppointmentForm {...props} />;
      case 'phone-call':
        return <PhoneCallForm {...props} />;
      case 'log-text-message':
        return <LogTextMessageForm {...props} />;
      case 'add-task':
        return <AddTaskForm {...props} />;
      default:
        return null;
    }
  };

  const form = renderForm(currentForm);

  return (
    <div ref={formRef} className="row">
      <div className="col-12">{form}</div>
    </div>
  );
};

LeadDrawerForms.defaultProps = {
  currentForm: null,
  lead:        null,
};

LeadDrawerForms.propTypes = {
  currentForm: PropTypes.string,
  lead:        PropTypes.shape({}),
};

export default LeadDrawerForms;
