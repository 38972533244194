import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class ActionSelect extends React.Component {
  constructor(props) {
    super(props);

    const options = [
      { value: 'start', label: 'START' },
      { value: 'stop',  label: 'STOP' },
    ];

    this.state = {
      options,
    };

    autoBind(this);
  }

  render() {
    const { options } = this.state;
    const { value, onChange } = this.props;

    return (
      <Select
        {...this.props}
        value={value}
        onChange={onChange}
        options={options}
        style={{ width: '80px' }}
      />
    );
  }
}

ActionSelect.defaultProps = {
  onChange:    () => {},
  value:       '',
};

ActionSelect.propTypes = {
  onChange:    PropTypes.func,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
};

export default ActionSelect;
