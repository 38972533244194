import AppDispatcher          from '~/dispatchers/app_dispatcher';
import APIRequest             from '~/lib/api_request';
import SmsTemplateActionTypes from '~/types/sms_template_action_types';

let loadSmsTemplatesRequest;
let createSmsTemplateRequest;
let updateSmsTemplateRequest;
let deleteSmsTemplateRequest;

const SmsTemplateActions = {
  loadSmsTemplates(componentID = null) {
    APIRequest.abort(loadSmsTemplatesRequest);

    AppDispatcher.dispatch({
      type: SmsTemplateActionTypes.LOAD_SMS_TEMPLATES,
      componentID,
    });

    loadSmsTemplatesRequest = APIRequest.get({
      resource: '/v1/sms_templates',
    });

    loadSmsTemplatesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: SmsTemplateActionTypes.LOAD_SMS_TEMPLATES_FAIL,
          error,
          componentID,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:         SmsTemplateActionTypes.LOAD_SMS_TEMPLATES_DONE,
        smsTemplates: response.body,
        componentID,
      });
    });
  },

  createSmsTemplate(smsTemplate, componentID = null) {
    APIRequest.abort(createSmsTemplateRequest);

    AppDispatcher.dispatch({
      type: SmsTemplateActionTypes.CREATE_SMS_TEMPLATE,
      componentID,
    });

    createSmsTemplateRequest = APIRequest.post({
      resource: '/v1/sms_templates',
      data:     {
        sms_template: smsTemplate,
      },
    });

    createSmsTemplateRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   SmsTemplateActionTypes.CREATE_SMS_TEMPLATE_FAIL,
          errors: response.body,
          componentID,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        SmsTemplateActionTypes.CREATE_SMS_TEMPLATE_DONE,
        smsTemplate: response.body,
        componentID,
      });

      SmsTemplateActions.loadSmsTemplates(componentID);
    });
  },

  updateSmsTemplate(smsTemplate, componentID = null) {
    APIRequest.abort(updateSmsTemplateRequest);

    AppDispatcher.dispatch({
      type: SmsTemplateActionTypes.UPDATE_SMS_TEMPLATE,
      componentID,
    });

    updateSmsTemplateRequest = APIRequest.put({
      resource: `/v1/sms_templates/${smsTemplate.id}`,
      data:     {
        sms_template: smsTemplate,
      },
    });

    updateSmsTemplateRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   SmsTemplateActionTypes.UPDATE_SMS_TEMPLATE_FAIL,
          errors: response.body,
          componentID,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        SmsTemplateActionTypes.UPDATE_SMS_TEMPLATE_DONE,
        smsTemplate: response.body,
        componentID,
      });

      SmsTemplateActions.loadSmsTemplates(componentID);
    });
  },

  deleteSmsTemplate(templateID, componentID = null) {
    APIRequest.abort(deleteSmsTemplateRequest);

    AppDispatcher.dispatch({
      type: SmsTemplateActionTypes.DELETE_SMS_TEMPLATE,
      componentID,
    });

    deleteSmsTemplateRequest = APIRequest.delete({
      resource: `/v1/sms_templates/${templateID}`,
    });

    deleteSmsTemplateRequest.end((error, response) => {
      if (error) return;

      AppDispatcher.dispatch({
        type: SmsTemplateActionTypes.DELETE_SMS_TEMPLATE_DONE,
        componentID,
      });

      SmsTemplateActions.loadSmsTemplates(componentID);
    });
  },
};

export default SmsTemplateActions;
