import React, { useEffect, useState, useRef } from 'react';
import ReactDOM            from 'react-dom';
import { PropTypes }       from 'prop-types';
import { Editor }          from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VideosModal from '~/components/modals/videos/VideosModal';
import AssistModal from '~/components/modals/AiAssist/Modal';
import {
  TINYMCE_SCRIPT_PATH,
  LANDING_PAGE_EDITOR_PLUGINS,
  LANDING_PAGE_EDITOR_TOOLBAR,
  IMAGES_UPLOAD_HANDLER,
} from '~/constants/TinyMCE';
import { initWebSpellChecker } from '~/lib/web_spell_checker';

const LandingPageHtmlEditor = ({
  value,
  id,
  className,
  placeholder,
  onChange,
  spellCheck,
}) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    if (!spellCheck) return;

    const editor = editorRef.current
    if (editor) initWebSpellChecker(editor.iframeElement);
  }, [spellCheck]);

  const handleEditorChange = (_value, editor) => {
    const newContent = editor.getContent({ format: 'html' });
    setContent(newContent);
    onChange(newContent);
  };

  const handleOnInit = (_event, editor) => {
    editorRef.current = editor
    setLoading(false);

    if (value) {
      editor.setContent(value, { format: 'html' });
    }
  };

  return (
    <>
      <div className={className}>
        {loading && (
          <div className="text-center">
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
            {' '}
            Loading
          </div>
        )}

        <Editor
          id={id}
          tinymceScriptSrc={TINYMCE_SCRIPT_PATH}
          scriptLoading={{ async: true }}
          value={content}
          onEditorChange={handleEditorChange}
          onInit={handleOnInit}
          init={{
            placeholder,
            license_key:                   'gpl',
            plugins:                       LANDING_PAGE_EDITOR_PLUGINS,
            toolbar:                       LANDING_PAGE_EDITOR_TOOLBAR,
            toolbar_mode:                  'wrap',
            branding:                      false,
            menubar:                       false,
            file_picker_types:             'image',
            min_height:                    350,
            relative_urls:                 false,
            remove_script_host:            false,
            document_base_url:             Rails.baseUrl,
            forced_root_block:             'div',
            entity_encoding:               'named',
            content_style:                 'body { font-family: Arial; font-size: 13px; }',
            paste_as_text:                 false,
            contextmenu:                   false,
            paste_remove_styles_if_webkit: false,
            statusbar:                     true,
            images_upload_handler:         IMAGES_UPLOAD_HANDLER,
            setup:                         (editor) => {
              editor.ui.registry.addIcon('videoIcon', '<span class="fas fa-video-plus" />');

              editor.ui.registry.addButton('videos', {
                icon:     'videoIcon',
                tooltip:  'Insert Video',
                onAction: () => {
                  ReactDOM.render(
                    <VideosModal
                      containerID="secondary-modal"
                      modalClass="modal modal-overlay"
                      dialogClass="modal-dialog modal-lg"
                      editor={editor}
                    />, document.getElementById('secondary-modal'),
                  );
                },
              });

              editor.ui.registry.addButton('ai-assist', {
                icon:     'ai',
                tooltip:  'AI Assist',
                onAction: () => {
                  ReactDOM.render(
                    <AssistModal
                      containerID="ai-assist-modal"
                      modalClass="modal modal-overlay"
                      dialogClass="modal-dialog"
                      editor={editor}
                    />,
                    document.getElementById('ai-assist-modal'),
                  );
                },
              });
            },
          }}
        />
      </div>
    </>
  );
};

LandingPageHtmlEditor.defaultProps = {
  value:       '',
  className:   '',
  id:          '',
  placeholder: 'Enter text or insert / drop images ...',
  onChange:    () => false,
  spellCheck:  false,
};

LandingPageHtmlEditor.propTypes = {
  value:       PropTypes.string,
  className:   PropTypes.string,
  id:          PropTypes.string,
  placeholder: PropTypes.string,
  onChange:    PropTypes.func,
  spellCheck:  PropTypes.bool,
};

export default LandingPageHtmlEditor;
