import { useMemo } from 'react';

const useGroupedNotifications = (notifications) => useMemo(() => {
  const todayDate = Moment();
  const yesterdayDate = Moment().subtract(1, 'days');
  const result = [];
  const addedGroupHeaders = { today: false, yesterday: false, older: false };

  notifications.forEach((notification) => {
    const notificationDate = Moment(notification.CreatedAt);
    let groupKey;

    if (notificationDate.isSame(todayDate, 'day')) {
      groupKey = 'today';
    } else if (notificationDate.isSame(yesterdayDate, 'day')) {
      groupKey = 'yesterday';
    } else {
      groupKey = 'older';
    }

    if (!addedGroupHeaders[groupKey]) {
      result.push({ type: 'group', name: _lodash.upperFirst(groupKey) });
      addedGroupHeaders[groupKey] = true;
    }

    result.push({ type: 'notification', content: notification });
  });

  return result;
}, [notifications]);

export default useGroupedNotifications;
