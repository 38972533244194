import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import update              from 'immutability-helper';
import { Input }           from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ErrorMessage from '~/components/forms/ErrorMessage';
import SmsTemplateActions from '~/actions/sms_template_actions';
import SmsTemplateStore from '~/stores/sms_template_store';
import AppModal from './app_modal';

class SmsTemplateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:     {},
      submitting: false,
      title:      '',
    };

    autoBind(this);
  }

  componentDidMount() {
    this.templateStoreListener = SmsTemplateStore.addListener(this.onTemplateStoreChange);
  }

  componentWillUnmount() {
    if (this.templateStoreListener) this.templateStoreListener.remove();
  }

  onTemplateStoreChange() {
    const smsTemplateStoreState = SmsTemplateStore.getState();
    const { errors, smsTemplateStoreAction } = smsTemplateStoreState;
    const $modal = $(this.smsModal.modal);

    if (smsTemplateStoreAction === 'createSmsTemplateDone') {
      $modal.modal('hide');
    }

    if (smsTemplateStoreAction === 'createSmsTemplateFail') {
      this.setState({
        submitting: false,
        errors,
      });
    }
  }

  validate() {
    const errors = {};
    const { title } = this.state;

    if (!title) {
      errors.title = ["Can't be empty"];
    }

    this.setState({ errors });

    return _lodash.size(errors) === 0;
  }

  handleTitleChange(e) {
    e.preventDefault();

    this.setState(
      update(this.state, {
        title: { $set: e.target.value },
      }),
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { title } = this.state;
    const {
      body, media_url, media_name, componentID,
    }  = this.props;

    this.setState({ submitting: true });

    if (this.validate()) {
      SmsTemplateActions.createSmsTemplate({
        title,
        body,
        media_url,
        media_name,
      }, componentID);
    } else {
      this.setState({ submitting: false });
    }
  }

  render() {
    const { errors, submitting, title } = this.state;

    return (
      <AppModal
        containerID="secondary-modal"
        modalClass="modal modal-overlay"
        dialogClass="modal-dialog modal-sm"
        ref={(smsModal) => this.smsModal = smsModal}
      >
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Create a new Text Template
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="sms_template_title" className="label">Title</label>
              <Input
                id="sms_template_title"
                name="sms_template[title]"
                placeholder="Type a Title for Sms Template"
                styles={`${classNames('form-control', { 'has-error': errors.title })}`}
                value={title}
                onChange={this.handleTitleChange}
              />
              {errors.title && <ErrorMessage message={errors.title} />}
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Saving ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Save</button>
            )}
          </div>
        </form>
      </AppModal>
    );
  }
}

SmsTemplateModal.defaultProps = {
  body:        '',
  media_url:   '',
  media_name:  '',
  componentID: '',
};

SmsTemplateModal.propTypes = {
  body:        PropTypes.string.isRequired,
  media_url:   PropTypes.string,
  media_name:  PropTypes.string,
  componentID: PropTypes.string.isRequired,
};

export default SmsTemplateModal;
