import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadDrawerActions from '~/actions/lead_drawer_actions';

class LeadDrawerTeamMemberManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.onInviteToTeam = this.onInviteToTeam.bind(this);
  }

  onInviteToTeam(e) {
    e.preventDefault();
    this.setState({ sending: true });
    LeadDrawerActions.inviteLeadToTeam(this.props.lead);
  }

  render() {
    const { lead } = this.props;
    const { sending } = this.state;

    if (!lead.email) {
      return <span className="text-grey">add email to invite to team</span>;
    }

    if (lead.team_member && lead.team_member.joined_at) {
      return <span>{Moment(lead.team_member.last_sign_in_at).format('MMMM D, YYYY')}</span>;
    }

    if (sending) {
      return (
        <a href="#" disabled className="btn btn-sm btn-outline-green disabled">
          <FontAwesomeIcon icon={['far', 'fa-paper-plane']} className="mr5" />
          Invite Sent
        </a>
      );
    } if (lead.team_member && lead.team_member.invited_at) {
      return (
        <a href="#" className="btn btn-sm btn-secondary" onClick={this.onInviteToTeam}>
          <FontAwesomeIcon icon={['far', 'fa-paper-plane']} className="mr5" />
          Re-Send Invite
        </a>
      );
    }

    return (
      <a href="#" className="btn btn-sm btn-secondary" onClick={this.onInviteToTeam}>
        <FontAwesomeIcon icon={['far', 'fa-paper-plane']} className="mr5" />
        Send Invite
      </a>
    );
  }
}

LeadDrawerTeamMemberManager.defaultProps = {
  lead: null,
};

LeadDrawerTeamMemberManager.propTypes = {
  lead: PropTypes.shape({}),
};

export default LeadDrawerTeamMemberManager;
