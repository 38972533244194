import { ReduceStore }            from 'flux/utils';
import AppDispatcher              from '~/dispatchers/app_dispatcher';
import BusinessProfileActionTypes from '~/types/business_profiles_action_types';

class BusinessProfileStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      bundle:                         null,
      status:                         null,
      business_information:           null,
      address:                        null,
      lastBusinessProfileStoreAction: null,
      errors:                         {},
      creating:                       false,
      saving:                         false,
    };
  }

  createProfile(state, action) {
    const newState = { ...state };

    newState.lastBusinessProfileStoreAction = 'createProfile';
    newState.creating = true;

    return newState;
  }

  createProfileDone(state, action) {
    const newState = { ...state };

    newState.bundle = action.profile.bundle;
    newState.status = action.profile.status;
    newState.business_information = action.profile.business_information;
    newState.address = action.profile.address;
    newState.lastBusinessProfileStoreAction = 'createProfileDone';
    newState.creating = false;
    newState.errors = {};
    newState.tabName = action.tabName;

    return newState;
  }

  createProfileFail(state, action) {
    const newState = { ...state };

    newState.creating = false;
    newState.errors     = action.error.response.body.errors;
    newState.lastBusinessProfileStoreAction = 'createProfileFail';

    return newState;
  }

  updateProfile(state, action) {
    const newState = { ...state };

    newState.lastBusinessProfileStoreAction = 'updateProfile';
    newState.saving = true;

    return newState;
  }

  updateProfileDone(state, action) {
    const newState = { ...state };

    newState.bundle = action.profile.bundle;
    newState.status = action.profile.status;
    newState.business_information = action.profile.business_information;
    newState.address = action.profile.address;
    newState.saving = false;
    newState.errors = {};
    newState.lastBusinessProfileStoreAction = 'updateProfileDone';
    newState.tabName = action.tabName;

    return newState;
  }

  updateProfileFail(state, action) {
    const newState = { ...state };

    newState.saving = false;
    newState.errors = action.error.response.body.errors;
    newState.lastBusinessProfileStoreAction = 'updateProfileFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case BusinessProfileActionTypes.CREATE_BUSINESS_PROFILE:

        return this.createProfile(state, action);
      case BusinessProfileActionTypes.CREATE_BUSINESS_PROFILE_DONE:

        return this.createProfileDone(state, action);
      case BusinessProfileActionTypes.CREATE_BUSINESS_PROFILE_FAIL:

        return this.createProfileFail(state, action);
      case BusinessProfileActionTypes.UPDATE_BUSINESS_PROFILE:

        return this.updateProfile(state, action);
      case BusinessProfileActionTypes.UPDATE_BUSINESS_PROFILE_DONE:

        return this.updateProfileDone(state, action);
      case BusinessProfileActionTypes.UPDATE_BUSINESS_PROFILE_FAIL:

        return this.updateProfileFail(state, action);
      default:
        return state;
    }
  }
}

export default new BusinessProfileStore();
