import React         from  'react';
import { PropTypes } from 'prop-types';
import Select        from  '~/components/forms/select';

class ListingCampaignTypes extends React.Component {
  render() {
    const { options, value, onChange } = this.props;

    return (
      <Select
        clearable
        value={value}
        options={options}
        onChange={(option) => onChange(option)}
      />
    );
  }
}

ListingCampaignTypes.defaultProps = {
  value:    0,
  options:  [],
  onChange: () => {},
};

ListingCampaignTypes.propTypes = {
  value:                  PropTypes.number,
  options:                PropTypes.arrayOf(PropTypes.object),
  onChange:               PropTypes.func,
};

export default ListingCampaignTypes;
