const CampaignLibraryActionTypes = {
  LOAD_CAMPAIGNS:      'LOAD_CAMPAIGNS',
  LOAD_CAMPAIGNS_DONE: 'LOAD_CAMPAIGNS_DONE',
  LOAD_CAMPAIGNS_FAIL: 'LOAD_CAMPAIGNS_FAIL',

  LOAD_CAMPAIGN:      'LOAD_CAMPAIGN',
  LOAD_CAMPAIGN_DONE: 'LOAD_CAMPAIGN_DONE',
  LOAD_CAMPAIGN_FAIL: 'LOAD_CAMPAIGN_FAIL',

  UPDATE_CAMPAIGN:      'UPDATE_CAMPAIGN',
  UPDATE_CAMPAIGN_DONE: 'UPDATE_CAMPAIGN_DONE',
  UPDATE_CAMPAIGN_FAIL: 'UPDATE_CAMPAIGN_FAIL',

  DELETE_CAMPAIGN:      'DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_DONE: 'DELETE_CAMPAIGN_DONE',
  DELETE_CAMPAIGN_FAIL: 'DELETE_CAMPAIGN_FAIL',

  LOAD_CAMPAIGN_TYPES:      'LOAD_CAMPAIGN_TYPES',
  LOAD_CAMPAIGN_TYPES_DONE: 'LOAD_CAMPAIGN_TYPES_DONE',
  LOAD_CAMPAIGN_TYPES_FAIL: 'LOAD_CAMPAIGN_TYPES_FAIL',
};

export default CampaignLibraryActionTypes;
