import React from 'react';

export const SettingsIcon = () => (
  <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs />
    <g id="Page-1" stroke="none" vectorEffect="1" fill="none" fillRule="evenodd">
      <path d="M18.0172982,11.529 C18.0592982,11.193 18.0907982,10.857 18.0907982,10.5 C18.0907982,10.143 18.0592982,9.807 18.0172982,9.471 L20.2327982,7.7385 C20.4322982,7.581 20.4847982,7.2975 20.3587982,7.0665 L18.2587982,3.4335 C18.1327982,3.2025 17.8492982,3.1185 17.6182982,3.2025 L15.0037982,4.2525 C14.4577982,3.8325 13.8697982,3.486 13.2292982,3.2235 L12.8302982,0.441 C12.7987982,0.189 12.5782982,0 12.3157982,0 L8.11579818,0 C7.85329818,0 7.63279818,0.189 7.60129818,0.441 L7.20229818,3.2235 C6.56179818,3.486 5.97379818,3.843 5.42779818,4.2525 L2.81329818,3.2025 C2.57179818,3.108 2.29879818,3.2025 2.17279818,3.4335 L0.0727981841,7.0665 C-0.0637018159,7.2975 -0.000701815872,7.581 0.198798184,7.7385 L2.41429818,9.471 C2.37229818,9.807 2.34079818,10.1535 2.34079818,10.5 C2.34079818,10.8465 2.37229818,11.193 2.41429818,11.529 L0.198798184,13.2615 C-0.000701815872,13.419 -0.0532018159,13.7025 0.0727981841,13.9335 L2.17279818,17.5665 C2.29879818,17.7975 2.58229818,17.8815 2.81329818,17.7975 L5.42779818,16.7475 C5.97379818,17.1675 6.56179818,17.514 7.20229818,17.7765 L7.60129818,20.559 C7.63279818,20.811 7.85329818,21 8.11579818,21 L12.3157982,21 C12.5782982,21 12.7987982,20.811 12.8302982,20.559 L13.2292982,17.7765 C13.8697982,17.514 14.4577982,17.157 15.0037982,16.7475 L17.6182982,17.7975 C17.8597982,17.892 18.1327982,17.7975 18.2587982,17.5665 L20.3587982,13.9335 C20.4847982,13.7025 20.4322982,13.419 20.2327982,13.2615 L18.0172982,11.529 Z M10.2157982,14.175 C8.18929818,14.175 6.54079818,12.5265 6.54079818,10.5 C6.54079818,8.4735 8.18929818,6.825 10.2157982,6.825 C12.2422982,6.825 13.8907982,8.4735 13.8907982,10.5 C13.8907982,12.5265 12.2422982,14.175 10.2157982,14.175 Z" id="settings" fill="#FFFFFF" fillRule="nonzero" />
    </g>
  </svg>
);
