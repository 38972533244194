import React, { useState, useEffect, useRef } from 'react';
import PropTypes                      from 'prop-types';
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome';
import { Collapse }                   from 'reactstrap';
import LeadDrawerActions              from '~/actions/lead_drawer_actions';
import { initWebSpellChecker }        from '~/lib/web_spell_checker';

const LeadDrawerNotes = ({ leadDrawerStoreAction, lead }) => {
  const isOpenLocalStorage = localStorage.isDetailsOpen;
  const [isOpen, setIsOpen] = useState(isOpenLocalStorage ? JSON.parse(isOpenLocalStorage) : false);
  const [editing, setEditing] = useState(false);
  const { manageLead, manageNotes } = Rails.abilities;
  const noteRef = useRef(null);

  useEffect(() => {
    if (leadDrawerStoreAction === 'updateLeadDone') {
      setEditing(false);
    }
  }, [leadDrawerStoreAction]);

  useEffect(() => {
    const ele = noteRef.current
    if (ele) initWebSpellChecker(ele);
  }, [editing]);

  const toggleEditing = (newState) => (e) => {
    e.preventDefault();
    setEditing(newState);
  };

  const onCollapseClick = (e) => {
    e.preventDefault();

    setIsOpen(!isOpen);
    localStorage.isDetailsOpen = !isOpen;
  };

  const onNoteSubmit = (e) => {
    e.preventDefault();
    const note = document.getElementById('lead_note').value;
    LeadDrawerActions.updateLead({ id: lead.id, note });
  };

  const onSave = (e) => {
    e.preventDefault();

    setEditing(false);
    setIsOpen(false);
  };

  let noteContent;
  let noteButton;

  if (editing) {
    noteContent = (
      <form
        id="edit-top-lead-form"
        className="edit-lead-form"
        onSubmit={onNoteSubmit}
      >
        <div className="form-group text optional lead_note">
          <textarea
            id="lead_note"
            name="lead[note]"
            rows="10"
            className="form-control text optional"
            defaultValue={lead.note}
            ref={noteRef}
          />
        </div>

        <div className="form-group mt15">
          <button type="submit" className="btn btn-primary" onSubmit={onSave}>
            Save
          </button>
        </div>
      </form>
    );
    noteButton = (
      <button
        type="button"
        className="btn btn-secondary btn-sm ml5 pull-right"
        onClick={toggleEditing(false)}
      >
        <FontAwesomeIcon icon={['far', 'fa-times']} />
        {' '}
        Close
      </button>
    );
  } else {
    noteContent = <p className="text-grey-dark">{lead.note}</p>;
    noteButton = (
      <button
        type="button"
        className="btn btn-secondary btn-sm ml5"
        onClick={toggleEditing(true)}
      >
        <FontAwesomeIcon icon={['far', 'fa-edit']} />
        {' '}
        Edit
      </button>
    );
  }

  return (
    <div>
      <h3 className="mb5">
        Referrer Notes
        {' '}
        {(manageLead || manageNotes) && noteButton}
        { !editing && (
          <button type="button" className="btn btn-secondary btn-sm mr-1 pull-right" onClick={onCollapseClick}>
            { isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
        )}
      </h3>
      <Collapse isOpen={isOpen || editing}>
        {noteContent}
      </Collapse>
    </div>
  );
};

LeadDrawerNotes.defaultProps = {
  leadDrawerStoreAction: null,
  lead:                  null,
};

LeadDrawerNotes.propTypes = {
  leadDrawerStoreAction: PropTypes.string,
  lead:                  PropTypes.shape({}),
};

export default LeadDrawerNotes;
