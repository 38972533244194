const LeadLabelActionTypes = {
  LOAD_LEAD_LABELS:      'LOAD_LEAD_LABELS',
  LOAD_LEAD_LABELS_DONE: 'LOAD_LEAD_LABELS_DONE',
  LOAD_LEAD_LABELS_FAIL: 'LOAD_LEAD_LABELS_FAIL',

  CREATE_LEAD_LABEL:      'CREATE_LEAD_LABEL',
  CREATE_LEAD_LABEL_DONE: 'CREATE_LEAD_LABEL_DONE',
  CREATE_LEAD_LABEL_FAIL: 'CREATE_LEAD_LABEL_FAIL',

  DELETE_LEAD_LABEL:      'DELETE_LEAD_LABEL',
  DELETE_LEAD_LABEL_DONE: 'DELETE_LEAD_LABEL_DONE',
  DELETE_LEAD_LABEL_FAIL: 'DELETE_LEAD_LABEL_FAIL',
};

export default LeadLabelActionTypes;
