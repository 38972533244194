import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CampaignTriggerActions from '~/actions/campaign_trigger_actions';
import CampaignTriggerStore   from '~/stores/campaign_trigger_store';
import CampaignTriggersTable  from './campaign_triggers_table';

class ListingCampaignTriggers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCampaignTriggers: false,
      campaignTriggers:        [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.campaignTriggerStoreListener = CampaignTriggerStore.addListener(this.onCampaignTriggerStoreChange);

    CampaignTriggerActions.loadCampaignTriggers();
  }

  componentWillUnmount() {
    if (this.campaignTriggerStoreListener) this.campaignTriggerStoreListener.remove();
  }

  onCampaignTriggerStoreChange() {
    const campaignTriggerStoreState = CampaignTriggerStore.getState();
    const { loadingCampaignTriggers, campaignTriggers } = campaignTriggerStoreState;

    this.setState({
      loadingCampaignTriggers,
      campaignTriggers,
    });
  }

  deleteCampaignTrigger(trigger) {
    if (_lodash.isEmpty(trigger)) return;

    CampaignTriggerActions.deleteCampaignTrigger(trigger);
  }

  renderCampaignTriggersTable() {
    const { loadingCampaignTriggers, campaignTriggers } = this.state;

    if (loadingCampaignTriggers) {
      return (
        <div className="text-center card-block">
          <FontAwesomeIcon icon="far fa-spinner" pulse className="fa-lg" />
        </div>
      );
    }

    if (campaignTriggers.length === 0) {
      return <div className="text-grey-dark card-block text-center">No smart campaign triggers, please add a new one trigger.</div>;
    }

    return (
      <CampaignTriggersTable
        loading={loadingCampaignTriggers}
        campaignTriggers={campaignTriggers}
        deleteCampaignTrigger={this.deleteCampaignTrigger}
      />
    );
  }

  render() {
    const campaigns_uri = GlobalContainer.productUri('/campaigns');
    const campaign_triggers_new_uri = GlobalContainer.productUri('/campaign_triggers/new');

    return (
      <div className="card campaign-trigger">
        <div className="card-block">
          <h4 className="mb15">
            Smart Campaign Triggers
            <div className="pull-right">
              <Link className="btn btn-secondary mr3" to={campaigns_uri}>
                <FontAwesomeIcon icon={['far', 'fa-arrow-left']} className="mr5" />
                {' '}
                Back to Campaigns
              </Link>
              <Link className="btn btn-secondary" to={campaign_triggers_new_uri}>
                <FontAwesomeIcon icon={['far', 'fa-plus']} className="mr5" />
                {' '}
                New Trigger
              </Link>
            </div>
          </h4>
        </div>
        {this.renderCampaignTriggersTable()}
      </div>
    );
  }
}

ListingCampaignTriggers.defaultProps = {};

ListingCampaignTriggers.propTypes = {};

export default ListingCampaignTriggers;
