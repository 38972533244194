import React            from 'react';
import TimelineActivity from '~/components/drawers/timeline/activity';
import Tooltipable      from '~/components/effects/tooltipable';
import TinyUser         from '~/components/shared/users/tiny_user';

class TimelineActivityCampaignSubscription extends TimelineActivity {
  renderWhoTimestamp() {
    const { activity } = this.state;
    const { parameters: { trigger_name }, owner } = activity;
    const activity_owner = trigger_name ? { name: trigger_name } : (owner || { name: 'System' });

    return (
      <div className="text-grey mt10">
        <span className="pull-right text-grey">
          <Tooltipable
            text={Moment(activity.occurred_at).format(
              'MMMM Do YYYY, h:mm:ss a',
            )}
          >
            <>{Moment(activity.occurred_at).fromNow()}</>
          </Tooltipable>
        </span>
        {activity_owner.id ? (
          <TinyUser user={activity.owner} />
        ) : (
          <span>{activity_owner.name}</span>
        )}
      </div>
    );
  }
}

TimelineActivityCampaignSubscription.defaultProps = {
  title: (object) => {
    const { parameters: { campaign_name, trigger_name }, owner, key } = object.props.activity;
    const activity_owner = trigger_name ? { name: trigger_name } : (owner || { name: 'System' });

    switch (key) {
      case 'lead.campaign_subscription':
        return (
          `${campaign_name} was added by ${trigger_name ? 'campaign trigger' : ''} ${activity_owner.name}`
        );
      case 'lead.campaign_subscription_delete':
        return (
          `${campaign_name} was deleted by ${trigger_name ? 'campaign trigger' : ''} ${activity_owner.name}`
        );
      default:
        return (
          `${campaign_name} was ${key} by ${trigger_name ? 'campaign trigger' : ''} ${activity_owner.name}`
        );
    }
  },
  icon:           'exchange-alt',
  prefix:         'fas',
  alwaysPublic:   false,
};

export default TimelineActivityCampaignSubscription;
