import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class ProductionLabelSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getOptions() {
    const productionLabels = Rails.helpers.currentConfiguration.production_labels;

    const opts = [];

    Object.entries(productionLabels).map(([key, value]) => {
      const productionLabel = { value: key, label: value };

      opts.push(productionLabel);
    });

    if (this.props.blank) {
      opts.unshift({ label: '-blank-', value: '-blank-' });
    }

    return opts;
  }

  render() {
    return (
      <Select
        {...this.props}
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.getOptions()}
      />
    );
  }
}

ProductionLabelSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

ProductionLabelSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default ProductionLabelSelect;
