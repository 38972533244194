import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InitialState from './InitialState';
import GeneratingState from './GeneratingState';
import ErrorState from './ErrorState';
import Buttons from './Buttons';
import { ModalStates } from '../states/ModalStates';

const ModalContent = ({
  state,
  dispatch,
  markdownContentRef,
  handleInputChange,
  handlePromptClick,
  handleGenerateText,
  handleInsert,
  handleRegenerate,
  handleRevisePrompt,
  handleNewPrompt,
  handleRetry,
  selectedType,
}) => (
  <>
    <div className="modal-body">
      {state.status === ModalStates.INITIAL && (
        <InitialState
          state={state}
          handlePromptClick={handlePromptClick}
          handleInputChange={handleInputChange}
          selectedType={selectedType}
        />
      )}

      {(state.status === ModalStates.FINISHED || state.status === ModalStates.GENERATING) && (
        <GeneratingState
          state={state}
          markdownContentRef={markdownContentRef}
        />
      )}

      {state.error && <ErrorState state={state} />}

      <Buttons
        state={state}
        dispatch={dispatch}
        handleGenerateText={handleGenerateText}
        handleInsert={handleInsert}
        handleRegenerate={handleRegenerate}
        handleRevisePrompt={handleRevisePrompt}
        handleNewPrompt={handleNewPrompt}
        handleRetry={handleRetry}
      />

      <p className="mt-2">
        <FontAwesomeIcon icon="far fa-exclamation-circle" />
        {' '}
        AI generated output can be misleading and inaccurate.
        {' '}
        <a
          href="https://info.getbrokerkit.com/knowledge/ai-assist"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray"
        >
          Learn More
        </a>
      </p>
    </div>
  </>
);

export default ModalContent;
