import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AccountSideBar extends React.Component {
  render() {
    const { team } = this.props;
    const { abilities } = Rails;
    const { currentUser } = Rails.helpers;
    const currentUserRoleName = currentUser.role.name;
    // eslint-disable-next-line no-restricted-globals, no-undef
    const page = location.pathname;

    const homeURL = '/';

    const accountClassName = classNames('list-group-item', { active: page === '/users/edit' });
    const landingPageClassName = classNames('list-group-item', { active: page === '/team/landing_page/edit' });
    const teamClassName = classNames('list-group-item', { active: page === '/team/edit' });
    const phoneNumberPageClassName = classNames('list-group-item', { active: page === '/users/phone_number/edit' });
    const membersClassName = classNames('list-group-item', { active: page === '/team/members' });

    return (
      <div>
        <a href={homeURL} className="ml20">
          <FontAwesomeIcon icon={['far', 'fa-arrow-left']} className="mr5" />
          {' '}
          Back
        </a>

        <div className="list-group sidebar mt45 mb15">
          <Link to="/users/edit" className={accountClassName}>
            <FontAwesomeIcon icon={['fas', 'fa-user']} className="mr5" />
            {' '}
            User Settings
          </Link>

          {
            (currentUserRoleName === 'agent' || currentUserRoleName === 'affiliate') && (
              <Link to="/team/landing_page/edit" className={landingPageClassName}>
                <FontAwesomeIcon icon={['fas', 'fa-file-alt']} className="mr5" />
                {' '}
                Landing Page
              </Link>
            )
          }

          {
            team && (currentUser.admin || currentUser.role.name === 'staff' || currentUser.role.name === 'admin') && (
              <Link to="/users/phone_number/edit" className={phoneNumberPageClassName}>
                <FontAwesomeIcon icon={['fas', 'fa-phone-alt']} className="mr5" />
                {' '}
                Phone Number
              </Link>
            )
          }

          {
            team && abilities.manageCurrentTeam && (
              <Link to="/team/edit" className={teamClassName}>
                <FontAwesomeIcon icon={['fas', 'fa-building']} className="mr5" />
                {' '}
                Account Settings
              </Link>
            )
          }

          {
            team && abilities.manageCurrentTeam && (
              <Link to="/team/members" className={membersClassName}>
                <FontAwesomeIcon icon={['fas', 'fa-users']} className="mr5" />
                {' '}
                Manage Team
              </Link>
            )
          }

        </div>
      </div>
    );
  }
}

AccountSideBar.defaultProps = {
  team: null,
};

AccountSideBar.propTypes = {
  team: PropTypes.shape({}),
};

export default AccountSideBar;
