import React            from 'react';

import TimelineActivity from '~/components/drawers/timeline/activity';
import LeadHelpers      from '~/helpers/lead_helpers';

class TimelineActivityStatus extends TimelineActivity {
  // render(){
  //   const { activity } = this.props;
  //
  //   return(
  //     <TimelineActivity icon="exchange">
  //       <h4 className="text-black">Status changed to {)}</h4>
  //       {this.renderWhoTimestamp()}
  //     </TimelineActivity>
  //   )
  // }
}

TimelineActivityStatus.defaultProps = {
  title:          (obj) => `Status changed to "${obj.props.activity.parameters?.status}"`,
  icon:           'exchange-alt',
  prefix:         'fas',
  alwaysPublic:   false,
};

export default TimelineActivityStatus;
