import React from 'react';

import NewLeadDrawer     from '~/components/drawers/new_lead_drawer';
import NewReferralDrawer from '~/components/drawers/new_referral_drawer';
import TeamMemberDrawer  from '~/components/drawers/team_member_drawer';
import LeadDrawer        from '~/components/drawers/lead_drawer';
import EmailDomainDrawer from '~/components/drawers/email_domain_drawer';

export default class DrawerManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  open(kind, options) {
    let drawer;

    switch (kind) {
      case 'email-domain':
        drawer = <EmailDomainDrawer {...options} />;
        break;
      case 'team-member':
        drawer = <TeamMemberDrawer {...options} />;
        break;
      case 'new-lead':
        drawer = <NewLeadDrawer {...options} />;
        break;
      case 'new-referral':
        drawer = <NewReferralDrawer {...options} />;
        break;
      case 'lead':
        drawer = (
          <LeadDrawer
            lead={options.lead}
            loadLeadID={parseInt(options.loadLeadID, 10)}
            defaultAction={options.defaultAction}
            table={options.table}
            query={options.query}
            totalCount={options.totalCount}
            rowIdx={options.rowIdx}
            /* Report page props */
            reportItemIndex={options.reportItemIndex}
            recordsLength={options.recordsLength}
            handleReportPrevClick={options.handleReportPrevClick}
            handleReportNextClick={options.handleReportNextClick}
          />
        );
        break;
      default:
        drawer = null;
    }

    this.setState({
      drawer,
    });
  }

  close() {
    this.setState({
      drawer: null,
    });
  }

  render() {
    const { drawer } = this.state;

    return (
      <div>
        <div id="primary-modal">{drawer}</div>
        <div id="secondary-modal" />
        <div id="dialer-modal" />
        <div id="ai-assist-modal" />
      </div>
    );
  }
}
