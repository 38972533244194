import autoBind       from 'react-autobind';
import React          from 'react';
import { PropTypes }  from 'prop-types';
import { withRouter } from 'react-router-dom';

import brokerbit           from '~/lib/brokerbit';
import GlobalContainer     from '~/components/global_container';
import CampaignTriggerItem from './campaign_trigger_item';

class CampaignTriggersTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleCampaignTriggerClick(campaignTrigger) {
    const { history } = this.props;
    const campaign_triggers_edit_uri = GlobalContainer.productUri(`/campaign_triggers/${campaignTrigger.id}/edit`);

    history.push(campaign_triggers_edit_uri);
  }

  handleCampaignTriggerDelete(campaignTrigger) {
    if (_lodash.isEmpty(campaignTrigger)) return;

    const { deleteCampaignTrigger } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this campaign trigger?',
      callback: (ok) => {
        if (ok) deleteCampaignTrigger(campaignTrigger);
      },
    });
  }

  renderCampaignTriggerRows() {
    const { campaignTriggers } = this.props;

    const campaignTriggerRows = [];

    campaignTriggers.forEach((campaignTrigger, idx) => {
      campaignTriggerRows.push(
        <CampaignTriggerItem
          key={`campaign-trigger-${campaignTrigger.id}`}
          campaignTrigger={campaignTrigger}
          handleCampaignTriggerClick={this.handleCampaignTriggerClick}
          handleCampaignTriggerDelete={this.handleCampaignTriggerDelete}
        />,
      );
    });

    return campaignTriggerRows;
  }

  render() {
    return (
      <table className="table table-hover table-responsive campaign-triggers-table mb-0 pb-0">
        <tbody>{this.renderCampaignTriggerRows()}</tbody>
      </table>
    );
  }
}

CampaignTriggersTable.defaultProps = {
  loading:                     false,
  campaignTriggers:            [],
  deleteCampaignTrigger: () => false,
};

CampaignTriggersTable.propTypes = {
  history:               PropTypes.shape({}).isRequired,
  loading:               PropTypes.bool,
  campaignTriggers:      PropTypes.arrayOf(PropTypes.shape({})),
  deleteCampaignTrigger: PropTypes.func,
};

export default withRouter(CampaignTriggersTable);
