import React from 'react';
import { PropTypes } from 'prop-types';
import { components } from 'react-select-4';
import Select from './NewSelect';
import Tooltipable from '~/components/effects/tooltipable';

const CampaignStepAtSelect = (props) => {
  const { value } = props;
  const options = [
    { value: '*', kind: 'rand', label: 'Any Time' },
    { value: '09:00-12:00', kind: 'range', label: '9 AM-Noon randomly' },
    { value: '12:00', kind: 'time', label: '12:00' },
    { value: '12:30', kind: 'time', label: '12:30' },
    { value: '01:00', kind: 'time', label: '01:00' },
    { value: '01:30', kind: 'time', label: '01:30' },
    { value: '02:00', kind: 'time', label: '02:00' },
    { value: '02:30', kind: 'time', label: '02:30' },
    { value: '03:00', kind: 'time', label: '03:00' },
    { value: '03:30', kind: 'time', label: '03:30' },
    { value: '04:00', kind: 'time', label: '04:00' },
    { value: '04:30', kind: 'time', label: '04:30' },
    { value: '05:00', kind: 'time', label: '05:00' },
    { value: '05:30', kind: 'time', label: '05:30' },
    { value: '06:00', kind: 'time', label: '06:00' },
    { value: '06:30', kind: 'time', label: '06:30' },
    { value: '07:00', kind: 'time', label: '07:00' },
    { value: '07:30', kind: 'time', label: '07:30' },
    { value: '08:00', kind: 'time', label: '08:00' },
    { value: '08:30', kind: 'time', label: '08:30' },
    { value: '09:00', kind: 'time', label: '09:00' },
    { value: '09:30', kind: 'time', label: '09:30' },
    { value: '10:00', kind: 'time', label: '10:00' },
    { value: '10:30', kind: 'time', label: '10:30' },
    { value: '11:00', kind: 'time', label: '11:00' },
    { value: '11:30', kind: 'time', label: '11:30' },
  ];

  return (
    <Select
      {...props}
      components={{
        Control: ({ children, ...rest }) => (
          <Tooltipable
            text="Choose the time of day that this step of the campaign will be executed."
            placement="top"
          >
            <components.Control {...rest}>
              {children}
            </components.Control>
          </Tooltipable>
        ),
      }}
      options={options}
      value={value}
    />
  );
};

CampaignStepAtSelect.defaultProps = {
  onChange:    () => {},
  value:       '',
};

CampaignStepAtSelect.propTypes = {
  onChange: PropTypes.func,
  value:    PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
};

export default CampaignStepAtSelect;
