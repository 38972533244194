export default class PositionHandler {
  constructor(editor) {
    this.editor = editor;
  }

  offset = () => {
    const editorContainerRect = this.editor.getContainer().getBoundingClientRect();
    const editorContentAreaRect = this.editor.getContentAreaContainer();
    const autocompleteSpan = this.editor.dom.select('span#mentions-autocomplete')[0];
    const scrollTop = this.editor.getDoc().documentElement.scrollTop || this.editor.getDoc().body.scrollTop;

    const rtePosition = { top: editorContainerRect.top + window.scrollY, left: editorContainerRect.left + window.scrollX };
    const contentAreaPosition = { top: editorContentAreaRect.offsetTop, left: editorContentAreaRect.offsetLeft };

    const nodePosition = {
      top:  autocompleteSpan.offsetTop,
      left: autocompleteSpan.offsetLeft,
    };

    return {
      top:  rtePosition.top + contentAreaPosition.top + nodePosition.top + this.editor.selection.getNode().clientHeight - scrollTop + 25,
      left: rtePosition.left + contentAreaPosition.left + nodePosition.left,
    };
  }

  offsetInline = () => {
    const nodePosition = this.editor.dom.select('span#mentions-autocomplete').getBoundingClientRect();

    return {
      top:  nodePosition.top + this.editor.selection.getNode().offsetHeight + 5,
      left: nodePosition.left,
    };
  }
}
