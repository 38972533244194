import React               from 'react';
import autoBind            from 'react-autobind';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest from '~/lib/api_request';

class CallStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:    true,
      callStats: {},
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadCallStats();
  }

  loadCallStats() {
    APIRequest.get({ resource: '/v1/statistics/voice_calls' })
      .end((err, response) => {
        if (response && response.ok) {
          const callStats = response.body;

          this.setState({ callStats, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
  }

  renderStatisticTable() {
    const { loading, callStats } = this.state;

    if (loading) {
      return (
        <tbody className="text-center">
          <tr>
            <td colSpan="3">
              <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        <tr>
          <th scope="row">Today</th>
          <td className="text-center">{callStats.inboundToday}</td>
          <td className="text-center">{callStats.outboundToday}</td>
        </tr>
        <tr>
          <th scope="row">This Week</th>
          <td className="text-center">{callStats.inboundThisWeek}</td>
          <td className="text-center">{callStats.outboundThisWeek}</td>
        </tr>
        <tr>
          <th scope="row">This Month</th>
          <td className="text-center">{callStats.inboundThisMonth}</td>
          <td className="text-center">{callStats.outboundThisMonth}</td>
        </tr>
        <tr>
          <th scope="row">Last Month</th>
          <td className="text-center">{callStats.inboundLastMonth}</td>
          <td className="text-center">{callStats.outboundLastMonth}</td>
        </tr>
      </tbody>
    );
  }

  render() {
    return (
      <div className="table-responsive col-md-12 col-lg-6">
        <table className="table table-bordered statistic-table">
          <thead>
            <tr>
              <th style={{ width: '50%' }} scope="col">Call usage minutes</th>
              <th style={{ width: '20%' }} scope="col" className="text-center">Inbound</th>
              <th style={{ width: '20%' }} scope="col" className="text-center">Outbound</th>
            </tr>
          </thead>
          {this.renderStatisticTable()}
        </table>
      </div>
    );
  }
}

CallStats.defaultProps = {};

CallStats.propTypes = {};

export default CallStats;
