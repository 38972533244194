import React from 'react';
import { components } from 'react-select-4';

const Option = ({ children, data, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return (
    <components.Option {...newProps} className="text-break word-break">
      {data.user?.avatar_url ? (
        <>
          <img src={data.user.avatar_url} alt="avatar" className="rounded-circle mr5" height="20" />
          {children}
        </>
      ) : (
        children
      )}
    </components.Option>
  );
};

export default Option;
