import AppDispatcher              from '~/dispatchers/app_dispatcher';
import APIRequest                 from '~/lib/api_request';
import CampaignTriggerActionTypes from '~/types/campaign_trigger_action_types';

let loadCampaignTriggerRequest;
let campaignTriggersRequest;
let createCampaignTriggerRequest;
let updateCampaignTriggerRequest;
let deleteCampaignTriggerRequest;

const CampaignTriggerActions = {
  loadCampaignTrigger(triggerID, onDone = () => false, onFail = () => false) {
    APIRequest.abort(loadCampaignTriggerRequest);

    AppDispatcher.dispatch({
      type: CampaignTriggerActionTypes.LOAD_CAMPAIGN_TRIGGERS,
    });

    loadCampaignTriggerRequest = APIRequest.get({
      resource: `/v1/campaign_triggers/${triggerID}`,
    });

    loadCampaignTriggerRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  loadCampaignTriggers() {
    APIRequest.abort(campaignTriggersRequest);

    AppDispatcher.dispatch({
      type: CampaignTriggerActionTypes.LOAD_CAMPAIGN_TRIGGERS,
    });

    campaignTriggersRequest = APIRequest.get({
      resource: '/v1/campaign_triggers',
    });

    campaignTriggersRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignTriggerActionTypes.LOAD_CAMPAIGN_TRIGGERS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:             CampaignTriggerActionTypes.LOAD_CAMPAIGN_TRIGGERS_DONE,
        campaignTriggers: response.body,
      });
    });
  },

  createCampaignTrigger(campaign_trigger) {
    APIRequest.abort(createCampaignTriggerRequest);

    AppDispatcher.dispatch({
      type: CampaignTriggerActionTypes.CREATE_CAMPAIGN_TRIGGER,
      campaign_trigger,
    });

    createCampaignTriggerRequest = APIRequest.post({
      resource: '/v1/campaign_triggers',
      data:     { campaign_trigger },
    });

    createCampaignTriggerRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignTriggerActionTypes.CREATE_CAMPAIGN_TRIGGER_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:             CampaignTriggerActionTypes.CREATE_CAMPAIGN_TRIGGER_DONE,
        campaign_trigger: response.body,
      });

      CampaignTriggerActions.loadCampaignTriggers();
    });
  },

  updateCampaignTrigger(campaign_trigger) {
    APIRequest.abort(updateCampaignTriggerRequest);

    AppDispatcher.dispatch({
      type: CampaignTriggerActionTypes.UPDATE_CAMPAIGN_TRIGGER,
      campaign_trigger,
    });

    updateCampaignTriggerRequest = APIRequest.put({
      resource: `/v1/campaign_triggers/${campaign_trigger.id}`,
      data:     { campaign_trigger },
    });

    updateCampaignTriggerRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignTriggerActionTypes.UPDATE_CAMPAIGN_TRIGGER_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:             CampaignTriggerActionTypes.UPDATE_CAMPAIGN_TRIGGER_DONE,
        campaign_trigger: response.body,
      });

      CampaignTriggerActions.loadCampaignTriggers();
    });
  },

  updateCampaignTriggerStatus(triggerID, action, onDone = () => false, onFail = () => false) {
    APIRequest.put({
      resource: `/v1/campaign_triggers/${triggerID}/${action}`,
    }).end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  runCampaignTrigger(triggerID, onDone = () => false, onFail = () => false) {
    this.updateCampaignTriggerStatus(triggerID, 'run', onDone, onFail);
  },

  pauseCampaignTrigger(triggerID, onDone = () => false, onFail = () => false) {
    this.updateCampaignTriggerStatus(triggerID, 'pause', onDone, onFail);
  },

  deleteCampaignTrigger(campaignTrigger) {
    APIRequest.abort(deleteCampaignTriggerRequest);

    AppDispatcher.dispatch({
      type: CampaignTriggerActionTypes.DELETE_CAMPAIGN_TRIGGER,
      campaignTrigger,
    });

    deleteCampaignTriggerRequest = APIRequest.delete({
      resource: `/v1/campaign_triggers/${campaignTrigger.id}`,
    });

    deleteCampaignTriggerRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignTriggerActionTypes.DELETE_CAMPAIGN_TRIGGER_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: CampaignTriggerActionTypes.DELETE_CAMPAIGN_TRIGGER_DONE,
      });

      CampaignTriggerActions.loadCampaignTriggers();
    });
  },
};

export default CampaignTriggerActions;
