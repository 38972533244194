import React               from 'react';
import autoBind            from 'react-autobind';
import ReactAudioPlayer    from 'react-audio-player';
import shadowRoot          from 'react-shadow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TimelineActivity      from '~/components/drawers/timeline/activity';
import LeadHelpers           from '~/helpers/lead_helpers';
import ActivityEditor        from '~/components/forms/HtmlEditors/ActivityEditor';
import CallDispositionSelect from '~/components/forms/call_disposition_select';

const callDisposition = {
  no_answer:    'No Answer',
  closed:       'Completed',
  voicemail:    'Voicemail',
  busy:         'Busy',
  wrong_number: 'Wrong Number',
  canceled:     'Canceled',
  failed:       'Failed',
};

class TimelineActivityPhoneCall extends TimelineActivity {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  renderForm() {
    const { activity } = this.state;
    const call_isposition = activity.parameters?.call_disposition;

    return (
      <form onSubmit={this.onSubmit}>
        <ActivityEditor
          value={activity.body}
          onChange={(html) => {
            this.state.queuedChanges.body = html;
          }}
          placeholder="Enter Text"
        />

        <div className="col-lg-4 p0 mt5">
          <CallDispositionSelect
            name="call_disposition"
            placeholder="Call Disposition"
            value={call_isposition || ''}
            onChange={(select) => {
              this.state.queuedChanges.parameters = { call_disposition: select.value };
            }}
          />
        </div>

        <div className="mt5">
          <button type="submit" className="btn btn-primary btn-sm mr5">
            { this.state.sending ? (
              <span>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Saving
              </span>
            ) : 'Save' }
          </button>
          <a href="#" onClick={() => { this.setState({ queuedChanges: {}, editing: false }); }} className="btn btn-secondary btn-sm">Cancel</a>
        </div>
      </form>
    );
  }

  renderView() {
    const { activity } = this.state;
    const { parameters, voice_call } = activity;

    if (!activity) {
      return null;
    }

    return (
      <div>
        {voice_call && (
          <span>
            {_lodash.capitalize(voice_call.direction)}
            {' '}
            call to the
            {' '}
            {LeadHelpers.renderPhoneNumber(voice_call.to)}
            {' '}
            {LeadHelpers.renderDuration(voice_call.duration)}
          </span>
        )}

        {parameters && parameters.call_disposition && (
          <div className="mt-2">
            Call Disposition:
            {' '}
            {callDisposition[parameters.call_disposition]}
          </div>
        )}

        {voice_call && voice_call.record_url && (
          <div className="mt-2">
            <ReactAudioPlayer
              src={voice_call.record_url}
              controls
            />
          </div>
        )}

        {(activity.body
          && (
          <shadowRoot.div className="mt-2 form-control font-activity">
            <div className="content" dangerouslySetInnerHTML={{ __html: activity.body || '' }} />
          </shadowRoot.div>
          )
        )}
      </div>
    );
  }
}

TimelineActivityPhoneCall.defaultProps = {
  editable:       true,
  deletable:      true,
  title:          'Phone Call',
  icon:           'phone-alt',
  prefix:         'fas',
  color:          'text-pink',
  alwaysPublic:   false,
};

export default TimelineActivityPhoneCall;
