import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import AgentAlertPopover from '~/components/drawers/lead_drawer/details/LeadDrawerAgentAlerts/AgentAlertPopover';

const CheckboxWithPopover = ({
  id,
  label,
  isChecked,
  onChange,
}) => {
  const [featureFlagEnabled, setFeatureFlagEnabled] = useState(false);

  useEffect(() => {
    const checkFlag = async () => {
      const isFeatureEnabled = await checkFeatureFlag(process.env.ALLOW_AGENT_ALERTS_SYNC);
      setFeatureFlagEnabled(isFeatureEnabled);
    };

    checkFlag();
  }, []);

  return (
    <>
      <label htmlFor={id} className="mr-3">{label}</label>

      <AgentAlertPopover>
        {({ togglePopover }) => (
          <input
            className="mr-2"
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={(e) => featureFlagEnabled && onChange(e)}
            onClick={(e) => {
              if (!featureFlagEnabled) {
                e.preventDefault();
                togglePopover(e);
              }
            }}
            style={{
              cursor:          featureFlagEnabled ? 'pointer' : 'auto',
              opacity:         featureFlagEnabled ? 1 : 0.5,
            }}
          />
        )}
      </AgentAlertPopover>
    </>
  );
};

CheckboxWithPopover.propTypes = {
  id:        PropTypes.string.isRequired,
  label:     PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange:  PropTypes.func.isRequired,
};

export default CheckboxWithPopover;
