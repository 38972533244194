import autoBind                from 'react-autobind';
import React                   from 'react';
import { Form, Input }         from 'reactstrap';
import { PropTypes }           from 'prop-types';

import AgentActions            from '~/actions/agent_actions';
import BulkSelectActions       from '~/actions/bulk_select_actions';
import SearchForm              from '~/components/pages/agent_dashboard/search_form';
import { getRestrictionScope } from '~/helpers/staffRestrictionHelper';
import DocumentActions         from '~/actions/document_actions';

class PageScoping extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleScopeChange(e) {
    e.preventDefault();
    const { widgets } = this.props;

    const scope = e.target.value;

    BulkSelectActions.deselectLeadItems();
    AgentActions.switchOwnerScope(widgets, scope);
  }

  handleSizeChange(e) {
    e.preventDefault();
    const { widgets } = this.props;

    const size = e.target.value;

    const currentScope = widgets.tables[0]?.scope;
    const isDocumentScope = currentScope === 'documents' || currentScope === 'trash_documents';

    if (isDocumentScope) {
      DocumentActions.switchDocumentScopeSize(widgets, parseInt(size, 10), SearchForm.buildSearchData());
    } else {
      AgentActions.switchLeadScopeSize(widgets, parseInt(size, 10), SearchForm.buildSearchData());
    }
  }

  shouldDisplayOwnerScope() {
    // only show owner scope filter if can manage lead and is not searching - which is not very wise regex test
    return Rails.abilities.manageLead;
  }

  render() {
    const { widgets } = this.props;
    let { ownerScope, leadScopeSize, documentScopeSize } = localStorage;

    const staffRestriction = getRestrictionScope();

    ownerScope = ownerScope || staffRestriction || 'all';
    leadScopeSize = parseInt(leadScopeSize, 10) || 10;
    documentScopeSize = parseInt(documentScopeSize, 10) || 10;

    if (widgets.tables.length === 0) {
      return null;
    }

    const selectStyle = { display: 'inline-block', width: 'auto', verticalAlign: 'middle' };

    const currentScope = widgets.tables[0].scope;
    let leadsScope;
    let scopeSize = leadScopeSize;

    if (currentScope === 'documents' || currentScope === 'trash_documents') {
      leadsScope = 'documents';
      scopeSize = documentScopeSize;
    } else if (widgets.leadsScope) {
      leadsScope = GlobalContainer.product() === 'retention' ? 'team members' : 'leads';
    } else {
      leadsScope = 'tasks';
    }

    if (widgets.tasksScope) {
      leadsScope = 'leads/tasks';
    }
    const isDocumentsOrTrash = widgets.tables[0].scope === 'documents' || widgets.tables[0].scope === 'trash_documents';

    let leadScope;
    if (this.shouldDisplayOwnerScope()) {
      leadScope = (
        <span>
          <Input type="select" defaultValue={ownerScope} onChange={this.handleScopeChange} style={selectStyle}>
            <option value="all">all</option>
            {!isDocumentsOrTrash && staffRestriction !== 'mine' && staffRestriction !== 'my+unassigned' && (
            <option value="my+unassigned">my + unassigned</option>
            )}
            <option value="mine">my</option>
          </Input>
          <span className="ml5 mr5">
            {leadsScope}
            {' '}
            and
          </span>
        </span>
      );
    }

    return (
      <Form inline className="mt15">
        <span className="mr5">Show</span>
        {leadScope}
        <Input
          type="select"
          name="selectMulti"
          defaultValue={scopeSize}
          onChange={this.handleSizeChange}
          style={selectStyle}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Input>
        <span className="ml5">items per page</span>
      </Form>
    );
  }
}

PageScoping.defaultProps = {
  widgets: {
    tables: [],
  },
};

PageScoping.propTypes = {
  widgets: PropTypes.shape({
    tables: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default PageScoping;
