import AppDispatcher       from '~/dispatchers/app_dispatcher';
import APIRequest          from '~/lib/api_request';
import LeadLinkActionTypes from '~/types/lead_link_action_types';

let fullContactRequest;
let createLinkRequest;
let updateLinkRequest;
let deleteLinkRequest;

const LeadLinkActions = {
  loadLeadLinks(links) {
    setTimeout(() => {
      AppDispatcher.dispatch({
        type: LeadLinkActionTypes.LOAD_LEAD_LINKS_DONE,
        links,
      });
    }, 1);
  },

  createLeadLink(leadID, link) {
    APIRequest.abort(createLinkRequest);

    AppDispatcher.dispatch({
      type: LeadLinkActionTypes.CREATE_LEAD_LINK,
    });

    createLinkRequest = APIRequest.post({
      resource: `/v1/leads/${leadID}/links/`,
      data:     {
        ...link,
      },
    });

    createLinkRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadLinkActionTypes.CREATE_LEAD_LINK_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  LeadLinkActionTypes.CREATE_LEAD_LINK_DONE,
        links: response.body,
      });
    });
  },

  updateLeadLink(leadID, link) {
    APIRequest.abort(updateLinkRequest);

    AppDispatcher.dispatch({
      type: LeadLinkActionTypes.UPDATE_LEAD_LINK,
    });

    updateLinkRequest = APIRequest.put({
      resource: `/v1/leads/${leadID}/links/${link.id}`,
      data:     {
        ...link,
      },
    });

    updateLinkRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadLinkActionTypes.UPDATE_LEAD_LINK_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  LeadLinkActionTypes.UPDATE_LEAD_LINK_DONE,
        links: response.body,
      });
    });
  },

  deleteLeadLink(leadID, link) {
    APIRequest.abort(deleteLinkRequest);

    AppDispatcher.dispatch({
      type: LeadLinkActionTypes.DELETE_LEAD_LINK,
      leadID,
      link,
    });

    deleteLinkRequest = APIRequest.delete({
      resource: `/v1/leads/${leadID}/links/${link.id}`,
    });

    deleteLinkRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadLinkActionTypes.DELETE_LEAD_LINK_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  LeadLinkActionTypes.DELETE_LEAD_LINK_DONE,
        links: response.body,
      });
    });
  },

  updateFromFullContact(leadID) {
    APIRequest.abort(fullContactRequest);

    AppDispatcher.dispatch({
      type: LeadLinkActionTypes.UPDATE_FROM_FULLCONTACT,
    });

    fullContactRequest = APIRequest.post({
      resource: `/v1/leads/${leadID}/links/autoload`,
    });

    fullContactRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadLinkActionTypes.UPDATE_FROM_FULLCONTACT_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  LeadLinkActionTypes.UPDATE_FROM_FULLCONTACT_DONE,
        links: response.body,
      });
    });
  },
};

export default LeadLinkActions;
