import React from 'react';
import { PropTypes } from 'prop-types';
import Select from './NewSelect';

const MeridianSelect = ({ isInvalid, onChange, value = '' }) => {
  const options = [
    { value: 'am', label: 'AM' },
    { value: 'pm', label: 'PM' },
  ];

  return (
    <Select
      options={options}
      isInvalid={isInvalid}
      onChange={onChange}
      value={value}
    />
  );
};

MeridianSelect.defaultProps = {
  onChange:  () => {},
  value:     '',
  isInvalid: false,
};

MeridianSelect.propTypes = {
  onChange: PropTypes.func,
  value:    PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  isInvalid: PropTypes.bool,
};

export default MeridianSelect;
