const AccountActionTypes = {
  UPDATE_ACCOUNT:           'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_DONE:      'UPDATE_ACCOUNT_DONE',
  UPDATE_ACCOUNT_FAIL:      'UPDATE_ACCOUNT_FAIL',

  UPDATE_TEAM:              'UPDATE_TEAM',
  UPDATE_TEAM_DONE:         'UPDATE_TEAM_DONE',
  UPDATE_TEAM_FAIL:         'UPDATE_TEAM_FAIL',

  UPDATE_LANDING_PAGE:      'UPDATE_LANDING_PAGE',
  UPDATE_LANDING_PAGE_DONE: 'UPDATE_LANDING_PAGE_DONE',
  UPDATE_LANDING_PAGE_FAIL: 'UPDATE_LANDING_PAGE_FAIL',
};

export default AccountActionTypes;
