import autoBind            from 'react-autobind';
import React               from 'react';
import shadowRoot          from 'react-shadow';

import TimelineActivity from '~/components/drawers/timeline/activity';
import LeadHelpers      from '~/helpers/lead_helpers';

class TimelineActivityAppointment extends TimelineActivity {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  renderView() {
    const { activity } = this.state;
    const appointmentAt = LeadHelpers.renderAppointmentAt(activity.occurred_at);

    return (
      <div>
        <p className="text-grey mb5">
          on
          {' '}
          {appointmentAt}
        </p>

        {(activity.body
          && (
          <shadowRoot.div className="form-control font-activity">
            <div className="content" dangerouslySetInnerHTML={{ __html: activity.body || '' }} />
          </shadowRoot.div>
          )
        )}
      </div>
    );
  }
}

TimelineActivityAppointment.defaultProps = {
  editable:       false,
  deletable:      true,
  title:          'Appointment Logged',
  icon:           'calendar-check',
  prefix:         'far',
  color:          'text-blue',
  alwaysPublic:   false,
};

export default TimelineActivityAppointment;
