import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LabelActions from '~/actions/label_actions';
import LabelStore   from '~/stores/label_store';
import LabelForm    from '~/components/pages/account/forms/label_form';
import LabelsTable  from './labels_table';

class ListingLabels extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingLabels: false,
      labels:        [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.labelStoreListener = LabelStore.addListener(this.onLabelStoreChange);

    LabelActions.loadLabels();
  }

  componentWillUnmount() {
    if (this.labelStoreListener) this.labelStoreListener.remove();
  }

  onLabelStoreChange() {
    const labelStoreState = LabelStore.getState();
    const { loadingLabels, labels } = labelStoreState;

    this.setState({
      loadingLabels,
      labels,
    });
  }

  deleteLabel(label) {
    if (_lodash.isEmpty(label)) return;

    LabelActions.deleteLabel(label);
  }

  renderLabelsTable() {
    const { loadingLabels, labels } = this.state;

    if (loadingLabels) {
      return (
        <div className="text-center card-block">
          <FontAwesomeIcon testid="spinner" icon="far fa-spinner" pulse className="fa-lg" />
        </div>
      );
    }

    if (labels.length === 0) {
      return <div className="text-grey-dark card-block text-center">You don&apos;t have the label, please add.</div>;
    }

    return <LabelsTable loading={loadingLabels} labels={labels} deleteLabel={this.deleteLabel} />;
  }

  render() {
    const { labels } = this.state;

    return (
      <div className="card label">
        <div className="card-block">
          <h4 className="mb15">
            Labels
          </h4>
        </div>
        <div className="mb15">
          <hr />
          <LabelForm labels={labels} />
        </div>
        {this.renderLabelsTable()}
      </div>
    );
  }
}

ListingLabels.defaultProps = {};

ListingLabels.propTypes = {};

export default ListingLabels;
