import React from 'react';
import Tab   from 'react-bootstrap/Tab';
import Nav   from 'react-bootstrap/Nav';

import LeadsImportTab            from './leads_import_tab';
import TeamImportTab             from './team_import_tab';
import BrokerMetricsImportTab    from './broker_metrics_import_tab';
import MarketViewBrokerImportTab from './mark_view_broker_import_tab';

const DataImportTab = () => (
  <Tab.Container id="import-tabs" defaultActiveKey="lead_import" mountOnEnter unmountOnExit>
    <div className="row">
      <div className="col-sm-4">
        <Nav fill variant="pills" className="flex-column">
          <Nav.Item>
            <Nav.Link eventKey="lead_import">Leads</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="team_import">Team Members</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="broker_mertrics_import">BrokerMetrics</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="mark_view_broker_import">MarketView Broker</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      <div className="col-sm-8">
        <Tab.Content>
          <Tab.Pane eventKey="lead_import">
            <LeadsImportTab />
          </Tab.Pane>
          <Tab.Pane eventKey="team_import">
            <TeamImportTab />
          </Tab.Pane>
          <Tab.Pane eventKey="broker_mertrics_import">
            <BrokerMetricsImportTab />
          </Tab.Pane>
          <Tab.Pane eventKey="mark_view_broker_import">
            <MarketViewBrokerImportTab />
          </Tab.Pane>
        </Tab.Content>
      </div>
    </div>
  </Tab.Container>
);

export default DataImportTab;
