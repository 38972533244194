import autoBind  from 'react-autobind';
import React     from 'react';
import PropTypes from 'prop-types';

import APIRequest from '~/lib/api_request';
import Select     from '~/components/forms/select';

class ClosestOfficeSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      options: [],
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    APIRequest.get({
      resource: '/v1/leads/all_closest_offices',
    }).end((error, response) => {
      if (this._isMounted) {
        const body = (response && response.body) || [];
        const options = _lodash.map(body, (e) =>  ({ value: e, label: e }));

        this.setState({
          options,
          loading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Select
        {...this.props}
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.state.options}
        isLoading={this.state.loading}
      />
    );
  }
}

ClosestOfficeSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

ClosestOfficeSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default ClosestOfficeSelect;
