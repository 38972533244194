import React from 'react'

const DocumentLimitAlert = ({onClose}) => (
  <div>
    <div className="alert alert-dismissible fade show px-1" role="alert">
      <div className="text-left ml-2">
        <button type="button" className="close pr-1 pt-0" data-dismiss="alert" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 className="alert-heading mt-2">
          Oh no, you've reached your allowed document limit
        </h4>
        <p>Delete some old documents or upgrade your subscription.</p>
        <a href="/billing" className="btn btn-primary mt-2">Upgrade your Subscription</a>
      </div>
    </div>
  </div>
)

export default DocumentLimitAlert
