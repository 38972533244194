import AppDispatcher                   from '~/dispatchers/app_dispatcher';
import APIRequest                      from '~/lib/api_request';
import CampaignSubscriptionActionTypes from '~/types/campaign_subscription_action_types';
import LeadDrawerActions               from '~/actions/lead_drawer_actions';
import AgentActions                    from '~/actions/agent_actions';
import LeadDrawerStore                 from '~/stores/lead_drawer_store';

let campaignSubscriptionsRequest;
let createCampaignSubscriptionRequest;
let updateCampaignSubscriptionRequest;
let deleteCampaignSubscriptionRequest;

const CampaignSubscriptionActions = {
  updateSubscriptionStatus(leadID, subscriptionID, action, onDone = () => false, onFail = () => false) {
    APIRequest.put({
      resource: `/v1/leads/${leadID}/subscriptions/${subscriptionID}/${action}`,
    }).end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  runSubscription(leadID, subscriptionID, onDone = () => false, onFail = () => false) {
    this.updateSubscriptionStatus(leadID, subscriptionID, 'run', onDone, onFail);
  },

  pauseSubscription(leadID, subscriptionID, onDone = () => false, onFail = () => false) {
    this.updateSubscriptionStatus(leadID, subscriptionID, 'pause', onDone, onFail);
  },

  endSubscription(leadID, subscriptionID, onDone = () => false, onFail = () => false) {
    this.updateSubscriptionStatus(leadID, subscriptionID, 'end', onDone, onFail);
  },

  loadCampaignSubscriptions(leadID) {
    APIRequest.abort(campaignSubscriptionsRequest);

    AppDispatcher.dispatch({
      type: CampaignSubscriptionActionTypes.LOAD_CAMPAIGN_SUBSCRIPTIONS,
    });

    campaignSubscriptionsRequest = APIRequest.get({
      resource: `/v1/leads/${leadID}/subscriptions`,
    });

    campaignSubscriptionsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignSubscriptionActionTypes.LOAD_CAMPAIGN_SUBSCRIPTIONS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:          CampaignSubscriptionActionTypes.LOAD_CAMPAIGN_SUBSCRIPTIONS_DONE,
        subscriptions: response.body,
      });
    });
  },

  createCampaignSubscription(leadID, subscription) {
    const { recordIndex } = LeadDrawerStore.getState();

    APIRequest.abort(createCampaignSubscriptionRequest);

    AppDispatcher.dispatch({
      type: CampaignSubscriptionActionTypes.CREATE_CAMPAIGN_SUBSCRIPTION,
      leadID,
      subscription,
    });

    createCampaignSubscriptionRequest = APIRequest.post({
      resource: `/v1/leads/${leadID}/subscriptions`,
      data:     { campaign_subscription: subscription },
    });

    createCampaignSubscriptionRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   CampaignSubscriptionActionTypes.CREATE_CAMPAIGN_SUBSCRIPTION_FAIL,
          errors: response.body.errors,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:         CampaignSubscriptionActionTypes.CREATE_CAMPAIGN_SUBSCRIPTION_DONE,
        subscription: response.body,
      });

      // LeadDrawerActions.loadLead(leadID, { recordIndex });
      // AgentActions.reloadLeadTables();
    });
  },

  updateCampaignSubscription(leadID, subscription) {
    APIRequest.abort(updateCampaignSubscriptionRequest);

    AppDispatcher.dispatch({
      type: CampaignSubscriptionActionTypes.UPDATE_CAMPAIGN_SUBSCRIPTION,
      leadID,
      subscription,
    });

    updateCampaignSubscriptionRequest = APIRequest.put({
      resource: `/v1/leads/${leadID}/subscriptions/${subscription.id}`,
      data:     { campaign_subscription: subscription },
    });

    updateCampaignSubscriptionRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:   CampaignSubscriptionActionTypes.UPDATE_CAMPAIGN_SUBSCRIPTION_FAIL,
          errors: response.body.errors,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:         CampaignSubscriptionActionTypes.UPDATE_CAMPAIGN_SUBSCRIPTION_DONE,
        subscription: response.body,
      });
    });
  },

  deleteCampaignSubscription(leadID, subscription) {
    const { recordIndex } = LeadDrawerStore.getState();

    APIRequest.abort(deleteCampaignSubscriptionRequest);

    AppDispatcher.dispatch({
      type: CampaignSubscriptionActionTypes.DELETE_CAMPAIGN_SUBSCRIPTION,
      leadID,
      subscription,
    });

    deleteCampaignSubscriptionRequest = APIRequest.delete({
      resource: `/v1/leads/${leadID}/subscriptions/${subscription.id}`,
    });

    deleteCampaignSubscriptionRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignSubscriptionActionTypes.DELETE_CAMPAIGN_SUBSCRIPTION_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: CampaignSubscriptionActionTypes.DELETE_CAMPAIGN_SUBSCRIPTION_DONE,
      });

      // LeadDrawerActions.loadLead(leadID, { recordIndex });
      // AgentActions.reloadLeadTables();
    });
  },
};

export default CampaignSubscriptionActions;
