import React from 'react';
import PropTypes from 'prop-types';
import Select from './SelectWrapper/Select';

const TimeSelect = ({
  name, value, startHour, endHour, onChange, increments, disabled, ...restProps
}) => {
  const timeSelectOptions = () => {
    const parsedStartHour = parseInt(startHour, 10);
    const parsedEndHour = parseInt(endHour, 10);
    const hoursRange = parsedEndHour - parsedStartHour + 1;

    return Array.from({ length: hoursRange * increments.length }, (_, i) => {
      const hour = parsedStartHour + Math.floor(i / increments.length);
      const inc = increments[i % increments.length];
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const displayHour = hour > 12 ? hour - 12 : hour || 12;

      return {
        value: `${String(hour).padStart(2, '0')}:${inc}`,
        label: `${String(displayHour).padStart(2, '0')}:${inc} ${ampm}`,
      };
    });
  };

  return (
    <Select
      name={name}
      value={value}
      options={timeSelectOptions()}
      isDisabled={disabled}
      onChange={onChange}
      {...restProps}
    />
  );
};

TimeSelect.defaultProps = {
  name:       '',
  value:      '',
  startHour:  6,
  endHour:    20,
  increments: ['00', '15', '30', '45'],
  onChange:   () => false,
  disabled:   false,
};

TimeSelect.propTypes = {
  name:       PropTypes.string,
  value:      PropTypes.string,
  startHour:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endHour:    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  increments: PropTypes.arrayOf(PropTypes.string),
  onChange:   PropTypes.func,
  disabled:   PropTypes.bool,
};

export default TimeSelect;
