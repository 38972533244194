import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import Activity from './Activity';
import editFormReducer, { openEditForm, closeEditForm } from '~/components/hooks/editFormReducer';
import InfiniteScroll from '~/components/VirtualList/InfiniteScroll';

const Activities = ({
  loadingActivities,
  setLoadingActivities,
  activities,
  nextPage,
  prevPage,
  loadMoreActivities,
  activeTab,
  setActiveTab,
  hideOlderThan,
  expanded,
  setExpanded,
  activitiesCount,
  lead,
  setCurrentFormWithData,
  toggleActivityInternal,
  removeActivityFromList,
}) => {
  const location = useLocation();
  const infiniteScrollRef = useRef(null);
  const [editFormState, dispatch] = useReducer(editFormReducer, { isFormOpen: false });
  const [showAfterActivities, setShowAfterActivities] = useState(true);

  const forceUpdateList = useCallback(() => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.forceUpdateList();
    }
  }, []);

  useLayoutEffect(() => {
    forceUpdateList();
  }, [editFormState.isFormOpen, forceUpdateList]);

  useEffect(() => {
    const scrollToElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    const hash = location.hash.replace('#', '');
    if (!loadingActivities && hash) {
      setTimeout(() => {
        scrollToElement(hash);
      }, 0);
    }
  }, [loadingActivities]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const tabSelect = hash.split('-')[1];
      if (tabSelect === 'SmsMessage') {
        setActiveTab('texts');
      } else if (tabSelect === 'Email') {
        setActiveTab('email');
      } else {
        setActiveTab('all');
      }
    }
  }, []);

  useEffect(() => {
    forceUpdateList();
  }, [editFormState.isFormOpen, forceUpdateList]);

  const renderLoading = () => (
    <div className="text-center mt-3">
      <FontAwesomeIcon icon="far fa-spinner" pulse />
    </div>
  );

  const renderNoActivities = () => {
    if (!loadingActivities && activities.length === 0 && activeTab !== 'all' && activeTab !== 'changes') {
      return (
        <div className="text-center mt-3">
          <p>No activities found for this tab.</p>
        </div>
      );
    }
    return null;
  };

  const validActivities = activities.filter((activity) => activity !== null);

  const renderActivityEntries = useCallback((activity, isLoading, index, editFormOpen) => {
    if (!activity) {
      return null;
    }

    return (
      <Activity
        key={activity.id || index}
        activity={activity}
        lead={lead}
        setCurrentFormWithData={setCurrentFormWithData}
        toggleActivityInternal={toggleActivityInternal}
        removeActivity={removeActivityFromList}
        dispatch={dispatch}
        editFormState={editFormState}
        forceUpdateList={forceUpdateList}
      />
    );
  }, [loadingActivities, lead, setCurrentFormWithData, toggleActivityInternal, removeActivityFromList, editFormState, forceUpdateList]);

  if (loadingActivities && activities.length === 0) {
    return renderLoading();
  }

  const sortedActivities = _lodash.orderBy(validActivities, ['occurred_at'], 'desc');
  const beforeActivities = sortedActivities.filter((activity) => Moment(activity.occurred_at).isBefore(hideOlderThan));
  const afterActivities = sortedActivities.filter((activity) => Moment(activity.occurred_at).isAfter(hideOlderThan));

  const ShowButton = ({ onClick }) => (
    <div className="text-center mb15">
      <button
        type="button"
        onClick={onClick}
        className="btn btn-secondary btn-wide btn-sm"
      >
        {beforeActivities.length}
        {' '}
        older activities, click to show recruiting history
      </button>
    </div>
  );

  const HideButton = ({ onClick }) => (
    <div className="text-center mb15">
      <button
        type="button"
        onClick={onClick}
        className="btn btn-secondary btn-wide btn-sm"
      >
        Hide recruiting history
      </button>
    </div>
  );

  const toggleAfterActivities = () => {
    setShowAfterActivities(!showAfterActivities);
  };

  const combinedActivities = showAfterActivities
    ? [...afterActivities, ...beforeActivities]
    : [...afterActivities];

  infiniteScrollRef.current?.forceUpdateList();

  const renderInfiniteScroll = (items) => (
    <InfiniteScroll
      ref={infiniteScrollRef}
      items={items}
      renderItem={renderActivityEntries}
      itemSize={100}
      nextPage={nextPage}
      prevPage={prevPage}
      isNextPageLoading={loadingActivities}
      setLoadingActivities={setLoadingActivities}
      loadNextPage={loadMoreActivities}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      lead={lead}
      isFormOpen={editFormState.isFormOpen}
      editFormState={editFormState}
      isActivitiesComponent
      inverse
    />
  );

  if (GlobalContainer.product() === 'retention') {
    return (
      <>
        <div className="text-center">
          {(showAfterActivities ? (
            <HideButton onClick={toggleAfterActivities} />
          ) : (
            <ShowButton onClick={toggleAfterActivities} />
          ))}
        </div>
        <div className="mt-4 vh-100">
          {combinedActivities.length > 0
            ? renderInfiniteScroll(combinedActivities)
            : renderNoActivities()}
        </div>
      </>
    );
  }

  return (
    <div className="mt-4 vh-100">
      {sortedActivities.length > 0
        ? renderInfiniteScroll(sortedActivities)
        : renderNoActivities()}
    </div>
  );
};

Activities.propTypes = {
  loadingActivities:      PropTypes.bool.isRequired,
  activities:             PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeTab:              PropTypes.string.isRequired,
  hideOlderThan:          PropTypes.instanceOf(Date).isRequired,
  expanded:               PropTypes.bool.isRequired,
  setExpanded:            PropTypes.func.isRequired,
  lead:                   PropTypes.shape({}).isRequired,
  setCurrentFormWithData: PropTypes.func.isRequired,
};

export default Activities;
