import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import { Input }           from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltipable        from '~/components/effects/tooltipable';
import TaskActions        from '~/actions/task_actions';
import TaskStore          from '~/stores/task_store';
import LeadsSelect        from '~/components/forms/leads_select';
import TaskCategorySelect from '~/components/forms/task_category_select';
import TaskPrioritySelect from '~/components/forms/task_priority_select';
import TaskStatusSelect   from '~/components/forms/task_status_select';
import TaskEditor         from '~/components/forms/HtmlEditors/TaskEditor';
import FollowUpPicker     from '~/components/forms/lead_fields/follow_up_picker';
import { TeammatePicker } from '~/components/forms/team_member_picker';
import AppModal           from './app_modal';

class TaskModal extends React.Component {
  constructor(props) {
    super(props);

    const { taskID } = props;

    this._isMounted = false;

    this.state = {
      loadingTask: !!taskID,
      task:        {},
      errors:      {},
      submitting:  false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    // listen to taskStoreListener changes
    this.taskStoreListener = TaskStore.addListener(
      this.onTaskStoreChange,
    );

    const { taskID } = this.props;

    if (taskID && this._isMounted) {
      TaskActions.loadTask(taskID, this.onTaskLoad);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    // remove listener to TaskStore changes on Unmount
    if (this.taskStoreListener) this.taskStoreListener.remove();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { task } = this.state;
    const { lead, loadLead } = this.props;

    this.setState({ submitting: true });

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      if (loadLead) {
        TaskActions.updateTask({
          ...task,
          id:     task.id,
          leadID: lead.id,
        });
      } else {
        TaskActions.updateTask({ ...task, id: task.id });
      }
    } else {
      this.setState({ submitting: false, errors });
    }
  }

  onTaskLoad(task) {
    this.setState({
      loadingTask: false,
      task,
    });
  }

  onTaskStoreChange() {
    const taskState = TaskStore.getState();
    const { task, errors, lastTaskStoreAction } = taskState;
    const $modal = $(this.appModal.modal);

    let nextState = { taskState };

    if (lastTaskStoreAction === 'updateTaskDone') {
      $modal.modal('hide');
      GlobalContainer.notify(
        `Task "${task.name}" updated.`,
        'success',
      );
    }

    if (lastTaskStoreAction === 'updateTaskFail') {
      if (errors) {
        nextState = { ...nextState, errors, submitting: false };
      }
    }

    this.setState(nextState);
  }

  setTaskField(name, val) {
    this.setState((prevState) => ({
      task: {
        ...prevState.task,
        [name]: val,
      },
    }));
  }

  handleMarkAsDoneTaskClick = (e) => {
    e.preventDefault();

    this.setTaskField('status', 'done');

    this.setState({ submitting: true }, () => {
      const { task } = this.state;
      const { lead, loadLead } = this.props;

      const errors = this.validate();

      if (_lodash.size(errors) === 0) {
        if (loadLead) {
          TaskActions.updateTask({
            ...task,
            id:     task.id,
            leadID: lead.id,
          });
        } else {
          TaskActions.updateTask({ ...task, id: task.id });
        }
      } else {
        this.setState({ submitting: false, errors });
      }
    });
  };

  validate() {
    const { task } = this.state;
    const errors = {};

    if (!task.name) {
      errors.name = "Can't be empty";
    }

    if (!task.category) {
      errors.category = "Can't be empty";
    }

    if (!task.due_date_at) {
      errors.due_date_at = "Can't be empty";
    }

    if (!task.priority) {
      errors.priority = "Can't be empty";
    }

    if (!task.status) {
      errors.status = "Can't be empty";
    }

    if (_lodash.isEmpty(task.lead_ids)) {
      errors.lead_ids = "Can't be empty";
    }

    this.setState({ errors });

    return errors;
  }

  renderTaskForm() {
    const {
      loadingTask, task, errors, responseErrors,
    } = this.state;

    if (loadingTask) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    return (
      <>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="task_name">Task Name</label>
            <Input
              value={task.name}
              id="task_name"
              name="name"
              className={classNames(
                'd-block',
                errors.name ? 'has-error' : '',
              )}
              placeholder="Enter Name"
              onChange={(val) => this.setTaskField('name', val && val.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputCategory">Task Category</label>
            <TaskCategorySelect
              id="inputCategory"
              name="category"
              placeholder="Select Category"
              value={task.category}
              className={errors.category ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('category', opt && opt.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="task_due_date_at">Due Date</label>
            <FollowUpPicker
              id="task_due_date_at"
              ref={(el) => {
                this.picker = el;
              }}
              placeholder="Select Due Date"
              className={classNames({
                'has-error': errors.due_date_at,
              })}
              right
              disabledDays={[{ before: Moment().toDate() }]}
              date={task.due_date_at}
              onChange={(picker) => this.setTaskField(
                'due_date_at',
                picker && picker.date,
              )}
            />
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="task-description" className="d-block">Description</label>
          <TaskEditor
            className={errors.description ? 'tiny-mce has-error' : ''}
            onChange={(html) => this.setTaskField('description', html)}
            placeholder="Enter Description"
            value={task.description}
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="related-contacts-ids" className="d-block">
              Related Contacts
            </label>
            <LeadsSelect
              id="leads-tasks"
              placeholder="Please Select Contacts ..."
              selected_ids={task.lead_ids}
              multiple
              onChange={(ids) => this.setTaskField('lead_ids', ids)}
              isInvalid={!!errors.lead_ids}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label
              htmlFor="task_owner_id"
              className="form-control-label"
            >
              Owner
            </label>
            <TeammatePicker
              id="task_owner_id"
              name="owner_id"
              placeholder="Owner"
              value={task.owner_id}
              onChange={(opt) => this.setTaskField('owner_id', opt && opt.value)}
              clearable
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_priority">Priority</label>
            <TaskPrioritySelect
              id="task_priority"
              name="priority"
              placeholder="Select Priority"
              value={task.priority}
              className={errors.priority ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('priority', opt && opt.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="task_status">Status</label>
            <TaskStatusSelect
              id="task_status"
              name="status"
              placeholder="Select Status"
              value={task.status}
              className={errors.status ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('status', opt && opt.value)}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { task, submitting } = this.state;
    const { containerID, modalClass, dialogClass } = this.props;

    return (
      <AppModal
        containerID={containerID}
        modalClass={modalClass}
        dialogClass={dialogClass}
        ref={(appModal) => (this.appModal = appModal)}
      >
        <form
          method="PUT"
          action={`/tasks/${task.id}`}
          onSubmit={this.handleSubmit}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="appModalTask">
              Edit Task
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">{this.renderTaskForm()}</div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            {submitting ? (
              <button
                type="submit"
                className="btn btn-primary disabled"
                disabled
              >
                <FontAwesomeIcon
                  icon="far fa-spinner"
                  pulse
                  className="mr5"
                />
                {' '}
                Updating ...
              </button>
            ) : (
              <>
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
                <Tooltipable text="Mark Task as Done">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleMarkAsDoneTaskClick}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'fa-check-square']}
                    />
                  </button>
                </Tooltipable>
              </>
            )}
          </div>
        </form>
      </AppModal>
    );
  }
}

TaskModal.defaultProps = {
  lead:     {},
  loadLead: true,
};

TaskModal.propTypes = {
  taskID:   PropTypes.number.isRequired,
  lead:     PropTypes.shape({}),
  loadLead: PropTypes.bool,
};

export default TaskModal;
