import { ReduceStore }        from 'flux/utils';
import AppDispatcher          from '~/dispatchers/app_dispatcher';
import AppointmentActionTypes from '~/types/appointment_action_types';

class AppointmentStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingAppointments:    false,
      appointments:           [],
      appointmentStoreAction: null,
    };
  }

  loadAppointments(state, action) {
    const newState = { ...state };

    newState.loadingAppointments = false;

    return newState;
  }

  loadAppointmentsDone(state, action) {
    const newState = { ...state };

    newState.loadingAppointments = false;
    newState.appointments = action.appointments;
    newState.appointmentStoreAction = 'loadAppointmentsDone';

    return newState;
  }

  loadAppointmentsFail(state, action) {
    const newState = { ...state };

    newState.loadingAppointments = false;
    newState.appointmentStoreAction = 'loadAppointmentsFail';

    return newState;
  }

  deleteAppointmentDone(state, action) {
    const newState = { ...state };

    newState.appointmentStoreAction = 'deleteAppointmentDone';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case AppointmentActionTypes.LOAD_APPOINTMENTS:
        return this.loadAppointments(state, action);

      case AppointmentActionTypes.LOAD_APPOINTMENTS_DONE:
        return this.loadAppointmentsDone(state, action);

      case AppointmentActionTypes.LOAD_APPOINTMENTS_FAIL:
        return this.loadAppointmentsFail(state, action);

      case AppointmentActionTypes.DELETE_APPOINTMENT_DONE:
        return this.deleteAppointmentDone(state, action);

      default:
        return state;
    }
  }
}

export default new AppointmentStore();
