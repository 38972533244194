import React from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

export default function PdfDocViewer({ document }) {
  const { url } = document;
  const workerUrl = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

  const toolbarPluginInstance = toolbarPlugin();
  const getFilePluginInstance = getFilePlugin();
  const disableScrollPlugin = () => {
    const renderViewer = (props) => {
      const { slot } = props;

      if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
        slot.subSlot.attrs.style = { ...slot.subSlot.attrs.style, scrollbarWidth: 'thin', scrollbarColor: 'white white' };
      }

      return slot;
    };

    return {
      renderViewer,
    };
  };

  const disableScrollPluginInstance = disableScrollPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return (
    <div className='resume height'>
      <Worker workerUrl={workerUrl}>
        <Toolbar>
          {(props) => {
            const {
              CurrentPageInput,
              GoToNextPage,
              GoToPreviousPage,
              Zoom,
              ZoomIn,
              ZoomOut,
              EnterFullScreen,
            } = props;
            return (
              <div className="p8 mb5">
                <div className="pull-right resume toolbar resume-icon">
                  <span className="mr5">
                    <ZoomIn />
                  </span>
                  <span className="vertical-line" />
                  <span className="ml5">
                    <ZoomOut />
                  </span>
                  <span className="mr10 ml10 resume zoom-button">
                    <Zoom />
                  </span>
                  <EnterFullScreen />
                </div>
                <div className="resume toolbar">
                  <span className="ml10">
                    <GoToPreviousPage />
                  </span>
                  <span className="vertical-line" />
                  <span className="mr10">
                    <GoToNextPage />
                  </span>
                  <span className="mr10 toolbar-text-color">Page</span>
                  <span className="rpv-page-navigation__current-page-input">
                    <CurrentPageInput />
                  </span>
                </div>
              </div>
            );
          }}
        </Toolbar>
        <Viewer
          fileUrl={url}
          plugins={[toolbarPluginInstance, getFilePluginInstance, disableScrollPluginInstance]}
          defaultScale={SpecialZoomLevel.ActualSize}
        />
      </Worker>
    </div>
  );
}
