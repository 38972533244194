import { ReduceStore }            from 'flux/utils';
import AppDispatcher              from '~/dispatchers/app_dispatcher';
import CampaignLibraryActionTypes from '~/types/campaign_library_action_types';

class CampaignLibraryStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingCampaigns:        false,
      campaign_libraries:      {},
      campaign:                null,
      lastCampaignStoreAction: null,
      campaignTypes:           [],
    };
  }

  loadCampaigns(state, action) {
    const newState = { ...state };

    newState.loadingCampaigns = true;
    newState.lastCampaignStoreAction = 'loadCampaigns';

    return newState;
  }

  loadCampaignsDone(state, action) {
    const newState = { ...state };

    newState.loadingCampaigns = false;
    newState.campaign_libraries = action.campaign_libraries;
    newState.lastCampaignStoreAction = 'loadCampaignsDone';

    return newState;
  }

  loadCampaignsFail(state, action) {
    const newState = { ...state };

    newState.loadingCampaigns = false;
    newState.lastCampaignStoreAction = 'loadCampaignsFail';

    return newState;
  }

  loadCampaignTypes(state, action) {
    const newState = { ...state };

    newState.loadingCampaignTypes = true;
    newState.lastCampaignStoreAction = 'loadCampaignTypes';

    return newState;
  }

  loadCampaignTypesDone(state, action) {
    const newState = { ...state };

    newState.loadingCampaignTypes = false;
    newState.campaignTypes = action.campaignTypes;
    newState.lastCampaignStoreAction = 'loadCampaignTypesDone';

    return newState;
  }

  loadCampaignTypesFail(state, action) {
    const newState = { ...state };

    newState.loadingCampaignTypes = false;
    newState.lastCampaignStoreAction = 'loadCampaignTypesFail';

    return newState;
  }

  updateCampaign(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'updateCampaign';

    return newState;
  }

  updateCampaignDone(state, action) {
    const newState = { ...state };

    newState.campaign = action.campaign;
    newState.lastCampaignStoreAction = 'updateCampaignDone';

    return newState;
  }

  updateCampaignFail(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'updateCampaignFail';

    return newState;
  }

  deleteCampaign(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'deleteCampaign';

    return newState;
  }

  deleteCampaignDone(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'deleteCampaignDone';

    return newState;
  }

  deleteCampaignFail(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'deleteCampaignFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case CampaignLibraryActionTypes.LOAD_CAMPAIGNS:
        return this.loadCampaigns(state, action);

      case CampaignLibraryActionTypes.LOAD_CAMPAIGNS_DONE:
        return this.loadCampaignsDone(state, action);

      case CampaignLibraryActionTypes.LOAD_CAMPAIGNS_FAIL:
        return this.loadCampaignsFail(state, action);

      case CampaignLibraryActionTypes.UPDATE_CAMPAIGN:
        return this.updateCampaign(state, action);

      case CampaignLibraryActionTypes.UPDATE_CAMPAIGN_DONE:
        return this.updateCampaignDone(state, action);

      case CampaignLibraryActionTypes.UPDATE_CAMPAIGN_FAIL:
        return this.updateCampaignFail(state, action);

      case CampaignLibraryActionTypes.DELETE_CAMPAIGN:
        return this.deleteCampaign(state, action);

      case CampaignLibraryActionTypes.DELETE_CAMPAIGN_DONE:
        return this.deleteCampaignDone(state, action);

      case CampaignLibraryActionTypes.DELETE_CAMPAIGN_FAIL:
        return this.deleteCampaignFail(state, action);

      case CampaignLibraryActionTypes.LOAD_CAMPAIGN_TYPES:
        return this.loadCampaignTypes(state, action);

      case CampaignLibraryActionTypes.LOAD_CAMPAIGN_TYPES_DONE:
        return this.loadCampaignTypesDone(state, action);

      case CampaignLibraryActionTypes.LOAD_CAMPAIGN_TYPES_FAIL:
        return this.loadCampaignTypesFail(state, action);

      default:
        return state;
    }
  }
}

export default new CampaignLibraryStore();
