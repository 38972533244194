import AppDispatcher           from '~/dispatchers/app_dispatcher';
import APIRequest              from '~/lib/api_request';
import TwilioBundleActionTypes from '~/types/twilio_bundle_action_types';

let loadBundleRequest;
let createBundleRequest;

const TwilioBundleActions = {
  loadTwilioBundle() {
    APIRequest.abort(loadBundleRequest);

    AppDispatcher.dispatch({
      type: TwilioBundleActionTypes.LOAD_TWILIO_BUNDLE,
    });

    loadBundleRequest = APIRequest.get({
      resource: '/v1/twilio_profile',
    });

    loadBundleRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TwilioBundleActionTypes.LOAD_TWILIO_BUNDLE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    TwilioBundleActionTypes.LOAD_TWILIO_BUNDLE_DONE,
        profile: response.body,
      });
    });
  },

  createTwilioBundle(profile) {
    APIRequest.abort(createBundleRequest);

    AppDispatcher.dispatch({
      type: TwilioBundleActionTypes.CREATE_TWILIO_BUNDLE,
      profile,
    });

    createBundleRequest = APIRequest.post({
      resource: '/v1/twilio_profile',
      data:     { profile },
    });

    createBundleRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TwilioBundleActionTypes.CREATE_TWILIO_BUNDLE_FAIL,
          error: response.body.errors || error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    TwilioBundleActionTypes.CREATE_TWILIO_BUNDLE_DONE,
        profile: response.body,
      });
    });
  },
};

export default TwilioBundleActions;
