import React, { useEffect, useState }      from 'react';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames          from 'classnames';

import ScheduleOption       from './ScheduleOption';
import CustomScheduleOption from './CustomScheduleOption';

const scheduleOptions = [
  { id: '1-hour', value: '1 hour', label: 'In 1 hour' },
  { id: '2-hour', value: '2 hour', label: 'In 2 hours' },
  { id: '8AM', value: '08:00', label: 'Tomorrow Morning (8AM)' },
  { id: '1PM', value: '13:00', label: 'Tomorrow Afternoon (1PM)' },
  {
    id: 'Custom', value: 'Custom', label: 'Custom', isCustom: true,
  },
];

const ScheduleButton = ({
  schedule,
  disabled,
  setSchedule,
  popoverPlacement,
  containerRef,
}) => {
  const { selectedSchedule, customOptions: customOptionsProp } = schedule;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [errors, setErrors] = useState({});
  const [customOptions, setCustomOptions] = useState({
    date:     '',
    time:     '',
    timezone: customOptionsProp?.timezone || '',
  });

  useEffect(() => {
    if (isPopoverOpen && selectedSchedule) {
      const option = scheduleOptions.find((opt) => opt.value === selectedSchedule);

      if (option) {
        setSelectedOption(option);
        setCustomOptions(customOptionsProp);
      }
    }
  }, [isPopoverOpen, selectedSchedule]);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);

    if (!isPopoverOpen) {
      setSelectedOption(null);
      setCustomOptions({
        date:     '',
        time:     '',
        timezone: customOptionsProp?.timezone || '',
      });
      setErrors({});
    }
  };

  const validateCustomOptions = (options) => {
    const validationErrors = {};
    if (!options.date) validationErrors.date = 'Date is required.';
    if (!options.time) validationErrors.time = 'Time is required.';
    if (!options.timezone) validationErrors.timezone = 'Timezone is required.';
    return validationErrors;
  };

  const updateCustomOptions = (field, value) => {
    setCustomOptions((prev) => ({ ...prev, [field]: value }));

    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: null }));
    }
  };

  const handleConfirm = () => {
    if (selectedOption?.isCustom) {
      const validationErrors = validateCustomOptions(customOptions);

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
    }

    setSchedule({
      selectedSchedule: selectedOption?.value || '',
      customOptions:    selectedOption?.isCustom ? customOptions : {},
    });

    setIsPopoverOpen(false);
  };

  const handleSelectedSchedule = (e) => {
    const { value } = e.target;
    let option;
    if (value === selectedOption?.value) {
      option = null;
    } else {
      option = scheduleOptions.find((opt) => opt.value === value);
    }

    setSelectedOption(option);
  };

  const renderPopover = (props) => (
    <Popover
      {...props}
    >
      <Popover.Title className="bg-grey-lightest">Send Later</Popover.Title>
      <Popover.Content className="bg-grey-lightest">
        {scheduleOptions.map((option) => (
          <ScheduleOption
            key={option.id}
            id={option.id}
            value={option.value}
            labelText={option.label}
            onChange={handleSelectedSchedule}
            checked={selectedOption?.value === option.value}
          >
            {option?.isCustom && (
              <CustomScheduleOption
                isCustomSelected={selectedOption?.isCustom || false}
                updateCustomOptions={updateCustomOptions}
                customOptions={customOptions}
                errors={errors}
              />
            )}
          </ScheduleOption>
        ))}

        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleConfirm}
          >
            Confirm
          </button>

          <button
            type="button"
            className="btn btn-danger ml-2"
            onClick={togglePopover}
          >
            Cancel
          </button>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        placement={popoverPlacement}
        overlay={renderPopover}
        show={isPopoverOpen}
        container={containerRef.current}
      >
        <Button
          id="ScheduleUpdateButton"
          className={classNames('ml-0 border-left', { disabled })}
          disabled={disabled}
          onClick={togglePopover}
        >
          <FontAwesomeIcon
            icon={[selectedSchedule ? 'fad' : 'far', 'fa-clock']}
            className="mr5"
          />
        </Button>
      </OverlayTrigger>
    </>
  );
};

ScheduleButton.propTypes = {
  schedule: PropTypes.shape({
    selectedSchedule: PropTypes.string,
    timezone:         PropTypes.string,
    customOptions:    PropTypes.shape({
      time: PropTypes.string,
      date: PropTypes.string,
    }),
  }).isRequired,
  disabled:         PropTypes.bool,
  setSchedule:      PropTypes.func.isRequired,
  popoverPlacement: PropTypes.string,
  containerRef:     PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
};

ScheduleButton.defaultProps = {
  disabled:         false,
  popoverPlacement: 'bottom',
};

export default ScheduleButton;
