import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

class AutosuggestInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value:       '',
      suggestions: [],
    };
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const { suggestions } = this.props;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : suggestions.filter((suggestion) => suggestion.name.toLowerCase().slice(0, inputLength) === inputValue);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => (
    <div>
      {suggestion.name}
    </div>
  );

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue }, () => {
      this.props.onChange(newValue);
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const {
      id, name, placeholder, styles, value,
    } =  this.props;
    const { suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      id,
      name,
      placeholder,
      value,
      onChange:    this.onChange,
      className:   styles,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

AutosuggestInput.defaultProps = {
  onChange:    () => {},
  suggestions: [],
  placeholder: 'Select a suggestion...',
  id:          '',
  name:        '',
  styles:      '',
  value:       undefined,
};

AutosuggestInput.propTypes = {
  onChange:    PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string.isRequired,
  id:          PropTypes.string.isRequired,
  name:        PropTypes.string.isRequired,
  styles:      PropTypes.string.isRequired,
  value:       PropTypes.string,
};

export default AutosuggestInput;
