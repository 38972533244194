import qs from 'qs';
import autoBind from 'react-autobind';
import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltipable from '~/components/effects/tooltipable';

class LeadSourceItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      source: props.source,
    };
  }

  handleSearch(searchData) {
    const { history } = this.props;
    const searchQuery = qs.stringify({ s: searchData, _t: Rails.helpers.currentTeam.id }, { arrayFormat: 'brackets' });
    const resource = GlobalContainer.product() === 'retention' ? 'retention' : 'recruiter';

    history.push(`/${resource}/search?${decodeURI(searchQuery)}`, searchData);
  }

  render() {
    const { handleLeadSourceClick, handleLeadSourceDelete } = this.props;
    const { source } = this.state;

    return (
      <tr
        key={`source-${source.id}`}
        className="text-grey"
        onClick={(e) => {
          e.preventDefault();
          if (e.target.nodeName === 'TD') handleLeadSourceClick(source);
        }}
      >
        <td>
          <strong>
            <a
              href="#edit"
              className="text-grey-dark text-no-underline"
              onClick={(e) => {
                e.preventDefault();
                handleLeadSourceClick(source);
              }}
            >
              {source.name}
            </a>
          </strong>
          <p className="mt10">
            <small className="text-grey">
              <a
                href="#search"
                className="text-grey text-no-underline"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleSearch({ query: '', source: [source.name] });
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-paper-plane']} className="mr5" />
                {' '}
                view leads with this source
              </a>
            </small>
          </p>
        </td>
        <td className="col-2">
          <span className="pull-right">
            <Tooltipable text="Edit Source">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();
                  handleLeadSourceClick(source);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-edit']} />
              </button>
            </Tooltipable>

            <Tooltipable text="Deletes source">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleLeadSourceDelete(source);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </Tooltipable>
          </span>
        </td>
      </tr>
    );
  }
}

LeadSourceItem.defaultProps = {
  handleLeadSourceClick:  () => false,
  handleLeadSourceDelete: () => false,
};

LeadSourceItem.propTypes = {
  history:                PropTypes.shape({}).isRequired,
  source:                 PropTypes.shape({}).isRequired,
  handleLeadSourceClick:  PropTypes.func,
  handleLeadSourceDelete: PropTypes.func,
};

export default withRouter(LeadSourceItem);
