import { ReduceStore }         from 'flux/utils';
import AppDispatcher           from '~/dispatchers/app_dispatcher';
import TwilioBundleActionTypes from '~/types/twilio_bundle_action_types';

class TwilioBundleStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      bundle:                      null,
      status:                      null,
      business_information:        null,
      address:                     null,
      authorized_representative_1: null,
      authorized_representative_2: null,
      shaken_stir:                 null,
      brand:                       null,
      lastTwilioBundleStoreAction: null,
      creating:                    false,
      loading:                     false,
      error:                       false,
    };
  }

  loadTwilioBundle(state, action) {
    const newState = { ...state };

    newState.lastTwilioBundleStoreAction = 'loadBundle';
    newState.loading = true;
    newState.error = false;

    return newState;
  }

  loadTwilioBundleDone(state, action) {
    const newState = { ...state };

    newState.bundle = action.profile.bundle;
    newState.status = action.profile.status;
    newState.business_information = action.profile.business_information;
    newState.address = action.profile.address;
    newState.authorized_representative_1 = action.profile.authorized_representative_1;
    newState.authorized_representative_2 = action.profile.authorized_representative_2;
    newState.shaken_stir = action.profile.shaken_stir;
    newState.brand = action.profile.brand;
    newState.lastTwilioBundleStoreAction = 'loadBundleDone';
    newState.loading = false;
    newState.error = false;

    return newState;
  }

  loadTwilioBundleFail(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.error = true;
    newState.lastTwilioBundleStoreAction = 'loadBundleFail';

    return newState;
  }

  createTwilioBundle(state, action) {
    const newState = { ...state };

    newState.lastTwilioBundleStoreAction = 'createBundle';
    newState.creating = true;

    return newState;
  }

  createTwilioBundleDone(state, action) {
    const newState = { ...state };

    newState.bundle = action.profile.bundle;
    newState.status = action.profile.status;
    newState.business_information = action.profile.business_information;
    newState.address = action.profile.address;
    newState.shaken_stir = action.profile.shaken_stir;
    newState.brand = action.profile.brand;
    newState.lastTwilioBundleStoreAction = 'createBundleDone';
    newState.creating = false;

    return newState;
  }

  createTwilioBundleFail(state, action) {
    const newState = { ...state };

    newState.creating = false;
    newState.error_message = action.error;
    newState.lastTwilioBundleStoreAction = 'createBundleFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case TwilioBundleActionTypes.LOAD_TWILIO_BUNDLE:

        return this.loadTwilioBundle(state, action);
      case TwilioBundleActionTypes.LOAD_TWILIO_BUNDLE_DONE:

        return this.loadTwilioBundleDone(state, action);
      case TwilioBundleActionTypes.LOAD_TWILIO_BUNDLE_FAIL:

        return this.loadTwilioBundleFail(state, action);
      case TwilioBundleActionTypes.CREATE_TWILIO_BUNDLE:

        return this.createTwilioBundle(state, action);
      case TwilioBundleActionTypes.CREATE_TWILIO_BUNDLE_DONE:

        return this.createTwilioBundleDone(state, action);
      case TwilioBundleActionTypes.CREATE_TWILIO_BUNDLE_FAIL:

        return this.createTwilioBundleFail(state, action);
      default:
        return state;
    }
  }
}

export default new TwilioBundleStore();
