import { ReduceStore }       from 'flux/utils';
import AppDispatcher         from '~/dispatchers/app_dispatcher';
import LeadDrawerActionTypes from '~/types/lead_drawer_action_types';

class LeadDrawerStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      currentAction:         null, // current side forms action
      loadingLead:           false,
      updatingLead:          false,
      lead:                  null,
      leadDrawerStoreAction: null, // store last dispatch action name
      recordIndex:           null,
      smsMessage:            {},
      email:                 {},
      inboxMessage:          {},
    };
  }

  loadLead(state, action) {
    const newState = { ...state };

    newState.loadingLead = true;
    newState.leadDrawerStoreAction = 'loadLead';

    return newState;
  }

  loadLeadDone(state, action) {
    const newState = { ...state };

    newState.loadingLead = false;
    newState.lead = action.lead;
    newState.leadDrawerStoreAction = 'loadLeadDone';
    newState.recordIndex = action.recordIndex;

    return newState;
  }

  loadLeadFail(state, action) {
    const newState = { ...state };

    newState.loadingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'loadLeadFail';

    return newState;
  }

  createLead(state, action) {
    const newState = { ...state };

    newState.updatingLead = true;
    newState.leadDrawerStoreAction = 'createLead';

    return newState;
  }

  createLeadDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.lead = action.lead;
    newState.errors = {};
    newState.responseErrors = {};
    newState.leadDrawerStoreAction = 'createLeadDone';

    return newState;
  }

  createLeadFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'createLeadFail';

    return newState;
  }

  updateLead(state, action) {
    const newState = { ...state };

    newState.updatingLead = true;
    newState.leadDrawerStoreAction = 'updateLead';

    return newState;
  }

  updateLeadDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.lead = action.lead;
    newState.errors = {};
    newState.leadDrawerStoreAction = 'updateLeadDone';

    return newState;
  }

  updateLeadFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'updateLeadFail';

    return newState;
  }

  updateLeadAvatar(state, action) {
    const newState = { ...state };

    newState.updatingLead = true;
    newState.leadDrawerStoreAction = 'updateLeadAvatar';

    return newState;
  }

  updateLeadAvatarDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.lead = action.lead;
    newState.errors = {};
    newState.leadDrawerStoreAction = 'updateLeadAvatarDone';

    return newState;
  }

  updateLeadAvatarFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'updateLeadAvatarFail';

    return newState;
  }

  removeLeadAvatar(state, action) {
    const newState = { ...state };

    newState.updatingLead = true;
    newState.leadDrawerStoreAction = 'removeLeadAvatar';

    return newState;
  }

  removeLeadAvatarDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.lead = action.lead;
    newState.errors = {};
    newState.leadDrawerStoreAction = 'removeLeadAvatarDone';

    return newState;
  }

  removeLeadAvatarFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'removeLeadAvatarFail';

    return newState;
  }

  updateLeadRole(state, action) {
    const newState = { ...state };

    newState.updatingLead = true;
    newState.leadDrawerStoreAction = 'updateLeadRole';

    return newState;
  }

  updateLeadRoleDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.lead = Object.assign(state.lead, action);
    newState.leadDrawerStoreAction = 'updateLeadRoleDone';

    return newState;
  }

  updateLeadRoleFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.leadDrawerStoreAction = 'updateLeadRoleFail';

    return newState;
  }

  createLeadAppointmentDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    // newState.lead = action.email.lead;
    newState.leadDrawerStoreAction = 'createLeadAppointmentDone';

    return newState;
  }

  createLeadAppointmentFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'createLeadAppointmentFail';

    return newState;
  }

  createLeadSmsMessageDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.smsMessage = action.smsMessage;
    newState.inboxMessage = action.inboxMessage;
    newState.leadDrawerStoreAction = 'createLeadSmsMessageDone';

    return newState;
  }

  createLeadSmsMessageFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.smsMessage = {};
    newState.inboxMessage = {};
    newState.leadDrawerStoreAction = 'createLeadSmsMessageFail';

    return newState;
  }

  updateLeadSmsMessageDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.smsMessage = action.smsMessage;
    newState.inboxMessage = action.inboxMessage;
    newState.leadDrawerStoreAction = 'updateLeadSmsMessageDone';

    return newState;
  }

  updateLeadSmsMessageFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.smsMessage = {};
    newState.inboxMessage = {};
    newState.leadDrawerStoreAction = 'updateLeadSmsMessageFail';

    return newState;
  }

  cancelScheduledLeadSmsMessageDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.deletingActivity = false;
    newState.leadDrawerStoreAction = 'cancelScheduledLeadSmsMessageDone';

    return newState;
  }

  cancelScheduledLeadSmsMessageFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'cancelScheduledLeadSmsMessageFail';

    return newState;
  }

  createLeadEmailDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.email = action.email;
    newState.inboxMessage = action.inboxMessage;
    newState.leadDrawerStoreAction = 'createLeadEmailDone';

    return newState;
  }

  createLeadEmailFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.email = {};
    newState.inboxMessage = {};
    newState.leadDrawerStoreAction = 'createLeadEmailFail';

    return newState;
  }

  updateLeadEmailDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.email = action.email;
    newState.inboxMessage = action.inboxMessage;
    newState.leadDrawerStoreAction = 'updateLeadEmailDone';

    return newState;
  }

  updateLeadEmailFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.email = {};
    newState.inboxMessage = {};
    newState.leadDrawerStoreAction = 'updateLeadEmailFail';

    return newState;
  }

  cancelScheduledLeadEmailDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.deletingActivity = false;
    newState.leadDrawerStoreAction = 'cancelScheduledLeadEmailDone';

    return newState;
  }

  cancelScheduledLeadEmailFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'cancelScheduledLeadEmailFail';

    return newState;
  }

  createLeadActivityDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    // newState.lead = action.email.lead;
    newState.leadDrawerStoreAction = 'createLeadActivityDone';

    return newState;
  }

  createLeadActivityFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'createLeadActivityFail';

    return newState;
  }

  updateLeadActivityDone(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.activity = action.activity;
    newState.leadDrawerStoreAction = 'updateLeadActivityDone';

    return newState;
  }

  updateLeadActivityFail(state, action) {
    const newState = { ...state };

    newState.updatingLead = false;
    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'updateLeadActivityFail';

    return newState;
  }

  reassignLead(state, action) {
    const newState = { ...state };

    newState.reassignLead = true;
    newState.leadDrawerStoreAction = 'reassignLead';

    return newState;
  }

  reassignLeadDone(state, action) {
    const newState = { ...state };

    newState.reassignLead = false;
    newState.errors = {};
    newState.message = action.activity.message;
    newState.leadDrawerStoreAction = 'reassignLeadDone';

    return newState;
  }

  reassignLeadFail(state, action) {
    const newState = { ...state };

    newState.reassignLead = false;
    newState.errors = action.errors || action.activity;
    newState.message = action.activity.message;
    newState.leadDrawerStoreAction = 'reassignLeadFail';

    return newState;
  }

  unloadLead(state, action) {
    const newState = { ...state };

    newState.currentAction = null;
    newState.loadingLead = false;
    newState.updatingLead = false;
    newState.lead = null;
    newState.leadDrawerStoreAction = null;

    return newState;
  }

  createResume(state, action) {
    const newState = { ...state };

    newState.leadDrawerStoreAction = 'createResume';

    return newState;
  }

  createResumeDone(state, action) {
    const newState = { ...state };

    newState.lead = action.lead;
    newState.leadDrawerStoreAction = 'createResumeDone';

    return newState;
  }

  createResumeFail(state, action) {
    const newState = { ...state };

    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'createResumeFail';

    return newState;
  }

  deleteResume(state) {
    const newState = { ...state };

    newState.leadDrawerStoreAction = 'deleteResume';

    return newState;
  }

  deleteResumeDone(state, action) {
    const newState = { ...state };

    newState.lead = action.lead;
    newState.leadDrawerStoreAction = 'deleteResumeDone';

    return newState;
  }

  deleteResumeFail(state, action) {
    const newState = { ...state };

    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'deleteResumeFail';

    return newState;
  }

  updateResume(state, action) {
    const newState = { ...state };

    newState.leadDrawerStoreAction = 'updateResume';

    return newState;
  }

  updateResumeDone(state, action) {
    const newState = { ...state };

    newState.lead = action.lead;
    newState.leadDrawerStoreAction = 'updateResumeDone';

    return newState;
  }

  updateResumeFail(state, action) {
    const newState = { ...state };

    newState.errors = action.errors;
    newState.leadDrawerStoreAction = 'updateResumeFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case LeadDrawerActionTypes.LOAD_LEAD:
        return this.loadLead(state, action);

      case LeadDrawerActionTypes.LOAD_LEAD_DONE:
        return this.loadLeadDone(state, action);

      case LeadDrawerActionTypes.LOAD_LEAD_FAIL:
        return this.loadLeadFail(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD:
        return this.createLead(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_DONE:
        return this.createLeadDone(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_FAIL:
        return this.createLeadFail(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD:
        return this.updateLead(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_DONE:
        return this.updateLeadDone(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_FAIL:
        return this.updateLeadFail(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_AVATAR:
        return this.updateLeadAvatar(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_AVATAR_DONE:
        return this.updateLeadAvatarDone(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_AVATAR_FAIL:
        return this.updateLeadAvatarFail(state, action);

      case LeadDrawerActionTypes.REMOVE_LEAD_AVATAR:
        return this.removeLeadAvatar(state, action);

      case LeadDrawerActionTypes.REMOVE_LEAD_AVATAR_DONE:
        return this.removeLeadAvatarDone(state, action);

      case LeadDrawerActionTypes.REMOVE_LEAD_AVATAR_FAIL:
        return this.removeLeadAvatarFail(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_ROLE:
        return this.updateLeadRole(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_ROLE_DONE:
        return this.updateLeadRoleDone(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_ROLE_FAIL:
        return this.updateLeadRoleFail(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_APPOINTMENT_DONE:
        return this.createLeadAppointmentDone(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_APPOINTMENT_FAIL:
        return this.createLeadAppointmentFail(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_SMS_MESSAGE_DONE:
        return this.createLeadSmsMessageDone(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_SMS_MESSAGE_FAIL:
        return this.createLeadSmsMessageFail(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_SMS_MESSAGE_DONE:
        return this.updateLeadSmsMessageDone(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_SMS_MESSAGE_FAIL:
        return this.updateLeadSmsMessageFail(state, action);

      case LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_DONE:
        return this.cancelScheduledLeadSmsMessageDone(state, action);

      case LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_FAIL:
        return this.cancelScheduledLeadSmsMessageFail(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_EMAIL_DONE:
        return this.createLeadEmailDone(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_EMAIL_FAIL:
        return this.createLeadEmailFail(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_EMAIL_DONE:
        return this.updateLeadEmailDone(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_EMAIL_FAIL:
        return this.updateLeadEmailFail(state, action);

      case LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_EMAIL_DONE:
        return this.cancelScheduledLeadEmailDone(state, action);

      case LeadDrawerActionTypes.CANCEL_SCHEDULED_LEAD_EMAIL_FAIL:
        return this.cancelScheduledLeadEmailFail(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_ACTIVITY_DONE:
        return this.createLeadActivityDone(state, action);

      case LeadDrawerActionTypes.CREATE_LEAD_ACTIVITY_FAIL:
        return this.createLeadActivityFail(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_ACTIVITY_DONE:
        return this.updateLeadActivityDone(state, action);

      case LeadDrawerActionTypes.UPDATE_LEAD_ACTIVITY_FAIL:
        return this.updateLeadActivityFail(state, action);

      case LeadDrawerActionTypes.REASSIGN_LEAD:
        return this.reassignLead(state, action);

      case LeadDrawerActionTypes.REASSIGN_LEAD_DONE:
        return this.reassignLeadDone(state, action);

      case LeadDrawerActionTypes.REASSIGN_LEAD_FAIL:
        return this.reassignLeadFail(state, action);

      case LeadDrawerActionTypes.UNLOAD_LEAD:
        return this.unloadLead(state, action);

      case LeadDrawerActionTypes.CREATE_RESUME:
        return this.createResume(state, action);

      case LeadDrawerActionTypes.CREATE_RESUME_DONE:
        return this.createResumeDone(state, action);

      case LeadDrawerActionTypes.CREATE_RESUME_FAIL:
        return this.createResumeFail(state, action);

      case LeadDrawerActionTypes.DELETE_RESUME:
        return this.deleteResume(state, action);

      case LeadDrawerActionTypes.DELETE_RESUME_DONE:
        return this.deleteResumeDone(state, action);

      case LeadDrawerActionTypes.DELETE_RESUME_FAIL:
        return this.deleteResumeFail(state, action);

      case LeadDrawerActionTypes.UPDATE_RESUME:
        return this.updateResume(state, action);

      case LeadDrawerActionTypes.UPDATE_RESUME_DONE:
        return this.updateResumeDone(state, action);

      case LeadDrawerActionTypes.UPDATE_RESUME_FAIL:
        return this.updateResumeFail(state, action);

      default:
        return state;
    }
  }
}

export default new LeadDrawerStore();
