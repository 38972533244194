const OPEN_EDIT_FORM = 'OPEN_EDIT_FORM';
const CLOSE_EDIT_FORM = 'CLOSE_EDIT_FORM';

const initialState = {
  isFormOpen: false,
};

const editFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EDIT_FORM:
      return {
        ...state,
        isFormOpen: true,
      };
    case CLOSE_EDIT_FORM:
      return {
        ...state,
        isFormOpen: false,
      };
    default:
      return state;
  }
};

export const openEditForm = () => ({ type: OPEN_EDIT_FORM });
export const closeEditForm = () => ({ type: CLOSE_EDIT_FORM });

export default editFormReducer;
