import React, { useCallback }        from 'react';
import Stats                         from './stats';
import MultipleListingServicesSelect from '~/components/forms/multiple_listing_services_select';
import brokerbit from '~/lib/brokerbit';

const SettingsStep = ({
  labels,
  multiple_listing_service_ouid,
  handleLabelsChange,
  handleMultipleListingServiceChange,
  step,
  nextStep,
  ...props
}) => {
  const validate = useCallback(() => {
    if (multiple_listing_service_ouid) {
      nextStep();
    } else {
      brokerbit.confirmBox({
        message:  'Please confirm proceeding with no MLS set',
        callback: (ok) => {
          if (ok) nextStep();
        },
      });
    }
  }, [multiple_listing_service_ouid, nextStep]);

  return (
    <div>
      <div className="form-group">
        <label htmlFor="lables">Comma Separated Labels to add to leads</label>
        <input
          type="text"
          id="labels"
          value={labels}
          onChange={handleLabelsChange}
          className="form-control"
        />
      </div>

      <div className="form-group" id="mls-select">
        <label htmlFor="mls">MLS that the data is for</label>
        <MultipleListingServicesSelect
          id="mls"
          value={multiple_listing_service_ouid}
          placeholder="--- Select a MLS ---"
          onChange={(val) => handleMultipleListingServiceChange(val)}
          teamMlses
          primaryMls
          clearable
          target="mls-select"
        />
      </div>

      <Stats step={step} nextStep={validate} {...props} />
    </div>
  );
};

export default SettingsStep;
