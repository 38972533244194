import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import TeamMembersActions from '~/actions/team_members_actions';
import TeamMembersStore   from '~/stores/team_members_store';
import TeamMembersTab     from './team_members_tab';
import TeamMembersSearch  from './team_members_search';
import TeamMembersTable   from './team_members_table';

class TeamMembersCore extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const tabs = props.tabs || ['admins', 'staff', 'agents', 'affiliates'];
    this.state = {
      selectedTab: props.selectedTab || tabs[0],
      searchQuery: '',
      currentPage: 1,
      tabs,
    };
  }

  getChildContext() {
    const { mode } = this.props;
    const { selectedTab, searchQuery, currentPage } = this.state;

    return {
      selectedTab,
      searchQuery,
      currentPage,
      mode,
    };
  }

  componentDidMount() {
    const { per, filter } = this.props;

    this.teamMembersStoreListener = TeamMembersStore.addListener(this.onTeamMembersStoreChange);

    TeamMembersActions.loadTeamMembers(this.state.selectedTab, { page: 1, per, filter });
  }

  componentWillUnmount() {
    if (this.teamMembersStoreListener) this.teamMembersStoreListener.remove();
  }

  onTeamMembersStoreChange() {
    const teamMembersState = TeamMembersStore.getState();

    this.props.onTeamMembersStoreChange(teamMembersState);

    this.setState({
      teamMembersState,
    });
  }

  handleTabSwitch(role) {
    const { per, filter } = this.props;

    TeamMembersActions.loadTeamMembers(role, {
      q: this.state.searchQuery, page: 1, per, filter,
    });

    this.setState({
      selectedTab: role,
      currentPage: 1,
    });

    if (this.props.handleTabSwitch) this.props.handleTabSwitch(role);
  }

  handleSearch(query) {
    const { per, filter } = this.props;

    this.setState({
      searchQuery: query,
      selectedTab: query.length > 0 ? 'members' : this.state.selectedTab,
      currentPage: 1,
    });

    TeamMembersActions.loadTeamMembers(this.state.selectedTab, {
      q: query, page: 1, per, filter,
    });
  }

  clearSearch() {
    const { per, filter } = this.props;

    this.setState({
      searchQuery: '',
      currentPage: 1,
    });

    TeamMembersActions.loadTeamMembers(this.state.selectedTab, { page: 1, per, filter });
  }

  handlePageChange(page) {
    const { per, filter } = this.props;
    const { selectedTab, searchQuery } = this.state;

    this.setState({
      currentPage: page + 1,
    });

    TeamMembersActions.loadTeamMembers(selectedTab, {
      q: searchQuery, page: page + 1, per, filter,
    });
  }

  render() {
    const { currentTeam } = Rails.helpers;
    const { title, notify, drawerExternalRolesOnly } = this.props;
    const {
      selectedTab, searchQuery, currentPage, teamMembersState, tabs,
    } = this.state;

    return (
      <div className="card">
        <div className="card-block">
          <h4 className="mb15 clearfix">
            {title}

            <div className="pull-right">
              <TeamMembersSearch
                memberRole={selectedTab}
                searchQuery={searchQuery}
                currentPage={currentPage}
                handleSearch={this.handleSearch}
                notify={notify}
                drawerExternalRolesOnly={drawerExternalRolesOnly}
              />
            </div>
          </h4>
          <TeamMembersTab
            tabs={tabs}
            memberRole={selectedTab}
            handleTabSwitch={this.handleTabSwitch}
          />
        </div>
        <TeamMembersTable
          memberRole={selectedTab}
          {...teamMembersState}
          handlePageChange={this.handlePageChange}
          notify={notify}
          searchQuery={searchQuery}
          clearSearch={this.clearSearch}
          drawerExternalRolesOnly={drawerExternalRolesOnly}
        />
      </div>
    );
  }
}

TeamMembersCore.childContextTypes = {
  selectedTab: PropTypes.string,
  searchQuery: PropTypes.string,
  currentPage: PropTypes.number,
  mode:        PropTypes.string,
};

TeamMembersCore.defaultProps = {
  title:                    'Manage Team',
  sidebar:                  true,
  per:                      10,
  filter:                   [],
  notify:                   true,
  drawerExternalRolesOnly:  false,
  handleTabSwitch:          () => false,
  onTeamMembersStoreChange: () => false,
  mode:                     'default',
};

TeamMembersCore.propTypes = {
  title:                    PropTypes.string,
  tabs:                     PropTypes.arrayOf(PropTypes.string),
  selectedTab:              PropTypes.string,
  sidebar:                  PropTypes.bool,
  per:                      PropTypes.number,
  filter:                   PropTypes.arrayOf(PropTypes.string),
  mode:                     PropTypes.string,
  notify:                   PropTypes.bool,
  drawerExternalRolesOnly:  PropTypes.bool,
  handleTabSwitch:          PropTypes.func,
  onTeamMembersStoreChange: PropTypes.func,
};

export default TeamMembersCore;
