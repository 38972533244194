import tokenizer from 'gpt-tokenizer';
import { ModalStates } from '../states/ModalStates';

export const initialModalState = {
  isLoading:         false,
  inputText:         '',
  outputText:        '',
  conversationId:    null,
  status:            ModalStates.INITIAL,
  feedbackState:     null,
  feedbackText:      '',
  messages:          [],
  error:             null,
  inputError:        null,
  tokensCount:       0,
  subscriptionLimit: 0,
  totalTokensUsed:   0,
};

export const modalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'APPEND_MESSAGE':
      return { ...state, messages: [...state.messages, action.payload] };
    case 'SET_INPUT_TEXT': {
      let tokensCount = 0;
      const newMessage = action.payload ? [{ role: 'user', content: action.payload }] : [];
      const messages = [...state.messages, ...newMessage];
      if (messages.length > 0) {
        const tokens = tokenizer.encodeChat(messages, process.env.GPT_MODEL);
        tokensCount = tokens?.length || 0;
      }

      return {
        ...state,
        inputText:       action.payload,
        totalTokensUsed: state.totalTokensUsed - state.tokensCount + tokensCount,
        tokensCount,
      };
    }
    case 'SET_OUTPUT_TEXT': {
      const newMessage = [{ role: 'assistant', content: action.payload }];
      const messages = [...state.messages, ...newMessage];
      const tokens = tokenizer.encodeChat(messages, process.env.GPT_MODEL);
      const tokensCount = tokens?.length || 0;

      return {
        ...state,
        outputText:      action.payload,
        totalTokensUsed: state.totalTokensUsed - state.tokensCount + tokensCount,
        tokensCount,
      };
    }
    case 'SET_CONVERSATION_ID':
      return { ...state, conversationId: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_INPUT_ERROR':
      return { ...state, inputError: action.payload };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_FEEDBACK_STATE':
      return { ...state, feedbackState: action.payload };
    case 'SET_FEEDBACK_TEXT':
      return { ...state, feedbackText: action.payload };
    case 'SET_FEEDBACK_SENDING':
      return { ...state, feedbackSending: action.payload };
    case 'SET_SUBSCRIPTION_LIMIT':
      return { ...state, subscriptionLimit: action.payload };
    case 'SET_TOTAL_TOKENS_USED':
      return { ...state, totalTokensUsed: action.payload };
    case 'RESET_STATE':
      return {
        ...initialModalState,
        subscriptionLimit: state.subscriptionLimit,
        totalTokensUsed:   state.totalTokensUsed,
      };
    case 'REVISE_PROMPT': {
      const newMessage = [{ role: 'user', content: state.inputText }];
      const messages = [...state.messages, ...newMessage];
      const tokens = tokenizer.encodeChat(messages, process.env.GPT_MODEL);
      const tokensCount = tokens?.length || 0;

      return {
        ...state,
        status:          ModalStates.INITIAL,
        outputText:      '',
        error:           null,
        inputError:      null,
        feedbackState:   null,
        feedbackText:    '',
        feedbackSending: false,
        tokensCount,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
