import React from 'react';

export const FullScreenIcon = () => (
  <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" overflow="visible">
    <g id="Page-1" stroke="none" vectorEffect="1" fill="none" fillRule="evenodd">
      <g id="fullscreen" fill="#FFFFFF" vectorEffect="non-scaling-stroke">
        <polygon id="Path4" points="18 17 14 17 14 20 21 20 21 13 18 13" />
        <polygon id="Path3" fillRule="nonzero" points="3 13 0 13 0 20 7 20 7 17 3 17" />
        <polygon id="Path2" points="14 0 14 3 18 3 18 7 21 7 21 0" />
        <polygon id="Path1" points="0 7 3 7 3 3 7 3 7 0 0 0" />
      </g>
    </g>
  </svg>
);
