import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import EmailForm from '~/components/forms/email_form';

class StepEmailForm extends React.Component {
  constructor(props) {
    super(props);
    const { step } = props;

    this.state = {
      email: {
        from:           step.from || Rails.helpers.currentUser.email,
        subject:        step.subject || '',
        body:           step.body || '',
        cc_emails:      step.cc_emails || '',
        attachments:    step.attachments || [],
        attachment_ids: step.attachment_ids || [],
      },
    };

    autoBind(this);
  }

  assignFormState(emailState) {
    const { onChange } = this.props;
    const { email } = this.state;

    this.setState({
      email: Object.assign(email, emailState),
    });

    onChange(emailState);
  }

  validate() {
    this.emailForm.validate();
  }

  render() {
    const { email } = this.state;
    const { type } = this.props;

    return (
      <EmailForm
        ref={(el) => {
          this.emailForm = el;
        }}
        from={email.from}
        subject={email.subject}
        body={email.body}
        attachments={email.attachments}
        enableFromField={type === 'Campaign'}
        onChange={this.assignFormState}
        contactOwner
      />
    );
  }
}

StepEmailForm.defaultProps = {
  step:     {},
  onChange: () => false,
};

StepEmailForm.propTypes = {
  step:     PropTypes.shape({}),
  onChange: PropTypes.func,
  type:     PropTypes.string.isRequired,
};

export default StepEmailForm;
