import { ReduceStore }    from 'flux/utils';
import AppDispatcher      from '~/dispatchers/app_dispatcher';
import AccountActionTypes from '~/types/account_action_types';

class AccountStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loading:                false,
      updating:               false,
      user:                   null,
      team:                   null,
      landingPage:            null,
      lastAccountStoreAction: null,
      errors:                 {},
    };
  }

  updateAccount(state, _action) {
    const newState = { ...state };

    newState.updating = true;
    newState.user = null;
    newState.lastAccountStoreAction = 'updateAccount';

    return newState;
  }

  updateAccountDone(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.user = action.response;
    newState.errors = {};
    newState.lastAccountStoreAction = 'updateAccountDone';

    return newState;
  }

  updateAccountFail(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.errors = action.error.response?.body?.errors;
    newState.lastAccountStoreAction = 'updateAccountFail';

    return newState;
  }

  updateTeam(state, action) {
    const newState = { ...state };

    newState.updating = true;
    newState.team = action.team;
    newState.lastAccountStoreAction = 'updateTeam';

    return newState;
  }

  updateTeamDone(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.team = action.response;
    newState.lastAccountStoreAction = 'updateTeamDone';

    return newState;
  }

  updateTeamFail(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.lastAccountStoreAction = 'updateTeamFail';

    return newState;
  }

  updateLandingPage(state, action) {
    const newState = { ...state };

    newState.updating = true;
    newState.landingPage = action.landingPage;
    newState.lastAccountStoreAction = 'updateLandingPage';

    return newState;
  }

  updateLandingPageDone(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.landingPage = action.response;
    newState.lastAccountStoreAction = 'updateLandingPageDone';

    return newState;
  }

  updateLandingPageFail(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.errors = action.error.response.body;
    newState.lastAccountStoreAction = 'updateLandingPageFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case AccountActionTypes.UPDATE_ACCOUNT:
        return this.updateAccount(state, action);

      case AccountActionTypes.UPDATE_ACCOUNT_DONE:
        return this.updateAccountDone(state, action);

      case AccountActionTypes.UPDATE_ACCOUNT_FAIL:
        return this.updateAccountFail(state, action);

      case AccountActionTypes.UPDATE_TEAM:
        return this.updateTeam(state, action);

      case AccountActionTypes.UPDATE_TEAM_DONE:
        return this.updateTeamDone(state, action);

      case AccountActionTypes.UPDATE_TEAM_FAIL:
        return this.updateTeamFail(state, action);

      case AccountActionTypes.UPDATE_LANDING_PAGE:
        return this.updateLandingPage(state, action);

      case AccountActionTypes.UPDATE_LANDING_PAGE_DONE:
        return this.updateLandingPageDone(state, action);

      case AccountActionTypes.UPDATE_LANDING_PAGE_FAIL:
        return this.updateLandingPageFail(state, action);

      default:
        return state;
    }
  }
}

export default new AccountStore();
