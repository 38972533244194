import React from 'react';
import { PropTypes } from 'prop-types';
import Select from './NewSelect';
import TimeZones from '~/lib/time_zones';

const TimezoneSelect = ({
  id,
  name,
  value = '',
  placeholder = 'Select a Time Zone',
  onChange,
  isInvalid,
  isClearable,
  isSearchable = true,
}) => {
  let { options } = TimeZones;

  const valueExists = options.some((option) => option.value === value);

  if (!valueExists && value) {
    const label = Moment.tz(value).zoneAbbr();
    options = [...options, { label, value }];
  }

  return (
    <Select
      id={id}
      name={name}
      options={options}
      isInvalid={isInvalid}
      onChange={onChange}
      value={value}
      isClearable={isClearable}
      placeholder={placeholder}
      isSearchable={isSearchable}
    />
  );
};

TimezoneSelect.defaultProps = {
  id:           '',
  name:         '',
  placeholder:  'Select a Time Zone',
  onChange:     () => {},
  value:        '',
  isInvalid:    false,
  isClearable:  false,
  isSearchable: true,
};

TimezoneSelect.propTypes = {
  id:          PropTypes.string,
  name:        PropTypes.string,
  placeholder: PropTypes.string,
  onChange:    PropTypes.func,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  isInvalid:    PropTypes.bool,
  isClearable:  PropTypes.bool,
  isSearchable: PropTypes.bool,
};

export default TimezoneSelect;
