const LeadSourceActionTypes = {
  LOAD_LEAD_SOURCES:      'LOAD_LEAD_SOURCES',
  LOAD_LEAD_SOURCES_DONE: 'LOAD_LEAD_SOURCES_DONE',
  LOAD_LEAD_SOURCES_FAIL: 'LOAD_LEAD_SOURCES_FAIL',

  LOAD_LEAD_SOURCE:      'LOAD_LEAD_SOURCE',
  LOAD_LEAD_SOURCE_DONE: 'LOAD_LEAD_SOURCE_DONE',
  LOAD_LEAD_SOURCE_FAIL: 'LOAD_LEAD_SOURCE_FAIL',

  CREATE_LEAD_SOURCE:      'CREATE_LEAD_SOURCE',
  CREATE_LEAD_SOURCE_DONE: 'CREATE_LEAD_SOURCE_DONE',
  CREATE_LEAD_SOURCE_FAIL: 'CREATE_LEAD_SOURCE_FAIL',

  UPDATE_LEAD_SOURCE:      'UPDATE_LEAD_SOURCE',
  UPDATE_LEAD_SOURCE_DONE: 'UPDATE_LEAD_SOURCE_DONE',
  UPDATE_LEAD_SOURCE_FAIL: 'UPDATE_LEAD_SOURCE_FAIL',

  DELETE_LEAD_SOURCE:      'DELETE_LEAD_SOURCE',
  DELETE_LEAD_SOURCE_DONE: 'DELETE_LEAD_SOURCE_DONE',
  DELETE_LEAD_SOURCE_FAIL: 'DELETE_LEAD_SOURCE_FAIL',
};

export default LeadSourceActionTypes;
