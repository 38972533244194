import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { onNotificationUpdates } from '~/graphql/subscriptions';
import { countUnreadNotifications } from '~/graphql/queries';

const useUnreadNotificationsCount = () => {
  const { currentUser, currentTeam } = Rails.helpers;
  const UserID = currentUser?.id;
  const TeamID = currentTeam?.id;
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  useEffect(() => {
    const fetchUnreadNotificationsCount = async () => {
      const client = generateClient();
      const result = await client.graphql({
        query:     countUnreadNotifications,
        variables: { UserID, TeamID },
      });

      if (result.data && result.data.countUnreadNotifications !== null) {
        setUnreadNotifications(result.data.countUnreadNotifications);
      }
    };

    fetchUnreadNotificationsCount();
  }, [UserID, TeamID]);

  useEffect(() => {
    const client = generateClient();
    const subscription = client.graphql({
      query:     onNotificationUpdates,
      variables: { UserID, TeamID },
    }).subscribe({
      next: ({ data }) => {
        const notification = data.onNotificationUpdates;
        if (!notification) return;

        setUnreadNotifications((prev) => {
          if (!notification.IsRead) return prev + 1;
          if (notification.IsRead && prev > 0) return prev - 1;
          return prev;
        });
      },
    });

    return () => subscription.unsubscribe();
  }, [UserID, TeamID]);

  return [unreadNotifications, setUnreadNotifications];
};

export default useUnreadNotificationsCount;
