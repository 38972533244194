import React                   from 'react';
import { useState, useEffect } from 'react';
import { useParams }           from 'react-router-dom';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import DocumentComponentMap    from '~/constants/DocumentComponentMap';
import DocumentActions         from '~/actions/document_actions'
import DocumentStore           from '~/stores/document_store'
import brokerbit               from '~/lib/brokerbit';
import APIRequest              from '~/lib/api_request';
import BlankPage               from '~/components/pages/blank_page';
import Tooltipable             from '~/components/effects/tooltipable';
import { formatedFileSize }    from '~/helpers/document_helpers.jsx'
import { checkFeatureFlag }    from '~/helpers/FeatureFlagChecker';
import { CopyToClipboard }     from 'react-copy-to-clipboard';

const Preview = () => {
  const isDmsEnabled = checkFeatureFlag(process.env.ENABLE_DOCUMENT_MANAGEMENT_FLAG);

  if (!isDmsEnabled) return 'Document management is disabled';

  const { id: documentId } = useParams();
  const [state, setState] = useState({
    loadingDocument: false,
    document: null,
    error: false,
  });
  const { helpers } = Rails;
  const { currentUser } = helpers;
  const isAdminOrStaff = currentUser?.role.name === 'admin' || currentUser?.role.name === 'staff';
  const [sharableTooltipText, setSharableTooltipText] = useState('Copy internal link to clipboard (requires Brokerkit access)');
  const [shareablePublicTooltipText, setShareablePublicTooltipText] = useState('Copy public link to clipboard (no Brokerkit login required)');
  const [sharablePublicDocLink, setSharablePublicDocLink] = useState('');

  useEffect(() => {
    const documentStoreListener = DocumentStore.addListener(onDocumentStoreChange);

    if (documentId) {
      DocumentActions.loadDocument(documentId);
      fetchAndSetPublicDocumentLink(documentId);
    }

    return () => {
      if (documentStoreListener) documentStoreListener.remove();
    };

  }, [documentId]);

  const onDocumentStoreChange = () => {
    const {
      loadingDocument,
      document,
      lastDocumentStoreAction,
    } = DocumentStore.getState();

    let nextState = { loadingDocument, document };

    switch (lastDocumentStoreAction) {
      case 'loadDocumentFail':
        nextState = { loadingDocument, error: true };
        break;
      case 'deleteDocumentDone':
        window.location.href = '/recruiter/documents';
        break;
      default:
        break;
    }

    setState(nextState);
  }

  const handlePrintClick = () => {
    const { document } = state;
    switch (document.document_type) {
      case 'image_document':
        handlePrintImage(document.url);
        break;
      case 'pdf_document':
      case 'pdf_resume':
        handlePrintPdf();
        break;
      default:
        console.log('Document type not supported for printing');
    }
  }

  const handlePrintImage = (url) => {
    const printWindow = window.open('', '_blank');

    printWindow.onafterprint = function() {
      printWindow.close();
    };

    printWindow.document.write(`
      <html>
        <head>
          <title>Print Image</title>
          <style>
            body, html {
              margin: 0;
              padding: 0;
              text-align: center;
            }
            img {
              max-width: 100%;
              height: auto;
            }
          </style>
        </head>
        <body>
          <img src="${url}" onload="window.print();" />
        </body>
      </html>
    `);

    printWindow.document.close();
  }

  const handlePrintPdf = () => {
    const printWin = window.open(document.public_url, '', 'width=800,height=600');
    printWin.focus();
    printWin.print();
  }

  const handleChangeInternalClick = () => {
    const { document } = state;
    const { internal } = document;

    DocumentActions.updateDocument({
      ...document,
      internal: !internal,
    });
  }

  const fetchAndSetPublicDocumentLink = async (id) => {
    try {
      const res = await APIRequest.get({ resource: '/v1/auth/token/document' }).then();
      if (res.body.token) {
        const url = `${window.location.origin}/d/${id}/?auth=${res.body.token}`;
        setSharablePublicDocLink(url);
      } else {
        GlobalContainer.error(
          'Unable to authenticate for document, please contact support',
          'error',
        );
      }
    } catch (err) {
      console.error('Failed to get the link: ', err);
    }
  }

  const handleDeleteClick = () => {
    const { document } = state;

    brokerbit.confirmBox({
      message: 'Are you sure you want to delete this document?',
      callback: (ok) => {
        if (ok) {
          DocumentActions.deleteDocument(document);
        }
      },
    });
  }

  const handleDownloadClick = async () => {
    try {
      const downloadUrl = `${Rails.apiUrl}/api/v1/documents/${documentId}/download?auth=${
        Rails.helpers.authToken
        }`;

      const link = window.document.createElement('a');
      link.href = downloadUrl;
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const renderDocumentViewer = () => {
    const { document } = state;

    if (!document) return null;

    const DocumentComponent = DocumentComponentMap[document.document_type]?.Component;

    if (!DocumentComponent) return <div className='text-center'>Document type not supported</div>;

    let style = {};
    if (document.document_type.includes('pdf')) {
      style = { minHeight: '845px' };
    }

    return (
      <div className="row my-3 mx-5" style={style}>
        <div className="col">
          <DocumentComponent document={document} />
        </div>
      </div>
    );
  };

  const renderDocumentActions = () => {
    const { document } = state;
    if (!document) return null;
    const showPrintButton = ['image_document', 'pdf_document', 'pdf_resume'].includes(document.document_type);

    return (
      <div className="row justify-content-end mr-5 ">
        <div className="col-auto d-flex mb-1">
          {isAdminOrStaff && (
            <>
              <Tooltipable text={sharableTooltipText}>
                <CopyToClipboard text={window.location.href} onCopy={() => setSharableTooltipText('Copied!')}>
                  <div className="mr-3" onMouseLeave={() => setSharableTooltipText('Copy internal link to clipboard (requires Brokerkit access)')}>
                    <button className="btn btn-secondary">
                      <FontAwesomeIcon icon="far fa-external-link-alt" /> Share Internal Link
                    </button>
                  </div>
                </CopyToClipboard>
              </Tooltipable>

              <Tooltipable text={shareablePublicTooltipText}>
                <CopyToClipboard text={sharablePublicDocLink} onCopy={() => setShareablePublicTooltipText('Copied!')}>
                  <div className="mr-3" onMouseLeave={() => setShareablePublicTooltipText('Copy public link to clipboard (no Brokerkit login required)')}>
                    <button className="btn btn-secondary">
                      <FontAwesomeIcon icon="far fa-external-link-alt" /> Share Public Link
                    </button>
                  </div>
                </CopyToClipboard>
              </Tooltipable>
            </>
          )}
          {showPrintButton && (
            <Tooltipable text="Print">
              <div className="mr-3">
                <button onClick={handlePrintClick} className="btn btn-secondary">
                  <FontAwesomeIcon icon="far fa-print" />
                </button>
              </div>
            </Tooltipable>
          )}
          <Tooltipable text="Download">
            <div className="mr-3">
              <button onClick={handleDownloadClick} className="btn btn-secondary">
                <FontAwesomeIcon icon="far fa-download" />
              </button>
            </div>
          </Tooltipable>
          {isAdminOrStaff && (
            <>
              <div className="mr-3">
                {
                  document.internal ? (
                    <Tooltipable text="Private: viewable to staff only">
                      <button onClick={handleChangeInternalClick} className="btn btn-secondary">
                        <FontAwesomeIcon icon="far fa-eye-slash" />
                      </button>
                    </Tooltipable>
                  ) : (
                    <Tooltipable text="Public: viewable to all">
                      <button onClick={handleChangeInternalClick} className="btn btn-secondary">
                        <FontAwesomeIcon icon="far fa-eye" />
                      </button>
                    </Tooltipable>
                  )
                }
              </div>
              <div className="mr-3">
                <Tooltipable text="Delete">
                  <button onClick={handleDeleteClick} className="btn btn-secondary">
                    <FontAwesomeIcon icon="far fa-trash-alt" />
                  </button>
                </Tooltipable>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  const { loadingDocument, document, error } = state;
  const { title, size } = document || {};
  const TopBarStyle = {
    position: 'sticky',
    zIndex: '999',
    top: '0',
    marginTop: '-30px',
  }

  return (
    <BlankPage showHeader={false}>
      <div className="content-container" style={{maxWidth: '98%'}}>
        <div className="row mb-3 bg-white bb p5" style={TopBarStyle}>
          <div className="col">
            <div className="ml-5">
              <h2>File Preview</h2>
            </div>
          </div>
        </div>

        {error && (
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-11 m-auto">
              <div className="text-center">
                <h3>Sorry, but this document is no longer available.</h3>
                <br />
                <FontAwesomeIcon icon="far fa-document-slash" size="2x"/>
              </div>
            </div>
          </div>
        )}

        {loadingDocument && (
          <div className="text-center">
            <FontAwesomeIcon icon="far fa-spinner" pulse size='2x' />
          </div>
        )}

        {document && (
          <>
            {renderDocumentActions()}
            {renderDocumentViewer()}
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-11 m-auto">
                <div className="text-center">
                  <b>{title}</b>
                  <br/>
                  <span>{formatedFileSize(size)}</span>
                </div>

              </div>
            </div>
          </>
        )}
      </div>
    </BlankPage>
  );
};

export default Preview;
