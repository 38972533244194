import AppDispatcher               from '~/dispatchers/app_dispatcher';
import APIRequest                  from '~/lib/api_request';
import BusinessProfilesActionTypes from '~/types/business_profiles_action_types';

let createProfileRequest;
let updateProfileRequest;

const BusinnessProfileActions = {
  createProfile(profile, tabName = null) {
    APIRequest.abort(createProfileRequest);

    AppDispatcher.dispatch({
      type: BusinessProfilesActionTypes.CREATE_BUSINESS_PROFILE,
      profile,
    });

    createProfileRequest = APIRequest.post({
      resource: '/v1/business_profile',
      data:     { profile },
    });

    createProfileRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: BusinessProfilesActionTypes.CREATE_BUSINESS_PROFILE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    BusinessProfilesActionTypes.CREATE_BUSINESS_PROFILE_DONE,
        profile: response.body,
        tabName
      });
    });
  },

  updateProfile(profile, tabName = null) {
    APIRequest.abort(updateProfileRequest);

    AppDispatcher.dispatch({
      type: BusinessProfilesActionTypes.UPDATE_BUSINESS_PROFILE,
      profile,
    });

    updateProfileRequest = APIRequest.put({
      resource: '/v1/business_profile',
      data:     { profile },
    });

    updateProfileRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: BusinessProfilesActionTypes.UPDATE_BUSINESS_PROFILE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    BusinessProfilesActionTypes.UPDATE_BUSINESS_PROFILE_DONE,
        profile: response.body,
        tabName
      });
    });
  },
};

export default BusinnessProfileActions;
