import React from 'react';
import { PropTypes } from 'prop-types';
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import APIRequest from '~/lib/api_request';
import TeamEmailPicker from '~/components/forms/team_fields/team_email_picker';
import TeamPhoneNumberPicker from '~/components/forms/team_fields/team_phone_number_picker';
import { TeammatePicker } from '~/components/forms/team_member_picker';
import VerifiedTextingWarning from '~/components/warnings/VerifiedTextingWarning';
import OwnerContactWarning from '~/components/warnings/OwnerContactWarning';
import AppModal from './app_modal';
import A2pNotApprovedWarning from '~/components/warnings/A2pNotApprovedWarning';

class CopyCampaignLibraryModal extends React.Component {
  isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

  constructor(props) {
    super(props);

    const { currentUser } = Rails.helpers;
    const smsNumber = _lodash.first(currentUser.sms_numbers);
    const ownerID = _lodash.isInteger(currentUser.id) ? currentUser.id.toString() : '';

    this.state = {
      submitting:                 false,
      email_address:              currentUser.role ? currentUser.email : null,
      phone_number:               smsNumber ? smsNumber.service_number : null,
      owner_id:                   ownerID,
      errors:                     {},
      showVerifiedTextingWarning: false,
      showA2PWarning:             false
    };

    autoBind(this);
  }

  handleEmailAddressSelect = (selectedItem) => {
    const emailAddress = selectedItem ? selectedItem.value : null;

    this.setState({
      email_address: emailAddress,
    });
  }

  handlePhoneNumberSelect = (selectedItem) => {
    this.setState((prevState) => {
      const phoneNumber = selectedItem?.value || null;

      if (phoneNumber === -1) {
        return {
          phone_number:               phoneNumber,
          showVerifiedTextingWarning: false,
          showA2PWarning:             false,
        };
      }

      const showWarning = phoneNumber ? !selectedItem.verified_texting : false;
      const showA2PWarning = phoneNumber ? (selectedItem.verified_texting && !selectedItem.a2p_approved) : false;

      return {
        phone_number:               phoneNumber,
        showVerifiedTextingWarning: showWarning,
        showA2PWarning
      };
    });
  }

  handleVerifiedTextingWarning = (verifiedTexting) => {
    this.setState({
      showVerifiedTextingWarning: verifiedTexting,
    });
  }

  handleA2PWarning = (a2pApproved) => {
    this.setState({
      showA2PWarning: a2pApproved,
    });
  }

  handleOwnerSelect = (selectedItem) => {
    const ownerId = selectedItem ? selectedItem.value : null;

    this.setState({
      owner_id: ownerId,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      email_address, phone_number, owner_id,
    } = this.state;
    const { campaign } = this.props;
    const $modal = $(this.appModal.modal);

    this.setState({ submitting: true }, () => {
      if (this.validate()) {
        APIRequest.put({
          resource: `/v1/campaign_libraries/${campaign.id}/copy`,
          data:     {
            campaign_id: campaign.id, email_address, phone_number, owner_id,
          },
        }).end((error) => {
          this.setState({ submitting: false }, () => {
            if (!error) {
              GlobalContainer.notify(`Campaign ${campaign.name} was successfully copied to your team account.`, 'success');

              $modal.modal('hide');
            }
          });
        });
      } else {
        this.setState({ submitting: false });
      }
    });
  }

  validate() {
    const errors = {};
    const {
      email_address, phone_number, owner_id, showVerifiedTextingWarning, showA2PWarning,
    } = this.state;

    if (!email_address) {
      errors.email_address = "Can't be empty";
    }

    if ((this.isRestrictionForUnverifiedNumbersEnabled && showVerifiedTextingWarning && showA2PWarning) && phone_number) {
      errors.phone_number = 'This number is not verified for texting. Please choose another number.';
    }

    if (!phone_number) {
      errors.phone_number = "Can't be empty";
    }

    if (!owner_id) {
      errors.owner_id = "Can't be empty";
    }

    this.setState({ errors });

    return _lodash.size(errors) === 0;
  }

  renderSmsAndEmailForm() {
    const {
      email_address, phone_number, owner_id, errors, showVerifiedTextingWarning, showA2PWarning,
    } = this.state;

    return (
      <>
        <div className="form-group mb15">
          {this.isRestrictionForUnverifiedNumbersEnabled && showVerifiedTextingWarning && <VerifiedTextingWarning />}
          {this.isRestrictionForUnverifiedNumbersEnabled && phone_number === -1 && <OwnerContactWarning />}
          {this.isRestrictionForUnverifiedNumbersEnabled && showA2PWarning && <A2pNotApprovedWarning />}

          <label htmlFor="text-picker" className="d-block">
            <b>All texts sent from:</b>
          </label>
          <TeamPhoneNumberPicker
            name="phone_number"
            value={phone_number}
            onChange={this.handlePhoneNumberSelect}
            handleVerifiedTextingWarning={this.handleVerifiedTextingWarning}
            handleA2PWarning={this.handleA2PWarning}
            className={errors.phone_number ? 'has-error' : ''}
            contactOwner
          />
        </div>

        <div className="form-group mb15">
          <label htmlFor="email-picker" className="d-block">
            <b>All emails sent from:</b>
          </label>
          <TeamEmailPicker
            name="email_address"
            value={email_address}
            onChange={this.handleEmailAddressSelect}
            className={errors.email_address ? 'has-error' : ''}
            contactOwner
          />
        </div>

        <div className="form-group mb15">
          <label htmlFor="teammate-picker" className="d-block">
            <b>All tasks owned by:</b>
          </label>
          <TeammatePicker
            name="owner_id"
            placeholder="Owner"
            value={owner_id}
            onChange={this.handleOwnerSelect}
            className={errors.owner_id ? 'has-error' : ''}
            contactOwner
          />
        </div>
      </>
    );
  }

  render() {
    const { submitting } = this.state;
    const { campaign } = this.props;

    return (
      <AppModal ref={(appModal) => this.appModal = appModal}>
        <form method="PUT" action={`/campaign_libraries/${campaign.id}/copy`} onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              <span className="text-grey-dark text-no-underline">Copy Campaign: </span>
              {campaign.name}
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {this.renderSmsAndEmailForm()}
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Copying ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Copy</button>
            )}
          </div>
        </form>
      </AppModal>
    );
  }
}

CopyCampaignLibraryModal.defaultProps = {};

CopyCampaignLibraryModal.propTypes = {
  campaign: PropTypes.shape({}).isRequired,
};

export default CopyCampaignLibraryModal;
