const BulkSelectActionTypes = {
  SELECT_ALL_ITEMS:    'SELECT_ALL_ITEMS',
  DESELECT_ALL_ITEMS:  'DESELECT_ALL_ITEMS',

  SELECT_LEAD_ITEMS:   'SELECT_LEAD_ITEMS',
  DESELECT_LEAD_ITEMS: 'DESELECT_LEAD_ITEMS',

  SELECT_LEAD_ITEM:    'SELECT_LEAD_ITEM',
  DESELECT_LEAD_ITEM:  'DESELECT_LEAD_ITEM',

  SELECT_DOCUMENT_ITEM:   'SELECT_DOCUMENT_ITEM',
  DESELECT_DOCUMENT_ITEM: 'DESELECT_DOCUMENT_ITEM',

  SELECT_DOCUMENT_ITEMS:   'SELECT_DOCUMENT_ITEMS',
  DESELECT_DOCUMENT_ITEMS: 'DESELECT_DOCUMENT_ITEMS',

  SELECT_TRASH_DOCUMENT_ITEM:   'SELECT_TRASH_DOCUMENT_ITEM',
  DESELECT_TRASH_DOCUMENT_ITEM: 'DESELECT_TRASH_DOCUMENT_ITEM',

  SELECT_TRASH_DOCUMENT_ITEMS:   'SELECT_TRASH_DOCUMENT_ITEMS',
  DESELECT_TRASH_DOCUMENT_ITEMS: 'DESELECT_TRASH_DOCUMENT_ITEMS',
};

export default BulkSelectActionTypes;
