import AppDispatcher                    from '~/dispatchers/app_dispatcher';
import APIRequest                       from '~/lib/api_request';
import TwilioProfileActions             from '~/actions/twilio_profile_actions';
import TwilioVerifiedTextingActionTypes from '~/types/twilio_verified_texting_action_types';

let loadVerifiedTextingRequest;
let createVerifiedTextingRequest;
let updateVerifiedTextingRequest;

const TwilioVerifiedTextingActions = {
  loadVerifiedTexting() {
    APIRequest.abort(loadVerifiedTextingRequest);

    AppDispatcher.dispatch({
      type: TwilioVerifiedTextingActionTypes.LOAD_TWILIO_VERIFIED_TEXTING,
    });

    loadVerifiedTextingRequest = APIRequest.get({
      resource: '/v1/twilio_verified_texting',
    });

    loadVerifiedTextingRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TwilioVerifiedTextingActionTypes.LOAD_TWILIO_VERIFIED_TEXTING_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:             TwilioVerifiedTextingActionTypes.LOAD_TWILIO_VERIFIED_TEXTING_DONE,
        verified_texting: response.body,
      });
    });
  },

  createVerifiedTexting(verified_texting, submitTwilioProfile) {
    APIRequest.abort(createVerifiedTextingRequest);

    AppDispatcher.dispatch({
      type: TwilioVerifiedTextingActionTypes.CREATE_TWILIO_VERIFIED_TEXTING,
      verified_texting,
    });

    createVerifiedTextingRequest = APIRequest.post({
      resource: '/v1/twilio_verified_texting',
      data:     { verified_texting },
    });

    createVerifiedTextingRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TwilioVerifiedTextingActionTypes.CREATE_TWILIO_VERIFIED_TEXTING_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:             TwilioVerifiedTextingActionTypes.CREATE_TWILIO_VERIFIED_TEXTING_DONE,
        verified_texting: response.body,
      });

      if (submitTwilioProfile) {
        TwilioProfileActions.submitTwilioProfile();
      }
    });
  },

  updateVerifiedTexting(verified_texting, submitTwilioProfile) {
    APIRequest.abort(updateVerifiedTextingRequest);

    AppDispatcher.dispatch({
      type: TwilioVerifiedTextingActionTypes.UPDATE_TWILIO_VERIFIED_TEXTING,
      verified_texting,
    });

    updateVerifiedTextingRequest = APIRequest.put({
      resource: '/v1/twilio_verified_texting',
      data:     { verified_texting },
    });

    updateVerifiedTextingRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TwilioVerifiedTextingActionTypes.UPDATE_TWILIO_VERIFIED_TEXTING_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:             TwilioVerifiedTextingActionTypes.UPDATE_TWILIO_VERIFIED_TEXTING_DONE,
        verified_texting: response.body,
      });

      if (submitTwilioProfile) {
        TwilioProfileActions.submitTwilioProfile();
      }
    });
  },
};

export default TwilioVerifiedTextingActions;
