import React               from 'react';
import autoBind            from 'react-autobind';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest from '~/lib/api_request';

class EmailStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:    true,
      emailStats: {},
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadEmailStats();
  }

  loadEmailStats() {
    APIRequest.get({ resource: '/v1/statistics/emails' })
      .end((err, response) => {
        if (response && response.ok) {
          const emailStats = response.body;

          this.setState({ emailStats, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
  }

  renderStatisticTable() {
    const { loading, emailStats } = this.state;

    if (loading) {
      return (
        <tbody className="text-center">
          <tr>
            <td>
              <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        <tr>
          <th scope="row">Today</th>
          <td className="text-center">{emailStats.today}</td>
        </tr>
        <tr>
          <th scope="row">This Week</th>
          <td className="text-center">{emailStats.thisWeek}</td>
        </tr>
        <tr>
          <th scope="row">This Month</th>
          <td className="text-center">{emailStats.thisMonth}</td>
        </tr>
        <tr>
          <th scope="row">Last Month</th>
          <td className="text-center">{emailStats.lastMonth}</td>
        </tr>
      </tbody>
    );
  }

  render() {
    return (
      <div className="table-responsive col-md-12 col-lg-6">
        <table className="table table-bordered statistic-table">
          <thead>
            <tr>
              <th colSpan="2" scope="col">Emails Sent</th>
            </tr>
          </thead>
          {this.renderStatisticTable()}
        </table>
      </div>
    );
  }
}

EmailStats.defaultProps = {};

EmailStats.propTypes = {};

export default EmailStats;
