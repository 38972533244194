import autoBind       from 'react-autobind';
import React          from 'react';
import ReactDOM       from 'react-dom';
import { PropTypes }  from 'prop-types';
import { withRouter } from 'react-router-dom';

import brokerbit           from '~/lib/brokerbit';
import EditLeadSourceModal from '~/components/modals/edit_lead_source_modal';
import LeadSourceItem      from './lead_source_item';

class LeadSourceTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleLeadSourceClick(source) {
    const { leadSources } = this.props;

    ReactDOM.render(
      <EditLeadSourceModal sourceID={source.id} leadSources={leadSources} />, document.getElementById('primary-modal'),
    );
  }

  handleLeadSourceDelete(source) {
    if (_lodash.isEmpty(source)) return;
    const { deleteSource } = this.props;

    brokerbit.confirmBox({
      message:  `Are you sure you want to permanently delete (${source.name})?`,
      callback: (ok) => {
        if (ok) deleteSource(source);
      },
    });
  }

  renderLeadSourceRows() {
    const { leadSources } = this.props;

    const sourceRows = [];
    leadSources.forEach((source, idx) => {
      sourceRows.push(
        <LeadSourceItem
          key={`source-${source.id}`}
          source={source}
          handleLeadSourceClick={this.handleLeadSourceClick}
          handleLeadSourceDelete={this.handleLeadSourceDelete}
        />,
      );
    });

    return sourceRows;
  }

  render() {
    return (
      <table className="table table-hover table-responsive">
        <tbody>{this.renderLeadSourceRows()}</tbody>
      </table>
    );
  }
}

LeadSourceTable.defaultProps = {
  loading:      false,
  leadSources:  [],
  deleteSource: () => false,
};

LeadSourceTable.propTypes = {
  history:      PropTypes.shape({}).isRequired,
  loading:      PropTypes.bool,
  leadSources:  PropTypes.arrayOf(PropTypes.shape({})),
  deleteSource: PropTypes.func,
};

export default withRouter(LeadSourceTable);
