import React, { useRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';

const IconInput = ({
  iconPrefix, icon, side, className, ...props
}) => {
  const classes = classNames(side === 'left' ? 'pl30' : '', className);
  const inputRef = useRef(null);

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={classNames('form-group has-feedback')}>
      <FontAwesomeIcon
        icon={[`${iconPrefix}`, `fa-${icon}`]}
        className={`form-control-feedback form-control-feedback-${side}`}
        onClick={handleIconClick}
      />
      <input
        ref={inputRef}
        className={classes}
        {...props}
      />
    </div>
  );
};

IconInput.defaultProps = {
  side:       'left',
  iconPrefix: 'far',
  className:  '',
};

IconInput.propTypes = {
  icon:       PropTypes.string.isRequired,
  iconPrefix: PropTypes.string,
  side:       PropTypes.string,
  className:  PropTypes.string,
};

export default IconInput;
