import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import update              from 'immutability-helper';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Link }            from 'react-router-dom';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GlobalContainer from '~/components/global_container';
import CampaignPicker from '~/components/forms/campaign_picker';
import AudienceSelect from '~/components/forms/audience_select';
import ActionSelect from '~/components/forms/action_select';
import StatementSelect from '~/components/forms/statement_select';
import CampaignTriggerActions from '~/actions/campaign_trigger_actions';
import CampaignTriggerStore from '~/stores/campaign_trigger_store';
import TriggerCondition from './trigger_condition_form/trigger_condition';
import TriggerConditionNew from './trigger_condition_form/trigger_condition_new';
import ErrorMessage from '~/components/forms/ErrorMessage';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import APIRequest from '~/lib/api_request';

class CampaignTriggerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting:               false,
      unverifiedNumbersWarning:  false,
      errors:                   {},
      conditions:               [],
      campaignTriggerForm:      {
        id:                            null,
        name:                          '',
        campaign_id:                   null,
        audience:                      'leads',
        action:                        'start',
        statement:                     'all',
        trigger_conditions_attributes: [],
      },
    };

    const { campaignTrigger } = this.props;

    if (campaignTrigger) {
      this.state.campaignTriggerForm = this.buildCampaignTriggerFormState(campaignTrigger);
      this.state.conditions = this.buildCampaignConditionComponents(campaignTrigger.trigger_conditions);
    }

    this.conditionInstances = [];

    autoBind(this);
  }

  componentDidMount() {
    const { campaignTrigger } = this.props;

    this.campaignTriggerStoreListener = CampaignTriggerStore.addListener(this.onCampaignTriggerStoreChange);

    if (!campaignTrigger) {
      this.addCondition();
    }
  }

  componentWillUnmount() {
    if (this.campaignTriggerStoreListener) this.campaignTriggerStoreListener.remove();
  }

  handleNameChange(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      campaignTriggerForm: {
        ...prevState.campaignTriggerForm,
        name: e.target.value,
      },
    }));
  }

  handleCampaignIdChange(option) {
    const campaignId = option ? option.value : null;

    this.setState((prevState) => ({
      campaignTriggerForm: {
        ...prevState.campaignTriggerForm,
        campaign_id: campaignId,
      },
    }), () => {
      this.validateSmsCampaignStepsWithUnverifiedNumbers(campaignId);
    });
  }

  handleAudienceChange(option) {
    const audience = option ? option.value : null;

    this.setState((prevState) => {
      const updatedCampaignTriggerForm = {
        ...prevState.campaignTriggerForm,
        audience,
      };

      return {
        campaignTriggerForm: updatedCampaignTriggerForm,
        conditions:          this.buildCampaignConditionComponents(prevState.campaignTriggerForm.trigger_conditions_attributes, audience),
      };
    });
  }

  handleActionChange(option) {
    const action = option ? option.value : null;

    this.setState((prevState) => ({
      campaignTriggerForm: {
        ...prevState.campaignTriggerForm,
        action,
      },
    }));
  }

  handleStatementChange(option) {
    const statement = option ? option.value : null;

    this.setState((prevState) => ({
      campaignTriggerForm: {
        ...prevState.campaignTriggerForm,
        statement,
      },
    }));
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { campaignTriggerForm } = this.state;

    this.saveCampaignTrigger(campaignTriggerForm);
  }

  onCampaignTriggerStoreChange() {
    const campaignTriggerStoreState = CampaignTriggerStore.getState();
    const {
      campaignTrigger, lastCampaignTriggerStoreAction, errors, submitting,
    } = campaignTriggerStoreState;

    let nextState = { campaignTriggerStoreState };

    switch (lastCampaignTriggerStoreAction) {
      case 'createCampaignTriggerDone':
        GlobalContainer.notify(`Campaign Trigger "${campaignTrigger.name}" created.`, 'success');
        this.redirectToCampaignTriggers();

        break;
      case 'createCampaignTriggerFail':
        nextState = { ...nextState, errors, submitting };

        break;
      case 'updateCampaignTriggerDone':
        if (campaignTrigger) {
          this.setState({
            conditions:          this.buildCampaignConditionComponents(campaignTrigger.trigger_conditions),
            campaignTriggerForm: this.buildCampaignTriggerFormState(campaignTrigger),
          });
        }

        GlobalContainer.notify(`Campaign Trigger "${campaignTrigger.name}" updated.`, 'success');
        this.redirectToCampaignTriggers();

        break;
      case 'updateCampaignTriggerFail':
        nextState = { ...nextState, errors, submitting };
        break;
      default:
      // do nothing
    }

    this.setState(nextState);
  }

  deleteCondition = (conditionIdx) => {
    const { campaignTriggerForm } = this.state;
    const deleteCondition = campaignTriggerForm.trigger_conditions_attributes[conditionIdx];

    deleteCondition._destroy = 1;

    const newState = update(this.state, {
      campaignTriggerForm: {
        trigger_conditions_attributes: {
          $splice: [[conditionIdx, 1, deleteCondition]],
        },
      },
    });

    newState.conditions = this.buildCampaignConditionComponents(newState.campaignTriggerForm.trigger_conditions_attributes);

    this.setState(newState);
  }

  addCondition = () => {
    const { campaignTriggerForm } = this.state;

    const newCondition = {
      id:       null,
      field:    null,
      operator: null,
      value:    '',
    };

    const newState = update(this.state, {
      conditions: {
        $push: [(
          <TriggerCondition
            ref={(el) => {
              this.addConditionInstance(el);
            }}
            key={uuidv4()}
            audience={campaignTriggerForm.audience}
            condition={newCondition}
            conditionIdx={this.state.conditions.length}
            updateCondition={this.updateCondition}
            deleteCondition={this.deleteCondition}
          />
        )],
      },
      campaignTriggerForm: {
        trigger_conditions_attributes: {
          $push: [newCondition],
        },
      },
    });

    newState.conditions = this.buildCampaignConditionComponents(newState.campaignTriggerForm.trigger_conditions_attributes);

    this.setState(newState);
  }

  updateCondition = (conditionIdx, conditionDataFragment) => {
    const { campaignTriggerForm } = this.state;
    const updateCondition = campaignTriggerForm.trigger_conditions_attributes[conditionIdx];

    const newState = update(this.state, {
      campaignTriggerForm: {
        trigger_conditions_attributes: {
          $splice: [[conditionIdx, 1, { ...updateCondition, ...conditionDataFragment }]],
        },
      },
    });

    this.setState(newState);
  }

  validateSmsCampaignStepsWithUnverifiedNumbers = async (campaignId) => {
    const isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

    if (!isRestrictionForUnverifiedNumbersEnabled) return;

    const defaultState = {
      unverifiedNumbersWarning: false,
    };

    if (!campaignId) {
      this.setState({ ...defaultState });
      return;
    }

    try {
      const response = await APIRequest.get({
        resource: `/v1/campaigns/${campaignId}/check_campaign_for_unverified_numbers`,
      });

      const unferifiedNumbersInSteps = response.body?.unferifiedNumbersInSteps;

      if (unferifiedNumbersInSteps) {
        this.setState({
          unverifiedNumbersWarning: true,
        });
      } else {
        this.setState({ ...defaultState });
      }
    } catch (error) {
      this.setState({ ...defaultState });
    }
  };

  buildCampaignTriggerFormState(campaignTriggerProp) {
    if (!campaignTriggerProp) return null;

    if (campaignTriggerProp?.campaign_id) {
      this.validateSmsCampaignStepsWithUnverifiedNumbers(campaignTriggerProp.campaign_id);
    }

    return {
      id:                            campaignTriggerProp.id,
      name:                          campaignTriggerProp.name,
      campaign_id:                   campaignTriggerProp.campaign_id,
      audience:                      campaignTriggerProp.audience,
      action:                        campaignTriggerProp.action,
      statement:                     campaignTriggerProp.statement,
      trigger_conditions_attributes: campaignTriggerProp.trigger_conditions.map((condition, idx) => _lodash.pickBy({
        id:       condition.id,
        field:    condition.field,
        operator: condition.operator,
        value:    condition.value,
      })),
    };
  }

  validate(campaignTriggerForm) {
    const { unverifiedNumbersWarning } = this.state;
    const {
      name, campaign_id, audience, action, statement,
    } = campaignTriggerForm;
    const errors = {};

    if (!name) {
      errors.name = ['Please enter a name to save this trigger'];
    }

    if (!campaign_id) {
      errors.campaign_id = ['Please select a campaign to save this trigger'];
    }

    if (campaign_id && unverifiedNumbersWarning) {
      errors.campaign_id = [];
    }

    if (!audience) {
      errors.audience = ["Can't be empty"];
    }

    if (!action) {
      errors.action = ["Can't be empty"];
    }

    if (!statement) {
      errors.statement = ["Can't be empty"];
    }

    if (campaignTriggerForm.trigger_conditions_attributes.length === 0) {
      errors.conditions = ["Can't be empty"];
    }

    this.conditionInstances.forEach((conditionInstance) => {
      conditionInstance.validate();
    });

    this.setState({ errors });

    return errors;
  }

  redirectToCampaignTriggers() {
    const { history } = this.props;
    const campaign_triggers_uri = GlobalContainer.productUri('/campaign_triggers');

    setTimeout(() => {
      history.push(campaign_triggers_uri);
      window.scrollTo(0, 0);
    });
  }

  saveCampaignTrigger(campaignTriggerForm) {
    const errors = this.validate(campaignTriggerForm);

    if (_lodash.size(errors) === 0) {
      this.setState({ submitting: true }, () => {
        if (_lodash.isNumber(campaignTriggerForm.id)) {
          CampaignTriggerActions.updateCampaignTrigger(_lodash.pickBy(campaignTriggerForm));
        } else {
          CampaignTriggerActions.createCampaignTrigger(_lodash.pickBy(campaignTriggerForm));
        }
      });
    }
  }

  addConditionInstance(el) {
    if (!el) return;

    this.conditionInstances.push(el);
  }

  buildCampaignConditionComponents(triggerConditions, audience) {
    const { campaignTriggerForm } = this.state;
    const conditions = [];
    this.conditionInstances = [];

    triggerConditions.forEach((condition, idx) => {
      if (condition._destroy === 1) return;

      conditions.push(
        <TriggerCondition
          ref={(el) => {
            this.addConditionInstance(el);
          }}
          key={uuidv4()}
          audience={audience || campaignTriggerForm.audience}
          condition={condition}
          conditionIdx={idx}
          updateCondition={this.updateCondition}
          deleteCondition={this.deleteCondition}
        />,
      );
    });

    return conditions;
  }

  renderTriggerConditions() {
    const { campaignTriggerForm } = this.state;

    const conditions = [];
    campaignTriggerForm.trigger_conditions_attributes.forEach((condition, idx) => {
      if (condition._destroy === 1) return;

      conditions.push(
        <TriggerCondition
          key={uuidv4()}
          condition={condition}
          audience={campaignTriggerForm.audience}
          conditionIdx={idx}
          updateCondition={this.updateCondition}
          deleteCondition={this.deleteCondition}
        />,
      );
    });

    return this.state.conditions;
  }

  renderErrors() {
    const { errors } = this.state;

    if (errors.conditions) {
      return (
        <div className="alert alert-danger text-left mb15">
          Can&apos;t save campaign trigger without conditions.
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      campaignTriggerForm, submitting, errors, unverifiedNumbersWarning,
    } = this.state;

    const conditionsCount = campaignTriggerForm.trigger_conditions_attributes.length;
    const campaign_triggers_uri = GlobalContainer.productUri('/campaign_triggers');

    return (
      <form method="POST" action="/campaign_triggers" onSubmit={this.handleFormSubmit}>
        <hr />

        <div className="form-group">
          <label htmlFor="campaign_name">Trigger Name:</label>
          <input
            id="campaign_trigger_name"
            name="campaign_trigger[name]"
            className={classNames('form-control', { 'has-error': errors.name })}
            value={campaignTriggerForm.name}
            placeholder="e.g. 6 Minute Mega"
            onChange={this.handleNameChange}
          />
          <div>{errors.name && <ErrorMessage message={errors.name} />}</div>
        </div>

        <div className="form-group">
          <label htmlFor="campaign">What campaign does this trigger effect?</label>
          <CampaignPicker
            value={campaignTriggerForm.campaign_id}
            placeholder="--- Select a Campaign ---"
            onChange={(selectedOptions) => {
              this.handleCampaignIdChange(selectedOptions);
            }}
            className={classNames({ 'has-error': errors.campaign_id })}
            clearable
          />

          <div>{errors.campaign_id && <ErrorMessage message={errors.campaign_id} />}</div>
          {unverifiedNumbersWarning && (
            <div
              className="alert alert-warning mt-3"
              role="alert"
            >
              Your campaign includes a text step, but the sender&apos;s
              phone number is not linked to an approved verified texting use case.
              To assign this campaign, please select a phone number in the
              text step(s) which are linked to an approved verified texting use case.
            </div>
          )}
        </div>

        <div className="form-group">
          <span className="mr-2">This campaign is intended for my</span>

          <div className="d-inline-flex" style={{ width: '80px' }}>
            <AudienceSelect
              className={classNames({ 'has-error': !!errors.audience })}
              value={campaignTriggerForm.audience}
              onChange={this.handleAudienceChange}
            />
          </div>

          <span className="mx-2">and should</span>

          <div className="d-inline-flex" style={{ width: '80px' }}>
            <ActionSelect
              className={classNames({ 'has-error': !!errors.action })}
              value={campaignTriggerForm.action}
              onChange={this.handleActionChange}
            />
          </div>

          <span className="mx-2">when</span>

          <div className="d-inline-flex" style={{ width: '70px' }}>
            <StatementSelect
              className={classNames({ 'has-error': !!errors.statement })}
              value={campaignTriggerForm.statement}
              onChange={this.handleStatementChange}
            />
          </div>

          <span className="ml-2">of the following conditions are met:</span>
        </div>

        <hr />

        <div className="trigger-conditions">
          <div className="row">
            <div className="col my-1 text-center">Field</div>
            <div className="col my-1 text-center">Operator</div>
            <div className="col my-1 text-center">Value</div>
            <div className="col-lg-1 text-center" />
          </div>

          {this.renderErrors()}
          {this.renderTriggerConditions()}
        </div>

        <TriggerConditionNew conditionsCount={conditionsCount} addCondition={this.addCondition} />

        <hr />

        <div className="pull-right">
          <Link to={campaign_triggers_uri} className="btn btn-secondary mr5">Back to Triggers</Link>
          { submitting ? (
            <button type="submit" className="btn btn-primary disabled" disabled>
              <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
              {' '}
              Saving ...
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">Save Trigger</button>
          )}
        </div>
      </form>
    );
  }
}

CampaignTriggerForm.defaultProps = {
  campaignTrigger: null,
};

CampaignTriggerForm.propTypes = {
  history:         PropTypes.shape({}).isRequired,
  campaignTrigger: PropTypes.shape({}),
};

export default CampaignTriggerForm;
