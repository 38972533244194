import classNames from 'classnames';
import React, {
  useState, useEffect, useMemo,
} from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSafeLocation } from '~/helpers/locationHelper';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import AgentNavContent from './nav_bar/agent_nav_content';
import DefaultNavContent from './nav_bar/default_nav_content';
import RecruitingNavContent from './nav_bar/recruiting_nav_content';
import RetentionNavContent from './nav_bar/retention_nav_content';

const PRODUCTS = {
  RECRUITING: 'recruiting',
  RETENTION:  'retention',
  AGENT:      'agent',
  ARCHIVED:   'archived',
  DEFAULT:    'default',
};

const ProductSelector = ({ onClickSwitch }) => {
  const { abilities } = Rails;

  if (!abilities.retention) return null;

  const product = GlobalContainer.product();
  const productColor = GlobalContainer.productColor();
  const text = product === PRODUCTS.RETENTION ? 'Retention' : 'Recruiting';
  const switchText = product === PRODUCTS.RETENTION ? 'Switch to Recruiting' : 'Switch to Retention';
  const switchURL = product === PRODUCTS.RETENTION ? '/recruiter/today' : '/retention/today';

  const handleClick = (e) => {
    if (product !== PRODUCTS.RETENTION) {
      onClickSwitch(e);
    }
  };

  return (
    <span className="dropdown">
      <a
        href="#select-product"
        className="mr20-md-up navbar-brand navbar-text text-center-sm-down position-relative"
        id=""
        data-toggle="dropdown"
      >
        <span className={classNames(`text-${productColor}`, 'text-uppercase small')}>
          {text}
          {' '}
          <FontAwesomeIcon icon={['fas', 'fa-caret-down']} />
        </span>
      </a>
      <div className="p10-sm-down dropdown-menu">
        <Link to={switchURL} onClick={handleClick} className="nav-link text-no-wrap custom-user-profile">
          {switchText}
        </Link>
      </div>
    </span>
  );
};

const ProductNav = () => {
  const product = GlobalContainer.product();
  switch (product) {
    case PRODUCTS.RECRUITING:
      return <RecruitingNavContent />;
    case PRODUCTS.RETENTION:
      return <RetentionNavContent />;
    case PRODUCTS.AGENT:
      return <AgentNavContent />;
    default:
      return <DefaultNavContent />;
  }
};

const NavBar = () => {
  const { currentTeam } = Rails.helpers;
  const product = GlobalContainer.product();
  const history = useHistory();
  const location = useSafeLocation();
  const [isRetentionSwitchBlocked, setRetentionSwitchBlocked] = useState((location?.state && location?.state?.isRetentionSwitchBlocked) || false);
  const [isInboxAccessRestricted, setIsInboxAccessRestricted] = useState((location?.state && location?.state?.isInboxAccessBlocked) || false);

  const handleSwitchAttempt = (e) => {
    e.preventDefault();

    const isRetentionEnabled = checkFeatureFlag(process.env.RESTRICT_RETENTION_ACCESS_FLAG);

    setRetentionSwitchBlocked(isRetentionEnabled);

    if (!isRetentionEnabled) {
      history.push('/retention/today');
    }
  };

  const logoName = useMemo(() => (product === PRODUCTS.RETENTION ? 'brokerkit_blue' : 'brokerkit'), [product]);
  const showProductSelector = useMemo(() => [PRODUCTS.RECRUITING, PRODUCTS.RETENTION, PRODUCTS.ARCHIVED].includes(product), [product]);

  useEffect(() => {
    if (location?.state && location?.state?.isRetentionSwitchBlocked) {
      history.replace({ ...location, state: undefined });
    }
  }, []);

  useEffect(() => {
    const handlePosthogReady = () => {
      if (product === PRODUCTS.RETENTION && checkFeatureFlag(process.env.RESTRICT_RETENTION_ACCESS_FLAG)) {
        history.push({
          pathname: '/recruiter/today',
          state:    { isRetentionSwitchBlocked: true },
        });
      }
    };

    if (window.isPosthogInitialized) {
      handlePosthogReady();
    } else {
      window.addEventListener('posthogReady', handlePosthogReady);
    }

    return () => {
      window.removeEventListener('posthogReady', handlePosthogReady);
    };
  }, []);

  useEffect(() => {
    let timeout;

    if (isRetentionSwitchBlocked) {
      timeout = setTimeout(() => {
        setRetentionSwitchBlocked(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isRetentionSwitchBlocked]);

  useEffect(() => {
    let timeout;

    if (isInboxAccessRestricted) {
      timeout = setTimeout(() => {
        setIsInboxAccessRestricted(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isInboxAccessRestricted]);

  return (
    <>
      {isRetentionSwitchBlocked && (
        <div className="pl15 pt15 pr15 bg-white">
          <div className="alert alert-info" role="alert">
            It looks like you&apos;re trying to switch to the retention module — great choice!
            Please upgrade your pricing plan
            {' '}
            <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">here</a>
            {' '}
            to enable this feature.
          </div>
        </div>
      )}

      {isInboxAccessRestricted && (
        <div className="pl15 pt15 pr15 bg-white">
          <div className="alert alert-info" role="alert">
            It looks like you&apos;re trying to access the inbox — great choice! Please upgrade your pricing plan
            {' '}
            <a href="/billing" target="_blank" rel="noopener noreferrer" className="text-success">here</a>
            {' '}
            to enable this feature.
          </div>
        </div>
      )}

      <nav className="bg-white bb d-flex flex-column flex-md-row flex-md-wrap flex-xl-nowrap navbar navbar-expand-md navbar-light bg-faded">
        <span className="d-flex flex-column flex-md-row align-items-center navbar-brand mx-auto ml-md-0 mr-md-auto">
          <a href="/" className="mr-0 mr-md-3">
            <img
              src={Rails.assets[`${logoName}.svg`]}
              height={18}
              alt="BrokerKit Logo"
              style={{ marginTop: '-6px' }}
            />
          </a>

          {showProductSelector && <ProductSelector onClickSwitch={handleSwitchAttempt} />}

          {currentTeam && (
            <span
              className="ml-0 ml-md-3 text-nowrap navbar-text text-break text-black text-center text-md-left"
              href="/"
              style={{ position: 'relative', top: '1px' }}
            >
              {currentTeam.name}
            </span>
          )}
        </span>

        <ProductNav />
      </nav>
    </>
  );
};

NavBar.defaultProps = {};

NavBar.propTypes = {};

export default NavBar;
