import autoBind            from 'react-autobind';
import React               from 'react';
import filesize             from 'filesize';
import axios               from 'axios';
import { Progress }        from 'react-sweet-progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { axiosInstance } from '~/lib/api_request';
import DataActions       from '~/actions/data_actions';
import DataStore         from '~/stores/data_store';
import Stats             from './stats';

const { CancelToken } = axios;
let cancel;

class UploadFileStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:          false,
      file:             undefined,
      key:              '',
      percentCompleted: 0,
      errors:           {},
    };

    this.baseState = this.state;

    this.hiddenFileInput = React.createRef();

    autoBind(this);
  }

  componentDidMount() {
    this.dataStoreListener = DataStore.addListener(this.onDataStoreChange);
  }

  componentWillUnmount() {
    if (this.dataStoreListener) this.dataStoreListener.remove();
  }

  onDataStoreChange() {
    const { lastDataStoreAction } = DataStore.getState();
    const { resetState, goToStep } = this.props;

    switch (lastDataStoreAction) {
      case 'createImportDone':
        GlobalContainer.notify('Your import has started successfully. We will send you an email about the import results.', 'success');
        break;
      case 'createImportFail':
        GlobalContainer.notify('Oops, something went wrong. Try again later.', 'error');
        break;
      default:
      // do nothing
    }

    this.hiddenFileInput.current.value = null;
    this.setState(this.baseState);
    resetState();
    goToStep(1);
  }

  handleCancelUploading = (event) => {
    event.preventDefault();

    this.setState({ percentCompleted: 0 });

    cancel();
  }

  handleClick = (event) => {
    this.hiddenFileInput.current.click();
  };

  handleFileChange = (event) => {
    const errors = {};
    const file = event.target.files[0];
    if (!file) return;

    if (!(/\.(csv)$/i).test(file.name)) {
      errors.file_type = 'Invalid file format. Please select a different file.';
    }

    this.setState({ errors, file });
  };

  handleResetFile = (event) => {
    event.preventDefault();

    this.hiddenFileInput.current.value = null;
    this.setState({ file: undefined, key: '', percentCompleted: 0 });
  }

  submit = async (e) => {
    const { file, key, loading } = this.state;
    const { labels, multiple_listing_service_ouid, importType } = this.props;
    const errors = {};

    if (!file || loading || key || !_lodash.isEmpty(errors)) return;

    this.setState({ errors, loading: true });

    try {
      const { data } = await axiosInstance.get('/v1/imports/direct_post');
      const { url, fields } = data;
      const formData = new FormData();

      Object.keys(fields).forEach((fieldKey) => {
        formData.append(fieldKey, fields[fieldKey]);
      });
      formData.append('file', file);

      const response = await axios.post(url, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({ percentCompleted });
        },
        cancelToken: new CancelToken((c) => cancel = c),
      });

      const awsKey = new DOMParser()
        .parseFromString(response.data, 'application/xml')
        .getElementsByTagName('Key')[0]
        .textContent;

      this.setState({ key: awsKey });

      setTimeout(() => {
        if (awsKey) {
          DataActions.createImport({
            type: importType,
            key:  awsKey,
            labels,
            multiple_listing_service_ouid,
          });
        }
      }, 2000);
    } catch (error) {
      if (!axios.isCancel(error)) {
        errors.s3_error = `Oops, ${error.message}. Try it again later.`;
        this.setState({ errors });
      }
    } finally {
      // Stop loading
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      key, loading, file, errors, percentCompleted,
    } = this.state;
    const { step } = this.props;
    let status = 'active';

    if (!loading && _lodash.isEmpty(errors) && key) {
      status = 'success';
    }

    if (!loading && !_lodash.isEmpty(errors)) {
      status = 'error';
    }

    return (
      <div>
        <p>Allowed File Type: CSV</p>

        <hr />

        <button disabled={key || loading} type="button" className="btn btn-success btn-block" onClick={this.handleClick}>
          <FontAwesomeIcon icon={['far', 'fa-arrow-up']} />
          {' '}
          Select Completed Template File
        </button>
        <input
          type="file"
          accept=".csv"
          ref={this.hiddenFileInput}
          hidden
          onChange={this.handleFileChange}
        />

        { file && (
          <div>
            <hr />

            <p>{file.name}</p>
            <small className="text-muted">{filesize(file.size)}</small>

            <br />

            {errors.file_type && (
              <p className="text-red">{errors.file_type}</p>
            )}

            {errors.s3_error && (
              <p className="text-red">{errors.s3_error}</p>
            )}

            {!loading ? (
              <button type="button" className="btn btn-sm btn-danger" onClick={this.handleResetFile}>
                <FontAwesomeIcon icon={['far', 'fa-times']} />
                {' '}
                Reset Template File
              </button>
            ) : (
              <button type="button" className="btn btn-sm btn-danger" onClick={this.handleCancelUploading}>
                <FontAwesomeIcon icon={['far', 'fa-times']} />
                {' '}
                Cancel Uploading File
              </button>
            )}

            <Progress percent={percentCompleted} status={status} />
          </div>
        )}

        <Stats step={step} {...this.props} nextStep={this.submit} disabled={!file || loading || key || !_lodash.isEmpty(errors)} />
      </div>
    );
  }
}

export default UploadFileStep;
