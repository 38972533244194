import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import TriggerConditionForm from './trigger_condition_form';
import brokerbit            from '~/lib/brokerbit';

class TriggerCondition extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleConditionFormChange(formState) {
    const { conditionIdx, updateCondition } = this.props;

    updateCondition(conditionIdx, formState);
  }

  handleConditionDelete(e) {
    e.preventDefault();

    const { conditionIdx, deleteCondition } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this condition?',
      callback: (ok) => {
        if (ok) deleteCondition(conditionIdx);
      },
    });
  }

  validate() {
    this.conditionForm.validate();
  }

  render() {
    const { condition, audience, conditionIdx } = this.props;

    return (
      <div className="row my-2">
        <TriggerConditionForm
          ref={(el) => this.conditionForm = el}
          audience={audience}
          condition={condition}
          onChange={this.handleConditionFormChange}
        />
        <div className="col-lg-1 my-1 text-center">
          <button type="button" className="btn btn-outline-danger mr5" onClick={this.handleConditionDelete}>
            <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
          </button>
        </div>
      </div>
    );
  }
}

TriggerCondition.defaultProps = {
  condition:       {},
  audience:        '',
  conditionIdx:    null,
  updateCondition: () => false,
  deleteCondition: () => false,
};

TriggerCondition.propTypes = {
  condition:       PropTypes.shape({}),
  audience:        PropTypes.string,
  conditionIdx:    PropTypes.number,
  updateCondition: PropTypes.func,
  deleteCondition: PropTypes.func,
};

export default TriggerCondition;
