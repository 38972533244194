import React, { useState, useEffect, useCallback } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { PropTypes } from 'prop-types';
import APIRequest from '~/lib/api_request';

const LeadsSelect = ({
  id,
  placeholder,
  multiple,
  onChange,
  selected_ids,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState(
    selected_ids && _lodash.map(selected_ids, (v) => parseInt(v, 10)),
  );

  useEffect(() => {
    let isMounted = true;
    const request = APIRequest.get({
      resource: '/v1/teams/leads',
    }).end((error, response) => {
      if (!error && isMounted) {
        const leads = response.body.map((lead) => ({
          label: lead.name,
          id:    lead.id,
        }));

        setOptions(leads);
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
      if (request) {
        request.abort();
      }
    };
  }, [selected_ids]);

  const handleSelectChange = useCallback(
    (selected) => {
      const ids = _lodash.map(selected, 'id');

      setSelectedIds(ids);
      onChange(ids);
    },
    [onChange],
  );

  const selected = options.filter((i) => _lodash.find(selectedIds, (leadId) => leadId === i.id));

  return (
    <Typeahead
      {...{
        id,
        placeholder,
        selected,
        multiple,
        options,
      }}
      onChange={handleSelectChange}
      emptyLabel={loading ? 'Loading ...' : 'No results'}
    >
      {() => (loading ? (
        <div className="rbt-aux">
          <span className="Select-loading" />
        </div>
      ) : null)}
    </Typeahead>
  );
};

LeadsSelect.defaultProps = {
  id:           '',
  placeholder:  'Please Select Value...',
  multiple:     true,
  onChange:     () => {},
  selected_ids: [],
};

LeadsSelect.propTypes = {
  id:           PropTypes.string,
  placeholder:  PropTypes.string,
  multiple:     PropTypes.bool,
  onChange:     PropTypes.func,
  selected_ids: PropTypes.arrayOf(PropTypes.number),
};

export default LeadsSelect;
