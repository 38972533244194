import { ReduceStore }  from 'flux/utils';
import AppDispatcher    from '~/dispatchers/app_dispatcher';
import LabelActionTypes from '~/types/label_action_types';

class LabelStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingLabels:        false,
      loadingLabel:         false,
      labels:               [],
      label:                null,
      lastLabelStoreAction: null,
      errors:               {},
      submitting:           false,
    };
  }

  loadLabels(state, action) {
    const newState = { ...state };

    newState.loadingLabels = true;
    newState.lastLabelStoreAction = 'loadLabels';

    return newState;
  }

  loadLabelsDone(state, action) {
    const newState = { ...state };

    newState.loadingLabels = false;
    newState.labels = action.labels;
    newState.lastLabelStoreAction = 'loadLabelsDone';

    return newState;
  }

  loadLabelsFail(state, action) {
    const newState = { ...state };

    newState.loadingLabels = false;
    newState.errors = action.error.response.body.errors;
    newState.lastLabelStoreAction = 'loadLabelsFail';

    return newState;
  }

  loadLabel(state, action) {
    const newState = { ...state };

    newState.loadingLabel = true;
    newState.lastLabelStoreAction = 'loadLabel';

    return newState;
  }

  loadLabelDone(state, action) {
    const newState = { ...state };

    newState.loadingLabel = false;
    newState.label = action.label;
    newState.lastLabelStoreAction = 'loadLabelDone';
    newState.recordIndex = action.recordIndex;

    return newState;
  }

  loadLabelFail(state, action) {
    const newState = { ...state };

    newState.loadingLabel = false;
    newState.errors = action.errors;
    newState.lastLabelStoreAction = 'loadLabelFail';

    return newState;
  }

  createLabel(state, action) {
    const newState = { ...state };

    newState.lastLabelStoreAction = 'createLabel';

    return newState;
  }

  createLabelDone(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.label      = action.label;
    newState.errors     = {};
    newState.lastLabelStoreAction = 'createLabelDone';

    return newState;
  }

  createLabelFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.errors     = action.error.response.body.errors;
    newState.lastLabelStoreAction = 'createLabelFail';

    return newState;
  }

  updateLabel(state, action) {
    const newState = { ...state };

    newState.lastLabelStoreAction = 'updateLabel';

    return newState;
  }

  updateLabelDone(state, action) {
    const newState = { ...state };

    newState.label  = action.label;
    newState.errors = {};
    newState.lastLabelStoreAction = 'updateLabelDone';

    return newState;
  }

  updateLabelFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body.errors;
    newState.lastLabelStoreAction = 'updateLabelFail';

    return newState;
  }

  deleteLabel(state, action) {
    const newState = { ...state };

    newState.lastLabelStoreAction = 'deleteLabel';

    return newState;
  }

  deleteLabelDone(state, action) {
    const newState = { ...state };

    newState.errors = {};
    newState.lastLabelStoreAction = 'deleteLabelDone';

    return newState;
  }

  deleteLabelFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error;
    newState.lastLabelStoreAction = 'deleteLabelFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case LabelActionTypes.LOAD_LABELS:
        return this.loadLabels(state, action);
      case LabelActionTypes.LOAD_LABELS_DONE:
        return this.loadLabelsDone(state, action);
      case LabelActionTypes.LOAD_LABELS_FAIL:
        return this.loadLabelsFail(state, action);
      case LabelActionTypes.LOAD_LABEL:
        return this.loadLabel(state, action);
      case LabelActionTypes.LOAD_LABEL_DONE:
        return this.loadLabelDone(state, action);
      case LabelActionTypes.LOAD_LABEL_FAIL:
        return this.loadLabelFail(state, action);
      case LabelActionTypes.CREATE_LABEL:
        return this.createLabel(state, action);
      case LabelActionTypes.CREATE_LABEL_DONE:
        return this.createLabelDone(state, action);
      case LabelActionTypes.CREATE_LABEL_FAIL:
        return this.createLabelFail(state, action);
      case LabelActionTypes.UPDATE_LABEL:
        return this.updateLabel(state, action);
      case LabelActionTypes.UPDATE_LABEL_DONE:
        return this.updateLabelDone(state, action);
      case LabelActionTypes.UPDATE_LABEL_FAIL:
        return this.updateLabelFail(state, action);
      case LabelActionTypes.DELETE_LABEL:
        return this.deleteLabel(state, action);
      case LabelActionTypes.DELETE_LABEL_DONE:
        return this.deleteLabelDone(state, action);
      case LabelActionTypes.DELETE_LABEL_FAIL:
        return this.deleteLabelFail(state, action);
      default:
        return state;
    }
  }
}

export default new LabelStore();
