import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest from '~/lib/api_request';

const STATUS_RESENDING = 'resending';
const STATUS_RESENT = 'resent';
const STATUS_ERROR = 'error';

class AttentionNeeded extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded:       false,
      appointments: [],
      emails:       [],
      statuses:     {},
    };
    autoBind(this);
  }

  componentDidMount() {
    APIRequest.get({ resource: '/v1/attention_items' }).end(
      (error, response) => {
        if (response.ok) {
          this.setState({
            appointments: response.body.appointments,
            emails:       response.body.emails,
            loaded:       true,
          });
        }
      },
    );
  }

  resendAppointment(id) {
    this.setState((prevState) => ({
      statuses: {
        ...prevState.statuses,
        [`appt-${id}`]: STATUS_RESENDING,
      },
    }));

    APIRequest.post({
      resource: `/v1/appointments/${id}/resend`,
    }).end((error, response) => {
      if (response.ok) {
        this.setState((prevState) => ({
          statuses: {
            ...prevState.statuses,
            [`appt-${id}`]: STATUS_RESENT,
          },
        }));
      } else {
        this.setState((prevState) => ({
          statuses: {
            ...prevState.statuses,
            [`appt-${id}`]: STATUS_ERROR,
          },
        }));
      }
    });
  }

  cancelAppointment(id) {
    const { appointments } = this.state;

    APIRequest.delete({ resource: `/v1/appointments/${id}` }).end(
      (error, response) => {
        this.setState({
          appointments: _lodash.filter(
            appointments,
            (a) => a.id !== id,
          ),
        });
      },
    );
  }

  resendEmail(id) {
    this.setState((prevState) => ({
      statuses: {
        ...prevState.statuses,
        [`email-${id}`]: STATUS_RESENDING,
      },
    }));

    APIRequest.post({ resource: `/v1/emails/${id}/resend` }).end(
      (error, response) => {
        if (response.ok) {
          this.setState((prevState) => ({
            statuses: {
              ...prevState.statuses,
              [`email-${id}`]: STATUS_RESENT,
            },
          }));
        } else {
          this.setState((prevState) => ({
            statuses: {
              ...prevState.statuses,
              [`email-${id}`]: STATUS_ERROR,
            },
          }));
        }
      },
    );
  }

  cancelEmail(id) {
    const { emails } = this.state;

    APIRequest.delete({ resource: `/v1/emails/${id}` }).end(
      (error, response) => {
        this.setState({
          emails: _lodash.filter(emails, (e) => e.id !== id),
        });
      },
    );
  }

  renderAppointment(appt) {
    const { statuses } = this.state;
    let controls;

    switch (statuses[`appt-${appt.id}`]) {
      case STATUS_RESENDING:
        controls = (
          <span className="text-grey">
            <FontAwesomeIcon
              icon="far fa-spinner"
              pulse
              className="mr5"
            />
            {' '}
            Resending
          </span>
        );
        break;
      case STATUS_RESENT:
        controls = (
          <span className="text-green">
            <FontAwesomeIcon
              icon={['far', 'fa-check']}
              className="mr5"
            />
            {' '}
            Resent
          </span>
        );
        break;
      case STATUS_ERROR:
        controls = (
          <span className="text-red">
            <FontAwesomeIcon
              icon={['far', 'fa-times']}
              className="mr5"
            />
            {' '}
            Error
          </span>
        );
        break;
      default:
        controls = (
          <div>
            <a
              href="#"
              className="ml10"
              onClick={(e) => {
                e.preventDefault();
                this.resendAppointment(appt.id);
              }}
            >
              Resend
            </a>
            <a
              href="#"
              className="ml10"
              onClick={(e) => {
                e.preventDefault();
                this.cancelAppointment(appt.id);
              }}
            >
              Cancel
            </a>
          </div>
        );
    }

    return (
      <tr key={appt.id}>
        <td>
          Appointment
          {' '}
          <strong>{appt.subject}</strong>
          {' '}
          on
          {' '}
          {Moment(appt.created_at).format('LL')}
          {' '}
          failed to send.
        </td>
        <td className="text-right">{controls}</td>
      </tr>
    );
  }

  renderEmail(email) {
    const { statuses } = this.state;
    let controls;

    switch (statuses[`email-${email.id}`]) {
      case 'resending':
        controls = (
          <span className="text-grey">
            <FontAwesomeIcon
              icon="far fa-spinner"
              pulse
              className="mr5"
            />
            {' '}
            Resending
          </span>
        );
        break;
      case 'resent':
        controls = (
          <span className="text-green">
            <FontAwesomeIcon
              icon={['far', 'fa-check']}
              className="mr5"
            />
            {' '}
            Resent
          </span>
        );
        break;
      default:
        controls = (
          <div>
            <a
              href="#"
              className="ml10"
              onClick={(e) => {
                e.preventDefault();
                this.resendEmail(email.id);
              }}
            >
              Resend
            </a>
            <a
              href="#"
              className="ml10"
              onClick={(e) => {
                e.preventDefault();
                this.cancelEmail(email.id);
              }}
            >
              Cancel
            </a>
          </div>
        );
    }

    return (
      <tr key={email.id}>
        <td>
          Email
          {' '}
          <strong>{email.subject}</strong>
          {' '}
          on
          {' '}
          {Moment(email.created_at).format('LL')}
          {' '}
          failed to send.
        </td>
        <td className="text-right">{controls}</td>
      </tr>
    );
  }

  render() {
    const { loaded, appointments, emails } = this.state;

    if (!loaded) return null;
    if (
      _lodash.size(appointments) === 0
      && _lodash.size(emails) === 0
    ) {
      return null;
    }

    return (
      <div className="card card-warning mt15 ml15 mr15">
        <div className="pl20 pt20 pr20">
          <h2>Items requiring your attention</h2>
        </div>
        <table className="table table-responsive">
          <tbody>
            {appointments.map(this.renderAppointment)}
            {emails.map(this.renderEmail)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AttentionNeeded;
