import React       from 'react';
import EmailEditor from '../forms/HtmlEditors/EmailEditor';

const StyleguideHtmlEditor = (props) => (
  <div className="mb15">
    <EmailEditor
      {...props}
      placeholder="Enter Description"
    />
  </div>
);

export default StyleguideHtmlEditor;
