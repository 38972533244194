import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import APIRequest from '~/lib/api_request';
import Select     from '~/components/forms/select';

class SourceSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      options: [],
    };

    autoBind(this);
  }

  componentDidMount() {
    const SOURCES = [
      'Abstrakt',
      'ASREB',
      'Broker Metrics',
      'Career Builder',
      'Career Hour',
      'Career Workshop',
      'Cold Call',
      'Co-op Agent',
      'Craigslist',
      'Direct Mail',
      'Educational Event',
      'Email Campaign',
      'Facebook',
      'Friend of Referrer',
      'Indeed',
      'Landing Page',
      'MarketView Broker',
      'Mergers & Acquisitions',
      'MLS',
      'Monster',
      'Networking',
      'Allied Resources',
      'Open House Drop By',
      'Other',
      'RB Agent Page',
      'RB Market Center Page',
      'Real Estate Schools',
      'Recruit Advertising',
      'Red Careers',
      'Referral',
      'Relitix',
      'SmartSetter',
      'Sphere',
      'Text Campaign',
      'Walk in',
      'Wizehire',
      'Wright Brothers',
      'Ziprecruiter',
    ];

    APIRequest.get({
      resource: '/v1/lead_sources',
    }).end((error, response) => {
      this.setState({ loading: false }, () => {
        if (!error) {
          const customSources = response.body.map((source) => ({
            value: source.name,
            label: `${source.name}`,
          }));

          const staticSources = _lodash.map(SOURCES, (e) =>  ({ value: e, label: e }));

          let options = [...customSources, ...staticSources];
          options = _lodash.sortBy(options, (item) => item.value.toLowerCase());

          if (this.props.blank) options = [{ value: '-blank-', label: '-blank-' }, ...options];

          this.setState({ options });
        }
      });
    });
  }

  render() {
    const { loading, options } = this.state;
    return (
      <Select
        {...this.props}
        value={this.props.value}
        onChange={this.props.onChange}
        options={options}
        isLoading={loading}
      />
    );
  }
}

SourceSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
  blank:    false,
};

SourceSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
  blank:       PropTypes.bool,
};

export default SourceSelect;
