import { ReduceStore }            from 'flux/utils';
import AppDispatcher              from '~/dispatchers/app_dispatcher';
import CampaignTriggerActionTypes from '~/types/campaign_trigger_action_types';

class CampaignTriggerStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingCampaignTriggers:        false,
      campaignTriggers:               [],
      campaignTrigger:                null,
      lastCampaignTriggerStoreAction: null,
      errors:                         {},
      submitting:                     false,
    };
  }

  loadCampaignTriggers(state, action) {
    const newState = { ...state };

    newState.loadingCampaignTriggers = true;
    newState.lastCampaignTriggerStoreAction = 'loadCampaignTriggers';

    return newState;
  }

  loadCampaignTriggersDone(state, action) {
    const newState = { ...state };

    newState.loadingCampaignTriggers = false;
    newState.campaignTriggers = action.campaignTriggers;
    newState.lastCampaignTriggerStoreAction = 'loadCampaignTriggersDone';

    return newState;
  }

  loadCampaignTriggersFail(state, action) {
    const newState = { ...state };

    newState.loadingCampaignTriggers = false;
    newState.lastCampaignTriggerStoreAction = 'loadCampaignTriggersFail';

    return newState;
  }

  createCampaignTrigger(state, action) {
    const newState = { ...state };

    newState.lastCampaignTriggerStoreAction = 'createCampaignTrigger';

    return newState;
  }

  createCampaignTriggerDone(state, action) {
    const newState = { ...state };

    newState.campaignTrigger = action.campaign_trigger;
    newState.lastCampaignTriggerStoreAction = 'createCampaignTriggerDone';
    newState.submitting = false;
    newState.errors     = {};

    return newState;
  }

  createCampaignTriggerFail(state, action) {
    const newState = { ...state };

    newState.lastCampaignTriggerStoreAction = 'createCampaignTriggerFail';
    newState.submitting = false;
    newState.errors     = action.error.response.body.errors;

    return newState;
  }

  updateCampaignTrigger(state, action) {
    const newState = { ...state };

    newState.lastCampaignTriggerStoreAction = 'updateCampaignTrigger';

    return newState;
  }

  updateCampaignTriggerDone(state, action) {
    const newState = { ...state };

    newState.campaignTrigger = action.campaign_trigger;
    newState.lastCampaignTriggerStoreAction = 'updateCampaignTriggerDone';
    newState.submitting = false;
    newState.errors     = {};

    return newState;
  }

  updateCampaignTriggerFail(state, action) {
    const newState = { ...state };

    newState.lastCampaignTriggerStoreAction = 'updateCampaignTriggerFail';
    newState.submitting = false;
    newState.errors     = action.error.response.body.errors;

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case CampaignTriggerActionTypes.LOAD_CAMPAIGN_TRIGGERS:
        return this.loadCampaignTriggers(state, action);

      case CampaignTriggerActionTypes.LOAD_CAMPAIGN_TRIGGERS_DONE:
        return this.loadCampaignTriggersDone(state, action);

      case CampaignTriggerActionTypes.LOAD_CAMPAIGN_TRIGGERS_FAIL:
        return this.loadCampaignTriggersFail(state, action);

      case CampaignTriggerActionTypes.CREATE_CAMPAIGN_TRIGGER:
        return this.createCampaignTrigger(state, action);

      case CampaignTriggerActionTypes.CREATE_CAMPAIGN_TRIGGER_DONE:
        return this.createCampaignTriggerDone(state, action);

      case CampaignTriggerActionTypes.CREATE_CAMPAIGN_TRIGGER_FAIL:
        return this.createCampaignTriggerFail(state, action);

      case CampaignTriggerActionTypes.UPDATE_CAMPAIGN_TRIGGER:
        return this.updateCampaignTrigger(state, action);

      case CampaignTriggerActionTypes.UPDATE_CAMPAIGN_TRIGGER_DONE:
        return this.updateCampaignTriggerDone(state, action);

      case CampaignTriggerActionTypes.UPDATE_CAMPAIGN_TRIGGER_FAIL:
        return this.updateCampaignTriggerFail(state, action);

      default:
        return state;
    }
  }
}

export default new CampaignTriggerStore();
