import { ReduceStore }          from 'flux/utils';
import AppDispatcher            from '~/dispatchers/app_dispatcher';
import TwilioProfileActionTypes from '~/types/twilio_profile_action_types';

class TwilioProfileStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      bundle:                       null,
      status:                       null,
      business_information:         null,
      address:                      null,
      lastTwilioProfileStoreAction: null,
      submitting:                   false,
      errors:                       {},
      twilio_errors:                [],
    };
  }

  submitTwilioProfile(state, action) {
    const newState = { ...state };

    newState.lastTwilioProfileStoreAction = 'submitTwilioProfile';
    newState.submitting = true;

    return newState;
  }

  submitTwilioProfileDone(state, action) {
    const newState = { ...state };

    newState.lastTwilioProfileStoreAction = 'submitTwilioProfileDone';
    newState.submitting = false;
    newState.errors = {};
    newState.twilio_errors = [];

    return newState;
  }

  submitTwilioProfileFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.twilio_errors = action.error.response.body?.twilio_errors || [];
    newState.lastTwilioProfileStoreAction = 'submitTwilioProfileFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case TwilioProfileActionTypes.SUBMIT_TWILIO_PROFILE:

        return this.submitTwilioProfile(state, action);
      case TwilioProfileActionTypes.SUBMIT_TWILIO_PROFILE_DONE:

        return this.submitTwilioProfileDone(state, action);
      case TwilioProfileActionTypes.SUBMIT_TWILIO_PROFILE_FAIL:

        return this.submitTwilioProfileFail(state, action);
      default:
        return state;
    }
  }
}

export default new TwilioProfileStore();
