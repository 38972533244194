import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityReferrerUpdate extends TimelineActivity {
}

TimelineActivityReferrerUpdate.defaultProps = {
  title:          (obj) => `The referrer has been set to ${obj.props.activity.parameters.referrer.changed_to} by ${obj.props.activity.parameters.referrer.changed_by}`,
  icon:           'exchange-alt',
  prefix:         'fas',
  alwaysPublic:   false,
};

export default TimelineActivityReferrerUpdate;
