import autoBind               from 'react-autobind';
import React                  from 'react';
import { PropTypes }          from 'prop-types';
import { withRouter }         from 'react-router-dom';
import ReactPaginate          from 'react-paginate';

import CampaignLibraryActions from '~/actions/campaign_library_actions';
import GlobalContainer        from '~/components/global_container';
import CampaignLibraryItem    from './campaign_library_item';

class CampaignLibraryTable extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleCampaignClick(campaign) {
    const { campaign_libraries, history } = this.props;
    const campaign_libraries_uri = GlobalContainer.productUri(`/campaign_libraries/${campaign.id}`);

    history.push({
      pathname: campaign_libraries_uri,
      state:    { page: campaign_libraries.current_page },
    });
  }

  onPageChange(page) {
    const selectedPage = page.selected;
    const { selectedScopeFilter: scope, selectedCampaignTypeFilter: campaign_type } = this.props;

    CampaignLibraryActions.loadCampaigns({
      currentPage: selectedPage + 1, scope, campaign_type,
    });
  }

  buildPageHref(pageNumber) {
    return `#page-${pageNumber}`;
  }

  renderCampaignRows() {
    const { campaign_libraries } = this.props;
    const campaignRows = [];

    campaign_libraries.data.forEach((campaign, idx) => {
      campaignRows.push(
        <CampaignLibraryItem
          key={`campaign-${campaign.id}`}
          campaign={campaign}
          handleCampaignClick={this.handleCampaignClick}
          currentPage={campaign_libraries.current_page}
        />,
      );
    });

    return campaignRows;
  }

  renderPagination() {
    const { campaign_libraries } = this.props;

    if (!campaign_libraries) {
      return null;
    }

    return (
      <div className="pl20 pr20 pt10 pb10">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            Showing
            {' '}
            {campaign_libraries.start}
            {' '}
            to
            {' '}
            {campaign_libraries.end}
            {' '}
            of
            {' '}
            {campaign_libraries.total_count}
            {' '}
            campaigns
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            { campaign_libraries.total_pages > 1
              && (
              <nav>
                <ReactPaginate
                  forcePage={campaign_libraries.current_page - 1}
                  pageCount={campaign_libraries.total_pages}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  containerClassName="pagination justify-content-end"
                  activeClassName="active"
                  breakLabel="..."
                  breakLinkClassName="page-link disabled"
                  breakClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  onPageChange={this.onPageChange}
                  hrefBuilder={this.buildPageHref}
                  previousLabel={Rails.isMobile ? '<' : 'Previous'}
                  nextLabel={Rails.isMobile ? '>' : 'Next'}
                />
              </nav>
              )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <table className="table table-hover table-responsive">
          <tbody>
            {this.renderCampaignRows()}
          </tbody>
        </table>

        {this.renderPagination()}
      </>
    );
  }
}

CampaignLibraryTable.defaultProps = {
  campaign_libraries: {},
};

CampaignLibraryTable.propTypes = {
  history:            PropTypes.shape({}).isRequired,
  campaign_libraries: PropTypes.shape({}),
};

export default withRouter(CampaignLibraryTable);
