import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Collapse }         from 'reactstrap';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import Select               from '~/components/forms/select';

import LeadLinkActions from '~/actions/lead_link_actions';
import LeadLinkStore from '~/stores/lead_link_store';
import LeadDrawerActions from '~/actions/lead_drawer_actions';
import LeadDrawerStore from '~/stores/lead_drawer_store';

const SUPPORTED_SERVICES = [
  { label: 'Linkedin',         value: 'linkedin' },
  { label: 'Facebook',         value: 'facebook' },
  { label: 'Twitter',          value: 'twitter' },
  { label: 'Pinterest',          value: 'pinterest' },
  { label: 'Youtube',          value: 'youtube' },
  { label: 'Vimeo',          value: 'vimeo' },
  { label: 'Business Website', value: 'business-website' },
  { label: 'Personal Website', value: 'personal-website' },
  { label: 'Other',            value: 'other' },
];

class LeadDrawerSocialLinks extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const isOpen = localStorage.isSocialLinksOpen ? JSON.parse(localStorage.isSocialLinksOpen) : true;

    this.state = {
      isOpen,
      avatarFile: React.createRef(),
    };
  }

  componentDidMount() {
    const { lead } = this.props;

    this.linkStoreListener = LeadLinkStore.addListener(this.onLeadLinkStoreChange);
    this.leadDrawerStoreListener = LeadDrawerStore.addListener(this.onLeadDrawerStoreChange);

    LeadLinkActions.loadLeadLinks(lead.web_links);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    LeadLinkActions.loadLeadLinks(nextProps.lead.web_links);
  }

  componentWillUnmount() {
    if (this.linkStoreListener) this.linkStoreListener.remove();
    if (this.leadDrawerStoreListener) this.leadDrawerStoreListener.remove();
  }

  onLeadLinkStoreChange() {
    const nextState = LeadLinkStore.getState();
    const { lastLeadLinkStoreAction } = nextState;
    const { lead } = this.props;

    switch (lastLeadLinkStoreAction) {
      case 'updateLeadLinkFail':
        GlobalContainer.notify("Couldn't update link, please try again", 'danger');
        break;
      case 'createLeadLinkFail':
        GlobalContainer.notify("Couldn't add link, please try again", 'danger');
        break;

      case 'updateFromFullContactFail':
        GlobalContainer.notify('Something went wrong during lookup, please try again', 'danger');
        break;

      case 'updateFromFullContactDone':
        setTimeout(() => {
          LeadDrawerActions.loadLead(lead.id);
        });
        break;

      default:
        break;
    }

    this.setState(nextState);
  }

  onLeadDrawerStoreChange() {
    const { lead } = this.props;
    const nextState = LeadDrawerStore.getState();
    const { leadDrawerStoreAction } = nextState;

    switch (leadDrawerStoreAction) {
      case 'updateLeadAvatarFail':
        GlobalContainer.notify("Couldn't update the headshot, check if file size is greater than 10MB", 'danger');
        break;

      case 'updateLeadAvatarDone':
        setTimeout(() => {
          LeadDrawerActions.loadLead(lead.id);
        });
        break;

      default:
        break;
    }
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    localStorage.isSocialLinksOpen = !isOpen;
  }

  onLinkServiceNameChange(v, attr) {
    this.setState({
      [attr]: {
        ...this.state[attr],
        service_name: v.value,
      },
    });
  }

  onLinkServiceURLChange(v, attr) {
    this.setState({
      [attr]: {
        ...this.state[attr],
        service_url: v.target.value,
      },
    });
  }

  addAvatar(e) {
    const { lead } = this.props;
    const file = e.target.files[0];

    if (!file) return;

    LeadDrawerActions.updateAvatar({ id: lead.id, photo_url: file });
    e.target.value = null;
  }

  removeAvatar() {
    const { lead } = this.props;

    LeadDrawerActions.removeAvatar({ id: lead.id, remove_photo_url: true });
  }

  updateFromFullContact() {
    const { lead } = this.props;

    LeadLinkActions.updateFromFullContact(lead.id);
  }

  initialiseState(extraState = {}) {
    const { links } = this.state;

    this.setState(LeadLinkActions.loadLeadLinks(links));
  }

  createLink(e) {
    e.preventDefault();

    const { lead } = this.props;
    const { newLink } = this.state;

    LeadLinkActions.createLeadLink(lead.id, newLink);
  }

  deleteLink(linkId) {
    const { lead } = this.props;

    LeadLinkActions.deleteLeadLink(lead.id, linkId);
  }

  updateLink(e) {
    e.preventDefault();

    const { lead } = this.props;
    const { editLink } = this.state;

    LeadLinkActions.updateLeadLink(lead.id, editLink);
  }

  showEditLink(link) {
    const links = [...this.state.links].map((l) => {
      if (l.id === link.id) {
        return { ...link, showEdit: true, showText: false };
      }
      return { ...l, showEdit: false, showText: true };
    });

    const editLink = { id: link.id, service_name: link.service_name, service_url: link.service_url };

    this.setState({ ...this.state, links, editLink });
  }

  renderLinks() {
    const { lead } = this.props;
    const { links } = this.state;

    if (links) {
      if (Object.keys(links).length > 0) {
        const renderedLinks = _lodash.map(links, (e) => (
          <React.Fragment key={e.id}>
            {!e.showEdit && (
              <>
                <div key={e.id} className="col-10 m-0 p-0 mb-3">
                  <div className="row py-1">
                    <div className="col-3 m-0 p-0">
                      {_lodash.startCase(_lodash.toLower(e.service_name))}
                      :
                    </div>

                    <div className="col-9 m-0 p-0 text-sm text-truncate my-auto">
                      <a className="text-black" target="_blank" href={e.service_url} rel="noopener noreferrer">
                        {e.service_url}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-2 m-0 p-0 mb-3 d-flex align-items-center justify-content-end">
                  <div className="pull-right">
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.showEditLink(e)}>
                      <FontAwesomeIcon icon={['far', 'fa-edit']} />
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.deleteLink(e)}>
                      <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
                    </button>
                  </div>
                </div>
              </>
            )}

            {e.showEdit && this.renderEditLink()}
          </React.Fragment>
        ));

        return (
          <div className="row p-0 m-0">
            {this.renderAddLink()}
            {renderedLinks}
          </div>
        );
      }

      return (
        <div className="row p-0 m-0">
          {this.renderAddLink()}
          <p>No social profiles found</p>
        </div>
      );
    }

    return null;
  }

  renderAddLink() {
    const { showAddLink, newLink } = this.state;

    if (!showAddLink) return null;

    return (
      <div className="col-12 p-0 mb-3">
        <form className="form-inline w-100" onSubmit={this.createLink}>
          <div className="col-lg-3 m-0 p-0 text-sm">
            <Select
              name="service_name"
              className="m-0 p-0 social-dropdown"
              placeholder="Link Type"
              value={newLink.service_name}
              options={SUPPORTED_SERVICES}
              onChange={(e) => this.onLinkServiceNameChange(e, 'newLink')}
              required
            />
          </div>
          <div className="col-lg-7 m-0 p-0 text-sm">
            <div className="form-group form-group-sm">
              <input
                type="url"
                className="form-control w-100 py-1"
                required
                name="service_url"
                value={newLink.service_url}
                onChange={(e) => this.onLinkServiceURLChange(e, 'newLink')}
              />
            </div>
          </div>
          <div className="col-lg-2 m-0 p-0">
            <div className="pull-right">
              <button type="submit" className="btn btn-secondary btn-sm">
                <FontAwesomeIcon icon="far fa-check-circle" />
              </button>
              <button type="button" className="btn btn-secondary btn-sm px-2" onClick={(e) => this.setState(this.initialiseState())}>
                <FontAwesomeIcon icon="fas fa-times" />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderEditLink() {
    const { editLink } = this.state;

    return (
      <form key={editLink.id} onSubmit={this.updateLink} className="form-inline w-100 mb-3">
        <div className="col-lg-3 m-0 p-0 text-sm">
          <Select
            name="service_name"
            className="m-0 p-0 social-dropdown"
            placeholder="Link Type"
            value={editLink.service_name}
            options={SUPPORTED_SERVICES}
            onChange={(e) => this.onLinkServiceNameChange(e, 'editLink')}
            required
          />
        </div>
        <div className="col-lg-7 m-0 p-0 text-sm">
          <div className="form-group form-group-sm">
            <input
              type="url"
              className="form-control w-100 py-1"
              name="service_url"
              value={editLink.service_url}
              onChange={(e) => this.onLinkServiceURLChange(e, 'editLink')}
            />
          </div>
        </div>
        <div className="col-lg-2 m-0 p-0">
          <div className="pull-right">
            <button type="submit" className="btn btn-secondary btn-sm">
              <FontAwesomeIcon icon="far fa-check-circle" />
            </button>
            <button type="button" className="btn btn-secondary btn-sm px-2" onClick={this.initialiseState}>
              <FontAwesomeIcon icon="fas fa-times" />
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderUpdateButton() {
    const { loading, avatarFile } = this.state;
    const { lead } = this.props;

    if (Rails.abilities.manageLead === false) return null;

    if (loading) {
      return (
        <button type="button" className="btn btn-secondary btn-sm disabled" onClick={this.updateFromFullContact}>
          <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
          Lookup Social Profiles
        </button>
      );
    }

    return (
      <div>
        <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={(e) => this.setState({ showAddLink: true })}>
          <FontAwesomeIcon icon={['fas', 'fa-plus']} className="mr5" />
          Links
        </button>
        {!lead.photo_url && (
        <button
          type="button"
          className="btn btn-secondary btn-sm mr-1"
          onClick={() => avatarFile.current.click()}
        >
          <FontAwesomeIcon icon={['fas', 'fa-plus']} className="mr5" />
          Headshot
        </button>
        )}
        <input type="file" ref={avatarFile} onChange={this.addAvatar} style={{ display: 'none' }} />

        <button type="button" className="btn btn-secondary btn-sm" onClick={this.updateFromFullContact}>
          <FontAwesomeIcon icon={['fas', 'fa-users']} className="mr5" />
          Lookup Social Profiles
        </button>
      </div>
    );
  }

  render() {
    const { isOpen } = this.state;
    const { lead } = this.props;

    return (
      <div>
        <div className="pull-right">
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.onCollapseClick}>
            {isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
        </div>

        <h4 className="mb15">Social and Web</h4>

        <Collapse isOpen={isOpen}>
          {lead.photo_url && (
          <div className="row p-0">
            <div className="col-4 p-0 mr-1">
              <img src={lead.photo_url} alt="Lead avatar" width="100%" className="rounded" />
            </div>
            <div className="col-2 d-flex p-0 flex-column align-items-start">
              <button type="button" className="btn btn-secondary btn-sm" onClick={(e) => this.state.avatarFile.current.click()}>
                <FontAwesomeIcon icon={['far', 'fa-edit']} />
              </button>
              <button type="button" className="btn btn-secondary btn-sm" style={{ padding: '6px 6px 3px 7px' }} onClick={this.removeAvatar}>
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </div>
          </div>
          )}
          <div className="mt10">{this.renderUpdateButton()}</div>
          <div className="clearfix" />
          <div className="mt-3" style={{ marginBottom: '7rem' }}>
            {this.renderLinks()}
          </div>
        </Collapse>
      </div>
    );
  }
}

LeadDrawerSocialLinks.defaultProps = {
  lead: null,
};

LeadDrawerSocialLinks.propTypes = {
  lead: PropTypes.shape({}),
};

export default LeadDrawerSocialLinks;
