import { ReduceStore }             from 'flux/utils';
import AppDispatcher               from '~/dispatchers/app_dispatcher';
import BusinessContactsActionTypes from '~/types/business_contacts_action_types';

class BusinessContactsStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      authorized_representative_1:     {},
      authorized_representative_2:     {},
      lastBusinessContactsStoreAction: null,
      errors:                          {},
      creating:                        false,
      saving:                          false,
    };
  }

  createContacts(state, action) {
    const newState = { ...state };

    newState.lastBusinessContactsStoreAction = 'createContacts';
    newState.creating = true;

    return newState;
  }

  createContactsDone(state, action) {
    const newState = { ...state };

    newState.authorized_representative_1 = action.contacts.authorized_representative_1;
    newState.authorized_representative_2 = action.contacts.authorized_representative_2;
    newState.lastBusinessContactsStoreAction = 'createContactsDone';
    newState.creating = false;
    newState.errors = {};
    newState.tabName = action.tabName;

    return newState;
  }

  createContactsFail(state, action) {
    const newState = { ...state };

    newState.creating = false;
    newState.errors   = action.error.response.body.errors;
    newState.lastBusinessContactsStoreAction = 'createContactsFail';

    return newState;
  }

  updateContacts(state, action) {
    const newState = { ...state };

    newState.lastBusinessContactsStoreAction = 'updateContacts';
    newState.saving = true;

    return newState;
  }

  updateContactsDone(state, action) {
    const newState = { ...state };

    newState.authorized_representative_1 = action.contacts.authorized_representative_1 || {};
    newState.authorized_representative_2 = action.contacts.authorized_representative_2 || {};
    newState.saving = false;
    newState.errors = {};
    newState.lastBusinessContactsStoreAction = 'updateContactsDone';
    newState.tabName = action.tabName;

    return newState;
  }

  updateContactsFail(state, action) {
    const newState = { ...state };

    newState.saving = false;
    newState.errors = action.error.response.body.errors;
    newState.lastBusinessContactsStoreAction = 'updateContactsFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case BusinessContactsActionTypes.CREATE_BUSINESS_CONTACT:

        return this.createContacts(state, action);
      case BusinessContactsActionTypes.CREATE_BUSINESS_CONTACT_DONE:

        return this.createContactsDone(state, action);
      case BusinessContactsActionTypes.CREATE_BUSINESS_CONTACT_FAIL:

        return this.createContactsFail(state, action);
      case BusinessContactsActionTypes.UPDATE_BUSINESS_CONTACT:

        return this.updateContacts(state, action);
      case BusinessContactsActionTypes.UPDATE_BUSINESS_CONTACT_DONE:

        return this.updateContactsDone(state, action);
      case BusinessContactsActionTypes.UPDATE_BUSINESS_CONTACT_FAIL:

        return this.updateContactsFail(state, action);
      default:
        return state;
    }
  }
}

export default new BusinessContactsStore();
