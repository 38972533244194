import React, { useState, useEffect } from 'react';
import { PropTypes }                  from 'prop-types';
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome';
import { Collapse }                   from 'reactstrap';

import LeadLabelActions from '~/actions/lead_label_actions';
import LeadLabelStore   from '~/stores/lead_label_store';
import LabelingForm     from './forms/labeling_form';
import LeadDrawerLabel  from './details/lead_drawer_label';

const LeadDrawerLabels = ({ lead }) => {
  const canManageLead = Rails.abilities.manageLead;
  const isOpenLocalStorage = localStorage.isDetailsOpen;
  const [isOpen, setIsOpen] = useState(isOpenLocalStorage ? JSON.parse(isOpenLocalStorage) : false);
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const loadLabels = async () => {
      await LeadLabelActions.loadLeadLabels(lead.id);
    };

    setTimeout(loadLabels, 0);

    const labelListener = LeadLabelStore.addListener(() => {
      const state = LeadLabelStore.getState();

      setLabels(state.leadLabels);
      setIsLoading(state.loadingLeadLabels);
      setErrors(state.errors);

      switch (state.lastLeadLabelStoreAction) {
        case 'createLeadLabelDone':
        case 'deleteLeadLabelDone':
          setTimeout(loadLabels, 0);
          break;
        default:
          break;
      }
    });

    return () => labelListener.remove();
  }, [lead.id]);

  const renderLoading = () => (
    <div className="text-center card-block">
      <FontAwesomeIcon
        icon="far fa-spinner"
        pulse
      />
    </div>
  );

  const onCollapseClick = (e) => {
    e.preventDefault();

    setIsOpen(!isOpen);
    localStorage.isDetailsOpen = !isOpen;
  };

  const renderLeadLabels = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (labels.length === 0) {
      return (
        <div className="text-grey-dark card-block">
          No labels
        </div>
      );
    }

    return labels.map((label) => (
      <LeadDrawerLabel
        lead={lead}
        label={label}
        key={`label-${label.id}`}
      />
    ));
  };

  return (
    <div>
      <h3 className="mb5">
        Labels
        <button type="button" className="btn btn-secondary btn-sm mr-1 pull-right" onClick={onCollapseClick}>
          { isOpen ? (
            <FontAwesomeIcon icon={['far', 'fa-minus']} />
          ) : (
            <FontAwesomeIcon icon={['far', 'fa-plus']} />
          )}
        </button>
      </h3>
      <Collapse isOpen={isOpen}>
        <div className="mr25 ml30 float-right timeline-label">
          {canManageLead && <LabelingForm leadID={lead.id} />}
        </div>
        <div className="labels mt10">{renderLeadLabels()}</div>
      </Collapse>
    </div>
  );
};

LeadDrawerLabels.defaultProps = {
  lead: null,
};

LeadDrawerLabels.propTypes = {
  lead: PropTypes.shape({}),
};

export default LeadDrawerLabels;
