import React     from 'react';
import PropTypes from 'prop-types';
import autoBind  from 'react-autobind';
import { Link }  from 'react-router-dom';

import Select           from '~/components/forms/select';
import ClickableTooltip from '~/components/effects/clickable_tooltip';

import MlsActions from '~/actions/mls_actions';
import MlsStore   from '~/stores/mls_store';

class ActivatedMlsesSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingOptions: true,
      options:        [],
      value:          props.value,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.mlsStoreListener = MlsStore.addListener(this.onMlsStoreChange);

    MlsActions.loadActivatedTeamMlsesOptions();
  }

  componentWillUnmount() {
    if (this.mlsStoreListener) this.mlsStoreListener.remove();
  }

  onMlsStoreChange() {
    const mlsStoreState = MlsStore.getState();
    const {
      loadingOptions, options,
    } = mlsStoreState;
    let selectOptions = [];
    let newState;

    if (options) {
      selectOptions = options.map((mls) => ({
        label: mls.mls_name,
        value: mls.id,
        id:    mls.id,
      }));
    }

    newState = {
      loadingOptions,
      options: selectOptions,
    };

    this.setState(newState);
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : '';
    const { onChange } = this.props;

    this.setState({ value }, () => {
      onChange(value);
    });
  }

  render() {
    const {
      placeholder, clearable, className, target,
    } = this.props;
    const { value, loadingOptions, options } = this.state;

    return (
      <>
        <Select
          className={className}
          clearable={clearable}
          placeholder={placeholder}
          value={value}
          onChange={this.handleSelectChange}
          options={options}
          isLoading={loadingOptions}
        />

        <ClickableTooltip
          text={(
            <>
              This dropdown will show MLSs with an active integration in the
              {' '}
              <a className="text-white" href="/data" target="_blank" rel="noopener noreferrer">MLS Settings tab.</a>
              {' '}
              Please add your MLS and activate an integration there and then you will be able to select it here.
            </>
          )}
          placement="right"
          target={target}
        />
      </>
    );
  }
}

ActivatedMlsesSelect.defaultProps = {
  onChange:    () => {},
  placeholder: 'Select a MLS...',
  style:       {},
  value:       null,
  clearable:   false,
  className:   '',
  target:      'mls-select',
};

ActivatedMlsesSelect.propTypes = {
  onChange:           PropTypes.func,
  placeholder:        PropTypes.string,
  style:              PropTypes.shape({}),
  value:              PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  clearable:          PropTypes.bool,
  className:          PropTypes.string,
  target:             PropTypes.string,
};

export default ActivatedMlsesSelect;
