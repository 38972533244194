import autoBind      from 'react-autobind';
import React         from 'react';
import ReactDOM      from 'react-dom';
import { PropTypes } from 'prop-types';

class AppModal extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  componentDidMount() {
    const $modal = $(this.modal);
    const { containerID } = this.props;

    $modal.on('hide.bs.modal', () => {
      ReactDOM.unmountComponentAtNode(document.getElementById(containerID));
    });

    $modal.modal({ keyboard: false, backdrop: 'static' });
  }

  componentWillUnmount() {
    $(this.modal).modal('hide');
  }

  render() {
    const {
      id, modalClass, dialogClass, contentClass, children,
    } = this.props;

    return (
      <div
        className={modalClass}
        id={id}
        ref={(modal) => this.modal = modal}
        role="dialog"
        aria-labelledby="appModalLabel"
        aria-hidden="true"
      >
        <div className={dialogClass} role="document">
          <div className={contentClass}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

AppModal.defaultProps = {
  containerID:  'primary-modal',
  id:           'modal-dialog',
  modalClass:   'modal',
  dialogClass:  'modal-dialog',
  contentClass: 'modal-content',
  children:     [],
};

AppModal.propTypes = {
  containerID:  PropTypes.string,
  id:           PropTypes.string,
  modalClass:   PropTypes.string,
  dialogClass:  PropTypes.string,
  contentClass: PropTypes.string,
  children:     PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
};

export default AppModal;
