import autoBind     from 'react-autobind';
import React        from 'react';
import PropTypes    from 'prop-types';
import NumberFormat from 'react-number-format';

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      name:  props.name,
      value: props.value || '',
      size:  props.size || null,
    };
  }

  onChange(obj, e) {
    const { onChange } = this.props;
    const newValue = obj.value;

    this.setState({ value: newValue }, () => {
      onChange(newValue);
    });
  }

  render() {
    const { value, size } = this.state;
    const { id } = this.props;

    return (
      <NumberFormat
        id={id}
        value={value}
        thousandSeparator
        prefix="$"
        className="form-control"
        allowNegative={false}
        isNumericString
        isAllowed={(vals, e) => (size ? vals.value.toString().length <= size : true)}
        onValueChange={this.onChange}
      />
    );
  }
}

CurrencyInput.defaultProps = {
  id:       null,
  name:     null,
  value:    null,
  size:     null,
  onChange: () => false,
};

CurrencyInput.propTypes = {
  id:       PropTypes.string,
  name:     PropTypes.string,
  value:    PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  size:     PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
};

export default CurrencyInput;
