import Moment from 'moment';

export const generateDateOptions = (startDate = Moment().startOf('month'), endDate = Moment().startOf('month').add(2, 'years')) => {
  const options = [];

  for (let d = Moment(startDate).startOf('month'); d.isBefore(endDate); d.add(1, 'month')) {
    options.push({ value: d.format('YYYY-MM-DD'), label: d.format('MMMM YYYY') });
  }

  return options;
};
