import AppDispatcher              from '~/dispatchers/app_dispatcher';
import APIRequest                 from '~/lib/api_request';
import CampaignLibraryActionTypes from '~/types/campaign_library_action_types';

let loadCampaignRequest;
let campaignsRequest;
let updateCampaignRequest;
let deleteCampaignRequest;
let loadCampaignTypesRequest;

const CampaignLibraryActions = {
  loadCampaign(campaignID, onDone = () => false, onFail = () => false) {
    APIRequest.abort(loadCampaignRequest);

    AppDispatcher.dispatch({
      type: CampaignLibraryActionTypes.LOAD_CAMPAIGN,
    });

    campaignsRequest = APIRequest.get({
      resource: `/v1/campaign_libraries/${campaignID}`,
    });

    campaignsRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  loadCampaigns({ currentPage = 1, scope = '', campaign_type = null } = {}) {
    APIRequest.abort(campaignsRequest);

    AppDispatcher.dispatch({
      type: CampaignLibraryActionTypes.LOAD_CAMPAIGNS,
    });

    const data = {
      page: currentPage, scope,
    };

    if (campaign_type) {
      data.campaign_type = campaign_type;
    }

    campaignsRequest = APIRequest.get({
      resource: '/v1/campaign_libraries',
      data,
    });

    campaignsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignLibraryActionTypes.LOAD_CAMPAIGNS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:               CampaignLibraryActionTypes.LOAD_CAMPAIGNS_DONE,
        campaign_libraries: response.body,
      });
    });
  },

  updateCampaign(campaign) {
    APIRequest.abort(updateCampaignRequest);

    AppDispatcher.dispatch({
      type: CampaignLibraryActionTypes.UPDATE_CAMPAIGN,
      campaign,
    });

    updateCampaignRequest = APIRequest.put({
      resource: `/v1/campaign_libraries/${campaign.id}`,
      data:     { campaign },
    });

    updateCampaignRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignLibraryActionTypes.UPDATE_CAMPAIGN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     CampaignLibraryActionTypes.UPDATE_CAMPAIGN_DONE,
        campaign: response.body,
      });
    });
  },

  deleteCampaign(campaign) {
    APIRequest.abort(deleteCampaignRequest);

    AppDispatcher.dispatch({
      type: CampaignLibraryActionTypes.DELETE_CAMPAIGN,
      campaign,
    });

    deleteCampaignRequest = APIRequest.delete({
      resource: `/v1/campaign_libraries/${campaign.id}`,
    });

    deleteCampaignRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignLibraryActionTypes.DELETE_CAMPAIGN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: CampaignLibraryActionTypes.DELETE_CAMPAIGN_DONE,
      });

      CampaignLibraryActions.loadCampaigns();
    });
  },

  loadCampaignTypes() {
    APIRequest.abort(loadCampaignTypesRequest);

    AppDispatcher.dispatch({
      type: CampaignLibraryActionTypes.LOAD_CAMPAIGN_TYPES,
    });

    loadCampaignTypesRequest = APIRequest.get({
      resource: '/v1/campaign_types',
    });

    loadCampaignTypesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignLibraryActionTypes.LOAD_CAMPAIGN_TYPES_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:          CampaignLibraryActionTypes.LOAD_CAMPAIGN_TYPES_DONE,
        campaignTypes: response.body,
      });
    });
  },
};

export default CampaignLibraryActions;
