import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class LeadBucketAndRoleSelect extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onChange(opt) {
    this.select.setState({ value: null }); // clear out the value so it doesn't stick
    this.props.onChange(opt);
  }

  getOptions() {
    const { role, lead } = this.props;

    const options = [];

    if (role) {
      switch (role.name) {
        case 'agent':
          options.push({ value: 'affiliate', label: 'Affiliate' });
          break;
        case 'affiliate':
          options.push({ value: 'agent', label: 'Agent' });
          break;
      }
    }

    if (lead) {
      if (lead.retention) {
        options.push({ value: 'recruiting', label: 'Recruiting' });
      }

      if (!lead.archived) {
        options.push({ value: 'archived', label: 'Archive' });
      }
    } else {
      options.push({ value: 'recruiting', label: 'Recruiting' });
      options.push({ value: 'archived', label: 'Archive' });
    }

    return options;
  }

  render() {
    return (
      <Select
        {...this.props}
        ref={(el) => (this.select = el)}
        value={null}
        onChange={this.onChange}
        options={this.getOptions()}
      />
    );
  }
}

LeadBucketAndRoleSelect.defaultProps = {
  name:        '',
  value:       '',
  placeholder: 'Move to ...',
  onChange:    () => false,
};

LeadBucketAndRoleSelect.propTypes = {
  name:        PropTypes.string,
  placeholder: PropTypes.string,
  value:       PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange:    PropTypes.func,
};

export default LeadBucketAndRoleSelect;
