import React from 'react';
import { Nav } from 'reactstrap';
import NavBarSupportedContent from './nav_bar_supported_content';
import HelpCenter from './help_center';
import NotificationButton from '~/components/Notifications/Button';

const DefaultNavContent = () => (
  <Nav className="ml-auto mr-auto-sm-down" navbar>
    <div className="d-flex">
      <NotificationButton />
      <HelpCenter />
    </div>
    <NavBarSupportedContent />
  </Nav>
);

DefaultNavContent.defaultProps = {};
DefaultNavContent.propTypes = {};

export default DefaultNavContent;
