import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityLeadUpdate extends TimelineActivity {}

TimelineActivityLeadUpdate.defaultProps = {
  title: (object) => {
    const {
      parameters: {
        name, company, emails, agent_ids,
      },
    } = object.props.activity;
    const options  = [];
    const company_title = _lodash.isEmpty(company) ? '' : `with ${company}`;
    const emailAdresses = _lodash.isEmpty(emails) ? null : options.unshift(`Email addresses: ${emails}`);
    const agentIds      = _lodash.isEmpty(agent_ids) ? null : options.unshift(`Agent IDs: ${agent_ids}`);
    const agentIdAndEmailTitle = _lodash.isEmpty(options) ? '' : `(${options.map((option) => option)})`;

    return (
      `${name} ${agentIdAndEmailTitle} ${company_title} was updated from a duplicate new contact`
    );
  },
  icon:           'sync',
  prefix:         'fas',
  alwaysPublic:   false,
};

export default TimelineActivityLeadUpdate;
