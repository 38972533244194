import React               from 'react';
import autoBind            from 'react-autobind';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest from '~/lib/api_request';

class SmsStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSms: true,
      loadingMms: true,
      smsStats:   {},
      mmsStats:   {},
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadSmsStats();
    this.loadMmsStats();
  }

  loadSmsStats = () => {
    APIRequest.get({ resource: '/v1/statistics/sms_messages' })
      .end((err, response) => {
        if (response && response.ok) {
          const smsStats = response.body;

          this.setState({ smsStats, loadingSms: false });
        } else {
          this.setState({ loadingSms: false });
        }
      });
  }

  loadMmsStats = () => {
    APIRequest.get({ resource: '/v1/statistics/mms_messages' })
      .end((err, response) => {
        if (response && response.ok) {
          const mmsStats = response.body;

          this.setState({ mmsStats, loadingMms: false });
        } else {
          this.setState({ loadingMms: false });
        }
      });
  }

  renderStatisticTable = () => {
    const {
      loadingSms,
      loadingMms,
      smsStats,
      mmsStats,
    } = this.state;

    if (loadingSms || loadingMms) {
      return (
        <tbody className="text-center">
          <tr>
            <td colSpan="5">
              <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        <tr>
          <th scope="row">Today</th>
          <td className="text-center">{smsStats.today}</td>
          <td className="text-center">{mmsStats.today}</td>
          <td className="text-center">{smsStats.segmentsToday}</td>
          <td className="text-center">{mmsStats.segmentsToday}</td>
        </tr>
        <tr>
          <th scope="row">This Week</th>
          <td className="text-center">{smsStats.thisWeek}</td>
          <td className="text-center">{mmsStats.thisWeek}</td>
          <td className="text-center">{smsStats.segmentsThisWeek}</td>
          <td className="text-center">{mmsStats.segmentsThisWeek}</td>
        </tr>
        <tr>
          <th scope="row">This Month</th>
          <td className="text-center">{smsStats.thisMonth}</td>
          <td className="text-center">{mmsStats.thisMonth}</td>
          <td className="text-center">{smsStats.segmentsThisMonth}</td>
          <td className="text-center">{mmsStats.segmentsThisMonth}</td>
        </tr>
        <tr>
          <th scope="row">Last Month</th>
          <td className="text-center">{smsStats.lastMonth}</td>
          <td className="text-center">{mmsStats.lastMonth}</td>
          <td className="text-center">{smsStats.segmentsLastMonth}</td>
          <td className="text-center">{mmsStats.segmentsLastMonth}</td>
        </tr>
      </tbody>
    );
  }

  render() {
    return (
      <div className="table-responsive col-md-12 col-lg-6">
        <table className="table table-bordered statistic-table">
          <thead>
            <tr>
              <th scope="col" rowSpan="2" className="text-center">
                Text Messages
                <br />
                Sent & Received
              </th>
              <th scope="col" colSpan="2" className="text-center">Messages</th>
              <th scope="col" colSpan="2" className="text-center">Segments</th>
            </tr>
            <tr>
              <th scope="col" className="text-center">SMS</th>
              <th scope="col" className="text-center">MMS</th>
              <th scope="col" className="text-center">SMS</th>
              <th scope="col" className="text-center">MMS</th>
            </tr>
          </thead>

          {this.renderStatisticTable()}
        </table>
      </div>
    );
  }
}

SmsStats.defaultProps = {};

SmsStats.propTypes = {};

export default SmsStats;
