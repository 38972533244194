import qs                   from 'qs';
import classNames           from 'classnames';
import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer        from '~/components/global_container';
import CampaignTriggerActions from '~/actions/campaign_trigger_actions';
import Tooltipable            from '~/components/effects/tooltipable';

class CampaignTriggerItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      settingStatus:   false,
      campaignTrigger: props.campaignTrigger,
    };
  }

  handlePlayClick(e) {
    e.preventDefault();
    const { campaignTrigger } = this.state;

    CampaignTriggerActions.runCampaignTrigger(
      campaignTrigger.id,
      this.onStatusChange,
    );

    this.setState({
      settingStatus: true,
    });
  }

  handlePauseClick(e) {
    e.preventDefault();
    const { campaignTrigger } = this.state;

    CampaignTriggerActions.pauseCampaignTrigger(
      campaignTrigger.id,
      this.onStatusChange,
    );

    this.setState({
      settingStatus: true,
    });
  }

  onStatusChange(campaignTrigger) {
    this.setState({
      settingStatus: false,
      campaignTrigger,
    });
  }

  renderStatusControls() {
    const { campaignTrigger, settingStatus } = this.state;

    const buttonClass = classNames('btn btn-secondary btn-sm mr5', {
      disabled: settingStatus,
    });

    switch (campaignTrigger.status) {
      case 'running':
        return (
          <Tooltipable text="Pauses campaign trigger">
            <button
              type="button"
              className={buttonClass}
              disabled={settingStatus}
              onClick={this.handlePauseClick}
            >
              <FontAwesomeIcon icon={['fas', 'fa-pause']} />
            </button>
          </Tooltipable>
        );
      case 'paused':
      default:
        return (
          <Tooltipable text="Runs campaign trigger">
            <button
              type="button"
              className={buttonClass}
              disabled={settingStatus}
              onClick={this.handlePlayClick}
            >
              <FontAwesomeIcon icon={['fas', 'fa-play']} />
            </button>
          </Tooltipable>
        );
    }
  }

  render() {
    const {
      handleCampaignTriggerClick,
      handleCampaignTriggerDelete,
    } = this.props;
    const { campaignTrigger } = this.state;
    const campaign_triggers_edit_uri = GlobalContainer.productUri(
      `/campaign_triggers/${campaignTrigger.id}/edit`,
    );

    return (
      <tr
        key={`campaign-trigger-${campaignTrigger.id}`}
        className="text-grey"
        onClick={(e) => {
          e.preventDefault();
          if (e.target.nodeName === 'TD') { handleCampaignTriggerClick(campaignTrigger); }
        }}
      >
        <td className="col">
          <strong>
            <Link
              to={campaign_triggers_edit_uri}
              className="text-grey-dark text-no-underline"
            >
              {campaignTrigger.name}
            </Link>
          </strong>
        </td>
        <td className="col-2">
          <span className="pull-right">
            {this.renderStatusControls()}

            <Tooltipable text="Edit Campaign Trigger">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();
                  handleCampaignTriggerClick(campaignTrigger);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-edit']} />
              </button>
            </Tooltipable>

            <Tooltipable text="Deletes campaign trigger">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleCampaignTriggerDelete(campaignTrigger);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </Tooltipable>
          </span>
        </td>
      </tr>
    );
  }
}

CampaignTriggerItem.defaultProps = {
  handleCampaignTriggerClick:  () => false,
  handleCampaignTriggerDelete: () => false,
};

CampaignTriggerItem.propTypes = {
  history:                     PropTypes.shape({}).isRequired,
  campaignTrigger:             PropTypes.shape({}).isRequired,
  handleCampaignTriggerClick:  PropTypes.func,
  handleCampaignTriggerDelete: PropTypes.func,
};

export default withRouter(CampaignTriggerItem);
