import TeamMemberPicker from '~/components/forms/team_fields/team_member_picker';

class ReferrerPicker extends TeamMemberPicker {}

ReferrerPicker.defaultProps = {
  apiResource: '/v1/team/referrers_options',
  key:         'ownerPicker',
};

export default ReferrerPicker;
