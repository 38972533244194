import { ReduceStore }  from 'flux/utils';
import AppDispatcher    from '~/dispatchers/app_dispatcher';
import VideoActionTypes from '~/types/video_action_types';

class VideoStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      lastVideoStoreAction: null,
      errors:               {},
      submitting:           false,
      loadingVideos:        false,
      videos:               [],
      loadingVideo:         false,
      video:                null,
      pagination:           {},
      videosLimit:          0,
      countOfVideos:        0,
    };
  }

  loadVideos(state, action) {
    const newState = { ...state };

    newState.loadingVideos = true;
    newState.lastVideoStoreAction = 'loadVideos';

    return newState;
  }

  loadVideosDone(state, action) {
    const newState = { ...state };

    newState.loadingVideos = false;
    newState.videos = action.videos;
    newState.videosLimit = action.videos_limit;
    newState.countOfVideos = action.count_of_videos;
    newState.pagination = action.pagination;
    newState.lastVideoStoreAction = 'loadVideosDone';

    return newState;
  }

  loadVideosFail(state, action) {
    const newState = { ...state };

    newState.loadingVideos = false;
    newState.lastVideoStoreAction = 'loadVideosFail';

    return newState;
  }

  loadVideo(state, action) {
    const newState = { ...state };

    newState.loadingVideo = true;
    newState.lastVideoStoreAction = 'loadVideo';

    return newState;
  }

  loadVideoDone(state, action) {
    const newState = { ...state };

    newState.loadingVideo = false;
    newState.video = action.video;
    newState.lastVideoStoreAction = 'loadVideoDone';

    return newState;
  }

  loadVideoFail(state, action) {
    const newState = { ...state };

    newState.loadingVideo = false;
    newState.errors = action.errors;
    newState.lastVideoStoreAction = 'loadVideoFail';

    return newState;
  }

  createVideo(state, action) {
    const newState = { ...state };

    newState.submitting = true;
    newState.lastVideoStoreAction = 'createVideo';

    return newState;
  }

  createVideoDone(state, action) {
    const newState = { ...state };

    newState.errors = {};
    newState.submitting = false;
    newState.video  = action.video;
    newState.lastVideoStoreAction = 'createVideoDone';

    return newState;
  }

  createVideoFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.errors = action.error.response.body.errors;
    newState.lastVideoStoreAction = 'createVideoFail';

    return newState;
  }

  updateVideo(state, action) {
    const newState = { ...state };

    newState.submitting = true;
    newState.lastVideoStoreAction = 'updateVideo';

    return newState;
  }

  updateVideoDone(state, action) {
    const newState = { ...state };

    newState.errors = {};
    newState.submitting = false;
    newState.video  = action.video;
    newState.lastVideoStoreAction = 'updateVideoDone';

    return newState;
  }

  updateVideoFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.errors = action.error.response.body.errors;
    newState.lastVideoStoreAction = 'updateVideoFail';

    return newState;
  }

  deleteVideo(state, action) {
    const newState = { ...state };

    newState.lastVideoStoreAction = 'deleteVideo';

    return newState;
  }

  deleteVideoDone(state, action) {
    const newState = { ...state };

    newState.errors = {};
    newState.lastVideoStoreAction = 'deleteVideoDone';

    return newState;
  }

  deleteVideoFail(state, action) {
    const newState = { ...state };

    newState.lastVideoStoreAction = 'deleteVideoFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case VideoActionTypes.LOAD_VIDEOS:
        return this.loadVideos(state, action);

      case VideoActionTypes.LOAD_VIDEOS_DONE:
        return this.loadVideosDone(state, action);

      case VideoActionTypes.LOAD_VIDEOS_FAIL:
        return this.loadVideosFail(state, action);

      case VideoActionTypes.LOAD_VIDEO:
        return this.loadVideo(state, action);

      case VideoActionTypes.LOAD_VIDEO_DONE:
        return this.loadVideoDone(state, action);

      case VideoActionTypes.LOAD_VIDEO_FAIL:
        return this.loadVideoFail(state, action);

      case VideoActionTypes.CREATE_VIDEO:
        return this.createVideo(state, action);

      case VideoActionTypes.CREATE_VIDEO_DONE:
        return this.createVideoDone(state, action);

      case VideoActionTypes.CREATE_VIDEO_FAIL:
        return this.createVideoFail(state, action);

      case VideoActionTypes.UPDATE_VIDEO:
        return this.updateVideo(state, action);

      case VideoActionTypes.UPDATE_VIDEO_DONE:
        return this.updateVideoDone(state, action);

      case VideoActionTypes.UPDATE_VIDEO_FAIL:
        return this.updateVideoFail(state, action);

      case VideoActionTypes.DELETE_VIDEO:
        return this.deleteVideo(state, action);

      case VideoActionTypes.DELETE_VIDEO_DONE:
        return this.deleteVideoDone(state, action);

      case VideoActionTypes.DELETE_VIDEO_FAIL:
        return this.deleteVideoFail(state, action);

      default:
        return state;
    }
  }
}

export default new VideoStore();
