import React, {
  useRef, forwardRef, useImperativeHandle
} from 'react';

import PropTypes        from 'prop-types';
import { DirectUpload } from '@rails/activestorage';
import DocumentActions  from '~/actions/document_actions';
import {
  checkIfLimitExceeded
} from '~/helpers/document_helpers.jsx';

const MAX_FILE_SIZE = 26214400;
const MAX_FILE_SIZE_IN_MB = MAX_FILE_SIZE / (1024 * 1024);
const uploadUrl = `${Rails.apiUrl}/rails/active_storage/direct_uploads`;
const FILE_TYPE_MAP = {
  pdf:  'pdf_document',
  doc:  'word_document',
  docx: 'word_document',
  jpg:  'image_document',
  jpeg: 'image_document',
  png:  'image_document',
  ppt:  'powerpoint_document',
  pptx: 'powerpoint_document',
  xls:  'excel_document',
  xlsx: 'excel_document',
  tiff: 'other_document',
  txt:  'other_document',
  rtf:  'other_document',
  csv:  'other_document',
};
const SUPPORTED_FILE_FORMATS = Object.keys(FILE_TYPE_MAP);

const DocumentUploader = forwardRef((
  {
    onUploadComplete, onLoadingStateChange, setShowLimitAlert, leadID,
  }, ref,
) => {
  const hiddenFileInput = useRef(null);

  useImperativeHandle(ref, () => ({
    handleFileSelect() {
      hiddenFileInput.current.value = '';
      hiddenFileInput.current.click();
    },
  }));

  const checkFileTypeAndSize = (file) => {
    const fileType = file.name.split('.').pop();
    const isFileTypeValid = SUPPORTED_FILE_FORMATS.includes(fileType.toLowerCase());
    const isSizeWithinLimit = file.size <= MAX_FILE_SIZE;

    if (!file) return null;

    if (!isFileTypeValid) {
      GlobalContainer.notify(`Unsupported File Format: ${fileType}`, 'error');
      onLoadingStateChange(false);
      return null;
    }

    if (!isSizeWithinLimit) {
      GlobalContainer.notify(
        `File Size Limit Exceeded: Ensure your file is within ${MAX_FILE_SIZE_IN_MB} megabytes`,
        'error',
      );
      onLoadingStateChange(false);
      return null;
    }

    return fileType;
  };

  const getDocumentType = (fileType) => FILE_TYPE_MAP[fileType] || null;

  const uploadDocuments = (files) => {
    const documentsData = [];
    onLoadingStateChange(true);

    files.forEach((file) => {
      const fileType = checkFileTypeAndSize(file);
      if (!fileType) return;

      const upload = new DirectUpload(file, uploadUrl, {
        directUploadWillCreateBlobWithXHR: (xhr) => {
          xhr.setRequestHeader('Authentication', `${Rails.helpers.authToken}`);
        },
      });

      upload.create(async (error, blob) => {
        if (!error) {
          const documentType = getDocumentType(fileType);
          const documentData = {
            document_file: blob.signed_id,
            title:         file.name,
            document_type: documentType,
            lead_id:       leadID,
          };
          documentsData.push(documentData);

          if (documentsData.length === files.length) {
            try {
              await DocumentActions.createDocument(documentsData, leadID);
              if (onUploadComplete) onUploadComplete();
            } catch (err) {
              const errorMsg = err.response?.body?.errors?.[0]?.errors?.[0] || 'An error occurred while uploading the document';
              GlobalContainer.notify(errorMsg, 'error');
            } finally {
              onLoadingStateChange(false);
            }
          }
        } else {
          GlobalContainer.notify('Error uploading the file. Please try again.', 'error');
          onLoadingStateChange(false);
        }
      });
    });
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      checkIfLimitExceeded(files.length).then((isLimitExceeded) => {
        if (isLimitExceeded) {
          setShowLimitAlert(true);
        } else {
          uploadDocuments(files);
        }
      });
    }
  };

  return (
    <input
      type="file"
      ref={hiddenFileInput}
      style={{ display: 'none' }}
      onChange={handleFileChange}
      multiple
    />
  );
});

DocumentUploader.defaultProps = {
  onUploadComplete: null,
  setShowLimitAlert: () => {},
};

DocumentUploader.propTypes = {
  onUploadComplete: PropTypes.func,
  onLoadingStateChange: PropTypes.func.isRequired,
  setShowLimitAlert: PropTypes.func,
};

export default DocumentUploader;
