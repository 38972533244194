import AppDispatcher          from '~/dispatchers/app_dispatcher';
import APIRequest             from '~/lib/api_request';
import AppointmentActionTypes from '~/types/appointment_action_types';

let loadAppointmentRequest;
let deleteAppointmentRequest;

const AppointmentActions = {
  loadAppointments(options = {}) {
    APIRequest.abort(loadAppointmentRequest);

    AppDispatcher.dispatch({
      type: AppointmentActionTypes.LOAD_APPOINTMENTS,
    });

    loadAppointmentRequest = APIRequest.get({
      resource: '/v1/appointments',
      data:     {
        lead_scope: options.ownerScope,
      },
    });

    loadAppointmentRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: AppointmentActionTypes.LOAD_APPOINTMENTS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:         AppointmentActionTypes.LOAD_APPOINTMENTS_DONE,
        appointments: response.body,
      });
    });
  },

  deleteAppointment(apptID) {
    APIRequest.abort(deleteAppointmentRequest);

    AppDispatcher.dispatch({
      type: AppointmentActionTypes.DELETE_APPOINTMENT,
    });

    deleteAppointmentRequest = APIRequest.delete({
      resource: `/v1/appointments/${apptID}`,
    });

    deleteAppointmentRequest.end((error, response) => {
      if (error) return;

      AppDispatcher.dispatch({
        type: AppointmentActionTypes.DELETE_APPOINTMENT_DONE,
      });

      setTimeout(() => {
        AppointmentActions.loadAppointments();
      }, 500);
    });
  },
};

export default AppointmentActions;
