import { ReduceStore }    from 'flux/utils';
import AppDispatcher      from '~/dispatchers/app_dispatcher';
import TeamMlsActionTypes from '~/types/team_mls_action_types';

class MlsStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loading:                false,
      lastTeamMlsStoreAction: null,
      errors:                 {},
      team_mlses:             [],
      teamMls:                {},
      updating:               false,
    };
  }

  loadTeamMlses(state, action) {
    const newState = { ...state };

    newState.loading = true;
    newState.lastTeamMlsStoreAction = 'loadTeamMlses';

    return newState;
  }

  loadTeamMlsesDone(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.team_mlses = action.response;
    newState.lastTeamMlsStoreAction = 'loadTeamMlsesDone';

    return newState;
  }

  loadTeamMlsesFail(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.lastTeamMlsStoreAction = 'loadTeamMlsesFail';

    return newState;
  }

  updateTeamMlses(state, action) {
    const newState = { ...state };

    newState.updating = true;
    newState.errors = {};
    newState.lastTeamMlsStoreAction = 'updateTeamMlses';

    return newState;
  }

  updateTeamMlsesDone(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.team_mlses = action.response;
    newState.errors = {};
    newState.lastTeamMlsStoreAction = 'updateTeamMlsesDone';

    return newState;
  }

  updateTeamMlsesFail(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.errors = action.error.response.body.errors;
    newState.lastTeamMlsStoreAction = 'updateTeamMlsesFail';

    return newState;
  }

  updateTeamMls(state, action) {
    const newState = { ...state };

    newState.updating = true;
    newState.errors = {};
    newState.lastTeamMlsStoreAction = 'updateTeamMls';

    return newState;
  }

  updateTeamMlsDone(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.teamMls = action.response;
    newState.errors = {};
    newState.lastTeamMlsStoreAction = 'updateTeamMlsDone';

    return newState;
  }

  updateTeamMlsFail(state, action) {
    const newState = { ...state };

    newState.updating = false;
    newState.errors = action.error.response.body.errors;
    newState.lastTeamMlsStoreAction = 'updateTeamMlsFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case TeamMlsActionTypes.LOAD_TEAM_MLSES:
        return this.loadTeamMlses(state, action);

      case TeamMlsActionTypes.LOAD_TEAM_MLSES_DONE:
        return this.loadTeamMlsesDone(state, action);

      case TeamMlsActionTypes.LOAD_TEAM_MLSES_FAIL:
        return this.loadTeamMlsesFail(state, action);

      case TeamMlsActionTypes.UPDATE_TEAM_MLSES:
        return this.updateTeamMlses(state, action);

      case TeamMlsActionTypes.UPDATE_TEAM_MLSES_DONE:
        return this.updateTeamMlsesDone(state, action);

      case TeamMlsActionTypes.UPDATE_TEAM_MLSES_FAIL:
        return this.updateTeamMlsesFail(state, action);

      case TeamMlsActionTypes.UPDATE_TEAM_MLS:
        return this.updateTeamMls(state, action);

      case TeamMlsActionTypes.UPDATE_TEAM_MLS_DONE:
        return this.updateTeamMlsDone(state, action);

      case TeamMlsActionTypes.UPDATE_TEAM_MLS_FAIL:
        return this.updateTeamMlsFail(state, action);

      default:
        return state;
    }
  }
}

export default new MlsStore();
