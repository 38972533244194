import MlsServiceRequest      from '~/lib/mls_service_request';
import AppDispatcher          from '~/dispatchers/app_dispatcher';
import SearchQueryActionTypes from '~/types/search_query_action_types';
import GlobalContainer from '~/components/global_container';

let searchQueriesRequest;
let loadSearchQueryRequest;
let createSearchQueryRequest;
let updateSearchQueryRequest;
let deleteSearchQueryRequest;

const SearchQueryActions = {
  loadSearchQueries() {
    MlsServiceRequest.abort(searchQueriesRequest);

    AppDispatcher.dispatch({
      type: SearchQueryActionTypes.LOAD_SEARCH_QUERIES,
    });

    searchQueriesRequest = MlsServiceRequest.get({
      resource: '/search_queries',
    });

    searchQueriesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: SearchQueryActionTypes.LOAD_SEARCH_QUERIES_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:          SearchQueryActionTypes.LOAD_SEARCH_QUERIES_DONE,
        searchQueries: response.body,
      });
    });
  },

  loadSearchQuery(searchQueryID, onDone = () => false, onFail = () => false) {
    MlsServiceRequest.abort(loadSearchQueryRequest);

    AppDispatcher.dispatch({
      type: SearchQueryActionTypes.LOAD_SEARCH_QUERY,
    });

    loadSearchQueryRequest = MlsServiceRequest.get({
      resource: `/search_queries/${searchQueryID}`,
    });

    loadSearchQueryRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  createSearchQuery(searchQuery) {
    MlsServiceRequest.abort(createSearchQueryRequest);

    AppDispatcher.dispatch({
      type: SearchQueryActionTypes.CREATE_SEARCH_QUERY,
      searchQuery,
    });

    createSearchQueryRequest = MlsServiceRequest.post({
      resource: '/search_queries',
      data:     { search_query: searchQuery },
    });

    createSearchQueryRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: SearchQueryActionTypes.CREATE_SEARCH_QUERY_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        SearchQueryActionTypes.CREATE_SEARCH_QUERY_DONE,
        searchQuery: response.body,
      });
    });
  },

  updateSearchQuery(searchQuery) {
    MlsServiceRequest.abort(updateSearchQueryRequest);

    AppDispatcher.dispatch({
      type: SearchQueryActionTypes.UPDATE_SEARCH_QUERY,
      searchQuery,
    });

    updateSearchQueryRequest = MlsServiceRequest.put({
      resource: `/search_queries/${searchQuery.id}`,
      data:     { search_query: searchQuery },
    });

    updateSearchQueryRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: SearchQueryActionTypes.UPDATE_SEARCH_QUERY_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        SearchQueryActionTypes.UPDATE_SEARCH_QUERY_DONE,
        searchQuery: response.body,
      });
    });
  },

  deleteSearchQuery(searchQuery) {
    MlsServiceRequest.abort(deleteSearchQueryRequest);

    AppDispatcher.dispatch({
      type: SearchQueryActionTypes.DELETE_SEARCH_QUERY,
      searchQuery,
    });

    deleteSearchQueryRequest = MlsServiceRequest.delete({
      resource: `/search_queries/${searchQuery.id}`,
    });

    deleteSearchQueryRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: SearchQueryActionTypes.DELETE_SEARCH_QUERY_FAIL,
          error,
        });

        GlobalContainer.notify('Oops, something went wrong. Try again later.', 'error');

        return;
      }

      AppDispatcher.dispatch({
        type: SearchQueryActionTypes.DELETE_SEARCH_QUERY_DONE,
      });

      GlobalContainer.notify('Import Query will be deleted.');

      SearchQueryActions.loadSearchQueries();
    });
  },
};

export default SearchQueryActions;
