import React         from 'react';
import { PropTypes } from 'prop-types';

import DateTimePicker     from '~/components/forms/DateTimePicker';
import TaskStatusSelect   from '../../../forms/task_status_select';
import TaskPrioritySelect from '../../../forms/task_priority_select';
import TaskCategorySelect from '../../../forms/task_category_select';
import { TeammatePicker } from '../../../forms/team_member_picker';

const processPickerValue = (value) => {
  if (!value || value.length === 0) return null;

  return value;
};

const TaskSearchFilters = ({
  searchData,
  clearSearch,
  hideFilters,
  handleSingleSelectChange,
  handleDateSelectChange,
  handleMultiSelectChange,
}) => {
  const dueDateStart = processPickerValue(searchData.due_date_at_start);
  const dueDateEnd = processPickerValue(searchData.due_date_at_end);

  return (
    <div>
      <div className="form-group mb15">
        <a href="#hide-filters" onClick={hideFilters}>Hide advanced options</a>
      </div>

      <div className="row">
        <div className="col-md-4 mb15">
          <label htmlFor="task_status">Status</label>
          <TaskStatusSelect
            id="task_status"
            name="status"
            placeholder="Select Status"
            value={processPickerValue(searchData.status)}
            onChange={(selectedOptions) => {
              handleMultiSelectChange('status', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="task_priority">Priority</label>
          <TaskPrioritySelect
            id="task_priority"
            name="priority"
            placeholder="Select Priority"
            value={processPickerValue(searchData.priority)}
            onChange={(selectedOptions) => {
              handleMultiSelectChange('priority', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="input_category">Category</label>
          <TaskCategorySelect
            id="input_category"
            name="category"
            placeholder="Select Category"
            value={processPickerValue(searchData.category)}
            onChange={(selectedOptions) => {
              handleMultiSelectChange('category', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb15">
          <label htmlFor="task_owner_id">Owner</label>
          <TeammatePicker
            id="task_owner_id"
            name="owner_id"
            placeholder="Owner"
            value={processPickerValue(searchData.owner_id)}
            onChange={(selectedOptions) => {
              handleMultiSelectChange('owner_id', selectedOptions);
            }}
            multi
            blank
            clearable
          />
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="due_date_at_start">Due Date Start</label>
          <div className="form-group mr5">
            <DateTimePicker
              id="due_date_at_start"
              placeholder="Start"
              showClear
              format="MMMM D, YYYY"
              pickerType="date"
              value={Moment(dueDateStart)?.format('MMMM D, YYYY')}
              onChange={(date) => {
                handleDateSelectChange('due_date_at_start', date);
              }}
            />
          </div>
        </div>

        <div className="col-md-4 mb15">
          <label htmlFor="due_date_at_end">Due Date End</label>
          <div className="form-group mr5">
            <DateTimePicker
              id="due_date_at_end"
              placeholder="End"
              showClear
              format="MMMM D, YYYY"
              pickerType="date"
              value={Moment(dueDateEnd)?.format('MMMM D, YYYY')}
              onChange={(date) => {
                handleDateSelectChange('due_date_at_end', date);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <button type="button" className="btn btn-sm btn-secondary" onClick={() => { clearSearch(); }}>
            Clear search
          </button>
        </div>
      </div>
    </div>
  );
};

TaskSearchFilters.defaultProps = {
  searchData:               {},
  clearSearch:              () => false,
  handleSingleSelectChange: () => false,
  handleDateSelectChange:   () => false,
  handleMultiSelectChange:  () => false,
};

TaskSearchFilters.propTypes = {
  searchData:               PropTypes.shape({}),
  clearSearch:              PropTypes.func,
  hideFilters:              PropTypes.func.isRequired,
  handleSingleSelectChange: PropTypes.func,
  handleDateSelectChange:   PropTypes.func,
  handleMultiSelectChange:  PropTypes.func,
};

export default TaskSearchFilters;
