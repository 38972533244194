import TimelineActivityAppointment          from '~/components/drawers/timeline/activity_appointment';
import TimelineActivityAppointmentSent      from '~/components/drawers/timeline/activity_appointment_sent';
import TimelineActivityRetention            from '~/components/drawers/timeline/activity_retention';
import TimelineActivityNote                 from '~/components/drawers/timeline/activity_note';
import TimelineActivityMerge                from '~/components/drawers/timeline/activity_merge';
import TimelineActivityPhoneCall            from '~/components/drawers/timeline/activity_phone_call';
import TimelineActivityEmail                from '~/components/drawers/timeline/activity_email';
import TimelineActivityEmailSent            from '~/components/drawers/timeline/activity_email_sent';
import TimelineActivityStatus               from '~/components/drawers/timeline/activity_status';
import TimelineActivityBucket               from '~/components/drawers/timeline/activity_bucket';
import TimelineActivityTextMessage          from '~/components/drawers/timeline/activity_text_message';
import TimelineActivitySmsConversation      from '~/components/drawers/timeline/activity_sms_conversation';
import TimelineActivityCampaignSubscription from '~/components/drawers/timeline/activity_campaign_subscription';
import TimelineActivityReassign             from '~/components/drawers/timeline/activity_reassign';
import TimelineActivityTask                 from '~/components/drawers/timeline/activity_task';
import TimelineActivityReleasePhoneNumber   from '~/components/drawers/timeline/activity_release_phone_number';
import TimelineActivityLeadUpdate           from '~/components/drawers/timeline/activity_lead_update';
import TimelineActivityReferrerUpdate       from '~/components/drawers/timeline/activity_referrer_update';
import TimelineActivityResume               from '~/components/drawers/timeline/activity_resume';
import TimelineActivityMessageScheduled     from '~/components/drawers/timeline/activity_message_scheduled';
import TimelineActivityDocument             from '~/components/drawers/timeline/activity_document';

const ActivityComponentMap = {
  'lead.create_task':                  { Component: TimelineActivityTask, props: { color: 'text-green' } },
  'lead.destroy_task':                 { Component: TimelineActivityTask, props: { color: 'text-red' } },
  'lead.update_task':                  { Component: TimelineActivityTask, props: { color: 'text-green' } },
  'lead.status':                       { Component: TimelineActivityStatus },
  'lead.bucket':                       { Component: TimelineActivityBucket },
  'lead.note':                         { Component: TimelineActivityNote },
  'lead.merge':                        { Component: TimelineActivityMerge },
  'lead.reassign':                     { Component: TimelineActivityReassign },
  'lead.phone_call':                   { Component: TimelineActivityPhoneCall },
  'lead.text_message':                 { Component: TimelineActivityTextMessage },
  'lead.sms_conversation':             { Component: TimelineActivitySmsConversation },
  'lead.appointment_sent':             { Component: TimelineActivityAppointmentSent },
  'lead.appointment_saved':            { Component: TimelineActivityAppointmentSent, props: { title: 'Appointment Saved' } },
  'lead.appointment':                  { Component: TimelineActivityAppointment },
  'lead.retention':                    { Component: TimelineActivityRetention },
  'lead.email':                        { Component: TimelineActivityEmail },
  'lead.email_sent':                   { Component: TimelineActivityEmailSent },
  'lead.email_scheduled':   {
    Component: TimelineActivityMessageScheduled,
    props:     { messageType: 'email', editable: true, title: 'Scheduled Email' },
  },
  'lead.email_schedule_updated': {
    Component: TimelineActivityMessageScheduled,
    props:     { messageType: 'email', editable: true, title: 'Updated Scheduled Email' },
  },
  'lead.email_schedule_canceled':      {
    Component: TimelineActivityMessageScheduled,
    props:     {
      messageType: 'email', editable: false, title: 'Scheduled Email Canceled', deletable: false,
    },
  },
  'lead.campaign_email_sent':          { Component: TimelineActivityEmailSent, props: { title: 'Campaign Email Sent' } },
  'lead.campaign_subscription':        { Component: TimelineActivityCampaignSubscription },
  'lead.campaign_subscription_delete': { Component: TimelineActivityCampaignSubscription },
  'lead.release_phone_number':         { Component: TimelineActivityReleasePhoneNumber },
  'lead.update':                       { Component: TimelineActivityLeadUpdate },
  'lead.referrer_changed':             { Component: TimelineActivityReferrerUpdate },
  'lead.create_resume':                { Component: TimelineActivityResume, props: { resume_status: 'Resume Uploaded' } },
  'lead.update_resume':                { Component: TimelineActivityResume, props: { resume_status: 'Resume Updated' } },
  'lead.delete_resume':                { Component: TimelineActivityResume, props: { resume_status: 'Resume Deleted' } },
  'lead.create_document':              { Component: TimelineActivityDocument, props: { document_status: 'Document Uploaded' } },
  'lead.update_document':              { Component: TimelineActivityDocument, props: { document_status: 'Document Updated' } },
  'lead.delete_document':              { Component: TimelineActivityDocument, props: { document_status: 'Document Deleted' } },
  'lead.text_message_scheduled':       {
    Component: TimelineActivityMessageScheduled,
    props:     {
      messageType: 'sms_message',
      icon:        'comment',
      color:       'text-yellow',
      title:       'Scheduled Text',
    },
  },
  'lead.text_message_schedule_updated':       {
    Component: TimelineActivityMessageScheduled,
    props:     {
      messageType: 'sms_message',
      icon:        'comment',
      color:       'text-yellow',
      title:       'Updated Scheduled Text',
    },
  },
  'lead.text_message_schedule_canceled':      {
    Component: TimelineActivityMessageScheduled,
    props:     {
      messageType: 'sms_message',
      icon:        'comment',
      color:       'text-yellow',
      editable:    false,
      title:       'Scheduled Text Canceled',
      deletable:   false,
    },
  },
};

export default ActivityComponentMap;
