import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import APIRequest from '~/lib/api_request';

const PromptsList = ({ handlePromptClick, selectedType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let content;

  const fetchPrompts = useCallback((page) => {
    let isMounted = true;
    setIsLoading(true);
    APIRequest.get({
      resource: '/v1/ai_prompts',
      data:     {
        ai_prompt_type: selectedType,
        page,
      },
    }).end((error, response) => {
      if (isMounted) {
        if (!error) {
          setPrompts(response.body.prompts);
          setTotalPages(response.body.pagination?.total_pages);
        }
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [selectedType]);

  useEffect(() => {
    const cleanup = fetchPrompts(currentPage);
    return cleanup;
  }, [fetchPrompts, currentPage]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  if (isLoading) {
    content = (
      <div className="text-center mt-4">
        <FontAwesomeIcon icon="far fa-spinner" pulse />
      </div>
    );
  } else if (prompts.length === 0) {
    content = (
      <div className="text-center mt-4">
        <FontAwesomeIcon icon="far fa-frown" size="lg" />
        <div className="mt-2">No prompts found</div>
      </div>
    );
  } else {
    content = (
      <div className="suggestion-list">
        {prompts.map((prompt, index) => (
          <div key={prompt.id} className="flex-item mb-2">
            <div
              className="ai-suggestion-outline-success rounded px-2 py-1"
              onClick={() => handlePromptClick(prompt.prompt)}
            >
              {prompt.prompt}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="card mb-3">
      <div className="card-body py-2">
        <small className="text-gray">Ideas for you</small>

        <br />

        {content}

        {totalPages > 1 && !isLoading && (
          <ReactPaginate
            forcePage={currentPage - 1}
            pageCount={totalPages}
            pageRangeDisplayed={1}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-end"
            activeClassName="active"
            breakLabel="..."
            breakLinkClassName="page-link disabled"
            breakClassName="page-item"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            disabledClassName="disabled"
            onPageChange={handlePageChange}
            previousLabel="Previous"
            nextLabel="Next"
          />
        )}
      </div>
    </div>
  );
};

PromptsList.propTypes = {
  handlePromptClick: PropTypes.func.isRequired,
};

export default PromptsList;
