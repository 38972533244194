import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class BusinessTypeSelect extends React.Component {
  constructor(props) {
    super(props);

    // https://www.twilio.com/docs/sms/a2p-10dlc/onboarding-isv-api?code-sample=code-create-an-empty-secondary-customer-profile-bundle&code-language=Ruby&code-sdk-version=5.x#13-create-end-user-object-of-type-customer_profile_business_information
    const options = [
      { value: 'Sole Proprietorship',           label: 'Sole Proprietorship' },
      { value: 'Partnership',                   label: 'Partnership' },
      { value: 'Co-operative',                  label: 'Co-operative' },
      { value: 'Non-profit Corporation',        label: 'Non-profit' },
      { value: 'Corporation',                   label: 'Corporation' },
      { value: 'Limited Liability Corporation', label: 'Limited Liability Corporation' },
    ];

    this.state = {
      options,
    };

    autoBind(this);
  }

  render() {
    const { options } = this.state;
    const { value } = this.props;

    return (
      <Select
        {...this.props}
        value={value}
        onChange={this.props.onChange}
        options={options}
      />
    );
  }
}

BusinessTypeSelect.defaultProps = {
  onChange: () => {},
  value:    '',
};

BusinessTypeSelect.propTypes = {
  onChange: PropTypes.func,
  value:    PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
};

export default BusinessTypeSelect;
