import autoBind             from 'react-autobind';
import classNames           from 'classnames';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Input }            from 'reactstrap';
import PhoneInput           from 'react-phone-input-2';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import DuplicateContactCard from '~/components/duplicate_contact_card';
import ErrorMessageWithIcon from '~/components/forms/ErrorMessageWithIcon';

import brokerbit     from '~/lib/brokerbit';
import Select        from '~/components/forms/select';
import ErrorMessage  from '~/components/forms/ErrorMessage';
import CurrencyInput from '~/components/forms/currency_input';
import RoleSelect    from '~/components/forms/team_fields/role_select';
import LeadHelpers   from '~/helpers/lead_helpers';

import { ProductionLabelSelect } from '~/components/forms/lead_fields';
import MultipleListingServicesSelect from '~/components/forms/multiple_listing_services_select';
import AllowOrgDuplicateCheckBox from '~/components/forms/allow_org_duplicate_checkbox';
import {
  TeammatePicker,
} from '~/components/forms/team_member_picker';

const defaultEmailTypeOptions = [
  { value: 'office',   label: 'Office' },
  { value: 'personal', label: 'Personal' },
  { value: 'other',    label: 'Other' },
];

const { currentTeam } = Rails.helpers;
const teamID = currentTeam ? currentTeam.id : null;

class TeamMemberDrawerForm extends React.Component {
  constructor(props) {
    super(props);

    this.emptyEmail = {
      id:         null,
      email:      '',
      email_type: 'office',
      main:       false,
      team_id:    teamID,
      _destroy:   false,
    };

    this.emptyAgentID = {
      id:                            null,
      agent_id:                      '',
      multiple_listing_service_ouid: '',
      _destroy:                      false,
    };

    const { teamMember } = props;

    if (teamMember) {
      this.state = {
        id:                      teamMember.id,
        firstName:               teamMember.first_name,
        lastName:                teamMember.last_name,
        email:                   teamMember.email,
        role:                    teamMember.role,
        owner_id:                teamMember.lead ? teamMember.lead.owner_id : Rails.helpers.currentUser.id,
        agentId:                 teamMember.lead ? teamMember.lead.agent_id : '',
        phone:                   teamMember.lead ? teamMember.lead.phone : '',
        productionYtd:           teamMember.lead ? teamMember.lead.production_ytd : '',
        productionLtm:           teamMember.lead ? teamMember.lead.production_ltm : '',
        productionLabel:         teamMember.lead ? teamMember.lead.production_label : '',
        allow_duplicates_in_org: false,
        lead_emails_attributes:  teamMember.lead ? teamMember.lead.lead_emails_attributes : [{
          id:         null,
          email:      teamMember.email,
          email_type: 'office',
          team_id:    teamID,
          main:       true,
          _destroy:   false,
        }],
        lead_agent_ids_attributes: teamMember.lead ? teamMember.lead.lead_agent_ids_attributes : [{
          id:                            null,
          agent_id:                      '',
          multiple_listing_service_ouid: '',
          _destroy:                      false,
        }],
      };
    } else {
      this.state = {
        id:                  '',
        firstName:           '',
        lastName:            '',
        email:               '',
        phone:               '',
        agentId:             '',
        productionYtd:       '',
        productionLtm:       '',
        productionLabel:     '',
        reached_users_limit: false,
        owner_id:            Rails.helpers.currentUser.id,
        role:                {
          name:                    '',
          title:                   '',
          notification_recruiting: false,
          notification_retention:  false,
        },
        allow_duplicates_in_org: false,
        lead_emails_attributes:  [{
          id:         null,
          email:      '',
          email_type: 'office',
          main:       true,
          team_id:    teamID,
          _destroy:   false,
        }],
        lead_agent_ids_attributes: [{
          id:                            null,
          agent_id:                      '',
          multiple_listing_service_ouid: '',
          _destroy:                      false,
        }],
      };
    }

    autoBind(this);
  }

  componentDidMount() {
    const { handleFormChange } = this.props;

    handleFormChange(this.state);
  }

  handleLeadEmailDelete(e, leadEmail) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this email?',
      callback: (ok) => {
        if (ok) {
          const { lead_emails_attributes } = this.state;

          leadEmail._destroy = true;
          this.setState({ lead_emails_attributes });
        }
      },
    });
  }

  handleLeadAgentIdDelete(e, leadAgentId) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this Agent ID?',
      callback: (ok) => {
        if (ok) {
          const { lead_agent_ids_attributes } = this.state;

          leadAgentId._destroy = true;
          this.setState({ lead_agent_ids_attributes });
        }
      },
    });
  }

  handleAgentIdChange(e, leadAgentId) {
    e.preventDefault();

    const { lead_agent_ids_attributes } = this.state;
    const { handleFormChange } = this.props;

    leadAgentId.agent_id = e.target.value;

    this.setState({ lead_agent_ids_attributes });
    handleFormChange({ lead_agent_ids_attributes });
  }

  handleMultipleListingServiceChange(value, leadAgentId) {
    const { lead_agent_ids_attributes } = this.state;
    const { handleFormChange } = this.props;

    leadAgentId.multiple_listing_service_ouid = value;

    this.setState({ lead_agent_ids_attributes });
    handleFormChange({ lead_agent_ids_attributes });
  }

  handleEmailChange(e, leadEmail) {
    e.preventDefault();

    const { lead_emails_attributes } = this.state;
    const { handleFormChange } = this.props;

    leadEmail.email = e.target.value;

    this.setState({ lead_emails_attributes });
    handleFormChange({ lead_emails_attributes });
  }

  handleEmailTypeChange(opt, leadEmail) {
    const { lead_emails_attributes } = this.state;
    const { handleFormChange } = this.props;

    leadEmail.email_type = opt ? opt.value : '';

    this.setState({ lead_emails_attributes });
    handleFormChange({ lead_emails_attributes });
  }

  handleMainChange(e, leadEmail) {
    const { lead_emails_attributes } = this.state;
    const { handleFormChange } = this.props;

    leadEmail.main = e.target.checked;

    this.setState({ lead_emails_attributes });
    handleFormChange({ lead_emails_attributes });
  }

  onFirstNameChange(e) {
    e.preventDefault();

    const { handleFormChange } = this.props;

    const change = {
      firstName: e.target.value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onLastNameChange(e) {
    e.preventDefault();

    const { handleFormChange } = this.props;

    const change = {
      lastName: e.target.value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onEmailChange(e) {
    e.preventDefault();

    const { handleFormChange } = this.props;

    const change = {
      email: e.target.value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onPhoneChange(value) {
    const { handleFormChange } = this.props;

    const change = {
      phone: value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onProductionYtdChange(value) {
    const { handleFormChange } = this.props;

    const change = {
      productionYtd: value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onProductionLtmChange(e) {
    const { handleFormChange } = this.props;

    e.preventDefault();

    const change = {
      productionLtm: e.target.value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onProductionLabelChange(option) {
    const { handleFormChange } = this.props;

    const change = {
      productionLabel: option.value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onOwnerChange(opt) {
    const { handleFormChange } = this.props;

    const change = {
      owner_id: opt.value,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onTitleChange(e) {
    e.preventDefault();

    const { handleFormChange } = this.props;

    const change = Object.assign(this.state.role, {
      title: e.target.value,
    });

    this.setState(change);
    handleFormChange(change);
  }

  onAllowDuplicatesChange(checked) {
    const { handleFormChange } = this.props;

    const change = {
      allow_duplicates_in_org: checked,
    };

    this.setState(change);
    handleFormChange(change);
  }

  onRoleChange(option) {
    const { role } = this.state;
    const { limits, handleFormChange } = this.props;

    if (role.name === option.value) {
      return;
    }

    let newState;

    if (option.value === 'admin' || option.value === 'staff') {
      newState = Object.assign(this.state, {
        reached_users_limit: limits.reached_users_limit,
        role:                {
          name:                    option.value,
          notification_recruiting: true,
          notification_retention:  true,
        },
      });
    } else {
      newState = Object.assign(this.state, {
        reached_users_limit: false,
        role:                {
          name:                    option.value,
          notification_recruiting: false,
          notification_retention:  false,
        },
      });
    }

    this.setState(newState);
    handleFormChange(newState);
  }

  onNotificationChange(e) {
    const { role } = this.state;
    const { handleFormChange } = this.props;

    role[e.target.name] = e.target.checked;

    const change = Object.assign(this.state, { role });

    this.setState(change);
    handleFormChange(change);
  }

  addLeadEmail() {
    const { lead_emails_attributes } = this.state;

    lead_emails_attributes.push({ ...this.emptyEmail });

    this.setState({ lead_emails_attributes });
  }

  addLeadAgentId() {
    const { lead_agent_ids_attributes } = this.state;

    lead_agent_ids_attributes.push({ ...this.emptyAgentID });

    this.setState({ lead_agent_ids_attributes });
  }

  isLeadEmailValid(index) {
    const { errors } = this.props;
    return errors[index] || errors.no_email ? errors.no_email || errors[index].email || errors[index].duplicate : '';
  }

  renderLeadEmail() {
    const { lead_emails_attributes } = this.state;
    const { errors } = this.props;

    const leadEmailAttributes = lead_emails_attributes.filter((x, i) => !x._destroy);
    const checkedCount = leadEmailAttributes.filter((x, i) => x.main).length;
    const disableMainCheckbox = checkedCount >= 1;

    return leadEmailAttributes.map((leadEmail, index) => {
      const emailDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-4 col-lg-5">
            <Input
              className={classNames({ 'has-error': this.isLeadEmailValid(index) })}
              value={leadEmail.email}
              onChange={(e) => this.handleEmailChange(e, leadEmail)}
            />
          </div>

          <div className="col-4 col-lg-5 lead-emails">
            <Select
              value={leadEmail.email_type}
              options={defaultEmailTypeOptions}
              onChange={(opt) => this.handleEmailTypeChange(opt, leadEmail)}
              placeholder="--- Select ---"
              className={classNames({ 'has-error': errors[index] ? errors[index].email_type : '' })}
            />
          </div>

          <div className="col-auto">
            <div className="form-check">
              <input
                key={index}
                className="form-check-input"
                type="checkbox"
                id={`main_${index}`}
                onChange={(e) => this.handleMainChange(e, leadEmail)}
                checked={leadEmail.main || false}
                disabled={!leadEmail.main && disableMainCheckbox}
              />
              <label
                className="form-check-label"
                htmlFor={`main_${index}`}
              >
                Main
              </label>
            </div>
          </div>

          <div className="col-auto">
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleLeadEmailDelete(e, leadEmail)}>
              <FontAwesomeIcon icon={['far', 'fa-times']} />
            </button>
          </div>
          { errors[index] && errors[index].duplicate && (
            <div className="col-12">
              <ErrorMessageWithIcon message={[errors[index].duplicate]} />
              { errors[index].context === 'team' && <DuplicateContactCard duplicate={errors[index]} /> }
            </div>
          )}

          { errors[index] && errors[index].email && (
            <div className="col-12">
              <ErrorMessageWithIcon message={errors[index].email} />
            </div>
          )}
        </div>
      );

      return emailDOM;
    });
  }

  renderLeadAgentId() {
    const { lead_agent_ids_attributes } = this.state;
    const { warnings } = this.props;

    const leadAgentIdAttributes = lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    return leadAgentIdAttributes.map((leadAgentId, index) => {
      const agentIdDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-5 col-lg-5">
            <Input
              className={classNames({ 'has-error': warnings[index] ? warnings[index].duplicate : '' })}
              value={leadAgentId.agent_id}
              onChange={(e) => this.handleAgentIdChange(e, leadAgentId)}
            />
          </div>

          <div className="col-5 col-lg-6 lead-agent-ids" id={`mls-select-${index}`}>
            <MultipleListingServicesSelect
              value={leadAgentId.multiple_listing_service_ouid}
              placeholder="--- Select a MLS ---"
              onChange={(val) => this.handleMultipleListingServiceChange(val, leadAgentId)}
              teamMlses
              primaryMls
              clearable
              target={`mls-select-${index}`}
            />
          </div>

          <div className="col-auto">
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleLeadAgentIdDelete(e, leadAgentId)}>
              <FontAwesomeIcon icon={['far', 'fa-times']} />
            </button>
          </div>

          {warnings[index] && warnings[index].multiple_listing_service_ouid && (
            <div className="col-12 text-warning mt-2">
              <FontAwesomeIcon icon={['fas', 'fa-exclamation-triangle']} />
              {' '}
              {warnings[index].multiple_listing_service_ouid}
            </div>
          )}

          { warnings[index] && warnings[index].duplicate && (
            <div className="col-12">
              <ErrorMessageWithIcon message={[warnings[index].duplicate]} />
              { warnings[index].context === 'team' && <DuplicateContactCard duplicate={warnings[index]} /> }
            </div>
          )}

        </div>
      );

      return agentIdDOM;
    });
  }

  render() {
    const {
      role, reached_users_limit, id, firstName, lastName, phone, productionYtd, productionLtm, productionLabel, owner_id,
      allow_duplicates_in_org,
    } = this.state;
    const {
      teamMember, errors, limits, externalRolesOnly, duplicate_org_accounts, duplicates_found_in_org,
    } = this.props;
    const mutable = !teamMember || _lodash.isNull(teamMember.last_sign_in_at);

    return (
      <div>
        <input type="hidden" id="team_member_id" name="team_member[id]" value={id} />

        { errors.user_exists && (
          <div className="mb10 alert alert-danger">
            {errors.user_exists}
          </div>
        )}

        { (reached_users_limit || errors.reached_users_limit)
          && (
          <div className="mb10 alert alert-danger">
            You are unable to add another user with the Admin or Staff role at this time as you are at the current limit of your pricing plan. Please email us at
            {' '}
            <a href="mailto:help@getbrokerkit.com" target="_blank" rel="noopener noreferrer">help@getbrokerkit.com</a>
            {' '}
            to upgrade your pricing plan to enable additional users.
          </div>
          )}

        { !mutable
          && <div className="mb10 alert alert-info">This user has registered with the system and is in control of their settings, so we've disabled editing their details.</div>}

        <div className="form-group mb15">
          <label htmlFor="team_member_first_name">First Name</label>
          <input
            type="text"
            id="team_member_first_name"
            ref={(input) => this.firstNameInput = input}
            name="team_member[first_name]"
            className={classNames('form-control', { 'has-error': !!errors.first_name })}
            value={firstName}
            onChange={this.onFirstNameChange}
            disabled={!mutable}
          />

          { errors.first_name && <ErrorMessageWithIcon message={[errors.first_name]} /> }
        </div>

        <div className="form-group mb15">
          <label htmlFor="team_member_last_name">Last Name</label>
          <input
            type="text"
            id="team_member_last_name"
            ref={(input) => this.lastNameInput = input}
            name="team_member[last_name]"
            className={classNames('form-control', { 'has-error': !!errors.last_name })}
            value={lastName}
            onChange={this.onLastNameChange}
            disabled={!mutable}
          />
          { errors.last_name && <ErrorMessageWithIcon message={[errors.last_name]} /> }
        </div>

        <div className="field-group mb15">
          <label className="d-block">
            <a
              href="#lead_email"
              id="btn-lead-email"
              className="pull-right"
              onClick={(e) => {
                e.preventDefault();
                this.addLeadEmail();
              }}
            >
              Add Email
            </a>
            Email
          </label>

          {this.renderLeadEmail()}

          {errors.email && (<ErrorMessage message={errors.email} />)}
          {errors.emails && (<ErrorMessageWithIcon message={errors.emails} />)}
          {errors.no_email && (<ErrorMessageWithIcon message={errors.no_email} />)}

          { duplicates_found_in_org && (
            <AllowOrgDuplicateCheckBox
              duplicate_org_accounts={duplicate_org_accounts}
              onChange={this.onAllowDuplicatesChange}
              checked={allow_duplicates_in_org}
            />
          )}
        </div>

        <div className="form-group mb15">
          <label htmlFor="team_member_last_name">Cell Phone</label>
          <PhoneInput
            country="us"
            onlyCountries={['us']}
            disableCountryCode
            disableDropdown
            placeholder=""
            defaultMask="(...) ...-...."
            value={LeadHelpers.nationalFormatPhoneNumber(phone)}
            onChange={this.onPhoneChange}
            disabled={!mutable}
            specialLabel={false}
            inputProps={{
              id:        'team_member_phone',
              name:      'team_member[phone]',
              className: classNames('phone form-control', { 'has-error': !!errors.phone }),
            }}
          />
        </div>

        <div className="field-group mb15">
          <label className="d-block">
            <a
              href="#lead_agent_id"
              id="btn-lead-agent-id"
              className="pull-right"
              onClick={(e) => {
                e.preventDefault();
                this.addLeadAgentId();
              }}
            >
              Add Agent ID
            </a>
            Agent ID
          </label>

          {this.renderLeadAgentId()}
        </div>

        <div className="form-group mb15">
          <label htmlFor="team_member_production_ytd">Total $ Production LTM</label>
          <CurrencyInput
            name="team_member[production_ytd]"
            value={productionYtd}
            size={9}
            onChange={this.onProductionYtdChange}
          />
        </div>

        <div className="form-group mb15">
          <label htmlFor="team_member_production_ltm">Total # Production LTM</label>
          <input
            type="text"
            id="team_member_production_ltm"
            ref={(input) => this.productionLtmInput = input}
            name="team_member[production_ltm]"
            className={classNames('form-control', { 'has-error': !!errors.production_ltm })}
            value={productionLtm || ''}
            onChange={this.onProductionLtmChange}
          />
        </div>

        <div className="form-group mb15">
          <label htmlFor="team_member_production_label">Production Label</label>
          <ProductionLabelSelect
            name="team_member[production_label]"
            value={productionLabel}
            clearable
            onChange={this.onProductionLabelChange}
          />
        </div>

        <div className="form-group mb15">
          <label htmlFor="team_member_title">Title (optional)</label>
          <input
            type="text"
            id="team_member_title"
            ref={(input) => this.titleInput = input}
            name="team_member[title]"
            placeholder="Team Leader, etc"
            className={classNames('form-control', { 'has-error': !!errors.title })}
            value={role.title || ''}
            onChange={this.onTitleChange}
            disabled={!mutable}
          />
        </div>

        {
          Rails.abilities.manageLead && (
            <div className="form-group mb15">
              <TeammatePicker
                name="team_member[owner_id]"
                value={owner_id}
                label="Owner"
                onChange={this.onOwnerChange}
              />
            </div>
          )
        }

        <div className="form-group mb15">
          <label htmlFor="team_member_role">Role</label>
          <RoleSelect
            className={classNames({ 'has-error': !!errors.role })}
            externalRolesOnly={externalRolesOnly}
            value={role.name}
            onChange={this.onRoleChange}
          />
          { errors.role && <ErrorMessageWithIcon message={[errors.role]} /> }
        </div>

        { (role.name === 'admin' || role.name === 'staff') && (
          <div className="mt15">
            <label htmlFor="team_member_role" className="mb5">Emails</label>
            <div>
              <div className="custom-control custom-checkbox pl20">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="notification_recruiting"
                  id="notification_recruiting"
                  checked={role.notification_recruiting}
                  onChange={this.onNotificationChange}
                />
                <label className="custom-control-label" htmlFor="notification_recruiting">Recruiting agenda emails</label>
              </div>
            </div>

            <div>
              <div className="custom-control custom-checkbox pl20">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="notification_retention"
                  id="notification_retention"
                  checked={role.notification_retention}
                  onChange={this.onNotificationChange}
                />
                <label className="custom-control-label" htmlFor="notification_retention">Retention agenda emails</label>
              </div>
            </div>
          </div>
        )}

        <div className="form-group mb15" />
      </div>
    );
  }
}

TeamMemberDrawerForm.defaultProps = {
  teamMember:        null,
  errors:            {},
  warnings:          {},
  handleFormChange:  () => false,
  externalRolesOnly: false,
  limits:            {},
};

TeamMemberDrawerForm.propTypes = {
  teamMember:        PropTypes.shape({}),
  errors:            PropTypes.shape({}),
  warnings:          PropTypes.shape({}),
  handleFormChange:  PropTypes.func,
  externalRolesOnly: PropTypes.bool,
  limits:            PropTypes.shape({}),
};

export default TeamMemberDrawerForm;
