import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import LeadHelpers from '~/helpers/lead_helpers';
import UserAvatar  from '~/components/shared/users/user_avatar';

class ReportItem extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleRecordClick(e) {
    e.preventDefault();

    const {
      record, recordIndex, recordsLength, clickable, handleReportPrevClick, handleReportNextClick,
    } = this.props;

    if (clickable === false) return;

    this.context.helpers.openLeadDrawer({
      loadLeadID:      record.id,
      reportItemIndex: recordIndex,
      recordsLength,
      handleReportPrevClick,
      handleReportNextClick,
    });
  }

  renderDate(date) {
    if (!date) return null;

    const diff = Moment(date).diff(Moment(), 'h');
    if (diff > -24) {
      // less than 24 hours ago
      return 'Today';
    }
    return Moment(date).fromNow();
  }

  render() {
    const { record, showOwner } = this.props;

    return (
      <tr>
        <td className="" width="25%" onClick={this.handleRecordClick}>
          {record.name}
          {' '}
          {LeadHelpers.renderProductionBadge(record)}
        </td>
        {showOwner && (
          <td className="" width="15%" onClick={this.handleRecordClick}>
            {record?.team_name}
          </td>
        )}
        <td className="" width="15%" onClick={this.handleRecordClick}>
          {LeadHelpers.renderProductionValue(record.production_ytd)}
        </td>
        <td className="hidden-sm-down" width="15%" onClick={this.handleRecordClick}>
          {this.renderDate(record.referred_at)}
        </td>
        <td className="hidden-lg-down" width="15%" onClick={this.handleRecordClick}>
          {this.renderDate(record.first_appointment_at)}
        </td>
        <td className="hidden-lg-down" width="15%" onClick={this.handleRecordClick}>
          {this.renderDate(record.last_appointment_at)}
        </td>
        <td className="hidden-sm-down" width="10%" onClick={this.handleRecordClick}>
          {this.renderDate(record.last_activity_at)}
        </td>
        <td className="text-center hidden-md-down" width="5%" onClick={this.handleRecordClick}>
          <UserAvatar tooltip user={record.owner} />
        </td>
      </tr>
    );
  }
}

ReportItem.contextTypes = {
  helpers: PropTypes.shape({}),
};

ReportItem.defaultProps = {
  record:                null,
  recordsLength:         null,
  recordIndex:           null,
  clickable:             true,
  rating:                null,
  showOwner:             false,
  handleReportPrevClick: () => false,
  handleReportNextClick: () => false,
};

ReportItem.propTypes = {
  record:                PropTypes.shape({}),
  recordsLength:         PropTypes.number,
  recordIndex:           PropTypes.number,
  clickable:             PropTypes.bool,
  rating:                PropTypes.number,
  showOwner:             PropTypes.bool,
  handleReportPrevClick: PropTypes.func,
  handleReportNextClick: PropTypes.func,
};

export default ReportItem;
