import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import BlankPage            from '~/components/pages/blank_page';
import FrontSideBar         from '~/components/navigation/front_side_bar';
import ComposingSearchQuery from './search_query_form/composing_search_query';

class NewOrEditSearchQuery extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    const { match } = this.props;

    return (
      <BlankPage title="MLS Import Query - Brokerkit">
        <div className="row mb15">
          <FrontSideBar />

          <div className="right-side-page-md">
            <ComposingSearchQuery searchQueryID={match.params.id} />
          </div>
        </div>
      </BlankPage>
    );
  }
}

NewOrEditSearchQuery.defaultProps = {};

NewOrEditSearchQuery.propTypes = {
  match:  PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default NewOrEditSearchQuery;
