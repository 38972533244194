import { ReduceStore }      from 'flux/utils';
import AppDispatcher        from '~/dispatchers/app_dispatcher';
import LeadLabelActionTypes from '~/types/lead_label_action_types';

class LeadLabelStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingLeadLabels:        false,
      leadLabels:               [],
      label:                    null,
      lastLeadLabelStoreAction: null,
      errors:                   {},
      submitting:               false,
    };
  }

  loadLeadLabels(state, action) {
    const newState = { ...state };

    newState.loadingLeadLabels = true;
    newState.lastLeadLabelStoreAction = 'loadLeadLabels';

    return newState;
  }

  loadLeadLabelsDone(state, action) {
    const newState = { ...state };

    newState.loadingLeadLabels = false;
    newState.leadLabels = action.labels;
    newState.lastLeadLabelStoreAction = 'loadLeadLabelsDone';

    return newState;
  }

  loadLeadLabelsFail(state, action) {
    const newState = { ...state };

    newState.loadingLeadLabels = false;
    newState.lastLeadLabelStoreAction = 'loadLeadLabelsFail';

    return newState;
  }

  createLeadLabel(state, action) {
    const newState = { ...state };

    newState.lastLeadLabelStoreAction = 'createLeadLabel';

    return newState;
  }

  createLeadLabelDone(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.label = action.label;
    newState.errors = {};
    newState.lastLeadLabelStoreAction = 'createLeadLabelDone';

    return newState;
  }

  createLeadLabelFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.errors = action.error.response.body.errors;
    newState.lastLeadLabelStoreAction = 'createLeadLabelFail';

    return newState;
  }

  deleteLeadLabel(state, action) {
    const newState = { ...state };

    newState.lastLeadLabelStoreAction = 'deleteLeadLabel';

    return newState;
  }

  deleteLeadLabelDone(state, action) {
    const newState = { ...state };

    newState.errors = {};
    newState.lastLeadLabelStoreAction = 'deleteLeadLabelDone';

    return newState;
  }

  deleteLeadLabelFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error;
    newState.lastLeadLabelStoreAction = 'deleteLeadLabelFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case LeadLabelActionTypes.LOAD_LEAD_LABELS:
        return this.loadLeadLabels(state, action);
      case LeadLabelActionTypes.LOAD_LEAD_LABELS_DONE:
        return this.loadLeadLabelsDone(state, action);
      case LeadLabelActionTypes.LOAD_LEAD_LABELS_FAIL:
        return this.loadLeadLabelsFail(state, action);
      case LeadLabelActionTypes.CREATE_LEAD_LABEL:
        return this.createLeadLabel(state, action);
      case LeadLabelActionTypes.CREATE_LEAD_LABEL_DONE:
        return this.createLeadLabelDone(state, action);
      case LeadLabelActionTypes.CREATE_LEAD_LABEL_FAIL:
        return this.createLeadLabelFail(state, action);
      case LeadLabelActionTypes.DELETE_LEAD_LABEL:
        return this.deleteLeadLabel(state, action);
      case LeadLabelActionTypes.DELETE_LEAD_LABEL_DONE:
        return this.deleteLeadLabelDone(state, action);
      case LeadLabelActionTypes.DELETE_LEAD_LABEL_FAIL:
        return this.deleteLeadLabelFail(state, action);
      default:
        return state;
    }
  }
}

export default new LeadLabelStore();
