import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes           from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadLabelActions    from '~/actions/lead_label_actions';
import brokerbit           from '~/lib/brokerbit';

const LeadDrawerLabel = ({ lead, label: labelProp }) => {
  const [label, setLabel] = useState(labelProp);

  useEffect(() => {
    setLabel(labelProp);
  }, [labelProp]);

  const handleLabelDelete = (e) => {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  `Are you sure to remove the "${label.name}" label?`,
      callback: (ok) => {
        if (ok) {
          LeadLabelActions.deleteLeadLabel(lead.id, label);
        }
      },
    });
  };

  return (
    <div className="mr-2 mb-2 d-inline-block label">
      <span className="badge badge-success align-middle">
        <Link
          to="#"
          onClick={handleLabelDelete}
          className="mr-1 align-middle"
        >
          <FontAwesomeIcon
            icon={['far', 'fa-times']}
            size="sm"
            className="mr-1 text-white"
          />
        </Link>
        {label.name}
      </span>
    </div>
  );
};

LeadDrawerLabel.propTypes = {
  lead:  PropTypes.shape({}).isRequired,
  label: PropTypes.shape({}).isRequired,
};

export default LeadDrawerLabel;
