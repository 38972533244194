import React, {
  useState,
  useCallback,
} from 'react';
import StepWizard     from 'react-step-wizard';
import DownloadStep   from './steps/download_step';
import SettingsStep   from './steps/settings_step';
import UploadFileStep from './steps/upload_file_step';

const MarketViewBrokerImportTab = () => {
  const importType                = 'Import::MarketViewBroker';
  const templateUrl               = '/mvb_normal_template.xlsx';
  const templateButtonTitle       = 'Download Template for MVB Normal View';
  const secondTemplateUrl         = '/mvb_percent_change_template.xlsx';
  const secondTemplateButtonTitle = 'Download Template for MVB % Change View';
  const [labels, setLabels] = useState('');
  const [multipleListingServiceOuid, setMultipleListingServiceOuid] = useState('');

  const handleLabelsChange = useCallback((e) => {
    setLabels(e.target.value);
  }, []);

  const handleMultipleListingServiceChange = useCallback((value) => {
    setMultipleListingServiceOuid(value);
  }, []);

  const resetState = useCallback(() => {
    setLabels('');
    setMultipleListingServiceOuid('');
  }, []);

  return (
    <div className="market-view-broker-import mt-2">
      <h5>Follow the prompts below to import your data into Brokerkit</h5>

      <ul className="mt-2 pl-3">
        <li>
          Create a query in the normal or % change view in MarketView Broker as detailed
          {' '}
          <a
            className="text-primary"
            href="https://info.getbrokerkit.com/knowledge/hc/en-us/articles/360043048151-importing-and-updating-leads-from-marketview-broker"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          {' '}
          and export it to CSV for upload into Brokerkit
        </li>
        <li>Download the proper template below (normal or % change view) and paste the data rows without the header information into the Brokerkit template for upload</li>
        <li>The % change view is preferred as it will add year over year production changes not in the normal view</li>
        <li>The columns in the template should align with the data export from MVB with the addition of columns for Notes and Labels for you to add notes and labels by row on import if desired. Any other columns will be skipped on import</li>
        <li>We match data by column header name to know which field to import the data into so do not modify the column headers or delete columns from the template</li>
        <li>Any row that has neither an email address nor an agent ID will be skipped</li>
        <li>The agent ID OR email address columns will be used to match and update existing records</li>
        <li>If there are no existing records with the email addresses or agent IDs in the CSV, then new records will be created</li>
        <li>An MLS is required to be selected if the import includes Agent IDs</li>
        <li>Please import data from only one MLS at a time</li>
      </ul>

      <div className="mt-3">
        <StepWizard>
          <DownloadStep
            key="market-view-broker-download-template"
            step={1}
            importType={importType}
            templateUrl={templateUrl}
            templateButtonTitle={templateButtonTitle}
            secondTemplateUrl={secondTemplateUrl}
            secondTemplateButtonTitle={secondTemplateButtonTitle}
          />
          <SettingsStep
            key="market-view-broker-settings"
            step={2}
            labels={labels}
            multiple_listing_service_ouid={multipleListingServiceOuid}
            handleLabelsChange={handleLabelsChange}
            handleMultipleListingServiceChange={handleMultipleListingServiceChange}
          />
          <UploadFileStep
            key="market-view-broker-upload-template"
            step={3}
            importType={importType}
            labels={labels}
            multiple_listing_service_ouid={multipleListingServiceOuid}
            resetState={resetState}
          />
        </StepWizard>
      </div>
    </div>
  );
};

export default MarketViewBrokerImportTab;
