import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimelineActivity from '~/components/drawers/timeline/activity';
import LeadHelpers from '~/helpers/lead_helpers';

const SubmissionActivityEntry = ({ lead }) => {
  const submissionDate = lead.referred_at || lead.created_at;
  const formattedSubmissionDate = Moment(submissionDate).format('LL');
  const daysFromNow = LeadHelpers.daysFromNowString(submissionDate);

  return (
    <TimelineActivity icon="upload" prefix="fas">
      <h5>Lead Submitted</h5>
      <p className="text-grey mb5">
        on
        {' '}
        {formattedSubmissionDate}
      </p>
      <p className="text-grey">
        <FontAwesomeIcon icon={['far', 'fa-clock']} className="mr5" />
        {daysFromNow}
      </p>
    </TimelineActivity>
  );
};

SubmissionActivityEntry.propTypes = {
  lead: PropTypes.shape({
    referred_at: PropTypes.string,
    created_at:  PropTypes.string,
  }).isRequired,
};

export default SubmissionActivityEntry;
