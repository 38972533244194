import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import BlankPage         from '~/components/pages/blank_page';
import FrontSideBar      from '~/components/navigation/front_side_bar';
import ComposingCampaign from './manage_campaigns/composing_campaign';

class EditCampaign extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    const { match } = this.props;

    return (
      <BlankPage title="Campaigns - Brokerkit">
        <div className="row">
          <FrontSideBar />

          <div className="right-side-page-md">
            <ComposingCampaign campaignID={match.params.id} />
          </div>
        </div>
      </BlankPage>
    );
  }
}

EditCampaign.defaultProps = {};

EditCampaign.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default EditCampaign;
