import APIRequest from '~/lib/api_request';

export default class QueryHandler {
  constructor(ed, aCInstance, activityType) {
    this.editor     = ed;
    this.activityType = activityType;
    this.aCInstance = aCInstance;
    this.options = aCInstance.options;
  }

  matcher = (item) => {
    const lowercaseItemValue = item[this.options.queryBy].toLowerCase();
    const lowercaseQuery = this.aCInstance.query.toLowerCase();
    const index = lowercaseItemValue.indexOf(lowercaseQuery);

    return index === 0 || lowercaseItemValue[index - 1] === ' ';
  };

  sorter = (items) => {
    const beginswith = [];
    const caseSensitive = [];
    const caseInsensitive = [];
    let item = null;
    const { query } = this.aCInstance;

    while (item = items.shift()) { // eslint-disable-line no-cond-assign
      if (!item[this.options.queryBy].toLowerCase().indexOf(query.toLowerCase())) {
        beginswith.push(item);
      } else if (~item[this.options.queryBy].indexOf(query)) { // eslint-disable-line no-bitwise
        caseSensitive.push(item);
      } else {
        caseInsensitive.push(item);
      }
    }

    return beginswith.concat(caseSensitive, caseInsensitive);
  }

  loadOptions() {
    const params = GlobalContainer.urlParams();

    const request = APIRequest.get({
      resource: '/v1/team/members_role_options',
      data:     { lead_id: params.lead, activity_type: this.activityType },
    });

    request.end((error, response) => {
      if (error) return;

      const data = response.body.data ? response.body.data : response.body;
      const options = data.map((u) => ({ avatar: u.avatar_url, id: u.id.toString(), name: u.name }));

      this.aCInstance.options.source = options;
    });
  }
}
