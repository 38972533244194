import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import BlankPage                from '~/components/pages/blank_page';
import FrontSideBar             from '~/components/navigation/front_side_bar';
import ComposingCampaignLibrary from './composing_campaign_library';

class EditCampaignLibrary extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    const { match } = this.props;

    return (
      <BlankPage title="Campaign Library - Brokerkit">
        <div className="row">
          <FrontSideBar />

          <div className="right-side-page-md">
            <ComposingCampaignLibrary
              campaignID={match.params.id}
            />
          </div>
        </div>
      </BlankPage>
    );
  }
}

EditCampaignLibrary.defaultProps = {};

EditCampaignLibrary.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default EditCampaignLibrary;
