import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import CampaignActions      from '~/actions/campaign_actions';
import CampaignStore        from '~/stores/campaign_store';
import CampaignTemplateItem from './campaign_template/campaign_template_item';

class CampaignStepTemplate extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      campaigns: [],
    };
  }

  componentDidMount() {
    this.campaignStoreListener = CampaignStore.addListener(this.onCampaignStoreChange);

    CampaignActions.loadCampaigns();
  }

  componentWillUnmount() {
    if (this.campaignStoreListener) this.campaignStoreListener.remove();
  }

  onCampaignStoreChange() {
    const newState = { ...CampaignStore.getState() };

    this.setState({
      campaigns: newState.campaigns,
    });
  }

  renderCampaigns() {
    const { campaigns } = this.state;
    const { handleCampaignTemplateClick } = this.props;

    if (campaigns.length === 0) {
      return (<p className="text-grey text-center mt20 mb20">No existing campaign found.</p>);
    }

    const campaignRows = [];
    _lodash.each(campaigns, (campaign, idx) => {
      campaignRows.push(
        <CampaignTemplateItem
          key={`campaign-template-item-${campaign.id}`}
          campaign={campaign}
          handleCampaignTemplateClick={handleCampaignTemplateClick}
        />,
      );
    });

    return (
      <table className="table table-hover table-responsive">
        <tbody>
          {campaignRows}
        </tbody>
      </table>
    );
  }

  render() {
    const { handleFromScratchClick } = this.props;
    return (
      <div className="mt40 mb40">
        <div className="form-group">
          <p>Select a template</p>

          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                handleFromScratchClick();
              }}
            >
              Create from scratch
            </button>
          </div>
        </div>

        <div className="text-center mt20 mb20">or choose an existing campaign</div>

        {this.renderCampaigns()}
      </div>
    );
  }
}

CampaignStepTemplate.defaultProps = {
  handleFromScratchClick:      () => false,
  handleCampaignTemplateClick: () => false,
};

CampaignStepTemplate.propTypes = {
  handleFromScratchClick:      PropTypes.func,
  handleCampaignTemplateClick: PropTypes.func,
};

export default CampaignStepTemplate;
