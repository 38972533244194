import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class RatingSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getOptions() {
    return [
      { value: '10', label: '10 - Joining this month' },
      { value: '9', label: '9 - Joining next month' },
      { value: '8', label: '8 - Joining in 2 months' },
      { value: '7', label: '7 - Joining in 3 months' },
      { value: '6', label: '6 - Good meeting' },
      { value: '5', label: '5 - Meeting could have gone better' },
      { value: '4', label: '4 - No meeting' },
    ];
  }

  render() {
    return (
      <Select
        {...this.props}
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.getOptions()}
      />
    );
  }
}

RatingSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

RatingSelect.propTypes = {
  name:     PropTypes.string,
  value:    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
};

export default RatingSelect;
