import React              from 'react';
import { PropTypes }      from 'prop-types';
import { ToastContainer } from 'react-toastify';

import PubNub from '~/lib/pubnub';

class Notifications extends React.Component {
  componentDidMount() {
    const {
      channel, publishKey, subscribeKey, pubNubUuid,
    } = this.props;

    this.pubnub = PubNub.client(publishKey, subscribeKey, pubNubUuid);
    this.pubnub.addListener({
      message(payload) {
        switch (payload.message.event) {
          case 'reauth':
            // google auth failure, let's force them to do something about it.
            // eslint-disable-next-line no-restricted-globals
            top.location = '/google_token_expired';
            break;
          case 'notification':
            switch (payload.message.type) {
              case 'success':
                GlobalContainer.notify(payload.message.text, 'success');
                break;
              case 'error':
              case 'danger':
                GlobalContainer.notify(payload.message.text, 'danger');
                break;
              default:
                // do nothing
            }
            break;
          default:
        }
      },
    });

    if (channel) {
      this.pubnub.subscribe({
        channels: [channel, 'broadcast'],
      });
    }
  }

  render() {
    return (
      <div className="Toastify">
        <ToastContainer
          theme="colored"
          position="top-right"
          type="default"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
        />
      </div>
    );
  }
}

Notifications.propTypes = {
  publishKey:   PropTypes.string,
  subscribeKey: PropTypes.string,
  channel:      PropTypes.string,
  pubNubUuid:   PropTypes.string,
};
Notifications.defaultProps = {
  publishKey:   '',
  subscribeKey: '',
  channel:      '',
  pubNubUuid:   '',
};
export default Notifications;
