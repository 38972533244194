const BusinessContactsActionTypes = {
  CREATE_BUSINESS_CONTACT:      'CREATE_BUSINESS_CONTACT',
  CREATE_BUSINESS_CONTACT_DONE: 'CREATE_BUSINESS_CONTACT_DONE',
  CREATE_BUSINESS_CONTACT_FAIL: 'CREATE_BUSINESS_CONTACT_FAIL',

  UPDATE_BUSINESS_CONTACT:      'UPDATE_BUSINESS_CONTACT',
  UPDATE_BUSINESS_CONTACT_DONE: 'UPDATE_BUSINESS_CONTACT_DONE',
  UPDATE_BUSINESS_CONTACT_FAIL: 'UPDATE_BUSINESS_CONTACT_FAIL',
};

export default BusinessContactsActionTypes;
