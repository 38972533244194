import { ReduceStore }     from 'flux/utils';
import AppDispatcher       from '~/dispatchers/app_dispatcher';
import LeadLinkActionTypes from '~/types/lead_link_action_types';

class LeadLinkStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      showAddLink:             false,
      links:                   [],
      lastLeadLinkStoreAction: null,
      errors:                  {},
      newLink:                 {
        service_name: '',
        service_url:  '',
      },
      editLink: {
        service_name: '',
        service_url:  '',
      },
    };
  }

  updateFromFullContact(state, action) {
    const newState = { ...state };

    newState.loading = true;
    newState.lastLeadLinkStoreAction = 'updateFromFullContact';

    return newState;
  }

  updateFromFullContactDone(state, action) {
    const newState = { ...state };

    newState.links = action.links.map((l) => ({ ...l, showEdit: false, showText: true }));
    newState.loading = false;
    newState.errors = {};
    newState.lastLeadLinkStoreAction = 'updateFromFullContactDone';

    return newState;
  }

  updateFromFullContactFail(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.errors = action.error.response.body.errors;
    newState.lastLeadLinkStoreAction = 'updateFromFullContactFail';

    return newState;
  }

  loadLeadLinksDone(state, action) {
    const newState = { ...state, showAddLink: false, links: action.links.map((l) => ({ ...l, showEdit: false, showText: true })) };

    newState.lastLeadLinkStoreAction = 'loadLeadLinksDone';

    return newState;
  }

  createLeadLink(state, action) {
    const newState = { ...state };

    newState.lastLeadLinkStoreAction = 'createLeadLink';

    return newState;
  }

  updateLeadLink(state, action) {
    const newState = { ...state };

    newState.lastLeadLinkStoreAction = 'updateLeadLink';

    return newState;
  }

  deleteLeadLink(state, action) {
    const newState = { ...state };

    newState.lastLeadLinkStoreAction = 'deleteLeadLink';

    return newState;
  }

  createLeadLinkDone(state, action) {
    const newState = { ...state };

    newState.showAddLink = false;
    newState.links = action.links.map((l) => ({ ...l, showEdit: false, showText: true }));
    newState.errors = {};
    newState.lastLeadLinkStoreAction = 'createLeadLinkDone';

    return newState;
  }

  updateLeadLinkDone(state, action) {
    const newState = { ...state };

    newState.links = action.links.map((l) => ({ ...l, showEdit: false, showText: true }));
    newState.errors = {};
    newState.lastLeadLinkStoreAction = 'updateLeadLinkDone';

    return newState;
  }

  deleteLeadLinkDone(state, action) {
    const newState = { ...state };

    newState.links = action.links.map((l) => ({ ...l, showEdit: false, showText: true }));
    newState.errors = {};
    newState.lastLeadLinkStoreAction = 'deleteLeadLinkDone';

    return newState;
  }

  createLeadLinkFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body.errors;
    newState.lastLeadLinkStoreAction = 'createLeadLinkFail';

    return newState;
  }

  updateLeadLinkFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body.errors;
    newState.lastLeadLinkStoreAction = 'updateLeadLinkFail';

    return newState;
  }

  deleteLeadLinkFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body.errors;
    newState.lastLeadLinkStoreAction = 'deleteLeadLinkFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case LeadLinkActionTypes.LOAD_LEAD_LINKS_DONE:
        return this.loadLeadLinksDone(state, action);
      case LeadLinkActionTypes.CREATE_LEAD_LINK:
        return this.createLeadLink(state, action);
      case LeadLinkActionTypes.CREATE_LEAD_LINK_DONE:
        return this.createLeadLinkDone(state, action);
      case LeadLinkActionTypes.CREATE_LEAD_LINK_FAIL:
        return this.createLeadLinkFail(state, action);
      case LeadLinkActionTypes.UPDATE_LEAD_LINK:
        return this.updateLeadLink(state, action);
      case LeadLinkActionTypes.UPDATE_LEAD_LINK_DONE:
        return this.updateLeadLinkDone(state, action);
      case LeadLinkActionTypes.UPDATE_LEAD_LINK_FAIL:
        return this.updateLeadLinkFail(state, action);
      case LeadLinkActionTypes.DELETE_LEAD_LINK:
        return this.deleteLeadLink(state, action);
      case LeadLinkActionTypes.DELETE_LEAD_LINK_DONE:
        return this.deleteLeadLinkDone(state, action);
      case LeadLinkActionTypes.DELETE_LEAD_LINK_FAIL:
        return this.deleteLeadLinkFail(state, action);
      case LeadLinkActionTypes.UPDATE_FROM_FULLCONTACT:
        return this.updateFromFullContact(state, action);
      case LeadLinkActionTypes.UPDATE_FROM_FULLCONTACT_DONE:
        return this.updateFromFullContactDone(state, action);
      case LeadLinkActionTypes.UPDATE_FROM_FULLCONTACT_FAIL:
        return this.updateFromFullContactFail(state, action);
      default:
        return state;
    }
  }
}

export default new LeadLinkStore();
