import autoBind            from 'react-autobind';
import React               from 'react';
import PropTypes           from 'prop-types';
import update              from 'immutability-helper';
import { Collapse }        from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateTimePicker                from '~/components/forms/DateTimePicker';
import LeadDrawerActions             from '~/actions/lead_drawer_actions';
import SimpleMonthPicker, { months } from '~/components/forms/simple_month_picker';
import LeadDrawerTeamMemberManager   from './lead_drawer_team_member_manager';

class LeadDrawerRetentionDetails extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const isOpen = localStorage.isRetentionOpen ? JSON.parse(localStorage.isRetentionOpen) : false;

    this.state = {
      opened: props.opened,
      isOpen,
      fields: _lodash.pick(props.lead, 'active_listings_count', 'anniversary_month', 'joined_at', 'production_label'),
    };
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    localStorage.isRetentionOpen = !isOpen;
  }

  onEditClick(e) {
    e.preventDefault();

    this.setState({
      opened: true,
    });
  }

  onEditCloseClick(e) {
    e.preventDefault();

    this.setState({
      opened: false,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { fields } = this.state;
    const { lead, query } = this.props;

    LeadDrawerActions.updateLead(
      update(fields, { id: { $set: lead.id } }), query,
    );

    this.setState({
      opened: false,
    });
  }

  setFormField = (name, val) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: val,
      },
    }));
  };

  renderContent() {
    const { isOpen } = this.state;
    const { lead } = this.props;

    return (
      <div>
        <div className="pull-right">
          <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={this.onCollapseClick}>
            { isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
          { Rails.abilities.manageLead && (
            <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditClick}>
              <FontAwesomeIcon icon={['far', 'fa-edit']} />
              {' '}
              Edit
            </button>
          )}
        </div>

        <h4 className="mb10">Retention</h4>

        <Collapse isOpen={isOpen}>
          <table className="table table-flush table-sm no-border">
            <tbody>
              <tr>
                <th className="text-grey-dark">Active Listings</th>
                <td className="text-black">{lead.active_listings_count}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Anniversary</th>
                <td className="text-black">{months[lead.anniversary_month - 1]}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Joined On</th>
                <td className="text-black">{lead.joined_at && Moment(lead.joined_at).format('MMMM D, YYYY')}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Last Signin</th>
                <td className="text-black">
                  <LeadDrawerTeamMemberManager lead={lead} />
                </td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </div>
    );
  }

  renderForm() {
    const { fields } = this.state;

    return (
      <div>
        <div className="text-right">
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditCloseClick}>
            <FontAwesomeIcon icon={['fas', 'fa-times']} />
            {' '}
            Close
          </button>
        </div>

        <form id="edit-lead-form" onSubmit={this.onFormSubmit}>
          <div className="field-group mb15">
            <label htmlFor="active_listings" className="d-block">Active Listings</label>
            <input
              id="active_listings"
              name="active_listings_count"
              type="number"
              size="4"
              className="form-control string optional"
              value={fields.active_listings_count}
              onChange={(val) => this.setFormField('active_listings_count', val.target.value)}
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="lead_anniversary_month" className="d-block">Anniversary</label>
            <SimpleMonthPicker
              id="lead_anniversary_month"
              name="anniversary_month"
              value={fields.anniversary_month}
              placeholder=""
              clearable
              onChange={(val) => this.setFormField('anniversary_month', val ? val.value : '')}
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="lead_joined_at" className="d-block">Joined On</label>
            <DateTimePicker
              id="lead_joined_at"
              placeholder="Select the Date"
              format="MMM Do YYYY"
              pickerType="date"
              value={Moment(fields.joined_at)?.format('MMM Do YYYY')}
              onChange={(val) => {
                this.setFormField('joined_at', val && Moment(val)?.format('YYYY-MM-DD'));
              }}
            />
          </div>

          <div className="form-group mb15">
            <button type="submit" className="btn btn-success">Save</button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { opened } = this.state;

    if (opened) {
      return this.renderForm();
    }
    return this.renderContent();
  }
}

LeadDrawerRetentionDetails.defaultProps = {
  lead:   null,
  query:  null,
  opened: false,
};

LeadDrawerRetentionDetails.propTypes = {
  lead:   PropTypes.shape({}),
  query:  PropTypes.shape({}),
  opened: PropTypes.bool,
};

export default LeadDrawerRetentionDetails;
