import React from 'react';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltipable from '~/components/effects/tooltipable';

const TokensUsage = ({ state }) => {
  const maxTokensPerConversation = process.env.MAX_TOKENS_PER_CONVERSATION;
  const { tokensCount, totalTokensUsed, subscriptionLimit } = state;

  const formattedTokensCount = numeral(tokensCount).format('0,0');
  const formattedMaxTokens = numeral(maxTokensPerConversation).format('0,0');
  const formattedTotalTokensUsed = numeral(totalTokensUsed).format('0,0');
  const formattedLimit = subscriptionLimit !== null ? numeral(subscriptionLimit).format('0,0') : 'unlimited';

  const tooltipText = 'A token is a unit of text used in AI models. In English, a token can be as short as one character or as long as one word (e.g., "a" or "apple"). The total number of tokens affects both the cost and the ability to generate or process text. In English, a token can often correspond to a word, but it can also represent a single character or part of a word. For example, the word "apple" is one token, but "haven\'t" would be split into two tokens: "have" and "n\'t." While it might be common for many words to be represented as single tokens, it\'s not a strict one-token-per-word mapping.';

  return (
    <small className="text-gray">
      Tokens in this conversation:
      {' '}
      {formattedTokensCount}
      {' '}
      /
      {' '}
      {formattedMaxTokens}
      <Tooltipable placement="right" className="ml-1" text={tooltipText}>
        <FontAwesomeIcon icon="far fa-info-circle" />
      </Tooltipable>
      <br />
      Total tokens used (plan limit):
      {' '}
      {formattedTotalTokensUsed}
      {' '}
      of
      {' '}
      {formattedLimit}
    </small>
  );
};

export default TokensUsage;
