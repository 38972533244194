import AppDispatcher     from '~/dispatchers/app_dispatcher';
import APIRequest        from '~/lib/api_request';
import TaskActionTypes   from '~/types/task_action_types';
import AgentActions      from '~/actions/agent_actions';
import LeadDrawerActions from '~/actions/lead_drawer_actions';
import LeadDrawerStore   from '~/stores/lead_drawer_store';
import GlobalContainer from '~/components/global_container';

let loadTaskRequest;
let tasksRequest;
let createTaskRequest;
let updateTaskRequest;
let deleteTaskRequest;

const TaskActions = {
  loadTasks(leadID, currentPage) {
    APIRequest.abort(tasksRequest);

    AppDispatcher.dispatch({
      type: TaskActionTypes.LOAD_TASKS,
    });

    tasksRequest = APIRequest.get({
      resource: '/v1/tasks',
      data:     {
        lead_id: leadID,
        page:    currentPage,
      },
    });

    tasksRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TaskActionTypes.LOAD_TASKS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  TaskActionTypes.LOAD_TASKS_DONE,
        tasks: response.body,
      });
    });
  },

  loadTask(taskID, onDone = () => false, onFail = () => false) {
    APIRequest.abort(loadTaskRequest);

    AppDispatcher.dispatch({
      type: TaskActionTypes.LOAD_TASK,
    });

    loadTaskRequest = APIRequest.get({
      resource: `/v1/tasks/${taskID}`,
    });

    loadTaskRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  createTask(task, leadID) {
    const { recordIndex } = LeadDrawerStore.getState();

    APIRequest.abort(createTaskRequest);

    AppDispatcher.dispatch({
      type: TaskActionTypes.CREATE_TASK,
      task,
    });

    createTaskRequest = APIRequest.post({
      resource: '/v1/tasks',
      data:     { task },
    });

    createTaskRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TaskActionTypes.CREATE_TASK_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: TaskActionTypes.CREATE_TASK_DONE,
        task: response.body,
      });

      AgentActions.reloadLeadTables();

      if (leadID) {
        LeadDrawerActions.loadLead(leadID, { recordIndex });
        TaskActions.loadTasks(leadID);
      }
    });
  },

  updateTask(task, currentPage) {
    const { recordIndex } = LeadDrawerStore.getState();

    APIRequest.abort(updateTaskRequest);

    AppDispatcher.dispatch({
      type: TaskActionTypes.UPDATE_TASK,
      task,
    });

    updateTaskRequest = APIRequest.put({
      resource: `/v1/tasks/${task.id}`,
      data:     { task },
    });

    updateTaskRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TaskActionTypes.UPDATE_TASK_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: TaskActionTypes.UPDATE_TASK_DONE,
        task: response.body,
      });

      AgentActions.reloadLeadTables();

      if (task.leadID) {
        LeadDrawerActions.loadLead(task.leadID, { recordIndex });
        TaskActions.loadTasks(task.leadID, currentPage);
      }
    });
  },

  deleteTask(task, leadID, currentPage) {
    const { recordIndex } = LeadDrawerStore.getState();

    APIRequest.abort(deleteTaskRequest);

    AppDispatcher.dispatch({
      type: TaskActionTypes.DELETE_TASK,
      task,
    });

    deleteTaskRequest = APIRequest.delete({
      resource: `/v1/tasks/${task.id}`,
    });

    deleteTaskRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TaskActionTypes.DELETE_TASK_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: TaskActionTypes.DELETE_TASK_DONE,
      });

      GlobalContainer.notify('Task will be deleted');

      AgentActions.reloadLeadTables();

      if (leadID) {
        LeadDrawerActions.loadLead(leadID, { recordIndex });
        TaskActions.loadTasks(leadID, currentPage);
      }
    });
  },
};

export default TaskActions;
