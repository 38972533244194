import autoBind        from 'react-autobind';
import classNames      from 'classnames';
import React           from 'react';
import { PropTypes }   from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select              from '~/components/forms/select';
import Tooltipable         from '~/components/effects/tooltipable';
import StepEmailForm       from './step_email_form';
import StepTaskForm        from './step_task_form';
import StepTextMessageForm from './step_text_message_form';

import brokerbit            from '~/lib/brokerbit';
import CampaignStepOnSelect from '~/components/forms/dropdowns/CampaignStepOnSelect';
import CampaignStepAtSelect from '~/components/forms/dropdowns/CampaignStepAtSelect';
import MeridianSelect       from '~/components/forms/dropdowns/MeridianSelect';
import CampaignStepNew      from './campaign_step_new';

class CampaignStep extends React.Component {
  static updateColorAndIcon(formName) {
    let color = 'text-blue';
    let icon  = 'envelope';
    let prefix = 'far';

    switch (formName) {
      case 'sms':
        color = 'text-yellow';
        icon = 'comment';
        prefix = 'far';
        break;

      case 'task':
        color = 'text-green';
        icon = 'tasks';
        prefix = 'far';
        break;

      case 'email':
      default:
        color = 'text-blue';
        icon = 'envelope';
        prefix = 'far';
    }

    return { color, icon, prefix };
  }

  static timeToSeconds(timeValue, timeUnit) {
    switch (timeUnit) {
      case 'hours':
        return timeValue * 3600;
      case 'days':
      default:
        return timeValue * 86400;
    }
  }

  static secondsToTimeValue(seconds) {
    return seconds % 86400 === 0 ? seconds / 86400 : seconds / 3600;
  }

  static secondsToTimeUnit(seconds) {
    return seconds % 86400 === 0 ? 'days' : 'hours';
  }

  static timeToHhmm(time) {
    if (time === '*' || time === '09:00-12:00') {
      return [time, 'am'];
    }

    const convertedTime = Moment(time, 'hh:mm A');
    const at = convertedTime.format('hh:mm');
    const meridian = convertedTime.format('a');

    return [at, meridian];
  }

  static timeToHHmm(time, meridian) {
    if (time === '*' || time === '09:00-12:00') {
      return time;
    }

    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');

    hours = (hours % 12) + (meridian === 'am' ? 0 : 12);
    hours = hours < 10 ? `0${hours}` : hours;

    return `${hours}:${minutes}`;
  }

  constructor(props) {
    super(props);

    autoBind(this);

    let formName  = 'email';
    let timeValue = 0;
    let timeUnit  = 'days';
    let on        = 'mon-fri';
    let at        = '09:00-12:00';
    let meridian  = 'am';

    const { step } = props;

    if (step) {
      formName  = step.action_type;
      on        = step.on || on;
      at        = step.at || step.random_at_range || at;
      timeValue = CampaignStep.secondsToTimeValue(step.wait_time);
      timeUnit  = CampaignStep.secondsToTimeUnit(step.wait_time);
    }

    const { color, icon, prefix } = CampaignStep.updateColorAndIcon(formName);

    [at, meridian] = CampaignStep.timeToHhmm(at);

    this.state = {
      currentForm:       formName,
      waitTimeValue:     timeValue,
      waitTimeUnit:      timeUnit,
      showAddStepDialog: false,
      on,
      at,
      meridian,
      color,
      icon,
      prefix,
    };
  }

  handleSwitchForm(formName) {
    const { stepIdx, step, updateStep } = this.props;

    const { color, icon, prefix } = CampaignStep.updateColorAndIcon(formName);

    this.setState({
      currentForm: formName,
      color,
      icon,
      prefix,
    });

    if (!step.id) {
      this.setStepOn({ value: 'mon-fri' });

      if (formName === 'email' || formName === 'sms') {
        this.setStepAt({ value: '09:00-12:00' });
      } else {
        this.setStepAt({ value: '09:00' });
      }
    }

    updateStep(stepIdx, { action_type: formName });
  }

  handleStepFormChange(formState) {
    const { stepIdx, updateStep } = this.props;

    updateStep(stepIdx, formState);
  }

  handleStepDelete(e) {
    e.preventDefault();

    const { deleteStep, stepIdx } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this step?',
      callback: (ok) => {
        if (ok) { deleteStep(stepIdx); }
      },
    });
  }

  handleOptionChange(e) {
    const { stepIdx, moveStep } = this.props;

    moveStep(stepIdx, e.value);
  }

  onWaitTimeValueChange(e) {
    e.preventDefault();

    const { stepIdx, updateStep } = this.props;
    const { waitTimeUnit } = this.state;
    const timeValue = parseInt(e.target.value, 10);
    const value = _lodash.isNaN(timeValue) ? 0 : timeValue;

    this.setState({
      waitTimeValue: value,
    });

    updateStep(stepIdx, { wait_time: CampaignStep.timeToSeconds(value, waitTimeUnit) });
  }

  onAddStepIconClick() {
    this.setState({ showAddStepDialog: true });
  }

  onCloseStepIconClick() {
    this.setState({ showAddStepDialog: false });
  }

  setStepOn(option) {
    const { stepIdx, updateStep } = this.props;

    this.setState({ on: option.value });
    updateStep(stepIdx, { on: option.value });
  }

  setStepAt(option) {
    const { meridian } = this.state;
    const { stepIdx, updateStep } = this.props;
    let at = option.value;
    let random_at_range;

    if (at === '09:00-12:00') random_at_range = at;

    this.setState({ at }, () => {
      at = CampaignStep.timeToHHmm(at, meridian);

      if (random_at_range) updateStep(stepIdx, { random_at_range, at: null });
      if (!random_at_range) updateStep(stepIdx, { random_at_range: null, at });
    });
  }

  setStepMeridian(option) {
    let { at } = this.state;
    const { stepIdx, updateStep } = this.props;
    const meridian = option.value;

    this.setState({ meridian }, () => {
      at = CampaignStep.timeToHHmm(at, meridian);
      updateStep(stepIdx, { at });
    });
  }

  validate() {
    const { currentForm } = this.state;
    let errors;

    switch (currentForm) {
      case 'sms':
        errors = this.smsForm.validate();
        break;
      case 'task':
        errors = this.taskForm.validate();
        break;
      case 'email':
      default:
        errors = this.emailForm.validate();
    }

    return errors;
  }

  addStep(...args) {
    const { addStep } = this.props;
    addStep(...args);
    this.setState({ showAddStepDialog: false });
  }

  renderStepContent() {
    const { step, type, stepIdx } = this.props;
    const { currentForm } = this.state;

    switch (currentForm) {
      case 'sms':
        return (
          <StepTextMessageForm
            ref={(el) => this.smsForm = el}
            step={step}
            onChange={this.handleStepFormChange}
            type={type}
            key={stepIdx}
          />
        );
      case 'task':
        return (
          <StepTaskForm
            ref={(el) => this.taskForm = el}
            step={step}
            onChange={this.handleStepFormChange}
            type={type}
            key={stepIdx}
          />
        );
      case 'email':
      default:
        return (
          <StepEmailForm
            ref={(el) => this.emailForm = el}
            step={step}
            onChange={this.handleStepFormChange}
            type={type}
            key={stepIdx}
          />
        );
    }
  }

  renderTimeStepContent() {
    const {
      at, on, meridian, waitTimeValue,
    } = this.state;

    const { newEngine } = this.props;

    return (
      <>
        <div className="form-inline mb15">
          {newEngine && (
            <>
              <div className="form-group mb-2 mr-2">
                <label
                  className="d-inline-block mr-2"
                  htmlFor="campaign_step_on"
                >
                  On:
                </label>
                <CampaignStepOnSelect
                  id="campaign_step_on"
                  value={on}
                  name="campaign_step[on]"
                  onChange={(opt) => this.setStepOn(opt || '')}
                  className="d-inline-block"
                />
              </div>

              <div className="form-group mb-2 mr-2">
                <label
                  className="d-inline-block mr-2"
                  htmlFor="campaign_step_at"
                >
                  At:
                </label>
                <CampaignStepAtSelect
                  value={at}
                  name="campaign_step[at]"
                  onChange={(opt) => this.setStepAt(opt || '')}
                  className="d-inline-block"
                />
              </div>

              {at !== '*' && at !== '09:00-12:00' && (
                <div className="form-group mb-2 mr-2">
                  <MeridianSelect
                    value={meridian}
                    name="campaign_step[meridian]"
                    onChange={(opt) => this.setStepMeridian(opt || '')}
                    className="d-inline-block"
                  />
                </div>
              )}

              <div className="mr10 form-group mb-2">
                after waiting
                <Tooltipable
                  text="Specify the wait duration in days after the prior step before this step is to be executed. Note that the wait will be procesed first and then the On and At parameters in determining when this step will be executed."
                  placement="top"
                >
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control d-inline-block ml10 mr10"
                    value={waitTimeValue}
                    min={0}
                    pattern="[0-9]*"
                    step={1}
                    style={{ width: '60px' }}
                    onChange={this.onWaitTimeValueChange}
                  />
                </Tooltipable>
                <span>days</span>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  renderStepLabel() {
    const { step } = this.props;
    const { currentForm } = this.state;

    let stepLabel = 'Add the first step:';
    if (step.position > 1) stepLabel = 'Add another step:';

    if (_lodash.isNumber(step.id)) {
      stepLabel = 'Send a';
      if (currentForm === 'email') stepLabel = 'Send an';
    }

    return <span className="mr10">{stepLabel}</span>;
  }

  renderAddStepIcon() {
    const { showAddStepDialog } = this.state;
    const { stepIdx } = this.props;

    return (
      <div className="add-step-icon color-grey-dark">
        {!showAddStepDialog ? (
          <div className="d-flex justify-content-between align-items-center p-2 bg-white">
            <div className="w-50 pr-4">
              <hr />
            </div>
            <span type="button" onClick={this.onAddStepIconClick}>
              <FontAwesomeIcon icon="far fa-plus-circle" size="lg" />
            </span>
            <div className="w-50 pl-4">
              <hr />
            </div>
          </div>
        ) : (
          <CampaignStepNew
            nested
            stepIdx={stepIdx}
            addStep={this.addStep}
            onClose={this.onCloseStepIconClick}
          />
        )}
      </div>
    );
  }

  render() {
    const { subscription } = Rails;
    const {
      step,
      stepIdx,
      isDragging,
      isOver,
      newEngine,
      stepAttributes,
    } = this.props;
    const {
      currentForm,
      color,
      icon,
      prefix,
      waitTimeValue,
    } = this.state;

    const steps = [];

    stepAttributes.forEach((stp, idx) => {
      if (stp._destroy !== true) {
        steps.push({ value: idx, label: `Step ${stp.position}` });
      }
    });

    const stepClass = classNames('campaign-step', {
      'new-step':    _lodash.isNumber(step.id) === false,
      'is-dragging': isDragging,
      'is-over':     isOver,
    });

    return (
      <div>
        <div className={stepClass}>
          <span className="icon fa-stack fa-lg">
            <FontAwesomeIcon icon={['fas', 'fa-circle']} className={`fa-stack-2x ${color}`} />
            <FontAwesomeIcon icon={[`${prefix}`, `fa-${icon}`]} inverse className="fa-stack-1x" />
          </span>

          <div className="body pl-1">
            <div className="pull-right">
              <div className="mr10 ml10 d-inline-block step-dropdown-wrapper">
                <Select
                  className="step-dropdown"
                  options={steps}
                  value={stepIdx}
                  onChange={this.handleOptionChange}
                />
              </div>

              <button type="button" className="btn btn-outline-danger mr5 mb30" onClick={this.handleStepDelete}>
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </div>

            <div className="form-inline mb10">
              {this.renderStepLabel()}

              <span className="btn-group btn-group-selectable btn-log" role="group">
                <a
                  href="#email"
                  id="btn-email"
                  tabIndex="-1"
                  className={`btn btn-secondary btn-active-blue ${brokerbit.isActive(currentForm, 'email')}`}
                  data-tooltip="Email"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleSwitchForm('email');
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'fa-envelope']} className="mr5" />
                  {' '}
                  Email
                </a>

                {subscription && subscription.can_sms ? (
                  <a
                    href="#text-message"
                    id="btn-text-message"
                    tabIndex="-1"
                    className={`btn btn-secondary btn-active-yellow ${brokerbit.isActive(currentForm, 'sms')}`}
                    data-tooltip="Text"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSwitchForm('sms');
                    }}
                  >
                    <FontAwesomeIcon icon={['far', 'fa-comment']} className="mr5" />
                    {' '}
                    Text
                  </a>
                ) : (
                  <Tooltipable text="Add texting to your Brokerkit subscription to enable texting in campaigns.">
                    <a
                      href="#text-message"
                      tabIndex="-1"
                      className="btn btn-secondary disabled"
                      disabled
                    >
                      <FontAwesomeIcon icon={['far', 'fa-comment']} className="mr5" />
                      {' '}
                      Text
                    </a>
                  </Tooltipable>
                )}

                <a
                  href="#task"
                  id="btn-task"
                  tabIndex="-1"
                  className={`btn btn-secondary btn-active-green ${brokerbit.isActive(currentForm, 'task')}`}
                  data-tooltip="Task"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleSwitchForm('task');
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'fa-tasks']} className="mr5" />
                  {' '}
                  Task
                </a>
              </span>

              {!newEngine && (
                <>
                  {step.position === 1 && <span className="ml10 mr10">generated on the first day scheduled</span>}

                  {
                    step.position > 1 // only display wait time if not first step
                    && (
                    <span className="ml10 mr10">
                      after waiting
                      <input
                        type="number"
                        autoComplete="off"
                        className="form-control ml10 mr10"
                        value={waitTimeValue}
                        min={0}
                        pattern="[0-9]*"
                        step={1}
                        style={{ width: '60px' }}
                        onChange={this.onWaitTimeValueChange}
                      />
                      <span>days</span>
                    </span>
                    )
                  }
                </>
              )}
            </div>

            {this.renderTimeStepContent()}

            {this.renderStepContent()}
          </div>
        </div>
        {this.renderAddStepIcon()}
      </div>
    );
  }
}

CampaignStep.defaultProps = {
  type:                 'Campaign',
  isDragging:           false,
  isOver:               false,
  step:                 {},
  stepIdx:              null,
  updateStep:           () => false,
  deleteStep:           () => false,
  newEngine:            false,
  position:             0,
  addStep:              () => false,
  stepAttributes:       [],
};

CampaignStep.propTypes = {
  type:                 PropTypes.string,
  isDragging:           PropTypes.bool,
  isOver:               PropTypes.bool,
  step:                 PropTypes.shape({
    action_type: PropTypes.string,
  }),
  stepIdx:              PropTypes.number,
  updateStep:           PropTypes.func,
  deleteStep:           PropTypes.func,
  newEngine:            PropTypes.bool,
  position:             PropTypes.number,
  addStep:              PropTypes.func,
  stepAttributes:       PropTypes.shape([]),
};

export default CampaignStep;
