import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ message = [], className }) => {
  const errorMessage = useMemo(() => {
    if (!message || !Array.isArray(message) || message.length === 0) {
      return '';
    }
    return message[0];
  }, [message]);

  if (!errorMessage) {
    return null;
  }

  return (
    <small className={`text-red ${className}`}>
      {errorMessage}
    </small>
  );
};

ErrorMessage.defaultProps = {
  message:   [],
  className: '',
};

ErrorMessage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ])),
  ]),
  className: PropTypes.string,
};

export default ErrorMessage;
