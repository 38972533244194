import ProductHelper from '~/helpers/productHelper';

export const STAFF_RESTRICTION_LEVELS = [
  'all',
  'my+unassigned',
  'mine',
];

export function getRestrictionScope() {
  const { currentTeam, currentUser } = Rails.helpers;
  const staffRole = currentUser?.role?.name === 'staff';
  const productType = ProductHelper.product();
  const isStaffRoleAndLoggedInUser = currentTeam && currentUser && staffRole;

  return isStaffRoleAndLoggedInUser ? currentTeam[`staff_role_access_in_${productType}`] : null;
}

export function restrictOwnerScope(ownerScope) {
  const staffRestrictionIndex = STAFF_RESTRICTION_LEVELS.indexOf(getRestrictionScope());
  const ownerScopeIndex = STAFF_RESTRICTION_LEVELS.indexOf(ownerScope);

  if (staffRestrictionIndex > ownerScopeIndex) {
    ownerScope = STAFF_RESTRICTION_LEVELS[staffRestrictionIndex];
    localStorage.ownerScope = ownerScope;
  }

  return ownerScope;
}
