/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import NavBarSupportedContent from './nav_bar_supported_content';
import HelpCenter from './help_center';
import NotificationButton from '~/components/Notifications/Button';
import DocumentUploader from '~/components/navigation/document_uploader';
import DocumentActions from '~/actions/document_actions';
import DocumentLimitAlertChecker from '~/components/shared/document_limit_alert_checker';

class RetentionNavContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      showLimitAlert: false,
    };

    this.documentUploaderRef = React.createRef();
  }

  handleUploadComplete = () => {
    DocumentActions.loadDocuments();
  };

  handleDocumentUploadClick = () => {
    this.documentUploaderRef.current.handleFileSelect();
  }

  handleLoadingStateChange = (isLoading) => {
    this.setState({ isLoading });
  };

  setShowLimitAlert = (showLimitAlert) => {
    this.setState({ showLimitAlert });
  }

  onNewTeamMemberClick = (e) => {
    e.preventDefault();

    const { helpers } = this.context;

    helpers.openNewTeamMemberDrawer();
  }

  render() {
    const { abilities } = Rails;
    const { location } = this.props;
    const { isLoading, showLimitAlert } = this.state;
    const isDocumentPage = location.pathname === '/retention/documents' || location.pathname === '/retention/documents/trash';

    return (
      <Nav className="ml-auto mr-auto-sm-down" navbar>
        <div className="d-flex">
          <NotificationButton />
          <HelpCenter />
        </div>
        {isDocumentPage ? (
          <DocumentLimitAlertChecker
            showLimitAlert={showLimitAlert}
            setShowLimitAlert={this.setShowLimitAlert}
            placement="bottom"
          >
            <NavItem className="mr20-md-up">
              <NavLink
                className={`btn btn-blue btn-wide text-white ${isLoading ? 'disabled' : ''}`}
                href="#"
                onClick={this.handleDocumentUploadClick}
                disabled={isLoading}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon="spinner" spin />
                ) : (
                  <>
                    <FontAwesomeIcon icon={['far', 'fa-plus']} />
                    {' '}
                    Add Document
                  </>
                )}
              </NavLink>
              <DocumentUploader
                ref={this.documentUploaderRef}
                onUploadComplete={this.handleUploadComplete}
                onLoadingStateChange={this.handleLoadingStateChange}
                setShowLimitAlert={this.setShowLimitAlert}
              />
            </NavItem>
          </DocumentLimitAlertChecker>
        ) : (
          abilities.retention && (
            <NavItem className="mr20-md-up">
              <NavLink
                className="btn btn-blue btn-wide text-white"
                href="#new-team-member-drawer"
                onClick={this.onNewTeamMemberClick}
              >
                <FontAwesomeIcon icon={['far', 'fa-plus']} />
                {' '}
                Add Team Member
              </NavLink>
            </NavItem>
          )
        )}
        <NavBarSupportedContent />
      </Nav>
    );
  }
}

RetentionNavContent.defaultProps = {};
RetentionNavContent.propTypes = {
  location: PropTypes.object.isRequired,
};
RetentionNavContent.contextTypes = {
  helpers: PropTypes.shape({}),
};

export default withRouter(RetentionNavContent);
