import { PropTypes }       from 'prop-types';
import TimelineActivity    from '~/components/drawers/timeline/activity';

class TimelineActivityBucket extends TimelineActivity {}

TimelineActivityBucket.defaultProps = {
  title:  ({ props: { activity: { parameters: { bucket } } } }) => (
    bucket === 'archived' ? 'Bucket changed to archived' : 'Removed from archived bucket'
  ),
  icon:           'exchange-alt',
  prefix:         'fas',
  alwaysPublic:   false,
};

TimelineActivityBucket.propTypes = {
  editable:  PropTypes.bool,
  deletable: PropTypes.bool,
  title:     PropTypes.func,
  icon:      PropTypes.string,
  prefix:     PropTypes.string,
  color:     PropTypes.string,
};

export default TimelineActivityBucket;
