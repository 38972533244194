import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class ProductionLocationSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const options = [
      { value: 'closest_office_name',   label: 'Closest Office Name' },
      { value: 'closest_office_address',   label: 'Closest Office Address' },
      { value: 'distance_to_closest_office', label: 'Distance To Closest Office' },
      { value: 'distance_to_current_office', label: 'Distance To Current Office' },
      { value: 'lead_office_zip', label: 'Office Postal Code' },
      { value: 'center_of_production_zips', label: 'Center of Production Postal Code' },
    ];

    return (
      <Select
        {...this.props}
        onChange={this.props.onChange}
        options={options}
      />
    );
  }
}

ProductionLocationSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

ProductionLocationSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default ProductionLocationSelect;
