import React from 'react';
import { components } from 'react-select-4';
import Tooltipable from '~/components/effects/tooltipable';

const Control = ({ children, ...props }) => {
  const { tooltipText, tooltipPlacement, tooltipClassName } = props.selectProps;
  const control = <components.Control {...props}>{children}</components.Control>;

  return tooltipText ? (
    <Tooltipable text={tooltipText} placement={tooltipPlacement} className={tooltipClassName}>
      {control}
    </Tooltipable>
  ) : (
    control
  );
};

export default Control;
