import React from 'react'

export default function MSDocViewer({ document }) {
  const { public_url } = document

  const src = `https://view.officeapps.live.com/op/embed.aspx?src=${public_url}`

  return (
    <div className='text-center'>
      <iframe src={src} width='90%' height='623px' frameBorder='0'/>
    </div>
  )
}
