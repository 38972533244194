import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CampaignPicker from '~/components/forms/campaign_picker';
import AgentActions   from '~/actions/agent_actions';
import APIRequest     from '~/lib/api_request';
import AppModal       from './app_modal';

class RemoveCampaignModal extends React.Component {
  static propTypes = {
    bulkSelecting:      PropTypes.bool,
    countSelectedItems: PropTypes.func,
    table:              PropTypes.shape({}),
    records:            PropTypes.shape({}),
    searchData:         PropTypes.shape({}),
    selectedItems:      PropTypes.shape({}),
    unSelectedItems:    PropTypes.shape({}),
  }

  static defaultProps = {
    bulkSelecting:      false,
    countSelectedItems: () => {},
    records:            {},
    searchData:         {},
    selectedItems:      {},
    table:              {},
    unSelectedItems:    {},
  }

  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      errors:     {},
      formData:   {
        unsubscribe_from_campaign_id: null,
      },
    };
  }

  handleCampaignSelect = (selectedItem) => {
    const campaignId = selectedItem ? selectedItem.value : null;

    this.setState({ formData: { ...this.state.formData, unsubscribe_from_campaign_id: campaignId } });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.validate().then(() => {
      const $modal = $(this.appModal.modal);

      this.setState({ submitting: true }, () => {
        const {
          table,
          records,
          searchData,
          bulkSelecting,
          selectedItems,
          unSelectedItems,
        } = this.props;

        let campaignData;
        let selectedLeads = Object.values(selectedItems);
        const unselectedLeads = Object.values(unSelectedItems);

        if (bulkSelecting) {
          selectedLeads = 'all';
          campaignData = { ...searchData, ids: 'all', unselected_ids: _lodash.map(unselectedLeads, 'id') };
        } else {
          campaignData = { ...searchData, ids: _lodash.map(selectedLeads, 'id') };
        }

        APIRequest.put({
          resource: '/v1/lead_bulk_actions',
          data:     { ...campaignData, ...this.state.formData },
        }).end((error) => {
          this.setState({ submitting: false }, () => {
            if (!error) {
              const selectedItemsCount = this.props.countSelectedItems();
              GlobalContainer.notify(`${selectedItemsCount} agents are unsubscribed from the campaign`, 'warn');

              $modal.modal('hide');

              this.props.reloadLeads();
            }
          });
        });
      });
    }, (_error) => {});
  }

  validate = () => new Promise((resolve, reject) => {
    const VALIDATABLE_FIELDS = ['unsubscribe_from_campaign_id'];

    const errors = {};
    VALIDATABLE_FIELDS.forEach((field) => {
      if (!this.state.formData[field]) errors[field] = "Can't be empty";
    });

    this.setState({ errors });

    if (!Object.keys(errors).length) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    const { errors, submitting } = this.state;
    const selectedItemsCount = this.props.countSelectedItems();
    const spinnerImg = <FontAwesomeIcon icon="far fa-spinner" pulse />;
    const saveButtonClass = classNames('btn btn-primary', { disabled: submitting });

    return (
      <AppModal ref={(appModal) => this.appModal = appModal}>
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Remove
              {' '}
              <small>
                {selectedItemsCount}
                {' '}
                agents
              </small>
              {' '}
              from Campaign
              {' '}
              {submitting && spinnerImg}
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group">
              <CampaignPicker
                className={errors.unsubscribe_to_campaign_id ? 'has-error' : ''}
                onChange={this.handleCampaignSelect}
                placeholder="Select a Campaign..."
              />
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" className={saveButtonClass} disabled={submitting}>Remove</button>
          </div>
        </form>
      </AppModal>
    );
  }
}

export default RemoveCampaignModal;
