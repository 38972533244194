import React         from 'react';
import { PropTypes } from 'prop-types';

import PubNub from '~/lib/pubnub';

class Messages extends React.PureComponent {
  componentDidMount() {
    const {
      publishKey, subscribeKey, pubNubUuid, token, channel,
    } = this.props;

    this.listeners = {
      message:  this.onMessageReceived,
      presence: this.onMessagePresence,
      status:   this.onMessageStatus,
    };

    this.pubnub = PubNub.client(publishKey, subscribeKey, pubNubUuid);
    this.pubnub.setAuthKey(token);
    this.pubnub.addListener(this.listeners);

    this.pubnub.subscribe({
      channels: [channel + token],
    });
  }

  componentWillUnmount() {
    this.pubnub.removeListener(this.listeners);
  }

  onMessageReceived = (payload) => {
    if (typeof window.onSmsMessageReceived !== 'object') return;

    window.onSmsMessageReceived[payload.message.activity_id](payload);
  }

  onMessagePresence = (payload) => {
    if (typeof window.onSmsMessagePresence !== 'object') return;

    window.onSmsMessagePresence[payload.message.activity_id](payload);
  }

  onMessageStatus = (payload) => {
    if (typeof window.onSmsMessageStatus !== 'object') return;

    window.onSmsMessageStatus[payload.message.activity_id](payload);
  }

  render() {
    return null;
  }
}

Messages.propTypes = {
  channel:      PropTypes.string,
  token:        PropTypes.string,
  publishKey:   PropTypes.string.isRequired,
  subscribeKey: PropTypes.string.isRequired,
  pubNubUuid:   PropTypes.string.isRequired,
};

Messages.defaultProps = {
  channel: 'twilio',
  token:   '',
};

export default Messages;
