import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';

import { listNotifications } from '~/graphql/queries';
import { onNotificationCreates } from '~/graphql/subscriptions';

const useNotifications = (UserID, TeamID, Type, showOnlyUnread) => {
  const [notifications, setNotifications] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchNotifications = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const client = generateClient();
      const result = await client.graphql({
        query:     listNotifications,
        variables: {
          UserID,
          TeamID,
          Type,
          showOnlyUnread,
          nextToken: null,
        },
      });

      const { items, nextToken: newToken } = result.data?.listNotifications;

      setNotifications(items);
      setNextToken(newToken);
    } catch (e) {
      setError('Failed to fetch notifications. Please try again.');
      setNotifications([]);
      setNextToken(null);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextPageOfNotifications = async () => {
    setIsNextPageLoading(true);
    setError(null);

    try {
      const client = generateClient();
      const result = await client.graphql({
        query:     listNotifications,
        variables: {
          UserID,
          TeamID,
          Type,
          showOnlyUnread,
          nextToken,
        },
      });

      const { items, nextToken: newToken } = result.data?.listNotifications;

      setNotifications((prev) => [...prev, ...items]);
      setNextToken(newToken);
    } catch (e) {
      setError('Failed to fetch notifications. Please try again.');
      setNotifications([]);
      setNextToken(null);
    } finally {
      setIsNextPageLoading(false);
    }
  };

  useEffect(() => {
    setNextToken(null);
    fetchNotifications(true);
  }, [Type, showOnlyUnread]);

  useEffect(() => {
    const client = generateClient();
    const sub = client.graphql({
      query:     onNotificationCreates,
      variables: {
        UserID,
        TeamID,
      },
    }).subscribe({
      next: ({ data }) => {
        const newNotification = data.onNotificationCreates;
        if (!newNotification) return;
        setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
      },
    });

    return () => sub.unsubscribe();
  }, []);

  return {
    notifications,
    isLoading,
    isNextPageLoading,
    error,
    nextToken,
    setNotifications,
    fetchNotifications,
    fetchNextPageOfNotifications,
  };
};

export default useNotifications;
