import autoBind       from 'react-autobind';
import React          from 'react';
import ReactDOM       from 'react-dom';
import { PropTypes }  from 'prop-types';
import { withRouter } from 'react-router-dom';

import brokerbit      from '~/lib/brokerbit';
import EditLabelModal from '~/components/modals/edit_label_modal';
import LabelItem      from './label_item';

class LabelsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleLabelClick(label) {
    const { labels } = this.props;

    ReactDOM.render(
      <EditLabelModal labelID={label.id} labels={labels} />, document.getElementById('primary-modal'),
    );
  }

  handleLabelDelete(label) {
    if (_lodash.isEmpty(label)) return;

    const { deleteLabel } = this.props;

    brokerbit.confirmBox({
      message:  `Are you sure you want to permanently delete (${label.name})? This change affects ${label.leads_count} users.`,
      callback: (ok) => {
        if (ok) deleteLabel(label);
      },
    });
  }

  renderLabelRows() {
    const { labels } = this.props;

    const labelRows = [];
    labels.forEach((label, idx) => {
      labelRows.push(
        <LabelItem
          key={`label-${label.id}`}
          label={label}
          handleLabelClick={this.handleLabelClick}
          handleLabelDelete={this.handleLabelDelete}
        />,
      );
    });

    return labelRows;
  }

  render() {
    return (
      <table className="table table-hover table-responsive">
        <tbody>{this.renderLabelRows()}</tbody>
      </table>
    );
  }
}

LabelsTable.defaultProps = {
  loading:     false,
  labels:      [],
  deleteLabel: () => false,
};

LabelsTable.propTypes = {
  history:     PropTypes.shape({}).isRequired,
  loading:     PropTypes.bool,
  labels:      PropTypes.arrayOf(PropTypes.shape({})),
  deleteLabel: PropTypes.func,
};

export default withRouter(LabelsTable);
