import request from 'superagent';
import axios   from 'axios';
import qs      from 'qs';
import GlobalContainer from '~/components/global_container';

const unauthorizedRedirect = (req) => {
  req.on('response', (res) => {
    if (res.status === 401) {
      request.delete('/users/sign_out')
        .set('X-CSRF-Token', getCSRFToken())
        .end((err, response) => {
          if (!err) {
            window.location.href = '/users/sign_in';
          }
        });
    }
  });
};

const getCSRFToken = () => document.querySelector("meta[name='csrf-token']").getAttribute('content');

const APIRequest = {
  apiRequiredHeaders() {
    const headers = {};
    if (Rails.helpers.currentTeam) headers.team = Rails.helpers.currentTeam.id;
    if (Rails.helpers.authToken) {
      headers.Authentication = Rails.helpers.authToken;
    }
    return headers;
  },

  setDefaultOptions(options) {
    const defaultOpts = {
      data: {
        product: GlobalContainer?.product(),
      },
    };

    return _lodash.merge(options, defaultOpts);
  },

  ajaxRequest(resource, method, type = 'application/json', data) {
    return request(method, `${Rails.apiUrl}/api${resource}`)
      .set(this.apiRequiredHeaders())
      .type(type)
      .use(unauthorizedRedirect)
      .accept('application/json');
  },

  get(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'GET').query(
      qs.stringify(mergedOptions.data, { arrayFormat: 'brackets' }),
    );
  },

  post(options, type) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'POST', type).send(
      mergedOptions.data,
    );
  },

  put(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'PUT').send(
      mergedOptions.data,
    );
  },

  patch(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'PATCH').send(
      mergedOptions.data,
    );
  },

  delete(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'DELETE').send(
      mergedOptions.data,
    );
  },

  abort(req) {
    if (req) {
      req.abort();
    }
  },
};

export const axiosInstance = axios.create({
  baseURL:      `${Rails.apiUrl}/api`,
  headers:      APIRequest.apiRequiredHeaders(),
  responseType: 'json',
});

export const apiV2Instance = axios.create({
  baseURL:      `${Rails.apiUrl}/v2`,
  headers:      { 'Content-Type': 'application/json' },
  responseType: 'json',
});

export default APIRequest;
