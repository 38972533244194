const SubscriptionLimitActionTypes = {
  LOAD_SUBSCRIPTION_LIMITS:      'LOAD_SUBSCRIPTION_LIMITS',
  LOAD_SUBSCRIPTION_LIMITS_DONE: 'LOAD_SUBSCRIPTION_LIMITS_DONE',
  LOAD_SUBSCRIPTION_LIMITS_FAIL: 'LOAD_SUBSCRIPTION_LIMITS_FAIL',

  CHECK_IF_VIDEOS_LIMIT_REACHED:      'CHECK_IF_VIDEOS_LIMIT_REACHED',
  CHECK_IF_VIDEOS_LIMIT_REACHED_DONE: 'CHECK_IF_VIDEOS_LIMIT_REACHED_DONE',
  CHECK_IF_VIDEOS_LIMIT_REACHED_FAIL: 'CHECK_IF_VIDEOS_LIMIT_REACHED_FAIL',
};

export default SubscriptionLimitActionTypes;
