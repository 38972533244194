import React from 'react';
import PropTypes from 'prop-types';

const AllowOrgDuplicateCheckbox = ({ duplicate_org_accounts, onChange, checked }) => (
  <div className="text-gray org-duplicate-wrapper p20">
    It looks like the following duplicates exist in other accounts linked in this organization:
    {' '}
    {' '}
    <span className="account-info">
      {duplicate_org_accounts.join(', ')}
    </span>
    <div className="confirm-message mt15 mb10">
      Please confirm you want to create a duplicate before proceeding.
    </div>
    <div className="confirm-checkbox-wrapper text-black">
      <input
        id="accept_duplicate_contact"
        type="checkbox"
        className="confirm-checkbox mr5"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label htmlFor="accept_duplicate_contact">
        Allow creation of a duplicate contact in this account
      </label>
    </div>
  </div>
);

AllowOrgDuplicateCheckbox.defaultProps = {
  duplicate_org_accounts: [],
  onChange:               () => false,
};

AllowOrgDuplicateCheckbox.propTypes = {
  duplicate_org_accounts: PropTypes.shape([]),
  onChange:               PropTypes.func,
};

export default AllowOrgDuplicateCheckbox;
