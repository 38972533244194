/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onNotificationCreates = /* GraphQL */ `
  subscription OnNotificationCreates($UserID: Int, $TeamID: Int) {
    onNotificationCreates(UserID: $UserID, TeamID: $TeamID) {
      ID
      UserID
      TeamID
      Type
      IconType
      IsRead
      Title
      Content
      OriginatingObjectID
      OriginatingObjectType
      LeadID
      LeadName
      RecruitingStatus
      InboxConversationID
      InboxMessageID
      CreatedAt
      __typename
    }
  }
`;
export const onNotificationUpdates = /* GraphQL */ `
  subscription OnNotificationUpdates($UserID: Int, $TeamID: Int) {
    onNotificationUpdates(UserID: $UserID, TeamID: $TeamID) {
      ID
      UserID
      TeamID
      Type
      IconType
      IsRead
      Title
      Content
      OriginatingObjectID
      OriginatingObjectType
      LeadID
      LeadName
      RecruitingStatus
      InboxConversationID
      InboxMessageID
      CreatedAt
      __typename
    }
  }
`;
