import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class StatementSelect extends React.Component {
  constructor(props) {
    super(props);

    const options = [
      { value: 'all', label: 'ALL' },
      { value: 'any', label: 'ANY' },
    ];

    this.state = {
      options,
    };

    autoBind(this);
  }

  render() {
    const { options } = this.state;
    const { value } = this.props;

    return (
      <Select
        {...this.props}
        value={value}
        onChange={this.props.onChange}
        options={options}
        style={{ width: '70px' }}
      />
    );
  }
}

StatementSelect.defaultProps = {
  onChange:    () => {},
  value:       '',
};

StatementSelect.propTypes = {
  onChange:    PropTypes.func,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
};

export default StatementSelect;
