import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import APIRequest from '~/lib/api_request';
import Select     from '~/components/forms/select';

class TeamEmailPicker extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const value = props.value ? props.value.toString() : '';

    this.state = {
      disabled:      false,
      value,
      loading:       false,
      options:       [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = nextProps.value ? nextProps.value.toString() : '';
    this.setState({
      value,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadOptions() {
    const { contactOwner } = this.props;

    this._isMounted = true;
    this.setState({ loading: true });

    const request = APIRequest.get({
      resource: '/v1/team/teammates_options',
    });

    request.end((error, response) => {
      if (this._isMounted) {
        if (error) return;

        const options = response.body.data.map((u) => ({
          label: `${u.name} <${u.email}>`,
          value: u.email,
        }));

        if (contactOwner) options.unshift({ value: -1, label: "Contact's Owner" });

        this.setState({
          options,
          loading: false,
        });
      }
    });
  }

  render() {
    const { label, onChange } = this.props;
    const {
      loading, options, disabled, value,
    } = this.state;

    return (
      <div>
        { label && (
          <label className="d-block">{label}</label>
        )}
        <Select
          {...this.props}
          ref={(el) => { this.select = el; }}
          isLoading={loading}
          options={options}
          value={value}
          disabled={disabled}
          openOnFocus
          onChange={onChange}
        />
      </div>
    );
  }
}

TeamEmailPicker.defaultProps = {
  name:         '',
  value:        '',
  clearable:    false,
  contactOwner: false,
  onChange:     () => false,
};

TeamEmailPicker.propTypes = {
  name:         PropTypes.string,
  value:        PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  clearable:    PropTypes.bool,
  contactOwner: PropTypes.bool,
  onChange:     PropTypes.func,
};

export default TeamEmailPicker;
