import React       from 'react';
import PropTypes   from 'prop-types';
import Select      from '~/components/forms/select';
import APIRequest  from '~/lib/api_request';

class EffectivenessGradeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      options: [],
      value:   props.value || [],
    };
  }

  componentDidMount() {
    this._isMounted = true;

    APIRequest.get({
      resource: '/v1/leads/all_listing_eff_grade',
    }).end((error, response) => {
      if (this._isMounted) {
        const body = (response && response.body) || [];
        const options = _lodash.map(body, (e) =>  ({ value: e, label: e }));
        this.setState({
          options,
          loading: false,
        });
      }
    });
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : null;
    const { onChange } = this.props;

    this.setState({ value }, () => {
      onChange(selectedOption);
    });
  }

  render() {
    const {
      placeholder, multi, clearable, className,
    } = this.props;

    const { value, loading, options } = this.state;

    return (
      <Select
        className={className}
        clearable={clearable}
        placeholder={placeholder}
        value={value}
        multi={multi}
        onChange={this.handleSelectChange}
        options={options}
        isLoading={loading}
      />
    );
  }
}

EffectivenessGradeSelect.defaultProps = {
  className:   '',
  blank:       false,
  multi:       false,
  onChange:    () => {},
  placeholder: 'Select a Grade...',
  style:       {},
  value:       undefined,
};

EffectivenessGradeSelect.propTypes = {
  className:   PropTypes.string,
  blank:       PropTypes.bool,
  multi:       PropTypes.bool,
  onChange:    PropTypes.func,
  placeholder: PropTypes.string,
  style:       PropTypes.shape({}),
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default EffectivenessGradeSelect;
