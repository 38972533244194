const TimeZones = {
  options: [
    { label: 'Hawaii',                      value: 'Pacific/Honolulu' },
    { label: 'Alaska',                      value: 'America/Juneau' },
    { label: 'Pacific Time (US & Canada)',   value: 'America/Los_Angeles' },
    { label: 'Mountain Time (US & Canada)', value: 'America/Denver' },
    { label: 'Arizona',                     value: 'America/Phoenix' },
    { label: 'Central Time (US & Canada)',  value: 'America/Chicago' },
    { label: 'Eastern Time (US & Canada)',  value: 'America/New_York' },
    { label: 'Indiana (East)',              value: 'America/Indiana/Indianapolis' },
    { label: 'Atlantic Time (Canada)',      value: 'America/Halifax' },
    { label: 'Puerto Rico',                 value: 'America/Puerto_Rico' },
  ],
};

export default TimeZones;
