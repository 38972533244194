import TeamMemberPicker from '~/components/forms/team_fields/team_member_picker';

class ConsolidatedUsersWithInternalRolePicker extends TeamMemberPicker {}

ConsolidatedUsersWithInternalRolePicker.defaultProps = {
  apiResource: '/v1/videos/users_options',
  key:         'ConsolidatedUsersPicker',
};

export default ConsolidatedUsersWithInternalRolePicker;
