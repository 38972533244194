import AppDispatcher               from '~/dispatchers/app_dispatcher';
import APIRequest                  from '~/lib/api_request';
import BusinessContactsActionTypes from '~/types/business_contacts_action_types';

let createContactsRequest;
let updateContactsRequest;

const BusinnessContactsActions = {
  createContacts(contacts, tabName = null) {
    APIRequest.abort(createContactsRequest);

    AppDispatcher.dispatch({
      type: BusinessContactsActionTypes.CREATE_BUSINESS_CONTACT,
      contacts,
    });

    createContactsRequest = APIRequest.post({
      resource: '/v1/business_contacts',
      data:     { contacts },
    });

    createContactsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: BusinessContactsActionTypes.CREATE_BUSINESS_CONTACT_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     BusinessContactsActionTypes.CREATE_BUSINESS_CONTACT_DONE,
        contacts: response.body,
        tabName,
      });
    });
  },

  updateContacts(contacts, tabName = null) {
    APIRequest.abort(updateContactsRequest);

    AppDispatcher.dispatch({
      type: BusinessContactsActionTypes.UPDATE_BUSINESS_CONTACT,
      contacts,
    });

    updateContactsRequest = APIRequest.put({
      resource: '/v1/business_contacts',
      data:     { contacts },
    });

    updateContactsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: BusinessContactsActionTypes.UPDATE_BUSINESS_CONTACT_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     BusinessContactsActionTypes.UPDATE_BUSINESS_CONTACT_DONE,
        contacts: response.body,
        tabName,
      });
    });
  },
};

export default BusinnessContactsActions;
