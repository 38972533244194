import autoBind from 'react-autobind';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  InputGroup, InputGroupAddon, Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TaskSearchFilters from './search_form/task_search_filters';

class TaskFiltersForm extends React.Component {
  static defaultSearchValues() {
    return {
      query:             '',
      status:            [],
      priority:          [],
      category:          [],
      owner_id:          [],
      due_date_at_start: '',
      due_date_at_end:   '',
    };
  }

  static buildSearchData(params = {}) {
    const urlParams = _lodash.isEmpty(params) ? GlobalContainer.urlParams() : params;
    const searchObj = urlParams.s;

    if (!searchObj) return TaskFiltersForm.defaultSearchValues();

    return {
      query:             searchObj.query,
      status:            searchObj.status,
      priority:          searchObj.priority,
      category:          searchObj.category,
      owner_id:          searchObj.owner_id,
      due_date_at_start: searchObj.due_date_at_start,
      due_date_at_end:   searchObj.due_date_at_end,
    };
  }

  doAutoComplete = _lodash.debounce((searchData, query) => {
    const { handleTaskSearch } = this.props;

    handleTaskSearch({ ...searchData, query: encodeURIComponent(query) });
  }, 850)

  constructor(props) {
    super(props);

    const params = GlobalContainer.urlParams();

    this.state = {
      showFilters: false,
      searchData:  TaskFiltersForm.defaultSearchValues(),
      filtersKey:  uuidv4(),
    };

    if (params.s) {
      this.state.showFilters = true;
      this.state.searchData = TaskFiltersForm.buildSearchData();
    }

    autoBind(this);
  }

  componentDidMount() {
    // autofocus to the end of search query input text
    if (this.queryInput) {
      const len = this.queryInput.value.length;
      this.queryInput.focus();
      this.queryInput.setSelectionRange(len, len);
    }

    // listen to URL hash changes
    const { history } = this.context;
    this.unlisten = history.listen((location, action) => {
      const urlParams = GlobalContainer.urlParams();
      if (_lodash.isEmpty(urlParams) || !urlParams.s) return;

      this.setState((prevState) => ({
        searchData: {
          ...prevState.searchData,
          ...TaskFiltersForm.buildSearchData(),
        },
      }));
    }).bind(this);
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { searchData } = this.state;
    const { handleTaskSearch } = this.props;

    handleTaskSearch({ ...searchData, query: encodeURIComponent(searchData.query) });
  }

  handleQueryChange(e) {
    const query = e.target.value || '';

    this.setState((prevState) => ({
      searchData: {
        ...prevState.searchData,
        query,
      },
    }), () => {
      const { searchData } = this.state;

      this.doAutoComplete(searchData, query);
    });
  }

  handleSearchDataChange(data) {
    const { handleTaskSearch } = this.props;

    this.setState((prevState) => ({
      searchData: {
        ...prevState.searchData,
        ...data,
      },
    }), () => {
      const { searchData } = this.state;

      handleTaskSearch(searchData);
    });
  }

  handleSingleSelectChange(fieldName, fieldValue) {
    this.handleSearchDataChange({ [fieldName]: fieldValue });
  }

  handleDateSelectChange(fieldName, fieldValue) {
    this.handleSearchDataChange({ [fieldName]:  Moment.isMoment(fieldValue) ? fieldValue.format('YYYY-MM-DD') : null });
  }

  handleMultiSelectChange(fieldName, selectedOptions) {
    this.handleSearchDataChange({ [fieldName]: _lodash.map(selectedOptions, (opt) => opt.value) });
  }

  showFilters() {
    this.setState({
      showFilters: true,
    });
  }

  hideFilters() {
    this.setState({
      showFilters: false,
    });
  }

  registerQueryInput(input) {
    this.queryInput = input;
  }

  clearSearch() {
    const { handleTaskSearch } = this.props;

    this.setState({
      searchData: TaskFiltersForm.defaultSearchValues(),
      filtersKey: uuidv4(), // regenerate a key so the old component gets trashed
    });

    handleTaskSearch(TaskFiltersForm.defaultSearchValues());
  }

  renderFilters() {
    const { searchData, showFilters, filtersKey } = this.state;

    if (!showFilters) {
      return (
        <div className="form-group mb0">
          <a href="#show-filters" onClick={this.showFilters}>
            Show advanced options
          </a>
        </div>
      );
    }

    const props = {
      key:                      filtersKey,
      searchData,
      hideFilters:              this.hideFilters,
      clearSearch:              this.clearSearch,
      handleSingleSelectChange: this.handleSingleSelectChange,
      handleMultiSelectChange:  this.handleMultiSelectChange,
      handleDateSelectChange:   this.handleDateSelectChange,
    };

    return (
      <TaskSearchFilters {...props} />
    );
  }

  render() {
    const { searchData } = this.state;

    return (
      <div className="search-form card p20 mb15">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group mb5">
            <div className="row">
              <div className="col-12">
                <InputGroup className="mr5">
                  <input
                    ref={this.registerQueryInput}
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchData.query}
                    onChange={this.handleQueryChange}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="secondary">
                      <FontAwesomeIcon icon={['far', 'fa-search']} className="mr5" />
                      {' '}
                      Search
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </div>

          {this.renderFilters()}
        </form>
      </div>
    );
  }
}

TaskFiltersForm.contextTypes = {
  history:          PropTypes.shape({}),
  location:         PropTypes.shape({}),
  match:            PropTypes.shape({}),
  handleTaskSearch: PropTypes.func,
};

TaskFiltersForm.defaultProps = {
  handleTaskSearch: () => false,
};

TaskFiltersForm.propTypes = {
  handleTaskSearch: PropTypes.func,
};

export default TaskFiltersForm;
