export default class AutoCompleteUI {
  constructor(editor, aCInstance) {
    this.editor = editor;
    this.queryBy = aCInstance.options.queryBy;
  }

  renderInput = () => {
    const rawHtml = `
      <span id="mentions-autocomplete">
        <span id="autocomplete-delimiter">@</span>
        <span id="autocomplete-searchtext">
          <span class="dummy">\u200b</span>
        </span>
      </span>`;

    this.editor.execCommand('mceInsertContent', false, rawHtml);
    this.editor.focus();
    this.selectFirstDummySpan();
  };

  selectFirstDummySpan = () => {
    const dummySpan = this.editor.selection.dom.select('span#autocomplete-searchtext span')[0];
    this.editor.selection.select(dummySpan);
    this.editor.selection.collapse(0);
  };

  renderDropdown = () => {
    const dropdown = document.createElement('ul');
    dropdown.className = 'rte-autocomplete dropdown-menu';

    const listItem = document.createElement('li');
    listItem.className = 'loading text-center';

    const spinnerIcon = document.createElement('i');
    spinnerIcon.className = 'far fa-spinner';

    listItem.appendChild(spinnerIcon);

    dropdown.appendChild(listItem);

    return dropdown;
  }

  renderItem = (item, index) => `
    <a href="javascript:;">
      <img class="suggestion-img" src="${item.avatar}" width="32" height="32" />
      <span class="suggestion-text">${item[this.queryBy]}</span>
    </a>`;
}
