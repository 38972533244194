import React            from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HelpCenter extends React.PureComponent {
  /* this function declaration allows not to bind function to component in constructor */

  openHubSpotChat = (e) => {
    e.preventDefault();

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      setTimeout(this.openHubSpotChat, 1000, e);
    }
  };

  renderDropdownMenu = () => {
    const { currentUser } = Rails.helpers;
    const hr = <hr className="my-1" />;

    const LinkWithText = ({
      href = '#', children, description, separater = true, target = '_blank', onClick,
    }) => (
      <span>
        <a
          className="dropdown-item"
          href={href}
          rel="noopener noreferrer"
          target={target}
          onClick={onClick}
        >
          <h5>{children}</h5>
          {description && <p className="text-muted">{description}</p>}
        </a>
        { separater && hr }
      </span>
    );

    const hasRole = (user, role) => (
      !!user && !!user.role && user.role.name === role
    );

    const helpArticles = (
      <LinkWithText key={uuidv4()} href="https://info.getbrokerkit.com/knowledge">
        Help Articles
      </LinkWithText>
    );

    const tutorialVideos = (
      <LinkWithText key={uuidv4()} href="https://www.youtube.com/playlist?list=PL_ZP6Z-m3hE0nbNOsj2G1AWZ4vP3xtrjq">
        Tutorial Videos
      </LinkWithText>
    );

    const growthAcademyVideos = (
      <LinkWithText key={uuidv4()} href="https://www.youtube.com/playlist?list=PL_ZP6Z-m3hE2b1x_Bpg3xtlEqlRjKyRCV">
        Brokerkit Academy
      </LinkWithText>
    );

    const contactViaHubspotChat = (
      <LinkWithText
        key={uuidv4()}
        onClick={this.openHubSpotChat}
        target="_self"
      >
        Contact Support
      </LinkWithText>
    );

    const scheduleTraining = (
      <LinkWithText key={uuidv4()} href="/home/training-calendar/">Training Calendar</LinkWithText>
    );

    const customerCommunity = (
      <LinkWithText key={uuidv4()} href="https://www.facebook.com/groups/brokerkitcommunity">
        Customer Community
      </LinkWithText>
    );

    const suggestFeature = (
      <LinkWithText key={uuidv4()} href="https://ideas.brokerkit.com/?sort=popular">
        Suggest a Feature
      </LinkWithText>
    );

    const podcast = (
      <LinkWithText key={uuidv4()} href="https://podcasters.spotify.com/pod/show/brokerkit-podcast">
        Podcast
      </LinkWithText>
    );

    const webinar = (
      <LinkWithText key={uuidv4()} href="https://www.youtube.com/playlist?list=PL_ZP6Z-m3hE33aixw7Q8F_fstGEeE5v3X">
        Webinars
      </LinkWithText>
    );

    const podcastVideos = (
      <LinkWithText key={uuidv4()} href="https://www.youtube.com/playlist?list=PL_ZP6Z-m3hE1U2QBbflXA1ZdsrX0eUSRl" separater="zfalse">
        Podcast Videos
      </LinkWithText>
    );

    const changeLog = (
      <LinkWithText key={uuidv4()} href="https://ideas.brokerkit.com/sections/7268038126137756226" separater="false">
        Changelog
      </LinkWithText>
    );

    const NestedDropdown = ({ label, children }) => {
      const renderChildren = () => (
        React.Children.map(children, (child, i) => (
          React.cloneElement(child, {
            separater: (i < children.length - 1),
          })
        ))
      );

      return (
        <UncontrolledDropdown direction="right" className="submenu">
          <DropdownToggle
            className="text-nowrap d-flex justify-content-between align-items-center mr-2"
            nav
            caret
          >
            <div className="submenu-item mr-2">
              <h5>
                { label }
                {' '}
              </h5>
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <>
              { renderChildren() }
            </>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    };

    let productTrainingLinks;
    let customerSupportLinks;
    let brokerkitAcademyLinks;
    let productUpdatesLinks;

    if (hasRole(currentUser, 'agent') || hasRole(currentUser, 'affiliate')) {
      productTrainingLinks = [helpArticles];
      customerSupportLinks = [contactViaHubspotChat];
    }

    if (hasRole(currentUser, 'staff') || hasRole(currentUser, 'admin') || (!!currentUser && currentUser.admin)) {
      productTrainingLinks = [helpArticles, tutorialVideos, scheduleTraining];
      customerSupportLinks = [contactViaHubspotChat];
      brokerkitAcademyLinks = [customerCommunity, webinar, podcast, podcastVideos];
      productUpdatesLinks = [suggestFeature, changeLog];
    }

    return (
      <UncontrolledDropdown nav>
        <DropdownToggle className="text-nowrap" nav caret>
          <FontAwesomeIcon icon={['far', 'fa-life-ring']} className="mr5" />
          Help Center
        </DropdownToggle>
        <DropdownMenu>
          <>
            <NestedDropdown label="Product Training">
              {productTrainingLinks}
            </NestedDropdown>

            {customerSupportLinks && (
              <>
                {hr}
                <NestedDropdown label="Customer Support">
                  {customerSupportLinks}
                </NestedDropdown>
              </>
            )}

            {brokerkitAcademyLinks && (
              <>
                {hr}
                <NestedDropdown label="Brokerkit Academy">
                  {brokerkitAcademyLinks}
                </NestedDropdown>
              </>
            )}

            {productUpdatesLinks && (
              <>
                {hr}
                <NestedDropdown label="Product Updates">
                  {productUpdatesLinks}
                </NestedDropdown>
              </>
            )}
          </>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  render() {
    return (
      <div
        className="navbar-collapse text-center-sm-down ml-1 mr-2"
        id="navbarSupportedContent"
      >
        {this.renderDropdownMenu()}
      </div>
    );
  }
}

HelpCenter.propTypes = {};

HelpCenter.defaultProps = {};

export default HelpCenter;
