import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';
import Moment        from 'moment';

import Select from '~/components/forms/select';

class MonthPicker extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  loadOptions(input) {
    const { startDate, endDate } = this.props;

    this.options = [];

    for (let d = Moment(startDate).startOf('month'); d.isBefore(endDate); d.add(1, 'month')) {
      this.options.push({ value: d.format('YYYY-MM-DD'), label: d.format('MMMM YYYY') });
    }

    return this.options;
  }

  render() {
    return (
      <Select
        {...this.props}
        value={this.props.value}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        options={this.loadOptions()}
      />
    );
  }
}

MonthPicker.defaultProps = {
  name:        '',
  value:       '',
  placeholder: 'Select a month',
  startDate:   Moment().startOf('month'),
  endDate:     Moment().startOf('month').add(2, 'years'),
  onChange:    () => false,
};

MonthPicker.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  placeholder: PropTypes.string,
  startDate:   PropTypes.shape({}),
  endDate:     PropTypes.shape({}),
  onChange:    PropTypes.func,
};

export default MonthPicker;
