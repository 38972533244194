export const BUTTONS = [
  [
    { digit: '1' },
    { digit: '2', subDigit: 'ABC' },
    { digit: '3', subDigit: 'DEF' },
  ],
  [
    { digit: '4', subDigit: 'GHI' },
    { digit: '5', subDigit: 'JKL' },
    { digit: '6', subDigit: 'MNO' },
  ],
  [
    { digit: '7', subDigit: 'PQRS' },
    { digit: '8', subDigit: 'TUV' },
    { digit: '9', subDigit: 'WXYZ' },
  ],
  [{ digit: '*' }, { digit: '0' }, { digit: '#' }],
];
