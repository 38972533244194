import autoBind        from 'react-autobind';
import React           from 'react';
import { PropTypes }   from 'prop-types';

import GlobalContainer from '../global_container';

class BlankPage extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  /**
   * Return context to child components as props
   * https://facebook.github.io/react/docs/context.html
   * @returns {
   *   {
   *     history:  AgentDashboard.props.history,
   *     location: AgentDashboard.props.location,
   *     match:    AgentDashboard.props.match
   *   }
   * }
   */
  getChildContext() {
    const { history, location, match } = this.props;

    return {
      history,
      location,
      match,
    };
  }

  componentDidMount() {
    const { title } = this.props;

    document.title = title || 'Brokerkit';
  }

  componentDidUpdate() {
    const { title } = this.props;

    document.title = title || 'Brokerkit';
  }

  render() {
    const { children, showHeader } = this.props;

    return (
      <GlobalContainer showHeader={showHeader}>
        {children}
      </GlobalContainer>
    );
  }
}

BlankPage.childContextTypes = {
  history:  PropTypes.shape({}),
  location: PropTypes.shape({}),
  match:    PropTypes.shape({}),
};

BlankPage.defaultProps = {
  history:  {},
  location: {
    pathname: null,
  },
  match:    {
    params: {},
  },
  title:    'Brokerkit',
  children: null,
  showHeader: true,
};

BlankPage.propTypes = {
  history:  PropTypes.shape({}),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match:    PropTypes.shape({
    params: PropTypes.object,
  }),
  title:    PropTypes.string,
  children: PropTypes.node,
  showHeader: PropTypes.bool,
};

export default BlankPage;
