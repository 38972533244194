import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import AgentAlertModal from './AgentAlertModal/AgentAlertModal';
import AgentAlertPopover from './AgentAlertPopover';
import APIRequest from '~/lib/api_request';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import { formatUSDate } from '~/helpers/formatHelper';

const LeadDrawerAgentAlerts = ({ lead }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [alertsLoaded, setAlertsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const fetchAlerts = async (page) => {
    if (!lead || !lead.lead_agent_ids_attributes) return;

    try {
      setLoading(true);

      const agentData = lead.lead_agent_ids_attributes.map((agent) => ({
        agent_id:  agent.agent_id,
        reso_ouid: agent.multiple_listing_service_ouid,
      }));

      const response = await APIRequest.get({
        resource: '/v1/agent_alerts',
        data:     {
          page,
          agent_data: agentData,
        },
      });

      setAlerts(response.body.alerts);
      setTotalPages(response.body.pagination.total_pages);
      setCurrentPage(response.body.pagination.current_page);
      setLoading(false);
      setAlertsLoaded(true);
    } catch (err) {
      setError('Failed to load agent alerts');
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkInitialState = async () => {
      const savedState = localStorage.getItem('isAgentAlertsOpen');
      const initialIsOpen = savedState ? JSON.parse(savedState) : false;

      const isFeatureEnabled = await checkFeatureFlag(process.env.ALLOW_AGENT_ALERTS_SYNC);

      if (initialIsOpen && !isFeatureEnabled) {
        setIsOpen(false);
        localStorage.setItem('isAgentAlertsOpen', JSON.stringify(false));
      } else {
        setIsOpen(initialIsOpen);
      }
    };

    checkInitialState();
  }, []);

  useEffect(() => {
    if (isOpen && !alertsLoaded) {
      fetchAlerts(currentPage);
    }
  }, [isOpen]);

  const onCollapseClick = async (e, togglePopover) => {
    e.preventDefault();

    const isFeatureEnabled = await checkFeatureFlag(process.env.ALLOW_AGENT_ALERTS_SYNC);

    if (!isFeatureEnabled) {
      togglePopover(e);
      return;
    }

    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    localStorage.setItem('isAgentAlertsOpen', JSON.stringify(newIsOpen));
  };

  const handlePageClick = (page) => {
    const selectedPage = page.selected;
    fetchAlerts(selectedPage + 1);
  };

  const handleViewClick = (alert) => {
    setSelectedAlert(alert);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAlert(null);
  };

  return (
    <div>
      <div className="pull-right">
        <AgentAlertPopover>
          {({ togglePopover }) => (
            <button
              type="button"
              className="btn btn-secondary btn-sm mr-1"
              onClick={(e) => onCollapseClick(e, togglePopover)}
            >
              { isOpen ? (
                <FontAwesomeIcon icon={['far', 'fa-minus']} />
              ) : (
                <FontAwesomeIcon icon={['far', 'fa-plus']} />
              )}
            </button>
          )}
        </AgentAlertPopover>
      </div>

      <h4 className="mb15">Agent Alerts</h4>

      <Collapse in={isOpen}>
        <div className="mb-3">
          {loading && (
            <div className="text-center">
              <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
              {' '}
              Loading ...
            </div>
          )}

          {error && (
            <div className="text-center text-danger">{error}</div>
          )}

          {!loading && !error && alerts.length === 0 && (
            <div className="text-center text-muted">
              No Agent Alerts available.
            </div>
          )}

          {!loading && !error && alerts.map((alert, index) => (
            <div
              key={alert.id}
              className={classNames(
                'd-flex justify-content-between align-items-center py-3',
                { 'border-bottom': index !== alerts.length - 1 },
                { 'border-top': index === 0 },
              )}
            >
              <div className="d-flex flex-column">
                <div><strong>{formatUSDate(alert.alert_date)}</strong></div>
                <div>
                  <strong>Alert Type:</strong>
                  {' '}
                  {alert.alert_type || 'N/A'}
                </div>
                <div>
                  <strong>Role:</strong>
                  {' '}
                  {alert.role || 'N/A'}
                </div>
                <div>
                  <strong>Address:</strong>
                  {' '}
                  {alert.unparsed_address || 'N/A'}
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => handleViewClick(alert)}
                >
                  View
                </button>
              </div>
            </div>
          ))}

          {!loading && !error && totalPages > 1 && (
            <ReactPaginate
              forcePage={currentPage - 1}
              pageCount={totalPages}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center flex-row flex-md-column flex-lg-row"
              activeClassName="active"
              breakLabel="..."
              breakLinkClassName="page-link disabled"
              breakClassName="page-item"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              disabledClassName="disabled"
              onPageChange={handlePageClick}
              previousLabel={Rails.isMobile ? '<' : 'Previous'}
              nextLabel={Rails.isMobile ? '>' : 'Next'}
            />
          )}
        </div>
      </Collapse>

      {selectedAlert && (
        <AgentAlertModal
          lead={lead}
          agentAlert={selectedAlert}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

LeadDrawerAgentAlerts.defaultProps = {
  lead: null,
};

LeadDrawerAgentAlerts.propTypes = {
  lead: PropTypes.shape({}),
};

export default LeadDrawerAgentAlerts;
