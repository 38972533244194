import autoBind         from 'react-autobind';
import React            from 'react';
import ReactDOM         from 'react-dom';
import { PropTypes }    from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import brokerbit          from '~/lib/brokerbit';
import Select             from '~/components/forms/select';
import SmsTemplateModal   from '~/components/modals/sms_template_modal';
import SmsTemplateActions from '~/actions/sms_template_actions';
import SmsTemplateStore   from '~/stores/sms_template_store';

class SmsTemplateSelect extends React.Component {
  constructor(props) {
    super(props);

    const { body, media_url, media_name } = props;
    const storeState = SmsTemplateStore.getState();

    this.state = {
      originalBody:      body,
      originalMediaUrl:  media_url,
      originalMediaName: media_name,
      saving:            false,
      selectedTemplate:  null,
      templateChanged:   false,
      ...storeState,
    };

    this.componentID = uuidv4();

    autoBind(this);
  }

  componentDidMount() {
    setTimeout(() => SmsTemplateActions.loadSmsTemplates(this.componentID), 500);

    this.templateStoreListener = SmsTemplateStore.addListener(this.onTemplateStoreChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { body, media_url, media_name } = nextProps;
    const { originalBody, originalMediaName, originalMediaUrl } = this.state;

    if (body !== originalBody || media_url !== originalMediaUrl || media_name !== originalMediaName) {
      this.setState({
        templateChanged: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.templateStoreListener) this.templateStoreListener.remove();
  }

  handleTemplateSelect(selectedOption) {
    const { smsTemplates } = this.state;
    const { applyTemplate } = this.props;

    if (!selectedOption) return;

    if (selectedOption.value === -1) {
      this.renderTitleModal();
    } else {
      this.setState({
        selectedTemplate: selectedOption,
        templateChanged:  false,
      });

      const template = _lodash.find(smsTemplates, (t) => t.id === selectedOption.value);
      applyTemplate(template);
    }
  }

  handleTemplateDelete(e) {
    e.preventDefault();

    const { selectedTemplate } = this.state;

    brokerbit.confirmBox({
      message:  'Are you sure you want to remove this template?',
      callback: (ok) => {
        if (ok) {
          SmsTemplateActions.deleteSmsTemplate(selectedTemplate.value, this.componentID);
          this.setState({
            selectedTemplate: null,
          });
        }
      },
    });
  }

  onTemplateStoreChange() {
    const newState = { ...SmsTemplateStore.getState() };

    if (newState.componentID === this.componentID) {
      if (newState.smsTemplateStoreAction === 'createSmsTemplateDone' && newState.smsTemplate) {
        GlobalContainer.notify('Sms Template Added');
        newState.selectedTemplate = { label: newState.smsTemplate.title, value: newState.smsTemplate.id };
        newState.errors = {};
      }

      if (newState.smsTemplateStoreAction === 'updateSmsTemplateDone' && newState.smsTemplate) {
        GlobalContainer.notify('Sms Template Updated');
        newState.selectedTemplate = { label: newState.smsTemplate.title, value: newState.smsTemplate.id };
        newState.errors = {};
      }

      if (newState.smsTemplateStoreAction === 'updateSmsTemplateFail') {
        GlobalContainer.notify(GlobalContainer.renderErrors(newState.errors), 'error');
      }

      if (newState.smsTemplateStoreAction === 'deleteSmsTemplateDone') {
        GlobalContainer.notify('Sms Template Deleted');
      }
    }

    this.setState(newState);
  }

  buildTemplateOptions() {
    const { smsTemplates } = this.state;

    const options = [{ label: '- Create new template -', value: -1 }];

    if (smsTemplates) {
      smsTemplates.forEach((template) => {
        options.push({ label: template.title, value: template.id });
      });
    }

    return options;
  }

  updateSmsTemplate(e) {
    e.preventDefault();
    this.setState({ saving: true });

    const { selectedTemplate } = this.state;
    const {
      title, body, media_url, media_name, validateSmsTemplate,
    } = this.props;

    const errors = validateSmsTemplate();

    if (_lodash.size(errors) === 0 && selectedTemplate.value) {
      SmsTemplateActions.updateSmsTemplate({
        id:    selectedTemplate.value,
        title,
        body,
        media_url,
        media_name,
      }, this.componentID);
      this.setState({ errors });
    } else {
      this.setState({ saving: false, templateSaved: false, errors });
    }
  }

  toggleDropdownTemplateOption() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  renderTitleModal() {
    this.setState({ saving: true });

    const {
      body, media_url, media_name, validateSmsTemplate,
    } = this.props;
    const errors = validateSmsTemplate();

    if (_lodash.size(errors) === 0) {
      ReactDOM.render(
        <SmsTemplateModal
          body={body}
          media_url={media_url}
          media_name={media_name}
          componentID={this.componentID}
        />, document.getElementById('secondary-modal'),
      );
      this.setState({ errors });
    } else {
      this.setState({ saving: false, templateSaved: false, errors });
    }
  }

  render() {
    const { dropdownOpen, selectedTemplate, templateChanged } = this.state;

    return (
      <div className="d-flex">
        <div className="flex-grow-1">
          <Select
            placeholder="Select a template"
            options={this.buildTemplateOptions()}
            value={selectedTemplate && selectedTemplate.value}
            onChange={this.handleTemplateSelect}
            clearable={false}
            className="p0"
          />
        </div>

        { selectedTemplate && (
          <div className="form-group mb5 ml5">
            <div className="btn-toolbar">
              <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggleDropdownTemplateOption}>
                <DropdownToggle caret className="m0">
                  Options
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem disabled={!selectedTemplate || !templateChanged} onClick={this.updateSmsTemplate}>
                    Save changes
                  </DropdownItem>
                  <DropdownItem disabled={!selectedTemplate} onClick={this.handleTemplateDelete}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SmsTemplateSelect.defaultProps = {
  body:                '',
  media_url:           '',
  media_name:          '',
  applyTemplate:       () => false,
  validateSmsTemplate: () => false,
};

SmsTemplateSelect.propTypes = {
  body:                PropTypes.string,
  media_url:           PropTypes.string,
  media_name:          PropTypes.string,
  applyTemplate:       PropTypes.func,
  validateSmsTemplate: PropTypes.func,
};

export default SmsTemplateSelect;
