import React from 'react';

export const PauseIcon = () => (
  <svg width="12px" height="15px" viewBox="0 0 12 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs />
    <g id="pause_icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="ui_kit" transform="translate(-117.000000, -695.000000)" fill="#FFFFFF">
        <g id="Group-3" transform="translate(100.000000, 680.000000)">
          <g id="record_pause" transform="translate(17.000000, 15.000000)">
            <rect id="Rectangle-6" x="0" y="0" width="4" height="15" rx="1" />
            <rect id="Rectangle-6-Copy-2" x="8" y="0" width="4" height="15" rx="1" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
