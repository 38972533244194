import { ReduceStore }          from 'flux/utils';
import AppDispatcher            from '~/dispatchers/app_dispatcher';
import AgentActionTypes         from '~/types/agent_action_types';
import { getRestrictionScope }  from '~/helpers/staffRestrictionHelper';

class AgentStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    const staffRestriction = getRestrictionScope();

    return {
      // table scope for data below is stored as Object key
      scopedLoadings:    {}, // store loading status of different table scopes
      scopedRecords:     {}, // store different table scope data
      scopedQueries:     {}, // store search query objects
      bulkUpdatedTables: {}, // store bulk updated status for different table scopes
      ownerScope:        localStorage.ownerScope || staffRestriction || 'all',
      leadScopeSize:     parseInt(localStorage.leadScopeSize, 10) || 10,
      AgentStoreAction:  null, // store last dispatch action name
    };
  }

  resetStore(state, action) {
    return this.getInitialState();
  }

  loadLeads(state, action) {
    const newState = { ...state };
    const { table } = action;
    const staffRestriction = getRestrictionScope();

    newState.scopedLoadings[table.scope] = true;
    newState.AgentStoreAction = 'loadLeads';
    newState.ownerScope = localStorage.ownerScope || staffRestriction || 'all';

    return newState;
  }

  loadLeadsDone(state, action) {
    const newState = { ...state };
    const { table, query } = action;

    newState.scopedLoadings[table.scope] = false;
    newState.scopedRecords[table.scope] = action.response;
    newState.scopedQueries[table.scope] = query;

    newState.AgentStoreAction = 'loadLeadsDone';

    return newState;
  }

  loadLeadsFail(state, action) {
    const newState = { ...state };
    const { table } = action;

    newState.scopedLoadings[table.scope] = false;
    newState.scopedRecords[table.scope] = {
      error: "Oh no, a server error occurred. Our site admins have been notified and we'll have this fixed shortly.",
    };
    newState.AgentStoreAction = 'loadLeadsFail';

    return newState;
  }

  switchOwnerScope(state, action) {
    const newState = { ...state };

    newState.ownerScope = action.ownerScope;
    newState.AgentStoreAction = 'switchOwnerScope';

    return newState;
  }

  switchLeadScopeSize(state, action) {
    const newState = { ...state };

    newState.leadScopeSize = action.leadScopeSize;
    newState.AgentStoreAction = 'switchLeadScopeSize';

    return newState;
  }

  bulkUpdateLeads(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkUpdateLeads';

    return newState;
  }

  bulkUpdateLeadsDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.AgentStoreAction = 'bulkUpdateLeadsDone';

    return newState;
  }

  bulkUpdateLeadsFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkUpdateLeadsFail';

    return newState;
  }

  bulkUpdateTasks(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkUpdateTasks';

    return newState;
  }

  bulkUpdateTasksDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.AgentStoreAction = 'bulkUpdateTasksDone';

    return newState;
  }

  bulkUpdateTasksFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkUpdateTasksFail';

    return newState;
  }

  bulkDeleteLeads(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkDeleteLeads';

    return newState;
  }

  bulkDeleteLeadsDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.AgentStoreAction = 'bulkDeleteLeadsDone';

    return newState;
  }

  bulkDeleteLeadsFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkDeleteLeadsFail';

    return newState;
  }

  bulkDeleteTasks(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkDeleteTasks';

    return newState;
  }

  bulkDeleteTasksDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.AgentStoreAction = 'bulkDeleteTasksDone';

    return newState;
  }

  bulkDeleteTasksFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.AgentStoreAction = 'bulkDeleteTasksFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case AgentActionTypes.RESET_STORE:
        return this.resetStore(state, action);

      case AgentActionTypes.LOAD_LEADS:
        return this.loadLeads(state, action);

      case AgentActionTypes.LOAD_LEADS_DONE:
        return this.loadLeadsDone(state, action);

      case AgentActionTypes.LOAD_LEADS_FAIL:
        return this.loadLeadsFail(state, action);

      case AgentActionTypes.SWITCH_OWNER_SCOPE:
        return this.switchOwnerScope(state, action);

      case AgentActionTypes.SWITCH_LEAD_SCOPE_SIZE:
        return this.switchLeadScopeSize(state, action);

      case AgentActionTypes.BULK_UPDATE_LEADS:
        return this.bulkUpdateLeads(state, action);

      case AgentActionTypes.BULK_UPDATE_LEADS_DONE:
        return this.bulkUpdateLeadsDone(state, action);

      case AgentActionTypes.BULK_UPDATE_LEADS_FAIL:
        return this.bulkUpdateLeadsFail(state, action);

      case AgentActionTypes.BULK_UPDATE_TASKS:
        return this.bulkUpdateLeads(state, action);

      case AgentActionTypes.BULK_UPDATE_TASKS_DONE:
        return this.bulkUpdateLeadsDone(state, action);

      case AgentActionTypes.BULK_UPDATE_TASKS_FAIL:
        return this.bulkUpdateLeadsFail(state, action);

      case AgentActionTypes.BULK_DELETE_LEADS:
        return this.bulkDeleteLeads(state, action);

      case AgentActionTypes.BULK_DELETE_LEADS_DONE:
        return this.bulkDeleteLeadsDone(state, action);

      case AgentActionTypes.BULK_DELETE_LEADS_FAIL:
        return this.bulkDeleteLeadsFail(state, action);

      case AgentActionTypes.BULK_DELETE_TASKS:
        return this.bulkDeleteTasks(state, action);

      case AgentActionTypes.BULK_DELETE_TASKS_DONE:
        return this.bulkDeleteTasksDone(state, action);

      case AgentActionTypes.BULK_DELETE_TASKS_FAIL:
        return this.bulkDeleteTasksFail(state, action);

      default:
        return state;
    }
  }
}

export default new AgentStore();
