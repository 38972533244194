import { ReduceStore }     from 'flux/utils';
import AppDispatcher       from '~/dispatchers/app_dispatcher';
import CampaignActionTypes from '~/types/campaign_action_types';

class CampaignStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingCampaigns:        false,
      campaigns:               [],
      campaign:                null,
      lastCampaignStoreAction: null,
    };
  }

  loadCampaigns(state, action) {
    const newState = { ...state };

    newState.loadingCampaigns = true;
    newState.lastCampaignStoreAction = 'loadCampaigns';

    return newState;
  }

  loadCampaignsDone(state, action) {
    const newState = { ...state };

    newState.loadingCampaigns = false;
    newState.campaigns = action.campaigns;
    newState.lastCampaignStoreAction = 'loadCampaignsDone';

    return newState;
  }

  loadCampaignsFail(state, action) {
    const newState = { ...state };

    newState.loadingCampaigns = false;
    newState.lastCampaignStoreAction = 'loadCampaignsFail';

    return newState;
  }

  createCampaign(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'createCampaign';

    return newState;
  }

  createCampaignDone(state, action) {
    const newState = { ...state };

    newState.campaign = action.campaign;
    newState.lastCampaignStoreAction = 'createCampaignDone';

    return newState;
  }

  createCampaignFail(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'createCampaignFail';

    return newState;
  }

  updateCampaign(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'updateCampaign';

    return newState;
  }

  updateCampaignDone(state, action) {
    const newState = { ...state };

    newState.campaign = action.campaign;
    newState.lastCampaignStoreAction = 'updateCampaignDone';

    return newState;
  }

  updateCampaignFail(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'updateCampaignFail';

    return newState;
  }

  donateCampaign(state, action) {
    const newState = { ...state };

    newState.lastCampaignStoreAction = 'donateCampaign';

    return newState;
  }

  donateCampaignDone(state, action) {
    const newState = { ...state };

    newState.message = action.response.message;
    newState.lastCampaignStoreAction = 'donateCampaignDone';

    return newState;
  }

  donateCampaignFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error;
    newState.lastCampaignStoreAction = 'donateCampaignFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case CampaignActionTypes.LOAD_CAMPAIGNS:
        return this.loadCampaigns(state, action);

      case CampaignActionTypes.LOAD_CAMPAIGNS_DONE:
        return this.loadCampaignsDone(state, action);

      case CampaignActionTypes.LOAD_CAMPAIGNS_FAIL:
        return this.loadCampaignsFail(state, action);

      case CampaignActionTypes.CREATE_CAMPAIGN:
        return this.createCampaign(state, action);

      case CampaignActionTypes.CREATE_CAMPAIGN_DONE:
        return this.createCampaignDone(state, action);

      case CampaignActionTypes.CREATE_CAMPAIGN_FAIL:
        return this.createCampaignFail(state, action);

      case CampaignActionTypes.UPDATE_CAMPAIGN:
        return this.updateCampaign(state, action);

      case CampaignActionTypes.UPDATE_CAMPAIGN_DONE:
        return this.updateCampaignDone(state, action);

      case CampaignActionTypes.UPDATE_CAMPAIGN_FAIL:
        return this.updateCampaignFail(state, action);

      case CampaignActionTypes.DONATE_CAMPAIGN:
        return this.donateCampaign(state, action);

      case CampaignActionTypes.DONATE_CAMPAIGN_DONE:
        return this.donateCampaignDone(state, action);

      case CampaignActionTypes.DONATE_CAMPAIGN_FAIL:
        return this.donateCampaignFail(state, action);

      default:
        return state;
    }
  }
}

export default new CampaignStore();
