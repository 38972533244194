import AppDispatcher from '~/dispatchers/app_dispatcher';
import APIRequest from '~/lib/api_request';
import EmailDomainActionTypes from '~/types/email_domain_action_types';

let loadDomainsRequest;
let createDomainRequest;
let verifyDomainRequest;
let deleteDomainRequest;

const EmailDomainActions = {
  loadDomains() {
    APIRequest.abort(loadDomainsRequest);

    AppDispatcher.dispatch({
      type: EmailDomainActionTypes.LOAD_EMAIL_DOMAINS,
    });

    loadDomainsRequest = APIRequest.get({
      resource: '/v1/email_domains',
    });

    loadDomainsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: EmailDomainActionTypes.LOAD_EMAIL_DOMAINS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    EmailDomainActionTypes.LOAD_EMAIL_DOMAINS_DONE,
        domains: response.body,
      });
    });
  },

  createDomain(domain) {
    APIRequest.abort(createDomainRequest);

    AppDispatcher.dispatch({
      type: EmailDomainActionTypes.CREATE_EMAIL_DOMAIN,
      domain,
    });

    createDomainRequest = APIRequest.post({
      resource: '/v1/email_domains',
      data:     { domain },
    });

    createDomainRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: EmailDomainActionTypes.CREATE_EMAIL_DOMAIN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   EmailDomainActionTypes.CREATE_EMAIL_DOMAIN_DONE,
        domain: response.body,
      });
    });
  },

  verifyDomain(domain) {
    APIRequest.abort(verifyDomainRequest);

    AppDispatcher.dispatch({
      type: EmailDomainActionTypes.VERIFY_EMAIL_DOMAIN,
      domain,
    });

    verifyDomainRequest = APIRequest.put({
      resource: `/v1/email_domains/${domain.id}/verify`,
    });

    verifyDomainRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: EmailDomainActionTypes.VERIFY_EMAIL_DOMAIN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   EmailDomainActionTypes.VERIFY_EMAIL_DOMAIN_DONE,
        domain: response.body,
      });
    });
  },

  deleteDomain(domain) {
    APIRequest.abort(deleteDomainRequest);

    AppDispatcher.dispatch({
      type: EmailDomainActionTypes.DELETE_EMAIL_DOMAIN,
      domain,
    });

    deleteDomainRequest = APIRequest.delete({
      resource: `/v1/email_domains/${domain.id}`,
    });

    deleteDomainRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: EmailDomainActionTypes.DELETE_EMAIL_DOMAIN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: EmailDomainActionTypes.DELETE_EMAIL_DOMAIN_DONE,
        domain,
      });
    });
  },
};

export default EmailDomainActions;
