import AppDispatcher         from '~/dispatchers/app_dispatcher';
import BulkSelectActionTypes from '~/types/bulk_select_action_types';

const BulkSelectActions = {

  /**
   * Select a single document item for bulk action
   * @param {Object} table - Document info table
   * @param {Object} documentItem
   */
  selectDocumentItem(table, documentItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_DOCUMENT_ITEM,
      tableScope: table.scope,
      documentItem,
    });
  },

  /**
   * De-select a single document item for bulk action
   * @param {Object} table - Document info table
   * @param {Object} documentItem
   */
  deselectDocumentItem(table, documentItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_DOCUMENT_ITEM,
      tableScope: table.scope,
      documentItem,
    });
  },

  /**
     * Select multiple document items for bulk action
     * @param {Object} table - Document info table
     * @param {Object[]} documentItems - Array of document items to be selected
     */
  selectDocumentItems(table, documentItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_DOCUMENT_ITEMS, // Action type for selecting document items
      tableScope: table.scope, // Scope of the table
      documentItems, // Array of document items to be selected
    });
  },

  /**
   * De-select multiple document items for bulk action
   * @param {Object} table - Document info table
   * @param {Object[]} documentItems - Array of document items to be deselected
   */
  deselectDocumentItems(table, documentItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_DOCUMENT_ITEMS, // Action type for deselecting document items
      tableScope: table.scope, // Scope of the table
      documentItems, // Array of document items to be deselected
    });
  },

  /**
   * Select a single trash document item for bulk action
   * @param {Object} table - Document info table
   * @param {Object} trashDocumentItem
   */
  selectTrashDocumentItem(table, trashDocumentItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_TRASH_DOCUMENT_ITEM,
      tableScope: table.scope,
      trashDocumentItem,
    });
  },

  /**
   * De-select a single trash document item for bulk action
   * @param {Object} table - Document info table
   * @param {Object} trashDocumentItem
   */
  deselectTrashDocumentItem(table, trashDocumentItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_TRASH_DOCUMENT_ITEM,
      tableScope: table.scope,
      trashDocumentItem,
    });
  },

  /**
   * Select multiple trash document items for bulk action
   * @param {Object} table - Document info table
   * @param {Object[]} trashDocumentItems - Array of trash document items to be selected
   */
  selectTrashDocumentItems(table, trashDocumentItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_TRASH_DOCUMENT_ITEMS,
      tableScope: table.scope,
      trashDocumentItems,
    });
  },

  /**
   * De-select multiple trash document items for bulk action
   * @param {Object} table - Document info table
   * @param {Object[]} trashDocumentItems - Array of trash document items to be deselected
   */
  deselectTrashDocumentItems(table, trashDocumentItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_TRASH_DOCUMENT_ITEMS,
      tableScope: table.scope,
      trashDocumentItems,
    });
  },

  /**
   * Select all items for bulk action
   * @param {Object} table - Lead/Task info table
   */
  selectAllItems(table) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_ALL_ITEMS,
      tableScope: table.scope,
    });
  },

  /**
   * De-select all items for bulk action
   * @param {Object} table - Lead/Task info table
   */
  deselectAllItems(table) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_ALL_ITEMS,
      tableScope: table.scope,
    });
  },

  /**
   * Select multiple lead items for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object[]} leadItems
   */
  selectLeadItems(table, leadItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_LEAD_ITEMS,
      tableScope: table.scope,
      leadItems,
    });
  },

  /**
   * De-select multiple lead items for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object[]} leadItems
   */
  deselectLeadItems(table, leadItems) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_LEAD_ITEMS,
      tableScope: table ? table.scope : null,
      leadItems,
    });
  },

  /**
   * Select a single lead item for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object} leadItem
   */
  selectLeadItem(table, leadItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.SELECT_LEAD_ITEM,
      tableScope: table.scope,
      leadItem,
    });
  },

  /**
   * De-select a single lead item for bulk action
   * @param {Object} table - Lead/Task info table
   * @param {Object} leadItem
   */
  deselectLeadItem(table, leadItem) {
    AppDispatcher.dispatch({
      type:       BulkSelectActionTypes.DESELECT_LEAD_ITEM,
      tableScope: table.scope,
      leadItem,
    });
  },
};

export default BulkSelectActions;
