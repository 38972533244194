import React          from 'react';
import Moment         from 'moment';
import DateTimePicker from '../forms/DateTimePicker';

const StyleguideDatepicker = (props) => (
  <div className="mb15">
    <DateTimePicker
      {...props}
      pickerType="date"
      maxDate={Moment().add(4, 'days').toDate()}
    />
  </div>
);

export default StyleguideDatepicker;
