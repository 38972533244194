const DocumentActionTypes = {
  LOAD_DOCUMENTS:      'LOAD_DOCUMENTS',
  LOAD_DOCUMENTS_DONE: 'LOAD_DOCUMENTS_DONE',
  LOAD_DOCUMENTS_FAIL: 'LOAD_DOCUMENTS_FAIL',

  LOAD_DOCUMENT:      'LOAD_DOCUMENT',
  LOAD_DOCUMENT_DONE: 'LOAD_DOCUMENT_DONE',
  LOAD_DOCUMENT_FAIL: 'LOAD_DOCUMENT_FAIL',

  CREATE_DOCUMENT:      'CREATE_DOCUMENT',
  CREATE_DOCUMENT_DONE: 'CREATE_DOCUMENT_DONE',
  CREATE_DOCUMENT_FAIL: 'CREATE_DOCUMENT_FAIL',

  UPDATE_DOCUMENT:      'UPDATE_DOCUMENT',
  UPDATE_DOCUMENT_DONE: 'UPDATE_DOCUMENT_DONE',
  UPDATE_DOCUMENT_FAIL: 'UPDATE_DOCUMENT_FAIL',

  DELETE_DOCUMENT:      'DELETE_DOCUMENT',
  DELETE_DOCUMENT_DONE: 'DELETE_DOCUMENT_DONE',
  DELETE_DOCUMENT_FAIL: 'DELETE_DOCUMENT_FAIL',

  LOAD_DOCUMENTS_WITH_RELATED_CONTACT:      'LOAD_DOCUMENTS_WITH_RELATED_CONTACT',
  LOAD_DOCUMENTS_WITH_RELATED_CONTACT_DONE: 'LOAD_DOCUMENTS_WITH_RELATED_CONTACT_DONE',
  LOAD_DOCUMENTS_WITH_RELATED_CONTACT_FAIL: 'LOAD_DOCUMENTS_WITH_RELATED_CONTACT_FAIL',

  SWITCH_DOCUMENT_SCOPE_SIZE:     'SWITCH_DOCUMENT_SCOPE_SIZE',

  LOAD_TRASHED_DOCUMENTS:      'LOAD_TRASHED_DOCUMENTS',
  LOAD_TRASHED_DOCUMENTS_DONE: 'LOAD_TRASHED_DOCUMENTS_DONE',
  LOAD_TRASHED_DOCUMENTS_FAIL: 'LOAD_TRASHED_DOCUMENTS_FAIL',

  LOAD_CATEGORIES:      'LOAD_CATEGORIES',
  LOAD_CATEGORIES_DONE: 'LOAD_CATEGORIES_DONE',
  LOAD_CATEGORIES_FAIL: 'LOAD_CATEGORIES_FAIL',

  LOAD_STATUSES:      'LOAD_STATUSES',
  LOAD_STATUSES_DONE: 'LOAD_STATUSES_DONE',
  LOAD_STATUSES_FAIL: 'LOAD_STATUSES_FAIL',

  RESTORE_DOCUMENT:      'RESTORE_DOCUMENT',
  RESTORE_DOCUMENT_DONE: 'RESTORE_DOCUMENT_DONE',
  RESTORE_DOCUMENT_FAIL: 'RESTORE_DOCUMENT_FAIL',

  PERMANENT_DELETE_DOCUMENT:      'PERMANENT_DELETE_DOCUMENT',
  PERMANENT_DELETE_DOCUMENT_DONE: 'PERMANENT_DELETE_DOCUMENT_DONE',
  PERMANENT_DELETE_DOCUMENT_FAIL: 'PERMANENT_DELETE_DOCUMENT_FAIL',

  BULK_RESTORE_TRASH_DOCUMENTS:      'BULK_RESTORE_TRASH_DOCUMENTS',
  BULK_RESTORE_TRASH_DOCUMENTS_DONE: 'BULK_RESTORE_TRASH_DOCUMENTS_DONE',
  BULK_RESTORE_TRASH_DOCUMENTS_FAIL: 'BULK_RESTORE_TRASH_DOCUMENTS_FAIL',

  BULK_DELETE_TRASH_DOCUMENTS:      'BULK_DELETE_TRASH_DOCUMENTS',
  BULK_DELETE_TRASH_DOCUMENTS_DONE: 'BULK_DELETE_TRASH_DOCUMENTS_DONE',
  BULK_DELETE_TRASH_DOCUMENTS_FAIL: 'BULK_DELETE_TRASH_DOCUMENTS_FAIL',

  BULK_UPDATE_DOCUMENTS:      'BULK_UPDATE_DOCUMENTS',
  BULK_UPDATE_DOCUMENTS_DONE: 'BULK_UPDATE_DOCUMENTS_DONE',
  BULK_UPDATE_DOCUMENTS_FAIL: 'BULK_UPDATE_DOCUMENTS_FAIL',

  BULK_DELETE_DOCUMENTS:      'BULK_DELETE_DOCUMENTS',
  BULK_DELETE_DOCUMENTS_DONE: 'BULK_DELETE_DOCUMENTS_DONE',
  BULK_DELETE_DOCUMENTS_FAIL: 'BULK_DELETE_DOCUMENTS_FAIL',
};

export default DocumentActionTypes;
