import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class ProductionDatesSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const options = [
      { value: 'first_transaction_current_office',   label: 'First Transaction At Current Office' },
      { value: 'first_transaction_mls', label: 'First Transaction At MLS' },
    ];

    return (
      <Select
        {...this.props}
        onChange={this.props.onChange}
        options={options}
      />
    );
  }
}

ProductionDatesSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

ProductionDatesSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default ProductionDatesSelect;
