import React from 'react';
import { ModalStates } from '../states/ModalStates';
import Feedback from './Feedback';

const Buttons = ({
  state,
  dispatch,
  handleGenerateText,
  handleInsert,
  handleRegenerate,
  handleRevisePrompt,
  handleNewPrompt,
  handleRetry,
}) => (
  <div className="mt-3">
    {state.status === ModalStates.INITIAL && !state.error && (
      <button
        type="button"
        className="btn btn-success mr-2"
        onClick={() => handleGenerateText()}
      >
        Generate Message
      </button>
    )}

    {(state.status === ModalStates.ERROR || state.error) && (
      <div className="mt-2">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleRetry}
        >
          Retry
        </button>
      </div>
    )}

    {(state.status === ModalStates.FINISHED || state.status === ModalStates.GENERATING) && (
      <>
        <button
          type="button"
          className="btn btn-success mr-1 mb-2"
          onClick={handleInsert}
          disabled={state.status === ModalStates.GENERATING || state.feedbackSending}
        >
          Insert
        </button>

        <button
          type="button"
          className="btn btn-outline-success mr-1 mb-2"
          onClick={handleRegenerate}
          disabled={state.status === ModalStates.GENERATING || state.feedbackSending}
        >
          Regenerate
        </button>

        <button
          type="button"
          className="btn btn-outline-success mr-1 mb-2"
          onClick={handleRevisePrompt}
          disabled={state.status === ModalStates.GENERATING || state.feedbackSending}
        >
          Revise Prompt
        </button>

        <button
          type="button"
          className="btn btn-outline-success mr-1 mb-2"
          onClick={handleNewPrompt}
          disabled={state.status === ModalStates.GENERATING || state.feedbackSending}
        >
          New Prompt
        </button>

        {state.status === ModalStates.FINISHED && state.conversationId && (
          <Feedback state={state} dispatch={dispatch} />
        )}
      </>
    )}
  </div>
);

export default Buttons;
