import autoBind                from 'react-autobind';
import React                   from 'react';
import { PropTypes }           from 'prop-types';
import APIRequest              from '~/lib/api_request';
import LimitExceededWarning    from '~/components/warnings/LimitExceededWarning';
import { checkFeatureFlag }    from '~/helpers/FeatureFlagChecker';

class CheckSmsUsage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      smsStats:           {},
      isLimitExceeded:    false,
      sms_limit:          Rails.subscription_limit.sms_limit,
      warningAccepted:    false,
      limitAlertAllowed:  false,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.setState({
      limitAlertAllowed: checkFeatureFlag(process.env.ALLOW_ALERT_ON_TEXT_LIMIT_FLAG)
    });

    this.loadSmsStats();
  }

  componentDidUpdate(prevProps) {
    const { segmentsCount, campaignId } = this.props;

    if (prevProps.segmentsCount !== segmentsCount || prevProps.campaignId !== campaignId) {
      this.checkExceedingLimit();

      if (campaignId !== null) {
        this.setState({ warningAccepted: false });
      }
    }
  }

  loadSmsStats = () => {
    APIRequest.get({ resource: '/v1/statistics/sms_messages' })
      .end((err, response) => {
        if (response && response.ok) {
          const smsStats = response.body;
          this.setState({ smsStats });
        }
      });
  };

  acceptWarning = () => {
    const { onWarningAccepted } = this.props;

    this.setState({ warningAccepted: true });
    onWarningAccepted();
  }

  checkExceedingLimit = () => {
    const {
      selectedItemsCount, segmentsCount, onWarningUpdated,
    } = this.props;

    const { sms_limit, smsStats, isLimitExceeded } = this.state;
    const totalSegmentsUsage = segmentsCount * selectedItemsCount + (smsStats?.thisMonth ?? 0);

    if (totalSegmentsUsage > sms_limit) {
      this.setState({ isLimitExceeded: true }, () => {
        onWarningUpdated(true);
      });
    } else if (isLimitExceeded) {
      this.setState({ isLimitExceeded: false }, () => {
        onWarningUpdated(false);
      });
    }
  }

  render() {
    const { smsStats, isLimitExceeded, warningAccepted, limitAlertAllowed } = this.state;
    const {
      selectedItemsCount, textMessagesCount, segmentsCount,
    } = this.props;

    return (
      limitAlertAllowed && isLimitExceeded && !warningAccepted && (
        <LimitExceededWarning
          smsStats={smsStats}
          segmentsCount={segmentsCount}
          selectedItemsCount={selectedItemsCount}
          onWarningAccepted={this.acceptWarning}
          textMessagesCount={textMessagesCount}
        />
      )
    );
  }
}

CheckSmsUsage.defaultProps = {
  textMessagesCount:    1,
  segmentsCount:        0,
  selectedItemsCount:   0,
  onWarningAccepted:    () => {},
  onWarningUpdated:     () => {},
  campaignId:           null,
};

CheckSmsUsage.propTypes = {
  textMessagesCount:    PropTypes.number,
  segmentsCount:        PropTypes.number,
  selectedItemsCount:   PropTypes.number,
  onWarningAccepted:    PropTypes.func,
  onWarningUpdated:     PropTypes.func,
  campaignId:           PropTypes.number,
};

export default CheckSmsUsage;
