import autoBind             from 'react-autobind';
import React                from 'react';
import ReactDOM             from 'react-dom';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer          from '~/components/global_container';
import CampaignLibraryActions   from '~/actions/campaign_library_actions';
import CopyCampaignLibraryModal from '~/components/modals/copy_campaign_library_modal';
import CampaignPreviewStep      from './campaign_preview_step';

class PreviewCampaign extends React.Component {
  constructor(props) {
    super(props);

    const { campaignID } = props;

    this.state = {
      loadingCampaign: !!campaignID,
      campaign:        null,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { campaignID } = this.props;

    if (campaignID) {
      CampaignLibraryActions.loadCampaign(campaignID, this.onCampaignLoad);
    }
  }

  onCampaignLoad(campaign) {
    this.setState({
      loadingCampaign: false,
      campaign,
    });
  }

  handleCopyClick = (e) => {
    e.preventDefault();

    const { campaign } = this.state;

    ReactDOM.render(
      <CopyCampaignLibraryModal campaign={campaign} />, document.getElementById('primary-modal'),
    );
  };

  renderCampaignSteps() {
    const { campaign } = this.state;
    const steps = [];

    campaign.campaign_steps.forEach((step, idx) => {
      steps.push(
        <CampaignPreviewStep
          key={`step-${step.id}`}
          step={step}
          campaign={campaign}
        />,
      );
    });

    return steps;
  }

  renderCampaign() {
    const { loadingCampaign, campaign } = this.state;
    const { history } = this.props;

    if (loadingCampaign) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    const campaign_libraries_uri = GlobalContainer.productUri('/campaign_libraries');

    return (
      <>
        <div className="form-group">
          <span className="text-grey">Campaign: </span>
          {' '}
          {campaign.name}
        </div>

        {this.renderCampaignSteps()}

        <div className="pull-right">
          <Link
            to={{
              pathname: campaign_libraries_uri,
              page:     history.location.state?.page,
            }}
            className="btn btn-secondary mr5"
          >
            Back to the Library
          </Link>
          <button type="button" onClick={this.handleCopyClick} className="btn btn-primary">Copy Campaign</button>
        </div>
      </>
    );
  }

  render() {
    const { history } = this.props;
    const campaign_libraries_uri = GlobalContainer.productUri('/campaign_libraries');

    return (
      <div className="card campaign">
        <div className="card-block">
          <h4 className="mb15">
            <div className="pull-right">
              <Link
                to={{
                  pathname: campaign_libraries_uri,
                  page:     history.location.state?.page,
                }}
                className="btn btn-secondary mr5"
              >
                Back to the Library
              </Link>
              <button type="button" className="btn btn-success mr5" onClick={this.handleCopyClick}>
                Copy Campaign
              </button>
            </div>

            Campaign Library
            <br />
          </h4>

          {this.renderCampaign()}
        </div>
      </div>
    );
  }
}

PreviewCampaign.defaultProps = {
  campaignID: null,
};

PreviewCampaign.propTypes = {
  campaignID: PropTypes.string,
  history:    PropTypes.shape({}).isRequired,
};

export default withRouter(PreviewCampaign);
