const LabelActionTypes = {
  LOAD_LABELS:      'LOAD_LABELS',
  LOAD_LABELS_DONE: 'LOAD_LABELS_DONE',
  LOAD_LABELS_FAIL: 'LOAD_LABELS_FAIL',

  LOAD_LABEL:      'LOAD_LABEL',
  LOAD_LABEL_DONE: 'LOAD_LABEL_DONE',
  LOAD_LABEL_FAIL: 'LOAD_LABEL_FAIL',

  CREATE_LABEL:      'CREATE_LABEL',
  CREATE_LABEL_DONE: 'CREATE_LABEL_DONE',
  CREATE_LABEL_FAIL: 'CREATE_LABEL_FAIL',

  UPDATE_LABEL:      'UPDATE_LABEL',
  UPDATE_LABEL_DONE: 'UPDATE_LABEL_DONE',
  UPDATE_LABEL_FAIL: 'UPDATE_LABEL_FAIL',

  DELETE_LABEL:      'DELETE_LABEL',
  DELETE_LABEL_DONE: 'DELETE_LABEL_DONE',
  DELETE_LABEL_FAIL: 'DELETE_LABEL_FAIL',
};

export default LabelActionTypes;
