import React, { memo } from 'react';
import { PropTypes }   from 'prop-types';

import DateTimePicker from '~/components/forms/DateTimePicker';
import TimezoneSelect from '~/components/forms/dropdowns/TimezoneSelect';

const CurrentDate = Moment().startOf('day').toDate();

const CustomScheduleOption = memo(({
  customOptions,
  errors,
  isCustomSelected,
  updateCustomOptions,
}) => {
  const { date, time, timezone } = customOptions;

  const handleDateChange = (value) => {
    updateCustomOptions('date', value.format('MMM Do YYYY'));
  };

  const handleTimeChange = (value) => {
    updateCustomOptions('time', value.format('HH:mm'));
  };

  const handleTimezoneChange = (opt) => {
    updateCustomOptions('timezone', opt?.value);
  };

  return (
    <>
      <div className="d-flex mt-1">
        <div className="col pl-0 pr-1">
          <DateTimePicker
            placeholder="Select Custom Date"
            format="MMM Do YYYY"
            pickerType="date"
            minDate={CurrentDate}
            value={date}
            isDisabled={!isCustomSelected}
            isInvalid={!!errors?.date}
            onChange={handleDateChange}
          />
        </div>

        <div className="col pl-1 pr-0">
          <DateTimePicker
            placeholder="Select Custom Time"
            format="hh:mm A"
            pickerType="time"
            value={time}
            isDisabled={!isCustomSelected}
            isInvalid={!!errors?.time}
            onChange={handleTimeChange}
          />
        </div>
      </div>

      {isCustomSelected && (
        <div className="d-flex mt-1">
          <div className="col p0">
            <TimezoneSelect
              value={timezone}
              onChange={handleTimezoneChange}
              isInvalid={!!errors?.timezone}
            />
          </div>
        </div>
      )}
    </>
  );
});

CustomScheduleOption.propTypes = {
  customOptions: PropTypes.shape({
    time: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    timezone: PropTypes.string,
  }),
  errors:              PropTypes.shape({}),
  isCustomSelected:    PropTypes.bool.isRequired,
  updateCustomOptions: PropTypes.func.isRequired,
};

CustomScheduleOption.defaultProps = {
  customOptions: {
    time:     '',
    date:     '',
    timezone: '',
  },
  errors: {},
};

export default CustomScheduleOption;
