import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit      from '~/lib/brokerbit';
import ActivityEditor from '~/components/forms/HtmlEditors/ActivityEditor';
import APIRequest     from '~/lib/api_request';
import AppModal       from './app_modal';

class AddNoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      formData:   {
        activity: {
          body: null,
        },
        event: 'note',
      },
    };
  }

  setEventType = (eventType) => () => {
    this.setState({ formData: { ...this.state.formData, event: eventType } });
  }

  handleBodyChange = (body) => {
    this.setState({ formData: { ...this.state.formData, activity: { body } } });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const $modal = $(this.appModal.modal);

    this.setState({ submitting: true }, () => {
      const { formData } = this.state;
      const {
        searchData,
        bulkSelecting,
        selectedItems,
        unSelectedItems,
        reloadLeads,
      } = this.props;

      let selectedLeads = Object.values(selectedItems);
      const unselectedLeads = Object.values(unSelectedItems);

      let noteData;

      if (bulkSelecting) {
        selectedLeads = 'all';
        noteData = { ...searchData, ids: 'all', unselected_ids: _lodash.map(unselectedLeads, 'id') };
      } else {
        noteData = { ...searchData, ids: _lodash.map(selectedLeads, 'id') };
      }

      APIRequest.put({
        resource: '/v1/lead_bulk_actions/create_activities',
        data:     { ...noteData, ...formData },
      }).end((error) => {
        this.setState({ submitting: false }, () => {
          if (!error) {
            $modal.modal('hide');
            reloadLeads();
          }
        });
      });
    });
  }

  render() {
    const { formData, submitting } = this.state;
    const { countSelectedItems } = this.props;
    const spinnerImg = <FontAwesomeIcon icon="far fa-spinner" pulse />;
    const saveButtonClass = classNames('btn btn-primary', { disabled: submitting });

    return (
      <AppModal ref={(appModal) => this.appModal = appModal} dialogClass="modal-dialog modal-lg">
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Add Note
              {' '}
              <small>
                (to
                {' '}
                {countSelectedItems()}
                {' '}
                items)
              </small>
              {' '}
              {submitting && spinnerImg}
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group mb5">
              <h3 className="mb20">
                <div className="btn-group pull-right" role="group">
                  <button
                    type="button"
                    className={
                      `btn btn-secondary btn-active-pink ${brokerbit.isActive('phone_call', formData.event)}`
                    }
                    onClick={this.setEventType('phone_call')}
                  >
                    <FontAwesomeIcon icon={['far', 'fa-phone-alt']} />
                  </button>
                  <button
                    type="button"
                    className={
                      `btn btn-secondary btn-active-yellow ${brokerbit.isActive('text_message', formData.event)}`
                    }
                    onClick={this.setEventType('text_message')}
                  >
                    <FontAwesomeIcon icon={['far', 'fa-comment']} />
                  </button>
                  <button
                    type="button"
                    className={
                      `btn btn-secondary btn-active-blue ${brokerbit.isActive('email', formData.event)}`
                    }
                    onClick={this.setEventType('email')}
                  >
                    <FontAwesomeIcon icon={['far', 'fa-envelope']} />
                  </button>
                  <button
                    type="button"
                    className={
                      `btn btn-secondary btn-active-purple ${brokerbit.isActive('note', formData.event)}`
                    }
                    onClick={this.setEventType('note')}
                  >
                    <FontAwesomeIcon icon={['far', 'fa-sticky-note']} />
                  </button>
                </div>

                {_lodash.startCase(formData.event)}
              </h3>
            </div>

            <div className="form-group mb5">
              <ActivityEditor
                value={formData.activity.body}
                onChange={this.handleBodyChange}
                placeholder="Enter Text"
              />
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" className={saveButtonClass} disabled={submitting}>Save</button>
          </div>
        </form>
      </AppModal>
    );
  }
}

AddNoteModal.defaultProps = {
  countSelectedItems: () => {},
  table:              {},
  records:            {},
  selectedItems:      {},
  bulkSelecting:      false,
  searchData:         {},
  unSelectedItems:    {},
};

AddNoteModal.propTypes = {
  countSelectedItems: PropTypes.func,
  table:              PropTypes.shape({}),
  records:            PropTypes.shape({}),
  selectedItems:      PropTypes.shape({}),
  bulkSelecting:      PropTypes.bool,
  searchData:         PropTypes.shape({}),
  unSelectedItems:    PropTypes.shape({}),
};

export default AddNoteModal;
