import React, { useEffect, useRef } from 'react'
import Viewer from 'viewerjs';

export default function ImageDocViewer({ document }) {
  const { public_url } = document
  const imageRef = useRef(null);

    useEffect(() => {
    const viewer = new Viewer(imageRef.current, {
      inline: false,
    });

    return () => {
      viewer.destroy();
    }
  }, [imageRef]);

  return (
    <div ref={imageRef}>
      <div className='text-center'>
        <img
          src={public_url}
          alt='image'
          width='100%'
        />
      </div>
    </div>
  )
}
