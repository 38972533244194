import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'webrtc-adapter';
import RecordRTCPromisesHandler, { MediaStreamRecorder, WhammyRecorder } from 'recordrtc';
import {
  Decoder,
  Encoder,
  tools,
  Reader,
} from 'ts-ebml';

import { RecordingIcon }  from '~/helpers/icons/recording_icon';
import { SettingsIcon }   from '~/helpers/icons/settings_icon';
import { FullScreenIcon } from '~/helpers/icons/full_screen_icon';
import { PauseIcon }      from '~/helpers/icons/pause_icon';

import brokerbit            from '~/lib/brokerbit';
import VideoRecorderHelpers from '~/helpers/video_recorder_helpers';
import VideoPlayer          from '~/helpers/video_player';

let mimeType = 'video/x-matroska;codecs=avc1';
let recorderType = MediaStreamRecorder;

const mediaConstraint = {
  audio: true,
  video: {
    width:  { ideal: 1280 },
    height: { ideal: 720 },
  },
};

class VideoRecorder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview:            false,
      hasUserMedia:       false,
      recording:          false,
      paused:             false,
      maxDurtion:         Number(process.env.MAX_VIDEO_DURATION),
      streamDuration:     0,
      startRecordingTime: null,
      pauseRecordingTime: null,
      showSettings:       false,
      videoInputs:        [],
      audioInputs:        [],
      videoInput:         null,
      audioInput:         null,
      videoConstraints:   mediaConstraint,
    };

    this.unmounted = false;

    autoBind(this);
  }

  componentDidMount() {
    this.unmounted = false;

    if (!this.hasGetUserMedia()) {
      this.setState({ error: 'Video Recording is not supported.' });

      return;
    }

    this.setMimeTypeAndRecorder();
    this.requestUserMedia();

    navigator.mediaDevices.addEventListener('devicechange', this.onChangeMediaDevice);
  }

  componentDidUpdate(prevProps, prevState) {
    const { audioInput, videoInput, videoConstraints } = this.state;

    if (!this.hasGetUserMedia()) {
      this.setState({ error: 'Video Recording is not supported.' });

      return;
    }

    const audioInputChanged = prevState.audioInput !== audioInput;
    const videoInputChanged = prevState.videoInput !== videoInput;
    const videoConstraintsChanged = prevState.videoConstraints.video !== videoConstraints.video;

    if (
      audioInputChanged
      || videoInputChanged
      || videoConstraintsChanged
    ) {
      this.stopAndCleanup();
      this.requestUserMedia();
    }
  }

  componentWillUnmount() {
    const { blob } = this.state;

    navigator.mediaDevices.removeEventListener('devicechange', this.onChangeMediaDevice);

    this.unmounted = true;
    this.stopAndCleanup();

    if (this.videoNode.current) this.videoNode.current.destroy();
  }

  isMimeTypeSupported = (_mimeType) => {
    if (typeof MediaRecorder.isTypeSupported !== 'function') {
      return true;
    }

    return MediaRecorder.isTypeSupported(_mimeType);
  };

  setMimeTypeAndRecorder = () => {
    if (this.isMimeTypeSupported(mimeType) === false) {
      mimeType = 'video/mp4';
      recorderType = MediaStreamRecorder;
      if (this.isMimeTypeSupported(mimeType) === false) {
        mimeType = 'video/webm;codecs=h264';
        if (this.isMimeTypeSupported(mimeType) === false) {
          mimeType = 'video/webm;codecs=vp9';
          if (this.isMimeTypeSupported(mimeType) === false) {
            mimeType = 'video/webm;codecs=vp8';
            if (this.isMimeTypeSupported(mimeType) === false) {
              mimeType = 'video/webm';
              if (this.isMimeTypeSupported(mimeType) === false) {
                mimeType = 'video/webm';
                recorderType = WhammyRecorder;
              }
            }
          }
        }
      }
    }
  }

  hasGetUserMedia = () => !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);

  onChangeMediaDevice = () => {
    const { videoConstraints } = this.state;

    navigator
      .mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoInputs = devices.filter((r) => (r.kind === 'videoinput' && r.label));
        const audioInputs = devices.filter((r) => (r.kind === 'audioinput' && r.label));
        const videoInput  = videoInputs[0];
        const audioInput  = audioInputs[0];

        if (videoInput && audioInput) {
          this.setState((prevState) => ({
            videoInputs,
            audioInputs,
            videoInput,
            audioInput,
            videoConstraints: {
              audio: {
                ...prevState.videoConstraints.audio,
                deviceId: { exact: audioInput.deviceId },
              },
              video: {
                ...prevState.videoConstraints.video,
                deviceId:  { exact: videoInput.deviceId },
              },
            },
          }));
        } else {
          this.requestUserMedia();
        }
      });
  };

  onCountDown = () => {
    const {
      paused,
      recording,
      startRecordingTime,
      pauseRecordingTime,
      maxDurtion,
    } = this.state;

    if (!recording) return;

    if (!paused) {
      const now = Moment.now();
      let streamDuration = (now - (startRecordingTime + pauseRecordingTime)) / 1000;

      if (streamDuration >= maxDurtion) {
        streamDuration = maxDurtion;
        this.stopCamerRecording();
      }

      this.setState({ streamDuration });
    }
  }

  captureCamera = (callback) => {
    const { videoConstraints } = this.state;

    navigator
      .mediaDevices
      .getUserMedia(videoConstraints)
      .then(callback)
      .catch((e) => { this.handleUserMedia(e); });
  }

  requestUserMedia = () => {
    this.captureCamera((stream) => {
      if (this.unmounted) {
        this.stopMediaStream(stream);
      } else {
        this.handleUserMedia(null, stream);
      }
    });
  }

  handleErrors = (error) => {
    if (
      error?.name === 'PermissionDeniedError'
      || error?.name === 'NotAllowedError'
      || (error?.message === 'Permission denied' && error?.name === 'DOMException')
    ) {
      error = "We don't have permission to access your camera or microphone.";
    }

    this.setState({
      hasUserMedia: false,
      error:        error?.message || error || 'Oops, something went wrong. Try again later.',
    });
  }

  isConstraintError = (e) => /Invalid constraint/.test(e.message) || e.name === 'OverconstrainedError';

  handleUserMedia = (err, stream) => {
    const { videoInputs, audioInputs } = this.state;

    this.stream = stream;

    if (!videoInputs.length && !audioInputs.length) {
      this.onChangeMediaDevice();

      return;
    }

    if (err || !stream) {
      this.handleErrors(err);

      return;
    }

    try {
      if (this.videoNode) {
        this.videoNode.srcObject = stream;
        this.setState({ hasUserMedia: true });
      }
    } catch (error) {
      this.setState({ hasUserMedia: true });
    }
  }

  stopMediaStream = (stream) => {
    if (stream) {
      if (stream.getTracks) {
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
          track.stop();
          stream.removeTrack(track);
        });
      } else {
        stream.stop();
      }
    }
  }

  stopAndCleanup = () => {
    if (this.stream) {
      this.stopMediaStream(this.stream);
      this.setState({ hasUserMedia: false });
    }
  }

  handleStartCameraRecording = (e) => {
    e.preventDefault();

    this.setState({
      preview:            false,
      blob:               null,
      recording:          true,
      showSettings:       false,
      startRecordingTime: Moment.now(),
      pauseRecordingTime: 0,
    }, () => {
      this.captureCamera((stream) => {
        if (this.stream) this.stopMediaStream(this.stream);

        this.handleUserMedia(null, stream);
        this.videoNode.current = new RecordRTCPromisesHandler(stream, {
          type:                   'video',
          mimeType,
          recorderType,
          timeSlice:              1000,
          checkForInactiveTracks: true,
          disableLogs:            false,
          onTimeStamp:            (current, all) => {
            this.onCountDown();
          },
        });
        this.videoNode.current.startRecording();
      });
    });
  };

  handleReRecord = () => {
    const { state } = this.state;

    brokerbit.confirmBox({
      message:  'Delete Video? If you have not saved this recording, you will not be able to restore it.',
      callback: (ok) => {
        if (ok) {
          this.setState({
            preview:            false,
            blob:               null,
            streamDuration:     0,
            startRecordingTime: null,
            pauseRecordingTime: null,
            showSettings:       false,
          }, () => {
            this.videoNode.current.reset();
            this.requestUserMedia();
          });
        }
      },
    });
  }

  stopCamerRecording = () => {
    this.setState({
      recording: false,
      paused:    false,
    }, () => {
      this.videoNode.current.stopRecording(() => {
        this.stopMediaStream(this.stream);

        const recordedBlob = this.videoNode.current.getBlob();

        if (recordedBlob.type === 'video/mp4') {
          this.setBlob(recordedBlob);
        } else {
          this
            .injectMetadata(recordedBlob)
            .then((seekableBlob) => {
              this.setBlob(seekableBlob);
            })
            .catch((e) => { this.setBlob(recordedBlob); });
        }
      });
    });
  }

  setBlob = (blob) => {
    const { onUserMedia } = this.props;

    if (blob) {
      this.setState({
        preview: true,
        blob,
      });

      onUserMedia(blob);
    }
  }

  readAsArrayBuffer = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => { resolve(reader.result); };
    reader.onerror = (ev) => { reject(ev.error); };
  })

  injectMetadata = (blob) => {
    const decoder = new Decoder();
    const reader = new Reader();

    reader.logging = false;
    reader.drop_default_duration = false;

    return this.readAsArrayBuffer(blob)
      .then((buffer) => {
        const elms = decoder.decode(buffer);
        elms.forEach((elm) => { reader.read(elm); });
        reader.stop();

        const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
        const body = buffer.slice(reader.metadataSize);

        return new Blob([refinedMetadataBuf, body], { type: blob.type });
      });
  }

  handleStopCameraRecording = (e) => {
    e.preventDefault();

    this.stopCamerRecording();
  };

  toggleFullScreen = (e) => {
    e.preventDefault();

    const doc = window.document;
    const el = this.videoContainer;

    const requestFullScreen = el.requestFullscreen || el.mozRequestFullScreen || el.webkitRequestFullScreen || el.msRequestFullscreen;
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(el);
    } else {
      cancelFullScreen.call(doc);
    }
  };

  handlePauseRecording = (e) => {
    e.preventDefault();

    const { paused, pauseRecordingTime } = this.state;

    this.setState({ paused: !paused }, () => {
      if (paused) {
        this.setState({ pauseRecordingTime: Moment.now() - pauseRecordingTime });
        this.videoNode.current.resumeRecording();
      } else {
        this.setState({ pauseRecordingTime: Moment.now() });
        this.videoNode.current.pauseRecording();
      }
    });
  }

  toggleAudioSetting = (e) => {
    e.preventDefault();

    const { videoConstraints, audioInput } = this.state;
    let audio;

    if (videoConstraints.audio) {
      audio = false;
    } else {
      audio = {
        deviceId: { exact: audioInput.deviceId },
      };
    }

    this.setState((prevState) => ({
      videoConstraints: {
        ...prevState.videoConstraints,
        audio,
      },
    }));
  };

  handleAudioInput = (e, audioInput) => {
    e.preventDefault();

    this.setState((prevState) => ({
      audioInput,
      videoConstraints: {
        ...prevState.videoConstraints,
        audio: {
          ...prevState.videoConstraints.audio,
          deviceId: { exact: audioInput.deviceId },
        },
      },
    }));
  }

  handleVideoInput = (e, videoInput) => {
    e.preventDefault();

    this.setState((prevState) => ({
      videoInput,
      videoConstraints: {
        ...prevState.videoConstraints,
        video: {
          ...prevState.videoConstraints.video,
          deviceId: { exact: videoInput.deviceId },
        },
      },
    }));
  }

  handleSettingsClick = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      showSettings: !prevState.showSettings,
    }));
  };

  renderPauseButton = () => {
    const { paused } = this.state;

    return (
      <button type="button" className={classNames('btn-pause-record', { active: paused })} onClick={this.handlePauseRecording}>
        <i className="icn-pause-record">
          <PauseIcon />
        </i>
      </button>
    );
  }

  renderVideoInputSettings = () => {
    const { videoInputs, videoInput } = this.state;

    return (
      <ul className="select-camera">
        {videoInputs.map((item) => (
          <li
            className={classNames('camera-item', { active: item.deviceId === videoInput.deviceId })}
            key={uuidv4()}
            onClick={(e) => this.handleVideoInput(e, item)}
          >
            <i className="a-indicator" />
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    );
  }

  renderAudioInputSettings = () => {
    const { audioInputs, audioInput } = this.state;

    return (
      <ul className="select-mic">
        {audioInputs.map((item) => (
          <li
            className={classNames('mic-item', { active: item.deviceId === audioInput.deviceId })}
            key={uuidv4()}
            onClick={(e) => this.handleAudioInput(e, item)}
          >
            <i className="a-indicator" />
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    );
  }

  renderSettings = () => {
    const {
      showSettings,
      videoConstraints,
    } = this.state;

    if (!showSettings) return false;

    return (
      <div className="dialog-settings">
        <div className={classNames('settings-group mic-enable', { active: videoConstraints.audio })}>
          <div className="settings-header wrapper">
            <span>Microphone</span>
            <div className={classNames('mic-check', { active: videoConstraints.audio })} onClick={this.toggleAudioSetting}>
              <i className="mic-check-icon" />
            </div>
          </div>
          <div className="settings-mics visible">
            {this.renderAudioInputSettings()}
          </div>
        </div>

        <div className="settings-group settings-camera visible">
          <div>
            <div className="settings-header">Camera</div>
            {this.renderVideoInputSettings()}
          </div>
        </div>
      </div>
    );
  }

  renderErrors = () => {
    const { error } = this.state;

    if (error) {
      return (
        <div className="text-center text-danger">
          <FontAwesomeIcon icon="fal fa-exclamation-triangle" />
          {' '}
          {error}
        </div>
      );
    }

    return null;
  }

  renderInitializator = () => {
    const { hasUserMedia, error } = this.state;

    if ((!hasUserMedia || !this.videoNode.srcObject) && !error) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
          <br />
          <p>Initializing...</p>
        </div>
      );
    }

    return null;
  }

  renderVideoPlayer = () => {
    const { blob } = this.state;

    if (!blob) return null;

    const videoJsOptions = {
      posterImage:   false,
      autoplay:      true,
      controls:      true,
      fluid:         true,
      bigPlayButton: false,
      preload:       'auto',
      sources:       [{
        src:  URL.createObjectURL(blob),
        type: 'video/mp4',
      }],
    };

    return (
      <div className="row mb-3">
        <div className="col">
          <div className="preview-player">
            <VideoPlayer options={videoJsOptions} handleReRecord={this.handleReRecord} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      recording,
      streamDuration,
      maxDurtion,
      paused,
      showSettings,
      hasUserMedia,
      error,
      preview,
    } = this.state;

    return (
      <div className="orc">
        {this.renderErrors()}
        {this.renderInitializator()}

        <div className={classNames('wc-video-recorder-container', { 'd-none': !hasUserMedia || !this.videoNode.srcObject || !!error || preview })}>
          <div
            className={classNames('video-recorder', { active: recording, paused })}
            ref={(node) => this.videoContainer = node}
          >
            <div className="video-container">
              <video
                ref={(node) => this.videoNode = node}
                muted
                autoPlay
                playsInline
                className="video-output"
                style={{ transform: 'initial' }}
              />

              <div className="record-timer">
                <div className="icon" />
                <div className="time">
                  <span className="time-elapsed">{VideoRecorderHelpers.renderDuration(streamDuration)}</span>
                  <span>/</span>
                  <span className="time-total">{VideoRecorderHelpers.renderDuration(maxDurtion)}</span>
                </div>
              </div>

              <div className="bottom-menu">
                <div className="mode-switch flex-1 flex-left" />
                <div className="flex-1 flex-center position-relative">
                  {recording ? (
                    <>
                      <button type="button" className="btn-record active" onClick={this.handleStopCameraRecording}>
                        <RecordingIcon />
                        <i className="icn-record" />
                      </button>
                      {this.renderPauseButton()}
                    </>
                  ) : (
                    <button type="button" className="btn-record" onClick={this.handleStartCameraRecording}>
                      <RecordingIcon />
                      <i className="icn-record" />
                    </button>
                  )}
                </div>
                <div className="flex-1 flex-right">
                  {!recording && (
                    <div className="settings">
                      <button type="button" className="btn-settings" onClick={this.handleSettingsClick}>
                        <SettingsIcon />
                      </button>
                      {this.renderSettings()}
                    </div>
                  )}
                  <button type="button" className="btn-fs" onClick={this.toggleFullScreen}>
                    <FullScreenIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {preview && this.renderVideoPlayer()}
      </div>
    );
  }
}

VideoRecorder.defaultProps = {
  onUserMedia: () => false,
};

VideoRecorder.propTypes = {
  onUserMedia: PropTypes.func,
};

export default VideoRecorder;
