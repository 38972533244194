import React, { memo } from 'react';
import { PropTypes }   from 'prop-types';
import classNames      from 'classnames';

const ScheduleOption = memo(({
  id,
  value,
  labelText,
  onChange,
  checked,
  children,
}) => (

  <div className={classNames('mb-2', { 'd-flex align-items-center': !children })}>
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        className="custom-control-input"
      />

      <label htmlFor={id} className="custom-control-label">
        {labelText}
      </label>
    </div>

    {children}
  </div>
));

ScheduleOption.propTypes = {
  id:        PropTypes.string.isRequired,
  value:     PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  checked:   PropTypes.bool.isRequired,
  onChange:  PropTypes.func.isRequired,
  children:  PropTypes.node,
};

ScheduleOption.defaultProps = {
  children: null,
};

export default ScheduleOption;
