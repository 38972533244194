import { ReduceStore }        from 'flux/utils';
import AppDispatcher          from '~/dispatchers/app_dispatcher';
import SmsTemplateActionTypes from '~/types/sms_template_action_types';

class SmsTemplateStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingSmsTemplates:    false,
      creatingSmsTemplate:    false,
      updatingSmsTemplate:    false,
      SmsTemplates:           null,
      SmsTemplate:            null,
      SmsTemplateStoreAction: null, // store last dispatch action name
      componentID:            null, // store select object instance to support multiple template selects
    };
  }

  loadSmsTemplates(state, action) {
    const newState = { ...state };

    newState.loadingSmsTemplates = true;
    newState.smsTemplateStoreAction = 'loadSmsTemplates';
    newState.componentID = action.componentID;

    return newState;
  }

  loadSmsTemplatesDone(state, action) {
    const newState = { ...state };

    newState.loadingSmsTemplates = false;
    newState.smsTemplates = action.smsTemplates;
    newState.smsTemplateStoreAction = 'loadSmsTemplatesDone';
    newState.componentID = action.componentID;

    return newState;
  }

  loadSmsTemplatesFail(state, action) {
    const newState = { ...state };

    newState.loadingSmsTemplates = false;
    newState.errors = action.errors;
    newState.smsTemplateStoreAction = 'loadSmsTemplatesFail';
    newState.componentID = action.componentID;

    return newState;
  }

  createSmsTemplate(state, action) {
    const newState = { ...state };

    newState.creatingSmsTemplate = true;
    newState.smsTemplateStoreAction = 'createSmsTemplate';
    newState.componentID = action.componentID;

    return newState;
  }

  createSmsTemplateDone(state, action) {
    const newState = { ...state };

    newState.creatingSmsTemplate = false;
    newState.smsTemplate = action.smsTemplate;
    newState.smsTemplateStoreAction = 'createSmsTemplateDone';
    newState.componentID = action.componentID;

    return newState;
  }

  createSmsTemplateFail(state, action) {
    const newState = { ...state };

    newState.creatingSmsTemplate = false;
    newState.errors = action.errors;
    newState.smsTemplateStoreAction = 'createSmsTemplateFail';
    newState.componentID = action.componentID;

    return newState;
  }

  updateSmsTemplate(state, action) {
    const newState = { ...state };

    newState.updatingSmsTemplate = true;
    newState.smsTemplateStoreAction = 'updateSmsTemplate';
    newState.componentID = action.componentID;

    return newState;
  }

  updateSmsTemplateDone(state, action) {
    const newState = { ...state };

    newState.updatingSmsTemplate = false;
    newState.smsTemplate = action.smsTemplate;
    newState.smsTemplateStoreAction = 'updateSmsTemplateDone';
    newState.componentID = action.componentID;

    return newState;
  }

  updateSmsTemplateFail(state, action) {
    const newState = { ...state };

    newState.updatingSmsTemplate = false;
    newState.errors = action.errors;
    newState.smsTemplateStoreAction = 'updateSmsTemplateFail';
    newState.componentID = action.componentID;

    return newState;
  }

  deleteSmsTemplate(state, action) {
    const newState = { ...state };

    newState.smsTemplateStoreAction = 'deleteSmsTemplate';
    newState.componentID = action.componentID;

    return newState;
  }

  deleteSmsTemplateDone(state, action) {
    const newState = { ...state };

    newState.smsTemplateStoreAction = 'deleteSmsTemplateDone';
    newState.componentID = action.componentID;

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case SmsTemplateActionTypes.LOAD_SMS_TEMPLATES:
        return this.loadSmsTemplates(state, action);

      case SmsTemplateActionTypes.LOAD_SMS_TEMPLATES_DONE:
        return this.loadSmsTemplatesDone(state, action);

      case SmsTemplateActionTypes.LOAD_SMS_TEMPLATES_FAIL:
        return this.loadSmsTemplatesFail(state, action);

      case SmsTemplateActionTypes.CREATE_SMS_TEMPLATE:
        return this.createSmsTemplate(state, action);

      case SmsTemplateActionTypes.CREATE_SMS_TEMPLATE_DONE:
        return this.createSmsTemplateDone(state, action);

      case SmsTemplateActionTypes.CREATE_SMS_TEMPLATE_FAIL:
        return this.createSmsTemplateFail(state, action);

      case SmsTemplateActionTypes.UPDATE_SMS_TEMPLATE:
        return this.updateSmsTemplate(state, action);

      case SmsTemplateActionTypes.UPDATE_SMS_TEMPLATE_DONE:
        return this.updateSmsTemplateDone(state, action);

      case SmsTemplateActionTypes.UPDATE_SMS_TEMPLATE_FAIL:
        return this.updateSmsTemplateFail(state, action);

      case SmsTemplateActionTypes.DELETE_SMS_TEMPLATE:
        return this.deleteSmsTemplate(state, action);

      case SmsTemplateActionTypes.DELETE_SMS_TEMPLATE_DONE:
        return this.deleteSmsTemplateDone(state, action);

      default:
        return state;
    }
  }
}

export default new SmsTemplateStore();
