import React, { Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIRequest          from '~/lib/api_request';
import PropTypes           from 'prop-types';
import Tooltipable         from '~/components/effects/tooltipable';

const onLogout = () => {
  fetch('/users/sign_out', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('[name=csrf-token]').content,
    },
  })
    .then((response) => {
      if (response.ok) {
        top.location = '/';
      } else {
        console.error('Logout failed');
      }
    })
    .catch((error) => {
      console.error('Logout error:', error);
    });
}

const splitParagraph = (text, maxLength = 70) => {
  return text.split(' ').reduce((lines, word) => {
    let currentLine = lines[lines.length - 1];
    if (currentLine && (currentLine + ' ' + word).length <= maxLength) {
      lines[lines.length - 1] += ' ' + word;
    } else {
      lines.push(word);
    }
    return lines;
  }, ['']);
};

const WarningMessage = ({ message, onClose }) => {
  if (!message) return null;
  const errorMessage = splitParagraph(message);
  const { currentUser } = Rails.helpers;
  const warningText = currentUser.is_google ? {
    tooltipText: 'Google',
    iconText: 'Oops, Permissions weren’t Granted'
  } : {
    tooltipText: 'Google/Microsoft',
    iconText: 'Oops, this feature is not available'
  };

  return (
    <div className="dropdown-message alert-warning d-flex border rounded" role="alert">
      <div className='p-2'>
        <h3 className="alert-heading">
          <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
          {warningText.iconText}
        </h3>
        {errorMessage.map((line, index) => (
          <p key={index} className="popover-text text-dark">{line}</p>
        ))}
      </div>
      <div className='align-self-center mr-2 p-2'>
        <div className="d-flex">
          <Tooltipable placement="bottom" text={warningText.tooltipText}>
            <button className="btn btn-success mr-2" onClick={onLogout}>Log Out</button>
          </Tooltipable>
          <Tooltipable placement="bottom" text='Close to continue without changes'>
            <button className="btn btn-danger" onClick={onClose}>Close</button>
          </Tooltipable>
        </div>
      </div>
    </div>
  );
};

const getErrorMessage = (type) => {
  const action = type.toLowerCase() === 'email' ? 'email' : 'appointments';
  return `To send ${action} directly via your Google account, 
  please grant ${action === 'appointments' ? 'calendar' : action} access on the sign-in page.`;
};

const getGenericErrorMessage = (type) => {
  const action = type.toLowerCase() === 'email' ? 'email' : 'calendar';
  return `An error occurred while checking permissions for ${action},
  please try again later.`;
};

const checkGoogleScopes = async (type) => {
  const apiUrl = '/v1/auth/check_google_scopes';
  const requestType = {
    resource: apiUrl,
    data: {
      buttonType: type,
    },
  };

  try {
    const response = await APIRequest.get(requestType);

    if (response?.body?.invalidToken) {
      top.location = '/google_token_expired';
      return { error: true, message: null };
    }

    if (!response?.body?.hasRequiredScope) {
      const errorMsg = getErrorMessage(type);
      return { error: true, message: errorMsg };
    }
    return { error: false, message: null };
  } catch (error) {
    const errorMsg = getGenericErrorMessage(type);
    return { error: true, message: errorMsg };
  }
};

export { WarningMessage, checkGoogleScopes };
