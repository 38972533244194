import { useReducer, useRef } from 'react';
import AWS from 'aws-sdk';
import { modalReducer, initialModalState } from './useAIAssist.reducer';
import { ModalStates } from '../states/ModalStates';
import APIRequest from '~/lib/api_request';

const { AWS_LAMBDA_OPEN_AI_FUNCTION } = process.env;
const CREATE_CONVERSATION_API = '/v1/openai/create_conversation';
const GET_TOKENS_USAGE = '/v1/openai/tokens_usage';

const useAIAssist = (editor, mode, setSubject) => {
  const [state, dispatch] = useReducer(modalReducer, initialModalState);
  const stateRef = useRef(state);
  const appModalRef = useRef();
  const markdownContentRef = useRef();

  const handleInputChange = (e) => dispatch({ type: 'SET_INPUT_TEXT', payload: e.target.value });
  const handlePromptClick = (prompt) => dispatch({ type: 'SET_INPUT_TEXT', payload: prompt });
  const handleNewPrompt = () => dispatch({ type: 'RESET_STATE' });
  const handleRevisePrompt = () => dispatch({ type: 'REVISE_PROMPT' });

  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const processError = (outputText) => {
    if (isJSON(outputText)) {
      const responseJson = JSON.parse(outputText);

      if (responseJson.message) {
        dispatch({ type: 'SET_STATUS', payload: ModalStates.ERROR });
        dispatch({ type: 'SET_ERROR', payload: responseJson.message });

        return true;
      }
    }

    return false;
  };

  const signAWSSDKRequest = (payload) => {
    const region = process.env.AWS_REGION;
    const accessKeyId = process.env.AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.AWS_SECRET_ACCESS_KEY;
    const endpoint = new AWS.Endpoint(AWS_LAMBDA_OPEN_AI_FUNCTION);
    const request = new AWS.HttpRequest(endpoint, region);

    request.method = 'POST';
    request.headers['Content-Type'] = 'application/json';
    request.headers['Cache-Control'] = 'no-cache';
    request.headers.Host = endpoint.hostname;
    request.body = JSON.stringify(payload);

    const signer = new AWS.Signers.V4(request, 'lambda');
    signer.addAuthorization({ accessKeyId, secretAccessKey }, new Date());

    return request;
  };

  const startConversation = async () => {
    try {
      const response = await APIRequest.post({
        resource: CREATE_CONVERSATION_API,
      });

      return response.body.id;
    } catch (error) {
      dispatch({
        type:    'SET_ERROR',
        payload: error.message,
      });

      return null;
    }
  };

  const saveContext = async (data) => {
    await APIRequest.post({
      resource: '/v1/openai/create_message',
      data:     {
        ...data,
        ai_conversation_id: stateRef.current.conversationId,
        tokens_count:       stateRef.current.tokensCount,
      },
    });
  };

  const fetchTokensUsage = async () => {
    dispatch({ type: 'SET_LOADING', payload: true });

    try {
      const response = await APIRequest.get({ resource: GET_TOKENS_USAGE });
      const { subscription_limits, tokens_count } = response.body;

      dispatch({ type: 'SET_SUBSCRIPTION_LIMIT', payload: subscription_limits });
      dispatch({ type: 'SET_TOTAL_TOKENS_USED', payload: tokens_count });

      return null;
    } catch (error) {
      dispatch({
        type:    'SET_ERROR',
        payload: error.message,
      });

      return null;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const sendToAI = async (messages) => {
    const awsRequest = signAWSSDKRequest(messages);
    const response = await fetch(awsRequest.endpoint.href, {
      method:  awsRequest.method,
      headers: awsRequest.headers,
      body:    awsRequest.body,
    });

    const reader = response.body?.getReader();
    handleResponseStream(reader);
  };

  const handleConversationId = async (conversationId) => {
    const id = conversationId || (await startConversation());

    if (!id) return false;

    dispatch({ type: 'SET_CONVERSATION_ID', payload: id });

    return true;
  };

  const validateInput = (inputText, totalTokens) => {
    if (!inputText.trim()) {
      return 'Prompt cannot be empty.';
    }

    if (totalTokens > process.env.MAX_TOKENS_PER_CONVERSATION) {
      return `Input exceeds the maximum allowed tokens (${process.env.MAX_TOKENS_PER_CONVERSATION}). Total tokens including current conversation: ${totalTokens}.`;
    }

    return null;
  };

  const extractSubject = (content, subjectPrefix) => {
    if (!content.includes(subjectPrefix)) return { content, subject: null };

    const startIndex = content.indexOf(subjectPrefix);
    const endIndex = content.indexOf('</div>', startIndex);
    let subject = content.slice(startIndex + subjectPrefix.length, endIndex === -1 ? undefined : endIndex);

    subject = subject.replace(/<\/?[^>]+(>|$)/g, '');

    const endIndexToReplace = content.indexOf('</div>', endIndex + '</div>'.length);
    content = content.replace(content.slice(startIndex, endIndexToReplace + '</div>'.length), '');

    return { content, subject };
  };

  const hideModal = (modalRef) => {
    const $modal = $(modalRef.modal);
    $modal.modal('hide');
  };

  const handleInsert = () => {
    if (state.status === ModalStates.GENERATING) return;

    const htmlContent = markdownContentRef.current.innerHTML;
    const subjectPrefix = 'Subject: ';
    const modalRef = appModalRef.current;
    const { content, subject } = extractSubject(htmlContent, subjectPrefix);

    editor.setContent(content, { format: mode === 'html' ? 'html' : 'text' });

    handleSubjectChange(subject);

    hideModal(modalRef);
  };

  const handleSubjectChange = (subject) => {
    if (subject && setSubject) setSubject(subject);
  };

  const handleResponseStream = (reader) => {
    let outputText = '';

    const readStream = () => {
      reader.read().then(processStream).catch((err) => {
        dispatch({ type: 'SET_STATUS', payload: ModalStates.ERROR });
        dispatch({ type: 'SET_ERROR', payload: 'An error occurred while reading the stream.' });
      });
    };

    const processStream = ({ done, value }) => {
      const chunk = new TextDecoder().decode(value);
      outputText += chunk;

      if (done) {
        if (!processError(outputText)) {
          const newMessage = { role: 'assistant', content: outputText };
          dispatch({ type: 'SET_STATUS', payload: ModalStates.FINISHED });
          dispatch({ type: 'APPEND_MESSAGE', payload: newMessage });

          saveContext(newMessage);
        }

        return;
      }

      dispatch({ type: 'SET_OUTPUT_TEXT', payload: outputText });

      readStream();
    };

    readStream();
  };

  const handleGenerateText = async (overrideMessage) => {
    const inputText = overrideMessage || state.inputText;
    const validationError = validateInput(inputText, stateRef.current.tokensCount);

    if (validationError) {
      dispatch({
        type:    'SET_INPUT_ERROR',
        payload: validationError,
      });

      return;
    }

    if (!await handleConversationId(state.conversationId)) return;

    dispatch({ type: 'SET_STATUS', payload: ModalStates.GENERATING });

    const newMessage = { role: 'user', content: inputText };

    dispatch({ type: 'APPEND_MESSAGE', payload: newMessage });

    await saveContext(newMessage);

    const payload = { messages: [...stateRef.current.messages, newMessage] };

    await sendToAI(payload);
  };

  const handleRetry = async () => {
    dispatch({ type: 'REVISE_PROMPT' });

    const validationError = validateInput(state.inputText, stateRef.current.tokensCount);

    if (validationError) {
      dispatch({
        type:    'SET_INPUT_ERROR',
        payload: validationError,
      });

      return;
    }

    if (!await handleConversationId(state.conversationId)) return;

    dispatch({ type: 'SET_STATUS', payload: ModalStates.GENERATING });

    const payload = { messages: [...stateRef.current.messages] };

    await sendToAI(payload);
  };

  const handleRegenerate = async () => {
    const regenerateInstruction = 'Please regenerate the last response.';

    dispatch({ type: 'SET_OUTPUT_TEXT', payload: '' });
    dispatch({ type: 'SET_FEEDBACK_STATE', payload: null });
    dispatch({ type: 'SET_FEEDBACK_TEXT', payload: '' });

    handleGenerateText(regenerateInstruction);
  };

  return {
    state,
    dispatch,
    appModalRef,
    stateRef,
    markdownContentRef,
    handleInputChange,
    handlePromptClick,
    handleNewPrompt,
    handleRevisePrompt,
    startConversation,
    handleInsert,
    handleGenerateText,
    handleRegenerate,
    handleRetry,
    fetchTokensUsage,
  };
};

export default useAIAssist;
