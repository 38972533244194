import AppDispatcher       from '~/dispatchers/app_dispatcher';
import APIRequest          from '~/lib/api_request';
import DocumentActionTypes from '~/types/document_action_types';
import LeadDrawerActions   from '~/actions/lead_drawer_actions';
import LeadDrawerStore     from '~/stores/lead_drawer_store';
import GlobalContainer     from '~/components/global_container';
import { captureEvent }    from '~/helpers/capture_posthog_event';
import { getRestrictionScope } from '~/helpers/staffRestrictionHelper';
import tableConfig             from '~/components/pages/agent_dashboard/table_config';

let loadDocumentRequest;
let createDocumentRequest;
let updateDocumentRequest;
let deleteDocumentRequest;
let documentsWithRelatedContactRequest;
let loadCategoriesRequest;
let loadStatusesRequest;
let bulkDeleteDocumentsRequest;
let bulkUpdateDocumentsRequest;

const documentTableScopeRequest = {};

const DocumentActions = {

  defaultOptions() {
    const staffRestriction = getRestrictionScope();

    return {
      ownerScope: localStorage.ownerScope || staffRestriction || 'all',
      page:       1,
      size:       parseInt(localStorage.documentScopeSize, 10) || 10,
      searchData: {},
    };
  },

  loadCategories() {
    APIRequest.abort(loadCategoriesRequest);

    loadCategoriesRequest = APIRequest.get({
      resource: '/v1/documents/categories',
    });

    loadCategoriesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DocumentActionTypes.LOAD_CATEGORIES_FAIL,
          error,
        });
        GlobalContainer.notify('Failed to load document categories', 'error');
        return;
      }

      AppDispatcher.dispatch({
        type:       DocumentActionTypes.LOAD_CATEGORIES_DONE,
        categories: response.body,
      });
    });
  },

  loadStatuses() {
    APIRequest.abort(loadStatusesRequest);

    loadStatusesRequest = APIRequest.get({
      resource: '/v1/documents/statuses',
    });

    loadStatusesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DocumentActionTypes.LOAD_STATUSES_FAIL,
          error,
        });
        GlobalContainer.notify('Failed to load document statuses', 'error');
        return;
      }

      AppDispatcher.dispatch({
        type:     DocumentActionTypes.LOAD_STATUSES_DONE,
        statuses: response.body,
      });
    });
  },

  loadDocument(documentID) {
    APIRequest.abort(loadDocumentRequest);

    AppDispatcher.dispatch({
      type: DocumentActionTypes.LOAD_DOCUMENT,
    });

    loadDocumentRequest = APIRequest.get({
      resource: `/v1/documents/${documentID}`,
    });

    loadDocumentRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DocumentActionTypes.LOAD_DOCUMENT_FAIL,
          error,
        });
        GlobalContainer.notify('Failed to load document', 'error');

        return;
      }

      AppDispatcher.dispatch({
        type:     DocumentActionTypes.LOAD_DOCUMENT_DONE,
        document: response.body,
      });
    });
  },

  loadDocuments(table = tableConfig.documentsTable(), options = {}) {
    const opts = Object.assign(this.defaultOptions(), options);

    const query = {
      document_scope: table.scope,
      scope:          opts.ownerScope,
      page:           opts.page,
      per:            opts.size,
      s:              opts.searchData || {},
    };

    const { sortColumn, sortDirection } = opts;
    if (sortColumn && sortDirection) {
      query.document_sort = `${sortColumn}-${sortDirection}`;
    }

    AppDispatcher.dispatch({
      type: DocumentActionTypes.LOAD_DOCUMENTS,
      table,
    });

    if (documentTableScopeRequest[table.scope]) {
      APIRequest.abort(documentTableScopeRequest[table.scope]);
    }

    documentTableScopeRequest[table.scope] = APIRequest.get({
      resource: '/v1/documents',
      data:     query,
    });

    documentTableScopeRequest[table.scope].end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DocumentActionTypes.LOAD_DOCUMENTS_FAIL,
          table,
          error,
        });
        GlobalContainer.notify('Failed to load documents', 'error');
        return;
      }

      AppDispatcher.dispatch({
        type:     DocumentActionTypes.LOAD_DOCUMENTS_DONE,
        table,
        response: response.body,
        query,
      });
    });
  },

  switchDocumentScopeSize(widgets, documentScopeSize, searchData) {
    AppDispatcher.dispatch({
      type: DocumentActionTypes.SWITCH_DOCUMENT_SCOPE_SIZE,
      documentScopeSize,
    });
    localStorage.documentScopeSize = documentScopeSize;

    const table = widgets.tables[0];
    const options = { size: documentScopeSize, page: 1, searchData };
    if (table.scope === 'documents') {
      DocumentActions.loadDocuments(table, options);
    } else if (table.scope === 'trash_documents') {
      DocumentActions.loadTrashedDocuments(table, options);
    }
  },

  loadTrashedDocuments(table = tableConfig.trashTable(), options = {}) {
    const opts = Object.assign(this.defaultOptions(), options);

    const query = {
      document_scope: table.scope,
      scope:          opts.ownerScope,
      page:           opts.page,
      per:            opts.size,
      s:              opts.searchData || {},
    };

    AppDispatcher.dispatch({
      type: DocumentActionTypes.LOAD_TRASHED_DOCUMENTS,
      table,
    });

    if (documentTableScopeRequest[table.scope]) {
      APIRequest.abort(documentTableScopeRequest[table.scope]);
    }

    documentTableScopeRequest[table.scope] = APIRequest.get({
      resource: '/v1/documents/trashed_documents',
      data:     query,
    });

    documentTableScopeRequest[table.scope].end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DocumentActionTypes.LOAD_TRASHED_DOCUMENTS_FAIL,
          table,
          error,
        });
        GlobalContainer.notify('Failed to load trashed documents', 'error');
        return;
      }

      AppDispatcher.dispatch({
        type:     DocumentActionTypes.LOAD_TRASHED_DOCUMENTS_DONE,
        table,
        response: response.body,
        query,
      });
    });
  },

  loadDocumentsWithRelatedContact(leadID, page = 1) {
    APIRequest.abort(documentsWithRelatedContactRequest);

    AppDispatcher.dispatch({
      type: DocumentActionTypes.LOAD_DOCUMENTS_WITH_RELATED_CONTACT,
    });

    documentsWithRelatedContactRequest = APIRequest.get({
      resource: '/v1/documents/lead_documents',
      data:     {
        lead_id:  leadID,
        page,
        per_page: 5,
      },
    });

    documentsWithRelatedContactRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DocumentActionTypes.LOAD_DOCUMENTS_WITH_RELATED_CONTACT_FAIL,
          error,
        });
        GlobalContainer.notify('Failed to load documents', 'error');

        return;
      }

      AppDispatcher.dispatch({
        type:       DocumentActionTypes.LOAD_DOCUMENTS_WITH_RELATED_CONTACT_DONE,
        documents:  response.body.documents,
        pagination: response.body.pagination,
      });
    });
  },

  createDocument(document, leadID) {
    const { recordIndex } = LeadDrawerStore.getState();

    APIRequest.abort(createDocumentRequest);

    AppDispatcher.dispatch({
      type: DocumentActionTypes.CREATE_DOCUMENT,
      document,
    });

    return new Promise((resolve, reject) => {
      createDocumentRequest = APIRequest.post({
        resource: '/v1/documents',
        data:     { document },
      });

      createDocumentRequest.end((error, response) => {
        if (error) {
          AppDispatcher.dispatch({
            type: DocumentActionTypes.CREATE_DOCUMENT_FAIL,
            error,
          });
          reject(error);
          return;
        }

        AppDispatcher.dispatch({
          type:     DocumentActionTypes.CREATE_DOCUMENT_DONE,
          document: response.body,
        });
        GlobalContainer.notify('Document has been uploaded', 'success');

        if (leadID) {
          LeadDrawerActions.loadLead(leadID, { recordIndex });
        } else {
          DocumentActions.loadDocuments();
        }

        resolve(response.body);
      });
      captureEvent('document upload');
    });
  },

  empty_documents_trash() {
    APIRequest.abort(deleteDocumentRequest);

    AppDispatcher.dispatch({
      type: DocumentActionTypes.PERMANENT_DELETE_DOCUMENT,
    });

    deleteDocumentRequest = APIRequest.delete({
      resource: '/v1/document_bulk_actions/empty_documents_trash',
    });

    deleteDocumentRequest.end((error) => {
      if (error) {
        const errors = error?.response?.body?.errors || 'Failed to permanently delete the documents. Please try again later.';

        AppDispatcher.dispatch({
          type:  DocumentActionTypes.PERMANENT_DELETE_DOCUMENT_FAIL,
          error: errors,
        });

        GlobalContainer.notify(errors, 'error');
        return;
      }

      AppDispatcher.dispatch({
        type: DocumentActionTypes.PERMANENT_DELETE_DOCUMENT_DONE,
      });

      GlobalContainer.notify('All soft-deleted documents have been permanently deleted');
    });
  },

  updateDocument(document, leadID, table, query) {
    APIRequest.abort(updateDocumentRequest);
    const { recordIndex } = LeadDrawerStore.getState();

    AppDispatcher.dispatch({
      type: DocumentActionTypes.UPDATE_DOCUMENT,
      document,
    });

    updateDocumentRequest = APIRequest.put({
      resource: `/v1/documents/${document.id}`,
      data:     { document },
    });

    updateDocumentRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: DocumentActionTypes.UPDATE_DOCUMENT_FAIL,
          error,
        });
        GlobalContainer.notify(error?.response?.body?.errors || 'Failed to update the document. Please try again later.', 'error');

        return;
      }

      AppDispatcher.dispatch({
        type:     DocumentActionTypes.UPDATE_DOCUMENT_DONE,
        document: response.body,
      });

      GlobalContainer.notify(`Document "${document.title}" updated.`, 'success');

      if (leadID) {
        LeadDrawerActions.loadLead(leadID, { recordIndex });
      }
      else if (table) {
        const { page, s: searchData, per: size } = query;
        DocumentActions.loadDocuments(table, { page, searchData, size });
      }
    });
  },

  restoreDocument(documentID, currentPage) {
    APIRequest.abort(updateDocumentRequest);

    AppDispatcher.dispatch({
      type: DocumentActionTypes.RESTORE_DOCUMENT,
      documentID,
    });

    updateDocumentRequest = APIRequest.put({
      resource: `/v1/documents/${documentID}/restore`,
    });

    updateDocumentRequest.end((error, response) => {
      if (error) {
        const errors = error.response && error.response.body && error.response.body.errors
          ? error.response.body.errors
          : 'Failed to restore the document. Please try again later.';

        AppDispatcher.dispatch({
          type:  DocumentActionTypes.RESTORE_DOCUMENT_FAIL,
          error: errors,
        });

        GlobalContainer.notify(errors, 'error');
        return;
      }

      AppDispatcher.dispatch({
        type:     DocumentActionTypes.RESTORE_DOCUMENT_DONE,
        document: response.body,
      });

      GlobalContainer.notify('Document has been restored', 'success');

      DocumentActions.loadTrashedDocuments(undefined, { page: currentPage });
    });
  },

  permanentDeleteDocument(documentID, currentPage) {
    APIRequest.abort(deleteDocumentRequest);

    AppDispatcher.dispatch({
      type: DocumentActionTypes.PERMANENT_DELETE_DOCUMENT,
      documentID,
    });

    deleteDocumentRequest = APIRequest.delete({
      resource: `/v1/documents/${documentID}`,
    });

    deleteDocumentRequest.end((error, response) => {
      if (error) {
        const errors = error.response && error.response.body && error.response.body.errors
          ? error.response.body.errors
          : 'Failed to permanently delete the document. Please try again later.';

        AppDispatcher.dispatch({
          type:  DocumentActionTypes.PERMANENT_DELETE_DOCUMENT_FAIL,
          error: errors,
        });

        GlobalContainer.notify(errors, 'error');
        return;
      }

      AppDispatcher.dispatch({
        type:       DocumentActionTypes.PERMANENT_DELETE_DOCUMENT_DONE,
        documentId: documentID,
      });

      GlobalContainer.notify('Document has been permanently deleted', 'success');
      DocumentActions.loadTrashedDocuments(undefined, { page: currentPage });
    });
  },

  deleteDocument(document, leadID, table, query) {
    APIRequest.abort(deleteDocumentRequest);

    AppDispatcher.dispatch({
      type: DocumentActionTypes.DELETE_DOCUMENT,
      document,
    });

    deleteDocumentRequest = APIRequest.delete({
      resource: `/v1/documents/${document.id}/move_to_trash`,
    });

    deleteDocumentRequest.end((error, response) => {
      if (error) {
        const errors = error?.response?.body?.errors || 'Failed to delete the document. Please try again later.';

        AppDispatcher.dispatch({
          type:  DocumentActionTypes.DELETE_DOCUMENT_FAIL,
          error: errors,
        });

        GlobalContainer.notify(errors, 'error');
        return;
      }

      AppDispatcher.dispatch({
        type:       DocumentActionTypes.DELETE_DOCUMENT_DONE,
        documentId: document.id,
      });

      GlobalContainer.notify('Document has been deleted', 'success');

      if (leadID) {
        LeadDrawerActions.loadLead(leadID);
      } else if (table) {
        const { page, s: searchData, per: size } = query || {};
        DocumentActions.loadDocuments(table, { page, searchData, size });
      }
    });
  },

  bulkRestoreTrashDocuments(table, trashDocuments, bulkRestoreData) {
    const staffRestriction = getRestrictionScope();
    const scope = localStorage.ownerScope || staffRestriction || 'all';
    const isAllDocuments = typeof trashDocuments === 'string';

    AppDispatcher.dispatch({
      type:       DocumentActionTypes.BULK_RESTORE_TRASH_DOCUMENTS,
      tableScope: table.scope,
      trashDocuments,
      bulkRestoreData,
    });

    const restoreData = {
      document_scope: table.scope,
      scope:          scope,
      ids:            isAllDocuments ? 'all' : _lodash.map(trashDocuments, 'id'),
    };

    const data = { ...restoreData, ...bulkRestoreData };

    bulkUpdateDocumentsRequest = APIRequest.put({
      resource: '/v1/document_bulk_actions/restore_documents',
      data,
    });

    bulkUpdateDocumentsRequest.end((error) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_RESTORE_TRASH_DOCUMENTS_FAIL,
          tableScope: table.scope,
          error,
        });

        GlobalContainer.notify(error?.response?.body?.errors || 'Failed to update documents', 'error');
      } else {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_RESTORE_TRASH_DOCUMENTS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk restore in progress, this may take some time.',
          'success',
        );
      }
    });
  },

  bulkDeleteTrashDocuments(table, trashDocuments, bulkDeleteData) {
    const staffRestriction = getRestrictionScope();
    const scope = localStorage.ownerScope || staffRestriction || 'all';
    const isAllDocuments = typeof trashDocuments === 'string';

    AppDispatcher.dispatch({
      type:       DocumentActionTypes.BULK_DELETE_TRASH_DOCUMENTS,
      tableScope: table.scope,
      trashDocuments,
      bulkDeleteData,
    });

    const deleteData = {
      document_scope: table.scope,
      scope:          scope,
      ids:            isAllDocuments ? 'all' : _lodash.map(trashDocuments, 'id'),
    };

    const data = { ...deleteData, ...bulkDeleteData };

    bulkDeleteDocumentsRequest = APIRequest.delete({
      resource: '/v1/document_bulk_actions/permanently_delete_documents',
      data,
    });

    bulkDeleteDocumentsRequest.end((error) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_DELETE_TRASH_DOCUMENTS_FAIL,
          tableScope: table.scope,
          error,
        });

        GlobalContainer.notify(error?.response?.body?.errors || 'Failed to delete documents', 'error');
      } else {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_DELETE_TRASH_DOCUMENTS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk delete in progress, this may take some time.',
          'success',
        );
      }
    });
  },

  bulkUpdateDocuments(table, documents, bulkUpdateData, options={}) {
    APIRequest.abort(bulkUpdateDocumentsRequest);

    const staffRestriction = getRestrictionScope();

    AppDispatcher.dispatch({
      type:       DocumentActionTypes.BULK_UPDATE_DOCUMENTS,
      tableScope: table.scope,
      documents,
      bulkUpdateData,
    });

    const scope = localStorage.ownerScope || staffRestriction || 'all';
    const updateData = {
      document_scope: table.scope,
      scope,
    };

    if (typeof documents === 'string') {
      updateData.ids = 'all';
    } else {
      updateData.ids = _lodash.map(documents, 'id');
    }

    const { page, s, per: size } = options;
    const data = { ...updateData, ...bulkUpdateData, s, scope };

    bulkUpdateDocumentsRequest = APIRequest.put({
      resource: '/v1/document_bulk_actions/update_documents',
      data,
    });

    bulkUpdateDocumentsRequest.end((error) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_UPDATE_DOCUMENTS_FAIL,
          tableScope: table.scope,
          error,
        });

        GlobalContainer.notify(error?.response?.body?.errors || 'Failed to update documents', 'error');
      } else {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_UPDATE_DOCUMENTS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk action in progress, this may take some time.',
          'success',
        );

        DocumentActions.loadDocuments(table, { page, size, searchData: s });
      }
    });
  },

  bulkDeleteDocuments(table, documents, bulkDeleteData, searchOptions) {
    APIRequest.abort(bulkDeleteDocumentsRequest);

    const staffRestriction = getRestrictionScope();
    const scope = localStorage.ownerScope || staffRestriction || 'all';
    const isAllDocuments = typeof documents === 'string';

    AppDispatcher.dispatch({
      type:       DocumentActionTypes.BULK_DELETE_DOCUMENTS,
      tableScope: table.scope,
      documents,
      bulkDeleteData,
    });

    const deleteData = {
      document_scope: table.scope,
      scope:          scope,
      ids:            isAllDocuments ? 'all' : _lodash.map(documents, 'id'),
    };

    const { s } = searchOptions;
    const data = { ...deleteData, ...bulkDeleteData, s, scope };

    bulkDeleteDocumentsRequest = APIRequest.delete({
      resource: '/v1/document_bulk_actions/delete_documents',
      data,
    });

    bulkDeleteDocumentsRequest.end((error) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_DELETE_DOCUMENTS_FAIL,
          tableScope: table.scope,
          error,
        });

        GlobalContainer.notify(error?.response?.body?.errors || 'Failed to delete documents', 'error');
      } else {
        AppDispatcher.dispatch({
          type:       DocumentActionTypes.BULK_DELETE_DOCUMENTS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk delete in progress, this may take some time.',
          'success',
        );
        DocumentActions.loadDocuments(table);
      }
    });
  },

};

export default DocumentActions;
