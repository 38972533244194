export const formatPrice = (price) => {
  if (!price) return 'N/A';
  return new Intl.NumberFormat('en-US', {
    style:                 'currency',
    currency:              'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};

export const formatUSDate = (date) => {
  if (!date) return 'N/A';
  const parsedDate = new Date(date);
  return parsedDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
};

export const formatValue = (value) => value || 'N/A';

/**
 * Formats a number with comma separators.
 *
 * @param {number} num - The number to format.
 * @returns {string} The formatted number with commas, e.g., 10,000.
 */
export const formatNumber = (num) => {
  if (typeof num !== 'number') return 0;

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
};
