import React     from 'react';
import PropTypes from 'prop-types';
import autoBind  from 'react-autobind';
import { Link }  from 'react-router-dom';

import Select            from '~/components/forms/select';
import MlsServiceRequest from '~/lib/mls_service_request';

class ImportersSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      options: [],
      value:   props.value,
    };

    autoBind(this);
  }

  componentDidMount() {
    MlsServiceRequest.get({
      resource: '/importers',
    }).end((error, response) => {
      this.setState({ loading: false }, () => {
        if (!error) {
          const options = response.body.map((importer) => ({
            label: importer.name,
            value: importer.id,
          }));

          this.setState({ options });
        }
      });
    });
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : null;
    const { onChange } = this.props;

    this.setState({ value }, () => {
      onChange(selectedOption);
    });
  }

  render() {
    const { placeholder, clearable, className } = this.props;
    const { value, loading, options } = this.state;

    return (
      <Select
        className={className}
        clearable={clearable}
        placeholder={placeholder}
        value={value}
        onChange={this.handleSelectChange}
        options={options}
        isLoading={loading}
      />
    );
  }
}

ImportersSelect.defaultProps = {
  onChange:    () => {},
  placeholder: '--- Select Data Source ---',
  style:       {},
  value:       null,
  clearable:   false,
  className:   '',
};

ImportersSelect.propTypes = {
  onChange:    PropTypes.func,
  placeholder: PropTypes.string,
  style:       PropTypes.shape({}),
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  clearable:   PropTypes.bool,
  className:   PropTypes.string,
};

export default ImportersSelect;
