import autoBind         from 'react-autobind';
import React            from 'react';
import { PropTypes }    from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import brokerbit            from '~/lib/brokerbit';
import Select               from '~/components/forms/select';
import EmailTemplateActions from '~/actions/email_template_actions';
import EmailTemplateStore   from '~/stores/email_template_store';

class EmailTemplateSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const { subject, body, attachments } = props;
    const storeState = EmailTemplateStore.getState();

    this.state = {
      originalSubject:     subject,
      originalBody:        body,
      originalAttachments: attachments,
      saving:              false,
      selectedTemplate:    null,
      templateChanged:     false,
      ...storeState,
    };

    this.componentID = uuidv4();
  }

  componentDidMount() {
    setTimeout(() => EmailTemplateActions.loadEmailTemplates(this.componentID), 500);

    this.templateStoreListener = EmailTemplateStore.addListener(this.onTemplateStoreChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { subject, body, attachments } = nextProps;
    const { originalSubject, originalBody, originalAttachments } = this.state;

    if (subject     !== originalSubject
        || body        !== originalBody
        || attachments !== originalAttachments) {
      this.setState({
        templateChanged: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.templateStoreListener) this.templateStoreListener.remove();
  }

  handleTemplateSelect(selectedOption) {
    const { emailTemplates } = this.state;
    const { applyTemplate } = this.props;

    if (!selectedOption) return;

    if (selectedOption.value === -1) {
      // create new
      this.saveEmailAsTemplate();
    } else {
      this.setState({
        selectedTemplate: selectedOption,
        templateChanged:  false,
      });

      const template = _lodash.find(emailTemplates, (t) => t.id === selectedOption.value);
      applyTemplate(template);
    }
  }

  handleTemplateDelete(e) {
    const { selectedTemplate } = this.state;
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to remove this template?',
      callback: (ok) => {
        if (ok) {
          EmailTemplateActions.deleteEmailTemplate(selectedTemplate.value, this.componentID);
          this.setState({
            selectedTemplate: null,
          });
        }
      },
    });
  }

  onTemplateStoreChange() {
    const newState = { ...EmailTemplateStore.getState() };

    if (newState.componentID === this.componentID) {
      if (newState.emailTemplateStoreAction === 'createEmailTemplateDone' && newState.emailTemplate) {
        GlobalContainer.notify('Email template added');
        newState.selectedTemplate = { label: newState.emailTemplate.title, value: newState.emailTemplate.id };
        newState.errors = {};
      }

      if (newState.emailTemplateStoreAction === 'updateEmailTemplateDone' && newState.emailTemplate) {
        GlobalContainer.notify('Email template updated');
        newState.selectedTemplate = { label: newState.emailTemplate.title, value: newState.emailTemplate.id };
        newState.errors = {};
      }

      if (newState.emailTemplateStoreAction === 'createEmailTemplateFail'
          || newState.emailTemplateStoreAction === 'updateEmailTemplateFail') {
        GlobalContainer.notify(GlobalContainer.renderErrors(newState.errors), 'error');
      }

      if (newState.emailTemplateStoreAction === 'deleteEmailTemplateDone') {
        GlobalContainer.notify('Email template deleted');
      }
    }

    this.setState(newState);
  }

  buildTemplateOptions() {
    const { emailTemplates } = this.state;

    const options = [{ label: '- Create new template -', value: -1 }];

    if (emailTemplates) {
      emailTemplates.forEach((template) => {
        options.push({ label: template.title, value: template.id });
      });
    }

    return options;
  }

  saveEmailAsTemplate() {
    this.setState({ saving: true });

    const {
      subject, body, attachments, validateEmailTemplate,
    } = this.props;

    const errors = validateEmailTemplate();

    if (_lodash.size(errors) === 0) {
      EmailTemplateActions.createEmailTemplate({
        title:          subject,
        body,
        attachment_ids: _lodash.map(attachments, (a) => a.id),
      }, this.componentID);
      this.setState({ errors });
    } else {
      this.setState({ saving: false, templateSaved: false, errors });
    }
  }

  updateEmailTemplate(e) {
    e.preventDefault();
    this.setState({ saving: true });

    const { selectedTemplate } = this.state;
    const {
      subject, body, attachments, validateEmailTemplate,
    } = this.props;

    const errors = validateEmailTemplate();

    if (_lodash.size(errors) === 0 && selectedTemplate.value) {
      EmailTemplateActions.updateEmailTemplate({
        id:             selectedTemplate.value,
        title:          subject,
        body,
        attachment_ids: _lodash.map(attachments, (a) => a.id),
      }, this.componentID);
      this.setState({ errors });
    } else {
      this.setState({ saving: false, templateSaved: false, errors });
    }
  }

  toggleDropdownTemplateOption() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { dropdownOpen, selectedTemplate, templateChanged } = this.state;

    return (
      <div className="d-flex">
        <div className="flex-grow-1">
          <Select
            placeholder="Select a Template"
            options={this.buildTemplateOptions()}
            value={selectedTemplate && selectedTemplate.value}
            onChange={this.handleTemplateSelect}
            clearable={false}
            className="p0"
          />
        </div>

        { selectedTemplate && (
          <div className="form-group mb5 ml5">
            <div className="btn-toolbar">
              <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggleDropdownTemplateOption}>
                <DropdownToggle caret className="m0">
                  Options
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem disabled={!selectedTemplate || !templateChanged} onClick={this.updateEmailTemplate}>
                    Save changes
                  </DropdownItem>
                  <DropdownItem disabled={!selectedTemplate} onClick={this.handleTemplateDelete}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EmailTemplateSelect.defaultProps = {
  subject:               '',
  body:                  '',
  attachments:           [],
  applyTemplate:         () => false,
  validateEmailTemplate: () => false,
};

EmailTemplateSelect.propTypes = {
  subject:               PropTypes.string,
  body:                  PropTypes.string,
  attachments:           PropTypes.arrayOf(PropTypes.shape({})),
  applyTemplate:         PropTypes.func,
  validateEmailTemplate: PropTypes.func,
};

export default EmailTemplateSelect;
