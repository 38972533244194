import AppDispatcher    from '~/dispatchers/app_dispatcher';
import APIRequest       from '~/lib/api_request';
import VideoActionTypes from '~/types/video_action_types';

let videosRequest;
let videoRequest;
let createVideoRequest;
let updateVideoRequest;
let deleteVideoRequest;

const VideoActions = {
  loadVideos({ page = 1, search = {} } = {}) {
    APIRequest.abort(videosRequest);

    AppDispatcher.dispatch({
      type: VideoActionTypes.LOAD_VIDEOS,
    });

    videosRequest = APIRequest.get({
      resource: '/v1/videos',
      data:     {
        page,
        search,
      },
    });

    videosRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: VideoActionTypes.LOAD_VIDEOS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:            VideoActionTypes.LOAD_VIDEOS_DONE,
        videos:          response.body?.videos,
        count_of_videos: response.body?.count_of_videos,
        videos_limit:    response.body?.videos_limit,
        pagination:      response.body?.pagination,
      });
    });
  },

  loadVideo(videoID) {
    APIRequest.abort(videoRequest);

    AppDispatcher.dispatch({
      type: VideoActionTypes.LOAD_VIDEO,
    });

    videoRequest = APIRequest.get({
      resource: `/v1/videos/${videoID}`,
    });

    videoRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: VideoActionTypes.LOAD_VIDEO_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   VideoActionTypes.LOAD_VIDEO_DONE,
        video: response.body,
      });
    });
  },

  createVideo(params) {
    APIRequest.abort(createVideoRequest);

    AppDispatcher.dispatch({
      type: VideoActionTypes.CREATE_VIDEO,
    });

    createVideoRequest = APIRequest.post({
      resource: '/v1/videos/',
      data:     { ...params },
    });

    createVideoRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: VideoActionTypes.CREATE_VIDEO_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   VideoActionTypes.CREATE_VIDEO_DONE,
        video: response.body,
      });
    });
  },

  updateVideo(video, { page = 1, loadVideos = true, search = {} } = {}) {
    APIRequest.abort(updateVideoRequest);

    AppDispatcher.dispatch({
      type: VideoActionTypes.UPDATE_VIDEO,
      video,
    });

    updateVideoRequest = APIRequest.put({
      resource: `/v1/videos/${video.id}`,
      data:     { ...video },
    });

    updateVideoRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: VideoActionTypes.UPDATE_VIDEO_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  VideoActionTypes.UPDATE_VIDEO_DONE,
        video: response.body,
      });

      if (loadVideos) {
        VideoActions.loadVideos({
          page,
          search,
        });
      }
    });
  },

  deleteVideo(video, { page = 1, loadVideos = true, search = {} } = {}) {
    APIRequest.abort(deleteVideoRequest);

    AppDispatcher.dispatch({
      type: VideoActionTypes.DELETE_VIDEO,
      video,
    });

    deleteVideoRequest = APIRequest.delete({
      resource: `/v1/videos/${video.id}`,
    });

    deleteVideoRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: VideoActionTypes.DELETE_VIDEO_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: VideoActionTypes.DELETE_VIDEO_DONE,
      });

      if (loadVideos) {
        VideoActions.loadVideos({
          page,
          search,
        });
      }
    });
  },
};

export default VideoActions;
