import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalStates } from '../states/ModalStates';

const ErrorState = ({ state }) => (
  <div className="card">
    <div className="card-header">
      {state.status === ModalStates.ERROR && (
        <FontAwesomeIcon
          icon="far fa-times-circle"
          className="mr5 text-danger"
        />
      )}

      {state.inputText}
    </div>

    <div className="card-body">
      <div className="text-danger">
        {state.error && state.error}
      </div>
    </div>
  </div>
);

export default ErrorState;
