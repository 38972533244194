import React, {
  useState,
  useCallback,
} from 'react';
import StepWizard     from 'react-step-wizard';
import DownloadStep   from './steps/download_step';
import SettingsStep   from './steps/settings_step';
import UploadFileStep from './steps/upload_file_step';

const LeadsImportTab = () => {
  const importType = 'Import::RecruitingBridgeReferrals';
  const templateUrl = '/leads_template.xlsx';
  const [labels, setLabels] = useState('');
  const [multipleListingServiceOuid, setMultipleListingServiceOuid] = useState('');

  const handleLabelsChange = useCallback((e) => {
    setLabels(e.target.value);
  }, []);

  const handleMultipleListingServiceChange = useCallback((value) => {
    setMultipleListingServiceOuid(value);
  }, []);

  const resetState = useCallback(() => {
    setLabels('');
    setMultipleListingServiceOuid('');
  }, []);

  return (
    <div className="leads-import mt-2">
      <h5>Follow the prompts below to import your data into Brokerkit</h5>

      <ul className="mt-2 pl-3">
        <li>
          Download our import template in Excel format, add in your data, and then
          {' '}
          <strong>save it as a CSV file to import</strong>
        </li>
        <li>We match data by column header name to know which field to import the data into so do not modify the column header names</li>
        <li>Any columns not in the download template will be skipped on import</li>
        <li>Any row that has neither an email address nor an agent ID will be skipped</li>
        <li>The agent ID OR email address columns will be used to match and update existing records</li>
        <li>If there are no existing records with the email addresses or agent IDs in the CSV, then new records will be created</li>
        <li>An MLS is required to be selected if the import includes Agent IDs</li>
        <li>Please import data from only one MLS at a time</li>
      </ul>

      <div className="mt-3">
        <StepWizard>
          <DownloadStep
            key="leads-download-template"
            step={1}
            importType={importType}
            templateUrl={templateUrl}
          />
          <SettingsStep
            key="leads-settings"
            step={2}
            labels={labels}
            multiple_listing_service_ouid={multipleListingServiceOuid}
            handleLabelsChange={handleLabelsChange}
            handleMultipleListingServiceChange={handleMultipleListingServiceChange}
          />
          <UploadFileStep
            key="leads-upload-template"
            step={3}
            importType={importType}
            labels={labels}
            multiple_listing_service_ouid={multipleListingServiceOuid}
            resetState={resetState}
          />
        </StepWizard>
      </div>
    </div>
  );
};

export default LeadsImportTab;
