import autoBind     from 'react-autobind';
import React        from 'react';
import PropTypes    from 'prop-types';
import NumberFormat from 'react-number-format';

class PercentageInput extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      name:  props.name,
      value: props.value || '',
    };
  }

  onChange(obj, e) {
    const newValue = obj.value;

    this.setState({ value: newValue }, () => {
      this.props.onChange(newValue);
    });
  }

  render() {
    const { value } = this.state;

    return (
      <NumberFormat
        value={value}
        thousandSeparator
        suffix=" %"
        className="form-control"
        isNumericString
        isAllowed={(vals, e) => (this.props.size ? vals.value.toString().length <= this.props.size : true)}
        onValueChange={this.onChange}
      />
    );
  }
}

PercentageInput.defaultProps = {
  name:     null,
  value:    null,
  size:     null,
  onChange: () => false,
};

PercentageInput.propTypes = {
  name:     PropTypes.string,
  value:    PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  size:     PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
};

export default PercentageInput;
