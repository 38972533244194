import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NoPreviewDocViewer({ document }) {
  return (
    <div className='text-center py-5 my-5'>
      <h3 className='text-secondary'>File not available to display</h3>
      <br />
      <h3 className='text-secondary mt-5'>Supported files to display are:</h3>
      <h3 className='text-secondary'>PDF, PNG, JPG, JPEG,</h3>
      <h3 className='text-secondary'> DOC, DOCX, XLS, XLSX, PPT, PPTX</h3>
    </div>
  )
}
