import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isURL               from 'validator/lib/isURL';

import BusinessProfileActions  from '~/actions/business_profile_actions';
import BusinessProfileStore    from '~/stores/business_profile_store';
import RegistrationTypeSelect from '~/components/forms/registration_type_select';
import BusinessTypeSelect     from '~/components/forms/business_type_select';
import TwilioCountrySelect    from '~/components/forms/twilio_country_select';
import Tooltipable            from '~/components/effects/tooltipable';
import ErrorAlert             from '~/components/shared/error_alert';

class BusinessProfileTab extends React.Component {
  constructor(props) {
    super(props);

    const { address, business_information } = this.props;
    const {
      street, city, region, postal_code, iso_country,
    } = address;
    const {
      business_name, friendly_name,
      business_type, business_registration_identifier,
      business_registration_number, website_url,
    } = business_information;

    this.state = {
      errors:   {},
      creating: false,
      saving:   false,
      address:  {
        street:      street || '',
        city:        city || '',
        region:      region || '',
        postal_code: postal_code || '',
        iso_country: iso_country || '',
      },
      business_information: {
        business_name:                    business_name || '',
        friendly_name:                    friendly_name || '',
        business_type:                    business_type || '',
        business_registration_identifier: business_registration_identifier || '',
        business_registration_number:     business_registration_number || '',
        website_url:                      website_url || '',
      },
    };

    autoBind(this);
  }

  componentDidMount() {
    // listen to BusinessProfileStore change events and store reference
    this.listener = BusinessProfileStore.addListener(this.onBusinessProfileStoreChange);
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  onBusinessProfileStoreChange() {
    const {
      errors,
      creating,
      saving,
      business_information,
      address,
      lastBusinessProfileStoreAction,
      tabName,
    } = BusinessProfileStore.getState();

    const { setTab } = this.props;

    let newState = {
      errors,
      creating,
      saving,
    };

    if (lastBusinessProfileStoreAction === 'createProfileDone'
        || lastBusinessProfileStoreAction === 'updateProfileDone') {
      GlobalContainer.notify('Your Business Profile was saved.');
      newState = { ...newState, business_information, address };
    } else if (lastBusinessProfileStoreAction === 'createProfileFail'
        || lastBusinessProfileStoreAction === 'updateProfileFail') {
      newState.submitError = errors || 'Failed to save Business Profile.';
    }

    if (tabName) setTab(tabName);
    if (saving || creating) newState.submitError = null;
    this.setState(newState);
  }

  onCreateClick(e, tabName) {
    e.preventDefault();

    const errors = this.validate();
    const { address, business_information } = this.state;
    const data = { ...address, ...business_information };

    if (_lodash.size(errors) === 0) {
      BusinessProfileActions.createProfile(data, tabName);
    } else {
      this.setState({ errors });
    }
  }

  onSaveClick(e, tabName) {
    e.preventDefault();

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      const { address, business_information } = this.state;
      const data = { ...address, ...business_information };

      BusinessProfileActions.updateProfile(data, tabName);
    } else {
      this.setState({ errors });
    }
  }

  setFormField(model, field, value) {
    this.setState((prevState) => ({
      [model]: {
        ...prevState[model],
        [field]: value,
      },
    }));
  }

  validate() {
    const { business_information, address } = this.state;
    const errors = {};

    if (!business_information.business_name) {
      errors.business_name = 'Is invalid';
    }

    if (!business_information.friendly_name) {
      errors.friendly_name = 'Is invalid';
    }

    if (!business_information.business_type) {
      errors.business_type = 'Is invalid';
    }

    if (!business_information.business_registration_identifier) {
      errors.business_registration_identifier = 'Is invalid';
    }

    if (!business_information.business_registration_number) {
      errors.business_registration_number = 'Is invalid';
    }

    if (!business_information.website_url || !isURL(business_information.website_url)) {
      errors.website_url = 'Is invalid';
    }

    if (!address.street) {
      errors.street = 'Is invalid';
    }

    if (!address.city) {
      errors.city = 'Is invalid';
    }

    if (!address.region) {
      errors.region = 'Is invalid';
    }

    if (!address.postal_code) {
      errors.postal_code = 'Is invalid';
    }

    if (!address.iso_country) {
      errors.iso_country = 'Is invalid';
    }

    return errors;
  }

  render() {
    const {
      errors, business_information={}, address={}, saving, creating, submitError
    } = this.state;
    const { status } = this.props;
    const disableStatus = _lodash.includes(['in-review', 'twilio-approved'], status.status_code);
    const rejectedStatus = _lodash.includes(['twilio-rejected'], status.status_code);

    return (
      <>
        <form onSubmit={this.handleFormSubmit}>
          <div className="row mb-3 text-center">
            <div className="form-group col">
              <label htmlFor="business_name" className="label">Legal Business Name</label>
              <input
                type="text"
                id="business_name"
                placeholder="Enter Legal Business Name"
                value={business_information.business_name}
                className={classNames('form-control', { 'has-error': !!errors.business_name })}
                onChange={(val) => this.setFormField('business_information', 'business_name', val.target.value)}
                disabled={disableStatus}
              />
              <Tooltipable
                target="business_name"
                text="Input the official business name as registered with official federal authority. For instance, in the U.S., this name can be found on your EIN confirmation letter from the IRS."
              />
            </div>

            <div className="form-group col">
              <label htmlFor="friendly_name" className="label">Profile Friendly Name</label>
              <input
                type="text"
                id="friendly_name"
                placeholder="Enter Profile Friendly Name"
                value={business_information.friendly_name}
                className={classNames('form-control', { 'has-error': !!errors.friendly_name })}
                onChange={(val) => this.setFormField('business_information', 'friendly_name', val.target.value)}
                disabled={disableStatus}
              />
              <Tooltipable
                target="friendly_name"
                text="This should be your commonly used business name for branding purposes, such as your Doing Business As (DBA). This is the name your clients will likely recognize."
              />
            </div>
          </div>

          <div className="row mb-3 text-center">
            <div className="form-group col">
              <label htmlFor="business_registration_identifier" className="label">Registration Type</label>
              <RegistrationTypeSelect
                className={classNames({ 'has-error': !!errors.business_registration_identifier })}
                placeholder="Select Business Registration Type"
                value={business_information.business_registration_identifier}
                clearable
                onChange={(val) => this.setFormField('business_information', 'business_registration_identifier', val ? val.value : '')}
                disabled={disableStatus}
              />
            </div>
          </div>

          <div className="row mb-3 text-center">
            <div className="form-group col">
              <label htmlFor="business_registration_number" className="label">Business Registration ID</label>
              <input
                type="text"
                id="business_registration_number"
                placeholder="Enter Business Registration ID"
                value={business_information.business_registration_number}
                className={classNames('form-control', { 'has-error': !!errors.business_registration_number })}
                onChange={(val) => this.setFormField('business_information', 'business_registration_number', val.target.value)}
                disabled={disableStatus}
              />
              <Tooltipable
                target="business_registration_number"
                text="Please provide your business registration number. For example, in the U.S., this could be your EIN as assigned by the IRS."
              />
            </div>

            <div className="form-group col">
              <label htmlFor="business_type" className="label">Business Type</label>
              <BusinessTypeSelect
                className={classNames({ 'has-error': !!errors.business_type })}
                placeholder="Select Business Type"
                value={business_information.business_type}
                clearable
                onChange={(val) => this.setFormField('business_information', 'business_type', val ? val.value : '')}
                disabled={disableStatus}
              />
            </div>
          </div>

          <div className="row mb-3 text-center">
            <div className="form-group col">
              <label htmlFor="website_url" className="label">Business Website</label>
              <input
                type="text"
                id="website_url"
                placeholder="Enter your website url (ex. www.abc.com)"
                className={classNames('form-control', { 'has-error': !!errors.website_url })}
                value={business_information.website_url}
                onChange={(val) => this.setFormField('business_information', 'website_url', val.target.value)}
                disabled={disableStatus}
              />
              <Tooltipable
                target="website_url"
                text="Please input your complete business website URL, beginning with 'http://' or 'https://'. This should match the website address used in your public business interactions."
              />
            </div>
          </div>

          <div className="row mb-3 text-center">
            <div className="form-group col">
              <label htmlFor="street" className="label">Business Address</label>
              <input
                type="text"
                id="street"
                placeholder="Enter Street Address"
                className={classNames('form-control', { 'has-error': !!errors.street })}
                value={address.street}
                onChange={(val) => this.setFormField('address', 'street', val.target.value)}
                disabled={disableStatus}
              />
              <Tooltipable
                target="street"
                text="Please input your official business address registered with the IRS or your state's Secretary of State office."
              />
            </div>
          </div>

          <div className="row mb-3 text-center">
            <div className="form-group col">
              <label htmlFor="city" className="label">City</label>
              <input
                type="text"
                id="city"
                placeholder="Enter City"
                className={classNames('form-control', { 'has-error': !!errors.city })}
                value={address.city}
                onChange={(val) => this.setFormField('address', 'city', val.target.value)}
                disabled={disableStatus}
              />
            </div>

            <div className="form-group col">
              <label htmlFor="region" className="label">State/Province</label>
              <input
                type="text"
                id="region"
                placeholder="Enter State or Province"
                className={classNames('form-control', { 'has-error': !!errors.region })}
                value={address.region}
                onChange={(val) => this.setFormField('address', 'region', val.target.value)}
                disabled={disableStatus}
              />
              <Tooltipable
                target="region"
                text="Please provide the two-letter abbreviation for your business's state. This should match the state in your official business documents."
              />
            </div>
          </div>

          <div className="row mb-3 text-center">
            <div className="form-group col">
              <label htmlFor="postal_code" className="label">Zip/Postal Code</label>
              <input
                type="text"
                id="postal_code"
                placeholder="Enter ZIP or Postal Code"
                className={classNames('form-control', { 'has-error': !!errors.postal_code })}
                value={address.postal_code}
                onChange={(val) => this.setFormField('address', 'postal_code', val.target.value)}
                disabled={disableStatus}
              />
            </div>

            <div className="form-group col">
              <label htmlFor="iso_country" className="label">Country/Region</label>
              <TwilioCountrySelect
                value={address.iso_country}
                className={classNames({ 'has-error': !!errors.iso_country })}
                placeholder="Select Country/Region"
                clearable
                onChange={(val) => this.setFormField('address', 'iso_country', val ? val.value : '')}
                disabled={disableStatus || rejectedStatus}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-8">
              {submitError && <ErrorAlert
                heading="Oops, Unable to Submit your Business Profile"
                message={submitError}
              />}
            </div>
            <div className="col-4 pl-0 pr-3">
              <div className="pull-right">
                {_lodash.isEmpty(business_information) ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-success mr-2"
                      disabled={creating || disableStatus}
                      onClick={(e) => {
                        this.onCreateClick(e, 'profile_status');
                      }}
                    >
                      Back
                    </button>

                    {creating ? (
                      <button type="button" className="btn btn-success mr-2 disabled" disabled={creating}>
                        <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                        {' '}
                        Saving ...
                      </button>
                    ) : (
                      <button type="button" className="btn btn-success mr-2" onClick={this.onCreateClick}>Save</button>
                    )}

                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={creating || disableStatus}
                      onClick={(e) => {
                        this.onCreateClick(e, 'business_contacts');
                      }}
                    >
                      Next
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-success mr-2"
                      disabled={saving || disableStatus}
                      onClick={(e) => {
                        this.onSaveClick(e, 'profile_status');
                      }}
                    >
                      Back
                    </button>

                    {saving ? (
                      <button type="button" className="btn btn-success mr-2 disabled" disabled={saving}>
                        <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                        {' '}
                        Saving ...
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={this.onSaveClick}
                        className={classNames('btn btn-success mr-2', { disabled: disableStatus })}
                        disabled={disableStatus}
                      >
                        Save
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={saving || disableStatus}
                      onClick={(e) => {
                        this.onSaveClick(e, 'business_contacts');
                      }}
                    >
                      Next
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

BusinessProfileTab.defaultProps = {
  bundle:                {},
  status:                {},
  business_information:  {},
  address:               {},
};

BusinessProfileTab.propTypes = {
  bundle:                PropTypes.shape({}),
  status:                PropTypes.shape({}),
  business_information:  PropTypes.shape({}),
  address:               PropTypes.shape({}),
};

export default BusinessProfileTab;
