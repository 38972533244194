import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VideoPlayer  from '~/helpers/video_player';
import VideoActions from '~/actions/video_actions';
import VideoStore   from '~/stores/video_store';

class Preview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const videoId = match?.params?.id;

    this.videoStoreListener = VideoStore.addListener(this.onVideoStoreChange);

    if (videoId) {
      VideoActions.loadVideo(videoId);
    }
  }

  componentWillUnmount() {
    if (this.videoStoreListener) this.videoStoreListener.remove();
  }

  onVideoStoreChange() {
    const {
      loadingVideo,
      video,
      lastVideoStoreAction,
    } = VideoStore.getState();

    let nextState = { loadingVideo, video };

    switch (lastVideoStoreAction) {
      case 'loadVideoFail':
        nextState = { loadingVideo, error: true };
        break;

      default:
        break;
    }

    this.setState(nextState);
  }

  renderVideoPlayer = () => {
    const { video } = this.state;

    if (!video) return null;

    const posterUrl = video.animated_thumbnail ? video.gif_thumbnail : video.thumbnail;
    const videoJsOptions = {
      posterImage:      true,
      autoplay:         false,
      controls:         true,
      fluid:            true,
      bigPlayButton:    false,
      poster:           posterUrl,
      playbackRates:    [1, 1.5, 2],
      aspectRatio:      '16:9',
      audioTrackButton: false,
      controlBar:       {
        pictureInPictureToggle: false,
        fullscreenToggle:       true,
        audioTrackButton:       false,
        playbackRateMenuButton: true,
      },
      sources: [{
        src:  video.url,
        type: 'video/mp4',
      }],
    };

    return (
      <div className="row mb-3">
        <div className="w-100">
          <div className="preview-player">
            <VideoPlayer options={videoJsOptions} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loadingVideo, video, error } = this.state;

    if (loadingVideo) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse />
        </div>
      );
    }

    return (
      <div className="content-container">
        <div className="row mb-5">
          <div className="col">
            {video && (
              <div className="text-center">
                <img
                  src={video.logo ? video.logo : Rails.assets['brokerkit_logo.svg']}
                  alt="BrokerKit Logo"
                  className="mt30 mb15"
                  width="20%"
                />
              </div>
            )}
          </div>
        </div>

        {error && (
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-11 m-auto">
              <div className="text-center">
                <h3>Sorry, but this video is no longer available.</h3>
                <br />
                <FontAwesomeIcon icon="far fa-video-slash" size="2x" />
              </div>
            </div>
          </div>
        )}

        {video && (
          <>
            {this.renderVideoPlayer()}

            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-11 m-auto">
                <div className="text-center">
                  {video.description}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

Preview.defaultProps = {};

Preview.propTypes = {
  match:  PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Preview;
