import qs                   from 'qs';
import classNames           from 'classnames';
import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer from '~/components/global_container';
import CampaignActions from '~/actions/campaign_actions';
import Tooltipable     from '~/components/effects/tooltipable';

class CampaignItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      settingStatus: false,
      campaign:      props.campaign,
    };
  }

  handleSearch(searchData) {
    const { history } = this.props;
    const searchQuery = qs.stringify({ s: searchData, _t: Rails.helpers.currentTeam.id }, { arrayFormat: 'brackets' });
    const resource = GlobalContainer.product() === 'retention' ? 'retention' : 'recruiter';

    history.push(`/${resource}/search?${decodeURI(searchQuery)}`, searchData);
  }

  handlePlayClick(e) {
    e.preventDefault();
    const { campaign } = this.state;

    CampaignActions.runCampaign(campaign.id, this.onStatusChange);

    this.setState({
      settingStatus: true,
    });
  }

  handlePauseClick(e) {
    e.preventDefault();
    const { campaign } = this.state;

    CampaignActions.pauseCampaign(campaign.id, this.onStatusChange);

    this.setState({
      settingStatus: true,
    });
  }

  onStatusChange(campaign) {
    this.setState({
      settingStatus: false,
      campaign,
    });
  }

  renderStatusControls() {
    const { campaign, settingStatus } = this.state;

    const buttonClass = classNames('btn btn-secondary btn-sm mr5', { disabled: settingStatus });

    switch (campaign.status) {
      case 'running':
        return (
          <Tooltipable text="Pauses campaign for all subscribers">
            <button
              type="button"
              className={buttonClass}
              disabled={settingStatus}
              onClick={this.handlePauseClick}
            >
              <FontAwesomeIcon icon={['fas', 'fa-pause']} />
            </button>
          </Tooltipable>
        );
      case 'paused':
      case 'ended':
      default:
        return (
          <Tooltipable text="Runs campaign for all subscribers">
            <button
              type="button"
              className={buttonClass}
              disabled={settingStatus}
              onClick={this.handlePlayClick}
            >
              <FontAwesomeIcon icon={['fas', 'fa-play']} />
            </button>
          </Tooltipable>
        );
    }
  }

  render() {
    const { handleCampaignClick, handleCampaignDelete, handleCampaignDonate } = this.props;
    const { campaign } = this.state;

    let { duration } = campaign;
    if (duration < 86400) duration = 86400;
    else duration += 86400;

    const durationMessage = `lasts ${Moment().add(duration, 's').fromNow(true)}`;
    const campaign_edit_uri = GlobalContainer.productUri(`/campaigns/${campaign.id}/edit`);

    return (
      <tr
        key={`campaign-${campaign.id}`}
        className="text-grey"
        onClick={(e) => {
          e.preventDefault();
          if (e.target.nodeName === 'TD') {
            handleCampaignClick(campaign);
          }
        }}
      >
        <td className="col">
          <strong className="text-wrap">
            <Link
              to={campaign_edit_uri}
              className="text-grey-dark text-no-underline"
            >
              {campaign.name}
            </Link>
          </strong>

          <p className="text-wrap">
            <span className="text-grey text-no-underline campaign-desc">
              {campaign.description}
            </span>
          </p>

          <p className="mt10">
            <small className="text-grey">
              <FontAwesomeIcon
                icon={['far', 'fa-bullhorn']}
                className="mr5"
              />
              {' '}
              {campaign.campaign_steps_count}
              {' '}
              emails/texts/tasks
            </small>
            <small className="text-grey ml30">
              {campaign.new_engine ? (
                <Tooltipable text="This field specifies the duration of the campaign and includes only the waiting periods between steps and does not factor in the On and At parameters as those will depend on the start date.">
                  <>
                    <FontAwesomeIcon
                      icon={['far', 'fa-clock']}
                      className="mr5"
                    />
                    {durationMessage}
                  </>
                </Tooltipable>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={['far', 'fa-clock']}
                    className="mr5"
                  />
                  {durationMessage}
                </>
              )}
            </small>
            <small className="text-grey ml30">
              <FontAwesomeIcon
                icon={['far', 'fa-users']}
                className="mr5"
              />
              {' '}
              {campaign.in_progress_subscriptions_count}
              {' '}
              subscribed
            </small>
            <small className="text-grey ml30">
              <FontAwesomeIcon
                icon={['far', 'fa-check']}
                className="mr5"
              />
              {' '}
              {campaign.completed_subscriptions_count}
              {' '}
              completed
            </small>
            <small className="text-grey ml30">
              <a
                href="#search"
                className="text-grey text-no-underline"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleSearch({
                    query:    '',
                    campaign: [campaign.id],
                  });
                }}
              >
                <FontAwesomeIcon
                  icon={['far', 'fa-paper-plane']}
                  className="mr5"
                />
                {' '}
                view subscribers
              </a>
            </small>
          </p>
        </td>
        <td className="col-2">
          <span className="pull-right">
            {campaign.new_engine && (
              <span className="badge badge-success mr5 p-2">
                2.0
              </span>
            )}

            <Tooltipable text="Donate campaign to Campaign Library">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();
                  handleCampaignDonate(campaign);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-upload']} />
              </button>
            </Tooltipable>

            {this.renderStatusControls()}

            <Tooltipable text="Edit campaign">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();
                  handleCampaignClick(campaign);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-edit']} />
              </button>
            </Tooltipable>

            <Tooltipable text="Deletes campaign and all subscriptions">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleCampaignDelete(campaign);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </Tooltipable>
          </span>
        </td>
      </tr>
    );
  }
}

CampaignItem.defaultProps = {
  handleCampaignClick:  () => false,
  handleCampaignDelete: () => false,
  handleCampaignDonate: () => false,
};

CampaignItem.propTypes = {
  history:              PropTypes.shape({}).isRequired,
  campaign:             PropTypes.shape({}).isRequired,
  handleCampaignClick:  PropTypes.func,
  handleCampaignDelete: PropTypes.func,
  handleCampaignDonate: PropTypes.func,
};

export default withRouter(CampaignItem);
