import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadSourceActions from '~/actions/lead_source_actions';
import LeadSourceStore   from '~/stores/lead_source_store';
import LeadSourceForm    from '~/components/pages/account/forms/lead_sources_form';
import LeadSourcesTable  from './lead_sources_table';

class ListingLeadSources extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingLeadSources: false,
      leadSources:        [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.leadSourceStoreListener = LeadSourceStore.addListener(this.onLeadSourceStoreChange);

    LeadSourceActions.loadLeadSources();
  }

  componentWillUnmount() {
    if (this.leadSourceStoreListener) this.leadSourceStoreListener.remove();
  }

  onLeadSourceStoreChange() {
    const leadSourceStoreState = LeadSourceStore.getState();
    const { loadingLeadSources, leadSources } = leadSourceStoreState;

    this.setState({
      loadingLeadSources,
      leadSources,
    });
  }

  deleteSource(source) {
    if (_lodash.isEmpty(source)) return;

    LeadSourceActions.deleteLeadSource(source);
  }

  renderSourcesTable() {
    const { loadingLeadSources, leadSources } = this.state;

    if (loadingLeadSources) {
      return (
        <div className="text-center card-block">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (leadSources.length === 0) {
      return <div className="text-grey-dark card-block text-center">You don&apos;t have the custom sources, please add...</div>;
    }

    return <LeadSourcesTable loading={loadingLeadSources} leadSources={leadSources} deleteSource={this.deleteSource} />;
  }

  render() {
    return (
      <>
        <div className="mb15">
          <LeadSourceForm />
        </div>

        {this.renderSourcesTable()}
      </>
    );
  }
}

ListingLeadSources.defaultProps = {};

ListingLeadSources.propTypes = {};

export default ListingLeadSources;
