import React from 'react';
import { PropTypes } from 'prop-types';
import autoBind from 'react-autobind';

import FormFooterView from './form_footer_view';
import LeadHelpers from '~/helpers/lead_helpers';

class FormFooter extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const {
      lead, callStatus, status, activityType, sending,
    } = props;
    const { currentUser } = Rails.helpers;
    const newStatus = LeadHelpers.updateStatusOnCommunicationActivity(status, activityType, lead);
    const internal = true;

    this.state = {
      sending,
      status:           newStatus,
      rating:           props.rating,
      internal,
      notify:           !internal,
      call_disposition: callStatus || '',
      errors:           {},
      task:             {
        isOpen:      false,
        name:        'Follow Up',
        category:    'follow_up',
        due_date_at: null,
        description: '',
        owner_id:    currentUser.id,
        priority:    'normal',
        status:      'to_do',
        lead_ids:    [lead.id],
      },
      warningMessage: props.warningMessage || '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { callStatus, sending } = nextProps;
    const newState = { sending };

    if (callStatus) {
      newState.call_disposition = callStatus;
    }

    this.setState(newState);
  }

  onToggleSharedType = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      internal: !prevState.internal,
      notify:   !prevState.notify,
    }));
  };

  onCallDispositionChange = (select) => {
    this.setState({ call_disposition: select.value });
  }

  onStatusChange = (select) => {
    this.setState({ status: select.value, errors: {} });
  }

  onRatingChange = (rating) => {
    this.setState({ rating: rating.value });
  }

  setTaskField(name, val) {
    this.setState((prevState) => ({
      task: {
        ...prevState.task,
        [name]: val,
      },
    }));
  }

  // TODO: because `.validate()` and `serialize()` is being acessed from refs, it's not clear what they are doing
  // from withing this component
  validate = (errors = {}) => {
    const { lead } = this.props;
    const { status, task } = this.state;

    if (task.isOpen) {
      if (!task.due_date_at) {
        errors.due_date_at = 'Must be set to a date';
      }

      if (!task.name) {
        errors.name = "Can't be empty";
      }

      if (!task.category) {
        errors.category = "Can't be empty";
      }

      if (!task.priority) {
        errors.priority = "Can't be empty";
      }

      if (!task.status) {
        errors.status = "Can't be empty";
      }

      if (_lodash.isEmpty(task.lead_ids)) {
        errors.lead_ids = "Can't be empty";
      }
    }

    if (status === 'onboarding' && _lodash.isEmpty(lead.email)) {
      errors.status = 'Please add an email address before moving this lead to Signed.';
    }

    const newState = { errors };

    // if errors is present, mark sending status as false
    if (!_lodash.isEmpty(errors)) newState.sending = false;

    this.setState(newState);

    return errors;
  }

  // TODO: enforce camel case, maybe move serializer logic to some library?
  serialize = () => {
    const {
      status, internal, notify, rating, task, call_disposition,
    } = this.state;

    const attrs = {
      status,
      internal,
      notify,
      rating,
      task,
      call_disposition,
    };

    return attrs;
  }

  render() {
    const {
      lead,
      showRatingSelect,
      submitButtonText,
      submitButtonSendingText,
      submitActionsComponent,
      disabledButton,
      activityType,
      showScheduleButton,
      setSchedule,
      scheduleBtnPopoverPlacement,
      isEditingMode,
      schedule,
      showPopover,
      renderPopover,
      warningMessage,
    } = this.props;
    const {
      internal, status, rating, errors, sending, task, call_disposition,
    } = this.state;

    const onSubmit = (e) => {
      e.preventDefault();

      const { onSubmit: handleSubmit } = this.props;

      if (warningMessage) {
        this.setState({ showPopover: true });
      } else {
        this.setState({ sending: true }, () => {
          handleSubmit(e);
        });
      }
    };

    return (
      <FormFooterView
        ref={(el) => { this.footer = el; }}
        lead={lead}
        task={task}
        rating={rating}
        status={status}
        internal={internal}
        activityType={activityType}
        call_disposition={call_disposition}
        submitButtonText={submitButtonText}
        disabledButton={disabledButton}
        submitButtonSendingText={submitButtonSendingText}
        submitActionsComponent={submitActionsComponent}
        showRatingSelect={showRatingSelect}
        onPrivateClick={this.onPrivateClick}
        onPublicClick={this.onPublicClick}
        onToggleSharedType={this.onToggleSharedType}
        onCallDispositionChange={this.onCallDispositionChange}
        onStatusChange={this.onStatusChange}
        setTaskField={this.setTaskField}
        onRatingChange={this.onRatingChange}
        onSubmit={onSubmit}
        sending={sending}
        errors={errors}
        showScheduleButton={showScheduleButton}
        schedule={schedule}
        setSchedule={setSchedule}
        scheduleBtnPopoverPlacement={scheduleBtnPopoverPlacement}
        isEditingMode={isEditingMode}
        showPopover={showPopover}
        renderPopover={renderPopover}
      />
    );
  }
}

FormFooter.defaultProps = {
  rating:                      '',
  scheduleBtnPopoverPlacement: 'bottom',
  status:                      'new_lead',
  submitButtonText:            'Save',
  submitButtonSendingText:     'Sending ...',
  disabledButton:              false,
  showRatingSelect:            false,
  showScheduleButton:          false,
  isEditingMode:               false,
  submitActionsComponent:      null,
  lead:                        {},
  schedule:                    {},
  onSubmit:                    () => {},
  setSchedule:                 () => {},
};

FormFooter.propTypes = {
  rating:                      PropTypes.string,
  scheduleBtnPopoverPlacement: PropTypes.string,
  status:                      PropTypes.string,
  submitButtonText:            PropTypes.string,
  submitButtonSendingText:     PropTypes.string,
  disabledButton:              PropTypes.bool,
  showRatingSelect:            PropTypes.bool,
  showScheduleButton:          PropTypes.bool,
  isEditingMode:               PropTypes.bool,
  showPopover:                 PropTypes.bool,
  submitActionsComponent:      PropTypes.shape({}),
  lead:                        PropTypes.shape({}),
  schedule:                    PropTypes.shape({}),
  onSubmit:                    PropTypes.func,
  setSchedule:                 PropTypes.func,
  renderPopover:               PropTypes.func,
};

export default FormFooter;
