import { ReduceStore } from 'flux/utils';
import AppDispatcher   from '~/dispatchers/app_dispatcher';
import MlsActionTypes  from '~/types/mls_action_types';

class MlsStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingOptions:     false,
      loadingPrimaryMls:  false,
      options:            [],
      lastMlsStoreAction: null,
      primaryMlsOuid:     '',
    };
  }

  loadMlsOptions(state, action) {
    const newState = { ...state };

    newState.loadingOptions = true;
    newState.lastMlsStoreAction = 'loadMlsOptions';

    return newState;
  }

  loadMlsOptionsDone(state, action) {
    const newState = { ...state };

    newState.loadingOptions = false;
    newState.options = action.options;
    newState.lastMlsStoreAction = 'loadMlsOptionsDone';

    return newState;
  }

  loadMlsOptionsFail(state, action) {
    const newState = { ...state };

    newState.loadingOptions = false;
    newState.options = [];
    newState.lastMlsStoreAction = 'loadMlsOptionsFail';

    return newState;
  }

  loadTeamMlsesOptions(state, action) {
    const newState = { ...state };

    newState.loadingOptions = true;
    newState.lastMlsStoreAction = 'loadTeamMlsesOptions';

    return newState;
  }

  loadTeamMlsesOptionsDone(state, action) {
    const newState = { ...state };

    newState.loadingOptions = false;
    newState.options = action.options;
    newState.lastMlsStoreAction = 'loadTeamMlsesOptionsDone';

    return newState;
  }

  loadTeamMlsesOptionsFail(state, action) {
    const newState = { ...state };

    newState.loadingOptions = false;
    newState.options = [];
    newState.lastMlsStoreAction = 'loadTeamMlsesOptionsFail';

    return newState;
  }

  loadActivatedTeamMlsesOptions(state, action) {
    const newState = { ...state };

    newState.loadingOptions = true;
    newState.lastMlsStoreAction = 'loadActivatedTeamMlsesOptions';

    return newState;
  }

  loadActivatedTeamMlsesOptionsDone(state, action) {
    const newState = { ...state };

    newState.loadingOptions = false;
    newState.options = action.options;
    newState.lastMlsStoreAction = 'loadActivatedTeamMlsesOptionsDone';

    return newState;
  }

  loadActivatedTeamMlsesOptionsFail(state, action) {
    const newState = { ...state };

    newState.loadingOptions = false;
    newState.options = [];
    newState.lastMlsStoreAction = 'loadActivatedTeamMlsesOptionsFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case MlsActionTypes.LOAD_MLS_OPTIONS:
        return this.loadMlsOptions(state, action);

      case MlsActionTypes.LOAD_MLS_OPTIONS_DONE:
        return this.loadMlsOptionsDone(state, action);

      case MlsActionTypes.LOAD_MLS_OPTIONS_FAIL:
        return this.loadMlsOptionsFail(state, action);

      case MlsActionTypes.LOAD_TEAM_MLSES_OPTIONS:
        return this.loadTeamMlsesOptions(state, action);

      case MlsActionTypes.LOAD_TEAM_MLSES_OPTIONS_DONE:
        return this.loadTeamMlsesOptionsDone(state, action);

      case MlsActionTypes.LOAD_TEAM_MLSES_OPTIONS_FAIL:
        return this.loadTeamMlsesOptionsFail(state, action);

      case MlsActionTypes.LOAD_ACTIVATED_TEAM_MLSES_OPTIONS:
        return this.loadActivatedTeamMlsesOptions(state, action);

      case MlsActionTypes.LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_DONE:
        return this.loadActivatedTeamMlsesOptionsDone(state, action);

      case MlsActionTypes.LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_FAIL:
        return this.loadActivatedTeamMlsesOptionsFail(state, action);

      default:
        return state;
    }
  }
}

export default new MlsStore();
