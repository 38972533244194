import React  from 'react';
import Select from '../forms/dropdowns/SelectWrapper/Select';

const StyleguideSelect = (props) => (
  <div className="mb15">
    <Select
      {...props}
      name="form-field-name"
      value="one,two,five"
      options={[
        { value: 'one',   label: 'Item Option 1' },
        { value: 'two',   label: 'Item Option 2' },
        { value: 'three', label: 'Item Option 3' },
        { value: 'four',  label: 'Item Option 4' },
        { value: 'five',   label: 'Item Option 5' },
        { value: 'six',   label: 'Item Option 6' },
        { value: 'seven', label: 'Item Option 7' },
        { value: 'eight', label: 'Item Option 8' },
      ]}
    />
  </div>
);

export default StyleguideSelect;
