const LeadDrawerActionTypes = {
  LOAD_LEAD:      'LOAD_LEAD',
  LOAD_LEAD_DONE: 'LOAD_LEAD_DONE',
  LOAD_LEAD_FAIL: 'LOAD_LEAD_FAIL',

  CREATE_LEAD:      'CREATE_LEAD',
  CREATE_LEAD_DONE: 'CREATE_LEAD_DONE',
  CREATE_LEAD_FAIL: 'CREATE_LEAD_FAIL',

  UPDATE_LEAD:      'UPDATE_LEAD',
  UPDATE_LEAD_DONE: 'UPDATE_LEAD_DONE',
  UPDATE_LEAD_FAIL: 'UPDATE_LEAD_FAIL',

  LEAD_TEAM_MEMBER_INVITE:      'LEAD_TEAM_MEMBER_INVITE',
  LEAD_TEAM_MEMBER_INVITE_DONE: 'LEAD_TEAM_MEMBER_INVITE_DONE',
  LEAD_TEAM_MEMBER_INVITE_FAIL: 'LEAD_TEAM_MEMBER_INVITE_FAIL',

  UPDATE_LEAD_ROLE:      'UPDATE_LEAD_ROLE',
  UPDATE_LEAD_ROLE_FAIL: 'UPDATE_LEAD_ROLE_FAIL',
  UPDATE_LEAD_ROLE_DONE: 'UPDATE_LEAD_ROLE_DONE',

  CREATE_LEAD_EMAIL:      'CREATE_LEAD_EMAIL',
  CREATE_LEAD_EMAIL_DONE: 'CREATE_LEAD_EMAIL_DONE',
  CREATE_LEAD_EMAIL_FAIL: 'CREATE_LEAD_EMAIL_FAIL',

  UPDATE_LEAD_EMAIL:      'UPDATE_LEAD_EMAIL',
  UPDATE_LEAD_EMAIL_DONE: 'UPDATE_LEAD_EMAIL_DONE',
  UPDATE_LEAD_EMAIL_FAIL: 'UPDATE_LEAD_EMAIL_FAIL',

  CANCEL_SCHEDULED_LEAD_EMAIL:      'CANCEL_SCHEDULED_LEAD_EMAIL',
  CANCEL_SCHEDULED_LEAD_EMAIL_DONE: 'CANCEL_SCHEDULED_LEAD_EMAIL_DONE',
  CANCEL_SCHEDULED_LEAD_EMAIL_FAIL: 'CANCEL_SCHEDULED_LEAD_EMAIL_FAIL',

  CREATE_LEAD_SMS_MESSAGE:      'CREATE_LEAD_SMS_MESSAGE',
  CREATE_LEAD_SMS_MESSAGE_DONE: 'CREATE_LEAD_SMS_MESSAGE_DONE',
  CREATE_LEAD_SMS_MESSAGE_FAIL: 'CREATE_LEAD_SMS_MESSAGE_FAIL',

  UPDATE_LEAD_SMS_MESSAGE:      'UPDATE_LEAD_SMS_MESSAGE',
  UPDATE_LEAD_SMS_MESSAGE_DONE: 'UPDATE_LEAD_SMS_MESSAGE_DONE',
  UPDATE_LEAD_SMS_MESSAGE_FAIL: 'UPDATE_LEAD_SMS_MESSAGE_FAIL',

  CANCEL_SCHEDULED_LEAD_SMS_MESSAGE:      'CANCEL_SCHEDULED_LEAD_SMS_MESSAGE',
  CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_DONE: 'CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_DONE',
  CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_FAIL: 'CANCEL_SCHEDULED_LEAD_SMS_MESSAGE_FAIL',

  CREATE_LEAD_ACTIVITY:      'CREATE_LEAD_ACTIVITY',
  CREATE_LEAD_ACTIVITY_DONE: 'CREATE_LEAD_ACTIVITY_DONE',
  CREATE_LEAD_ACTIVITY_FAIL: 'CREATE_LEAD_ACTIVITY_FAIL',

  UPDATE_LEAD_ACTIVITY:      'UPDATE_LEAD_ACTIVITY',
  UPDATE_LEAD_ACTIVITY_DONE: 'UPDATE_LEAD_ACTIVITY_DONE',
  UPDATE_LEAD_ACTIVITY_FAIL: 'UPDATE_LEAD_ACTIVITY_FAIL',

  CREATE_LEAD_APPOINTMENT:      'CREATE_LEAD_APPOINTMENT',
  CREATE_LEAD_APPOINTMENT_DONE: 'CREATE_LEAD_APPOINTMENT_DONE',
  CREATE_LEAD_APPOINTMENT_FAIL: 'CREATE_LEAD_APPOINTMENT_FAIL,',

  UPDATE_LEAD_AVATAR:      'UPDATE_LEAD_AVATAR',
  UPDATE_LEAD_AVATAR_DONE: 'UPDATE_LEAD_AVATAR_DONE',
  UPDATE_LEAD_AVATAR_FAIL: 'UPDATE_LEAD_AVATAR_FAIL,',

  REMOVE_LEAD_AVATAR:      'REMOVE_LEAD_AVATAR',
  REMOVE_LEAD_AVATAR_DONE: 'REMOVE_LEAD_AVATAR_DONE',
  REMOVE_LEAD_AVATAR_FAIL: 'REMOVE_LEAD_AVATAR_FAIL,',

  REASSIGN_LEAD:      'REASSIGN_LEAD',
  REASSIGN_LEAD_DONE: 'REASSIGN_LEAD_DONE',
  REASSIGN_LEAD_FAIL: 'REASSIGN_LEAD_FAIL,',

  UNLOAD_LEAD: 'UNLOAD_LEAD',

  CREATE_RESUME:      'CREATE_RESUME',
  CREATE_RESUME_DONE: 'CREATE_RESUME_DONE',
  CREATE_RESUME_FAIL: 'CREATE_RESUME_FAIL',

  DELETE_RESUME:      'DELETE_RESUME',
  DELETE_RESUME_DONE: 'DELETE_RESUME_DONE',
  DELETE_RESUME_FAIL: 'DELETE_RESUME_FAIL',

  UPDATE_RESUME:      'UPDATE_RESUME',
  UPDATE_RESUME_DONE: 'UPDATE_RESUME_DONE',
  UPDATE_RESUME_FAIL: 'UPDATE_RESUME_FAIL',
};

export default LeadDrawerActionTypes;
