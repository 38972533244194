import { v4 as uuidv4 } from 'uuid';
import shadowRoot from 'react-shadow';
import autoBind from 'react-autobind';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TimelineActivity from '~/components/drawers/timeline/activity';
import APIRequest       from '~/lib/api_request';

class TimelineActivityEmailSent extends TimelineActivity {
  _isMounted = false;

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadEmailEvents();
  }

  loadEmailEvents() {
    const { activity } = this.state;
    const { email } = activity;

    if (!email) {
      this.setState({ loading: false });

      return;
    }

    if (this._isMounted && email.source !== 'manual') {
      APIRequest.get({ resource: `/v1/events/${email.id}` })
        .end((_err, response) => {
          if (response && response.ok) {
            const emailEvents = response.body;

            this.setState({ emailEvents, loading: false });
          } else {
            this.setState({ loading: false });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onTogglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  renderEmailEvents() {
    const { loading, emailEvents } = this.state;

    if (loading) {
      return (
        <div className="d-flex flex-row-reverse mt-2">
          <FontAwesomeIcon icon="far fa-spinner" pulse />
        </div>
      );
    }

    if (_lodash.isEmpty(emailEvents)) return null;

    return (
      <div className="d-flex flex-row-reverse mt-2">
        <div className="text-center text-black">
          <strong>CLICKS</strong>
          <br />
          {emailEvents?.clicked}
        </div>
        <div className="mr10 text-center text-black">
          <strong>OPENS</strong>
          <br />
          {emailEvents?.opened}
        </div>
        <div className="mr10 text-center text-black">
          <strong>STATUS</strong>
          <br />
          {emailEvents?.status}
        </div>
      </div>
    );
  }

  renderView() {
    const { activity } = this.state;
    let subject;
    let body;
    let attachments;
    let source;
    let id;

    if (activity.email) {
      // Always refer to the email, it's the best source of info.
      id          = activity.email.id;
      subject     = activity.email.subject;
      body        = activity.email.body;
      attachments = activity.email.attachments;
      source      = activity.email.source;
    } else {
      // Just use activity body, if any
      body = activity.body;
    }

    return (
      <div id={`${id}-Email`}>
        { subject && <h5 className="mt10 mb5">{subject}</h5> }

        <shadowRoot.div id="stepEmailBodyPreview" className="form-control font-activity">
          <div className="content" dangerouslySetInnerHTML={{ __html: body || '' }} />
        </shadowRoot.div>

        { attachments && (
          attachments.map((a) => (
            <>
              <a key={uuidv4()} href={a.url}>
                <FontAwesomeIcon icon={['far', 'fa-file']} className="mt10 mr5" />
                {a.name}
              </a>
              <br />
            </>
          ))
        )}
        { source !== 'manual' && this.renderEmailEvents() }
      </div>
    );
  }
}

TimelineActivityEmailSent.defaultProps = {
  editable:       false,
  deletable:      true,
  title:          'Email Sent',
  icon:           'envelope',
  prefix:         'fas',
  color:          'text-blue',
  alwaysPublic:   false,
};

export default TimelineActivityEmailSent;
