import AppDispatcher        from '~/dispatchers/app_dispatcher';
import APIRequest           from '~/lib/api_request';
import LeadLabelActionTypes from '~/types/lead_label_action_types';

let labelsRequest;
let createLabelRequest;
let deleteLabelRequest;

const LeadLabelActions = {
  loadLeadLabels(leadID) {
    APIRequest.abort(labelsRequest);

    AppDispatcher.dispatch({
      type: LeadLabelActionTypes.LOAD_LEAD_LABELS,
      leadID,
    });

    labelsRequest = APIRequest.get({
      resource: `/v1/leads/${leadID}/labels`,
    });

    labelsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadLabelActionTypes.LOAD_LEAD_LABELS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   LeadLabelActionTypes.LOAD_LEAD_LABELS_DONE,
        labels: response.body,
      });
    });
  },

  createLeadLabel(leadID, label) {
    APIRequest.abort(createLabelRequest);

    AppDispatcher.dispatch({
      type: LeadLabelActionTypes.CREATE_LEAD_LABEL,
      leadID,
      label,
    });

    createLabelRequest = APIRequest.post({
      resource: `/v1/leads/${leadID}/labels`,
      data:     { label },
    });

    createLabelRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadLabelActionTypes.CREATE_LEAD_LABEL_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  LeadLabelActionTypes.CREATE_LEAD_LABEL_DONE,
        label: response.body,
      });
    });
  },

  deleteLeadLabel(leadID, label) {
    APIRequest.abort(deleteLabelRequest);

    AppDispatcher.dispatch({
      type: LeadLabelActionTypes.DELETE_LEAD_LABEL,
      leadID,
      label,
    });

    deleteLabelRequest = APIRequest.delete({
      resource: `/v1/leads/${leadID}/labels/${label.id}`,
    });

    deleteLabelRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadLabelActionTypes.DELETE_LEAD_LABEL_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadLabelActionTypes.DELETE_LEAD_LABEL_DONE,
      });
    });
  },
};

export default LeadLabelActions;
