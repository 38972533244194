import { ReduceStore } from 'flux/utils';
import AppDispatcher from '~/dispatchers/app_dispatcher';
import EmailDomainActionTypes from '~/types/email_domain_action_types';

class EmailDomainStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingDomains:        false,
      domains:               [],
      domain:                {},
      lastDomainStoreAction: null, // store last dispatch action name
      errors:                {},
      submitting:            false,
      formVisible:           false,
    };
  }

  loadEmailDomains(state, action) {
    const newState = { ...state };

    newState.loadingDomains = true;
    newState.errors = {};
    newState.lastDomainStoreAction = 'loadEmailDomains';

    return newState;
  }

  loadEmailDomainsDone(state, action) {
    const newState = { ...state };

    newState.loadingDomains = false;
    newState.domains = action.domains;
    newState.errors = {};
    newState.lastDomainStoreAction = 'loadEmailDomainsDone';

    return newState;
  }

  loadEmailDomainsFail(state, action) {
    const newState = { ...state };

    newState.loadingDomains = false;
    newState.submitting = false;
    newState.errors = action.errors;
    newState.lastDomainStoreAction = 'loadEmailDomainsFail';

    return newState;
  }

  createEmailDomain(state, action) {
    const newState = { ...state };

    newState.submitting = true;
    newState.errors = {};
    newState.lastDomainStoreAction = 'createEmailDomain';

    return newState;
  }

  createEmailDomainDone(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.domain = action.domain;
    newState.formVisible = false;
    newState.domains.push(action.domain);
    newState.lastDomainStoreAction = 'createEmailDomainDone';

    return newState;
  }

  createEmailDomainFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.errors = action.error.response.body?.errors;
    newState.lastDomainStoreAction = 'createEmailDomainFail';

    return newState;
  }

  verifyEmailDomain(state, action) {
    const newState = { ...state };

    newState.errors = {};
    newState.lastDomainStoreAction = 'verifyEmailDomain';

    return newState;
  }

  verifyEmailDomainDone(state, action) {
    const newState = { ...state };
    const { domain } = action;
    const { domains } = state;
    const domainIndex = domains.findIndex((d) => d.id === domain.id);

    newState.domain = action.domain;
    newState.lastDomainStoreAction = 'verifyEmailDomainDone';

    if (domainIndex !== -1) {
      newState.domains = [
        ...domains.slice(0, domainIndex),
        domain,
        ...domains.slice(domainIndex + 1),
      ];
    }

    return newState;
  }

  verifyEmailDomainFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body?.errors;
    newState.lastDomainStoreAction = 'verifyEmailDomainFail';

    return newState;
  }

  deleteEmailDomain(state, action) {
    const newState = { ...state };

    newState.lastDomainStoreAction = 'deleteEmailDomain';

    return newState;
  }

  deleteEmailDomainDone(state, action) {
    const newState = { ...state };
    const { domain } = action;
    const { domains } = state;
    const domainIndex = domains.findIndex((d) => d.id === domain.id);

    newState.lastDomainStoreAction = 'deleteEmailDomainDone';
    if (domainIndex !== -1) {
      newState.domains.splice(domainIndex, 1);
    }

    return newState;
  }

  deleteEmailDomainFail(state, action) {
    const newState = { ...state };

    newState.lastDomainStoreAction = 'deleteEmailDomainFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case EmailDomainActionTypes.LOAD_EMAIL_DOMAINS:
        return this.loadEmailDomains(state, action);

      case EmailDomainActionTypes.LOAD_EMAIL_DOMAINS_DONE:
        return this.loadEmailDomainsDone(state, action);

      case EmailDomainActionTypes.LOAD_EMAIL_DOMAINS_FAIL:
        return this.loadEmailDomainsFail(state, action);

      case EmailDomainActionTypes.CREATE_EMAIL_DOMAIN:
        return this.createEmailDomain(state, action);

      case EmailDomainActionTypes.CREATE_EMAIL_DOMAIN_DONE:
        return this.createEmailDomainDone(state, action);

      case EmailDomainActionTypes.CREATE_EMAIL_DOMAIN_FAIL:
        return this.createEmailDomainFail(state, action);

      case EmailDomainActionTypes.VERIFY_EMAIL_DOMAIN:
        return this.verifyEmailDomain(state, action);

      case EmailDomainActionTypes.VERIFY_EMAIL_DOMAIN_DONE:
        return this.verifyEmailDomainDone(state, action);

      case EmailDomainActionTypes.VERIFY_EMAIL_DOMAIN_FAIL:
        return this.verifyEmailDomainFail(state, action);

      case EmailDomainActionTypes.DELETE_EMAIL_DOMAIN:
        return this.deleteEmailDomain(state, action);

      case EmailDomainActionTypes.DELETE_EMAIL_DOMAIN_DONE:
        return this.deleteEmailDomainDone(state, action);

      case EmailDomainActionTypes.DELETE_EMAIL_DOMAIN_FAIL:
        return this.deleteEmailDomainFail(state, action);

      default:
        return state;
    }
  }
}

export default new EmailDomainStore();
