import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer        from '~/components/global_container';
import CampaignTriggerActions from '~/actions/campaign_trigger_actions';
import CampaignTriggerForm    from '~/components/pages/account/forms/campaign_trigger_form';

class ComposingCampaignTrigger extends React.Component {
  constructor(props) {
    super(props);

    const { triggerID } = props;

    this.state = {
      loadingCampaignTrigger: !!triggerID,
      campaignTrigger:        null,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { triggerID } = this.props;

    if (triggerID) {
      CampaignTriggerActions.loadCampaignTrigger(triggerID, this.onCampaignTriggerLoad);
    }
  }

  onCampaignTriggerLoad(campaignTrigger) {
    this.setState({
      loadingCampaignTrigger: false,
      campaignTrigger,
    });
  }

  renderCampaignForm() {
    const { loadingCampaignTrigger, campaignTrigger } = this.state;

    if (loadingCampaignTrigger) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    return <CampaignTriggerForm campaignTrigger={campaignTrigger} history={this.props.history} />;
  }

  render() {
    const campaign_triggers_uri = GlobalContainer.productUri('/campaign_triggers');

    return (
      <div className="card campaign-trigger">
        <div className="card-block">
          <h4 className="mb30">
            <div className="pull-right">
              <Link to={campaign_triggers_uri} className="btn btn-secondary mr5">Back To Triggers</Link>
            </div>
            Smart Campaign Triggers
            <br />
          </h4>

          {this.renderCampaignForm()}
        </div>
      </div>
    );
  }
}

ComposingCampaignTrigger.defaultProps = {
  triggerID: null,
};

ComposingCampaignTrigger.propTypes = {
  triggerID: PropTypes.string,
  history:   PropTypes.shape({}).isRequired,
};

export default withRouter(ComposingCampaignTrigger);
