const hasOrganizationId = Rails?.helpers?.currentTeam?.organization_id;
const hasAdminRole = Rails?.helpers?.currentUser?.role?.name === 'admin';

const PIPELINE_LEVEL_OPTIONS = [
  { value: 'account', label: 'Account' },
  ...(hasOrganizationId && hasAdminRole ? [{ value: 'organization', label: 'Organization' }] : []),
];

const ACTIVITY_LEVEL_OPTIONS = [
  { value: 'account', label: 'Account' },
  ...(hasOrganizationId ? [{ value: 'organization', label: 'Organization' }] : []),
];

export { PIPELINE_LEVEL_OPTIONS, ACTIVITY_LEVEL_OPTIONS };
