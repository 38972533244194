import autoBind                from 'react-autobind';
import React                   from 'react';
import BlankPage               from '~/components/pages/blank_page';
import FrontSideBar            from '~/components/navigation/front_side_bar';
import ListingCampaignTriggers from './manage_campaign_triggers/listing_campaign_triggers';

class ManageCampaignTriggers extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    return (
      <BlankPage title="Campaign Triggers - Brokerkit">
        <div className="row mb15">
          <FrontSideBar />

          <div className="right-side-page-md">
            <ListingCampaignTriggers />
          </div>
        </div>
      </BlankPage>
    );
  }
}

export default ManageCampaignTriggers;
