export const NOTIFICATION_TYPE_OPTIONS = [
  { value: 'all',                       label: 'All' },
  { value: 'email',                     label: 'Email' },
  { value: 'text',                      label: 'Text' },
  { value: 'agent_alerts_new_listing',  label: 'Agent Alert - New Listing' },
  { value: 'agent_alerts_new_pending',  label: 'Agent Alert - New Pending' },
  { value: 'agent_alerts_new_contract', label: 'Agent Alert - New Contract' },
  { value: 'agent_alerts_new_closing',  label: 'Agent Alert - New Closing' },
  { value: 'agent_alerts_misc',         label: 'Agent Alert - Misc' },
  // { value: 'call',         label: 'Call' },
  // { value: 'landing_page', label: 'Landing Page' },
  // { value: 'referral',     label: 'Referral' },
];
