import autoBind       from 'react-autobind';
import React          from 'react';
import { PropTypes }  from 'prop-types';

import BlankPage       from '~/components/pages/blank_page';
import AccountSideBar  from '~/components/navigation/account_side_bar';
import TeamMembersCore from './team_members/team_members_core';

class TeamMembers extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      teamMembersState: null,
    };
  }

  handleTeamMembersStoreChange(teamMembersState) {
    this.setState({
      teamMembersState,
    });
  }

  handleTabSwitch(tab) {
    this.setState({ selectedTab: tab });
  }

  renderImportNotice() {
    const { authToken } = Rails.helpers;

    return (
      <div className="alert alert-info mb30">
        Need to import or mass update your team?
        {' '}
        <br />
        <a
          href={`${
            Rails.apiUrl
          }/api/v1/team/agents_or_affiliates.csv?auth=${authToken}`}
        >
          Download a current CSV
        </a>
        {' '}
        and edit team members or add new ones to the end.
      </div>
    );
  }

  renderInviteNotice() {
    const { teamMembersState } = this.state;

    if (_lodash.isEmpty(teamMembersState)) return null;

    const { notJoinedCount } = teamMembersState;

    if (notJoinedCount === 0) return null;

    return (
      <div className="alert alert-info mb30">
        Some of your team hasn't yet joined Brokerkit (
        {notJoinedCount}
        , to be
        exact). To reinvite them,
        {' '}
        <a href="/onboard/referrers">
          <strong>invite who's left</strong>
        </a>
        .
      </div>
    );
  }

  render() {
    const { currentTeam } = Rails.helpers;
    const { selectedTab } = this.state;

    if (!currentTeam) return null;

    if (this.props.sidebar) {
      return (
        <BlankPage>
          <div className="container mt30">
            <div className="row mb15">
              <div className="col-md-3">
                <AccountSideBar team={currentTeam} />
              </div>
              <div className="col-md-9">
                {this.renderImportNotice()}
                {this.renderInviteNotice()}

                <TeamMembersCore
                  onTeamMembersStoreChange={this.handleTeamMembersStoreChange}
                  handleTabSwitch={this.handleTabSwitch}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        </BlankPage>
      );
    }
    return <TeamMembersCore {...this.props} />;
  }
}

TeamMembers.defaultProps = {
  title:                   'Manage Team',
  sidebar:                 true,
  per:                     10,
  notify:                  true,
  drawerExternalRolesOnly: false,
};

TeamMembers.propTypes = {
  title:                   PropTypes.string,
  tabs:                    PropTypes.arrayOf(PropTypes.string),
  selectedTab:             PropTypes.string,
  sidebar:                 PropTypes.bool,
  per:                     PropTypes.number,
  notify:                  PropTypes.bool,
  drawerExternalRolesOnly: PropTypes.bool,
};

export default TeamMembers;
