import { useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { updateReadStatus, markAllAsRead } from '~/graphql/mutations';

export const useNotificationStatus = (
  UserID,
  TeamID,
  Type,
  showOnlyUnread,
  setNotifications,
  setUnreadNotifications,
) => {
  const [updatingAllNotifications, setUpdatingAllNotifications] = useState(false);
  const [updatingNotificationIds, setUpdatingNotificationIds] = useState(new Set());

  const markAllNotificationsAsRead = async () => {
    setUpdatingAllNotifications(true);

    try {
      const client = generateClient();
      const result = await client.graphql({
        query:     markAllAsRead,
        variables: {
          UserID,
          TeamID,
          Type,
        },
      });
      const updatedNotifications = result.data?.markAllAsRead;

      if (updatedNotifications && updatedNotifications.length > 0) {
        setUnreadNotifications((prevCount) => prevCount - updatedNotifications.length);
        setNotifications((prevNotifications) => {
          const updated = prevNotifications.map((notification) => {
            const isUpdated = updatedNotifications.some((updatedNotification) => updatedNotification.ID === notification.ID);
            return isUpdated ? { ...notification, IsRead: true } : notification;
          });

          return showOnlyUnread ? updated.filter((notification) => !notification.IsRead) : updated;
        });
      }
    } finally {
      setUpdatingAllNotifications(false);
    }
  };

  const toggleNotificationReadStatus = async (notification, IsRead) => {
    if (!notification) return;

    setUpdatingNotificationIds((prev) => new Set([...prev, notification.ID]));

    try {
      const client = generateClient();
      const result = await client.graphql({
        query:     updateReadStatus,
        variables: {
          input: {
            ID:        notification.ID,
            CreatedAt: notification.CreatedAt,
            IsRead,
          },
        },
      });

      const data = result.data?.updateReadStatus;

      if (data?.ID) {
        setNotifications((prevNotifications) => {
          const index = prevNotifications.findIndex((n) => n.ID === data.ID);

          if (index !== -1 && IsRead && showOnlyUnread) {
            const updatedNotifications = [...prevNotifications];
            updatedNotifications.splice(index, 1);

            return updatedNotifications;
          } if (index !== -1) {
            const updatedNotifications = [...prevNotifications];

            updatedNotifications[index] = {
              ...updatedNotifications[index],
              IsRead: data.IsRead,
            };

            return updatedNotifications;
          }

          return prevNotifications;
        });
      }
    } finally {
      setUpdatingNotificationIds((prev) => {
        const updated = new Set(prev);
        updated.delete(notification.ID);

        return updated;
      });
    }
  };

  return {
    updatingAllNotifications,
    updatingNotificationIds,
    markAllNotificationsAsRead,
    toggleNotificationReadStatus,
  };
};

export default useNotificationStatus;
