import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind            from 'react-autobind';
import Dropzone            from 'react-dropzone';
import filesize            from 'filesize';
import SuperAgent          from 'superagent';
import APIRequest          from '~/lib/api_request';

import AppModal from '../app_modal';

const acceptedFormats = {
  'image/png':  ['.png'],
  'image/gif':  ['.gif'],
  'image/jpeg': ['.jpeg'],
  'image/jpg':  ['.jpg'],
};
const maxImageSize = 5242880; // 5 MB;

class MmsAttachmentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file:      undefined,
      errors:    {},
      uploading: false,
    };

    this.appModal = React.createRef();

    autoBind(this);
  }

  onDrop = (acceptedFiles, fileRejections) => {
    const file = acceptedFiles[0];
    const rejectedFile = fileRejections[0];

    rejectedFile?.errors.forEach((err) => {
      if (err.code === 'file-too-large') {
        this.setErrors('fileTooLarge', `Error: Image is too large (${filesize(rejectedFile.file.size)}).`);
      }

      if (err.code === 'file-invalid-type') {
        this.setErrors('fileInvalidType', 'Error: Image type is not accepted.');
      }
    });

    if (rejectedFile) return;

    this.setState({ file }, () => {
      this.uploadImage();
    });
  };

  uploadImage = () => {
    const { file } = this.state;
    const { handleMediaUrlChange } = this.props;

    if (!file) return;

    this.setState({ errors: {}, uploading: true });

    // We have to do a special request here since we need to multipart the
    // uploads, and api request by default tries to set everything as JSON
    const req = SuperAgent.post(`${Rails.apiUrl}/api/v1/mms_attachments`);

    req.set(APIRequest.apiRequiredHeaders());
    req.attach('attachment[media]', file);

    req.then((res) => {
      this.setState({
        uploading: false,
        file:      undefined,
        errors:    {},
      }, () => {
        const media = res.body;
        const modalRef = this.appModal.current;
        const $modal = $(modalRef.modal);

        handleMediaUrlChange(media);
        $modal.modal('hide');
      });
    }).catch((error) => {
      this.setState((prevState) => ({
        uploading: false,
        errors:    {
          ...prevState.errors,
          file_uploading: error,
        },
      }));
    });
  }

  setErrors = (name, value) => {
    this.setState((prevState) => ({
      errors: {
        [name]: value,
      },
    }));
  }

  renderDropZone = () => {
    const {
      file,
      errors,
      uploading,
      percentCompleted,
    } = this.state;

    if (uploading) {
      return (

        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse />
          {' '}
          <p>We are now uploading your image.</p>
        </div>
      );
    }

    return (
      <div className="preview-container">
        <Dropzone
          accept={acceptedFormats}
          onDrop={this.onDrop}
          multiple={false}
          maxSize={maxImageSize}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            acceptedFiles,
            fileRejections,
          }) => {
            const rejectedFile = fileRejections[0];

            return (
              <div {...getRootProps({ className: 'dropzone py-5 text-gray' })}>
                <input {...getInputProps()} />

                <FontAwesomeIcon icon="fal fa-cloud-upload" size="5x" className="mb-2" />

                {file ? (
                  <div>
                    <FontAwesomeIcon icon="far fa-image" />
                    {' '}
                    {file.path}
                    {' '}
                    -
                    {' '}
                    {filesize(file.size)}
                  </div>
                ) : (
                  <>
                    {isDragActive ? (
                      <p className="text-gray">Drop the image file here ...</p>
                    ) : (
                      <p className="text-gray">
                        Click or Drag image file to this area to upload.
                      </p>
                    )}

                    <p className="text-gray mt-2">
                      Up to
                      {' '}
                      {filesize(maxImageSize)}
                      {' '}
                      in size.
                    </p>

                    <p className="text-gray mt-2">
                      Accepted formats: png, gif, jpeg, jpg.
                    </p>

                    {rejectedFile && (
                      <>
                        {!!errors.file_uploading && (
                          <div className="text-danger mt-2">
                            Oops, something went wrong. Try again later.
                          </div>
                        )}

                        {!!errors.file && (
                          <div className="text-danger mt-2">
                            File must be selected!
                          </div>
                        )}

                        {!!errors.fileInvalidType && (
                          <div className="text-danger mt-2">
                            {errors.fileInvalidType}
                          </div>
                        )}

                        {!!errors.fileTooLarge && (
                          <div className="text-danger mt-2">
                            {errors.fileTooLarge}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }

  render = () => {
    const {
      containerID,
      modalClass,
      dialogClass,
    } = this.props;

    return (
      <AppModal
        containerID={containerID}
        modalClass={modalClass}
        dialogClass={dialogClass}
        ref={this.appModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="bkModalAttachments">
            <FontAwesomeIcon icon="far fa-image" />
            {' '}
            Insert an image
          </h5>

          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group m0">
            {this.renderDropZone()}
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
        </div>
      </AppModal>
    );
  }
}

MmsAttachmentModal.defaultProps = {
  containerID:          '',
  modalClass:           '',
  dialogClass:          '',
  handleMediaUrlChange: undefined,
};

MmsAttachmentModal.propTypes = {
  containerID:          PropTypes.string,
  modalClass:           PropTypes.string,
  dialogClass:          PropTypes.string,
  handleMediaUrlChange: PropTypes.func,
};

export default MmsAttachmentModal;
