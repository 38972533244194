import React         from 'react';
import { PropTypes } from 'prop-types';
import videojs       from 'video.js';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.options,
    };
  }

  componentDidMount() {
    const { options } = this.state;
    const { handleReRecord } = this.props;

    this.player = videojs(this.videoNode, options);

    if (handleReRecord !== undefined) {
      this.player.getChild('ControlBar').addChild('button', {
        controlText:  'Re-Record',
        className:    'vjs-visible-text',
        clickHandler: () => {
          handleReRecord();
        },
      }, 0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { options } = this.props;

    if (prevProps.options?.poster !== options.poster) {
      this.player.poster(options.poster);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div data-vjs-player>
        <video ref={(node) => this.videoNode = node} className="video-js vjs-default-skin vjs-big-play-centered">
          Sorry, your browser doesn&apos;t support embedded videos.
        </video>
      </div>
    );
  }
}

VideoPlayer.defaultProps = {};

VideoPlayer.propTypes = {
  handleReRecord: PropTypes.func.isRequired,
  options:        PropTypes.shape({}).isRequired,
};

export default VideoPlayer;
