import { ReduceStore } from 'flux/utils';
import AppDispatcher from '~/dispatchers/app_dispatcher';
import DocumentActionTypes from '~/types/document_action_types';
import { getRestrictionScope }  from '~/helpers/staffRestrictionHelper';

class DocumentStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    const staffRestriction = getRestrictionScope();

    return {
      loadingDocuments:        false,
      loadingDocument:         false,
      documents:               [],
      document:                null,
      scopedLoadings:          {},
      scopedRecords:           {},
      scopedQueries:           {},
      bulkUpdatedTables:       {},
      ownerScope:              localStorage.ownerScope || staffRestriction || 'all',
      documentScopeSize:       parseInt(localStorage.documentScopeSize, 10) || 10,
      lastDocumentStoreAction: null,
      errors:                  {},
      submitting:              false,
      pagination:              {
        total_pages:  1,
        current_page: 1,
        total_count:  0,
      },
    };
  }

  loadDocument(state, action) {
    return {
      ...state,
      loadingDocument:         true,
      lastDocumentStoreAction: 'loadDocument',
    };
  }

  loadDocumentDone(state, action) {
    return {
      ...state,
      loadingDocument:         false,
      document:                action.document,
      lastDocumentStoreAction: 'loadDocumentDone',
    };
  }

  loadDocumentFail(state, action) {
    const errors = action.error?.response?.body?.errors || 'An unknown error occurred. Please try again.';
    return {
      ...state,
      loadingDocument:         false,
      errors,
      lastDocumentStoreAction: 'loadDocumentFail',
    };
  }

  loadDocuments(state, action) {
    const newState = { ...state };
    const { table } = action;
    newState.scopedLoadings[table.scope] = true;
    newState.lastDocumentStoreAction = 'loadDocuments';
    return newState;
  }

  loadDocumentsDone(state, action) {
    const newState = { ...state };
    const { table, response, query } = action;
    newState.scopedLoadings[table.scope] = false;
    newState.scopedRecords[table.scope] = response;
    newState.scopedQueries[table.scope] = query;
    newState.pagination = response.pagination;
    newState.lastDocumentStoreAction = 'loadDocumentsDone';
    return newState;
  }

  loadDocumentsFail(state, action) {
    const newState = { ...state };
    const { table } = action;
    newState.scopedLoadings[table.scope] = false;
    newState.errors[table.scope] = action.error.response.body.errors;
    newState.lastDocumentStoreAction = 'loadDocumentsFail';
    return newState;
  }

  switchDocumentScopeSize(state, action) {
    const newState = { ...state };

    newState.documentScopeSize = action.documentScopeSize;
    newState.lastDocumentStoreAction = 'switchDocumentScopeSize';

    return newState;
  }

  loadTrashedDocuments(state, action) {
    const newState = { ...state };
    const { table } = action;
    newState.scopedLoadings[table.scope] = true;
    newState.lastDocumentStoreAction = 'loadTrashedDocuments';

    return newState;
  }

  loadTrashedDocumentsDone(state, action) {
    const newState = { ...state };
    const { table, response, query } = action;
    newState.scopedLoadings[table.scope] = false;
    newState.scopedRecords[table.scope] = response;
    newState.scopedQueries[table.scope] = query;
    newState.pagination = response.pagination;
    newState.lastDocumentStoreAction = 'loadTrashedDocumentsDone';

    return newState;
  }

  loadTrashedDocumentsFail(state, action) {
    const newState = { ...state };
    const { table } = action;
    newState.scopedLoadings[table.scope] = false;
    newState.errors[table.scope] = 'Something went wrong while loading trashed documents.';
    newState.lastDocumentStoreAction = 'loadTrashedDocumentsFail';

    return newState;
  }

  createDocument(state, action) {
    return {
      ...state,
      submitting:              true,
      lastDocumentStoreAction: 'createDocument',
    };
  }

  createDocumentDone(state, action) {
    const documents = [action.document, ...state.documents];
    return {
      ...state,
      submitting:              false,
      documents,
      document:                action.document,
      errors:                  {},
      lastDocumentStoreAction: 'createDocumentDone',
    };
  }

  createDocumentFail(state, action) {
    const errors = action.error?.response?.body?.errors || 'An unknown error occurred. Please try again.';
    return {
      ...state,
      submitting:              false,
      errors,
      lastDocumentStoreAction: 'createDocumentFail',
    };
  }

  loadDocumentsWithRelatedContact(state, action) {
    return {
      ...state,
      loadingDocuments:        true,
      lastDocumentStoreAction: 'loadDocumentsWithRelatedContact',
    };
  }

  loadDocumentsWithRelatedContactDone(state, action) {
    return {
      ...state,
      loadingDocuments:        false,
      documents:               action.documents,
      pagination:              action.pagination,
      lastDocumentStoreAction: 'loadDocumentsWithRelatedContactDone',
    };
  }

  loadDocumentsWithRelatedContactFail(state, action) {
    return {
      ...state,
      loadingDocuments:        false,
      errors:                  action.error.response.body.errors,
      lastDocumentStoreAction: 'loadDocumentsWithRelatedContactFail',
    };
  }

  updateDocument(state, action) {
    return {
      ...state,
      lastDocumentStoreAction: 'updateDocument',
    };
  }

  updateDocumentDone(state, action) {
    const documents = state.documents.map((doc) => (doc.id === action.document.id ? action.document : doc));
    return {
      ...state,
      documents,
      document:                action.document,
      errors:                  {},
      lastDocumentStoreAction: 'updateDocumentDone',
    };
  }

  updateDocumentFail(state, action) {
    return {
      ...state,
      errors:                  action.error.response.body.errors,
      lastDocumentStoreAction: 'updateDocumentFail',
    };
  }

  deleteDocument(state, action) {
    return {
      ...state,
      lastDocumentStoreAction: 'deleteDocument',
    };
  }

  deleteDocumentDone(state, action) {
    const documents = state.documents.filter((doc) => doc.id !== action.documentId);
    return {
      ...state,
      documents,
      lastDocumentStoreAction: 'deleteDocumentDone',
    };
  }

  deleteDocumentFail(state, action) {
    const errors = action.error?.response?.body?.errors ?? 'An unknown error occurred. Please try again.';

    return {
      ...state,
      errors,
      lastDocumentStoreAction: 'deleteDocumentFail',
    };
  }

  loadCategoriesDone(state, action) {
    return {
      ...state,
      categories:              action.categories,
      lastDocumentStoreAction: 'loadCategoriesDone',
    };
  }

  loadStatusesDone(state, action) {
    return {
      ...state,
      statuses:                action.statuses,
      lastDocumentStoreAction: 'loadStatusesDone',
    };
  }

  restoreDocument(state, action) {
    return {
      ...state,
      lastDocumentStoreAction: 'restoreDocument',
    };
  }

  restoreDocumentDone(state, action) {
    const documents = state.documents.map((doc) => (doc.id === action.document.id ? action.document : doc));
    return {
      ...state,
      documents,
      document:                action.document,
      errors:                  {},
      lastDocumentStoreAction: 'restoreDocumentDone',
    };
  }

  restoreDocumentFail(state, action) {
    const errors = action.error?.response?.body?.errors || 'An unknown error occurred. Please try again.';
    return {
      ...state,
      errors,
      lastDocumentStoreAction: 'restoreDocumentFail',
    };
  }

  permanentDeleteDocument(state, action) {
    return {
      ...state,
      lastDocumentStoreAction: 'permanentDeleteDocument',
    };
  }

  permanentDeleteDocumentDone(state, action) {
    const documents = state.documents.filter((doc) => doc.id !== action.documentId);
    return {
      ...state,
      documents,
      lastDocumentStoreAction: 'permanentDeleteDocumentDone',
    };
  }

  permanentDeleteDocumentFail(state, action) {
    return {
      ...state,
      errors:                  action.error.response.body.errors,
      lastDocumentStoreAction: 'permanentDeleteDocumentFail',
    };
  }

  bulkRestoreTrashDocuments(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkRestoreTrashDocuments';

    return newState;
  }

  bulkRestoreTrashDocumentsDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.lastDocumentStoreAction = 'bulkRestoreTrashDocumentsDone';

    return newState;
  }

  bulkRestoreTrashDocumentsFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkRestoreTrashDocumentsFail';

    return newState;
  }

  bulkDeleteTrashDocuments(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkDeleteTrashDocuments';

    return newState;
  }

  bulkDeleteTrashDocumentsDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.lastDocumentStoreAction = 'bulkDeleteTrashDocumentsDone';

    return newState;
  }

  bulkDeleteTrashDocumentsFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkDeleteTrashDocumentsFail';

    return newState;
  }

  bulkUpdateDocuments(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkUpdateDocuments';

    return newState;
  }

  bulkUpdateDocumentsDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.lastDocumentStoreAction = 'bulkUpdateDocumentsDone';

    return newState;
  }

  bulkUpdateDocumentsFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkUpdateDocumentsFail';

    return newState;
  }

  bulkDeleteDocuments(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkDeleteDocuments';

    return newState;
  }

  bulkDeleteDocumentsDone(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = true;
    newState.lastDocumentStoreAction = 'bulkDeleteDocumentsDone';

    return newState;
  }

  bulkDeleteDocumentsFail(state, action) {
    const newState = { ...state };
    const { tableScope } = action;

    newState.bulkUpdatedTables[tableScope] = false;
    newState.lastDocumentStoreAction = 'bulkDeleteDocumentsFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case DocumentActionTypes.LOAD_DOCUMENTS_WITH_RELATED_CONTACT:
        return this.loadDocumentsWithRelatedContact(state, action);
      case DocumentActionTypes.LOAD_DOCUMENTS_WITH_RELATED_CONTACT_DONE:
        return this.loadDocumentsWithRelatedContactDone(state, action);
      case DocumentActionTypes.LOAD_DOCUMENTS_WITH_RELATED_CONTACT_FAIL:
        return this.loadDocumentsWithRelatedContactFail(state, action);
      case DocumentActionTypes.CREATE_DOCUMENT:
        return this.createDocument(state, action);
      case DocumentActionTypes.CREATE_DOCUMENT_DONE:
        return this.createDocumentDone(state, action);
      case DocumentActionTypes.CREATE_DOCUMENT_FAIL:
        return this.createDocumentFail(state, action);
      case DocumentActionTypes.UPDATE_DOCUMENT:
        return this.updateDocument(state, action);
      case DocumentActionTypes.UPDATE_DOCUMENT_DONE:
        return this.updateDocumentDone(state, action);
      case DocumentActionTypes.UPDATE_DOCUMENT_FAIL:
        return this.updateDocumentFail(state, action);
      case DocumentActionTypes.DELETE_DOCUMENT:
        return this.deleteDocument(state, action);
      case DocumentActionTypes.DELETE_DOCUMENT_DONE:
        return this.deleteDocumentDone(state, action);
      case DocumentActionTypes.DELETE_DOCUMENT_FAIL:
        return this.deleteDocumentFail(state, action);
      case DocumentActionTypes.LOAD_CATEGORIES_DONE:
        return this.loadCategoriesDone(state, action);
      case DocumentActionTypes.LOAD_STATUSES_DONE:
        return this.loadStatusesDone(state, action);
      case DocumentActionTypes.LOAD_DOCUMENT:
        return this.loadDocument(state, action);
      case DocumentActionTypes.LOAD_DOCUMENT_DONE:
        return this.loadDocumentDone(state, action);
      case DocumentActionTypes.LOAD_DOCUMENT_FAIL:
        return this.loadDocumentFail(state, action);
      case DocumentActionTypes.LOAD_DOCUMENTS:
        return this.loadDocuments(state, action);
      case DocumentActionTypes.LOAD_DOCUMENTS_DONE:
        return this.loadDocumentsDone(state, action);
      case DocumentActionTypes.LOAD_DOCUMENTS_FAIL:
        return this.loadDocumentsFail(state, action);
      case DocumentActionTypes.SWITCH_DOCUMENT_SCOPE_SIZE:
        return this.switchDocumentScopeSize(state, action);
      case DocumentActionTypes.LOAD_TRASHED_DOCUMENTS:
        return this.loadTrashedDocuments(state, action);
      case DocumentActionTypes.LOAD_TRASHED_DOCUMENTS_DONE:
        return this.loadTrashedDocumentsDone(state, action);
      case DocumentActionTypes.LOAD_TRASHED_DOCUMENTS_FAIL:
        return this.loadTrashedDocumentsFail(state, action);
      case DocumentActionTypes.RESTORE_DOCUMENT:
        return this.restoreDocument(state, action);
      case DocumentActionTypes.RESTORE_DOCUMENT_DONE:
        return this.restoreDocumentDone(state, action);
      case DocumentActionTypes.RESTORE_DOCUMENT_FAIL:
        return this.restoreDocumentFail(state, action);
      case DocumentActionTypes.PERMANENT_DELETE_DOCUMENT:
        return this.permanentDeleteDocument(state, action);
      case DocumentActionTypes.PERMANENT_DELETE_DOCUMENT_DONE:
        return this.permanentDeleteDocumentDone(state, action);
      case DocumentActionTypes.PERMANENT_DELETE_DOCUMENT_FAIL:
        return this.permanentDeleteDocumentFail(state, action);
      case DocumentActionTypes.BULK_RESTORE_TRASH_DOCUMENTS:
        return this.bulkRestoreTrashDocuments(state, action);
      case DocumentActionTypes.BULK_RESTORE_TRASH_DOCUMENTS_DONE:
        return this.bulkRestoreTrashDocumentsDone(state, action);
      case DocumentActionTypes.BULK_RESTORE_TRASH_DOCUMENTS_FAIL:
        return this.bulkRestoreTrashDocumentsFail(state, action);
      case DocumentActionTypes.BULK_DELETE_TRASH_DOCUMENTS:
        return this.bulkDeleteTrashDocuments(state, action);
      case DocumentActionTypes.BULK_DELETE_TRASH_DOCUMENTS_DONE:
        return this.bulkDeleteTrashDocumentsDone(state, action);
      case DocumentActionTypes.BULK_DELETE_TRASH_DOCUMENTS_FAIL:
        return this.bulkDeleteTrashDocumentsFail(state, action);
      case DocumentActionTypes.BULK_UPDATE_DOCUMENTS:
        return this.bulkUpdateDocuments(state, action);
      case DocumentActionTypes.BULK_UPDATE_DOCUMENTS_DONE:
        return this.bulkUpdateDocumentsDone(state, action);
      case DocumentActionTypes.BULK_UPDATE_DOCUMENTS_FAIL:
        return this.bulkUpdateDocumentsFail(state, action);
      case DocumentActionTypes.BULK_DELETE_DOCUMENTS:
        return this.bulkDeleteDocuments(state, action);
      case DocumentActionTypes.BULK_DELETE_DOCUMENTS_DONE:
        return this.bulkDeleteDocumentsDone(state, action);
      case DocumentActionTypes.BULK_DELETE_DOCUMENTS_FAIL:
        return this.bulkDeleteDocumentsFail(state, action);
      default:
        return state;
    }
  }
}

export default new DocumentStore();
