import autoBind from 'react-autobind';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import { ALL_STATUSES } from '~/components/forms/dropdowns/StatusSelect';
import LeadHelpers from '~/helpers/lead_helpers';

class RecipientItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      selected: true,
    };
  }

  onClick(e) {
    e.preventDefault();
    const { lead, handleRecipientSelect } = this.props;
    const { selected } = this.state;

    const selectValue = !selected;

    this.setState({
      selected: selectValue,
    });

    handleRecipientSelect(lead.id, selectValue);
  }

  render() {
    const { lead, className } = this.props;
    const { selected } = this.state;
    const id = uuidv4();
    const displayCustomStatuses = checkFeatureFlag(process.env.DISPLAY_CUSTOM_STATUSES_FLAG);

    return (
      <tr className={classNames('table-item text-left', className, { selected })} onClick={this.onClick}>
        <td width="1%" className="d-none d-md-table-cell pt20 text-center">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={id}
              checked={selected}
              className="custom-control-input"
              onChange={() => {}}
            />
            <label className="custom-control-label" htmlFor={id} />
          </div>
        </td>

        <td className="clickable d-none d-md-table-cell">
          <small className="text-uppercase">
            {displayCustomStatuses
              ? lead.status
              : LeadHelpers.findStatusLabel(lead.status_id, ALL_STATUSES)}
          </small>
        </td>

        <td className="clickable text-grey-dark font-weight-bold">
          {LeadHelpers.renderLeadAvatar(lead, { className: 'mr5' })}
          {LeadHelpers.renderLeadName(lead)}
        </td>

        {GlobalContainer.product() !== 'retention' && (
          <td className="clickable d-none d-md-table-cell" onClick={this.onLeadClick}>
            {lead.currently_at && lead.currently_at.truncate(30)}
          </td>
        )}

        <td className="clickable d-none d-lg-table-cell" onClick={this.onLeadClick}>
          {
            GlobalContainer.product() === 'retention'
              ? LeadHelpers.renderLeadDateField(lead, 'referred_at', 'fromNow')
              : LeadHelpers.renderLeadDateField(lead, 'referred_at')
          }
        </td>
      </tr>
    );
  }
}

RecipientItem.defaultProps = {
  lead:                  null,
  className:             '',
  handleRecipientSelect: () => false,
};

RecipientItem.propTypes = {
  lead:                  PropTypes.shape({}),
  className:             PropTypes.string,
  handleRecipientSelect: PropTypes.func,
};

export default RecipientItem;
