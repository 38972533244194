import React from 'react';

import BlankPage    from '~/components/pages/blank_page';
import FrontSideBar from '~/components/navigation/front_side_bar';
import DataCore     from './data/data_core';

class ManageData extends React.Component {
  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    return (
      <BlankPage title="Data - Brokerkit">
        <div className="row">
          <FrontSideBar />

          <div className="right-side-page-md no-brand-colors">
            <div className="card">
              <div className="card-block">
                <h4 className="mb15">Data</h4>

                <DataCore />
              </div>
            </div>
          </div>
        </div>
      </BlankPage>
    );
  }
}

export default ManageData;
