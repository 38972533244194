import AppDispatcher    from '~/dispatchers/app_dispatcher';
import APIRequest       from '~/lib/api_request';
import LabelActionTypes from '~/types/label_action_types';

let loadLabelRequest;
let labelsRequest;
let createLabelRequest;
let updateLabelRequest;
let deleteLabelRequest;

const LabelActions = {
  loadLabel(labelID, onDone = () => false, onFail = () => false) {
    APIRequest.abort(loadLabelRequest);

    AppDispatcher.dispatch({
      type: LabelActionTypes.LOAD_LABEL,
    });

    loadLabelRequest = APIRequest.get({
      resource: `/v1/labels/${labelID}`,
    });

    loadLabelRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  loadLabels() {
    APIRequest.abort(labelsRequest);

    AppDispatcher.dispatch({
      type: LabelActionTypes.LOAD_LABELS,
    });

    labelsRequest = APIRequest.get({
      resource: '/v1/labels',
    });

    labelsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LabelActionTypes.LOAD_LABELS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   LabelActionTypes.LOAD_LABELS_DONE,
        labels: response.body,
      });
    });
  },

  createLabel(label) {
    APIRequest.abort(createLabelRequest);

    AppDispatcher.dispatch({
      type: LabelActionTypes.CREATE_LABEL,
      label,
    });

    createLabelRequest = APIRequest.post({
      resource: '/v1/labels',
      data:     { label },
    });

    createLabelRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LabelActionTypes.CREATE_LABEL_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  LabelActionTypes.CREATE_LABEL_DONE,
        label: response.body,
      });

      LabelActions.loadLabels();
    });
  },

  updateLabel(label) {
    APIRequest.abort(updateLabelRequest);

    AppDispatcher.dispatch({
      type: LabelActionTypes.UPDATE_LABEL,
      label,
    });

    updateLabelRequest = APIRequest.put({
      resource: `/v1/labels/${label.id}`,
      data:     { label },
    });

    updateLabelRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LabelActionTypes.UPDATE_LABEL_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:  LabelActionTypes.UPDATE_LABEL_DONE,
        label: response.body,
      });

      LabelActions.loadLabels();
    });
  },

  deleteLabel(label) {
    APIRequest.abort(deleteLabelRequest);

    AppDispatcher.dispatch({
      type: LabelActionTypes.DELETE_LABEL,
      label,
    });

    deleteLabelRequest = APIRequest.delete({
      resource: `/v1/labels/${label.id}`,
    });

    deleteLabelRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LabelActionTypes.DELETE_LABEL_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LabelActionTypes.DELETE_LABEL_DONE,
      });

      LabelActions.loadLabels();
    });
  },
};

export default LabelActions;
