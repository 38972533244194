import React from 'react';
import PropTypes from 'prop-types';

const Buttons = ({ handleConfirm, handleCloseModal, isSaving }) => (
  <div className="col d-flex justify-content-end p0">
    <button
      type="button"
      className="btn btn-primary mr-2"
      onClick={!isSaving ? handleConfirm : null}
      disabled={isSaving}
    >
      {isSaving ? 'Creating ...' : 'Confirm'}
    </button>

    <button
      type="button"
      className="btn btn-danger"
      onClick={!isSaving ? handleCloseModal : null}
      disabled={isSaving}
    >
      Close
    </button>
  </div>
);

Buttons.propTypes = {
  handleConfirm:    PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isSaving:         PropTypes.bool.isRequired,
};

export default Buttons;
