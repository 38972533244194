import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, FormGroup, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DocumentActions from '~/actions/document_actions';
import DocumentStore from '~/stores/document_store';
import AppModal from './app_modal';
import LeadsSelect from '~/components/forms/leads_select';
import { TeammatePicker } from '~/components/forms/team_member_picker';

const EditDocumentModal = ({ documentID, containerID, leadID, modalClass, dialogClass, onClose, table, query }) => {
  const [loadingDocument, setLoadingDocument] = useState(!!documentID);
  const [document, setDocument] = useState(null);
  const [categories, setCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const documentStoreListener = DocumentStore.addListener(() => {
      const documentState = DocumentStore.getState();
      const { document, errors, categories, statuses, lastDocumentStoreAction } = documentState;

      if (lastDocumentStoreAction === 'updateDocumentDone') {
        setSubmitting(false);
        if (onClose) onClose();
      } else if (lastDocumentStoreAction === 'updateDocumentFail') {
        console.error('Failed to update document:', errors);
        setErrors(errors);
        setSubmitting(false);
      } else if (lastDocumentStoreAction === 'loadDocumentDone') {
        setLoadingDocument(false);
        setDocument(document);
      } else if (lastDocumentStoreAction === 'loadCategoriesDone') {
        setCategories(categories);
      } else if (lastDocumentStoreAction === 'loadStatusesDone') {
        setStatuses(statuses);
      }
    });

    if (documentID) {
      DocumentActions.loadDocument(documentID);
    }
    DocumentActions.loadCategories();
    DocumentActions.loadStatuses();

    return () => {
      documentStoreListener.remove();
    };
  }, [documentID, onClose]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      DocumentActions.updateDocument(document, leadID, table, query);
    } else {
      setErrors(validationErrors);
      setSubmitting(false);
    }
  };

  const setDocumentField = (name, val) => {
    setDocument((prevDocument) => ({
      ...prevDocument,
      [name]: val,
    }));
  };

  const validate = () => {
    const validationErrors = {};
    if (!document?.title) {
      validationErrors.title = "Can't be empty";
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const renderDocumentForm = () => {
    if (loadingDocument || !document) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    const status_id = document.status_id || document.status?.id || '';
    const category_id = document.category_id || document.category?.id || '';

    return (
      <>
        <FormGroup className="mb-3">
          <Label for="document_title">File name</Label>
          <Input
            type="text"
            value={document.title || ''}
            id="document_title"
            name="title"
            className={classNames({ 'is-invalid': errors.title })}
            placeholder="Enter Name"
            onChange={(e) => setDocumentField('title', e.target.value)}
          />
          {errors.title && <div className="invalid-feedback">{errors.title}</div>}
        </FormGroup>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label for="document_status">Status</Label>
            <Input
              type="select"
              value={status_id}
              id="document_status"
              name="status_id"
              className={classNames({ 'is-invalid': errors.status_id })}
              onChange={(e) => setDocumentField('status_id', e.target.value || null)}
            >
              <option value="">Select Status</option>
              {statuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </Input>
            {errors.status_id && <div className="invalid-feedback">{errors.status_id}</div>}
          </FormGroup>

          <FormGroup className="col-md-6">
            <Label for="document_category">Category</Label>
            <Input
              type="select"
              value={category_id}
              id="document_category"
              name="category_id"
              className={classNames({ 'is-invalid': errors.category_id })}
              onChange={(e) => setDocumentField('category_id', e.target.value || null)}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Input>
            {errors.category_id && <div className="invalid-feedback">{errors.category_id}</div>}
          </FormGroup>
        </div>
        <FormGroup>
          <Label for="document_owner">Owner</Label>
          <TeammatePicker
            id="document_owner"
            name="owner"
            placeholder="Enter Owner"
            value={document.owner_id || ''}
            onChange={(opt) => setDocumentField('owner_id', opt?.value || null)}
            className={classNames({ 'is-invalid': errors.owner })}
            apiResource={'/v1/documents/owner_options'}
            clearable
          />
          {errors.owner && <div className="invalid-feedback">{errors.owner}</div>}
        </FormGroup>
        <FormGroup>
          <Label for="document_related_contacts">Related Contacts</Label>
          <LeadsSelect
            id="document_related_contacts"
            placeholder="Please Select Contacts ..."
            selected_ids={document.related_contacts.related_contact_ids || []}
            multiple
            onChange={(ids) => {
              setDocumentField('related_contacts', {related_contact_ids: (ids?.length ? ids : [])});
            }}
          />
        </FormGroup>
      </>
    );
  };

  return (
    <AppModal
      containerID={containerID}
      modalClass={modalClass}
      dialogClass={dialogClass}
    >
      <form
        method="PUT"
        action={`/api/v1/documents/${document?.id || ''}`}
        onSubmit={handleSubmit}
      >
        <div className="modal-body">
          <button type="button" className="close" onClick={onClose} aria-label="Close" style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 9999 }}>
            <span aria-hidden="true">&times;</span>
          </button>
          {renderDocumentForm()}
        </div>

        <div className="modal-footer">
          {submitting ? (
            <Button type="submit" color="success" disabled>
              <FontAwesomeIcon icon="spinner" pulse className="mr-2" />
              Updating ...
            </Button>
          ) : (
            <Button type="submit" color="success" className="btn btn-success">
              Confirm
            </Button>
          )}
          <Button type="button" color="danger" className="btn btn-danger" onClick={onClose}>
            Close
          </Button>
        </div>
      </form>
    </AppModal>
  );
};

EditDocumentModal.defaultProps = {
  modalClass:  '',
  dialogClass: '',
};

EditDocumentModal.propTypes = {
  documentID:  PropTypes.number.isRequired,
  containerID: PropTypes.string.isRequired,
  leadID:      PropTypes.number,
  modalClass:  PropTypes.string,
  dialogClass: PropTypes.string,
  onClose:     PropTypes.func.isRequired,
  table:       PropTypes.object,
  query:       PropTypes.object,
};

export default EditDocumentModal;
