import React from 'react';

export const RecordingIcon = () => (
  <svg width="46" height="46" className="btn-record-timer">
    <defs>
      <mask id="cirleMask">
        <rect height="46" width="46" fill="white" />
        <circle r="18" cx="23" cy="23" fill="black" />
      </mask>
    </defs>
    <circle r="23" cx="23" cy="23" fill="#64686B" mask="url(#cirleMask)" />
    <path id="timerArc" fill="#ffffff" vectorEffect="0" mask="url(#cirleMask)" />
  </svg>
);
