import autoBind from 'react-autobind';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';

import DuplicatesSelect from '~/components/forms/duplicates_select';
import DataActions from '~/actions/data_actions';
import DataStore from '~/stores/data_store';
import DuplicateItems from './duplicate_items';
import IconInput from '~/components/forms/IconInput';

class DuplicatesTab extends React.Component {
  identifyDuplicatesDebounced = debounce(() => {
    const { duplicatesFilter, duplicatesSearch } = this.state;
    DataActions.identifyDuplicates({ duplicatesFilter, duplicatesSearch }, 1);
  }, 500);

  constructor(props) {
    super(props);

    this.state = {
      errors:            {},
      loadingDuplicates: false,
      duplicateLeads:    [],
      duplicatesFilter:  null,
      pagination:        {},
      duplicatesSearch:  '',
    };

    autoBind(this);
  }

  componentDidMount() {
    // listen to dataStoreListener changes
    this.dataStoreListener = DataStore.addListener(this.onDataStoreChange);
  }

  componentWillUnmount() {
    if (this.dataStoreListener) this.dataStoreListener.remove();
  }

  onDataStoreChange() {
    const dataStoreState = DataStore.getState();
    const {
      duplicateLeads, lastDataStoreAction, loadingDuplicates, errors, pagination,
    } = dataStoreState;

    if (lastDataStoreAction === 'identifyDuplicates') {
      this.setState({ loadingDuplicates });
    }

    if (lastDataStoreAction === 'identifyDuplicatesDone') {
      this.setState({ loadingDuplicates, pagination, duplicateLeads });
    }

    if (lastDataStoreAction === 'identifyDuplicatesFail') {
      this.setState({ loadingDuplicates, errors, pagination });
    }
  }

  handleDuplicatesFilter = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : null;

    this.setState({ duplicatesFilter: value, duplicatesSearch: '' });
  }

  handleDuplicatesSearch = (value) => {
    this.setState({ duplicatesSearch: value });
    const { duplicatesFilter } = this.state;

    if (!duplicatesFilter) return;

    this.identifyDuplicatesDebounced();
  }

  onPageChange(page) {
    const { duplicatesFilter } = this.state;
    const selectedPage = page.selected;

    DataActions.identifyDuplicates({ duplicatesFilter }, selectedPage + 1);
  }

  identifyDuplicates = (e) => {
    e.preventDefault();

    const { duplicatesFilter, duplicatesSearch } = this.state;

    if (!duplicatesFilter) return;

    DataActions.identifyDuplicates({ duplicatesFilter, duplicatesSearch }, 1);
  }

  buildPageHref(pageNumber) {
    return `#page-${pageNumber}`;
  }

  renderDuplicateItems = () => {
    const {
      loadingDuplicates, duplicateLeads, duplicatesFilter, pagination,
    } = this.state;

    if (loadingDuplicates) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (!duplicatesFilter) {
      return <div className="text-grey-dark text-center">Please select a filter by which duplicates will be identified.</div>;
    }

    if (duplicateLeads.length === 0) {
      return <div className="text-grey-dark text-center">You don&apos;t have the duplicate leads or agents.</div>;
    }

    return (
      <DuplicateItems
        pagination={pagination}
        loading={loadingDuplicates}
        duplicates={duplicateLeads}
        duplicatesFilter={duplicatesFilter}
      />
    );
  }

  renderPagination() {
    const {
      loadingDuplicates, duplicatesFilter, duplicateLeads, pagination,
    } = this.state;

    if (_lodash.isEmpty(duplicateLeads) || !duplicatesFilter || loadingDuplicates) {
      return null;
    }

    return (
      <div className="pl20 pr20 pt10 pb10">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-12">
            Showing
            {' '}
            {pagination.start}
            {' '}
            to
            {' '}
            {pagination.end}
            {' '}
            of
            {' '}
            {pagination.total_count}
            {' '}
            duplicates
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            { pagination.total_pages > 1
              && (
              <nav>
                <ReactPaginate
                  forcePage={pagination.current_page - 1}
                  pageCount={pagination.total_pages}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  containerClassName="pagination justify-content-end"
                  activeClassName="active"
                  breakLabel="..."
                  breakLinkClassName="page-link disabled"
                  breakClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  onPageChange={this.onPageChange}
                  hrefBuilder={this.buildPageHref}
                  previousLabel={Rails.isMobile ? '<' : 'Previous'}
                  nextLabel={Rails.isMobile ? '>' : 'Next'}
                />
              </nav>
              )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { errors, duplicatesFilter, duplicatesSearch } = this.state;

    return (
      <>
        <div className="row">
          <div className="mb-2 col-md-5 col-lg-4 text-center">
            <DuplicatesSelect
              value={duplicatesFilter}
              onChange={(opt) => this.handleDuplicatesFilter(opt)}
              clearable
            />
          </div>
          <div className="mb-2 col-auto">
            <button
              type="button"
              className="btn btn-success mb-2"
              onClick={this.identifyDuplicates}
              disabled={!duplicatesFilter}
            >
              <FontAwesomeIcon icon={['far', 'fa-search']} className="mr5" />
              {' '}
              Identify Duplicates
            </button>
          </div>
        </div>

        <div className="row">
          <div className="mb-2 col-md-5 col-lg-4 text-center">
            <IconInput
              iconPrefix="far"
              icon="search"
              side="left"
              type="text"
              className="form-control"
              placeholder="Search..."
              value={duplicatesSearch}
              onChange={(e) => this.handleDuplicatesSearch(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && this.handleDuplicatesSearch(e.target.value)}
              name="search"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 col-lg-12">
            {this.renderDuplicateItems()}
            {this.renderPagination()}
          </div>
        </div>
      </>
    );
  }
}

export default DuplicatesTab;
