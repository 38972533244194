import React      from 'react';
import autoBind   from 'react-autobind';
import classNames from 'classnames';
import isEmail    from 'validator/lib/isEmail';
import { Input }  from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import ErrorMessage from '~/components/forms/ErrorMessage';
import APIRequest from '~/lib/api_request';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHIBLE_KEY);

class CardForm extends React.Component {
  constructor(props) {
    super(props);

    const { subscription } = this.props;

    this.state = {
      showCardForm:    !(subscription && subscription.status === 'active'),
      acceptAgreement: false,
      billingEmail:    (subscription && subscription.billing_email) || '',
      errors:          {},
      loading:         true,
    };

    autoBind(this);
  }

  onAgreementChange(e) {
    const { acceptAgreement } = this.state;

    const change = Object.assign(acceptAgreement, { acceptAgreement: e.target.checked });
    this.setState(change);
  }

  onBillingEmailChange(e) {
    this.setState({ billingEmail: e.target.value });
  }

  onFormSubmit = async (e, elements, stripe) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    this.setState({ authorizing: true, errors: {} });

    const { acceptAgreement, billingEmail } = this.state;
    const {
      planId, pricing_plan_id, additional_users_plan_id, qty_additional_users,
      onSuccess,
    } = this.props;
    const errors = this.validate();
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);

    if (payload.error) errors.stripe = [payload.error.message];

    if (_lodash.size(errors) === 0) {
      APIRequest.post({
        resource: '/v1/subscription',
        data:     {
          plan_id:                  planId,
          pricing_plan_id,
          additional_users_plan_id,
          qty_additional_users,
          product:                  'recruiting',
          token:                    payload ? payload.token : null,
          accept_agreement:         acceptAgreement,
          billing_email:            billingEmail,
        },
      }).end((err, res) => {
        if (res.body.errors) {
          this.setState({ authorizing: false, errors: res.body.errors });
        } else {
          this.setState({ authorizing: false });
          onSuccess(res);
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  handleUpdatePlan = (e) => {
    e.preventDefault();

    this.setState({ authorizing: true, errors: {} });

    const { acceptAgreement, billingEmail } = this.state;
    const {
      planId, pricing_plan_id, additional_users_plan_id, qty_additional_users,
      onSuccess,
    } = this.props;
    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      APIRequest.post({
        resource: '/v1/subscription',
        data:     {
          plan_id:                  planId,
          pricing_plan_id,
          additional_users_plan_id,
          qty_additional_users,
          product:                  'recruiting',
          accept_agreement:         acceptAgreement,
          billing_email:            billingEmail,
        },
      }).end((err, res) => {
        if (res.body.errors) {
          this.setState({ authorizing: false, errors: res.body.errors });
        } else {
          this.setState({ authorizing: false });
          onSuccess(res);
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  validate() {
    const { billingEmail } = this.state;
    const errors = {};

    if (_lodash.isEmpty(billingEmail)) {
      errors.billing_email = "Email address can't be blank";
    }

    if (!_lodash.isEmpty(billingEmail) && !isEmail(billingEmail)) {
      errors.billing_email = 'Email address is invalid';
    }

    return errors;
  }

  renderSubmitButton = (elements, stripe) => {
    const {
      authorizing, acceptAgreement, billingEmail, loading, showCardForm,
    } = this.state;
    const { purchaseButtonLabel } = this.props;

    if (showCardForm && (!stripe || !elements || loading)) {
      return (
        <button type="button" className="btn btn-primary btn-wide" disabled>
          Purchase
        </button>
      );
    }

    if (!showCardForm && !authorizing) {
      return (
        <button type="button" onClick={this.handleUpdatePlan} className="btn btn-primary btn-wide" disabled={!acceptAgreement || !billingEmail}>
          {purchaseButtonLabel}
        </button>
      );
    }

    if (authorizing) {
      return (
        <button type="button" className="btn btn-primary btn-wide" disabled>
          <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
          Purchasing...
        </button>
      );
    }

    return (
      <button type="button" onClick={(e) => this.onFormSubmit(e, elements, stripe)} className="btn btn-primary btn-wide" disabled={!acceptAgreement || !billingEmail}>
        {purchaseButtonLabel}
      </button>
    );
  }

  render() {
    const { subscription, onCancel } = this.props;
    const {
      acceptAgreement, billingEmail, errors, showCardForm,
    } = this.state;

    return (
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <form>
              { errors.general && (
                <div className="text-center alert alert-danger mb-2" dangerouslySetInnerHTML={{ __html: errors.general }} />
              )}

              { showCardForm ? (
                <div className="mb30">
                  <p className="mb10">Enter your credit card information to get started:</p>

                  <div
                    id="card-element"
                    className={classNames('form-control', { 'has-error': !!errors.stripe })}
                  >
                    <CardElement onReady={() => this.setState({ loading: false })} />
                  </div>
                  {errors.stripe && <ErrorMessage message={errors.stripe} />}

                  <div className="field-group mt15">
                    <label htmlFor="billing_email" className="d-block">Billing Email Address</label>
                    <Input
                      id="billing_email"
                      name="billing_email"
                      className={errors.billing_email ? 'has-error' : ''}
                      onChange={this.onBillingEmailChange}
                      value={billingEmail}
                    />
                  </div>
                </div>
              ) : (
                <p className="mb30">
                  <FontAwesomeIcon icon={['far', 'fa-credit-card']} className="mr5" />
                  {' '}
                  <strong>{subscription.card_brand}</strong>
                  {' '}
                  ending in ••••
                  {' '}
                  {subscription.card_last4}
                  <br />
                  <FontAwesomeIcon icon={['far', 'fa-calendar-alt']} className="mr5" />
                  {' '}
                  <strong>Exp. Date:</strong>
                  {' '}
                  {subscription.card_exp_month}
                  /
                  {subscription.card_exp_year}
                  <br />
                  { billingEmail ? (
                    <>
                      <FontAwesomeIcon icon={['far', 'fa-envelope']} className="mr5" />
                      {' '}
                      <strong>Billing Email Address:</strong>
                      {' '}
                      {billingEmail}
                      <br />
                    </>
                  ) : (
                    <span className="text-danger">Please update billing email address on the billing page.</span>
                  )}
                </p>
              )}

              <div className="custom-control custom-checkbox pl20 mb-3">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="accept_agreement"
                  id="accept_agreement"
                  checked={acceptAgreement}
                  disabled={!billingEmail}
                  onChange={this.onAgreementChange}
                />
                <label className="custom-control-label" htmlFor="accept_agreement">
                  Confirm agreement with the
                  Brokerkit
                  {' '}
                  <a href="/home/terms-of-service/" target="_blank" rel="noopener noreferrer"> Terms of Service</a>
                  {' '}
                  and
                  {' '}
                  <a href="/home/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </label>
              </div>

              <div>
                {this.renderSubmitButton(elements, stripe)}
                <button type="button" onClick={onCancel} className="btn btn-secondary btn-wide ml10">Cancel</button>
              </div>
            </form>
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}

export default CardForm;
