import autoBind from 'react-autobind';
import React    from 'react';
import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityNote extends TimelineActivity {
}

TimelineActivityNote.defaultProps = {
  editable:       true,
  deletable:      true,
  title:          'Note',
  icon:           'sticky-note',
  prefix:         'far',
  color:          'text-purple',
  alwaysPublic:   false,
};

export default TimelineActivityNote;
