import React               from 'react';
import { PropTypes }       from 'prop-types';
import autoBind            from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BlankPage                 from '~/components/pages/blank_page';
import AccountSideBar            from '~/components/navigation/account_side_bar';
import TextMessageFormOnboarding from '~/components/drawers/lead_drawer/forms/text_message_form_onboarding';
import SmsNumberEditForm         from '~/components/pages/account/forms/sms_number_edit_form';
import ReleasedPhoneNumbers      from '~/components/pages/account/phone_numbers/released_phone_numbers';
import TwilioProfile              from '~/components/pages/account/phone_numbers/twilio_profile';
import APIRequest                from '~/lib/api_request';

class PhoneNumberSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:                true,
      loadingReleasedNumbers: true,
      phoneNumber:            {},
      numbers:                [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadPhoneNumber();
    this.loadReleasedNumbers();
  }

  onPhoneSelect(phoneNumber) {
    this.setState({ phoneNumber });
  }

  loadPhoneNumber() {
    APIRequest.get({
      resource: '/v1/sms_numbers/mine',
    }).end((error, response) => {
      this.setState({ loadingReleasedNumbers: false }, () => {
        if (!error) {
          this.setState({ phoneNumber: response.body });
        }
      });
    });
  }

  loadReleasedNumbers() {
    APIRequest.get({
      resource: '/v1/sms_numbers',
    }).end((error, response) => {
      this.setState({ loading: false }, () => {
        if (!error) {
          this.setState({ numbers: response.body });
        }
      });
    });
  }

  renderSmsForm() {
    const { loading, phoneNumber } = this.state;

    if (loading) {
      return (
        <p className="text-center p-5">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="2x" />
        </p>
      );
    }

    return (
      <>
        {
          _lodash.isEmpty(phoneNumber.service_number) ? (
            <TextMessageFormOnboarding
              onPhoneSelect={this.onPhoneSelect}
            />
          ) : (
            <div>
              <h4 className="mb15">Phone Number</h4>
              <SmsNumberEditForm
                phoneNumber={phoneNumber}
                onPhoneSelect={this.onPhoneSelect}
                loadReleasedNumbers={this.loadReleasedNumbers}
              />
            </div>
          )
        }
      </>
    );
  }

  render() {
    const { currentTeam, currentUser } = Rails.helpers;
    const { loadingReleasedNumbers, numbers } = this.state;
    const { twilioProfileFeatureEnabled } = Rails.helpers;

    return (
      <BlankPage>
        <div className="container mt30">
          <div className="row mb15">
            <div className="col-md-3">
              <AccountSideBar team={currentTeam} />
            </div>
            <div className="col-md-9">
              <div className="card">
                <div className="card-block">
                  {this.renderSmsForm()}
                  <ReleasedPhoneNumbers
                    numbers={numbers}
                    loading={loadingReleasedNumbers}
                  />
                </div>
              </div>
            </div>
            {twilioProfileFeatureEnabled && (currentUser.role.name === 'admin' || currentUser.admin)
              && (
              <div className="col-md-9 offset-md-3 mt-4">
                <div className="card">
                  <div className="card-block">
                    <h3 className="mb-3">Register for Verified Texting & Calling</h3>
                    <TwilioProfile />
                  </div>
                </div>
              </div>
              )}
          </div>
        </div>
      </BlankPage>
    );
  }
}

export default PhoneNumberSetting;
