import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Input }           from 'reactstrap';
import SuperAgent          from 'superagent';
import Dropzone            from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest          from '~/lib/api_request';
import EmailEditor         from '~/components/forms/HtmlEditors/EmailEditor';
import EmailSelect         from '~/components/forms/email_select';
import EmailTemplateSelect from '~/components/forms/email_template_select';
import TeamEmailPicker     from '~/components/forms/team_fields/team_email_picker';
import InsertDocumentButton from '~/components/insert_document_button';

class EmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:              {},
      from:                props.from || '',
      subject:             props.subject || '',
      body:                props.body || '',
      cc_emails:           props.cc_emails || '',
      attachments:         props.attachments || [],
      attachment_ids:      _lodash.map(props.attachments, (a) => a.id) || [],
      attachmentsSize:     null,
      attachmentsError:    null,
      attachmentsLoading:  false,
      showCCEmails:        !!props.cc_emails || false,
      enableEmailTemplate: true, // disable email template feature
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    const emailFields = ['subject', 'body', 'attachments', 'cc_emails'];

    const hasChanged = emailFields.some((key) => this.props[key] !== prevProps[key]);

    if (hasChanged) {
      const {
        subject, body, attachments, cc_emails,
      } = this.props;

      if (cc_emails) {
        this.handleShowAdditionalEmails();
      } else {
        this.handleHideAdditionalEmails();
      }

      this.setState({
        subject, body, attachments, cc_emails,
      });
    }
  }

  handleShowAdditionalEmails() {
    this.setState({ showCCEmails: true });
  }

  handleHideAdditionalEmails() {
    localStorage.removeItem('ccEmails');
    this.setState({ showCCEmails: false });
  }

  onTemplateSave() {}

  onRemoveAttachment(attachmentID) {
    let { attachments } = this.state;

    attachments = _lodash.filter(attachments, (a) => a.id !== attachmentID);

    this.onAttachmentsChange(attachments);
  }

  onDrop(files) {
    this.setState({ attachmentsError: null, attachmentsLoading: true });

    const { attachments, attachmentsSize } = this.state;
    const { onChange } = this.props;

    // We have to do a special request here since we need to multipart the
    // uploads, and api request by default tries to set everything as JSON
    const req = SuperAgent.post(`${Rails.apiUrl}/api/v1/attachments`);
    req.set(APIRequest.apiRequiredHeaders());
    files.forEach((file) => {
      req.attach('attachment[][file]', file);
    });

    req.end((err, res) => {
      const a = _lodash.zip(files, res.body).map((ary) => _lodash.extend(ary[0], ary[1]));
      const size = _lodash.sumBy(a, 'size') / 1048576 + (attachmentsSize || 0);
      const updatedAttachments = _lodash.concat(attachments, a);
      if (size < 5.0) {
        this.setState({
          attachmentsSize:    size,
          attachments:        updatedAttachments,
          attachmentsLoading: false,
        });

        this.onAttachmentsChange(updatedAttachments);
      } else {
        this.setState({
          attachmentsError:
            'Sorry, Google only supports emailing with attachments under 5MB total. '
            + 'Try uploading less than 5MB worth of attachments.',
          attachmentsLoading: false,
        });
      }
    });
  }

  onEmailsChange(selectedOptions) {
    const { onChange, enableCCField } = this.props;
    if (enableCCField === false) return;

    const ccEmails = _lodash.map(selectedOptions, (o) => o.value).join(',');
    this.setState((prevState) => ({ ...prevState, cc_emails: ccEmails }), () => {
      onChange(this.getEmailState(this.state));
    });
  }

  onFromChange(selectedItem) {
    const { onChange, validateFromDomainAndRecipients } = this.props;
    const from = selectedItem ? selectedItem.value : null;
    this.setState((prevState) => ({ ...prevState, from }), () => {
      onChange(this.getEmailState(this.state));
      if (validateFromDomainAndRecipients) {
        validateFromDomainAndRecipients(from);
      }
    });
  }

  onSubjectChange(e) {
    e.preventDefault();

    const { onChange } = this.props;
    const subject = e.target.value;
    this.setState((prevState) => ({ ...prevState, subject }), () => {
      onChange(this.getEmailState(this.state));
    });
  }

  onBodyChange(html) {
    const { onChange } = this.props;
    this.setState((prevState) => ({ ...prevState, body: html }), () => {
      onChange(this.getEmailState(this.state));
    });
  }

  onAttachmentsChange(attachments) {
    const { onChange } = this.props;
    const attachment_ids = attachments.map((a) => a.id);
    this.setState((prevState) => ({ ...prevState, attachments, attachment_ids }), () => {
      onChange(this.getEmailState(this.state));
    });
  }

  onDismissSignatureNag(e) {
    e.preventDefault();

    localStorage['emailForm.hideSignatureNag'] = 1;
    this.forceUpdate();
  }

  getEmailState(prevState) {
    const {
      from, subject, body, cc_emails, attachment_ids,
    } = prevState;
    return {
      from, subject, body, cc_emails, attachment_ids,
    };
  }

  setSubject(subject) {
    this.setState({ subject });
  }

  validate() {
    const { enableFromField } = this.props;
    const { from, subject, body } = this.state;

    const errors = {};

    if (enableFromField && !from) {
      errors.from = "Can't be empty";
    }

    if (!subject) {
      errors.subject = "Can't be empty";
    }

    if (!body) {
      errors.body = "Can't be empty";
    }
    this.setState({ errors });

    return errors;
  }

  applyTemplate(template) {
    const { onChange } = this.props;
    let email;

    if (template) {
      this.setState({
        subject:        template.title,
        body:           template.body,
        attachments:    template.attachments,
        attachment_ids: _lodash.map(template.attachments, (a) => a.id),
        templateBody:   template.body,
        errors:         {},
      }, () => {
        onChange(this.getEmailState(this.state));
      });
    } else {
      email = {
        subject:        null,
        body:           null,
        attachments:    [],
        templateBody:   null,
        attachment_ids: [],
        errors:         {},
      };
      this.setState({
        subject:        null,
        body:           null,
        attachments:    [],
        templateBody:   null,
        attachment_ids: [],
        errors:         {},
      }, () => {
        onChange(this.getEmailState(this.state));
      });
    }
  }

  renderExtraFeatures() {
    const { cc_emails, showCCEmails } = this.state;
    const { enableCCField } = this.props;

    return (
      <div>
        {showCCEmails && enableCCField && (
          <div className="form-group mb15">
            <a
              href="#cancel"
              className="pull-right"
              onClick={this.handleHideAdditionalEmails}
            >
              Cancel and hide
            </a>
            <label htmlFor="email-address" className="d-block">
              CC
            </label>
            <EmailSelect
              id="email-address"
              name="address"
              placeholder="example@getbrokerkit.com"
              value={cc_emails}
              onChange={this.onEmailsChange}
            />
          </div>
        )}
      </div>
    );
  }

  renderExtraFeatureMenu() {
    const { showCCEmails } = this.state;
    const { enableCCField } = this.props;

    return (
      <div>
        {!showCCEmails && enableCCField && (
          <span className="pull-right mb5">
            <a
              href="#add-emails"
              className=""
              onClick={this.handleShowAdditionalEmails}
            >
              Add CC Emails
            </a>
          </span>
        )}
      </div>
    );
  }

  renderAttachments(attachments) {
    return attachments.map((f) => (
      <div className="alert alert-success p10 text-left mb5" key={f.name}>
        <div className="pull-right">
          <a
            href="#remove-attachment"
            onClick={(e) => {
              e.preventDefault();
              this.onRemoveAttachment(f.id);
            }}
          >
            <FontAwesomeIcon icon={['far', 'fa-times']} />
          </a>
        </div>
        <div className="text-break">
          <FontAwesomeIcon icon={['far', 'fa-file']} className="mr10" />
          {f.name}
        </div>
      </div>
    ));
  }

  render() {
    const {
      from,
      subject,
      body,
      errors,
      templateBody,
      attachmentsLoading,
      attachments,
      attachmentsError,
      enableEmailTemplate,
      linksToInsert,
    } = this.state;
    const { enableFromField, disableSignature, contactOwner } = this.props;

    const { currentUser } = Rails.helpers;
    const { signature } = currentUser;

    const emailContent = body || '';
    const signatureContent = signature || '';

    let emailValue;
    if (!_lodash.isEmpty(emailContent) || disableSignature) {
      emailValue = emailContent;
    } else {
      emailValue = `${emailContent}${signatureContent}`;
    }
    const showSignatureNag = !signature && localStorage['emailForm.hideSignatureNag'] !== 1;

    return (
      <div>
        {enableFromField && (
          <div className="form-group mb15">
            <label htmlFor="email_from" className="d-block">From</label>
            <TeamEmailPicker
              id="email_from"
              name="from"
              className={errors.from ? 'has-error' : ''}
              value={from}
              onChange={this.onFromChange}
              contactOwner={contactOwner}
            />
          </div>
        )}

        {this.renderExtraFeatures()}

        <div className="form-group mb15">
          {this.renderExtraFeatureMenu()}
          <label htmlFor="email_subject" className="d-block">Subject</label>
          <Input
            id="emai_suject"
            type="text"
            name="subject"
            className={errors.subject ? 'has-error' : ''}
            value={subject}
            onChange={this.onSubjectChange}
          />
        </div>

        <div className="form-group mb15">
          <label htmlFor="html-body" className="d-block">Body</label>
          <EmailEditor
            className={errors.body ? 'tiny-mce has-error' : ''}
            value={emailValue}
            onChange={this.onBodyChange}
            setSubject={this.setSubject}
            replaceValue={templateBody}
            clearReplaceValue={() => {
              this.setState({
                templateBody: null,
              });
            }}
            linksToInsert={linksToInsert}
            clearLinksToInsert={() => {
              this.setState({ linksToInsert: [] });
            }}
          />

          {showSignatureNag && (
            <p className="mt10 text-grey">
              <span>Need to add a signature? Add it in </span>
              <a
                href="/users/edit"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black font-weight-bold"
              >
                User Settings
              </a>
              <span> or </span>
              <a
                href="#dismiss"
                className="font-weight-bold"
                onClick={this.onDismissSignatureNag}
              >
                dismiss this
              </a>
              .
            </p>
          )}
        </div>

        <div className="row m0">
          <div className="col-lg-6 p0">
            <div className="form-group m0">
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: 'btn btn-secondary mb5 mr-2' })}>
                    <input {...getInputProps()} />
                    {attachmentsLoading ? (
                      <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                    ) : (
                      <FontAwesomeIcon icon={['far', 'fa-plus']} className="mr5" />
                    )}
                    Add Attachment
                  </div>
                )}
              </Dropzone>
              <InsertDocumentButton
                setLinksToInsert={(linksToInsert) => this.setState({ linksToInsert })}
              />

              {this.renderAttachments(attachments)}

              {attachmentsError && (
                <div className="alert alert-danger">{attachmentsError}</div>
              )}
            </div>
          </div>

          <div className="col-lg-4 p0 pl0-sm-down ml-auto">
            {enableEmailTemplate && (
              <EmailTemplateSelect
                subject={subject}
                body={body}
                attachments={attachments}
                applyTemplate={this.applyTemplate}
                validateEmailTemplate={this.validate}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

EmailForm.defaultProps = {
  errors:                          {},
  from:                            '',
  subject:                         '',
  body:                            '',
  attachments:                     [],
  disableSignature:                false,
  enableFromField:                 false,
  enableCCField:                   false,
  contactOwner:                    false,
  onChange:                        () => false,
  validateFromDomainAndRecipients: () => false,
};

EmailForm.propTypes = {
  errors:                          PropTypes.shape({}),
  from:                            PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  subject:                         PropTypes.string,
  body:                            PropTypes.string,
  attachments:                     PropTypes.arrayOf(PropTypes.shape({})),
  disableSignature:                PropTypes.bool,
  enableFromField:                 PropTypes.bool,
  enableCCField:                   PropTypes.bool,
  contactOwner:                    PropTypes.bool,
  onChange:                        PropTypes.func,
  validateFromDomainAndRecipients: PropTypes.func,
};

export default EmailForm;
