import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorMessage from '~/components/forms/ErrorMessage';

const ErrorMessageWithIcon = ({ message = '', icon = ['fas', 'fa-exclamation-circle'], className = '' }) => {
  const errorText = useMemo(() => (
    <span>
      <FontAwesomeIcon icon={icon} />
      {' '}
      {message}
    </span>
  ), [icon, message]);

  return <div><ErrorMessage message={[errorText]} className={className} /></div>;
};

ErrorMessageWithIcon.defaultProps = {
  message:   [],
  className: '',
  icon:      ['fas', 'fa-exclamation-circle'],
};

ErrorMessageWithIcon.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  icon:      PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default ErrorMessageWithIcon;
