const EmailTemplateActionTypes = {
  LOAD_EMAIL_TEMPLATES:      'LOAD_EMAIL_TEMPLATES',
  LOAD_EMAIL_TEMPLATES_DONE: 'LOAD_EMAIL_TEMPLATES_DONE',
  LOAD_EMAIL_TEMPLATES_FAIL: 'LOAD_EMAIL_TEMPLATES_FAIL',

  LOAD_EMAIL_TEMPLATE:      'LOAD_EMAIL_TEMPLATE',
  LOAD_EMAIL_TEMPLATE_DONE: 'LOAD_EMAIL_TEMPLATE_DONE',
  LOAD_EMAIL_TEMPLATE_FAIL: 'LOAD_EMAIL_TEMPLATE_FAIL',

  CREATE_EMAIL_TEMPLATE:      'CREATE_EMAIL_TEMPLATE',
  CREATE_EMAIL_TEMPLATE_DONE: 'CREATE_EMAIL_TEMPLATE_DONE',
  CREATE_EMAIL_TEMPLATE_FAIL: 'CREATE_EMAIL_TEMPLATE_FAIL',

  UPDATE_EMAIL_TEMPLATE:      'UPDATE_EMAIL_TEMPLATE',
  UPDATE_EMAIL_TEMPLATE_DONE: 'UPDATE_EMAIL_TEMPLATE_DONE',
  UPDATE_EMAIL_TEMPLATE_FAIL: 'UPDATE_EMAIL_TEMPLATE_FAIL',

  DELETE_EMAIL_TEMPLATE:      'DELETE_EMAIL_TEMPLATE',
  DELETE_EMAIL_TEMPLATE_DONE: 'DELETE_EMAIL_TEMPLATE_DONE',
  DELETE_EMAIL_TEMPLATE_FAIL: 'DELETE_EMAIL_TEMPLATE_FAIL',
};

export default EmailTemplateActionTypes;
