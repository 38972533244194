import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import APIRequest from '~/lib/api_request';
import LeadDrawerStore from '~/stores/lead_drawer_store';
import CampaignSubscriptionStore from '~/stores/campaign_subscription_store';
import GlobalContainer from '~/components/global_container';
import TaskStore from '~/stores/task_store';
import Activities from './timeline/Activities';

const tabs = [
  'all',
  'notes',
  'emails',
  'calls',
  'texts',
  'tasks',
  'appointments',
  'changes',
];

const LeadDrawerTimeline = ({ lead, setCurrentFormWithData }) => {
  const [activeTab, setActiveTab] = useState(GlobalContainer.urlParams()?.tab || 'all');
  const [expanded, setExpanded] = useState(false);
  const [hideOlderThan, setHideOlderThan] = useState(new Date());

  const [activities, setActivities] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [errors, setErrors] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [activitiesCount, setActivitiesCount] = useState(0);

  const isMounted = useRef(true);

  const attachStoreListener = useCallback(
    (store, actions, lastActionProperty) => {
      const listener = store.addListener(() => {
        const state = store.getState();

        if (actions.includes(state[lastActionProperty])) {
          fetchLeadActivities(lead.id, activeTab);
        }
      });

      return () => listener.remove();
    },
    [lead.id, activeTab, fetchLeadActivities],
  );

  const toggleActivityInternal = (activityId, newInternalValue) => {
    setActivities((prevActivities) => prevActivities.map((activity) => (activity.id === activityId ? { ...activity, internal: newInternalValue } : activity)));
  };

  const removeActivityFromList = (activityId) => {
    setActivities((prevActivities) => prevActivities.filter((activity) => activity && activity.id !== activityId));
  };

  useEffect(() => attachStoreListener(
    CampaignSubscriptionStore,
    [
      'createCampaignSubscriptionDone',
      'deleteCampaignSubscriptionDone',
    ],
    'lastCampaignSubscriptionStoreAction',
  ), [lead.id, activeTab, attachStoreListener]);

  useEffect(() => attachStoreListener(
    TaskStore,
    ['createTaskDone', 'deleteTaskDone'],
    'lastTaskStoreAction',
  ), [lead.id, activeTab, attachStoreListener]);

  useEffect(
    () => attachStoreListener(
      LeadDrawerStore,
      [
        'updateLeadDone',
        'updateLeadRoleDone',
        'createLeadAppointmentDone',
        'createLeadSmsMessageDone',
        'createLeadEmailDone',
        'createLeadActivityDone',
        'updateLeadActivityDone',
        'cancelScheduledLeadEmailDone',
        'updateLeadEmailDone',
        'updateLeadSmsMessageDone',
        'cancelScheduledLeadSmsMessageDone',
      ],
      'leadDrawerStoreAction',
    ),
    [lead.id, activeTab, attachStoreListener],
  );

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  useEffect(() => {
    const parseHash = () => {
      if (!window.location.hash) return { objectId: null, objectClass: null };

      const [objectId, objectClass] = window.location.hash.substring(1).split('-');
      return { objectId, objectClass };
    };

    const { objectId, objectClass } = parseHash();

    fetchLeadActivities(lead.id, activeTab, 1, 'init', objectId, objectClass);
  }, [lead.id, activeTab, fetchLeadActivities]);

  useEffect(() => {
    setActivities([]);
  }, [lead.id]);

  useEffect(() => {
    if (GlobalContainer.product() === 'retention' && lead.joined_at) {
      setExpanded(true);
      const hideOlderThanDate = Moment(lead.joined_at).add(1, 'second').toDate();
      setHideOlderThan(hideOlderThanDate);
    }
  }, [lead.joined_at]);

  const loadMoreActivities = useCallback((direction) => {
    if (direction === 'init') {
      fetchLeadActivities(lead.id, 'all', 1, 'init');
      return;
    }

    if (!loadingActivities) {
      if (direction === 'next' && nextPage) {
        fetchLeadActivities(lead.id, activeTab, nextPage, 'next');
      } else if (direction === 'prev' && prevPage) {
        fetchLeadActivities(lead.id, activeTab, prevPage, 'prev');
      }
    }
  }, [loadingActivities, nextPage, prevPage, fetchLeadActivities, lead.id, activeTab]);

  const fetchLeadActivities = useCallback((leadID, type, page = 1, direction = 'next', objectID = null, objectClass = null) => {
    setLoadingActivities(true);
    setErrors(null);

    const queryParams = {
      type,
      page,
      ...(objectID && { object_id: objectID }),
      ...(objectClass && { object_class: objectClass }),
      limit: 20,
    };

    const request = APIRequest.get({
      resource: `/v1/leads/${leadID}/activities`,
      data:     queryParams,
    }).end((error, response) => {
      if (!isMounted.current) return;

      if (error) {
        setErrors((prevErrors) => [...prevErrors, error]);
      } else {
        const {
          activities: leadActivities,
          total_activities: totalActivitiesCount,
          pagy,
        } = response.body;

        const newActivities = JSON.parse(leadActivities);

        const newPageState = {
          nextPage: pagy?.current_page < pagy?.last_page ? pagy.current_page + 1 : null,
          prevPage: pagy?.current_page > 1 ? pagy.current_page - 1 : null,
        };

        setActivitiesCount(totalActivitiesCount);
        if (direction === 'init') setActivities([]);
        setActivities((prev) => {
          const activityMap = new Map();
          (prev || []).forEach((activity) => {
            activityMap.set(activity.id, activity);
          });
          (newActivities || []).forEach((activity) => {
            activityMap.set(activity.id, activity);
          });

          return Array.from(activityMap.values());
        });

        setNextPage(newPageState.nextPage);
        setPrevPage(newPageState.prevPage);
      }

      setLoadingActivities(false);
    });

    return () => {
      if (request) request.abort();
    };
  }, []);

  const handleTabSelect = (key, e) => {
    if (activeTab === key) return;

    e.currentTarget.blur();

    setActivities([]);

    setActiveTab(key);
  };

  const productClass = GlobalContainer.product() === 'retention'
    ? 'retention-activities'
    : 'recruiting-activities';

  const memoizedTabs = useMemo(() => (
    <Tabs
      id="activities-tabs"
      activeKey={activeTab}
      onSelect={handleTabSelect}
      variant="pills"
      className={productClass}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab}
          disabled={loadingActivities}
          eventKey={tab}
          title={_lodash.startCase(tab)}
        />
      ))}
    </Tabs>
  ), [activeTab, loadingActivities]);

  return (
    <>
      <h3 className="mb15"> Activity </h3>

      {memoizedTabs}

      <Activities
        loadingActivities={loadingActivities}
        setLoadingActivities={setLoadingActivities}
        activities={activities}
        nextPage={nextPage}
        prevPage={prevPage}
        loadMoreActivities={loadMoreActivities}
        errors={errors}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        hideOlderThan={hideOlderThan}
        expanded={expanded}
        setExpanded={setExpanded}
        activitiesCount={activitiesCount}
        lead={lead}
        setCurrentFormWithData={setCurrentFormWithData}
        toggleActivityInternal={toggleActivityInternal}
        removeActivityFromList={removeActivityFromList}
      />
    </>
  );
};

LeadDrawerTimeline.defaultProps = {
  lead:                   {},
  setCurrentFormWithData: () => {},
};

LeadDrawerTimeline.propTypes = {
  lead: PropTypes.shape({
    joined_at: PropTypes.string,
    id:        PropTypes.number,
  }),
  setCurrentFormWithData: PropTypes.func,
};

export default React.memo(LeadDrawerTimeline);
