import React from 'react';
import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityReassign extends TimelineActivity {}

TimelineActivityReassign.defaultProps = {
  title: (object) => {
    const { parameters: { team_name }, owner } = object.props.activity;

    return `Lead reassigned to this account from "${team_name}" by ${owner.name}`;
  },
  icon:           'redo',
  prefix:         'fas',
  alwaysPublic:   false,
};

export default TimelineActivityReassign;
