import React         from 'react';
import { PropTypes } from 'prop-types';
import Select from './NewSelect';

const TIME_SELECT_OPTIONS = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '1 hour 30 minutes', value: 90 },
  { label: '2 hours', value: 120 },
];

const TimeLengthSelect = ({
  name, value, onChange, ...restProps
}) => (
  <Select
    {...restProps}
    name={name}
    value={value}
    onChange={onChange}
    options={TIME_SELECT_OPTIONS}
  />
);

TimeLengthSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

TimeLengthSelect.propTypes = {
  name:     PropTypes.string,
  value:    PropTypes.number,
  onChange: PropTypes.func,
};

export default TimeLengthSelect;
