import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AgentActions from '~/actions/agent_actions';
import TaskEditor   from '~/components/forms/HtmlEditors/TaskEditor';
import AppModal     from './app_modal';

class EditTaskDescriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting:  false,
      description: '',
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const $modal = $(this.appModal.modal);

    this.setState({ submitting: true }, () => {
      const { description } = this.state;
      const {
        table,
        records,
        searchData,
        bulkSelecting,
        selectedItems,
        unSelectedItems,
        reloadLeads,
      } = this.props;

      let updateParams;
      let selectedTasks = Object.values(selectedItems);
      const unselectedLeads = Object.values(unSelectedItems);

      if (bulkSelecting) {
        selectedTasks = 'all';
        updateParams = { ...searchData, ids: 'all', unselected_ids: _lodash.map(unselectedLeads, 'id') };
      } else {
        updateParams = { ...searchData, ids: _lodash.map(selectedTasks, 'id') };
      }

      AgentActions.bulkUpdateTasks(table, selectedTasks, searchData, { description });
      $modal.modal('hide');
      reloadLeads();
    });
  }

  render() {
    const { errors, submitting } = this.state;

    const saveButtonClass = classNames('btn btn-primary', { disabled: submitting });
    const spinnerImg = <FontAwesomeIcon icon="far fa-spinner" pulse />;

    return (
      <AppModal
        ref={(appModal) => this.appModal = appModal}
        dialogClass="modal-dialog modal-lg"
      >
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Edit Task Description
              {' '}
              {submitting && spinnerImg}
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group mb15">
              <label htmlFor="task-description" className="d-block">Description</label>
              <TaskEditor
                onChange={(html) => this.setState({ description: html })}
                placeholder="Enter Description"
              />
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" className={saveButtonClass} disabled={submitting}>Save</button>
          </div>
        </form>
      </AppModal>
    );
  }
}

EditTaskDescriptionModal.defaultProps = {
  table:              {},
  records:            {},
  selectedItems:      {},
  bulkSelecting:      false,
  searchData:         {},
  unSelectedItems:    {},
};

EditTaskDescriptionModal.propTypes = {
  table:              PropTypes.shape({}),
  records:            PropTypes.shape({}),
  selectedItems:      PropTypes.shape({}),
  bulkSelecting:      PropTypes.bool,
  searchData:         PropTypes.shape({}),
  unSelectedItems:    PropTypes.shape({}),
};

export default EditTaskDescriptionModal;
