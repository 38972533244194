import autoBind from 'react-autobind';
import React    from 'react';

import BlankPage     from '~/components/pages/blank_page';
import FrontSideBar  from '~/components/navigation/front_side_bar';
import ListingLabels from './listing_labels';

class ManageLabels extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    return (
      <BlankPage title="Labels - Brokerkit">
        <div className="row">
          <FrontSideBar />

          <div className="right-side-page-md">
            <ListingLabels />
          </div>
        </div>
      </BlankPage>
    );
  }
}

export default ManageLabels;
