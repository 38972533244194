import React       from 'react';
import PropTypes   from 'prop-types';

import Select      from '~/components/forms/select';
import APIRequest  from '~/lib/api_request';

class LabelSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      options: [],
      value:   props.value && _lodash.map(props.value, (v) => parseInt(v, 10)),
    };
  }

  componentDidMount() {
    const { blank } = this.props;

    APIRequest.get({
      resource: '/v1/labels',
    }).end((error, response) => {
      this.setState({ loading: false }, () => {
        if (!error) {
          let options = response.body.map((label) => ({
            label: `${label.name} (${label.leads_count})`,
            value: label.id,
          }));

          if (blank) options = [{ value: '-blank-', label: '-blank-' }, ...options];

          this.setState({ options });
        }
      });
    });
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : null;
    const { onChange } = this.props;

    this.setState({ value }, () => {
      onChange(selectedOption);
    });
  }

  render() {
    const {
      placeholder, multi, clearable, className,
    } = this.props;

    const { value, loading, options } = this.state;

    return (
      <Select
        className={className}
        clearable={clearable}
        placeholder={placeholder}
        value={value}
        multi={multi}
        onChange={this.handleSelectChange}
        options={options}
        isLoading={loading}
      />
    );
  }
}

LabelSelect.defaultProps = {
  className:   '',
  blank:       false,
  multi:       false,
  onChange:    () => {},
  placeholder: 'Select a Label...',
  style:       {},
  value:       undefined,
};

LabelSelect.propTypes = {
  className:   PropTypes.string,
  blank:       PropTypes.bool,
  multi:       PropTypes.bool,
  onChange:    PropTypes.func,
  placeholder: PropTypes.string,
  style:       PropTypes.shape({}),
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default LabelSelect;
