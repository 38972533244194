import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                   from '~/lib/brokerbit';
import Tooltipable                 from '~/components/effects/tooltipable';
import DateTimePicker              from '~/components/forms/DateTimePicker';
import APIRequest                  from '~/lib/api_request';
import CampaignSubscriptionActions from '~/actions/campaign_subscription_actions';
import { checkFeatureFlag }        from '~/helpers/FeatureFlagChecker';

const CurrentDate = Moment().startOf('day').toDate();

class LeadDrawerCampaignSubscription extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const { subscription } = props;

    this.state = {
      settingStatus:      false,
      subscription:       props.subscription,
      subscriptionErrors: {},
      subscriptionForm:   {
        id: subscription.id,
      },
      unverifiedNumbersWarning: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      subscription: nextProps.subscription,
    });
  }

  handlePlayClick(e) {
    e.preventDefault();
    const { lead } = this.props;
    const { subscription } = this.state;
    const { campaign } = subscription;

    this.validateSmsCampaignStepsWithUnverifiedNumbers(campaign.id).then((validationPassed) => {
      if (!validationPassed) return;

      CampaignSubscriptionActions.runSubscription(lead.id, subscription.id, this.onStatusChange, this.onStatusChangeFailed);

      this.setState({
        settingStatus: true,
      });
    });
  }

  handlePauseClick(e) {
    e.preventDefault();
    const { lead } = this.props;
    const { subscription } = this.state;

    CampaignSubscriptionActions.pauseSubscription(lead.id, subscription.id, this.onStatusChange, this.onStatusChangeFailed);

    this.setState({
      settingStatus: true,
    });
  }

  handleSubscriptionDelete(e) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure to remove the subscription?',
      callback: (ok) => {
        if (ok) {
          const { lead } = this.props;
          const { subscription } = this.state;

          CampaignSubscriptionActions.deleteCampaignSubscription(lead.id, subscription);
        }
      },
    });
  }

  handleSubscriptionSchedule(e) {
    e.preventDefault();

    const { subscriptionForm } = this.state;
    const { lead } = this.props;

    const errors = this.validateSubscriptionSchedule();

    if (_lodash.isEmpty(errors)) {
      CampaignSubscriptionActions.updateCampaignSubscription(lead.id, subscriptionForm);
    }
  }

  handleStartDateChange(selectedDate) {
    const { subscriptionForm } = this.state;
    const startDate = selectedDate.format('YYYY-MM-DD');

    this.setState({
      subscriptionForm: { ...subscriptionForm, start_date: startDate },
    });
  }

  onStatusChange(subscription) {
    this.setState({
      settingStatus: false,
      subscription,
    });
  }

  onStatusChangeFailed(error, response) {
    this.setState({
      settingStatus:      false,
      subscriptionErrors: response.body?.errors,
    });
  }

  validateSmsCampaignStepsWithUnverifiedNumbers = async (campaignId) => {
    const isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

    if (!isRestrictionForUnverifiedNumbersEnabled) return true;

    const defaultState = {
      unverifiedNumbersWarning: false,
      settingStatus:            false,
    };

    this.setState({ settingStatus: true });

    try {
      const response = await APIRequest.get({
        resource: `/v1/campaigns/${campaignId}/check_campaign_for_unverified_numbers`,
      });

      const unferifiedNumbersInSteps = response.body?.unferifiedNumbersInSteps;

      if (unferifiedNumbersInSteps) {
        this.setState({
          unverifiedNumbersWarning: unferifiedNumbersInSteps,
        });

        return false;
      }

      this.setState({ ...defaultState });
      return true;
    } catch (error) {
      this.setState({ ...defaultState });
      return false;
    } finally {
      this.setState({ settingStatus: false });
    }
  };

  validateSubscriptionSchedule() {
    const errors = {};

    const { subscriptionForm } = this.state;

    if (!subscriptionForm.start_date) {
      errors.start_date = "Can't be empty";
    }

    this.setState({ subscriptionErrors: errors });

    return errors;
  }

  renderCount() {
    const { subscription: campaign } = this.state;

    return (
      <div className="pr20">
        <FontAwesomeIcon icon={['far', 'fa-bullhorn']} className="mr5" />
        {' '}
        {campaign.campaign_steps_count}
        {' '}
        emails/texts/tasks
      </div>
    );
  }

  renderProgress() {
    const { subscription } = this.state;
    const { campaign } = subscription;
    const percentage = Math.round((subscription.sent_count / campaign.campaign_steps_count) * 100);

    if (_lodash.isEmpty(subscription.start_date)) {
      return (
        <span>
          <FontAwesomeIcon icon={['far', 'fa-clock']} className="mr5" />
          {' '}
          Not started
        </span>
      );
    }

    return (
      <div>
        <div className="progress" style={{ height: '5px' }}>
          <div
            className="progress-bar bg-success"
            role="progressbar"
            style={{ width: `${percentage}%` }}
            aria-label={`Completed: ${percentage}%`}
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>

        {subscription.sent_count}
        {' '}
        of
        {' '}
        {campaign.campaign_steps_count}
        {' '}
        generated
      </div>
    );
  }

  renderNextStepScheduledDate() {
    const { subscription } = this.state;

    const nextScheduledMoment = Moment(subscription.next_step_scheduled_at);
    const nextScheduledDate = nextScheduledMoment.format('l, h:mm A');
    const timeLeft = nextScheduledMoment.isValid() ? nextScheduledMoment.fromNow() : 0;

    return (
      <div className="text-center">
        <span>
          Next Step Scheduled:
          {' '}
          {nextScheduledDate}
          {' '}
          (
          {timeLeft}
          )
        </span>
      </div>
    );
  }

  renderDuration() {
    const { subscription, subscriptionErrors } = this.state;

    if (_lodash.isEmpty(subscription.start_date)) {
      return (
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 p0">
          <DateTimePicker
            id="campaign_start_date"
            placeholder="Select Start Date"
            format="LL"
            pickerType="date"
            minDate={CurrentDate}
            isInvalid={subscriptionErrors.start_date}
            onChange={this.handleStartDateChange}
          />
        </div>
      );
    }

    if (subscription.status === 'running') {
      const startDate = Moment(subscription.start_date).format('l');
      const endDate = Moment(subscription.end_date).format('l');

      return (
        <>
          <div className="bb">
            <span>
              Start Date:
              {' '}
              {startDate}
            </span>

            <span className="float-right" id={`end-date-${subscription.id}`}>
              End Date:
              {' '}
              {endDate}

              {subscription.show_icon && (
                <>
                  <FontAwesomeIcon icon={['far', 'fa-info-circle']} className="ml5" />
                  <Tooltipable placement="bottom" target={`end-date-${subscription.id}`} text="Note: This campaign has an end date in the past, yet not all steps were completed. This can happen when campaign steps are edited after the campaign was triggered and adding constraints that will not allow the steps to complete. Consider adjusting the campaign steps to ensure full campaign execution." />
                </>
              )}
            </span>
          </div>

          {subscription.campaign.new_engine && (
            this.renderNextStepScheduledDate()
          )}
        </>
      );
    }

    return (
      <span>
        <FontAwesomeIcon icon={['far', 'fa-clock']} className="mr5" />
        {' '}
        {subscription.status}
      </span>
    );
  }

  renderStatusControls() {
    const { subscription, settingStatus } = this.state;

    const buttonClass = classNames('btn btn-secondary btn-sm mr5', { disabled: settingStatus });

    switch (subscription.status) {
      case 'running':
        return (
          <Tooltipable className="pb-1" text="Pauses campaign for this subscriber">
            <button
              type="button"
              className={buttonClass}
              disabled={settingStatus}
              onClick={this.handlePauseClick}
            >
              <FontAwesomeIcon icon={['fas', 'pause']} />
            </button>
          </Tooltipable>
        );
      case 'ended':
        return null; // render nothing if subscription is ended.
      case 'paused':
        return (
          <Tooltipable className="pb-1" text="Runs campaign for this subscriber">
            <button
              type="button"
              className={buttonClass}
              disabled={settingStatus}
              onClick={this.handlePlayClick}
            >
              <FontAwesomeIcon icon={['fas', 'play']} />
            </button>
          </Tooltipable>
        );
      default:
        return null;
    }
  }

  render() {
    const { subscription, unverifiedNumbersWarning } = this.state;
    const { campaign } = subscription;

    return (
      <div className="campaign-subscription mb10">
        <h5>{campaign.name}</h5>

        <div className="fluid-container text-grey-dark bt mt-2 p0">
          <div className="mt-3 row">
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 my-auto p0" id={`progress-bar-${subscription.id}`}>
              {this.renderCount()}
              {this.renderProgress()}

              {subscription.show_icon && (
                <Tooltipable placement="bottom" target={`progress-bar-${subscription.id}`} text="Note: This campaign has an end date in the past, yet not all steps were completed. This can happen when campaign steps are edited after the campaign was triggered and adding constraints that will not allow the steps to complete. Consider adjusting the campaign steps to ensure full campaign execution." />
              )}
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 p0 row mt-xl-0 mt-lg-3 mt-md-3 mt-sm-3 mt-3">
              <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 px-xl-3 px-lg-0 px-md-0 px-sm-0 px-0 my-auto">
                {this.renderDuration()}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 p0 my-xl-auto my-lg-auto my-md-auto my-sm-auto mt-3">
                <span className="float-xl-right float-lg-right float-md-right float-sm-none float-none">
                  {
                    _lodash.isEmpty(subscription.start_date) && (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mr5"
                        onClick={this.handleSubscriptionSchedule}
                      >
                        Schedule
                      </button>
                    )
                  }

                  {subscription.start_date && this.renderStatusControls()}

                  <Tooltipable text="Delete this campaign subscription">
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={this.handleSubscriptionDelete}
                    >
                      <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
                    </button>
                  </Tooltipable>
                </span>
              </div>
            </div>
          </div>

          {unverifiedNumbersWarning && (
            <div className="alert alert-warning text-left">
              Your campaign includes a text step, but the sender&apos;s
              phone number is not linked to an approved verified texting use case.
              To assign this campaign, please select a phone number in the
              text step(s) which are linked to an approved verified texting use case.
            </div>
          )}
        </div>
      </div>
    );
  }
}

LeadDrawerCampaignSubscription.defaultProps = {};

LeadDrawerCampaignSubscription.propTypes = {
  lead:         PropTypes.shape({}).isRequired,
  subscription: PropTypes.shape({}).isRequired,
};

export default LeadDrawerCampaignSubscription;
