import autoBind         from 'react-autobind';
import React            from 'react';
import { PropTypes }    from 'prop-types';
import { Input }        from 'reactstrap';
import update           from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

import TaskCategorySelect   from '~/components/forms/task_category_select';
import TaskPrioritySelect   from '~/components/forms/task_priority_select';
import TaskStatusSelect     from '~/components/forms/task_status_select';
import TaskEditor           from '~/components/forms/HtmlEditors/TaskEditor';
import { TeammatePicker }   from '~/components/forms/team_member_picker';
import ErrorMessageWithIcon from '~/components/forms/ErrorMessageWithIcon';
import InsertDocumentButton from '~/components/insert_document_button';

class StepTaskForm extends React.Component {
  constructor(props) {
    super(props);

    const { step } = props;

    this.state = {
      task: {
        task_name:                    step.task_name     || 'Follow Up',
        task_category:                step.task_category || 'follow_up',
        body:                         step.body          || '',
        task_due_days:                step.task_due_days || 0,
        task_owner:                   step.task_owner    || Rails.helpers.currentUser.id,
        task_priority:                step.task_priority || 'normal',
        task_status:                  step.task_status   || 'to_do',
        notification_task_assignment: step.notification_task_assignment,
      },
      errors:           {},
      enableOwnerField: props.type === 'Campaign',
    };

    autoBind(this);
  }

  setTaskField(name, val) {
    const { onChange } = this.props;
    const newState = update(this.state, {
      task: {
        [name]: { $set: val },
      },
    });

    this.setState(newState);

    onChange(newState.task);
  }

  validate() {
    const { task } = this.state;
    const errors = {};

    if (!task.task_name) {
      errors.task_name = "Can't be empty";
    }

    if (!task.task_category) {
      errors.task_category = "Can't be empty";
    }

    if (!task.task_priority) {
      errors.task_priority = "Can't be empty";
    }

    if (task.task_due_days.length === 0) {
      errors.task_due_days = "Can't be empty";
    }

    if (!task.task_status) {
      errors.task_status = "Can't be empty";
    }

    if (!task.task_owner) {
      errors.task_owner = "Can't be empty";
    }

    this.setState({ errors });

    return errors;
  }

  render() {
    const {
      task, loading, errors, enableOwnerField, linksToInsert,
    } = this.state;
    const uuid = uuidv4();
    const classWhenOwnerFieldEnabled = enableOwnerField ? 'col-md-4' : 'col-md-6';

    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor={`task_name_${uuid}`}>Task Name</label>
            <Input
              id={`task_name_${uuid}`}
              name="task_name"
              placeholder="Enter Name"
              value={task.task_name}
              className={errors.task_name ? 'has-error' : ''}
              onChange={(val) => this.setTaskField('task_name', val && val.target.value)}
            />
            {errors.task_name && (
            <ErrorMessageWithIcon message={[errors.task_name]} />
            )}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor={`task_category_${uuid}`}>Task Category</label>
            <TaskCategorySelect
              id={`task_category_${uuid}`}
              name="task_category"
              placeholder="Select Category"
              value={task.task_category}
              className={errors.task_category ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('task_category', opt && opt.value)}
            />
            {errors.task_category && (
              <ErrorMessageWithIcon message={[errors.task_category]} />
            )}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor={`task_due_days_${uuid}`}>Task Due Days</label>
            <Input
              type="number"
              id={`task_due_days_${uuid}`}
              autoComplete="off"
              min={0}
              step={1}
              name="task_due_days"
              placeholder="Enter Task Due Days"
              value={task.task_due_days}
              className={errors.task_due_days ? 'has-error' : ''}
              onChange={(val) => this.setTaskField('task_due_days', val && val.target.value)}
            />
            {errors.task_due_days && (
              <ErrorMessageWithIcon message={[errors.task_due_days]} />
            )}
          </div>
        </div>

        <div className="form-group mb15">
          <label htmlFor="task-descrption" className="d-block">Description</label>
          <TaskEditor
            onChange={(html) => this.setTaskField('body', html)}
            placeholder="Enter Description"
            value={task.body}
            isCampaingStep
            linksToInsert={linksToInsert}
            clearLinksToInsert={() => {
              this.setState({ linksToInsert: [] });
            }}
          />
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <InsertDocumentButton
              setLinksToInsert={(linksToInsert) => this.setState({ linksToInsert })}
            />
          </div>
        </div>

        <div className="form-row">
          {enableOwnerField && (
            <div className="form-group col-md-4">
              <label htmlFor={`task_owner_${uuid}`} className="form-control-label">Owner</label>
              <TeammatePicker
                id={`task_owner_${uuid}`}
                name="task_owner"
                placeholder="Select Owner"
                value={task.task_owner}
                onChange={(opt) => this.setTaskField('task_owner', opt && opt.value)}
                className={errors.task_owner ? 'has-error' : ''}
                clearable
                contactOwner
              />
              {errors.task_owner && (
                <ErrorMessageWithIcon message={[errors.task_owner]} />
              )}
            </div>
          )}

          <div className={`form-group ${classWhenOwnerFieldEnabled}`}>
            <label htmlFor={`task_priority_${uuid}`}>Priority</label>
            <TaskPrioritySelect
              id={`task_priority_${uuid}`}
              name="task_priority"
              placeholder="Select Priority"
              value={task.task_priority}
              className={errors.task_priority ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('task_priority', opt && opt.value)}
            />
            {errors.task_priority && (
              <ErrorMessageWithIcon message={[errors.task_priority]} />
            )}
          </div>

          <div className={`form-group ${classWhenOwnerFieldEnabled}`}>
            <label htmlFor={`task_status_${uuid}`}>Status</label>
            <TaskStatusSelect
              id={`task_status_${uuid}`}
              name="task_status"
              placeholder="Select Status"
              value={task.task_status}
              className={errors.task_status ? 'has-error' : ''}
              onChange={(opt) => this.setTaskField('task_status', opt && opt.value)}
            />
            {errors.task_status && (
              <ErrorMessageWithIcon message={[errors.task_status]} />
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={`task_notification_emails_${uuid}`}
                onChange={(e) => this.setTaskField('notification_task_assignment', e && e.target.checked)}
                checked={task.notification_task_assignment}
              />
              <label
                className="form-check-label"
                htmlFor={`task_notification_emails_${uuid}`}
              >
                Disable Task Notification Emails
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StepTaskForm.defaultProps = {
  step:     {},
  onChange: () => false,
};

StepTaskForm.propTypes = {
  step:     PropTypes.shape({}),
  onChange: PropTypes.func,
  type:     PropTypes.string.isRequired,
};

export default StepTaskForm;
