import AppDispatcher           from '~/dispatchers/app_dispatcher';
import AgentActionTypes        from '~/types/agent_action_types';
import AgentStore              from '~/stores/agent_store';
import DocumentStore           from '~/stores/document_store';
import tableConfig             from '~/components/pages/agent_dashboard/table_config';
import APIRequest              from '~/lib/api_request';
import AppointmentActions      from '~/actions/appointment_actions';
import { getRestrictionScope } from '~/helpers/staffRestrictionHelper';

const leadTableRequest = {};
let leadBulkUpdateRequest;
let leadBulkDeleteLeadsRequest;
let bulkDeleteTasksRequest;
let bulkUpdateTasksRequest;

/**
 * Agent Dashboard action object.
 * @type {Object}
 */
const AgentActions = {
  defaultOptions() {
    const staffRestriction = getRestrictionScope();

    return {
      ownerScope: localStorage.ownerScope || staffRestriction || 'all',
      page:       1,
      size:       parseInt(localStorage.leadScopeSize, 10) || 10,
      searchData: {},
    };
  },

  resetStore() {
    AppDispatcher.dispatch({
      type: AgentActionTypes.RESET_STORE,
    });
  },

  loadLeadsQuery(table = tableConfig.todayTable(), options = {}) {
    const opts = Object.assign(this.defaultOptions(), options);

    const query = {
      lead_scope: table.scope,
      scope:      opts.ownerScope,
      page:       opts.page,
      per:        opts.size,
      s:          opts.searchData,
    };

    const { sortColumn, sortDirection } = opts;
    if (sortColumn && sortDirection) {
      query.lead_sort = `${sortColumn}-${sortDirection}`;
    }

    return query;
  },

  /**
   * Load Lead table.
   * @param {Object} table - Lead table to change
   * @param {Object} options - Supported options: ownerScope, page, size, sortColumn, sortDirection, searchData.
   */
  loadLeads(table = tableConfig.todayTable(), options = {}) {
    const query = AgentActions.loadLeadsQuery(table, options);

    let leadTableScopeRequest = leadTableRequest[table.scope];

    AppDispatcher.dispatch({
      type: AgentActionTypes.LOAD_LEADS,
      table,
    });

    APIRequest.abort(leadTableScopeRequest);

    leadTableScopeRequest = APIRequest.get({
      resource: table.resource,
      data:     query,
    });

    leadTableScopeRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: AgentActionTypes.LOAD_LEADS_FAIL,
          table,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     AgentActionTypes.LOAD_LEADS_DONE,
        table,
        response: response.body,
        query,
      });
    });

    leadTableRequest[table.scope] = leadTableScopeRequest;
  },

  /**
   * Change leads owner filtering scope.
   * @param {Object} widgets - List of app supported widgets
   * @param {String} ownerScope - Supports 'all' or 'mine' value.
   */
  switchOwnerScope(widgets, ownerScope) {
    const storeState = AgentStore.getState();
    const documentStoreState = DocumentStore.getState();

    AppDispatcher.dispatch({
      type: AgentActionTypes.SWITCH_OWNER_SCOPE,
      ownerScope,
    });
    localStorage.ownerScope = ownerScope;

    widgets.tables.forEach((table) => {
      let size = storeState.leadScopeSize;
      if (table.scope.includes('documents')) {
        size = documentStoreState.documentScopeSize;
      }
      this.loadLeads(table, {
        ownerScope,
        page: 1,
        size,
      });
    });

    AppointmentActions.loadAppointments({ ownerScope });
  },

  /**
   * Change leads pagination size.
   * @param {Object} widgets - List of app supported widgets
   * @param {Integer} leadScopeSize
   * @param {Object} searchData - Current app search data object from URL params
   */
  switchLeadScopeSize(widgets, leadScopeSize, searchData) {
    const storeState = AgentStore.getState();

    AppDispatcher.dispatch({
      type: AgentActionTypes.SWITCH_LEAD_SCOPE_SIZE,
      leadScopeSize,
    });
    localStorage.leadScopeSize = leadScopeSize;

    widgets.tables.forEach((table) => {
      this.loadLeads(table, {
        searchData,
        ownerScope: storeState.ownerScope,
        page:       1,
        size:       leadScopeSize,
      });
    });
  },

  /**
   * Bulk updating leads
   * @param {Object} table - Lead info table
   * @param {Object[]|string} leads - The leads going to be updated, support array or 'all' string
   * @param {Object} searchData - Extra search params/filters, e.g. production: ['Mega']
   * @param {Object} bulkUpdateData - accept Lead fields for bulk update: archived, ...
   */
  bulkUpdateLeads(table, leads, searchData = {}, bulkUpdateData) {
    APIRequest.abort(leadBulkUpdateRequest);

    const staffRestriction = getRestrictionScope();

    AppDispatcher.dispatch({
      type:       AgentActionTypes.BULK_UPDATE_LEADS,
      tableScope: table.scope,
      leads,
      bulkUpdateData,
    });

    const updateData = {
      lead_scope: table.scope,
      scope:      localStorage.ownerScope || staffRestriction || 'all',
    };

    switch (typeof leads) {
      case 'string':
        updateData.ids = 'all';
        break;
      default:
        updateData.ids = _lodash.map(leads, 'id');
    }

    let data = { ...updateData, ...bulkUpdateData };
    if (searchData) data = { ...data, ...searchData };

    APIRequest.put({
      resource: '/v1/lead_bulk_actions',
      data,
    }).end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_UPDATE_LEADS_FAIL,
          tableScope: table.scope,
          error,
        });
      } else {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_UPDATE_LEADS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk action in progress, this may take some time.',
          'success',
        );
      }
    });
  },

  /**
   * Bulk updating tasks
   * @param {Object} table - Task info table
   * @param {Object[]|string} tasks - The tasks going to be updated, support array or 'all' string
   * @param {Object} searchData - Extra search params/filters
   * @param {Object} bulkUpdateData - accept Task fields for bulk update
   */
  bulkUpdateTasks(table, tasks, searchData = {}, bulkUpdateData) {
    APIRequest.abort(bulkUpdateTasksRequest);

    const staffRestriction = getRestrictionScope();

    AppDispatcher.dispatch({
      type:       AgentActionTypes.BULK_UPDATE_TASKS,
      tableScope: table.scope,
      tasks,
      bulkUpdateData,
    });

    const updateData = {
      lead_scope: table.scope,
      scope:      localStorage.ownerScope || staffRestriction || 'all',
    };

    switch (typeof tasks) {
      case 'string':
        updateData.ids = 'all';
        break;
      default:
        updateData.ids = _lodash.map(tasks, 'id');
    }

    let data = { ...updateData, ...bulkUpdateData };
    if (searchData) data = { ...data, ...searchData };

    APIRequest.put({
      resource: '/v1/task_bulk_actions',
      data,
    }).end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_UPDATE_TASKS_FAIL,
          tableScope: table.scope,
          error,
        });
      } else {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_UPDATE_TASKS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk action in progress, this may take some time.',
          'success',
        );
      }
    });
  },

  bulkDeleteLeads(table, leads, searchData = {}, bulkDeleteData) {
    APIRequest.abort(leadBulkDeleteLeadsRequest);

    const staffRestriction = getRestrictionScope();

    AppDispatcher.dispatch({
      type:       AgentActionTypes.BULK_DELETE_LEADS,
      tableScope: table.scope,
      leads,
      bulkDeleteData,
    });

    const deleteData = {
      lead_scope: table.scope,
      scope:      staffRestriction || localStorage.ownerScope || 'all',
    };

    switch (typeof leads) {
      case 'string':
        deleteData.ids = 'all';
        break;
      default:
        deleteData.ids = _lodash.map(leads, 'id');
    }

    let data = { ...deleteData, ...bulkDeleteData };
    if (searchData) data = { ...data, ...searchData };

    APIRequest.delete({
      resource: '/v1/lead_bulk_actions/delete_leads',
      data,
    }).end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_DELETE_LEADS_FAIL,
          tableScope: table.scope,
          error,
        });
      } else {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_DELETE_LEADS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk delete in progress, this may take some time.',
          'success',
        );
      }
    });
  },

  bulkDeleteTasks(table, tasks, searchData = {}, bulkDeleteData) {
    APIRequest.abort(bulkDeleteTasksRequest);

    const staffRestriction = getRestrictionScope();

    AppDispatcher.dispatch({
      type:       AgentActionTypes.BULK_DELETE_TASKS,
      tableScope: table.scope,
      tasks,
      bulkDeleteData,
    });

    const deleteData = {
      lead_scope: table.scope,
      scope:      localStorage.ownerScope || staffRestriction || 'all',
    };

    switch (typeof tasks) {
      case 'string':
        deleteData.ids = 'all';
        break;
      default:
        deleteData.ids = _lodash.map(tasks, 'id');
    }

    let data = { ...deleteData, ...bulkDeleteData };
    if (searchData) data = { ...data, ...searchData };

    APIRequest.delete({
      resource: '/v1/task_bulk_actions/delete_tasks',
      data,
    }).end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_DELETE_TASKS_FAIL,
          tableScope: table.scope,
          error,
        });
      } else {
        AppDispatcher.dispatch({
          type:       AgentActionTypes.BULK_DELETE_TASKS_DONE,
          tableScope: table.scope,
        });

        GlobalContainer.notify(
          'Bulk delete in progress, this may take some time.',
          'success',
        );
      }
    });
  },

  reloadLeadTables() {
    window.dispatchEvent(new Event('agent.reload'));
  },
};

export default AgentActions;
