import AppDispatcher     from '~/dispatchers/app_dispatcher';
import MlsServiceRequest from '~/lib/mls_service_request';
import MlsActionTypes    from '~/types/mls_action_types';

let loadMlsOptionsRequest;
let loadTeamMlsesOptionsRequest;
let loadActivatedTeamMlsesOptionsRequest;

const MlsActions = {
  loadMlsOptions() {
    MlsServiceRequest.abort(loadMlsOptionsRequest);

    AppDispatcher.dispatch({
      type: MlsActionTypes.LOAD_MLS_OPTIONS,
    });

    loadMlsOptionsRequest = MlsServiceRequest.get({
      resource: '/mlses',
    });

    loadMlsOptionsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: MlsActionTypes.LOAD_MLS_OPTIONS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    MlsActionTypes.LOAD_MLS_OPTIONS_DONE,
        options: response.body,
      });
    });
  },

  loadTeamMlsesOptions() {
    MlsServiceRequest.abort(loadTeamMlsesOptionsRequest);

    AppDispatcher.dispatch({
      type: MlsActionTypes.LOAD_TEAM_MLSES_OPTIONS,
    });

    loadTeamMlsesOptionsRequest = MlsServiceRequest.get({
      resource: '/team_mlses/options',
    });

    loadTeamMlsesOptionsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: MlsActionTypes.LOAD_TEAM_MLSES_OPTIONS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    MlsActionTypes.LOAD_TEAM_MLSES_OPTIONS_DONE,
        options: response.body,
      });
    });
  },

  loadActivatedTeamMlsesOptions() {
    MlsServiceRequest.abort(loadActivatedTeamMlsesOptionsRequest);

    AppDispatcher.dispatch({
      type: MlsActionTypes.LOAD_ACTIVATED_TEAM_MLSES_OPTIONS,
    });

    loadActivatedTeamMlsesOptionsRequest = MlsServiceRequest.get({
      resource: '/team_mlses/activated_options',
    });

    loadActivatedTeamMlsesOptionsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: MlsActionTypes.LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:    MlsActionTypes.LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_DONE,
        options: response.body,
      });
    });
  },
};

export default MlsActions;
