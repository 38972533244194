import autoBind  from 'react-autobind';
import React     from 'react';
import PropTypes from 'prop-types';

import Select from '~/components/forms/select';

export const months = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'];

class SimpleMonthPicker extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  loadOptions() {
    this.options = [];

    for (let m = 0; m < months.length; m += 1) {
      this.options.push({ value: m + 1, label: months[m] });
    }

    return this.options;
  }

  render() {
    return (
      <Select
        {...this.props}
        value={this.props.value}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        options={this.loadOptions()}
      />
    );
  }
}

SimpleMonthPicker.defaultProps = {
  name:        '',
  value:       '',
  placeholder: 'Select a month',
  onChange:    () => false,
};

SimpleMonthPicker.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  placeholder: PropTypes.string,
  onChange:    PropTypes.func,
};

export default SimpleMonthPicker;
