import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                   from '~/lib/brokerbit';
import LeadHelpers                 from '~/helpers/lead_helpers';
import TeamMembersActions          from '~/actions/team_members_actions';
import TeamMemberInviteButton      from '~/components/pages/account/team_members/TeamMemberInviteButton';
import { LeadBucketAndRoleSelect } from '~/components/forms/lead_fields';

class TeamMemberItem extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onEditMemberClick(e) {
    e.preventDefault();

    const { teamMember, notify, drawerExternalRolesOnly } = this.props;
    const {
      selectedTab, searchQuery, currentPage, helpers,
    } = this.context;

    helpers.openTeamMemberDrawer({
      teamMember,
      memberRole:        selectedTab,
      searchQuery,
      currentPage,
      notify,
      externalRolesOnly: drawerExternalRolesOnly,
    });
  }

  onRemoveMemberClick(e) {
    e.preventDefault();

    const { teamMember } = this.props;
    const { selectedTab, searchQuery, currentPage } = this.context;

    let title;
    let message;
    let size;

    if (selectedTab === 'admins' || selectedTab === 'staff') {
      size = 'large';
      title = `Are you sure you want to remove ${teamMember.name}?`;
      message = '<div>Note: <br/> <ul><li>Removing this user will remove their system access and they will be moved to Archive in Retention.</li><li>Please review all campaigns to determine if this user is set as a "Sender" and change the sender to a different user to avoid messages being sent later as this user.</li></ul></div>';
    } else {
      size = 'small';
      title = '';
      message = `Are you sure you want to remove ${teamMember.name}?`;
    }

    brokerbit.confirmBox({
      title,
      message,
      size,
      callback: (ok) => {
        if (ok) TeamMembersActions.deleteTeamMember(teamMember, selectedTab, searchQuery, currentPage);
      },
    });
  }

  onSelectChange(opt) {
    const { teamMember } = this.props;
    const { selectedTab, searchQuery, currentPage } = this.context;

    switch (opt.value) {
      case 'recruiting':
        brokerbit.confirmBox({
          message:  `Are you sure you want to send ${teamMember.name} back to Recruiting and remove their access to Brokerkit?`,
          callback: (ok) => {
            if (ok) {
              TeamMembersActions.moveTeamMember(teamMember, 'recruiting', selectedTab, searchQuery, currentPage);
            }
          },
        });
        break;
      case 'agent':
        brokerbit.confirmBox({
          message:  `Are you sure you want to convert ${teamMember.name} to an Agent?`,
          callback: (ok) => {
            if (ok) {
              TeamMembersActions.moveTeamMember(teamMember, 'agent', selectedTab, searchQuery, currentPage);
            }
          },
        });
        break;
      case 'affiliate':
        brokerbit.confirmBox({
          message:  `Are you sure you want to convert ${teamMember.name} to an Affiliate?`,
          callback: (ok) => {
            if (ok) {
              TeamMembersActions.moveTeamMember(teamMember, 'affiliate', selectedTab, searchQuery, currentPage);
            }
          },
        });
        break;
      case 'archived':
        brokerbit.confirmBox({
          message:  `Are you sure you'd like to move ${teamMember.name} to Archive and remove their access to Brokerkit?`,
          callback: (ok) => {
            if (ok) {
              TeamMembersActions.moveTeamMember(teamMember, 'archived', selectedTab, searchQuery, currentPage);
            }
          },
        });
        break;
      default:
        break;
    }
  }

  renderRoles() {
    const { teamMember } = this.props;
    const { name } = teamMember.role;

    let label = name.replace('_', ' ').toUpperCase();

    let className;
    switch (name) {
      case 'admin':
        className = 'badge badge-outline-green';
        break;
      case 'staff':
        className = 'badge badge-outline-yellow';
        label     = 'STAFF';
        break;
      case 'agent':
        className = 'badge badge-outline-blue';
        break;
      default:
        className = 'badge badge-outline-grey';
        break;
    }

    return <span key={`role-${teamMember.role.id}`} className={classNames(className, 'mr-1')}>{label}</span>;
  }

  render() {
    const { mode } = this.context;
    const { teamMember } = this.props;

    if (mode === 'onboarding') {
      return (
        <tr>
          <td width="15%" className="d-none d-sm-table-cell" onClick={this.onEditMemberClick}>{this.renderRoles()}</td>
          <td width="50%" onClick={this.onEditMemberClick}>
            <span className="mr-2">{LeadHelpers.renderUserAvatar(teamMember)}</span>
            {teamMember.name}
          </td>
          <td width="20%" className="d-none d-sm-table-cell" onClick={this.onEditMemberClick}>{teamMember.email}</td>
        </tr>
      );
    }
    const showRetentionSelect = ['agent', 'affiliate'].includes(teamMember.role.name);

    return (
      <tr>
        <td width="15%" className="d-none d-sm-table-cell" onClick={this.onEditMemberClick}>{this.renderRoles()}</td>
        <td width="50%" onClick={this.onEditMemberClick}>
          <span className="mr-2">{LeadHelpers.renderUserAvatar(teamMember)}</span>
          {teamMember.name}
        </td>
        <td width="20%" className="d-none d-sm-table-cell" onClick={teamMember.role.joined_at && this.onEditMemberClick}>
          {teamMember.role.joined_at && (
            <span>
              Joined
              {' '}
              {Moment(teamMember.role.joined_at).format('MMM Do YYYY')}
            </span>
          )}
          {!teamMember.role.joined_at && (
            teamMember.role.invited_at ? (
              <TeamMemberInviteButton text="Resend Invite" memberId={teamMember.id} />
            ) : (
              <TeamMemberInviteButton text="Send Invite" memberId={teamMember.id} />
            )
          )}
        </td>
        <td width="5%" className="text-right">
          { showRetentionSelect ? (
            <LeadBucketAndRoleSelect
              role={teamMember.role}
              className="text-left"
              style={{ minWidth: '140px' }}
              onChange={this.onSelectChange}
            />
          ) : (
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.onRemoveMemberClick}>
              <FontAwesomeIcon icon={['far', 'fa-times']} />
            </button>
          )}
        </td>
      </tr>
    );
  }
}

TeamMemberItem.defaultProps = {
  teamMember:              null,
  mode:                    'normal',
  notify:                  false,
  drawerExternalRolesOnly: false,
};

TeamMemberItem.propTypes = {
  teamMember:              PropTypes.shape({}),
  notify:                  PropTypes.bool,
  drawerExternalRolesOnly: PropTypes.bool,
  mode:                    PropTypes.string,
};

TeamMemberItem.contextTypes = {
  selectedTab: PropTypes.string,
  searchQuery: PropTypes.string,
  currentPage: PropTypes.number,
  mode:        PropTypes.string,
  helpers:     PropTypes.shape({}),
};

export default TeamMemberItem;
