const BusinessProfilesActionTypes = {
  CREATE_BUSINESS_PROFILE:      'CREATE_BUSINESS_PROFILE',
  CREATE_BUSINESS_PROFILE_DONE: 'CREATE_BUSINESS_PROFILE_DONE',
  CREATE_BUSINESS_PROFILE_FAIL: 'CREATE_BUSINESS_PROFILE_FAIL',

  UPDATE_BUSINESS_PROFILE:      'UPDATE_BUSINESS_PROFILE',
  UPDATE_BUSINESS_PROFILE_DONE: 'UPDATE_BUSINESS_PROFILE_DONE',
  UPDATE_BUSINESS_PROFILE_FAIL: 'UPDATE_BUSINESS_PROFILE_FAIL',
};

export default BusinessProfilesActionTypes;
