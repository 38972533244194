import React               from 'react';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivitySmsConversation extends TimelineActivity {
  componentDidMount() {
    /* initialize stack of activity listeners if not initialized yet */
    if (!window.onSmsMessageReceived) window.onSmsMessageReceived = {};

    /* add listener to stack of message listeners */
    window.onSmsMessageReceived[this.state.activity.id] = this.handleUpdateMessage;
  }

  componentWillUnmount() {
    /* remove listener from stack of message listeners */
    delete window.onSmsMessageReceived[this.state.activity.id];
  }

  handleUpdateMessage = (data) => {
    if (this.state.activity.id !== data.message.activity_id) return;

    /* check if this a new message or status update */
    const isIncluded = this.state.activity.sms_messages.map((m) => m.id).includes(data.message.id);

    if (isIncluded) {
      this.setState((prevState) => ({
        activity: {
          ...prevState.activity,
          sms_messages: prevState.activity.sms_messages.map((message) => {
            if (data.message.id === message.id) return { ...message, status: data.message.status };
            return message;
          }),
        },
      }));
    } else {
      this.setState((prevState) => ({
        activity: {
          ...prevState.activity,
          sms_messages: [data.message, ...prevState.activity.sms_messages],
        },
      }));
    }
  }

  render() {
    const { activity } = this.state;

    if (!activity.sms_messages) {
      return null;
    }

    return (
      <div className="timeline-activity">
        <span className="icon fa-stack fa-lg">
          <FontAwesomeIcon icon={['fas', 'fa-circle']} className="fa-stack-2x text-yellow" />
          <FontAwesomeIcon icon={['fas', 'fa-comment']} inverse className="fa-stack-1x" />
        </span>
        <div className="body pl-1">
          <h5 className="text-grey-dark mb5">Text Message Conversation</h5>
          <p className="text-grey mb15">
            Started on
            {' '}
            {Moment(activity.created_at).format('LL')}
          </p>
        </div>
        <div className="chat">
          {activity.sms_messages.map((m) => (
            <div id={`${m.id}-SmsMessage`} className={classNames('message', m.direction === 'outbound' ? 'right' : '')} key={`timeline-sms-${m.id}`}>
              <div className="bubble">
                {m.media_url && (
                  <img src={m.media_url} alt="Avatar" style={{ maxWidth: '100%' }} />
                )}
                <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
                  {m.body}
                </p>
                <div className="corner" />
              </div>
              <div className="clearfix" />
              {m.status && (
                <span>
                  <small className="text-grey">{m.status === 'sent' ? 'pending' : m.status}</small>
                  <br />
                </span>
              )}
              <small className="text-grey">
                {Moment(m.created_at).fromNow()}
                {' '}
                via
                {' '}
                {m.source}
              </small>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

TimelineActivitySmsConversation.defaultProps = {
  editable:       false,
  deletable:      false,
  title:          'Text Message Conversation',
  icon:           'comment',
  prefix:         'fas',
  color:          'text-yellow',
  alwaysPublic:   false,
};

export default TimelineActivitySmsConversation;
