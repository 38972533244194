import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class ProductionInsightsSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const options = [
      { value: 'rookie_rating', label: 'Rookie Rating' },
      { value: 'current_switch_risk', label: 'Current Switch Risk' },
      { value: 'effectiveness_grade', label: 'Listing Effectiveness Grade' },
      { value: 'list_eff_impact', label: 'Listing Effectiveness Impact' },
      { value: 'five_yr_closed_pct', label: 'Five Year Batting Average' },
      { value: 'five_yr_tot_listings', label: 'Five Year Listing Count' },
    ];

    return (
      <Select
        {...this.props}
        onChange={this.props.onChange}
        options={options}
      />
    );
  }
}

ProductionInsightsSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

ProductionInsightsSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default ProductionInsightsSelect;
