import React         from 'react';
import classNames    from 'classnames';
import autoBind      from 'react-autobind';
import PhoneInput    from 'react-phone-input-2';
import { apiV2Instance } from '~/lib/api_request';

import Select      from '~/components/forms/select';
import LeadHelpers from '~/helpers/lead_helpers';

let USER_LICENSES  = [];
let USER_INTERESTS = [];

class LeadRecruitingApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sending:  false,
      status:   '',
      response: null,
      user:     {
        first_name:       '',
        last_name:        '',
        email:            '',
        phone:            '',
        production_label: '',
        opt_in:           false,
      },
      interest: '',
    };

    const { team } = props;

    USER_LICENSES = [
      { value: 'new', label: 'Yes, I have a license' },
      { value: 'not_licensed', label: 'No, I do not have a license' },
      { value: 'in_school', label: 'I am studying for my license' },
    ];

    USER_INTERESTS = [
      {
        value: 'learning',
        label: `Learning more about ${team.name}`,
      },
      {
        value: 'starting',
        label: 'Starting a career in Real Estate',
      },
      { value: 'joining', label: `Joining ${team.name}` },
      {
        value: 'meeting',
        label: 'Setting up a confidential meeting',
      },
    ];

    autoBind(this);
  }

  handleFirstNameChange(e) {
    e.preventDefault();

    const first_name = e.target.value;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        first_name,
      },
    }));
  }

  handleLastNameChange(e) {
    e.preventDefault();

    const last_name = e.target.value;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        last_name,
      },
    }));
  }

  handleEmailChange(e) {
    e.preventDefault();

    const email = e.target.value;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        email,
      },
    }));
  }

  handlePhoneChange(phone) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        phone,
      },
    }));
  }

  handleLicenseChange(selectedItem) {
    const { value } = selectedItem;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        production_label: value,
      },
    }));
  }

  handleInterestChange(selectedItem) {
    this.setState({
      interest: selectedItem.value,
    });
  }

  handleMarketingOptInChange(e) {
    const isChecked = e.target.checked;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        opt_in: isChecked,
      },
    }));
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { user } = this.state;
    const { team, agentId, thank_you_url } = this.props;

    this.setState({
      sending: true,
    });

    user.note = this.buildLeadNote();
    user.source = 'Landing Page';
    if (agentId) user.referrer_id = agentId;

    const config = {
      headers: { Authorization: `Token token=${team.apiKey}` },
    };

    apiV2Instance
      .post('/leads', { ...user }, config)
      .then((response) => {
        this.setState({
          response,
        });

        window.location = thank_you_url;
      })
      .catch((error) => {
        let response;

        if (error.response) {
          response = error.response.data;
        } else {
          response = {
            serverError: [
              "Our site admins have been notified and we'll have this fixed shortly.",
            ],
          };
        }

        this.setState({
          status:  'fail',
          sending: false,
          response,
        });
      });
  }

  buildLeadNote() {
    const { interest } = this.state;

    let note = '';

    USER_INTERESTS.forEach((item) => {
      if (item.value === interest) {
        note += `- Interest: ${item.label} \n`;
      }
    });

    return note;
  }

  renderErrors(response) {
    const errorsContent = [];
    _lodash.forEach(response, (v, k) => {
      errorsContent.push(
        <p key={`${k}-error`}>
          {_lodash.startCase(k)}
          {' - '}
          {v.join(', ')}
        </p>,
      );
    });

    return errorsContent;
  }

  render() {
    const {
      user, interest, sending, status, response,
    } = this.state;
    const {
      team, thank_you, url, color,
    } = this.props;

    const style = { backgroundColor: color, borderColor: color };
    const submitButtonClass = classNames(
      'btn btn-primary btn-block btn-lg',
      { disabled: sending },
    );

    let formContent = (
      <form onSubmit={this.handleFormSubmit}>
        {status === 'fail' && (
          <div className="alert alert-danger text-left">
            {this.renderErrors(response)}
          </div>
        )}

        <div className="form-group">
          <label htmlFor="user_first_name" className="label">
            First name
          </label>
          <input
            type="text"
            className="form-control"
            id="user_first_name"
            ref={(input) => (this.userFirstNameInput = input)}
            name="user[first_name]"
            value={user.first_name}
            required
            onChange={this.handleFirstNameChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="user_last_name" className="label">
            Last name
          </label>
          <input
            type="text"
            className="form-control"
            id="user_last_name"
            ref={(input) => (this.userLastNameInput = input)}
            name="user[last_name]"
            value={user.last_name}
            required
            onChange={this.handleLastNameChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="user_email" className="label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="user_email"
            ref={(input) => (this.userEmailInput = input)}
            name="user[email]"
            value={user.email}
            required
            onChange={this.handleEmailChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="user_phone" className="label">
            Phone
          </label>
          <PhoneInput
            country="us"
            onlyCountries={['us']}
            disableCountryCode
            disableDropdown
            placeholder=""
            defaultMask="(...) ...-...."
            value={LeadHelpers.nationalFormatPhoneNumber(user.phone)}
            onChange={this.handlePhoneChange}
            specialLabel={false}
            inputProps={{
              id:        'user_phone',
              name:      'user[phone]',
              className: 'form-control',
              type:      'phone',
              required:  true,
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="user_license" className="label">
            Do you have your Real Estate License?
          </label>
          <Select
            options={USER_LICENSES}
            value={user.production_label}
            onChange={this.handleLicenseChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="user_interest" className="label">
            What are you interested in?
          </label>
          <Select
            options={USER_INTERESTS}
            value={interest}
            onChange={this.handleInterestChange}
          />
        </div>

        <div className="form-group text-center text-xs">
          <div className="form-check">
            <input
              id="opt_in"
              className="form-check-input"
              type="checkbox"
              checked={user.opt_in}
              onChange={this.handleMarketingOptInChange}
            />
            <label
              className="form-check-label"
              htmlFor="opt_in"
            >
              I agree to receive marketing messaging at the phone number provided above.
              {' '}
              I understand I will receive messages; data rates may apply; reply STOP to opt-out.
              <br />
              <a href="/home/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              {' '}
              /
              {' '}
              <a href="/home/terms-of-service/" target="_blank" rel="noopener noreferrer"> Terms of Service</a>
            </label>
          </div>
        </div>

        <div className="form-group">
          {sending ? (
            <button
              type="submit"
              className={submitButtonClass}
              disabled={sending}
            >
              Sending...
            </button>
          ) : (
            <button type="submit" className={submitButtonClass} style={style}>
              Learn More
            </button>
          )}
        </div>
      </form>
    );

    if (thank_you === true) {
      formContent = (
        <div className="alert alert-success">
          Thanks for requesting to meet the team at
          {' '}
          {team.name}
          !
          We&apos;ve sent your information to the Team Leader and you
          should hear from them shortly. Make it a great day! Return
          to the
          {' '}
          <a href={url}>previous page.</a>
        </div>
      );
    }

    return (
      <div className="card">
        <div className="card-block">
          <h4 className="card-title">Learn more about us!</h4>
          {formContent}
        </div>
      </div>
    );
  }
}

export default LeadRecruitingApp;
