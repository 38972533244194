import pluralize from 'pluralize';

export const MESSAGE_TYPES = {
  INFO:   'info',
  DANGER: 'danger',
};

export const DOMAIN_MESSAGES = {
  UNCONFIGURED_DOMAIN_WARNING: {
    type: MESSAGE_TYPES.INFO,
    text: '🚀 Pro Tip: We recommend that you configure sending domains before sending from this email address to improve email deliverability.',
  },
  UNCONFIGURED_DOMAIN_ERROR: (recipientsCount, maxRecipients) => ({
    type: MESSAGE_TYPES.DANGER,
    text: `📨 You have selected ${recipientsCount} ${pluralize(
      'contact',
      recipientsCount,
    )} to email from an email address without sending domains set up. Please configure your sending domain for this sender email address to send a bulk email to over ${maxRecipients} ${pluralize(
      'contact',
      maxRecipients,
    )}.`,
  }),
  UNCONFIGURED_DOMAINS_WARNING: (domains) => ({
    type: MESSAGE_TYPES.INFO,
    text: `🚀 Pro Tip: There is a campaign email step with a sender email that does not have sending domains configured. We recommend that you configure sending domains before sending from these email addresses to improve email deliverability: ${domains.join(
      ', ',
    )}`,
  }),
  UNCONFIGURED_DOMAINS_ERROR: (
    recipientsCount,
    maxRecipients,
    domains,
  ) => ({
    type: MESSAGE_TYPES.DANGER,
    text: `📨 You have selected ${recipientsCount} ${pluralize(
      'contact',
      recipientsCount,
    )} for this campaign which has an email step from an email address without sending domains set up. Please configure your sending domain for these sender email addresses to send a campaign email to over ${maxRecipients} ${pluralize(
      'contact',
      recipientsCount,
    )}: ${domains.join(', ')}`,
  }),
  VALIDATION_ERROR: {
    type: MESSAGE_TYPES.DANGER,
    text: 'An error occurred while validating the domain. Please try again later.',
  },
};
