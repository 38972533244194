import autoBind            from 'react-autobind';
import React               from 'react';
import classNames          from 'classnames';
import { PropTypes }       from 'prop-types';
import PhoneInput          from 'react-phone-input-2';
import { Input }           from 'reactstrap';
import isEmail             from 'validator/lib/isEmail';
import isMobilePhone       from 'validator/lib/isMobilePhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                     from '~/lib/brokerbit';
import Drawer                        from '~/components/drawers/drawer';
import CurrencyInput                 from '~/components/forms/currency_input';
import MultipleListingServicesSelect from '~/components/forms/multiple_listing_services_select';
import Select                        from '~/components/forms/select';
import LeadHelpers                   from '~/helpers/lead_helpers';
import ErrorMessage                  from '~/components/forms/ErrorMessage';
import { ProductionLabelSelect }     from '~/components/forms/lead_fields';

import LeadDrawerActions from '~/actions/lead_drawer_actions';
import LeadDrawerStore from '~/stores/lead_drawer_store';
import { initWebSpellChecker }       from '~/lib/web_spell_checker';

let leadStoreListener;
let approvedForm = false;

const defaultEmailTypeOptions = [
  { value: 'office',   label: 'Office' },
  { value: 'personal', label: 'Personal' },
  { value: 'other',    label: 'Other' },
];

const { currentTeam } = Rails.helpers;
const teamID = currentTeam ? currentTeam.id : null;

class NewReferralDrawer extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const { currentUser } = Rails.helpers;
    const { role } = currentUser;

    this.emptyEmail = {
      id:         null,
      email:      '',
      email_type: 'office',
      main:       false,
      team_id:    teamID,
      _destroy:   false,
    };

    this.emptyAgentID = {
      id:                            null,
      agent_id:                      '',
      multiple_listing_service_ouid: '',
      _destroy:                      false,
    };

    this.state = {
      lead: {
        first_name:             '',
        last_name:              '',
        phone:                  '',
        owner_id:               currentUser.id,
        referrer_id:            currentUser.id,
        production_ytd:         '',
        production_label:       '',
        note:                   '',
        production_ltm:         '',
        source:                 'Referral',
        status:                 'new_lead',
        lead_emails_attributes: [{
          id:          null,
          email:       '',
          email_type: 'office',
          main:        true,
          team_id:     teamID,
          _destroy:    false,
        }],
        lead_agent_ids_attributes: [{
          id:                            null,
          agent_id:                      '',
          multiple_listing_service_ouid: '',
          _destroy:                      false,
        }],
      },
      errors:   {},
      warnings: {},
    };

    this.notesRef = React.createRef(null);
  }

  componentDidMount() {
    // listen to LeadDrawerStore changes
    leadStoreListener = LeadDrawerStore.addListener(this.onChange);
    this.initializeSpellCheckerForNotes();
  }

  componentWillUnmount() {
    // remove listener to LeadDrawerStore changes on Unmount
    if (leadStoreListener) leadStoreListener.remove();
  }

  initializeSpellCheckerForNotes() {
    const ele = this.notesRef.current;
    if (ele) initWebSpellChecker(ele);
  }

  handleAgentIdChange(e, leadAgentId) {
    e.preventDefault();

    leadAgentId.agent_id = e.target.value;

    this.setState({ lead: this.state.lead });
  }

  handleMultipleListingServiceChange(value, leadAgentId) {
    leadAgentId.multiple_listing_service_ouid = value;

    this.setState({ lead: this.state.lead });
  }

  handleEmailChange(e, leadEmail) {
    e.preventDefault();

    leadEmail.email = e.target.value;

    this.setState({ lead: this.state.lead });
  }

  handleEmailTypeChange(opt, leadEmail) {
    leadEmail.email_type = opt ? opt.value : '';

    this.setState({ lead: this.state.lead });
  }

  handleMainChange(e, leadEmail) {
    leadEmail.main = e.target.checked;

    this.setState({ lead: this.state.lead });
  }

  handleLeadEmailDelete(e, leadEmail) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this email?',
      callback: (ok) => {
        if (ok) {
          leadEmail._destroy = true;
          this.setState({ lead: this.state.lead });
        }
      },
    });
  }

  handleLeadAgentIdDelete(e, leadAgentId) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this Agent ID?',
      callback: (ok) => {
        if (ok) {
          leadAgentId._destroy = true;
          this.setState({ lead: this.state.lead });
        }
      },
    });
  }

  // when LeadDrawerStore changes...
  onChange() {
    const leadDrawerState = LeadDrawerStore.getState();
    const { lead, leadDrawerStoreAction, errors } = leadDrawerState;
    const { helpers } = this.context;

    if (leadDrawerStoreAction === 'createLeadDone') {
      helpers.openLeadDrawer({ lead });
    }

    if (leadDrawerStoreAction === 'createLeadFail') {
      this.setState({
        errors,
        submitting: false,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { lead } = this.state;
    const leadAgentIdAttributes = lead.lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    this.setState({ submitting: true });

    if (this.validate() && !approvedForm) {
      leadAgentIdAttributes.forEach((leadAgentId) => {
        if (!leadAgentId.agent_id) {
          leadAgentId.multiple_listing_service_ouid = '';
        }

        if (!leadAgentId.agent_id && !leadAgentId.multiple_listing_service_ouid) {
          leadAgentId._destroy = true;
        }
      });

      LeadDrawerActions.createReferralLead(lead);
    } else {
      this.setState({ submitting: false });
    }
  }

  onCancel(e) {
    e.preventDefault();

    const { helpers } = this.context;

    helpers.closeDrawer();
  }

  addLeadEmail() {
    const { lead } = this.state;

    lead.lead_emails_attributes.push({ ...this.emptyEmail });

    this.setState({ lead });
  }

  addLeadAgentId() {
    const { lead } = this.state;

    lead.lead_agent_ids_attributes.push({ ...this.emptyAgentID });

    this.setState({ lead });
  }

  validate() {
    const { lead } = this.state;

    const leadEmailAttributes   = lead.lead_emails_attributes.filter((x, i) => !x._destroy);
    const checkedCount          = leadEmailAttributes.filter((x, i) => x.main).length;
    const leadAgentIdAttributes = lead.lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    const errors   = {};
    const warnings = {};

    if (!lead.first_name) {
      errors.first_name = "Can't be empty";
    }

    if (!lead.last_name) {
      errors.last_name = "Can't be empty";
    }

    if (!_lodash.isEmpty(lead.phone) && !isMobilePhone(lead.phone, ['en-US'])) {
      errors.phone = 'Is invalid';
    }

    if (leadEmailAttributes.length > 0 && checkedCount < 1) {
      errors.emails = ['Please select any main email'];
    }

    leadEmailAttributes.forEach((leadEmail, index) => {
      if (!leadEmail.email || !isEmail(leadEmail.email)) {
        errors[index] = { email: 'Is invalid' };
      }

      if (!leadEmail.email_type) {
        errors[index] = { email_type: "Can't be empty" };
      }
    });

    leadAgentIdAttributes.forEach((leadAgentId, index) => {
      if (!!leadAgentId.agent_id && !leadAgentId.multiple_listing_service_ouid) {
        warnings[index] = { multiple_listing_service_ouid: 'It is recommended that you select the MLS for this agent ID.' };
      }
    });

    if (_lodash.size(errors) === 0 && _lodash.size(warnings) !== 0) {
      approvedForm = !approvedForm;
    } else {
      approvedForm = false;
    }

    this.setState({ errors, warnings });

    return _lodash.size(errors) === 0;
  }

  renderLeadEmail() {
    const { lead, errors } = this.state;
    const leadEmailAttributes = lead.lead_emails_attributes.filter((x, i) => !x._destroy);
    const checkedCount = leadEmailAttributes.filter((x, i) => x.main).length;
    const disableMainCheckbox = checkedCount >= 1;

    return leadEmailAttributes.map((leadEmail, index) => {
      const emailDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-4 col-lg-5">
            <Input
              className={classNames({ 'has-error': errors[index] ? errors[index].email : '' })}
              value={leadEmail.email}
              onChange={(e) => this.handleEmailChange(e, leadEmail)}
            />
          </div>

          <div className="col-4 col-lg-5 lead-emails">
            <Select
              value={leadEmail.email_type}
              options={defaultEmailTypeOptions}
              onChange={(opt) => this.handleEmailTypeChange(opt, leadEmail)}
              placeholder="--- Select ---"
              className={classNames({ 'has-error': errors[index] ? errors[index].email_type : '' })}
            />
          </div>

          <div className="col-auto">
            <div className="form-check">
              <input
                key={index}
                className="form-check-input"
                type="checkbox"
                id={`main_${index}`}
                onChange={(e) => this.handleMainChange(e, leadEmail)}
                checked={leadEmail.main || false}
                disabled={!leadEmail.main && disableMainCheckbox}
              />
              <label
                className="form-check-label"
                htmlFor={`main_${index}`}
              >
                Main
              </label>
            </div>
          </div>

          <div className="col-auto">
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleLeadEmailDelete(e, leadEmail)}>
              <FontAwesomeIcon icon={['fas', 'fa-times']} />
            </button>
          </div>
        </div>
      );

      return emailDOM;
    });
  }

  renderLeadAgentId() {
    const { lead, errors, warnings } = this.state;
    const leadAgentIdAttributes = lead.lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    return leadAgentIdAttributes.map((leadAgentId, index) => {
      const agentIdDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-5 col-lg-5">
            <Input
              className={classNames({ 'has-error': errors[index] ? errors[index].agent_id : '' })}
              value={leadAgentId.agent_id}
              onChange={(e) => this.handleAgentIdChange(e, leadAgentId)}
            />
          </div>

          <div className="col-5 col-lg-6 lead-agent-ids" id={`mls-select-${index}`}>
            <MultipleListingServicesSelect
              value={leadAgentId.multiple_listing_service_ouid}
              placeholder="--- Select a MLS ---"
              onChange={(val) => this.handleMultipleListingServiceChange(val, leadAgentId)}
              className={classNames({ 'has-error': errors[index] ? errors[index].multiple_listing_service_ouid : '' })}
              teamMlses
              primaryMls
              clearable
              target={`mls-select-${index}`}
            />
          </div>

          <div className="col-auto">
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleLeadAgentIdDelete(e, leadAgentId)}>
              <FontAwesomeIcon icon={['fas', 'fa-times']} />
            </button>
          </div>

          {warnings[index] && (
            <div className="col-12 text-warning mt-2">
              <FontAwesomeIcon icon={['fas', 'fa-exclamation-triangle']} />
              {' '}
              {warnings[index].multiple_listing_service_ouid}
            </div>
          )}
        </div>
      );

      return agentIdDOM;
    });
  }

  render() {
    const { lead, errors, submitting } = this.state;
    const saveButton = approvedForm ? 'Approve & Save' : 'Save';

    return (
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <Drawer contentClass="bg-grey-lightest">
          <div className="modal-header bg-white bb">
            <h5 className="modal-title">Add Lead</h5>
          </div>

          <div className="modal-body">
            <div className="field-group mb15">
              <label className="d-block">First Name</label>
              <Input
                className={errors.first_name ? 'has-error' : ''}
                onChange={(v) => {
                  lead.first_name = v.target.value;
                }}
              />
            </div>

            <div className="field-group mb15">
              <label className="d-block">Last Name</label>
              <Input
                className={errors.last_name ? 'has-error' : ''}
                onChange={(v) => {
                  lead.last_name = v.target.value;
                }}
              />
            </div>

            <div className="field-group mb15">
              <label className="d-block">
                <a
                  href="#lead_email"
                  id="btn-lead-email"
                  className="pull-right"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addLeadEmail();
                  }}
                >
                  Add Email
                </a>
                Email
              </label>

              {this.renderLeadEmail()}

              {errors.emails && (
                <ErrorMessage message={errors.emails} />
              )}
            </div>

            <div className="field-group mb15">
              <label className="d-block">Cell Phone</label>
              <PhoneInput
                country="us"
                onlyCountries={['us']}
                disableCountryCode
                disableDropdown
                placeholder=""
                defaultMask="(...) ...-...."
                value={LeadHelpers.nationalFormatPhoneNumber(lead.phone)}
                onChange={(v) => { lead.phone = v; }}
                specialLabel={false}
                inputProps={{
                  className: classNames('phone form-control', { 'has-error': !!errors.phone }),
                }}
              />
            </div>

            <div className="field-group mb15">
              <label className="d-block">
                <a
                  href="#lead_agent_id"
                  id="btn-lead-agent-id"
                  className="pull-right"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addLeadAgentId();
                  }}
                >
                  Add Agent ID
                </a>
                Agent ID
              </label>

              {this.renderLeadAgentId()}

              {errors.agent_ids && (
                <ErrorMessage message={errors.agent_ids} />
              )}
            </div>

            <div className="field-group mb15">
              <label className="d-block">Total $ Production LTM</label>

              <div className="form-group has-feedback mb5">
                <CurrencyInput
                  name="production_ytd"
                  value={lead.production_ytd}
                  size={9}
                  onChange={(v) => {
                    lead.production_ytd = v;
                  }}
                />
              </div>
            </div>

            <div className="field-group mb15">
              <label className="d-block">Total # Production LTM</label>

              <div className="form-group has-feedback mb5">
                <Input onChange={(v) => {
                  lead.production_ltm = v.target.value;
                }}
                />
              </div>
            </div>

            <div className="field-group mb15">
              <label className="d-block">Production Label</label>
              <ProductionLabelSelect
                value={lead.production_label}
                onChange={(opt) => {
                  lead.production_label = opt && opt.value;
                }}
              />
            </div>

            <div className="field-group mb15">
              <label className="d-block">Notes</label>
              <Input
                type="textarea"
                rows="8"
                id="notes"
                onChange={(v) => {
                  lead.note = v.target.value;
                }}
                innerRef={this.notesRef}
              />
            </div>
          </div>

          <div className="modal-footer bg-white bt">
            <a href="#cancel" className="text-grey mr10" onClick={this.onCancel}>Cancel</a>
            { submitting ? (
              <a href="#save" className="btn btn-success btn-wide disabled">
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                Saving ...
              </a>
            ) : (
              <a href="#save" className="btn btn-success btn-wide" onClick={this.onSubmit}>{saveButton}</a>
            )}
          </div>
        </Drawer>
      </form>
    );
  }
}

NewReferralDrawer.contextTypes = {
  helpers: PropTypes.shape({}),
};

export default NewReferralDrawer;
