import autoBind from 'react-autobind';
import classNames from 'classnames';
import update from 'immutability-helper';
import React from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EmailDomainActions from '~/actions/email_domain_actions';
import EmailDomainStore from '~/stores/email_domain_store';
import ErrorMessage from '~/components/forms/ErrorMessage';
import Tooltipable from '~/components/effects/tooltipable';

class DomainForm extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      errors:      {},
      submitting:  false,
      formVisible: false,
      domain:      {
        name: '',
      },
    };
  }

  componentDidMount() {
    // listen to domainStoreListener changes
    this.domainStoreListener = EmailDomainStore.addListener(
      this.onDomainStoreChange,
    );
  }

  componentWillUnmount() {
    // remove listener to EmailDomainStore changes on Unmount
    if (this.domainStoreListener) {
      this.domainStoreListener.remove();
    }
  }

  handleNameChange(e) {
    e.preventDefault();

    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      domain: {
        ...prevState.domain,
        name: value,
      },
    }));
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { domain } = this.state;
    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      EmailDomainActions.createDomain({ ...domain });
    } else {
      this.setState({ errors });
    }
  }

  // when EmailDomainStore changes...
  onDomainStoreChange() {
    const domainStoreState = EmailDomainStore.getState();
    const { helpers } = this.context;
    const {
      domain,
      lastDomainStoreAction,
      errors,
      submitting,
      formVisible,
    } = domainStoreState;
    let nextState;

    if (lastDomainStoreAction === 'createEmailDomain') {
      nextState = {
        errors,
        submitting,
      };
    }

    if (lastDomainStoreAction === 'createEmailDomainDone') {
      helpers.openEmailEmailDrawer({ domain });

      nextState = {
        domain: { name: '' },
        errors,
        submitting,
        formVisible,
      };
    }

    if (lastDomainStoreAction === 'createEmailDomainFail') {
      nextState = {
        errors,
        submitting,
      };
    }

    this.setState(nextState);
  }

  validate() {
    const { domain } = this.state;
    const { name } = domain;
    const errors = {};
    const domainRegex = /^(?=.{1,253})(?=.{1,63}\.)((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/;

    if (!domainRegex.test(name)) {
      errors.name = ['Domain name is invalid'];
    }

    return errors;
  }

  render() {
    const {
      domain, errors, submitting, formVisible,
    } = this.state;

    return (
      <div>
        <div
          className={classNames('domain-form-container', {
            visible: formVisible,
          })}
        >
          <form onSubmit={this.handleFormSubmit}>
            <div className="form-group row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label htmlFor="domain_name">
                  Type domain name
                  {' '}
                  <Tooltipable text="Please enter the email sending domain (for example, domain.com in name@domain.com) that you wish to use for sending emails through Brokerkit.">
                    <FontAwesomeIcon
                      icon="fad fa-info-circle"
                      style={{ '--fa-primary-color': 'white' }}
                    />
                  </Tooltipable>
                </label>
                <input
                  id="domain_name"
                  name="domain[name]"
                  placeholder="Ex: example.com"
                  className={`${classNames('form-control', {
                    'has-error': errors.name,
                  })}`}
                  value={domain.name}
                  onChange={this.handleNameChange}
                />
              </div>
            </div>

            <div>
              {errors.name && (
                <span>
                  <ErrorMessage
                    message={errors.name}
                    className="text-sm"
                  />
                  {' '}
                </span>
              )}
              {errors.base && (
                <span>
                  <ErrorMessage
                    message={errors.base}
                    className="text-sm"
                  />
                  {' '}
                </span>
              )}
            </div>

            <hr />

            <div className="form-group">
              {submitting ? (
                <button
                  type="submit"
                  className="btn btn-primary disabled"
                  disabled
                >
                  <FontAwesomeIcon
                    testid="spinner"
                    icon="far fa-spinner"
                    pulse
                  />
                  {' '}
                  Saving ...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Get Settings
                </button>
              )}
            </div>
          </form>
        </div>
        {!formVisible && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              this.setState({ formVisible: !formVisible });
            }}
          >
            Add Sending Domain
          </button>
        )}
      </div>
    );
  }
}

DomainForm.contextTypes = {
  helpers: PropTypes.shape({}),
};

DomainForm.defaultProps = {};

DomainForm.propTypes = {};

export default DomainForm;
