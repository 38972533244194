import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class BucketSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  loadOptions(_input, _callback) {
    const options = [];

    options.push({ value: 'default', label: 'Normal' });
    if (GlobalContainer.product() === 'retention') {
      options.push({ value: 'recruiting', label: 'Recruiting' });
    }
    options.push({ value: 'archived', label: 'Archived' });

    return options;
  }

  render() {
    return (
      <Select
        {...this.props}
        onChange={this.props.onChange}
        options={this.loadOptions()}
      />
    );
  }
}

BucketSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

BucketSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default BucketSelect;
