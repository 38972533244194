import autoBind      from 'react-autobind';
import classNames    from 'classnames';
import React         from 'react';
import { PropTypes } from 'prop-types';
import DayPicker     from 'react-day-picker';

import InputWithIcon from '~/components/forms/input_with_icon';
import Select        from '~/components/forms/select';

class FollowUpPicker extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const { date } = this.props;

    let followUpDate;

    if (date) {
      followUpDate = Moment(date);
    }

    this.state = {
      opened: false,
      date:   followUpDate,
    };
  }

  // force override change of date value
  changeDate(date) {
    this.setState({ date: Moment(date) });
  }

  onClick() {
    this.setState({ opened: true });
  }

  onFocus() {
    this.setState({ opened: true });
  }

  onBlur() {}

  onOverlayClick(e) {
    this.setState({ opened: false });
  }

  onDateChange(date, { disabled }) {
    const { onChange, onDateChange } = this.props;

    const change = {
      date: Moment(date),
    };

    if (disabled) return;
    if (onChange) onChange(change);

    this.setState({ opened: false, date: Moment(date) });
  }

  render() {
    const { inline, right } = this.props;
    const { opened, date } = this.state;

    const wrapperStyle = {
      width:           '279px',
      zIndex:          1500,
      position:        'absolute',
      backgroundColor: '#fff',
      marginTop:       '3px',
    };

    if (right) {
      wrapperStyle.right = 0;
    } else if (inline) {
      wrapperStyle.left = 0;
    }

    const overlayStyle = {
      width:    '100vw',
      height:   '100vh',
      left:     0,
      top:      0,
      position: 'fixed',
      zIndex:   1499,
    };

    const modifiers = {
      selected: date && date.toDate(),
      disabled: [
        { before: Moment().toDate() },
      ],
    };

    let triggerComponent;
    if (this.props.children) {
      triggerComponent = <span onClick={this.onClick}>{this.props.children}</span>;
    } else {
      triggerComponent =  (
        <InputWithIcon
          name={this.props.name}
          icon="calendar-alt"
          prefix="far"
          side="right"
          value={date && Moment(date).format('dddd, MMMM Do')}
          placeholder={this.props.placeholder}
          className={`${this.props.className} mb0`}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      );
    }

    let inputComponent;
    if (opened) {
      inputComponent = (
        <span>
          <div style={wrapperStyle} className="card">
            <span className="text-left">
              <DayPicker
                {...this.props}
                onDayClick={this.onDateChange}
                modifiers={modifiers}
              />
            </span>
          </div>
          <div style={overlayStyle} onClick={this.onOverlayClick} />
        </span>
      );
    }

    return (
      React.createElement(
        (this.props.inline ? 'span' : 'div'),
        { className: this.props.bulk ? '' : 'position-relative col p0' },
        triggerComponent,
        inputComponent,
      )
    );
  }
}

export default FollowUpPicker;
