import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer from '~/components/global_container';
import SearchQueryForm from './search_query_form';

import SearchQueryActions from '~/actions/search_query_actions';
import SearchQueryStore   from '~/stores/search_query_store';

class ComposingSearchQuery extends React.Component {
  constructor(props) {
    super(props);

    const { searchQueryID } = props;

    this.state = {
      loadingSearchQuery: !!searchQueryID,
      searchQuery:        null,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { searchQueryID } = this.props;

    if (searchQueryID) {
      SearchQueryActions.loadSearchQuery(searchQueryID, this.onSearchQueryLoad, this.onSearchQueryLoadFail);
    }
  }

  onSearchQueryLoad(searchQuery) {
    this.setState({
      loadingSearchQuery: false,
      searchQuery,
    });
  }

  onSearchQueryLoadFail() {
    this.setState({
      loadingSearchQuery: false,
      searchQuery:        null,
    });
  }

  renderSearchQueryForm() {
    const { loadingSearchQuery, searchQuery } = this.state;
    const { history } = this.props;

    if (loadingSearchQuery) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    return <SearchQueryForm searchQuery={searchQuery} history={history} />;
  }

  render() {
    const search_queries_uri = GlobalContainer.productUri('/data');
    const { searchQueryID } = this.props;

    return (
      <div className="card search-query">
        <div className="card-block">
          <h4 className="mb30">
            <div className="pull-right">
              <Link
                to={{
                  pathname: search_queries_uri,
                  dataTab:  'mls_import',
                }}
                className="btn btn-outline-success mr5"
              >
                <FontAwesomeIcon icon={['fas', 'fa-caret-left']} className="mr1" />
                {' '}
                Back
              </Link>
            </div>
            {searchQueryID ? 'Edit' : 'New'}
            {' '}
            Import Query
            <hr className="mt-5 hr-success-color" />
          </h4>

          {this.renderSearchQueryForm()}
        </div>
      </div>
    );
  }
}

ComposingSearchQuery.defaultProps = {
  searchQueryID: null,
};

ComposingSearchQuery.propTypes = {
  searchQueryID: PropTypes.string,
  history:       PropTypes.shape({}).isRequired,
};

export default withRouter(ComposingSearchQuery);
