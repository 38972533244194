import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select-4';

const NewSelect = ({
  id,
  value,
  name,
  isMulti,
  isClearable,
  isSearchable,
  isLoading,
  creatable,
  disabled,
  placeholder,
  options,
  loadOptions,
  onChange,
  isInvalid,
  components,
}) => {
  const getOptionFromValue = () => options.find((option) => option.value === value) || null;

  const initialSelectedOption = getOptionFromValue();
  const color = GlobalContainer.product() === 'retention' ? '#4286C6' : '#16B783';

  let backgroundColor;
  if (isInvalid) {
    backgroundColor = '#ffebeb';
  } else if (disabled) {
    backgroundColor = '#e9ecef';
  } else {
    backgroundColor = 'white';
  }

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

  const handleChange = useCallback((selectedOpt) => {
    setSelectedOption(selectedOpt);
    onChange(selectedOpt);
  }, [onChange]);

  return (
    <>
      <ReactSelect
        inputId={id}
        openMenuOnFocus
        name={name}
        components={components}
        isMulti={isMulti}
        isClearable={isClearable}
        isLoading={isLoading}
        isDisabled={disabled}
        isSearchable={isSearchable}
        creatable={creatable}
        placeholder={placeholder}
        options={options}
        loadOptions={loadOptions}
        onChange={handleChange}
        value={selectedOption}
        styles={{
          container: (styles) => ({
            ...styles,
          }),
          control: (styles) => ({
            ...styles,
            border: isInvalid
              ? '1px solid #fd3737'
              : '1px solid #e5e8ef',
            backgroundColor,
            boxShadow:       '0 1px 2px 0 rgba(106, 116, 135, 0.1)',
            ':hover':        {
              ...styles[':hover'],
              borderColor: color,
            },

          }),
          option: (styles, { isDisabled, isSelected }) => ({
            ...styles,
            color:           isSelected ? color : 'black',
            backgroundColor: undefined,
            cursor:          isDisabled ? 'not-allowed' : 'default',
            ':hover':        {
              ...styles[':hover'],
              color,
            },
            ':active':        {
              ...styles[':active'],
              backgroundColor: undefined,
            },
          }),

          placeholder: (styles) => ({
            ...styles,
            color: '#93a1ba',
          }),
          menu: (styles) => ({
            ...styles,
            zIndex:   '99',
            width:    'max-content',
            minWidth: '100%',
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#16b783',
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: 'white',
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: 'white',
          }),
        }}
      />
    </>
  );
};

NewSelect.defaultProps = {
  id:           '',
  value:        '',
  name:         'dropdown',
  isMulti:      false,
  isClearable:  false,
  isLoading:    false,
  creatable:    false,
  disabled:     false,
  placeholder:  'Select an Option',
  options:      [],
  loadOptions:  () => false,
  onChange:     () => false,
  isInvalid:    false,
  isSearchable: false,
  components:   {},
};

NewSelect.propTypes = {
  id:           PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.string,
    PropTypes.number,
  ]),
  name:         PropTypes.string,
  isMulti:      PropTypes.bool,
  isClearable:  PropTypes.bool,
  isLoading:    PropTypes.bool,
  isInvalid:    PropTypes.bool,
  isSearchable: PropTypes.bool,
  creatable:    PropTypes.bool,
  disabled:     PropTypes.bool,
  placeholder:  PropTypes.string,
  loadOptions:  PropTypes.func,
  onChange:     PropTypes.func,
  components:   PropTypes.shape({}),
  options:      PropTypes.arrayOf(PropTypes.shape({})),
};

export default NewSelect;
