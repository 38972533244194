import APIRequest from '~/lib/api_request';

const formatedFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

const formatedDate = (date) => (date ? Moment(date).format('MM/DD/YYYY') : '-');

const checkIfLimitExceeded = async (documentsCount) => {
  try {
    const response = await APIRequest.get({
      resource: `/v1/documents/documents_limit_exceeded`,
      data: { documents_count: documentsCount },
    });

    const { body } = response;

    return body?.limit_exceeded;
  } catch (error) {
    console.error('Error checking if limit exceeded', error);
    return false;
  }
};

export { formatedFileSize, formatedDate, checkIfLimitExceeded };
