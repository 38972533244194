const TwilioBundleActionTypes = {
  LOAD_TWILIO_BUNDLE:      'LOAD_TWILIO_BUNDLE',
  LOAD_TWILIO_BUNDLE_DONE: 'LOAD_TWILIO_BUNDLE_DONE',
  LOAD_TWILIO_BUNDLE_FAIL: 'LOAD_TWILIO_BUNDLE_FAIL',

  CREATE_TWILIO_BUNDLE:      'CREATE_TWILIO_BUNDLE',
  CREATE_TWILIO_BUNDLE_DONE: 'CREATE_TWILIO_BUNDLE_DONE',
  CREATE_TWILIO_BUNDLE_FAIL: 'CREATE_TWILIO_BUNDLE_FAIL',
};

export default TwilioBundleActionTypes;
