import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadSourceActions from '~/actions/lead_source_actions';
import LeadSourceStore from '~/stores/lead_source_store';
import ErrorMessage from '~/components/forms/ErrorMessage';

class LeadSourceForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      errors:     {},
      submitting: false,
      source:     {
        id:   null,
        name: '',
      },
    };
  }

  componentDidMount() {
    // listen to leadSourceStoreListener changes
    this.leadSourceStoreListener = LeadSourceStore.addListener(this.onLeadSourceStoreChange);
  }

  componentWillUnmount() {
    // remove listener to LeadSourceStore changes on Unmount
    if (this.leadSourceStoreListener) this.leadSourceStoreListener.remove();
  }

  handleNameChange = (e) => {
    e.preventDefault();

    const { value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      source: {
        ...prevState.source,
        name: value,
      },
    }));
  };

  handleFormSubmit(e) {
    e.preventDefault();

    const { source } = this.state;

    this.setState({ submitting: true }, () => {
      LeadSourceActions.createLeadSource({ ...source });
    });
  }

  // when LeadSourceStore changes...
  onLeadSourceStoreChange() {
    const leadSourceStoreState = LeadSourceStore.getState();
    const {
      source, lastLeadSourceStoreAction, errors, submitting,
    } = leadSourceStoreState;

    let nextState = { leadSourceStoreState };

    if (lastLeadSourceStoreAction === 'createLeadSourceDone') {
      GlobalContainer.notify(`Source "${source.name}" created.`, 'success');
      nextState = { source: { id: null, name: '' }, errors, submitting };
    }

    if (lastLeadSourceStoreAction === 'createLeadSourceFail') {
      nextState = { ...nextState, errors, submitting };
    }

    this.setState(nextState);
  }

  render() {
    const { source, errors, submitting } = this.state;

    return (
      <div>
        <form method="POST" action="/lead_sources" className="form-inline" onSubmit={this.handleFormSubmit}>
          <div className="form-group mr-3">
            <input
              id="source_name"
              type="text"
              name="source[name]"
              placeholder="Type a Source Name"
              className={classNames('form-control', { 'has-error': errors.name })}
              value={source.name}
              onChange={this.handleNameChange}
            />
          </div>
          <div className="form-group">
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Saving ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Create New Source</button>
            )}
          </div>
        </form>
        <div>{errors.name && <ErrorMessage message={errors.name} />}</div>
      </div>
    );
  }
}

LeadSourceForm.defaultProps = {};

LeadSourceForm.propTypes = {};

export default LeadSourceForm;
