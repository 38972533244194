import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit        from '~/lib/brokerbit';
import LabelActions     from '~/actions/label_actions';
import LabelStore       from '~/stores/label_store';
import AutosuggestInput from '~/components/forms/autosuggest_input';
import ErrorMessage     from '~/components/forms/ErrorMessage';
import AppModal         from './app_modal';

class EditLabelModal extends React.Component {
  constructor(props) {
    super(props);

    const { labelID } = props;

    this._isMounted = false;

    this.state = {
      loadingLabel:   !!labelID,
      label:          {},
      errors:         {},
      submitting:     false,
      labelName:      '',
    };

    autoBind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    // listen to labelStoreListener changes
    this.labelStoreListener = LabelStore.addListener(this.onLabelStoreChange);

    const { labelID } = this.props;

    if (labelID && this._isMounted) {
      LabelActions.loadLabel(labelID, this.onLabelLoad);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    // remove listener to LabelStore changes on Unmount
    if (this.labelStoreListener) this.labelStoreListener.remove();
  }

  handleNameChange(value) {
    this.setState((prevState) => ({
      ...prevState,
      label: {
        ...prevState.label,
        name: value,
      },
    }));
  }

  handleSubmit(e) {
    e.preventDefault();

    const { label, labelName } = this.state;

    this.setState({ submitting: true }, () => {
      brokerbit.confirmBox({
        message:  `Are you sure you want to change (${labelName}) to (${label.name})? This change affects ${label.leads_count} users.`,
        callback: (ok) => {
          if (ok) {
            LabelActions.updateLabel({ ...label, id: label.id });
          } else {
            this.setState({ submitting: false });
          }
        },
      });
    });
  }

  onLabelLoad(label) {
    this.setState({
      loadingLabel: false,
      labelName:    label.name,
      label,
    });
  }

  // when LabelStore changes...
  onLabelStoreChange() {
    const labelState = LabelStore.getState();
    const { label, errors, lastLabelStoreAction } = labelState;
    const $modal = $(this.appModal.modal);

    let nextState = { labelState };

    if (lastLabelStoreAction === 'updateLabelDone') {
      $modal.modal('hide');
      GlobalContainer.notify(`Label "${label.name}" updated.`, 'success');
    }

    if (lastLabelStoreAction === 'updateLabelFail') {
      if (errors) {
        nextState = { ...nextState, errors, submitting: false };
      }
    }

    this.setState(nextState);
  }

  renderLabelForm() {
    const {
      loadingLabel, label, errors, responseErrors,
    } = this.state;
    const { labels } = this.props;

    if (loadingLabel) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse />
        </div>
      );
    }

    return (
      <div className="form-group">
        <label htmlFor="label_name" className="label">Label Name</label>
        <AutosuggestInput
          suggestions={labels}
          id="label_name"
          name="label[name]"
          placeholder="Type a Label Name"
          onChange={this.handleNameChange}
          styles={`${classNames('form-control', { 'has-error': errors.name })}`}
          value={label.name}
        />
        {errors.name && <ErrorMessage message={errors.name} />}
      </div>
    );
  }

  render() {
    const { label, submitting } = this.state;

    return (
      <AppModal ref={(appModal) => this.appModal = appModal}>
        <form method="PUT" action={`/labels/${label.id}`} onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Edit Label
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {this.renderLabelForm()}
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Updating ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Update</button>
            )}
          </div>
        </form>
      </AppModal>
    );
  }
}

EditLabelModal.defaultProps = {};

EditLabelModal.propTypes = {
  labelID: PropTypes.number.isRequired,
  labels:  PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default EditLabelModal;
