import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

class TriggerConditionNew extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  addNewCondition() {
    this.props.addCondition();
  }

  render() {
    const { conditionsCount } = this.props;

    const buttonText = conditionsCount < 1 ? 'Add Condition' : 'Add Another Condition';

    return (
      <a
        href="#condition"
        id="btn-condition"
        className="btn btn-primary"
        onClick={(e) => {
          e.preventDefault();
          this.addNewCondition();
        }}
      >
        {buttonText}
      </a>
    );
  }
}

TriggerConditionNew.defaultProps = {
  conditionsCount: 0,
  addCondition:    () => false,
};

TriggerConditionNew.propTypes = {
  conditionsCount: PropTypes.number,
  addCondition:    PropTypes.func,
};

export default TriggerConditionNew;
