import React from 'react';
import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineReleasePhoneNumber extends TimelineActivity {}

TimelineReleasePhoneNumber.defaultProps = {
  title: (object) => {
    const { parameters: { acting_user_name, message }, owner } = object.props.activity;

    return `${message}`;
  },
  icon:           'redo',
  prefix:         'fas',
  alwaysPublic:   false,
};

export default TimelineReleasePhoneNumber;
