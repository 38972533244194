import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import update              from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadDrawerActions from '~/actions/lead_drawer_actions';
import LeadDrawerStore   from '~/stores/lead_drawer_store';

import OrganizationTeamsSelect from '~/components/forms/organization_teams_select';
import AppModal                from './app_modal';

let leadStoreListener;

class ReassignLeadModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:     {},
      submitting: false,
      newTeam:    null,
    };

    autoBind(this);
  }

  componentDidMount() {
    // listen to LeadDrawerStore changes
    leadStoreListener = LeadDrawerStore.addListener(this.onChange);
  }

  componentWillUnmount() {
    // remove listener to LeadDrawerStore changes on Unmount
    if (leadStoreListener) leadStoreListener.remove();
  }

  // when LeadDrawerStore changes...
  onChange() {
    const leadDrawerState = LeadDrawerStore.getState();
    const { leadDrawerStoreAction, message, errors } = leadDrawerState;
    const $modal = $(this.appModal.modal);

    if (leadDrawerStoreAction === 'reassignLeadDone') {
      GlobalContainer.notify(message, 'success');

      $modal.modal('hide');
    }

    if (leadDrawerStoreAction === 'reassignLeadFail') {
      this.setState({
        errors,
        submitting: false,
      });
    }
  }

  handleTeamSelect = (selectedItem) => {
    const teamID = selectedItem ? selectedItem.value : null;

    this.setState({ newTeam: teamID });
  }

  validate() {
    const errors = {};
    const { newTeam } = this.state;

    if (!newTeam) {
      errors.newTeam = "Can't be empty";
    }

    this.setState({ errors });

    return _lodash.size(errors) === 0;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { newTeam } = this.state;
    const { leadID }  = this.props;

    this.setState({ submitting: true });

    if (this.validate()) {
      LeadDrawerActions.reassignLead(leadID, newTeam);
    } else {
      this.setState({ submitting: false });
    }
  }

  render() {
    const { errors, submitting } = this.state;

    return (
      <AppModal
        containerID="secondary-modal"
        modalClass="modal modal-overlay"
        dialogClass="modal-dialog modal-lg"
        ref={(appModal) => this.appModal = appModal}
      >
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Reassign
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group mr5">
              <label>Organization Accounts</label>
              <OrganizationTeamsSelect
                placeholder="Select a Account..."
                onChange={this.handleTeamSelect}
                className={errors.newTeam ? 'has-error' : ''}
                clearable
              />
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Reassigning ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Reassign</button>
            )}
          </div>
        </form>
      </AppModal>
    );
  }
}

ReassignLeadModal.defaultProps = {
  teamID:         null,
  leadID:         null,
  organizationID: null,
};

ReassignLeadModal.propTypes = {
  teamID:         PropTypes.number.isRequired,
  leadID:         PropTypes.number.isRequired,
  organizationID: PropTypes.number.isRequired,
};

export default ReassignLeadModal;
