import React               from 'react';
import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import update              from 'immutability-helper';
import { PropTypes }       from 'prop-types';
import { Link }            from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LabelActions     from '~/actions/label_actions';
import LabelStore       from '~/stores/label_store';
import LeadLabelActions from '~/actions/lead_label_actions';
import LeadLabelStore   from '~/stores/lead_label_store';

import ErrorMessage from '~/components/forms/ErrorMessage';
import AutosuggestInput from '~/components/forms/autosuggest_input';

class LabelingForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      labels:     [],
      errors:     {},
      submitting: false,
      label:      {
        id:   null,
        name: '',
      },
    };
  }

  componentDidMount() {
    // listen to labelsStoreListener changes
    this.labelsStoreListener     = LabelStore.addListener(this.onLabelStoreChange);
    this.leadLabelsStoreListener = LeadLabelStore.addListener(this.onLeadLabelStoreChange);

    setTimeout(() => {
      LabelActions.loadLabels();
    });
  }

  componentWillUnmount() {
    if (this.labelsStoreListener) this.labelsStoreListener.remove();
    if (this.leadLabelsStoreListener) this.leadLabelsStoreListener.remove();
  }

  onLabelStoreChange() {
    const labelStoreState = LabelStore.getState();
    const { labels, lastLabelStoreAction } = labelStoreState;

    if (lastLabelStoreAction === 'loadLabelsDone') {
      this.setState({ labels });
    }
  }

  onLeadLabelStoreChange() {
    const leadLabelStoreState = LeadLabelStore.getState();
    const {
      label, lastLeadLabelStoreAction, errors, submitting,
    } = leadLabelStoreState;

    let nextState = { leadLabelStoreState };

    if (lastLeadLabelStoreAction === 'createLeadLabelDone') {
      nextState = { label: { id: null, name: '' }, errors, submitting };
    }

    if (lastLeadLabelStoreAction === 'createLeadLabelFail') {
      nextState = { ...nextState, errors, submitting };
    }

    this.setState(nextState);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { label }  = this.state;
    const { leadID } = this.props;

    this.setState({ submitting: true }, () => {
      LeadLabelActions.createLeadLabel(
        leadID,
        { ...label },
      );
    });
  }

  handleNameChange(value) {
    this.setState(
      update(this.state, {
        label: {
          name: { $set: value },
        },
      }),
    );
  }

  render() {
    const {
      label, labels, errors, submitting,
    } = this.state;

    const error = errors.name || errors.label_id;

    return (
      <div>
        <form method="POST" action="/labels" className="form-inline" onSubmit={this.handleFormSubmit}>
          <div className="form-group ml-3">
            <AutosuggestInput
              suggestions={labels}
              id="label_name"
              name="label[name]"
              placeholder="Type a Label Name"
              onChange={this.handleNameChange}
              styles={`${classNames('form-control', { 'has-error': error })}`}
              value={label.name}
            />
          </div>
          <div className="form-group mx-3">
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                Saving ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Add New Label</button>
            )}
          </div>
        </form>
        <div className="ml-3">{error && <ErrorMessage message={error} />}</div>
      </div>
    );
  }
}

LabelingForm.defaultProps = {
  leadID: null,
};

LabelingForm.propTypes = {
  leadID: PropTypes.number.isRequired,
};

export default LabelingForm;
