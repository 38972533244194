import APIRequest            from '~/lib/api_request';
import AppDispatcher         from '~/dispatchers/app_dispatcher';
import LeadSourceActionTypes from '~/types/lead_source_action_types';

let sourcesRequest;
let loadSourceRequest;
let createSourceRequest;
let updateSourceRequest;
let deleteSourceRequest;

const LeadSourceActions = {
  loadLeadSources() {
    APIRequest.abort(sourcesRequest);

    AppDispatcher.dispatch({
      type: LeadSourceActionTypes.LOAD_LEAD_SOURCES,
    });

    sourcesRequest = APIRequest.get({
      resource: '/v1/lead_sources',
    });

    sourcesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadSourceActionTypes.LOAD_LEAD_SOURCES_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        LeadSourceActionTypes.LOAD_LEAD_SOURCES_DONE,
        leadSources: response.body,
      });
    });
  },

  loadLeadSource(sourceID, onDone = () => false, onFail = () => false) {
    APIRequest.abort(loadSourceRequest);

    AppDispatcher.dispatch({
      type: LeadSourceActionTypes.LOAD_LEAD_SOURCE,
    });

    loadSourceRequest = APIRequest.get({
      resource: `/v1/lead_sources/${sourceID}`,
    });

    loadSourceRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  createLeadSource(source) {
    APIRequest.abort(createSourceRequest);

    AppDispatcher.dispatch({
      type: LeadSourceActionTypes.CREATE_LEAD_SOURCE,
      source,
    });

    createSourceRequest = APIRequest.post({
      resource: '/v1/lead_sources',
      data:     {
        lead_source: source,
      },
    });

    createSourceRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadSourceActionTypes.CREATE_LEAD_SOURCE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   LeadSourceActionTypes.CREATE_LEAD_SOURCE_DONE,
        source: response.body,
      });

      LeadSourceActions.loadLeadSources();
    });
  },

  updateLeadSource(source) {
    APIRequest.abort(updateSourceRequest);

    AppDispatcher.dispatch({
      type: LeadSourceActionTypes.UPDATE_LEAD_SOURCE,
      source,
    });

    updateSourceRequest = APIRequest.put({
      resource: `/v1/lead_sources/${source.id}`,
      data:     {
        lead_source: source,
      },
    });

    updateSourceRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadSourceActionTypes.UPDATE_LEAD_SOURCE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:   LeadSourceActionTypes.UPDATE_LEAD_SOURCE_DONE,
        source: response.body,
      });

      LeadSourceActions.loadLeadSources();
    });
  },

  deleteLeadSource(source) {
    APIRequest.abort(deleteSourceRequest);

    AppDispatcher.dispatch({
      type: LeadSourceActionTypes.DELETE_LEAD_SOURCE,
      source,
    });

    deleteSourceRequest = APIRequest.delete({
      resource: `/v1/lead_sources/${source.id}`,
    });

    deleteSourceRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: LeadSourceActionTypes.DELETE_LEAD_SOURCE_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: LeadSourceActionTypes.DELETE_LEAD_SOURCE_DONE,
      });

      LeadSourceActions.loadLeadSources();
    });
  },
};

export default LeadSourceActions;
