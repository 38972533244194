const TwilioVerifiedActionTypes = {
  LOAD_TWILIO_VERIFIED_TEXTING:      'LOAD_TWILIO_VERIFIED_TEXTING',
  LOAD_TWILIO_VERIFIED_TEXTING_DONE: 'LOAD_TWILIO_VERIFIED_TEXTING_DONE',
  LOAD_TWILIO_VERIFIED_TEXTING_FAIL: 'LOAD_TWILIO_VERIFIED_TEXTING_FAIL',

  CREATE_TWILIO_VERIFIED_TEXTING:      'CREATE_TWILIO_VERIFIED_TEXTING',
  CREATE_TWILIO_VERIFIED_TEXTING_DONE: 'CREATE_TWILIO_VERIFIED_TEXTING_DONE',
  CREATE_TWILIO_VERIFIED_TEXTING_FAIL: 'CREATE_TWILIO_VERIFIED_TEXTING_FAIL',

  UPDATE_TWILIO_VERIFIED_TEXTING:      'UPDATE_TWILIO_VERIFIED_TEXTING',
  UPDATE_TWILIO_VERIFIED_TEXTING_DONE: 'UPDATE_TWILIO_VERIFIED_TEXTING_DONE',
  UPDATE_TWILIO_VERIFIED_TEXTING_FAIL: 'UPDATE_TWILIO_VERIFIED_TEXTING_FAIL',
};

export default TwilioVerifiedActionTypes;
