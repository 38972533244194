import autoBind from 'react-autobind';
import React, { useState } from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab  from 'react-bootstrap/Tab';

import DataImportTab  from './data_import_tab/data_import_tab';
import MlsImportTab   from './mls_import_tab/mls_import_tab';
import MlsSettingsTab from './mls_settings_tab/mls_settings_tab';
import DuplicatesTab  from './duplicate_leads_tab/duplicates_tab';

function DataTabs() {
  const dataTab = browserHistory.location.dataTab || 'data_import';
  const { mlsImportFeatureEnabled, currentUser } = Rails.helpers;
  const [key, setKey] = useState(dataTab);

  return (
    <Tabs
      id="data-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="data-tabs mb15 clearfix"
      mountOnEnter
      unmountOnExit
    >
      <Tab eventKey="data_import" title="File Import">
        <DataImportTab />
      </Tab>

      <Tab eventKey="multiple_listing_services" title="MLS Settings">
        <MlsSettingsTab />
      </Tab>

      {mlsImportFeatureEnabled && (currentUser.role.name === 'admin' || currentUser.admin) && (
        <Tab eventKey="mls_import" title="MLS Import">
          <MlsImportTab />
        </Tab>
      )}

      <Tab eventKey="duplicate_leads" title="Merge Duplicates">
        <DuplicatesTab />
      </Tab>
    </Tabs>
  );
}

class DataCore extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    return (
      <>
        <DataTabs />
      </>
    );
  }
}

export default DataCore;
