import { ReduceStore }       from 'flux/utils';
import AppDispatcher         from '~/dispatchers/app_dispatcher';
import LeadSourceActionTypes from '~/types/lead_source_action_types';

class LeadSourceStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingLeadSources:        false,
      loadingLeadSource:         false,
      leadSources:               [],
      source:                    null,
      lastLeadSourceStoreAction: null,
      errors:                    {},
      submitting:                false,
    };
  }

  loadLeadSources(state, action) {
    const newState = { ...state };

    newState.loadingLeadSources = true;
    newState.lastLeadSourceStoreAction = 'loadLeadSources';

    return newState;
  }

  loadLeadSourcesDone(state, action) {
    const newState = { ...state };

    newState.loadingLeadSources = false;
    newState.leadSources = action.leadSources;
    newState.lastLeadSourceStoreAction = 'loadLeadSourcesDone';

    return newState;
  }

  loadLeadSourcesFail(state, action) {
    const newState = { ...state };

    newState.loadingLeadSources = false;
    newState.errors = action.error.response.body.errors;
    newState.lastLeadSourceStoreAction = 'loadLeadSourceFail';

    return newState;
  }

  loadLeadSource(state, action) {
    const newState = { ...state };

    newState.loadingLeadSource = true;
    newState.lastLeadSourceStoreAction = 'loadLeadSource';

    return newState;
  }

  loadLeadSourceDone(state, action) {
    const newState = { ...state };

    newState.loadingLeadSource = false;
    newState.source = action.source;
    newState.lastLeadSourceStoreAction = 'loadLeadSourceDone';

    return newState;
  }

  loadLeadSourceFail(state, action) {
    const newState = { ...state };

    newState.loadingLeadSource = false;
    newState.errors = action.errors;
    newState.lastLeadSourceStoreAction = 'loadLeadSourceFail';

    return newState;
  }

  createLeadSource(state, action) {
    const newState = { ...state };

    newState.lastLeadSourceStoreAction = 'createLeadSource';

    return newState;
  }

  createLeadSourceDone(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.source = action.source;
    newState.errors     = {};
    newState.lastLeadSourceStoreAction = 'createLeadSourceDone';

    return newState;
  }

  createLeadSourceFail(state, action) {
    const newState = { ...state };

    newState.submitting = false;
    newState.errors     = action.error.response.body.errors;
    newState.lastLeadSourceStoreAction = 'createLeadSourceFail';

    return newState;
  }

  updateLeadSource(state, action) {
    const newState = { ...state };

    newState.lastLeadSourceStoreAction = 'updateLeadSource';

    return newState;
  }

  updateLeadSourceDone(state, action) {
    const newState = { ...state };

    newState.source  = action.source;
    newState.errors = {};
    newState.lastLeadSourceStoreAction = 'updateLeadSourceDone';

    return newState;
  }

  updateLeadSourceFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body.errors;
    newState.lastLeadSourceStoreAction = 'updateLeadSourceFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case LeadSourceActionTypes.LOAD_LEAD_SOURCES:
        return this.loadLeadSources(state, action);
      case LeadSourceActionTypes.LOAD_LEAD_SOURCES_DONE:
        return this.loadLeadSourcesDone(state, action);
      case LeadSourceActionTypes.LOAD_LEAD_SOURCES_FAIL:
        return this.loadLeadSourcesFail(state, action);
      case LeadSourceActionTypes.LOAD_LEAD_SOURCE:
        return this.loadLeadSource(state, action);
      case LeadSourceActionTypes.LOAD_LEAD_SOURCE_DONE:
        return this.loadLeadSourceDone(state, action);
      case LeadSourceActionTypes.LOAD_LEAD_SOURCE_FAIL:
        return this.loadLeadSourceFail(state, action);
      case LeadSourceActionTypes.CREATE_LEAD_SOURCE:
        return this.createLeadSource(state, action);
      case LeadSourceActionTypes.CREATE_LEAD_SOURCE_DONE:
        return this.createLeadSourceDone(state, action);
      case LeadSourceActionTypes.CREATE_LEAD_SOURCE_FAIL:
        return this.createLeadSourceFail(state, action);
      case LeadSourceActionTypes.UPDATE_LEAD_SOURCE:
        return this.updateLeadSource(state, action);
      case LeadSourceActionTypes.UPDATE_LEAD_SOURCE_DONE:
        return this.updateLeadSourceDone(state, action);
      case LeadSourceActionTypes.UPDATE_LEAD_SOURCE_FAIL:
        return this.updateLeadSourceFail(state, action);
      default:
        return state;
    }
  }
}

export default new LeadSourceStore();
