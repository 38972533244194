import React, { Suspense, lazy } from 'react';
import { Router, Route } from 'react-router-dom';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';

const AgentDashboard = lazy(() => import(
  /* webpackChunkName: "agent-dashboard" */ '~/components/pages/agent_dashboard'
));
const PublicReport = lazy(() => import(
  /* webpackChunkName: "public-report" */ '~/components/pages/public_report'
));
const PhoneNumberSetting = lazy(() => import(
  /* webpackChunkName: "phone-number" */ '~/components/pages/account/phone_number_setting'
));
const TeamSettings = lazy(() => import(
  /* webpackChunkName: "account" */ '~/pages/AccountSettings/index'
));
const UserSettings = lazy(() => import(
  /* webpackChunkName: "user" */ '~/pages/UserSettings/index'
));
const LandingPageSetting = lazy(() => import(
  /* webpackChunkName: "landing-page" */ '~/components/pages/account/landing_page_setting'
));
const LandingPageAccountSetting = lazy(() => import(
  /* webpackChunkName: "landing-page" */ '~/components/pages/account/landing_page_account_setting'
));
const TeamMembers = lazy(() => import(
  /* webpackChunkName: "team-members" */ '~/components/pages/account/team_members'
));
const ManageCampaigns = lazy(() => import(
  /* webpackChunkName: "campaigns" */ '~/components/pages/account/manage_campaigns'
));
const EditCampaign = lazy(() => import(
  /* webpackChunkName: "campaigns" */ '~/components/pages/account/edit_campaign'
));
const EditCampaignLibrary = lazy(() => import(
  /* webpackChunkName: "campaign-library" */ '~/components/pages/account/manage_campaigns_library/form/edit_campaign_library'
));
const OnboardApp = lazy(() => import(
  /* webpackChunkName: "onboard-app" */ '~/components/onboard_app'
));
const ManageLabels = lazy(() => import(
  /* webpackChunkName: "labels" */ '~/components/pages/account/labels/manage_labels'
));
const ManageCampaignTriggers = lazy(() => import(
  /* webpackChunkName: "campaign-triggers" */ '~/components/pages/account/manage_campaign_triggers'
));
const NewOrEditCampaignTrigger = lazy(() => import(
  /* webpackChunkName: "campaign-triggers" */ '~/components/pages/account/new_or_edit_campaign_trigger'
));
const ManageCampaignsLibrary = lazy(() => import(
  /* webpackChunkName: "campaign-library" */ '~/components/pages/account/manage_campaigns_library'
));
const PreviewCampaignsLibrary = lazy(() => import(
  /* webpackChunkName: "campaign-library" */ '~/components/pages/account/preview_campaigns_library'
));
const ManageData = lazy(() => import(
  /* webpackChunkName: "data" */ '~/components/pages/account/manage_data'
));
const SignUp = lazy(() => import(
  /* webpackChunkName: "sign-up" */ '~/components/pages/users/sign_up'
));
const Register = lazy(() => import(
  /* webpackChunkName: "sign-up" */ '~/components/pages/users/register'
));
const NewOrEditSearchQuery = lazy(() => import(
  /* webpackChunkName: "search-queries" */ '~/components/pages/account/data/mls_import_tab/new_or_edit_search_query'
));
const ManageMlsConfiguration = lazy(() => import(
  /* webpackChunkName: "mls" */ '~/components/pages/account/manage_mls_configuration'
));
const VideoPreview = lazy(() => import(
  /* webpackChunkName: "videos" */ '~/components/pages/videos/preview'
));
const Inbox = lazy(() => import(
  /* webpackChunkName: "inbox" */ '~/pages/Inbox/index'
));
const DocumentPreview = lazy(() => import(
  /* webpackChunkName: "documents" */ '~/components/pages/documents/preview'
));

const isDmsEnabled = checkFeatureFlag(process.env.ENABLE_DOCUMENT_MANAGEMENT_FLAG);

// TODO: [Teefan] AppRouter could be improved?
// https://reacttraining.com/react-router/web/example/route-config
const AppRouter = () => (
  <Router history={browserHistory}>
    <Suspense fallback={<div>Loading...</div>}>
      <div className="router-root">
        {/* Recruiting routes */}
        <Route exact path="/recruiter/today" component={AgentDashboard} />
        <Route exact path="/recruiter/leads" component={AgentDashboard} />
        <Route exact path="/recruiter/backlog" component={AgentDashboard} />
        <Route exact path="/recruiter/tasks" component={AgentDashboard} />
        <Route exact path="/recruiter/tasks_due_today" component={AgentDashboard} />
        <Route exact path="/recruiter/tasks_due_later" component={AgentDashboard} />
        <Route exact path="/recruiter/tasks_overdue" component={AgentDashboard} />
        {(isDmsEnabled) && (
          <>
            <Route exact path="/recruiter/documents" component={AgentDashboard} />
            <Route path="/recruiter/documents/trash" component={AgentDashboard} />
          </>
        )}
        <Route exact path="/recruiter/search" component={AgentDashboard} />
        <Route exact path="/recruiter/reports" component={AgentDashboard} />
        <Route exact path="/recruiter/inbox" component={Inbox} />

        <Route exact path="/recruiter/campaigns" component={ManageCampaigns} />
        <Route exact path="/recruiter/campaigns/new" component={EditCampaign} />
        <Route exact path="/recruiter/campaigns/:id/edit" component={EditCampaign} />
        <Route exact path="/recruiter/campaign_libraries" component={ManageCampaignsLibrary} />
        <Route exact path="/recruiter/campaign_libraries/:id" component={PreviewCampaignsLibrary} />
        <Route exact path="/recruiter/campaign_libraries/:id/edit" component={EditCampaignLibrary} />
        <Route exact path="/recruiter/campaign_triggers" component={ManageCampaignTriggers} />
        <Route exact path="/recruiter/campaign_triggers/new" component={NewOrEditCampaignTrigger} />
        <Route exact path="/recruiter/campaign_triggers/:id/edit" component={NewOrEditCampaignTrigger} />
        <Route exact path="/recruiter/team/landing_page/edit" component={LandingPageSetting} />
        <Route exact path="/recruiter/labels" component={ManageLabels} />
        <Route exact path="/recruiter/data" component={ManageData} />
        <Route exact path="/recruiter/search_queries/new" component={NewOrEditSearchQuery} />
        <Route exact path="/recruiter/search_queries/:id/edit" component={NewOrEditSearchQuery} />
        <Route exact path="/recruiter/configure_mls/:id" component={ManageMlsConfiguration} />

        {/* Retention routes */}
        <Route exact path="/retention/today" component={AgentDashboard} />
        <Route exact path="/retention/team" component={AgentDashboard} />
        <Route exact path="/retention/tasks" component={AgentDashboard} />
        <Route exact path="/retention/tasks_due_today" component={AgentDashboard} />
        <Route exact path="/retention/tasks_due_later" component={AgentDashboard} />
        <Route exact path="/retention/tasks_overdue" component={AgentDashboard} />
        {(isDmsEnabled) && (
          <>
            <Route exact path="/retention/documents" component={AgentDashboard} />
            <Route path="/retention/documents/trash" component={AgentDashboard} />
          </>
        )}
        <Route exact path="/retention/backlog" component={AgentDashboard} />
        <Route exact path="/retention/search" component={AgentDashboard} />
        <Route exact path="/retention/reports" component={AgentDashboard} />

        <Route exact path="/retention/campaigns" component={ManageCampaigns} />
        <Route exact path="/retention/campaigns/new" component={EditCampaign} />
        <Route exact path="/retention/campaigns/:id/edit" component={EditCampaign} />
        <Route exact path="/retention/campaign_libraries" component={ManageCampaignsLibrary} />
        <Route exact path="/retention/campaign_libraries/:id" component={PreviewCampaignsLibrary} />
        <Route exact path="/retention/campaign_libraries/:id/edit" component={EditCampaignLibrary} />
        <Route exact path="/retention/campaign_triggers" component={ManageCampaignTriggers} />
        <Route exact path="/retention/campaign_triggers/new" component={NewOrEditCampaignTrigger} />
        <Route exact path="/retention/campaign_triggers/:id/edit" component={NewOrEditCampaignTrigger} />
        <Route exact path="/retention/labels" component={ManageLabels} />
        <Route exact path="/retention/data" component={ManageData} />
        <Route exact path="/retention/search_queries/new" component={NewOrEditSearchQuery} />
        <Route exact path="/retention/search_queries/:id/edit" component={NewOrEditSearchQuery} />
        <Route exact path="/retention/configure_mls/:id" component={ManageMlsConfiguration} />
        <Route exact path="/retention/inbox" component={Inbox} />

        {/* Other routes */}
        <Route exact path="/team/landing_page/edit" component={LandingPageAccountSetting} />

        <Route exact path="/agent/dashboard" component={AgentDashboard} />
        <Route path="/archived/search" component={AgentDashboard} />
        <Route exact path="/archived" component={AgentDashboard} />

        <Route exact path="/r/:code" component={PublicReport} />
        <Route exact path="/onboard/referrers" component={OnboardApp} />

        <Route exact path="/users/edit" component={UserSettings} />
        <Route exact path="/users/phone_number/edit" component={PhoneNumberSetting} />
        <Route exact path="/team/edit" component={TeamSettings} />
        <Route exact path="/team/members" component={TeamMembers} />

        <Route exact path="/campaigns" component={ManageCampaigns} />
        <Route exact path="/campaigns/new" component={EditCampaign} />
        <Route exact path="/campaigns/:id/edit" component={EditCampaign} />

        <Route exact path="/campaign_libraries" component={ManageCampaignsLibrary} />
        <Route exact path="/campaign_libraries/:id" component={PreviewCampaignsLibrary} />
        <Route exact path="/campaign_libraries/:id/edit" component={EditCampaignLibrary} />

        <Route exact path="/labels" component={ManageLabels} />

        <Route exact path="/campaign_triggers" component={ManageCampaignTriggers} />
        <Route exact path="/campaign_triggers/new" component={NewOrEditCampaignTrigger} />
        <Route exact path="/campaign_triggers/:id/edit" component={NewOrEditCampaignTrigger} />

        <Route exact path="/data" component={ManageData} />

        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/register" component={Register} />

        <Route exact path="/search_queries/new" component={NewOrEditSearchQuery} />
        <Route exact path="/search_queries/:id/edit" component={NewOrEditSearchQuery} />

        <Route exact path="/configure_mls/:id" component={ManageMlsConfiguration} />
        <Route exact path="/video/:id" component={VideoPreview} />
        {isDmsEnabled && (
          <Route exact path="/documents/:id" component={DocumentPreview} /> 
        )}
        <Route exact path="/d/:id" component={DocumentPreview} />
      </div>
    </Suspense>
  </Router>
);

export default AppRouter;
