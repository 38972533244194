const ReportActionTypes = {
  LOAD_REPORT:           'LOAD_REPORT',
  LOAD_REPORT_DONE:      'LOAD_REPORT_DONE',
  LOAD_REPORT_FAIL:      'LOAD_REPORT_FAIL',

  LOAD_JOIN_REPORT:      'LOAD_JOIN_REPORT',
  LOAD_JOIN_REPORT_DONE: 'LOAD_JOIN_REPORT_DONE',
  LOAD_JOIN_REPORT_FAIL: 'LOAD_JOIN_REPORT_FAIL',

  LOAD_ACTIVITY:      'LOAD_ACTIVITY',
  LOAD_ACTIVITY_DONE: 'LOAD_ACTIVITY_DONE',
  LOAD_ACTIVITY_FAIL: 'LOAD_ACTIVITY_FAIL',
};

export default ReportActionTypes;
