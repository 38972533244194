import { ReduceStore }       from 'flux/utils';
import AppDispatcher         from '~/dispatchers/app_dispatcher';
import ReportActionTypes     from '~/types/report_action_types';
import LeadDrawerActionTypes from '~/types/lead_drawer_action_types';

class ReportStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingReport:     false,
      loadingActivity:   false,
      recordGroups:      [],
      summaryCount:      null,
      summaryYTD:        null,
      reportStoreAction: null,
      activityData:      [],
    };
  }

  loadReport(state, action) {
    const newState = { ...state };

    newState.loadingReport = true;

    return newState;
  }

  loadReportDone(state, action) {
    const newState = { ...state };
    newState.loadingReport = false;
    newState.recordGroups = action.recordGroups;
    newState.summaryCount = action.summaryCount;
    newState.summaryYTD = action.summaryYTD;
    newState.reportStoreAction = 'loadReportDone';

    return newState;
  }

  loadReportFail(state, action) {
    const newState = { ...state };

    newState.loadingReport = false;
    newState.reportStoreAction = 'loadReportFail';

    return newState;
  }

  loadJoinReport(state, action) {
    const newState = { ...state };

    newState.loadingJoinReport = true;

    return newState;
  }

  loadJoinReportDone(state, action) {
    const newState = { ...state };

    newState.loadingJoinReport = false;
    newState.recordGroups = { ...newState.recordGroups, ...action.recordGroups };
    newState.reportStoreAction = 'loadJoinReportDone';

    return newState;
  }

  loadJoinReportFail(state, action) {
    const newState = { ...state };

    newState.loadingJoinReport = false;
    newState.reportStoreAction = 'loadJoinReportFail';

    return newState;
  }

  updateLeadDone(state, action) {
    const newState = { ...state };

    const { lead } = action;
    if (!lead.rating) return state;

    const records = newState.recordGroups[lead.rating];
    if (!records) return state;

    let recordsLen = records.length;
    // TODO: is there any performance problem?
    while (recordsLen--) { // eslint-disable-line
      const item = records[recordsLen];
      if (item && item.id === lead.id) {
        newState.recordGroups[lead.rating][recordsLen] = lead;

        break;
      }
    }

    return newState;
  }

  loadActivity(state, action) {
    const newState = { ...state };

    newState.loadingActivity = true;
    newState.activityData = action.activityData;
    newState.lastPage =  action.lastPage;
    newState.currentPage =  action.currentPage;

    return newState;
  }

  loadActivityDone(state, action) {
    const newState = { ...state };
    newState.loadingActivity = false;
    newState.activityData =  action.activityData;
    newState.lastPage =  action.lastPage;
    newState.currentPage =  action.currentPage;
    newState.reportStoreAction = 'loadActivityDone';

    return newState;
  }

  loadActivityFail(state, action) {
    const newState = { ...state };

    newState.loadingActivity = false;
    newState.reportStoreAction = 'loadActivityFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case ReportActionTypes.LOAD_REPORT:
        return this.loadReport(state, action);
      case ReportActionTypes.LOAD_REPORT_DONE:
        return this.loadReportDone(state, action);
      case ReportActionTypes.LOAD_REPORT_FAIL:
        return this.loadReportFail(state, action);
      case ReportActionTypes.LOAD_JOIN_REPORT:
        return this.loadJoinReport(state, action);
      case ReportActionTypes.LOAD_JOIN_REPORT_DONE:
        return this.loadJoinReportDone(state, action);
      case ReportActionTypes.LOAD_JOIN_REPORT_FAIL:
        return this.loadJoinReportFail(state, action);
      case LeadDrawerActionTypes.UPDATE_LEAD_DONE:
        return this.updateLeadDone(state, action);
      case ReportActionTypes.LOAD_ACTIVITY:
        return this.loadActivity(state, action);
      case ReportActionTypes.LOAD_ACTIVITY_DONE:
        return this.loadActivityDone(state, action);
      case ReportActionTypes.LOAD_ACTIVITY_FAIL:
        return this.loadActivityFail(state, action);
      default:
        return state;
    }
  }
}

export default new ReportStore();
