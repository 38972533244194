import autoBind       from 'react-autobind';
import React          from 'react';
import ReactDOM       from 'react-dom';
import { PropTypes }  from 'prop-types';
import { withRouter } from 'react-router-dom';

import brokerbit           from '~/lib/brokerbit';
import GlobalContainer     from '~/components/global_container';
import DonateCampaignModal from '~/components/modals/donate_campaign_modal';
import CampaignItem        from './campaign_item';

class CampaignsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleCampaignClick(campaign) {
    const { history } = this.props;
    const campaign_edit_uri = GlobalContainer.productUri(`/campaigns/${campaign.id}/edit`);
    history.push(campaign_edit_uri);
  }

  handleCampaignDelete(campaign) {
    const { deleteCampaign } = this.props;

    if (_lodash.isEmpty(campaign)) return;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this campaign?',
      callback: (ok) => {
        if (ok) {
          deleteCampaign(campaign);
        }
      },
    });
  }

  handleCampaignDonate(campaign) {
    if (_lodash.isEmpty(campaign)) return;

    ReactDOM.render(
      <DonateCampaignModal campaignId={campaign.id} />,
      document.getElementById('secondary-modal'),
    );
  }

  renderCampaignRows() {
    const { campaigns } = this.props;

    const campaignRows = [];
    campaigns.forEach((campaign, idx) => {
      campaignRows.push(
        <CampaignItem
          key={`campaign-${campaign.id}`}
          campaign={campaign}
          handleCampaignClick={this.handleCampaignClick}
          handleCampaignDelete={this.handleCampaignDelete}
          handleCampaignDonate={this.handleCampaignDonate}
        />,
      );
    });

    return campaignRows;
  }

  render() {
    return (
      <table className="table table-hover table-responsive">
        <tbody>{this.renderCampaignRows()}</tbody>
      </table>
    );
  }
}

CampaignsTable.defaultProps = {
  loading:        false,
  campaigns:      [],
  deleteCampaign: () => false,
};

CampaignsTable.propTypes = {
  history:        PropTypes.shape({}).isRequired,
  loading:        PropTypes.bool,
  campaigns:      PropTypes.arrayOf(PropTypes.shape({})),
  deleteCampaign: PropTypes.func,
};

export default withRouter(CampaignsTable);
