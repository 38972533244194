import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from '~/components/forms/DateTimePicker';
import { TeammatePicker } from '~/components/forms/team_member_picker';
import DocumentActions from '~/actions/document_actions';
import DocumentStore from '~/stores/document_store';

const processPickerValue = (value) => {
  if (!value || value.length === 0) return null;
  return value;
};

const DocumentSearchFilters = ({
  searchData,
  clearSearch,
  hideFilters,
  handleSingleSelectChange,
  handleDateSelectChange,
  handleMultiSelectChange,
}) => {
  const [categories, setCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const documentStoreListener = DocumentStore.addListener(() => {
      const { categories, statuses, lastDocumentStoreAction } = DocumentStore.getState();

      if (lastDocumentStoreAction === 'loadCategoriesDone') {
        setCategories(categories);
      }

      if (lastDocumentStoreAction === 'loadStatusesDone') {
        setStatuses(statuses);
      }
    });

    DocumentActions.loadCategories();
    DocumentActions.loadStatuses();

    return () => {
      documentStoreListener.remove();
    };
  }, []);

  const uploaded_at = processPickerValue(searchData.uploaded_at);

  return (
    <div>
      <div className="form-group mb15">
        <a href="#hide-filters" onClick={hideFilters}>Hide advanced options</a>
      </div>

      <div className="row">
        <div className="col-md-3 mb15">
          <label htmlFor="uploaded_at">Created</label>
          <div className="form-group mr5">
            <DateTimePicker
              id="uploaded_at"
              placeholder="Select Date"
              showClear
              format="MMMM D, YYYY"
              pickerType="date"
              value={Moment(uploaded_at)?.format('MMMM D, YYYY')}
              onChange={(date) => {
                handleDateSelectChange('uploaded_at', date);
              }}
            />
          </div>
        </div>
        <div className="col-md-3 mb15">
          <label htmlFor="document_owner_id">Owner</label>
          <TeammatePicker
            id="document_owner_id"
            name="owner_id"
            placeholder="Owner"
            value={processPickerValue(searchData.owner_id)}
            onChange={(selectedOptions) => {
              handleMultiSelectChange('owner_id', selectedOptions);
            }}
            multi
            blank
            clearable
            apiResource={'/v1/documents/owner_options?only_existing=true'}
          />
        </div>

        <div className="col-md-3 mb15">
          <label htmlFor="document_status">Status</label>
          <select
            id="document_status"
            name="status"
            placeholder="Select Status"
            value={processPickerValue(searchData.status)}
            onChange={(e) => handleSingleSelectChange('status', e.target.value)}
            className="form-control"
          >
            <option value="">Select Status</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-3 mb15">
          <label htmlFor="input_category">Category</label>
          <select
            id="input_category"
            name="category"
            placeholder="Select Category"
            value={processPickerValue(searchData.category)}
            onChange={(e) => handleSingleSelectChange('category', e.target.value)}
            className="form-control"
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button type="button" className="btn btn-sm btn-secondary" onClick={() => { clearSearch(); }}>
            Clear search
          </button>
        </div>
      </div>
    </div>
  );
};

DocumentSearchFilters.defaultProps = {
  searchData:               {},
  clearSearch:              () => false,
  handleSingleSelectChange: () => false,
  handleDateSelectChange:   () => false,
  handleMultiSelectChange:  () => false,
};

DocumentSearchFilters.propTypes = {
  searchData:               PropTypes.shape({}),
  clearSearch:              PropTypes.func,
  hideFilters:              PropTypes.func.isRequired,
  handleSingleSelectChange: PropTypes.func,
  handleDateSelectChange:   PropTypes.func,
  handleMultiSelectChange:  PropTypes.func,
};

export default DocumentSearchFilters;
