const CampaignSubscriptionActionTypes = {
  LOAD_CAMPAIGN_SUBSCRIPTIONS:       'LOAD_CAMPAIGN_SUBSCRIPTIONS',
  LOAD_CAMPAIGN_SUBSCRIPTIONS_DONE:  'LOAD_CAMPAIGN_SUBSCRIPTIONS_DONE',
  LOAD_CAMPAIGN_SUBSCRIPTIONS_FAIL:  'LOAD_CAMPAIGN_SUBSCRIPTIONS_FAIL',

  CREATE_CAMPAIGN_SUBSCRIPTION:      'CREATE_CAMPAIGN_SUBSCRIPTION',
  CREATE_CAMPAIGN_SUBSCRIPTION_DONE: 'CREATE_CAMPAIGN_SUBSCRIPTION_DONE',
  CREATE_CAMPAIGN_SUBSCRIPTION_FAIL: 'CREATE_CAMPAIGN_SUBSCRIPTION_FAIL',

  UPDATE_CAMPAIGN_SUBSCRIPTION:      'UPDATE_CAMPAIGN_SUBSCRIPTION',
  UPDATE_CAMPAIGN_SUBSCRIPTION_DONE: 'UPDATE_CAMPAIGN_SUBSCRIPTION_DONE',
  UPDATE_CAMPAIGN_SUBSCRIPTION_FAIL: 'UPDATE_CAMPAIGN_SUBSCRIPTION_FAIL',

  DELETE_CAMPAIGN_SUBSCRIPTION:      'DELETE_CAMPAIGN_SUBSCRIPTION',
  DELETE_CAMPAIGN_SUBSCRIPTION_DONE: 'DELETE_CAMPAIGN_SUBSCRIPTION_DONE',
  DELETE_CAMPAIGN_SUBSCRIPTION_FAIL: 'DELETE_CAMPAIGN_SUBSCRIPTION_FAIL',
};

export default CampaignSubscriptionActionTypes;
