import autoBind  from 'react-autobind';
import React     from 'react';
import PropTypes from 'prop-types';

import Select from '~/components/forms/select';

class RoleSelect extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onChange(opt) {
    this.props.onChange(opt);
  }

  getOptions() {
    const options = [
      { value: 'agent',     label: 'Agent' },
      { value: 'affiliate', label: 'Affiliate' },
    ];

    return options;
  }

  render() {
    return (
      <Select
        {...this.props}
        ref={(el) => (this.select = el)}
        onChange={this.onChange}
        options={this.getOptions()}
      />
    );
  }
}

RoleSelect.defaultProps = {
  name:        '',
  value:       '',
  placeholder: 'Select roles',
  onChange:    () => false,
};

RoleSelect.propTypes = {
  name:        PropTypes.string,
  placeholder: PropTypes.string,
  value:       PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange:    PropTypes.func,
};

export default RoleSelect;
