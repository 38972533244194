import autoBind from 'react-autobind';
import React    from 'react';

import BlankPage               from '~/components/pages/blank_page';
import FrontSideBar            from  '~/components/navigation/front_side_bar';
import ListingCampaignsLibrary from './manage_campaigns_library/listing_campaigns_lib';

class ManageCampaignsLibrary extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { currentTeam } = Rails.helpers;
    if (!currentTeam) return null;

    return (
      <BlankPage title="Campaign Library - Brokerkit">
        <div className="row mb15">
          <FrontSideBar />

          <div className="right-side-page-md">
            <ListingCampaignsLibrary />
          </div>
        </div>
      </BlankPage>
    );
  }
}

export default ManageCampaignsLibrary;
