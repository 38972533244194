import autoBind            from 'react-autobind';
import React               from 'react';
import classNames          from 'classnames';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                     from '~/lib/brokerbit';
import MultipleListingServicesSelect from '~/components/forms/multiple_listing_services_select';
import ErrorMessage                  from '~/components/forms/ErrorMessage';
import ClickableTooltip              from '~/components/effects/clickable_tooltip';

import TeamMlsActions from '~/actions/team_mls_actions';
import TeamMlsStore   from '~/stores/team_mls_store';
import MlsActions     from '~/actions/mls_actions';
import MlsStore       from '~/stores/mls_store';

class MlsSettingsTab extends React.Component {
  constructor(props) {
    super(props);

    this.emptyMultipleListingService = {
      id:                 null,
      mlses_ouid:         '',
      primary:            false,
      integration_status: false,
      _destroy:           false,
    };

    this.state = {
      team_mlses: [],
      errors:     {},
      updating:   false,
      loading:    false,
      mlses:      [],
    };

    autoBind(this);
  }

  componentDidMount() {
    // listen to TeamMlsStore change events and store reference
    this.listener = TeamMlsStore.addListener(
      this.onTeamMlsStoreChange,
    );
    this.mlsStoreListener = MlsStore.addListener(
      this.onMlsStoreChange,
    );

    MlsActions.loadMlsOptions();
    TeamMlsActions.loadTeamMlses();
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
    if (this.mlsStoreListener) this.mlsStoreListener.remove();
  }

  handleMlsOuidChange(val, mls) {
    mls.mlses_ouid = val;

    this.setState({ team_mlses: this.state.team_mlses });
  }

  handlePrimaryMlsChange(e, mls) {
    mls.primary = e.target.checked;

    this.setState({ team_mlses: this.state.team_mlses });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { team_mlses } = this.state;

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      const teamData = {
        id: Rails.helpers.currentTeam.id,
        team_mlses,
      };

      TeamMlsActions.updateTeamMlses(teamData);
    } else {
      this.setState({ errors });
    }
  }

  handleMultipleListingServiceDelete(e, mls) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this MLS?',
      callback: (ok) => {
        if (ok) {
          mls._destroy = true;
          this.setState({ team_mlses: this.state.team_mlses });
        }
      },
    });
  }

  handleMlsConfiguration(e, mls) {
    e.preventDefault();

    const uri = GlobalContainer.productUri(
      `/configure_mls/${mls.id}`,
    );

    browserHistory.push(uri);
  }

  onTeamMlsStoreChange() {
    const mlsState = TeamMlsStore.getState();
    const {
      loading,
      team_mlses,
      updating,
      lastTeamMlsStoreAction,
      errors,
    } = mlsState;

    const newState = {
      loading,
      team_mlses,
      updating,
      errors,
    };

    if (lastTeamMlsStoreAction === 'updateTeamMlsesDone') {
      GlobalContainer.notify(
        'Your Multiple Listing Services were saved.',
      );
    }

    this.setState(newState);
  }

  onMlsStoreChange() {
    const mlsStoreState = MlsStore.getState();
    const { options } = mlsStoreState;

    this.setState({
      mlses: options,
    });
  }

  validate() {
    const errors = {};
    const { team_mlses } = this.state;
    const activeMlses = team_mlses.filter((x, i) => !x._destroy);
    const checkedCount = activeMlses.filter(
      (x, i) => x.primary,
    ).length;

    if (activeMlses.length > 0 && checkedCount < 1) {
      errors.mls = ['Please select primary MLS'];
    }

    if (activeMlses.length > 0 && checkedCount > 1) {
      errors.mls = ['Please select only one primary MLS'];
    }

    team_mlses.forEach((mls, index) => {
      if (mls._destroy) return;

      if (!mls.mlses_ouid) {
        errors[index] = { mlses_ouid: "Can't be empty" };
      }
    });

    return errors;
  }

  addMultipleListingService(e) {
    e.preventDefault();

    const { team_mlses } = this.state;

    team_mlses.push({ ...this.emptyMultipleListingService });

    this.setState({ team_mlses });
  }

  renderIntegrationStatus(enabled) {
    let status;

    if (enabled) {
      status = (
        <FontAwesomeIcon
          icon={['far', 'fa-check-circle']}
          className="fa-lg text-success"
        />
      );
    } else {
      status = (
        <FontAwesomeIcon
          icon={['far', 'fa-exclamation-circle']}
          className="fa-lg text-grey"
        />
      );
    }

    return status;
  }

  renderMultipleListingServices() {
    const { team_mlses, errors, mlses } = this.state;
    const { mlsImportFeatureEnabled } = Rails.helpers;
    const checkedCount = team_mlses.filter(
      (x, i) => x.primary && x._destroy === false,
    ).length;
    const disablePrimaryCheckbox = checkedCount >= 1;

    return team_mlses.map((mls, index) => {
      const url = GlobalContainer.productUri(
        `/configure_mls/${mls.id}`,
      );

      if (mls._destroy === false) {
        const Mls = mlses.find(
          (item) => item.ouid === mls.mlses_ouid,
        );

        const mlsDOM = (
          <React.Fragment key={index}>
            <div className="row my-2">
              <div className="col-12 col-lg-7 my-1 text-center">
                <MultipleListingServicesSelect
                  value={mls.mlses_ouid}
                  placeholder="--- Select a MLS ---"
                  onChange={(val) => this.handleMlsOuidChange(val, mls)}
                  className={classNames({
                    'has-error': errors[index]
                      ? errors[index].mlses_ouid
                      : '',
                  })}
                  clearable
                />

                {mls.api_key && Mls?.preferred_phone_setting && !mls.preferred_phone && (
                  <div className="alert alert-warning mt-2">
                    You selected an MLS which only provides Preferred
                    Phone without indicating phone type, so we recommend
                    you activate the Preferred Phone mapping for the
                    Relitix integration
                    {' '}
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                  </div>
                )}
              </div>

              <div className="col my-1 my-auto offset-1">
                <div className="row">
                  {mlsImportFeatureEnabled && (
                    <div className="col-3 text-center my-auto p0">
                      {this.renderIntegrationStatus(
                        mls.integration_status,
                      )}
                    </div>
                  )}

                  <div className="col-3 text-center my-auto p0">
                    <div className="form-check">
                      <input
                        key={uuidv4()}
                        className="form-check-input"
                        type="checkbox"
                        id={`primary_${index}`}
                        onChange={(e) => this.handlePrimaryMlsChange(e, mls)}
                        checked={mls.primary || false}
                        disabled={
                          !mls.primary && disablePrimaryCheckbox
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`primary_${index}`}
                      />
                    </div>
                  </div>

                  <div className="col my-auto">
                    {mls.id && mlsImportFeatureEnabled && (
                      <>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm mr5"
                          id={`configure-mls-${index}`}
                          onClick={(e) => {
                            this.handleMlsConfiguration(e, mls);
                          }}
                        >
                          <FontAwesomeIcon icon={['far', 'fa-cog']} />
                        </button>
                        <ClickableTooltip
                          text="Configure MLS Integration"
                          target={`configure-mls-${index}`}
                          placement="bottom"
                        />
                      </>
                    )}

                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={(e) => this.handleMultipleListingServiceDelete(
                        e,
                        mls,
                      )}
                      id={`deletes-mls-${index}`}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'fa-trash-alt']}
                      />
                    </button>
                    <ClickableTooltip
                      text="Delete MLS Integration"
                      target={`deletes-mls-${index}`}
                      placement="bottom"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </React.Fragment>
        );

        return mlsDOM;
      }
    });
  }

  render() {
    const { errors, updating, loading } = this.state;
    const { mlsImportFeatureEnabled } = Rails.helpers;
    const submitButtonClass = classNames('btn btn-primary', {
      disabled: updating,
    });

    if (loading) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    return (
      <form onSubmit={this.handleFormSubmit}>
        <div className="multiple-listing-services">
          <div className="row">
            <div className="col my-auto">
              <h5>Multiple Listing Services for Account</h5>
            </div>
            <div className="col">
              <a
                href="#team_mls"
                className="btn btn-secondary pull-right"
                onClick={this.addMultipleListingService}
              >
                + Add MLS
              </a>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-12 col-lg-7 my-1 text-center align-self-center">
              MLS
            </div>
            <div className="col my-1 my-auto offset-1">
              <div className="row">
                {mlsImportFeatureEnabled && (
                  <div className="col-3 my-1 text-center align-self-center p0">
                    Integration Active
                  </div>
                )}
                <div className="col-3 my-1 text-center align-self-center p0">
                  Primary
                </div>
                <div className="col my-1" />
              </div>
            </div>
          </div>

          {this.renderMultipleListingServices()}

          <div>
            {errors.mls && <ErrorMessage message={errors.mls} />}
          </div>
        </div>

        <div className="form-group mb-3">
          <button
            type="submit"
            className={submitButtonClass}
            disabled={updating}
          >
            Update MLS
          </button>
        </div>
      </form>
    );
  }
}

export default MlsSettingsTab;
