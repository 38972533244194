import autoBind            from 'react-autobind';
import React               from 'react';
import classNames          from 'classnames';
import { PropTypes }       from 'prop-types';
import PhoneInput          from 'react-phone-input-2';
import { Input }           from 'reactstrap';
import isEmail             from 'validator/lib/isEmail';
import isMobilePhone       from 'validator/lib/isMobilePhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                     from '~/lib/brokerbit';
import DuplicateContactCard          from '~/components/duplicate_contact_card';
import StatusSelect                  from '~/components/forms/dropdowns/StatusSelect';
import Drawer                        from '~/components/drawers/drawer';
import CurrencyInput                 from '~/components/forms/currency_input';
import MultipleListingServicesSelect from '~/components/forms/multiple_listing_services_select';
import Select                        from '~/components/forms/select';
import LeadDrawerActions             from '~/actions/lead_drawer_actions';
import LeadDrawerStore               from '~/stores/lead_drawer_store';
import LeadHelpers                   from '~/helpers/lead_helpers';
import ErrorMessage                  from '~/components/forms/ErrorMessage';
import AllowOrgDuplicateCheckBox     from '~/components/forms/allow_org_duplicate_checkbox';
import { initWebSpellChecker }       from '~/lib/web_spell_checker';

import {
  TeamMemberPicker,
  TeammatePicker,
} from '~/components/forms/team_member_picker';
import {
  ProductionLabelSelect,
  RatingSelect,
  SourceSelect,
} from '~/components/forms/lead_fields';
import ErrorMessageWithIcon from '~/components/forms/ErrorMessageWithIcon';

let leadStoreListener;
let approvedForm = false;

const defaultEmailTypeOptions = [
  { value: 'office',   label: 'Office' },
  { value: 'personal', label: 'Personal' },
  { value: 'other',    label: 'Other' },
];

const { currentTeam } = Rails.helpers;
const teamID = currentTeam ? currentTeam.id : null;

class NewLeadDrawer extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const { role } = Rails.helpers.currentUser;

    this.emptyEmail = {
      id:         null,
      email:      '',
      email_type: 'office',
      main:       false,
      team_id:    teamID,
      _destroy:   false,
    };

    this.emptyAgentID = {
      id:                            null,
      agent_id:                      '',
      multiple_listing_service_ouid: '',
      _destroy:                      false,
    };

    this.state = {
      lead: {
        first_name:  '',
        last_name:   '',
        phone:       '',
        agent_id:    '',
        owner_id:    Rails.helpers.currentUser.id,
        referrer_id: role.internal
          ? ''
          : Rails.helpers.currentUser.id,
        production_ytd:          '',
        production_label:        '',
        currently_at:            '',
        joined_at:               '',
        note:                    '',
        production_ltm:          '',
        source:                  role.internal ? '' : 'Referral',
        rating:                  '',
        status:                  'new_lead',
        allow_duplicates_in_org: false,
        lead_emails_attributes:  [
          {
            id:         null,
            email:      '',
            email_type: 'office',
            main:       true,
            team_id:    teamID,
            _destroy:   false,
          },
        ],
        lead_agent_ids_attributes: [
          {
            id:                            null,
            agent_id:                      '',
            multiple_listing_service_ouid: '',
            _destroy:                      false,
          },
        ],
      },
      duplicates_found_in_org: false,
      duplicate_org_accounts:  [],
      errors:                  {},
      warnings:                {},
    };

    this.notesRef = React.createRef(null);
  }

  componentDidMount() {
    // listen to LeadDrawerStore changes
    leadStoreListener = LeadDrawerStore.addListener(this.onChange);
    // initialize spell checker for notes
    this.initializeSpellCheckerForNotes();
  }

  componentWillUnmount() {
    // remove listener to LeadDrawerStore changes on Unmount
    if (leadStoreListener) leadStoreListener.remove();
  }

  initializeSpellCheckerForNotes() {
    const ele = this.notesRef.current;
    if (ele) initWebSpellChecker(ele);
  }

  handleAgentIdChange(e, leadAgentId) {
    e.preventDefault();

    leadAgentId.agent_id = e.target.value;

    this.setState({ lead: this.state.lead });
  }

  handleMultipleListingServiceChange(value, leadAgentId) {
    leadAgentId.multiple_listing_service_ouid = value;

    this.setState({ lead: this.state.lead });
  }

  handleEmailChange(e, leadEmail) {
    e.preventDefault();

    leadEmail.email = e.target.value;

    this.setState({ lead: this.state.lead });
  }

  handleEmailTypeChange(opt, leadEmail) {
    leadEmail.email_type = opt ? opt.value : '';

    this.setState({ lead: this.state.lead });
  }

  handleMainChange(e, leadEmail) {
    leadEmail.main = e.target.checked;

    this.setState({ lead: this.state.lead });
  }

  handleAllowDuplicatesChange(value) {
    const { lead } = this.state;
    lead.allow_duplicates_in_org = value;
    this.setState({ lead });
  }

  handleLeadEmailDelete(e, leadEmail) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this email?',
      callback: (ok) => {
        if (ok) {
          leadEmail._destroy = true;
          this.setState({ lead: this.state.lead });
        }
      },
    });
  }

  handleLeadAgentIdDelete(e, leadAgentId) {
    e.preventDefault();

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this Agent ID?',
      callback: (ok) => {
        if (ok) {
          leadAgentId._destroy = true;
          this.setState({ lead: this.state.lead });
        }
      },
    });
  }

  // when LeadDrawerStore changes...
  onChange() {
    const emailErrors = {};
    const agentIDErrors = {};
    const leadDrawerState = LeadDrawerStore.getState();
    const { lead, leadDrawerStoreAction, errors } = leadDrawerState;
    const { duplicate_org_accounts } = this.state;
    const { helpers } = this.context;
    let duplicates_found_in_org = false;

    if (leadDrawerStoreAction === 'createLeadDone') {
      helpers.openLeadDrawer({ lead });
    }

    if (leadDrawerStoreAction === 'createLeadFail') {
      if (errors.duplicate_emails) {
        errors.duplicate_emails.forEach((e, i) => {
          if (e) {
            if (e.context === 'org') {
              duplicate_org_accounts.push(`${e.match.name} - Email: ${e.value}`);
              duplicates_found_in_org = true;
            }

            emailErrors[i] = {
              duplicate: 'It looks like a contact with this email already exists.',
              context:   e.context,
              match:     e.match,
            };
          }
        });
      }

      if (errors.duplicate_agent_ids) {
        errors.duplicate_agent_ids.forEach((e, i) => {
          if (e) {
            if (e.context === 'org') {
              duplicate_org_accounts.push(`${e.match.name} - Agent ID/MLS: ${e.value}`);
              duplicates_found_in_org = true;
            }

            agentIDErrors[i] = {
              duplicate: 'It looks like this agent id already exists.',
              context:   e.context,
              match:     e.match,
            };
          }
        });
      }

      this.setState({
        errors:     emailErrors,
        warnings:   agentIDErrors,
        submitting: false,
        duplicate_org_accounts,
        duplicates_found_in_org,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { lead } = this.state;
    const leadEmailAttributes = lead.lead_emails_attributes.filter((x, i) => !x._destroy);
    const leadAgentIdAttributes = lead.lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    this.setState({
      submitting: true, duplicate_org_accounts: [], duplicates_found_in_org: false, errors: {}, warnings: {},
    });

    if (this.validate() && !approvedForm) {
      leadEmailAttributes.forEach((leadEmail) => {
        if (!leadEmail.email) {
          leadEmail._destroy = true;
        }
      });

      leadAgentIdAttributes.forEach((leadAgentId) => {
        if (!leadAgentId.agent_id) {
          leadAgentId.multiple_listing_service_ouid = '';
        }

        if (
          !leadAgentId.agent_id
          && !leadAgentId.multiple_listing_service_ouid
        ) {
          leadAgentId._destroy = true;
        }
      });

      LeadDrawerActions.createLead(lead);
    } else {
      this.setState({ submitting: false });
    }
  }

  onCancel(e) {
    e.preventDefault();

    const { helpers } = this.context;

    helpers.closeDrawer();
  }

  isLeadEmailValid(index) {
    const { errors } = this.state;

    return errors[index] || errors.no_email_agent_id
      ? errors.no_email_agent_id || errors[index].email || errors[index].duplicate
      : '';
  }

  isLeadAgentIdValid(index) {
    const { errors, warnings } = this.state;

    return warnings[index] || errors.no_email_agent_id
      ? errors.no_email_agent_id || warnings[index].duplicate
      : '';
  }

  addLeadEmail() {
    const { lead } = this.state;

    lead.lead_emails_attributes.push({ ...this.emptyEmail });

    this.setState({ lead });
  }

  addLeadAgentId() {
    const { lead } = this.state;

    lead.lead_agent_ids_attributes.push({ ...this.emptyAgentID });

    this.setState({ lead });
  }

  validate() {
    let all_emails_blank = true;
    let all_agent_ids_blank = true;

    const { lead } = this.state;

    const leadEmailAttributes = lead.lead_emails_attributes.filter(
      (x, i) => !x._destroy,
    );
    const checkedCount = leadEmailAttributes.filter(
      (x, i) => x.main,
    ).length;
    const leadAgentIdAttributes = lead.lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    const errors = {};
    const warnings = {};

    if (!lead.first_name) {
      errors.first_name = 'This field can not be empty';
    }

    if (!lead.last_name) {
      errors.last_name = 'This field can not be empty';
    }

    if (
      !_lodash.isEmpty(lead.phone)
      && !isMobilePhone(lead.phone, ['en-US'])
    ) {
      errors.phone = 'Is invalid';
    }

    if (leadEmailAttributes.length > 0 && checkedCount < 1) {
      errors.emails = ['Please select any main email'];
    }

    leadEmailAttributes.forEach((leadEmail, index) => {
      if (leadEmail.email) {
        all_emails_blank = false;

        if (!isEmail(leadEmail.email)) {
          errors[index] = { email: 'The provided email is invalid' };
        }
      }

      if (!leadEmail.email_type) {
        errors[index] = { email_type: "Can't be empty" };
      }
    });

    leadAgentIdAttributes.forEach((leadAgentId, index) => {
      if (leadAgentId.agent_id) {
        all_agent_ids_blank = false;
      }

      if (
        !!leadAgentId.agent_id
        && !leadAgentId.multiple_listing_service_ouid
      ) {
        warnings[index] = {
          multiple_listing_service_ouid:
            'It is recommended that you select the MLS for this agent ID.',
        };
      }
    });

    if ((leadEmailAttributes.length === 0 && leadAgentIdAttributes.length === 0) || (all_emails_blank && all_agent_ids_blank)) {
      errors.no_email_agent_id = ['Please add either an email address or agent ID to be able to create this record so that there is a way to match and update the data later.'];
    }

    if (_lodash.size(errors) === 0 && _lodash.size(warnings) !== 0) {
      approvedForm = !approvedForm;
    } else {
      approvedForm = false;
    }

    this.setState({ errors, warnings });

    return _lodash.size(errors) === 0;
  }

  renderLeadEmail() {
    const { lead, errors } = this.state;
    const leadEmailAttributes = lead.lead_emails_attributes.filter(
      (x, i) => !x._destroy,
    );
    const checkedCount = leadEmailAttributes.filter(
      (x, i) => x.main,
    ).length;
    const disableMainCheckbox = checkedCount >= 1;

    return leadEmailAttributes.map((leadEmail, index) => {
      const emailDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-4 col-lg-5">
            <Input
              className={classNames({ 'has-error': this.isLeadEmailValid(index) })}
              value={leadEmail.email}
              onChange={(e) => this.handleEmailChange(e, leadEmail)}
            />
          </div>

          <div className="col-4 col-lg-5 lead-emails">
            <Select
              value={leadEmail.email_type}
              options={defaultEmailTypeOptions}
              onChange={(opt) => this.handleEmailTypeChange(opt, leadEmail)}
              placeholder="--- Select ---"
              className={classNames({
                'has-error': errors[index]
                  ? errors[index].email_type
                  : '',
              })}
            />
          </div>

          <div className="col-auto">
            <div className="form-check">
              <input
                key={index}
                className="form-check-input"
                type="checkbox"
                id={`main_${index}`}
                onChange={(e) => this.handleMainChange(e, leadEmail)}
                checked={leadEmail.main || false}
                disabled={!leadEmail.main && disableMainCheckbox}
              />
              <label
                className="form-check-label"
                htmlFor={`main_${index}`}
              >
                Main
              </label>
            </div>
          </div>

          <div className="col-auto">
            <button type="button" className="btn btn-outline-danger btn-sm" onClick={(e) => this.handleLeadEmailDelete(e, leadEmail)}>
              <FontAwesomeIcon icon={['fas', 'fa-times']} />
            </button>
          </div>

          { errors[index] && errors[index].duplicate && (
            <div className="col-12">
              <ErrorMessageWithIcon message={[errors[index].duplicate]} />
              { errors[index].context === 'team' && <DuplicateContactCard duplicate={errors[index]} /> }
            </div>
          )}
          { errors[index] && errors[index].email && (
            <div className="col-12">
              <ErrorMessageWithIcon message={errors[index].email} />
            </div>
          )}

        </div>
      );

      return emailDOM;
    });
  }

  renderLeadAgentId() {
    const { lead, warnings } = this.state;
    const leadAgentIdAttributes = lead.lead_agent_ids_attributes.filter((x, i) => !x._destroy);

    return leadAgentIdAttributes.map((leadAgentId, index) => {
      const agentIdDOM = (
        <div className="form-row align-items-center mb-2" key={index}>
          <div className="col-5 col-lg-5">
            <Input
              className={classNames({ 'has-error': this.isLeadAgentIdValid(index) })}
              value={leadAgentId.agent_id}
              onChange={(e) => this.handleAgentIdChange(e, leadAgentId)}
            />
          </div>

          <div
            className="col-5 col-lg-6 lead-agent-ids"
            id={`mls-select-${index}`}
          >
            <MultipleListingServicesSelect
              value={leadAgentId.multiple_listing_service_ouid}
              placeholder="--- Select a MLS ---"
              onChange={(val) => this.handleMultipleListingServiceChange(
                val,
                leadAgentId,
              )}
              teamMlses
              primaryMls
              clearable
              target={`mls-select-${index}`}
            />
          </div>

          <div className="col-auto">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={(e) => this.handleLeadAgentIdDelete(e, leadAgentId)}
            >
              <FontAwesomeIcon icon={['fas', 'fa-times']} />
            </button>
          </div>

          { warnings[index] && warnings[index].multiple_listing_service_ouid && (
            <div className="col-12 text-warning mt-2">
              <FontAwesomeIcon
                icon={['fas', 'fa-exclamation-triangle']}
              />
              {' '}
              {warnings[index].multiple_listing_service_ouid}
            </div>
          )}

          { warnings[index] && warnings[index].duplicate && (
            <div className="col-12">
              <ErrorMessageWithIcon message={[warnings[index].duplicate]} />
              { warnings[index].context === 'team' && <DuplicateContactCard duplicate={warnings[index]} /> }
            </div>
          )}
        </div>
      );

      return agentIdDOM;
    });
  }

  render() {
    const {
      lead, errors, duplicates_found_in_org, duplicate_org_accounts, submitting,
    } = this.state;
    const saveButton = approvedForm ? 'Approve & Save' : 'Save';

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Drawer contentClass="bg-grey-lightest">
          <div className="modal-header bg-white bb">
            <h5 className="modal-title">Add Lead</h5>
          </div>

          <div className="modal-body">
            <div className="field-group mb15">
              <label htmlFor="first_name" className="d-block">
                First Name
              </label>
              <Input
                id="first_name"
                className={errors.first_name ? 'has-error' : ''}
                onChange={(v) => {
                  lead.first_name = v.target.value;
                }}
              />
              { errors.first_name && <ErrorMessageWithIcon message={[errors.first_name]} /> }
            </div>

            <div className="field-group mb15">
              <label htmlFor="last_name" className="d-block">
                Last Name
              </label>
              <Input
                id="last_name"
                className={errors.last_name ? 'has-error' : ''}
                onChange={(v) => {
                  lead.last_name = v.target.value;
                }}
              />
              { errors.last_name && <ErrorMessageWithIcon message={[errors.last_name]} /> }
            </div>

            <div className="field-group mb15">
              <label htmlFor="btn-lead-email" className="d-block">
                <a
                  href="#lead_email"
                  id="btn-lead-email"
                  className="pull-right"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addLeadEmail();
                  }}
                >
                  Add Email
                </a>
                Email
              </label>

              {this.renderLeadEmail()}

              { errors.emails && (
                <ErrorMessageWithIcon message={errors.emails} />
              )}

              { errors.no_email_agent_id && (
                <ErrorMessageWithIcon message={errors.no_email_agent_id} />
              )}

              { duplicates_found_in_org && (
                <AllowOrgDuplicateCheckBox
                  checked={lead.allow_duplicates_in_org}
                  duplicate_org_accounts={duplicate_org_accounts}
                  onChange={this.handleAllowDuplicatesChange}
                />
              )}
            </div>

            <div className="field-group mb15">
              <label htmlFor="phone-number" className="d-block">Cell Phone</label>
              <PhoneInput
                country="us"
                onlyCountries={['us']}
                disableCountryCode
                disableDropdown
                placeholder=""
                defaultMask="(...) ...-...."
                value={LeadHelpers.nationalFormatPhoneNumber(
                  lead.phone,
                )}
                onChange={(v) => {
                  lead.phone = v;
                }}
                specialLabel={false}
                inputProps={{
                  id:        'phone-number',
                  className: classNames('phone form-control', {
                    'has-error': !!errors.phone,
                  }),
                }}
              />
            </div>

            <div className="field-group mb15">
              <label htmlFor="btn-lead-agent-id" className="d-block">
                <a
                  href="#lead_agent_id"
                  id="btn-lead-agent-id"
                  className="pull-right"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addLeadAgentId();
                  }}
                >
                  Add Agent ID
                </a>
                Agent ID
              </label>

              {this.renderLeadAgentId()}
            </div>

            {GlobalContainer.product() === 'recruiting' && (
              <div className="field-group mb15">
                <label htmlFor="currently_at" className="d-block">
                  Currently At
                </label>
                <Input
                  id="currently_at"
                  onChange={(v) => {
                    lead.currently_at = v.target.value;
                  }}
                />
              </div>
            )}

            <div className="field-group mb15">
              <label htmlFor="production_ytd" className="d-block">
                Total $ Production LTM
              </label>

              <div className="form-group has-feedback mb5">
                <CurrencyInput
                  id="production_ytd"
                  name="production_ytd"
                  value={lead.production_ytd}
                  size={9}
                  onChange={(v) => {
                    lead.production_ytd = v;
                  }}
                />
              </div>
            </div>

            <div className="field-group mb15">
              <label htmlFor="production_ltm" className="d-block">
                Total # Production LTM
              </label>

              <div className="form-group has-feedback mb5">
                <Input
                  id="production_ltm"
                  onChange={(v) => {
                    lead.production_ltm = v.target.value;
                  }}
                />
              </div>
            </div>

            <div className="field-group mb15">
              <label htmlFor="production-label" className="d-block">Production Label</label>
              <ProductionLabelSelect
                id="production-label"
                value={lead.production_label}
                onChange={(opt) => {
                  lead.production_label = opt && opt.value;
                }}
              />
            </div>

            {Rails.abilities.manageLead && (
              <>
                <div className="field-group mb15">
                  <label htmlFor="source" className="d-block">Source</label>
                  <SourceSelect
                    id="source"
                    name="source"
                    clearable
                    value={lead.source}
                    onChange={(opt) => (lead.source = opt && opt.value)}
                  />
                </div>

                <div className="field-group mb15">
                  <label htmlFor="status" className="d-block">Status</label>
                  <StatusSelect
                    id="status"
                    value={lead.status}
                    stage="recruiting_with_shared"
                    onChange={(opt) => (lead.status = opt && opt.value)}
                    isInvalid={!!errors.status}
                    isSearchable
                  />

                  {errors.status && (
                    <ErrorMessage message={errors.status} />
                  )}
                </div>

                <div className="field-group mb15">
                  <label htmlFor="rating">Rating</label>
                  <RatingSelect
                    id="rating"
                    value={lead.rating}
                    placeholder="Select ratings"
                    onChange={(opt) => (lead.rating = opt && opt.value)}
                    clearable
                  />
                </div>
              </>
            )}

            {Rails.abilities.manageLead
              && GlobalContainer.product() === 'recruiting' && (
                <div className="field-group mb15">
                  <TeammatePicker
                    value={lead.owner_id}
                    label="Owner"
                    onChange={(opt) => {
                      lead.owner_id = opt && opt.value;
                    }}
                  />
                </div>
            )}

            {Rails.abilities.manageLead
              && GlobalContainer.product() === 'recruiting' && (
                <div className="field-group mb15">
                  <TeamMemberPicker
                    value={lead.referrer_id}
                    addable={{ title: 'Add Referrer' }}
                    label="Referrer"
                    onChange={(opt) => {
                      lead.referrer_id = opt && opt.value;
                    }}
                  />
                </div>
            )}

            <div className="field-group mb15">
              <label htmlFor="note" className="d-block">Notes</label>
              <Input
                id="notes"
                type="textarea"
                rows="8"
                onChange={(v) => {
                  lead.note = v.target.value;
                }}
                innerRef={this.notesRef}
              />
            </div>
          </div>

          <div className="modal-footer bg-white bt">
            <a
              href="#cancel"
              className="text-grey mr10"
              onClick={this.onCancel}
            >
              Cancel
            </a>
            {submitting ? (
              <a
                href="#save"
                className="btn btn-success btn-wide disabled"
              >
                <FontAwesomeIcon
                  icon="far fa-spinner"
                  pulse
                  className="mr5"
                />
                Saving ...
              </a>
            ) : (
              <a
                href="#save"
                className="btn btn-success btn-wide"
                onClick={this.onSubmit}
              >
                {saveButton}
              </a>
            )}
          </div>
        </Drawer>
      </form>
    );
  }
}

NewLeadDrawer.contextTypes = {
  helpers: PropTypes.shape({}),
};

export default NewLeadDrawer;
