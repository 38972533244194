import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadHelpers   from '~/helpers/lead_helpers';

class ReleasedPhoneNumbers extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadReleasedNumbers();
  }

  renderRealeasedNumber() {
    const { currentUser, currentTeam } = Rails.helpers;
    const { loading, numbers } = this.props;

    const Email   = 'help@getbrokerkit.com';
    const Subject = 'Please reactivate my released Brokerkit phone number';

    if (loading) {
      return (
        <p className="text-center p-5">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </p>
      );
    }

    if (_lodash.isEmpty(numbers)) {
      return (
        <p className="mt-3 text-center">No Released Phone Numbers</p>
      );
    }

    return (
      <div className="mt-3">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>BrokerKit number</th>
                <th>Forwarding number</th>
                <th>Date released</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {numbers.map((el) => {
                const Body = encodeURIComponent(`Please assist with reactivating my Brokerkit phone number which was released. The phone number is ${LeadHelpers.nationalFormatPhoneNumber(el.service_number)}. \nThanks, ${currentUser.first_name} ${currentUser.last_name}`);

                return (
                  <tr key={el.id}>
                    <td>{LeadHelpers.nationalFormatPhoneNumber(el.service_number)}</td>
                    <td>{LeadHelpers.nationalFormatPhoneNumber(el.forward_number)}</td>
                    <td>{Moment(el.deleted_at).format('LL')}</td>
                    <td>
                      <a className="btn btn-info" href={`mailto:${Email}?subject=${Subject}&body=${Body}`}>
                        Request to Restore
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="mt30">
        <h4 className="mb5">Released Phone Numbers</h4>
        <p>
          When you release a phone number, we will reserve that number for you
          for 10 days in case you need it back. Please contact support to
          restore the phone number.
        </p>
        {this.renderRealeasedNumber()}
      </div>
    );
  }
}

ReleasedPhoneNumbers.defaultProps = {
  loading:             true,
  numbers:             [],
  loadReleasedNumbers: () => false,
};

ReleasedPhoneNumbers.propTypes = {
  loading:             PropTypes.bool,
  numbers:             PropTypes.arrayOf(PropTypes.shape({})),
  loadReleasedNumbers: PropTypes.func,
};

export default ReleasedPhoneNumbers;
