import React, { useState, useEffect } from 'react';
import { PropTypes }                  from 'prop-types';
import { FontAwesomeIcon }            from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 }               from 'uuid';
import { Collapse }                   from 'reactstrap';

import LeadDrawerStore           from '~/stores/lead_drawer_store';
import CampaignSubscriptionStore from '~/stores/campaign_subscription_store';

import CampaignPicker                 from '~/components/forms/campaign_picker';
import CampaignSubscriptionActions    from '~/actions/campaign_subscription_actions';
import LeadDrawerCampaignSubscription from '~/components/drawers/lead_drawer/details/lead_drawer_campaign_subscription';
import APIRequest                     from '~/lib/api_request';
import { checkFeatureFlag }           from '~/helpers/FeatureFlagChecker';

const renderErrors = (errors) => {
  if (_lodash.isEmpty(errors)) return null;

  const errorComponents = [];

  const generalErrorsList = _lodash.flatMap(errors, (v, k) => {
    if (v && Array.isArray(v)) {
      return v.map((error) => <p key={uuidv4()}>{error}</p>);
    }

    return [];
  });

  if (generalErrorsList.length > 0) {
    errorComponents.push(
      <div className="alert alert-danger" key="generalErrors">
        {generalErrorsList}
      </div>,
    );
  }

  return errorComponents;
};

const LeadDrawerCampaignSubscriptions = ({ lead }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [unverifiedNumbersWarning, setUnverifiedNumbersWarning] = useState(false);
  const isOpenLocalStorage = localStorage.isDetailsOpen;
  const [isOpen, setIsOpen] = useState(isOpenLocalStorage ? JSON.parse(isOpenLocalStorage) : false);

  useEffect(() => {
    const loadSubscriptions = async () => {
      await CampaignSubscriptionActions.loadCampaignSubscriptions(
        lead.id,
      );
    };

    setTimeout(loadSubscriptions, 0);

    const subscription = CampaignSubscriptionStore.addListener(() => {
      const state = CampaignSubscriptionStore.getState();

      setSubscriptions(state.subscriptions);
      setIsLoading(state.loadingCampaignSubscriptions);
      setErrors(state.subscriptionErrors);

      switch (state.lastCampaignSubscriptionStoreAction) {
        case 'createCampaignSubscriptionDone':
        case 'updateCampaignSubscriptionDone':
        case 'deleteCampaignSubscriptionDone':
          setTimeout(loadSubscriptions, 0);
          break;
        default:
          break;
      }
    });

    return () => subscription.remove();
  }, [lead.id]);

  useEffect(() => {
    const loadSubscriptions = async () => {
      await CampaignSubscriptionActions.loadCampaignSubscriptions(
        lead.id,
      );
    };

    const leadDrawer = LeadDrawerStore.addListener(() => {
      const state = LeadDrawerStore.getState();

      switch (state.leadDrawerStoreAction) {
        case 'updateLeadDone':
          setTimeout(loadSubscriptions, 0);
          break;
        default:
          break;
      }
    });

    return () => leadDrawer.remove();
  }, [lead.id]);

  const handleCampaignSelect = (selectedOption) => {
    if (!selectedOption) return;

    const campaignID = selectedOption.value;

    validateSmsCampaignStepsWithUnverifiedNumbers(campaignID).then((validationPassed) => {
      if (!validationPassed) return;

      CampaignSubscriptionActions.createCampaignSubscription(lead.id, {
        campaign_id: campaignID,
      });
    });
  };

  const validateSmsCampaignStepsWithUnverifiedNumbers = async (campaignId) => {
    const isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

    if (!isRestrictionForUnverifiedNumbersEnabled) return true;

    try {
      const response = await APIRequest.get({
        resource: `/v1/campaigns/${campaignId}/check_campaign_for_unverified_numbers`,
      });

      const unferifiedNumbersInSteps = response.body?.unferifiedNumbersInSteps;

      if (unferifiedNumbersInSteps) {
        setUnverifiedNumbersWarning(true);

        return false;
      }

      setUnverifiedNumbersWarning(false);
      return true;
    } catch (error) {
      setUnverifiedNumbersWarning(false);
      return false;
    }
  };

  const renderLoading = () => (
    <div className="text-center mt-3">
      <FontAwesomeIcon icon="far fa-spinner" pulse />
    </div>
  );

  const onCollapseClick = (e) => {
    e.preventDefault();

    setIsOpen(!isOpen);
    localStorage.isDetailsOpen = !isOpen;
  };

  const renderLeadCampaignSubscriptions = () => {
    if (!Rails.abilities.manageLead) return null;

    if (isLoading) {
      return renderLoading();
    }

    return subscriptions.map((subscription) => (
      <LeadDrawerCampaignSubscription
        lead={lead}
        subscription={subscription}
        key={`subscription-${subscription.id}`}
      />
    ));
  };

  return (
    <>
      <div>
        <h3 className="mb2">
          Campaigns
          <button type="button" className="btn btn-secondary btn-sm mr-1 pull-right" onClick={onCollapseClick}>
            { isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
        </h3>
      </div>

      <Collapse isOpen={isOpen}>
        {Rails.abilities.manageLead && (
          <div
            className="text-grey-dark mr41 ml30 float-right timeline-campaign"
          >
            <div className="mr10 counter-text">
              <FontAwesomeIcon icon={['far', 'fa-bullhorn']} />
              {' '}
              {subscriptions.length === 0 ? 'No' : subscriptions.length}
              {' '}
              Campaigns
            </div>

            <CampaignPicker
              onChange={handleCampaignSelect}
              placeholder="Add a Campaign..."
              wrapperStyle={{ display: 'inline-block' }}
              style={{ minWidth: '180px' }}
            />
          </div>
        )}

        {renderErrors(errors)}

        {unverifiedNumbersWarning && (
          <div className="alert alert-warning text-left mt-3">
            Your campaign includes a text step, but the sender&apos;s
            phone number is not linked to an approved verified texting use case.
            To assign this campaign, please select a phone number in the
            text step(s) which are linked to an approved verified texting use case.
          </div>
        )}

        <br />

        {renderLeadCampaignSubscriptions()}
      </Collapse>
    </>
  );
};

LeadDrawerCampaignSubscriptions.defaultProps = {
  lead: {},
};

LeadDrawerCampaignSubscriptions.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default LeadDrawerCampaignSubscriptions;
