import React from 'react';
import DocumentLimitAlert from '~/components/shared/document_limit_alert';
import { Popover, OverlayTrigger } from 'react-bootstrap';

const DocumentLimitAlertChecker = (props) => {
  const { showLimitAlert, setShowLimitAlert, placement, children } = props;

  const renderPopover = (props) => {
    return (
      <Popover
        id="popover-doc-limit"
        {...props}
      >
        <Popover.Content className="bg-grey-lightest">
          <DocumentLimitAlert onClose={() => setShowLimitAlert(false)} />
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <OverlayTrigger placement={placement} overlay={renderPopover} trigger="manual" show={showLimitAlert}>
      { children }
    </OverlayTrigger>
  )
}

DocumentLimitAlertChecker.defaultProps = {
  placement: 'top',
};

export default DocumentLimitAlertChecker
