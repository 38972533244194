const ProductHelper = {
  /**
   * Returns the product mode (or none if not in product mode)
   * @returns {String} product
   */
  product() {
    if (process.env.NODE_ENV === 'test') return 'default';

    const path = window.location.pathname;
    switch (true) {
      case /^\/recruiter\/archived/.test(path):
        return 'archived';
      case /^\/recruiter/.test(path):
        return 'recruiting';
      case /^\/retention/.test(path):
        return 'retention';
      case /^\/agent/.test(path):
        return 'agent';
      case /^\/archived/.test(path):
        return 'archived';
      case /^\/campaign_libraries/.test(path):
        return 'recruiting';
      default:
        return 'default';
    }
  },
};

export default ProductHelper;
