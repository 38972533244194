import autoBind      from 'react-autobind';
import classNames    from 'classnames';
import React         from 'react';
import { PropTypes } from 'prop-types';

import LeadHelpers       from '~/helpers/lead_helpers';
import ReportItem        from './report_item';

class ReportTable extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleReportPrevClick(recordIndex) {
    const { records } = this.props;

    const prevIndex = recordIndex - 1;
    const record = records[prevIndex];

    if (!record) return;

    const recordsLength = records.length;

    this.context.helpers.openLeadDrawer({
      loadLeadID:            record.id,
      reportItemIndex:       prevIndex,
      recordsLength,
      handleReportPrevClick: this.handleReportPrevClick,
      handleReportNextClick: this.handleReportNextClick,
    });
  }

  handleReportNextClick(recordIndex) {
    const { records } = this.props;

    const nextIndex = recordIndex + 1;
    const record = records[nextIndex];

    if (!record) return;

    const recordsLength = records.length;

    this.context.helpers.openLeadDrawer({
      loadLeadID:            record.id,
      reportItemIndex:       nextIndex,
      recordsLength,
      handleReportPrevClick: this.handleReportPrevClick,
      handleReportNextClick: this.handleReportNextClick,
    });
  }

  renderItems() {
    const { rating, records, showOwner } = this.props;

    const itemRows = [];
    const recordsLength = records.length;
    records.forEach((record, idx) => {
      itemRows.push(
        <ReportItem
          key={`record-${record.id}`}
          record={record}
          recordsLength={recordsLength}
          recordIndex={idx}
          clickable={record.clickable}
          rating={rating}
          showOwner={showOwner}
          handleReportPrevClick={this.handleReportPrevClick}
          handleReportNextClick={this.handleReportNextClick}
        />,
      );
    });

    return itemRows;
  }

  render() {
    const {
      clickable, rating, showRating, showOwner,
    } = this.props;

    return (
      <table className={classNames('table mb30', { 'table-hover': clickable })}>
        <thead>
          <tr>
            <th>
              {showRating
                && (
                <strong className="text-black">
                  {rating}
                  {' '}
                  -
                  {' '}
                  {LeadHelpers.renderRatingText(rating)}
                </strong>
                )}
            </th>
            {showOwner && (<th>Account</th>)}
            <th>$12 MO</th>
            <th className="hidden-sm-down">Referred</th>
            <th className="hidden-lg-down">1st Appt</th>
            <th className="hidden-lg-down">Last Appt</th>
            <th className="hidden-sm-down">Last Contact</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {this.renderItems()}
        </tbody>
      </table>
    );
  }
}

ReportTable.contextTypes = {
  helpers: PropTypes.shape({}),
};

ReportTable.defaultProps = {
  rating:     null,
  records:    null,
  clickable:  true,
  showRating: true,
  showOwner:  false,
};

ReportTable.propTypes = {
  rating:     PropTypes.number,
  showRating: PropTypes.bool,
  records:    PropTypes.arrayOf(PropTypes.object),
  clickable:  PropTypes.bool,
  showOwner:  PropTypes.bool,
};

export default ReportTable;
