import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityDocument extends TimelineActivity {
}

TimelineActivityDocument.defaultProps = {
  title:          (obj) => `${obj.props.document_status} - ${obj.props.activity.parameters.document_title}`,
  color:          'text-indigo',
  icon:           'file',
  prefix:         'far',
  alwaysPublic:   true,
};

export default TimelineActivityDocument;
