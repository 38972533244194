import Pubnub from 'pubnub';

const obj = {
  client(publish, subscribe, uuid) {
    this._client = this._client || new Pubnub({
      publishKey:   publish,
      subscribeKey: subscribe,
      uuid,
    });
    return this._client;
  },
};

export default obj;
