import React        from  'react';
import { PropTypes } from  'prop-types';

import AgentSideBar     from  '~/components/navigation/agent_side_bar';
import RetentionSideBar from  '~/components/navigation/retention_side_bar';

class FrontSideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      widgets: props.widgets,
    };
  }

  render() {
    const { widgets } = this.state;
    let bar;

    switch (GlobalContainer.product()) {
      case 'retention':
        bar =  (<RetentionSideBar widgets={widgets} />);
        break;

      case 'recruiting':
      default:
        bar =  (<AgentSideBar widgets={widgets} />);
    }

    return (
      <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar pr-0" style={{ minWidth: 'fit-content' }}>
        {bar}
      </nav>
    );
  }
}

FrontSideBar.defaultProps = {
  widgets: {
    hasSearch:         false,
    hasTaskSearch:     false,
    hasAppointment:    false,
    tables:            [],
  },
};

FrontSideBar.propTypes = {
  widgets: PropTypes.shape({}),
};

export default FrontSideBar;
