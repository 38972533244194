import UIHandler       from '~/components/forms/HtmlEditors/mentions/UIHandler';
import EventsHandler   from '~/components/forms/HtmlEditors/mentions/EventsHandler';
import PositionHandler from '~/components/forms/HtmlEditors/mentions/PositionHandler';
import QueryHandler    from '~/components/forms/HtmlEditors/mentions/QueryHandler';

export default class AutoComplete {
  constructor(ed, options, activityType) {
    this.editor = ed;
    this.activityType = activityType;

    this.options = {
      delay:     500,
      queryBy:   'name',
      items:     10,
      delimiter: '@',
      ...options,
    };

    this.queryHandler       = new QueryHandler(ed, this, this.activityType);
    this.uiHandler          = new UIHandler(ed, this);
    this.eventHandler       = new EventsHandler(ed, this);
    this.positionHandler    = new PositionHandler(ed);

    this.options.source     = this.options.source || this.queryHandler.loadOptions();
    this.options.insertFrom = this.options.insertFrom || this.options.queryBy;
    this.matcher            = this.options.matcher || this.queryHandler.matcher;
    this.sorter             = this.options.sorter || this.queryHandler.sorter;
    this.renderDropdown     = this.options.renderDropdown || this.uiHandler.renderDropdown;
    this.render             = this.options.renderItem || this.uiHandler.renderItem;
    this.insert             = this.options.insert || this.insert;
    this.highlighter        = this.options.highlighter || this.highlighter;

    this.query              = '';
    this.hasFocus           = true;
    this.dropdown           = undefined;

    this.uiHandler.renderInput(ed);
    this.eventHandler.bindEvents();
  }

  show = () => {
    const autocompleteSpan = this.editor.dom.select('span#mentions-autocomplete')[0];
    if (!autocompleteSpan) {
      return;
    }
    const offset = this.editor.inline ? this.positionHandler.offsetInline() : this.positionHandler.offset();

    this.dropdown = this.renderDropdown();

    this.dropdown.style.top =  `${offset.top}px`;
    this.dropdown.style.left = `${offset.left}px`;

    document.body.appendChild(this.dropdown);

    this.dropdown.addEventListener('click', this.eventHandler.autoCompleteClick);
  }

  process = (data) => {
    if (!this.hasFocus) {
      return;
    }

    const result = [];

    let items = data.filter((item) => this.matcher(item));

    items = this.queryHandler.sorter(items);

    items = items.slice(0, this.options.items);

    items.forEach((item, i) => {
      const element = document.createElement('li');
      element.innerHTML = this.uiHandler.renderItem(item, i);

      Object.keys(items[i]).forEach((key) => {
        element.setAttribute(`data-${key}`, items[i][key]);
      });

      result.push(element.outerHTML);
    });

    if (result.length) {
      this.dropdown.innerHTML = result.join('');
      this.dropdown.style.display = 'block';
    } else {
      this.dropdown.style.display = 'none';
      const listItems = this.dropdown.querySelectorAll('li');

      listItems.forEach((item) => {
        item.classList.remove('active');
      });
    }
  }

  cleanUp = (rollback) => {
    this.eventHandler.unbindEvents();
    this.hasFocus = false;

    if (this.dropdown !== undefined) {
      this.dropdown.remove();
      delete this.dropdown;
    }

    if (rollback && this.editor.isHidden()) {
      const text = this.query;
      const selection = this.editor.dom.select('span#mentions-autocomplete');

      if (!selection.length) {
        return;
      }
      const paragraph = document.createElement('p');
      paragraph.innerHTML = `${this.options.delimiter}${text}`;
      const replacement = paragraph.firstChild;

      const editorSelectionOffsetTop = this.editor.selection.getNode().getBoundingClientRect().top + window.scrollY;
      const autocompleOffsetTop = selection[0].getBoundingClientRect().top + window.scrollY;

      const focus = editorSelectionOffsetTop === autocompleOffsetTop;

      this.editor.dom.replace(replacement, selection[0]);

      if (focus) {
        this.editor.selection.select(replacement);
        this.editor.selection.collapse();
      }
    }
  }
}
