import React     from 'react';
import PropTypes from 'prop-types';

import Select    from '~/components/forms/select';

const donateOrigins = [];

if (Rails
  && Rails.helpers
  && Rails.helpers.currentUser
  && Rails.helpers.currentUser.admin) {
  donateOrigins.push(
    { value: 'brokerkit', label: 'Brokerkit Library' },
  );
}

donateOrigins.push(
  { value: 'donated', label: 'Brokerkit Donated Campaign Library (Accessible by all Brokerkit users)' },
);

if (Rails
  && Rails.helpers
  && Rails.helpers.currentTeam
  && Rails.helpers.currentTeam.organization_id) {
  donateOrigins.push(
    { value: 'organization', label: 'Organization Campaign Library (Accessible by users in your Organization only)' },
  );
}

class DonateCampaignOriginSelect extends React.Component {
  render() {
    const { placeholder, className, onChange } = this.props;
    const { value } = this.props;

    return (
      <Select
        options={donateOrigins}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        value={value}
      />
    );
  }
}

DonateCampaignOriginSelect.defaultProps = {
  styles:      {},
  options:     {},
  placeholder: '',
  className:   '',
  value:       null,
  onChange:    () => {},
};

DonateCampaignOriginSelect.propTypes = {
  styles:      PropTypes.shape({}),
  options:     PropTypes.shape({}),
  placeholder: PropTypes.string,
  className:   PropTypes.string,
  onChange:    PropTypes.func,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
};

export default DonateCampaignOriginSelect;
