import React from 'react';
import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityTextMessage extends TimelineActivity {}

TimelineActivityTextMessage.defaultProps = {
  editable:       true,
  deletable:      true,
  title:          'Text Message',
  icon:           'comment',
  prefix:         'fas',
  color:          'text-yellow',
  alwaysPublic:   false,
};

export default TimelineActivityTextMessage;
