import { ReduceStore } from 'flux/utils';
import AppDispatcher   from '~/dispatchers/app_dispatcher';
import DataActionTypes from '~/types/data_action_types';

class DataStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingDuplicates:   false,
      duplicateLeads:      [],
      lastDataStoreAction: null,
      errors:              {},
      submitting:          false,
      pagination:          {},
    };
  }

  createImport(state, action) {
    const newState = { ...state };

    newState.lastDataStoreAction = 'createImport';

    return newState;
  }

  createImportDone(state, action) {
    const newState = { ...state };

    newState.lastDataStoreAction = 'createImportDone';

    return newState;
  }

  createImportFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error.response.body.errors;
    newState.lastDataStoreAction = 'createImportFail';

    return newState;
  }

  identifyDuplicates(state, action) {
    const newState = { ...state };

    newState.loadingDuplicates   = true;
    newState.lastDataStoreAction = 'identifyDuplicates';

    return newState;
  }

  identifyDuplicatesDone(state, action) {
    const newState = { ...state };

    newState.loadingDuplicates   = false;
    newState.duplicateLeads      = action.duplicates.data;
    newState.lastDataStoreAction = 'identifyDuplicatesDone';
    newState.pagination          = action.duplicates.pagination;

    return newState;
  }

  identifyDuplicatesFail(state, action) {
    const newState = { ...state };

    newState.loadingDuplicates = false;
    newState.errors = action.error.response.body;
    newState.lastDataStoreAction = 'identifyDuplicatesFail';

    return newState;
  }

  mergeDuplicates(state, action) {
    const newState = { ...state };

    newState.lastDataStoreAction = 'mergeDuplicates';

    return newState;
  }

  mergeDuplicatesDone(state, action) {
    const newState = { ...state };

    newState.errors = {};
    newState.lastDataStoreAction = 'mergeDuplicatesDone';

    return newState;
  }

  mergeDuplicatesFail(state, action) {
    const newState = { ...state };

    newState.errors = action.error;
    newState.lastDataStoreAction = 'mergeDuplicatesFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case DataActionTypes.IDENTIFY_DUPLICATES:
        return this.identifyDuplicates(state, action);
      case DataActionTypes.IDENTIFY_DUPLICATES_DONE:
        return this.identifyDuplicatesDone(state, action);
      case DataActionTypes.IDENTIFY_DUPLICATES_FAIL:
        return this.identifyDuplicatesFail(state, action);

      case DataActionTypes.MERGE_DUPLICATES:
        return this.mergeDuplicates(state, action);
      case DataActionTypes.MERGE_DUPLICATES_DONE:
        return this.mergeDuplicatesDone(state, action);
      case DataActionTypes.MERGE_DUPLICATES_FAIL:
        return this.mergeDuplicatesFail(state, action);

      case DataActionTypes.CREATE_IMPORT:
        return this.createImport(state, action);
      case DataActionTypes.CREATE_IMPORT_DONE:
        return this.createImportDone(state, action);
      case DataActionTypes.CREATE_IMPORT_FAIL:
        return this.createImportFail(state, action);
      default:
        return state;
    }
  }
}

export default new DataStore();
