import React, { memo } from 'react';

import NotificationItem from './NotificationItem';
import GroupItem from './GroupItem';
import InfiniteScroll from '~/components/VirtualList/InfiniteScroll';

const NotificationsGroup = memo(({
  notifications,
  nextToken,
  isNextPageLoading,
  fetchNextPageOfNotifications,
  updatingNotificationIds,
  updatingAllNotifications,
  markAllNotificationsAsRead,
  toggleNotificationReadStatus,
}) => {
  const hasUnreadNotifications = notifications.some((notification) => notification?.type === 'notification' && !notification?.content?.IsRead);

  const renderItem = (item, loadingSkeleton, index) => {
    if (item?.type === 'group') {
      return (
        <GroupItem
          item={item}
          showLink={index === 0 && hasUnreadNotifications}
          updatingAllNotifications={updatingAllNotifications}
          markAllNotificationsAsRead={markAllNotificationsAsRead}
        />
      );
    }

    return (
      <NotificationItem
        key={item?.content?.ID}
        notification={item?.content}
        isUpdating={updatingNotificationIds.has(item?.content?.ID)}
        loadingSkeleton={loadingSkeleton}
        toggleReadStatus={toggleNotificationReadStatus}
      />
    );
  };

  return (
    <div className="notifications list-group" style={{ height: 'calc(100vh - 130px)' }}>
      <InfiniteScroll
        items={notifications}
        renderItem={renderItem}
        itemSize={100}
        nextPage={nextToken}
        isNextPageLoading={isNextPageLoading}
        loadNextPage={fetchNextPageOfNotifications}
      />
    </div>
  );
});

export default NotificationsGroup;
