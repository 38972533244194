import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class EngagementTimePeriodSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  loadOptions(input, callback) {
    const options = [];

    options.push({ value: 'no_engagement', label: 'No Engagement' });
    options.push({ value: '7',             label: '7 Days' });
    options.push({ value: '30',            label: '30 Days' });
    options.push({ value: '90',            label: '90 Days' });
    options.push({ value: '180',           label: '180 Days' });
    options.push({ value: '360',           label: '360 Days' });
    options.push({ value: 'unlimited',     label: 'Unlimited' });

    return options;
  }

  render() {
    return (
      <Select
        {...this.props}
        onChange={this.props.onChange}
        options={this.loadOptions()}
      />
    );
  }
}

EngagementTimePeriodSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

EngagementTimePeriodSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default EngagementTimePeriodSelect;
