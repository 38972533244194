import React          from 'react';
import autoBind       from 'react-autobind';
import classNames     from 'classnames';
import { Input }      from 'reactstrap';
import isEmail        from 'validator/lib/isEmail';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest from '~/lib/api_request';
import ErrorMessage from '~/components/forms/ErrorMessage';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHIBLE_KEY);

class UpdateCardForm extends React.Component {
  constructor(props) {
    super(props);

    const { subscription } = this.props;

    this.state = {
      showCardForm:         !(subscription && subscription.status === 'active'),
      showBillingEmailForm: !(subscription && subscription.billing_email),
      billingEmail:         (subscription && subscription.billing_email) || '',
      errors:               {},
      loading:              true,
    };

    this.baseState = this.state;

    autoBind(this);
  }

  onFormSubmit = async (e, elements, stripe) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    this.setState({ authorizing: true, errors: {} });

    const { planId, pricing_plan_id, onSuccessPaymentCard } = this.props;
    const errors = {};
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);

    if (payload.error) errors.stripe = [payload.error.message];

    if (_lodash.size(errors) === 0) {
      APIRequest.post({
        resource: '/v1/subscription',
        data:     {
          plan_id:         planId,
          pricing_plan_id,
          product:         'recruiting',
          token:           payload ? payload.token : null,
        },
      }).end((err, res) => {
        if (res.body.errors) {
          this.setState({ authorizing: false, errors: res.body.errors });
        } else {
          this.setState({ authorizing: false, showCardForm: false, errors: {} });
          onSuccessPaymentCard(res);
        }
      });
    } else {
      this.setState({  authorizing: false, errors });
    }
  }

  onEmailSubmitForm(e) {
    e.preventDefault();

    const { billingEmail } = this.state;
    const { planId, pricing_plan_id } = this.props;
    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      this.setState({ authorizingEmail: true });

      APIRequest.post({
        resource: '/v1/subscription',
        data:     {
          billing_email:    billingEmail,
          plan_id:          planId,
          pricing_plan_id,
          product:          'recruiting',
        },
      }).end((err, res) => {
        if (res.body.errors) {
          this.setState({ authorizingEmail: false, errors: res.body.errors });
        } else {
          this.setState({ authorizingEmail: false, showBillingEmailForm: false, errors: {} });
          this.baseState = this.state;
        }
      });
    } else {
      this.setState({ errors });
    }
  }

  onBillingEmailChange(e) {
    this.setState({ billingEmail: e.target.value });
  }

  resetEmailForm = () => {
    this.setState(this.baseState);
  }

  validate() {
    const { billingEmail } = this.state;
    const errors = {};

    if (_lodash.isEmpty(billingEmail)) {
      errors.billing_email = "Email address can't be blank";
    }

    if (!_lodash.isEmpty(billingEmail) && !isEmail(billingEmail)) {
      errors.billing_email = 'Email address is invalid';
    }

    return errors;
  }

  renderSubmitButton = (elements, stripe) => {
    const { authorizing, loading } = this.state;

    if (!stripe || !elements || loading) {
      return (
        <button type="button" className="btn btn-primary btn-wide" disabled>
          Update
        </button>
      );
    }

    if (authorizing) {
      return (
        <button type="button" className="btn btn-primary btn-wide" disabled>
          <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
          Updating...
        </button>
      );
    }

    return (
      <button type="submit" className="btn btn-primary btn-wide">
        Update
      </button>
    );
  }

  render() {
    const { subscription } = this.props;
    const {
      authorizingEmail, billingEmail, errors, showCardForm, showBillingEmailForm,
    } = this.state;

    return (
      <>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <form onSubmit={(e) => this.onFormSubmit(e, elements, stripe)}>
                { errors.general && (
                  <div className="text-center alert alert-danger mb-2">
                    {errors.general}
                  </div>
                )}

                { showCardForm ? (
                  <div>
                    <div className="mb20">
                      <p className="mb10">Enter your credit card information to update your payment source:</p>
                      <div
                        id="card-element"
                        className={classNames('form-control', { 'has-error': !!errors.stripe })}
                      >
                        <CardElement onReady={() => this.setState({ loading: false })} />
                      </div>
                      {errors.stripe && <ErrorMessage message={errors.stripe} />}
                    </div>
                    <div>
                      {this.renderSubmitButton(elements, stripe)}
                      <button type="button" onClick={() => { this.setState({ errors: {}, showCardForm: false }); }} className="btn btn-secondary btn-wide ml10">Cancel</button>
                    </div>
                  </div>
                ) : (
                  <p>
                    <FontAwesomeIcon icon={['far', 'fa-credit-card']} className="mr5" />
                    {' '}
                    <strong>{subscription.card_brand}</strong>
                    {' '}
                    ending in ••••
                    {' '}
                    {subscription.card_last4}
                    <br />
                    <FontAwesomeIcon icon={['far', 'fa-calendar-alt']} className="mr5" />
                    {' '}
                    <strong>Exp. Date</strong>
                    {' '}
                    {subscription.card_exp_month}
                    /
                    {subscription.card_exp_year}
                    <br />
                    <button type="button" onClick={() => { this.setState({ showCardForm: true }); }} className="btn btn-secondary mt-3">Change payment source</button>
                  </p>
                )}
              </form>
            )}
          </ElementsConsumer>
        </Elements>

        <br />

        <form onSubmit={this.onEmailSubmitForm}>
          { showBillingEmailForm ? (
            <div>
              <div className="mb30">
                <p className="mb10">Enter your billing email address:</p>
                <Input
                  name="email"
                  className={errors.billing_email ? 'has-error' : ''}
                  onChange={this.onBillingEmailChange}
                  value={billingEmail}
                />
              </div>
              <div>
                { authorizingEmail ? (
                  <button type="button" className="btn btn-primary btn-wide" disabled>
                    <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                    Updating
                  </button>
                ) : <button type="submit" className="btn btn-primary btn-wide">Update</button>}
                <button type="button" onClick={this.resetEmailForm} className="btn btn-secondary btn-wide ml10">Cancel</button>
              </div>
            </div>
          ) : (
            <>
              <p>
                { billingEmail
                  ? (
                    <>
                      <FontAwesomeIcon icon={['far', 'fa-envelope']} className="mr5" />
                      {' '}
                      <strong>Billing Email Address: </strong>
                      {billingEmail}
                    </>
                  )
                  : <>No billing email address entered.</>}
              </p>
              <button type="button" onClick={() => { this.setState({ showBillingEmailForm: true }); }} className="btn btn-secondary mt-3">Update Billing Email</button>
            </>
          )}
        </form>
      </>
    );
  }
}

export default UpdateCardForm;
