import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class RoleSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getOptions() {
    if (this.props.externalRolesOnly) {
      return [
        { value: 'agent',     label: 'Agent' },
        { value: 'affiliate', label: 'Affiliate' },
      ];
    }

    return [
      { value: 'admin',       label: 'Admin - All access, including team, billing settings' },
      { value: 'staff',       label: 'Staff - All access, except team, billing settings' },
      { value: 'agent',       label: 'Agent - Can only view/add referrals' },
      { value: 'affiliate',   label: 'Affiliate - Can only view/add referrals, kept separate from Agents' },
    ];
  }

  render() {
    return (
      <Select
        {...this.props}
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.getOptions()}
      />
    );
  }
}

RoleSelect.defaultProps = {
  externalRolesOnly: false,
  name:              '',
  value:             '',
  onChange:          () => false,
};

RoleSelect.propTypes = {
  externalRolesOnly: PropTypes.bool,
  name:              PropTypes.string,
  value:             PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
};

export default RoleSelect;
