import TeamMemberPicker from '~/components/forms/team_fields/team_member_picker';

class OwnerPicker extends TeamMemberPicker {}

OwnerPicker.defaultProps = {
  apiResource: '/v1/team/owners_options',
  key:         'ownerPicker',
};

export default OwnerPicker;
