import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from '~/components/forms/dropdowns/SelectWrapper/Select';
import useNotifications from '~/hooks/Notifications/useNotifications';
import useGroupedNotifications from '~/hooks/Notifications/useGroupedNotifications';
import useNotificationStatus from '~/hooks/Notifications/useNotificationStatus';
import NotificationsGroup from '~/components/Notifications/NotificationsGroup';

// Constants
import { NOTIFICATION_TYPE_OPTIONS } from '~/constants/dropdowns/notifications/typeOptions';

const { currentUser, currentTeam } = Rails.helpers;
const UserID = currentUser?.id;
const TeamID = currentTeam?.id;

const NotificationModal = ({
  showModal,
  toggleModal,
  setUnreadNotifications,
  appliedFilter,
}) => {
  const [type, setType] = useState(appliedFilter || 'all');
  const [showOnlyUnread, setShowOnlyUnread] = useState(true);
  const {
    notifications,
    isLoading,
    isNextPageLoading,
    nextToken,
    error,
    setNotifications,
    fetchNotifications,
    fetchNextPageOfNotifications,
  } = useNotifications(UserID, TeamID, type, showOnlyUnread);
  const {
    updatingAllNotifications,
    updatingNotificationIds,
    markAllNotificationsAsRead,
    toggleNotificationReadStatus,
  } = useNotificationStatus(UserID, TeamID, type, showOnlyUnread, setNotifications, setUnreadNotifications);
  const groupedNotifications = useGroupedNotifications(notifications);

  const renderContent = () => {
    const centerClasses = 'd-flex flex-column align-items-center justify-content-center m-auto';

    if (isLoading) {
      return (
        <div className={centerClasses}>
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (error) {
      return (
        <div className={centerClasses}>
          <div className="text-danger mb-2">{error}</div>
          <div>
            <button type="button" className="btn btn-danger btn-sm" onClick={fetchNotifications}>Reload</button>
          </div>
        </div>
      );
    }

    if (notifications.length === 0) {
      return (
        <div className={`${centerClasses} text-grey-dark`}>
          <h3>All done here</h3>
          <div className="text-center">
            You&apos;ve
            {' '}
            <strong>read</strong>
            {' '}
            all your notifications
            {' '}
            <br />
            {' '}
            from the last 30 days.
          </div>
        </div>
      );
    }

    return (
      <div className="w-100">
        <NotificationsGroup
          notifications={groupedNotifications}
          nextToken={nextToken}
          isNextPageLoading={isNextPageLoading}
          fetchNextPageOfNotifications={fetchNextPageOfNotifications}
          markAllNotificationsAsRead={markAllNotificationsAsRead}
          updatingAllNotifications={updatingAllNotifications}
          toggleNotificationReadStatus={toggleNotificationReadStatus}
          updatingNotificationIds={updatingNotificationIds}
        />
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      dialogClassName="notifications-dialog"
      contentClassName="h-100"
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between align-items-center w-100">
          <Modal.Title>
            Notifications
          </Modal.Title>

          <div className="custom-switch small">
            <input
              type="checkbox"
              className="custom-control-input"
              name="unread_switch"
              id="unread_switch"
              checked={showOnlyUnread}
              onChange={() => setShowOnlyUnread(!showOnlyUnread)}
              disabled={isLoading}
            />
            <label className="custom-control-label ml-auto" htmlFor="unread_switch">Only Show Unread</label>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column p-0">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="col-5 p0">
            <Select
              id="type"
              name="type"
              options={NOTIFICATION_TYPE_OPTIONS}
              value={type}
              onChange={(opt) => setType(opt?.value || '')}
              isSearchable
              isDisabled={isLoading}
            />
          </div>

          <a href="/users/edit?tab=notification_settings" className="ml-3">
            <FontAwesomeIcon
              icon={['fas', 'fa-cog']}
              size="lg"
              className="text-grey-dark"
            />
          </a>
        </div>

        <hr className="m-0" />

        <div className="flex-grow-1 d-flex bg-grey-lightest">
          {renderContent()}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationModal;
