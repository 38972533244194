import AppDispatcher      from '~/dispatchers/app_dispatcher';
import MlsServiceRequest  from '~/lib/mls_service_request';
import TeamMlsActionTypes from '~/types/team_mls_action_types';

let loadTeamMlsesRequest;
let loadTeamMlsRequest;
let updateTeamMlsesRequest;
let updateTeamMlsRequest;

const TeamMlsActions = {
  loadTeamMlses() {
    MlsServiceRequest.abort(loadTeamMlsesRequest);

    AppDispatcher.dispatch({
      type: TeamMlsActionTypes.LOAD_TEAM_MLSES,
    });

    loadTeamMlsesRequest = MlsServiceRequest.get({
      resource: '/team_mlses',
    });

    loadTeamMlsesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMlsActionTypes.LOAD_TEAM_MLSES_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     TeamMlsActionTypes.LOAD_TEAM_MLSES_DONE,
        response: response.body,
      });
    });
  },

  loadTeamMls(teamMlsId, onDone = () => false, onFail = () => false) {
    MlsServiceRequest.abort(loadTeamMlsRequest);

    loadTeamMlsRequest = MlsServiceRequest.get({
      resource: `/team_mlses/${teamMlsId}`,
    });

    loadTeamMlsRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  updateTeamMlses(teamData) {
    MlsServiceRequest.abort(updateTeamMlsesRequest);

    AppDispatcher.dispatch({
      type: TeamMlsActionTypes.UPDATE_TEAM_MLSES,
    });

    updateTeamMlsesRequest = MlsServiceRequest.patch({
      resource: '/team_mlses/update_mlses',
      data:     {
        team: teamData,
      },
    });

    updateTeamMlsesRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMlsActionTypes.UPDATE_TEAM_MLSES_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     TeamMlsActionTypes.UPDATE_TEAM_MLSES_DONE,
        response: response.body,
      });
    });
  },

  updateTeamMls(data) {
    MlsServiceRequest.abort(updateTeamMlsRequest);

    AppDispatcher.dispatch({
      type: TeamMlsActionTypes.UPDATE_TEAM_MLS,
    });

    updateTeamMlsRequest = MlsServiceRequest.patch({
      resource: `/team_mlses/${data.id}`,
      data:     {
        team_mls: data,
      },
    });

    updateTeamMlsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMlsActionTypes.UPDATE_TEAM_MLS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     TeamMlsActionTypes.UPDATE_TEAM_MLS_DONE,
        response: response.body,
      });
    });
  },
};

export default TeamMlsActions;
