import autoBind            from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltipable from '~/components/effects/tooltipable';

class CampaignTemplateItem extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  render() {
    const { campaign } = this.props;

    let { duration } = campaign;
    if (duration < 86400) duration = 86400;
    else duration += 86400;

    const durationMessage = `lasts ${Moment().add(duration, 's').fromNow(true)}`;

    return (
      <tr
        className="text-grey"
        onClick={(e) => {
          e.preventDefault();
          const { handleCampaignTemplateClick } = this.props;

          handleCampaignTemplateClick(campaign);
        }}
      >
        <td>{campaign.name}</td>
        <td width="15%">
          <FontAwesomeIcon
            icon={['far', 'fa-bullhorn']}
            className="mr5"
          />
          {' '}
          {campaign.campaign_steps_count}
          {' '}
          emails/texts/tasks
        </td>
        <td width="15%">
          {campaign.new_engine ? (
            <Tooltipable text="This field specifies the duration of the campaign and includes only the waiting periods between steps and does not factor in the On and At parameters as those will depend on the start date.">
              <>
                <FontAwesomeIcon
                  icon={['far', 'fa-clock']}
                  className="mr5"
                />
                {durationMessage}
              </>
            </Tooltipable>
          ) : (
            <>
              <FontAwesomeIcon
                icon={['far', 'fa-clock']}
                className="mr5"
              />
              {durationMessage}
            </>
          )}
        </td>
      </tr>
    );
  }
}

CampaignTemplateItem.defaultProps = {
  handleCampaignTemplateClick: () => false,
};

CampaignTemplateItem.propTypes = {
  campaign:                    PropTypes.shape({}).isRequired,
  handleCampaignTemplateClick: PropTypes.func,
};

export default CampaignTemplateItem;
