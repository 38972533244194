import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSafeLocation } from '~/helpers/locationHelper';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import useUnreadNotificationsCount from '~/hooks/Notifications/useUnreadNotificationsCount';
import NotificationModal from './Modal';

// Constants
import { NOTIFICATION_TYPE_OPTIONS } from '~/constants/dropdowns/notifications/typeOptions';

const NotificationButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [hasCheckedFeature, setHasCheckedFeature] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useUnreadNotificationsCount();
  const [filter, setFilter] = useState(null);

  const location = useSafeLocation();

  useEffect(() => {
    const handlePosthogReady = () => {
      const isAccessAllowed = checkFeatureFlag(process.env.ENABLE_NOTIFICATION_BELL);
      setIsFeatureEnabled(isAccessAllowed);
      setHasCheckedFeature(true);
    };

    if (window.isPosthogInitialized) {
      handlePosthogReady();
    } else {
      window.addEventListener('posthogReady', handlePosthogReady);
    }

    return () => {
      window.removeEventListener('posthogReady', handlePosthogReady);
    };
  }, []);

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const openNotifications = params.get('open_notifications') === 'true';
      const filterParam = params.get('filter');

      if (openNotifications) {
        setShowModal(true);

        const validFilter = NOTIFICATION_TYPE_OPTIONS.find((option) => option.value === filterParam);

        if (validFilter) {
          setFilter(validFilter.value);
        }
      }
    }
  }, [location]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (!isFeatureEnabled || !hasCheckedFeature) return null;

  return (
    <>
      <div className="position-relative">
        <button type="button" className="btn btn-link shadow-none color-grey-dark px-2" onClick={toggleModal}>
          <FontAwesomeIcon testid="bell-icon" icon="fas fa-bell" size="lg" />
          {unreadNotifications > 0 && (
            <sup>
              <span data-testid="notification-badge" className="notifications-counter badge border border-white rounded-pill bg-danger position-absolute text-white">
                {unreadNotifications > 99 ? '99+' : unreadNotifications}
              </span>
            </sup>
          )}
        </button>
      </div>

      {showModal && (
        <NotificationModal
          showModal={showModal}
          toggleModal={toggleModal}
          setUnreadNotifications={setUnreadNotifications}
          appliedFilter={filter}
        />
      )}
    </>
  );
};

export default NotificationButton;
