import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';

const RetentionSideBar = () => {
  const { abilities } = Rails;
  const [isInboxTooltipVisible, setIsInboxTooltipVisible] = useState(false);

  const inboxLinkRef = useRef(null);
  const tooltipRef = useRef(null);

  const location = useLocation();
  const page = location.pathname;

  const [dropdowns, setDropdowns] = useState({
    content:    false,
    automation: false,
    data:       false,
  });

  const isDmsAllowed = checkFeatureFlag(process.env.ENABLE_DOCUMENT_MANAGEMENT_FLAG);

  const innerLinks = [
    /documents/,
    '/retention/campaigns',
    '/retention/campaign_libraries',
    '/retention/campaign_triggers',
    '/retention/data',
    '/retention/labels',
  ];

  const innerLinkStyles = { width: 'fit-content', minWidth: '100%' };
  const dropdownStyles = {
    background: 'none',
    border:     'none',
    padding:    '10',
    width:      '100%',
    textAlign:  'left',
  };

  const handleInboxClick = (e) => {
    const isAccessAllowed = checkFeatureFlag(process.env.RESTRICT_INBOX_ACCESS_FLAG);
    if (!isAccessAllowed) {
      e.preventDefault();
      setIsInboxTooltipVisible(true);
    } else {
      setIsInboxTooltipVisible(false);
    }
  };

  const handleOutsideClick = (e) => {
    if (inboxLinkRef.current && !inboxLinkRef.current.contains(e.target)
        && tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setIsInboxTooltipVisible(false);
    }
  };

  function isActivePage(pathOrRegex) {
    let isActive = false;

    if (Array.isArray(pathOrRegex)) {
      isActive = pathOrRegex.some((p) => (typeof p === 'string' ? page === p : p.test(page)));
    } else if (typeof pathOrRegex === 'string') {
      isActive = page === pathOrRegex;
    } else {
      isActive = pathOrRegex.test(page);
    }

    return isActive;
  };

  const isDropdown = (pathOrRegex) => {
    if (Array.isArray(pathOrRegex)) {
      return pathOrRegex.some((p) => innerLinks.some((l) => (typeof l === 'string' ? l === p : l.source == p.source)));
    } else if (typeof pathOrRegex === 'string') {
      return innerLinks.some((l) => l === pathOrRegex);
    } else {
      return innerLinks.some((l) => l.source == pathOrRegex.source);
    }
  };

  const getClassName = (pathOrRegex) => {
    const active = isActivePage(pathOrRegex);
    let baseClass = 'list-group-item justify-content-between';
    const dropDown = isDropdown(pathOrRegex);
    if (dropDown) {
      baseClass = baseClass + ' pl30 pr-3';
    }

    return classNames(baseClass, {
      active,
    });
  };

  const toggleDropdown = (name) => {
    setDropdowns((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    setDropdowns({
      content:    isActivePage(/documents/),
      automation: isActivePage(/campaign/),
      data:       isActivePage([/data/, /labels/, /search_queries/, /configure_mls/]),
    });

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [page]);


  return (
    <div className="sidebar list-group text-nowrap">
      <Link to="/retention/today" className={getClassName('/retention/today')}>
        <FontAwesomeIcon icon={['fas', 'fa-calendar-day']} className="fa-lg position-absolute" />
        <span className="ml40">Today</span>
      </Link>

      <>
        <Link
          id="inbox-link"
          to="/retention/inbox"
          className={getClassName('/retention/inbox')}
          onClick={handleInboxClick}
        >
          <FontAwesomeIcon icon={['fas', 'fa-inbox']} className="fa-lg position-absolute" />
          <span className="ml40">Inbox</span>
        </Link>

        <Tooltip
          placement="bottom"
          isOpen={isInboxTooltipVisible}
          target="inbox-link"
          autohide={false}
          toggle={() => { }}
          innerRef={tooltipRef}
        >
          It looks like you&apos;re trying to access the inbox — great choice! Please upgrade your pricing plan
          {' '}
          <a
            ref={inboxLinkRef}
            href="/billing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-success"
          >
            here
          </a>
          {' '}
          to enable this feature.
        </Tooltip>
      </>

      <Link to="/retention/team" className={getClassName('/retention/team')}>
        <FontAwesomeIcon icon={['fas', 'fa-address-book']} className="fa-lg position-absolute" />
        <span className="ml40">Contacts</span>
      </Link>

      <Link to="/retention/tasks_due_today" className={getClassName(/tasks/)}>
        <FontAwesomeIcon icon={['fas', 'fa-tasks']} className="fa-lg position-absolute" />
        <span className="ml40">Tasks</span>
      </Link>

      <>
        <a
          className='list-group-item justify-content-between'
          onClick={() => toggleDropdown('content')}
          style={dropdownStyles}
        >
          <FontAwesomeIcon icon={['far', 'fa-photo-video']} className="fa-lg position-absolute" />
          <span className="ml40">Content</span>
          <FontAwesomeIcon
            icon={['fas', dropdowns.content ? 'fa-caret-up' : 'fa-caret-down']}
            className="fa-sm position-absolute mt-1 ml-2"
          />
        </a>

        {dropdowns.content && isDmsAllowed && (
          <div>
            <Link to="/retention/documents" className={getClassName(/documents/)}>
              <FontAwesomeIcon icon={['fal', 'fa-folder-open']} className="fa-lg position-absolute" />
              <span className="ml30">Documents</span>
            </Link>
          </div>
        )}
      </>

      <a
        className='list-group-item justify-content-between'
        onClick={() => toggleDropdown('automation')}
        style={dropdownStyles}
      >
        <FontAwesomeIcon icon={['fal', 'fa-magic']} className="fa-lg position-absolute" />
        <span className="ml40">Automation</span>
        <FontAwesomeIcon
          icon={['fas', dropdowns.automation ? 'fa-caret-up' : 'fa-caret-down']}
          className="fa-sm position-absolute mt-1 ml-2"
        />
      </a>

      {dropdowns.automation && abilities.manageCampaigns && (
        <>
          <Link
            to="/retention/campaigns"
            className={getClassName(['/retention/campaigns', /campaigns\/new/, /campaigns\/\d+\/edit/])}
            style={innerLinkStyles}
          >
            <FontAwesomeIcon icon={['fas', 'fa-bullhorn']} className="fa-lg position-absolute" />
            <span className="ml30">Campaigns</span>
          </Link>
          <Link
            to="/retention/campaign_libraries"
            className={getClassName(['/retention/campaign_libraries', /campaign_libraries\/\d+$/, /campaign_libraries\/\d+\/edit/])}
            style={innerLinkStyles}
          >
            <FontAwesomeIcon icon={['fal', 'fa-books']} className="fa-lg position-absolute" />
            <span className="ml30">Campaign Library</span>
          </Link>
          <Link
            to="/retention/campaign_triggers"
            className={getClassName(['/retention/campaign_triggers', /campaign_triggers\/new/, /campaign_triggers\/\d+\/edit/])}
            style={innerLinkStyles}
          >
            <FontAwesomeIcon icon={['fal', 'fa-bolt']} className="fa-lg position-absolute" />
            <span className="ml30">Campaign Triggers</span>
          </Link>
        </>
      )}

      <a
        className='list-group-item justify-content-between'
        onClick={() => toggleDropdown('data')}
        style={dropdownStyles}
      >
        <FontAwesomeIcon icon={['fas', 'fa-database']} className="fa-lg position-absolute" />
        <span className="ml40">Data</span>
        <FontAwesomeIcon
          icon={['fas', dropdowns.data ? 'fa-caret-up' : 'fa-caret-down']}
          className="fa-sm position-absolute mt-1 ml-2"
        />
      </a>
      {dropdowns.data && (
        <>
          {abilities.manageLeadSources && (
            <Link
              to="/retention/data"
              className={getClassName(['/retention/data', /search_queries/, /configure_mls/])}
              style={innerLinkStyles}
            >
              <FontAwesomeIcon icon={['far', 'fa-user-plus']} className="fa-lg position-absolute" />
              <span className="ml30">Contact Import</span>
            </Link>
          )}
          {abilities.manageLabels && (
            <Link
              to="/retention/labels"
              className={getClassName('/retention/labels')}
              style={innerLinkStyles}
            >
              <FontAwesomeIcon icon={['fas', 'fa-tags']} className="fa-lg position-absolute" />
              <span className="ml30">Labels</span>
            </Link>
          )}
        </>
      )}

      <Link to="/retention/reports" className={getClassName('/retention/reports')}>
        <FontAwesomeIcon icon={['fas', 'fa-chart-line']} className="fa-lg position-absolute" />
        <span className="ml40">Reports</span>
      </Link>
    </div>
  );
};

RetentionSideBar.defaultProps = {};
RetentionSideBar.propTypes = {};

export default RetentionSideBar;
