const CampaignActionTypes = {
  LOAD_CAMPAIGNS:      'LOAD_CAMPAIGNS',
  LOAD_CAMPAIGNS_DONE: 'LOAD_CAMPAIGNS_DONE',
  LOAD_CAMPAIGNS_FAIL: 'LOAD_CAMPAIGNS_FAIL',

  CREATE_CAMPAIGN:      'CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_DONE: 'CREATE_CAMPAIGN_DONE',
  CREATE_CAMPAIGN_FAIL: 'CREATE_CAMPAIGN_FAIL',

  UPDATE_CAMPAIGN:      'UPDATE_CAMPAIGN',
  UPDATE_CAMPAIGN_DONE: 'UPDATE_CAMPAIGN_DONE',
  UPDATE_CAMPAIGN_FAIL: 'UPDATE_CAMPAIGN_FAIL',

  DELETE_CAMPAIGN:      'DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_DONE: 'DELETE_CAMPAIGN_DONE',
  DELETE_CAMPAIGN_FAIL: 'DELETE_CAMPAIGN_FAIL',

  DONATE_CAMPAIGN:      'DONATE_CAMPAIGN',
  DONATE_CAMPAIGN_DONE: 'DONATE_CAMPAIGN_DONE',
  DONATE_CAMPAIGN_FAIL: 'DONATE_CAMPAIGN_FAIL',
};

export default CampaignActionTypes;
