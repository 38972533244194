import autoBind         from 'react-autobind';
import React            from 'react';
import shadowRoot       from 'react-shadow';
import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityTask extends TimelineActivity {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderView() {
    const { activity } = this.state;

    if (!activity) {
      return null;
    }

    return (
      <div>
        <span>
          Category:
          {' '}
          {activity.parameters.category}
        </span>
        <br />
        {(activity.body
          && (
          <shadowRoot.div className="mt-2 form-control font-activity">
            <div className="content" dangerouslySetInnerHTML={{ __html: activity.body || '' }} />
          </shadowRoot.div>
          )
        )}
      </div>
    );
  }
}

TimelineActivityTask.defaultProps = {
  icon:   'tasks',
  prefix: 'fas',
  title:  (object) => {
    const { key, parameters: { owner_name } } = object.props.activity;
    let type;

    switch (key) {
      case 'lead.create_task':
        type = 'added';
        break;
      case 'lead.destroy_task':
        type = 'deleted';
        break;
      case 'lead.update_task':
        type = 'updated';
        break;
      default:
        type = 'performed an unknown action on';
    }

    return (
      `Follow Up Task was ${type} by ${owner_name}`
    );
  },
  alwaysPublic:  false,
};

export default TimelineActivityTask;
