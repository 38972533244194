import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ErrorAlert = ({heading, message}) => (
  <div>
    <div className="alert alert-danger text-center alert-dismissible fade show px-1" role="alert">
      <button type="button" className="close pr-1 pt-1" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 className="alert-heading">
        <FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
        {heading}
      </h4>
      <p>Error: "{message}"</p>
      <p>If the problem persists, email us at support@getbrokerkit.com.</p>
    </div>
  </div>
)

export default ErrorAlert
