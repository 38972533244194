import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { Input }           from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InputWithIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
    autoBind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
    });
  }

  onChange(change) {
    if (this.props.size && change.target.value.length > this.props.size) return;
    if (this.props.type === 'number') {
      if (!change.target.value.match(/\d?/)) return;
    }

    this.setState({
      value: change.target.value,
    });

    this.props.onChange(change);
  }

  onIconClick(e) {
    e.preventDefault();

    const { focusOnIconClick, onFocus, disabled } = this.props;

    if (disabled) return;

    if (!focusOnIconClick) return;

    this.input.focus();
    if (onFocus) onFocus(e);
  }

  render() {
    const { side, disabled } = this.props;
    const _props = _lodash.omit(this.props, ['side', 'focusOnIconClick']);

    const classes = classNames(side === 'left' ? 'pl20' : '', this.props.className);

    return (
      <div className={classNames(this.props.className, 'form-group has-feedback')}>
        <FontAwesomeIcon onClick={this.onIconClick} icon={[`${this.props.prefix}`, `fa-${this.props.icon}`]} className={`form-control-feedback form-control-feedback-${this.props.side}`} />
        <Input
          {..._props}
          className={classes}
          innerRef={(el) => {
            this.input = el;
          }}
          value={this.state.value || ''}
          onChange={this.onChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

InputWithIcon.defaultProps = {
  name:             '',
  value:            '',
  type:             'text',
  side:             'left',
  onChange:         () => false,
  focusOnIconClick: true,
  disabled:         false,
};

InputWithIcon.propTypes = {
  name:             PropTypes.string,
  className:        PropTypes.string,
  icon:             PropTypes.string.isRequired,
  prefix:           PropTypes.string.isRequired,
  side:             PropTypes.string,
  size:             PropTypes.string,
  value:            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  focusOnIconClick: PropTypes.bool,
  onChange:         PropTypes.func,
  disabled:         PropTypes.bool,
};

export default InputWithIcon;
