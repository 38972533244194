export default class ActionHandler {
  constructor(ed, aCInstance) {
    this.editor     = ed;
    this.aCInstance = aCInstance;
    this.options    = this.aCInstance.options;
    this.process    = this.aCInstance.process;
  }

  highlightPreviousResult = () => {
    const listItems    = this.aCInstance.dropdown.querySelectorAll('li');
    const currentIndex = Array.from(listItems).findIndex((item) => item.classList.contains('active'));
    const index        = (currentIndex <= 0) ? listItems.length - 1 : currentIndex - 1;

    listItems.forEach((item) => {
      item.classList.remove('active');
    });

    listItems[index].classList.add('active');
  }

  highlightNextResult = () => {
    const listItems    = this.aCInstance.dropdown.querySelectorAll('li');
    const currentIndex = Array.from(listItems).findIndex((item) => item.classList.contains('active'));
    const index        = (currentIndex === listItems.length - 1) ? 0 : currentIndex + 1;

    listItems.forEach((item) => {
      item.classList.remove('active');
    });

    listItems[index].classList.add('active');
  }

  select = (item) => {
    this.editor.focus();

    const selection = this.editor.dom.select('span#mentions-autocomplete')[0];

    this.editor.dom.remove(selection);

    if (item.id !== undefined) {
      this.editor.execCommand('mceInsertContent', false, this.insert(item));
    }
  }

  insert = (item) => (
    `<span class="mention mceNonEditable" style="background-color: #fce8fb;padding: 2px;" data-user-id=${item.id}>
      ${this.options.delimiter}${item[this.options.insertFrom]}
    </span>&nbsp;`
  );

  lookup = () => {
    const text =  this.editor.getBody().querySelector('#autocomplete-searchtext')?.textContent || '';
    this.aCInstance.query = text.trim().replace('\u200b', '');

    if (this.aCInstance.dropdown === undefined) {
      this.aCInstance.show();
    }

    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      const items = typeof this.options.source === 'function' ? this.options.source(this.query, this.process, this.options.delimiter) : this.options.source;
      if (items) {
        this.process(items);
      }
    }, this.options.delay);
  }
}
