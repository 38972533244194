const SearchQueryActionTypes = {
  LOAD_SEARCH_QUERIES:      'LOAD_SEARCH_QUERIES',
  LOAD_SEARCH_QUERIES_DONE: 'LOAD_SEARCH_QUERIES_DONE',
  LOAD_SEARCH_QUERIES_FAIL: 'LOAD_SEARCH_QUERIES_FAIL',

  LOAD_SEARCH_QUERY:        'LOAD_SEARCH_QUERY',
  LOAD_SEARCH_QUERY_DONE:   'LOAD_SEARCH_QUERY_DONE',
  LOAD_SEARCH_QUERY_FAIL:   'LOAD_SEARCH_QUERY_FAIL',

  CREATE_SEARCH_QUERY:      'CREATE_SEARCH_QUERY',
  CREATE_SEARCH_QUERY_DONE: 'CREATE_SEARCH_QUERY_DONE',
  CREATE_SEARCH_QUERY_FAIL: 'CREATE_SEARCH_QUERY_FAIL',

  UPDATE_SEARCH_QUERY:      'UPDATE_SEARCH_QUERY',
  UPDATE_SEARCH_QUERY_DONE: 'UPDATE_SEARCH_QUERY_DONE',
  UPDATE_SEARCH_QUERY_FAIL: 'UPDATE_SEARCH_QUERY_FAIL',

  DELETE_SEARCH_QUERY:      'DELETE_SEARCH_QUERY',
  DELETE_SEARCH_QUERY_DONE: 'DELETE_SEARCH_QUERY_DONE',
  DELETE_SEARCH_QUERY_FAIL: 'DELETE_SEARCH_QUERY_FAIL',
};

export default SearchQueryActionTypes;
