import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import update              from 'immutability-helper';
import React               from 'react';
import PropTypes           from 'prop-types';
import isMobilePhone       from 'validator/lib/isMobilePhone';
import Geosuggest          from 'react-geosuggest';
import PhoneInput          from 'react-phone-input-2';
import NumberFormat        from 'react-number-format';
import { Collapse }        from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LeadHelpers       from '~/helpers/lead_helpers';
import LeadDrawerActions from '~/actions/lead_drawer_actions';
import TinyUser          from '~/components/shared/users/tiny_user';
import CurrencyInput     from '~/components/forms/currency_input';
import PercentageInput   from '~/components/forms/percentage_input';
import DateTimePicker    from '~/components/forms/DateTimePicker';
import {
  TeammatePicker,
  TeamMemberPicker,
} from '~/components/forms/team_member_picker';
import {
  ProductionLabelSelect,
  SourceSelect,
} from '~/components/forms/lead_fields';

class LeadDrawerRecruitingDetails extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const isOpen = localStorage.isRecruitingOpen ? JSON.parse(localStorage.isRecruitingOpen) : false;

    this.state = {
      opened: props.opened,
      fields: _lodash.pick(props.lead, 'owner_id', 'referrer_id', 'referred_at', 'currently_at', 'source',
        'production_ytd', 'production_label', 'office_address', 'office_city', 'office_zip',
        'office_lat', 'office_lng', 'dollar_change', 'percentage_change', 'production_ltm', 'office_county',
        'office_id', 'office_phone', 'avg_sales_price'),
      errors: {},
      isOpen,
    };
  }

  handleAddressChange(address) {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        office_address: address,
      },
    }));
  }

  handleAddressSuggestion(suggest) {
    if (!suggest) return;

    const addressComponents = suggest.gmaps.address_components;
    const readableAddress = LeadHelpers.parseAddressComponents(addressComponents);

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        office_address: suggest.label,
        office_city:    readableAddress.locality,
        office_zip:     readableAddress.postal_code,
        office_lat:     suggest.location.lat,
        office_lng:     suggest.location.lng,
      },
    }));
  }

  onEditClick(e) {
    e.preventDefault();

    this.setState({
      opened: true,
    });
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    localStorage.isRecruitingOpen = !isOpen;
  }

  onEditCloseClick(e) {
    e.preventDefault();

    this.setState({
      opened: false,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { fields } = this.state;
    const { lead, query } = this.props;

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      LeadDrawerActions.updateLead(update(fields, { id: { $set: lead.id } }), query);

      this.setState({
        opened: false,
        errors: {},
      });
    } else {
      this.setState({ errors });
    }
  }

  setFormField(name, val) {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: val,
      },
    }));
  }

  handleOfficePhoneChange = (phone_number) => {
    this.setFormField('office_phone', phone_number);
  };

  validate() {
    const errors = {};
    const { fields } = this.state;
    const { office_phone } = fields;

    if (!_lodash.isEmpty(office_phone) && !isMobilePhone(office_phone, ['en-US'])) {
      errors.office_phone = 'Is invalid';
    }

    return errors;
  }

  renderContent() {
    const { isOpen } = this.state;
    const { lead } = this.props;

    return (
      <div>
        <div className="pull-right">
          <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={this.onCollapseClick}>
            { isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
          { Rails.abilities.manageLead && (
            <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditClick}>
              <FontAwesomeIcon icon={['far', 'fa-edit']} />
              {' '}
              Edit
            </button>
          )}
        </div>

        <h4 className="mb15">Recruiting</h4>

        <Collapse isOpen={isOpen}>
          <table className="table table-flush table-sm no-border">
            <tbody>
              <tr>
                <th width="40%" className="text-grey-dark">Owner</th>
                <td width="60%" className="text-black">
                  <TinyUser user={lead.owner} />
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">Referrer</th>
                <td className="text-black">
                  <TinyUser user={lead.referrer} />
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">Referral Date</th>
                <td className="text-black">{lead.referred_at && Moment(lead.referred_at).format('LL')}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Company</th>
                <td className="text-black">
                  <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>{lead.currently_at}</div>
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">Office Address</th>
                <td className="text-black">
                  { lead.office_address && (
                    <div style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                      <span className="mr5">{lead.office_address}</span>
                      <a href={`https://www.google.com/maps/place/${lead.office_address}`} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['far', 'fa-external-link-alt']} />
                      </a>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th width="40%" className="text-grey-dark">Office Phone</th>
                <td width="60%">
                  <a href={`tel:${lead.office_phone}`}>{LeadHelpers.renderPhoneNumber(lead.office_phone)}</a>
                </td>
              </tr>
              { GlobalContainer.product() === 'recruiting' && (
                <>
                  <tr>
                    <th className="text-grey-dark">Office County</th>
                    <td className="text-black">{lead.office_county}</td>
                  </tr>
                  <tr>
                    <th className="text-grey-dark">Office ID</th>
                    <td className="text-black">{lead.office_id}</td>
                  </tr>
                </>
              )}
              <tr>
                <th className="text-grey-dark">Source</th>
                <td className="text-black">{lead.source}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Total $ Production LTM</th>
                <td className="text-black">
                  {lead.production_ytd && <span className="mr5">{LeadHelpers.renderProductionValue(lead.production_ytd)}</span>}
                  {lead.production_label && LeadHelpers.renderProductionBadge(lead)}
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">Total # Production LTM</th>
                <td className="text-black">{lead.production_ltm}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">$ Change</th>
                <td className="text-black">{LeadHelpers.renderProductionValue(lead.dollar_change)}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">% Change</th>
                <td className="text-black">{lead.percentage_change && `${lead.percentage_change} % `}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Average Sales Price</th>
                <td className="text-black">{LeadHelpers.renderProductionValue(lead.avg_sales_price)}</td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </div>
    );
  }

  renderForm() {
    const { lead } = this.props;
    const { fields, errors } = this.state;

    return (
      <div>
        <div className="text-right">
          <button type="button" className="btn btn-secondary btn-sm" onClick={this.onEditCloseClick}>
            <FontAwesomeIcon icon={['fas', 'fa-times']} />
            {' '}
            Close
          </button>
        </div>

        <form id="edit-lead-form" onSubmit={this.onFormSubmit}>
          <div className="form-group optional mb15">
            <label htmlFor="lead_owner" className="form-control-label">Owner</label>
            <TeammatePicker
              id="lead_owner_id"
              name="owner_id"
              placeholder="Owner"
              value={fields.owner_id}
              onChange={(val) => this.setFormField('owner_id', val ? val.value : '')}
              clearable
            />
          </div>

          <div className="form-group optional mb15">
            <TeamMemberPicker
              id="lead_referrer_id"
              name="referrer_id"
              label="Referrer"
              addable={{ title: 'Add Referrer' }}
              placeholder="Referrer"
              value={fields.referrer_id}
              onChange={(val) => this.setFormField('referrer_id', val ? val.value : '')}
              clearable
            />
          </div>

          <div className="form-group optional mb15">
            <label htmlFor="lead_referred_at" className="form-control-label optional">Referred On</label>
            <DateTimePicker
              id="lead_referred_at"
              placeholder="Select the Date"
              format="MMM Do YYYY"
              pickerType="date"
              value={Moment(fields.referred_at)?.format('MMM Do YYYY')}
              onChange={(val) => {
                this.setFormField('referred_at', val && Moment(val)?.format('YYYY-MM-DD'));
              }}
            />
          </div>

          <div className="form-group string optional lead_currently_at mb15">
            <label htmlFor="lead_currently_at" className="form-control-label string optional">Company</label>
            <input
              id="lead_currently_at"
              name="currently_at"
              type="text"
              className="form-control string optional"
              defaultValue={fields.currently_at}
              onChange={(val) => this.setFormField('currently_at', val.target.value)}
            />
          </div>

          <div className="form-group string optional lead_office_address mb15">
            <label htmlFor="lead_office_address" className="form-control-label string optional">Office Str. Address</label>
            <Geosuggest
              country={['us', 'ca']}
              inputClassName="form-control"
              suggestsClassName={classNames('card p0 mt5', `b-${GlobalContainer.productColor()}`)}
              suggestsHiddenClassName="d-none"
              suggestItemClassName="p10"
              id="lead_office_address"
              ref={(input) => this.leadOfficeAddressInput = input}
              name="office_address"
              placeholder="e.g. P.O. Box 6752 Denver, CO 80206"
              initialValue={lead.office_address}
              onChange={this.handleAddressChange}
              onSuggestSelect={this.handleAddressSuggestion}
            />
          </div>

          <div className="form-group string optional lead_office_phone mb15">
            <label htmlFor="lead_office_phone" className="form-control-label string optional">Office Phone</label>
            <PhoneInput
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              disableDropdown
              placeholder=""
              defaultMask="(...) ...-...."
              value={LeadHelpers.nationalFormatPhoneNumber(fields.office_phone)}
              onChange={this.handleOfficePhoneChange}
              specialLabel={false}
              inputProps={{
                id:        'lead_office_phone',
                name:      'office_phone',
                className: classNames('form-control string office_phone optional', { 'has-error': !!errors.office_phone }),
              }}
            />
          </div>

          { GlobalContainer.product() === 'recruiting' && (
            <>
              <div className="form-group string optional lead_office_county mb15">
                <label htmlFor="lead_office_county" className="form-control-label string optional">Office County</label>
                <input
                  id="lead_office_county"
                  name="office_county"
                  type="text"
                  className="form-control string optional"
                  defaultValue={fields.office_county}
                  onChange={(val) => this.setFormField('office_county', val.target.value)}
                />
              </div>

              <div className="form-group string optional lead_office_id mb15">
                <label htmlFor="lead_office_id" className="form-control-label string optional">Office ID</label>
                <input
                  id="lead_office_id"
                  name="office_id"
                  type="text"
                  className="form-control string optional"
                  defaultValue={fields.office_id}
                  onChange={(val) => this.setFormField('office_id', val.target.value)}
                />
              </div>
            </>
          )}

          <div className="field-group mb15">
            <label htmlFor="source" className="d-block">Source</label>
            <SourceSelect
              id="source"
              name="source"
              clearable
              value={fields.source}
              onChange={(val) => this.setFormField('source', val ? val.value : '')}
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="total_dollar_prod_ltm" className="d-block">Total $ Production LTM</label>
            <CurrencyInput
              id="total_dollar_prod_ltm"
              name="production_ytd"
              value={fields.production_ytd}
              onChange={(val) => this.setFormField('production_ytd', val)}
              size="9"
            />
          </div>

          <div className="form-group mb15">
            <label htmlFor="lead_production_ltm" className="d-block">Total # Production LTM</label>
            <NumberFormat
              id="lead_production_ltm"
              name="production_ltm"
              isNumericString
              allowNegative={false}
              decimalSeparator={false}
              format="#######"
              className="form-control string optional"
              value={fields.production_ltm}
              onChange={(val) => this.setFormField('production_ltm', val.target.value)}
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="dollar_change" className="d-block">$ Change</label>
            <CurrencyInput
              id="dollar_change"
              name="dollar_change"
              value={fields.dollar_change}
              onChange={(val) => this.setFormField('dollar_change', val)}
              size="9"
            />
          </div>

          <div className="form-group mb15">
            <label htmlFor="lead_percentage_change" className="d-block">% Change</label>
            <PercentageInput
              id="lead_percentage_change"
              name="percentage_change"
              value={fields.percentage_change}
              onChange={(val) => this.setFormField('percentage_change', val)}
            />
          </div>

          <div className="field-group mb15">
            <label htmlFor="production_label" className="d-block">Production Label</label>
            <ProductionLabelSelect
              id="production_label"
              name="production_label"
              value={fields.production_label}
              clearable
              onChange={(val) => this.setFormField('production_label', val ? val.value : '')}
            />
          </div>

          <div className="form-group mb15">
            <button type="submit" className="btn btn-success">Save</button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { opened } = this.state;

    if (opened) return this.renderForm();

    return this.renderContent();
  }
}

LeadDrawerRecruitingDetails.defaultProps = {
  lead:   null,
  query:  null,
  opened: false,
};

LeadDrawerRecruitingDetails.propTypes = {
  lead:   PropTypes.shape({}),
  query:  PropTypes.shape({}),
  opened: PropTypes.bool,
};

export default LeadDrawerRecruitingDetails;
