import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autoBind            from 'react-autobind';

import AppModal     from '../app_modal';
import RecordVideo  from './forms/RecordVideo';
import UploadVideo  from './forms/UploadVideo';
import VideoLibrary from './VideoLibrary';

class VideosModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'recordVideo',
    };

    this.appModal = React.createRef();

    autoBind(this);
  }

  onChooseView = (e, view) => {
    e.preventDefault();

    this.setState({ view });
  }

  closeModal = () => {
    const modalRef = this.appModal.current;
    const $modal = $(modalRef.modal);

    $modal.modal('hide');
  }

  getComponent = () => {
    const { view } = this.state;
    const { editor, sms } = this.props;

    switch (view) {
      case 'recordVideo':
        return (
          <RecordVideo
            onChooseView={this.onChooseView}
            editor={editor}
            closeModal={this.closeModal}
            sms={sms}
          />
        );
      case 'uploadVideo':
        return (
          <UploadVideo
            onChooseView={this.onChooseView}
            editor={editor}
            closeModal={this.closeModal}
            sms={sms}
          />
        );
      case 'videoLibrary':
        return (
          <VideoLibrary
            onChooseView={this.onChooseView}
            editor={editor}
            closeModal={this.closeModal}
            sms={sms}
          />
        );
      default:
        return (
          <>
            <div className="modal-body">
              <div className="alert alert-warning">
                Oh shoot, looks like we had an issue.
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </>
        );
    }
  }

  render() {
    const {
      containerID,
      modalClass,
      dialogClass,
      sms,
    } = this.props;

    return (
      <AppModal
        containerID={containerID}
        modalClass={modalClass}
        dialogClass={dialogClass}
        ref={this.appModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="bkModalVideos">
            <FontAwesomeIcon icon="far fa-video-plus" />
            {' '}
            Insert a video
          </h5>

          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {this.getComponent()}
      </AppModal>
    );
  }
}

VideosModal.defaultProps = {
  containerID: '',
  modalClass:  '',
  dialogClass: '',
  editor:      {},
  sms:         false,
};

VideosModal.propTypes = {
  containerID: PropTypes.string,
  modalClass:  PropTypes.string,
  dialogClass: PropTypes.string,
  editor:      PropTypes.shape({}),
  sms:         PropTypes.bool,
};

export default VideosModal;
