import autoBind            from 'react-autobind';
import React               from 'react';
import ReactDOM            from 'react-dom';
import { PropTypes }       from 'prop-types';
import { Collapse }        from 'reactstrap';
import ReactPaginate       from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit       from '~/lib/brokerbit';
import Tooltipable     from '~/components/effects/tooltipable';
import TaskActions     from '~/actions/task_actions';
import TaskStore       from '~/stores/task_store';
import LeadDrawerStore from '~/stores/lead_drawer_store';
import LeadHelpers     from '~/helpers/lead_helpers';
import TaskModal       from '~/components/modals/task_modal';

class LeadDrawerTasks extends React.Component {
  constructor(props) {
    super(props);

    const isOpen = localStorage.isTasksOpen
      ? JSON.parse(localStorage.isTasksOpen)
      : true;

    this.state = {
      isOpen,
      tasks:      [],
      pagination: {},
    };

    autoBind(this);
  }

  componentDidMount() {
    const { lead } = this.props;

    // listen to TaskStore changes
    this.taskStoreListener = TaskStore.addListener(
      this.onTaskStoreChange,
    );
    this.leadDrawerStoreListener = LeadDrawerStore.addListener(
      this.onLeadDrawerStoreChange,
    );

    setTimeout(() => {
      TaskActions.loadTasks(lead.id);
    });
  }

  componentWillUnmount() {
    // remove listener to TaskStore changes on Unmount
    if (this.taskStoreListener) this.taskStoreListener.remove();
    if (this.leadDrawerStoreListener) {
      this.leadDrawerStoreListener.remove();
    }
  }

  handleTaskDelete(e, task) {
    e.preventDefault();

    const { lead } = this.props;
    const { pagination } = this.state;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this task?',
      callback: (ok) => {
        if (ok) {
          TaskActions.deleteTask(
            task,
            lead.id,
            pagination.current_page,
          );
        }
      },
    });
  }

  handleEditTaskClick(e, task) {
    e.preventDefault();

    const { lead } = this.props;

    ReactDOM.render(
      <TaskModal
        taskID={task.id}
        lead={lead}
        containerID="secondary-modal"
        modalClass="modal modal-overlay"
        dialogClass="modal-dialog modal-lg"
      />,
      document.getElementById('secondary-modal'),
    );
  }

  handleMarkAsDoneTaskClick(e, task) {
    e.preventDefault();

    const { lead } = this.props;
    const { pagination } = this.state;

    const taskData = { id: task.id, leadID: lead.id, status: 'done' };

    TaskActions.updateTask(taskData, pagination.current_page);

    GlobalContainer.notify('Task Marked as Done');
  }

  onTaskStoreChange() {
    const taskStoreState = TaskStore.getState();
    const { pagination, tasks, lastTaskStoreAction } = taskStoreState;

    if (lastTaskStoreAction === 'loadTasksDone') {
      this.setState({ tasks, pagination });
    }
  }

  onLeadDrawerStoreChange() {
    const leadDrawerStoreState = LeadDrawerStore.getState();
    const { lead, leadDrawerStoreAction } = leadDrawerStoreState;

    if (
      leadDrawerStoreAction === 'updateLeadDone'
      || leadDrawerStoreAction === 'loadLeadDone'
    ) {
      setTimeout(() => {
        TaskActions.loadTasks(lead.id);
      });
    }
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    localStorage.isTasksOpen = !isOpen;
  }

  onPageChange(page) {
    const { lead } = this.props;
    const selectedPage = page.selected;

    TaskActions.loadTasks(lead.id, selectedPage + 1);
  }

  buildPageHref(pageNumber) {
    return `#page-${pageNumber}`;
  }

  renderStatus(status) {
    if (!status) return null;

    switch (status) {
      case 'to_do':
        return (
          <span className="badge badge-primary" title="To Do">
            To Do
          </span>
        );
      case 'in_progress':
        return (
          <span className="badge badge-info" title="In Progress">
            In Progress
          </span>
        );
      case 'done':
        return (
          <span className="badge badge-success" title="Done">
            Done
          </span>
        );
      case 'canceled':
        return (
          <span className="badge badge-danger" title="Canceled">
            Canceled
          </span>
        );
      default:
        return (
          <span className="badge badge-warning" title={status}>
            {status}
          </span>
        );
    }
  }

  renderTask(task) {
    return (
      <tr key={task.id}>
        <td className="pl0">
          <strong>
            {task.name}
            :
          </strong>
          {' '}
          {Moment(task.due_date_at).format('L')}
          <br />
          Owner:
          {' '}
          {task.owner_name}
          <br />
          Category:
          {' '}
          {task.category}
          <br />
          Priority:
          {' '}
          {LeadHelpers.renderTaskPriority(task.priority)}
        </td>

        <td className="text-center">
          {this.renderStatus(task.status)}
        </td>

        {Rails.abilities.manageTasks && (
          <td className="text-lg-right pr0">
            {(task.status === 'to_do'
              || task.status === 'in_progress') && (
              <Tooltipable text="Mark Task as Done">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={(e) => this.handleMarkAsDoneTaskClick(e, task)}
                >
                  <FontAwesomeIcon
                    icon={['far', 'fa-check-square']}
                    size="sm"
                  />
                </button>
              </Tooltipable>
            )}
            <Tooltipable text="Edit Task">
              <button
                type="button"
                className="btn btn-secondary btn-sm ml-1"
                onClick={(e) => this.handleEditTaskClick(e, task)}
              >
                <FontAwesomeIcon
                  icon={['far', 'fa-edit']}
                  size="sm"
                />
              </button>
            </Tooltipable>
            <Tooltipable text="Delete Task">
              <button
                className="btn btn-secondary btn-sm ml-1"
                onClick={(e) => this.handleTaskDelete(e, task)}
                type="button"
              >
                <FontAwesomeIcon
                  icon={['far', 'fa-trash-alt']}
                  size="sm"
                />
              </button>
            </Tooltipable>
          </td>
        )}
      </tr>
    );
  }

  render() {
    const { tasks, pagination, isOpen } = this.state;

    return (
      <div>
        <div className="pull-right">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={this.onCollapseClick}
          >
            {isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
        </div>

        <h4 className="mb15">Tasks</h4>

        <Collapse isOpen={isOpen}>
          {_lodash.isEmpty(tasks) && <p>No Tasks</p>}
          <div className="table-responsive">
            <table className="table">
              <tbody>{tasks.map(this.renderTask)}</tbody>
            </table>
          </div>
          {pagination.total_pages > 1 && (
            <div className="mb-2">
              <nav>
                <ReactPaginate
                  forcePage={pagination.current_page - 1}
                  pageCount={pagination.total_pages}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={2}
                  containerClassName="pagination"
                  activeClassName="active"
                  breakLabel="..."
                  breakLinkClassName="page-link disabled"
                  breakClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  onPageChange={this.onPageChange}
                  hrefBuilder={this.buildPageHref}
                  previousLabel={Rails.isMobile ? '<' : 'Previous'}
                  nextLabel={Rails.isMobile ? '>' : 'Next'}
                />
              </nav>
            </div>
          )}
        </Collapse>
      </div>
    );
  }
}

LeadDrawerTasks.defaultProps = {
  lead: null,
};

LeadDrawerTasks.propTypes = {
  lead: PropTypes.shape({}),
};

export default LeadDrawerTasks;
