import classNames    from 'classnames';
import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

class TeamMembersTab extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleTabClick(selectedTab) {
    const { handleTabSwitch } = this.props;

    handleTabSwitch(selectedTab);
  }

  render() {
    const { memberRole, tabs } = this.props;
    const selected = memberRole || tabs[0];

    const tabItems = tabs.map((tab) => {
      let tabName = _lodash.titleize(tab);
      if (tab === 'members') tabName = 'All Members';

      return (
        <li className="nav-item" key={tab}>
          <a
            className={classNames('nav-link', { active: selected === tab })}
            href={`#${tab}`}
            onClick={(e) => {
              e.preventDefault();
              this.handleTabClick(tab);
            }}
          >
            {tabName}
          </a>
        </li>
      );
    });

    return (
      <ul className="nav nav-tabs">
        {tabItems}
      </ul>
    );
  }
}

TeamMembersTab.defaultProps = {
  tabs:            [],
  memberRole:      null,
  handleTabSwitch: () => false,
};

TeamMembersTab.propTypes = {
  tabs:            PropTypes.arrayOf(PropTypes.string),
  memberRole:      PropTypes.string,
  handleTabSwitch: PropTypes.func,
};

export default TeamMembersTab;
