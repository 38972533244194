import React         from 'react';
import { PropTypes } from 'prop-types';
import Select        from '~/components/forms/select';

const CallDispositionSelect = (props) => {
  const options = [
    { value: 'no_answer', label: 'No Answer' },
    { value: 'closed', label: 'Completed' },
    { value: 'voicemail', label: 'Voicemail' },
    { value: 'busy', label: 'Busy' },
    { value: 'wrong_number', label: 'Wrong Number' },
    { value: 'canceled', label: 'Canceled' },
    { value: 'failed', label: 'Failed' },
  ];

  return <Select {...props} options={options} />;
};

CallDispositionSelect.defaultProps = {
  onChange:    () => {},
  value:       '',
};

CallDispositionSelect.propTypes = {
  onChange:    PropTypes.func,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
};

export default CallDispositionSelect;
