import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select-4';

import APIRequest  from '~/lib/api_request';
import LeadHelpers from '~/helpers/lead_helpers';

const selectAllOption = {
  id:         '<SELECT_ALL>',
  label:      'All Phone Numbers',
  twilio_sid: '',
};

const customStyles = {
  control: (styles, { isInvalid }) => ({
    ...styles,
    border:          isInvalid ? '1px solid #fd3737' : '',
    backgroundColor: isInvalid ? '#ffebeb' : '',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#16b783',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
  }),
};

export const MessagingServicePhoneNumbersSelect = (props) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    APIRequest.get({
      resource: '/v1/twilio_verified_texting/sms_numbers',
    }).end((error, response) => {
      if (!error) {
        setOptions(response.body.sms_numbers.map((number) => ({
          label:      `${LeadHelpers.nationalFormatPhoneNumber(number.service_number)} ${number.user_name}`,
          id:         number.id,
          twilio_sid: number.twilio_sid,
        })));

        setLoading(false);
      }
    });
  }, []);

  const isSelectAllSelected = () => props.value.length === options.length;

  const isOptionSelected = (option) => props.value.some(({ id }) => id === option.id)
    || isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...options];

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : options.filter((obj) => _lodash.map(props.value, 'id').includes(obj.id)));

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.id === selectAllOption.id) {
      props.onChange(options);
    } else if ((action === 'deselect-option' && option.id === selectAllOption.id) || (action === 'remove-value' && removedValue.id === selectAllOption.id)) {
      props.onChange([]);
    } else if (action === 'deselect-option' && isSelectAllSelected()) {
      props.onChange(
        options.filter(({ id }) => id !== option.id),
      );
    } else {
      props.onChange(newValue || []);
    }
  };

  return (
    <ReactSelect
      getOptionValue={({ id }) => id}
      isLoading={loading}
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      id={props.id}
      placeholder="Select Phone Numbers ..."
      styles={customStyles}
    />
  );
};
