import React               from 'react';
import autoBind            from 'react-autobind';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest from '~/lib/api_request';

class RecordingStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading:        true,
      recordingStats: {},
    };

    autoBind(this);
  }

  componentDidMount() {
    this.loadRecordingStats();
  }

  loadRecordingStats() {
    APIRequest.get({ resource: '/v1/statistics/voice_recordings' })
      .end((err, response) => {
        if (response && response.ok) {
          const recordingStats = response.body;

          this.setState({ recordingStats, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
  }

  renderRecordingStatisticTable() {
    const { loading, recordingStats } = this.state;

    if (loading) {
      return (
        <tbody className="text-center">
          <tr>
            <td colSpan="3">
              <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        <tr>
          <th scope="row">Today</th>
          <td className="text-center">{recordingStats.inboundRecordingsToday}</td>
          <td className="text-center">{recordingStats.outboundRecordingsToday}</td>
        </tr>
        <tr>
          <th scope="row">This Week</th>
          <td className="text-center">{recordingStats.inboundRecordingsThisWeek}</td>
          <td className="text-center">{recordingStats.outboundRecordingsThisWeek}</td>
        </tr>
        <tr>
          <th scope="row">This Month</th>
          <td className="text-center">{recordingStats.inboundRecordingsThisMonth}</td>
          <td className="text-center">{recordingStats.outboundRecordingsThisMonth}</td>
        </tr>
        <tr>
          <th scope="row">Last Month</th>
          <td className="text-center">{recordingStats.inboundRecordingsLastMonth}</td>
          <td className="text-center">{recordingStats.outboundRecordingsLastMonth}</td>
        </tr>
      </tbody>
    );
  }

  render() {
    return (
      <div className="table-responsive col-md-12 col-lg-6">
        <table className="table table-bordered statistic-table">
          <thead>
            <tr>
              <th scope="col">Call recording minutes</th>
              <th scope="col" className="text-center">Inbound</th>
              <th scope="col" className="text-center">Outbound</th>
            </tr>
          </thead>
          {this.renderRecordingStatisticTable()}
        </table>
      </div>
    );
  }
}

RecordingStats.defaultProps = {};

RecordingStats.propTypes = {};

export default RecordingStats;
