import React       from 'react';
import PropTypes   from 'prop-types';

import Select from '~/components/forms/select';

class DuplicatesSelect extends React.Component {
  constructor(props) {
    super(props);

    const options = [
      { value: 'duplicates_by_email',    label: 'Duplicate Email Address' },
      { value: 'duplicates_by_agent_id', label: 'Duplicate Agent ID' },
      { value: 'duplicates_by_name',     label: 'Duplicate Name' },
    ];

    this.state = {
      options,
      value:   props.value,
    };
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : null;

    this.setState({ value }, () => {
      this.props.onChange(selectedOption);
    });
  }

  render() {
    const { placeholder, multi, clearable } = this.props;

    const { value, options } = this.state;

    return (
      <Select
        clearable={clearable}
        placeholder={placeholder}
        value={value}
        onChange={this.handleSelectChange}
        options={options}
      />
    );
  }
}

DuplicatesSelect.propTypes = {
  onChange:    PropTypes.func,
  placeholder: PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  clearable:   PropTypes.bool,
};

DuplicatesSelect.defaultProps = {
  onChange:    () => {},
  placeholder: '--- Select a Filter ---',
  value:       null,
  clearable:   false,
};

export default DuplicatesSelect;
