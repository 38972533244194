import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GroupItem = memo(({
  item,
  showLink,
  updatingAllNotifications,
  markAllNotificationsAsRead,
}) => (
  <div className="py-2 px-3 small text-grey-dark d-flex justify-content-between align-items-center border-bottom">
    {item.name}
    {showLink && (
      updatingAllNotifications ? (
        <FontAwesomeIcon icon="fa-spinner" className="text-secondary" size="xs" spin />
      ) : (
        <a
          type="button"
          className="link text-grey-dark"
          onClick={markAllNotificationsAsRead}
        >
          Mark all as read
        </a>
      )
    )}
  </div>
));

export default GroupItem;
