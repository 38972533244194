import { ReduceStore } from 'flux/utils';
import AppDispatcher   from '~/dispatchers/app_dispatcher';
import TwilioVerifiedTextingActionTypes from '~/types/twilio_verified_texting_action_types';

class TwilioVerifiedTextingStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loading:                              false,
      brand:                                null,
      lastTwilioVerifiedTextingStoreAction: null,
      errors:                               {},
      error:                                false,
      creating:                             false,
      saving:                               false,
    };
  }

  loadVerifiedTexting(state, action) {
    const newState = { ...state };

    newState.lastTwilioVerifiedTextingStoreAction = 'loadVerifiedTexting';
    newState.loading = true;
    newState.error = false;

    return newState;
  }

  loadVerifiedTextingDone(state, action) {
    const newState = { ...state };

    newState.brand = action.verified_texting.brand;
    newState.lastTwilioVerifiedTextingStoreAction = 'loadVerifiedTextingDone';
    newState.loading = false;
    newState.error = false;

    return newState;
  }

  loadVerifiedTextingFail(state, action) {
    const newState = { ...state };

    newState.loading = false;
    newState.error = true;
    newState.lastTwilioVerifiedTextingStoreAction = 'loadVerifiedTextingFail';

    return newState;
  }

  createVerifiedTexting(state, action) {
    const newState = { ...state };

    newState.lastTwilioVerifiedTextingStoreAction = 'createVerifiedTexting';
    newState.creating = true;

    return newState;
  }

  createVerifiedTextingDone(state, action) {
    const newState = { ...state };

    newState.brand = action.verified_texting.brand;
    newState.lastTwilioVerifiedTextingStoreAction = 'createVerifiedTextingDone';
    newState.creating = false;
    newState.errors = {};

    return newState;
  }

  createVerifiedTextingFail(state, action) {
    const newState = { ...state };

    newState.creating = false;
    newState.errors   = action.error.response.body.errors;
    newState.lastTwilioVerifiedTextingStoreAction = 'createVerifiedTextingFail';

    return newState;
  }

  updateVerifiedTexting(state, action) {
    const newState = { ...state };

    newState.lastTwilioVerifiedTextingStoreAction = 'updateVerifiedTexting';
    newState.saving = true;

    return newState;
  }

  updateVerifiedTextingDone(state, action) {
    const newState = { ...state };

    newState.brand = action.verified_texting.brand;
    newState.saving = false;
    newState.errors = {};
    newState.lastTwilioVerifiedTextingStoreAction = 'updateVerifiedTextingDone';

    return newState;
  }

  updateVerifiedTextingFail(state, action) {
    const newState = { ...state };

    newState.saving = false;
    newState.errors = action.error.response.body.errors;
    newState.lastTwilioVerifiedTextingStoreAction = 'updateVerifiedTextingFail';

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case TwilioVerifiedTextingActionTypes.LOAD_TWILIO_VERIFIED_TEXTING:

        return this.loadVerifiedTexting(state, action);
      case TwilioVerifiedTextingActionTypes.LOAD_TWILIO_VERIFIED_TEXTING_DONE:

        return this.loadVerifiedTextingDone(state, action);
      case TwilioVerifiedTextingActionTypes.LOAD_TWILIO_VERIFIED_TEXTING_FAIL:

        return this.loadVerifiedTextingFail(state, action);
      case TwilioVerifiedTextingActionTypes.CREATE_TWILIO_VERIFIED_TEXTING:

        return this.createVerifiedTexting(state, action);
      case TwilioVerifiedTextingActionTypes.CREATE_TWILIO_VERIFIED_TEXTING_DONE:

        return this.createVerifiedTextingDone(state, action);
      case TwilioVerifiedTextingActionTypes.CREATE_TWILIO_VERIFIED_TEXTING_FAIL:

        return this.createVerifiedTextingFail(state, action);
      case TwilioVerifiedTextingActionTypes.UPDATE_TWILIO_VERIFIED_TEXTING:

        return this.updateVerifiedTexting(state, action);
      case TwilioVerifiedTextingActionTypes.UPDATE_TWILIO_VERIFIED_TEXTING_DONE:

        return this.updateVerifiedTextingDone(state, action);
      case TwilioVerifiedTextingActionTypes.UPDATE_TWILIO_VERIFIED_TEXTING_FAIL:

        return this.updateVerifiedTextingFail(state, action);
      default:
        return state;
    }
  }
}

export default new TwilioVerifiedTextingStore();
