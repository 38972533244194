import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

const ClickableTooltip = ({
  children,
  trigger,
  placement,
  target,
  innerClassName,
  text,
  ...otherProps
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipObject = typeof text === 'object';

  return (
    <>
      {children}
      <Tooltip
        trigger={trigger}
        placement={placement}
        isOpen={tooltipOpen}
        autohide={false}
        target={target}
        toggle={toggle}
        innerClassName={innerClassName}
        {...otherProps}
      >
        {tooltipObject ? (
          <span>{text}</span>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </Tooltip>
    </>
  );
};

ClickableTooltip.defaultProps = {
  children:       null,
  trigger:        'hover',
  placement:      'top',
  innerClassName: '',
};

ClickableTooltip.propTypes = {
  children:       PropTypes.node,
  trigger:        PropTypes.string,
  text:           PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement:      PropTypes.string,
  target:         PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  innerClassName: PropTypes.string,
};

export default ClickableTooltip;
