import autoBind      from 'react-autobind';
import classNames    from 'classnames';
import React         from 'react';
import { PropTypes } from 'prop-types';
import isEmail       from 'validator/lib/isEmail';

import Select from '~/components/forms/select';

class EmailSelect extends React.Component {
  constructor(props) {
    super(props);

    let selectedEmails = [];
    let selectedOptions = [];

    const { multi, localStorageKey, value } = props;

    if (multi) {
      if (localStorageKey && localStorage[localStorageKey]) {
        selectedEmails = localStorage[localStorageKey].split(',');
      } else if (value) {
        selectedEmails = value.split(',');
      }
      selectedOptions = _lodash.map(selectedEmails, (email) => ({ value: email, label: email }));
    }

    this.state = {
      multiValue: selectedEmails,
      options:    selectedOptions,
      value:      props.value,
    };

    autoBind(this);
  }

  handleOnChange(value) {
    const { multi, localStorageKey, onChange } = this.props;

    if (multi) {
      this.setState({ multiValue: value });
      if (localStorageKey) localStorage[localStorageKey] = _lodash.map(value, (select) => select.value).join(',');
    } else {
      this.setState({ value });
      if (localStorageKey) localStorage[localStorageKey] = value;
    }
    if (onChange) { onChange(value); }
  }

  isValidNewOption(value) {
    return value.label && isEmail(value.label);
  }

  render() {
    const { multi, className } = this.props;
    const { multiValue, options, value } = this.state;

    return (
      <Select
        {...this.props}
        creatable
        multi={multi}
        options={options}
        onChange={this.handleOnChange}
        noResultsText="Type an email address"
        isValidNewOption={this.isValidNewOption}
        promptTextCreator={(name) => `Add ${name}`}
        className={classNames(className, 'Select--no-arrow')}
        value={multi ? multiValue : value}
      />
    );
  }
}

EmailSelect.defaultProps = {
  name:            '',
  multi:           true,
  value:           null,
  localStorageKey: null,
  onChange:        () => false,
};

EmailSelect.propTypes = {
  name:            PropTypes.string,
  multi:           PropTypes.bool,
  value:           PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  localStorageKey: PropTypes.string,
  onChange:        PropTypes.func,
};

export default EmailSelect;
