import autoBind     from 'react-autobind';
import React        from 'react';
import PropTypes    from 'prop-types';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LeadDrawerRelitixDetails extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    const isOpen = localStorage.isRelitixOpen ? JSON.parse(localStorage.isRelitixOpen) : false;

    this.state = {
      isOpen,
    };
  }

  onCollapseClick(e) {
    e.preventDefault();

    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
    localStorage.isRelitixOpen = !isOpen;
  }

  render() {
    const { isOpen } = this.state;
    const { lead } = this.props;
    const {
      closest_office_name,
      closest_office_address,
      dist_to_closest_office,
      rookie_potential_rating,
      first_trans_date_current_office,
      switch_color,
      list_eff_grade,
      list_eff_impact,
      five_yr_closed_pct,
      five_yr_tot_listings,
      production_zip_code,
      dist_to_office,
      first_trans_date,
      update_date,
    } = lead.relitix_attributes || {};

    const currentFormat = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    return (
      <div>
        <div className="pull-right">
          <button type="button" className="btn btn-secondary btn-sm mr-1" onClick={this.onCollapseClick}>
            { isOpen ? (
              <FontAwesomeIcon icon={['far', 'fa-minus']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'fa-plus']} />
            )}
          </button>
        </div>

        <h4 className="mb15">Relitix</h4>

        <Collapse isOpen={isOpen}>
          <table className="table table-flush table-sm no-border">
            <tbody>
              <tr>
                <th width="45" className="text-grey-dark mr-3">Our Closest Office:</th>
                <td width="55%" className="text-black">{closest_office_name}</td>
              </tr>
              <tr>
                <th width="45" className="text-grey-dark mr-3">Our Closest Office Address:</th>
                <td width="55%" className="text-black">{ closest_office_address }</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Dist. to Our Closest Office:</th>
                <td className="text-black">
                  { dist_to_closest_office ? `${dist_to_closest_office} mi` : ''}
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">Dist. to Current Office:</th>
                <td className="text-black">
                  { dist_to_office ? `${dist_to_office} mi` : ''}
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">Center of Production Zip:</th>
                <td className="text-black">{production_zip_code}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">First Transaction at Current Office:</th>
                <td className="text-black">
                  {first_trans_date_current_office ?  Moment(first_trans_date_current_office).format('L') : ''}
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">First Transaction in MLS:</th>
                <td className="text-black">
                  {first_trans_date ? Moment(first_trans_date).format('L') : ''}
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">Rookie Rating:</th>
                <td className="text-black">{rookie_potential_rating === null ? '' : rookie_potential_rating}</td>
              </tr>

              <tr>
                <th className="text-grey-dark">Current Switch Risk:</th>
                <td className="text-black">{switch_color}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Listing Effectiveness Grade:</th>
                <td className="text-black">{list_eff_grade}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Listing Effectiveness Impact:</th>
                <td className="text-black">
                  {list_eff_impact ? currentFormat.format(list_eff_impact) : ''}
                </td>
              </tr>
              <tr>
                <th className="text-grey-dark">5 Year Batting Avg:</th>
                <td className="text-black">{ five_yr_closed_pct ? `${five_yr_closed_pct * 100}%` : ''}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">5 Year Listing Count:</th>
                <td className="text-black">{five_yr_tot_listings}</td>
              </tr>
              <tr>
                <th className="text-grey-dark">Last Updated:</th>
                <td className="text-black">
                  {update_date ? Moment(update_date).format('MM/DD/YYYY h:mm A') : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </div>
    );
  }
}

LeadDrawerRelitixDetails.defaultProps = {
  lead: null,
};

LeadDrawerRelitixDetails.propTypes = {
  lead: PropTypes.shape({}),
};

export default LeadDrawerRelitixDetails;
