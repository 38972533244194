import AppDispatcher          from '~/dispatchers/app_dispatcher';
import APIRequest             from '~/lib/api_request';
import TeamMembersActionTypes from '~/types/team_members_action_types';

let teamMembersRequest;
let createTeamMemberRequest;
let updateTeamMemberRequest;
let deleteTeamMemberRequest;

const TeamMembersActions = {
  loadTeamMembers(memberRole, memberQuery = { page: 1, per: 10 }) {
    APIRequest.abort(teamMembersRequest);

    AppDispatcher.dispatch({
      type: TeamMembersActionTypes.LOAD_TEAM_MEMBERS,
      memberRole,
    });

    let resource;
    switch (memberRole) {
      case 'admins':
        resource = '/v1/team/managers';
        break;
      case 'staff':
        resource = '/v1/team/staff';
        break;
      case 'agents':
        resource = '/v1/team/agents';
        break;
      case 'affiliates':
        resource = '/v1/team/affiliates';
        break;
      case 'members':
        resource = '/v1/team/members';
        break;
      default:
        resource = null;
    }

    if (!resource) return;

    teamMembersRequest = APIRequest.get({
      resource,
      data: memberQuery,
    });

    teamMembersRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMembersActionTypes.LOAD_TEAM_MEMBERS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:        TeamMembersActionTypes.LOAD_TEAM_MEMBERS_DONE,
        teamMembers: response.body,
        memberRole,
      });
    });
  },

  createTeamMember(teamMember, memberRole, searchQuery, currentPage) {
    APIRequest.abort(createTeamMemberRequest);

    AppDispatcher.dispatch({
      type: TeamMembersActionTypes.CREATE_TEAM_MEMBER,
      teamMember,
    });

    createTeamMemberRequest = APIRequest.post({
      resource: '/v1/team/members',
      data:     teamMember,
    });

    createTeamMemberRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMembersActionTypes.CREATE_TEAM_MEMBER_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:       TeamMembersActionTypes.CREATE_TEAM_MEMBER_DONE,
        teamMember: response.body,
      });

      TeamMembersActions.loadTeamMembers(memberRole, { q: searchQuery, page: currentPage });
    });
  },

  updateTeamMember(teamMember, memberRole, searchQuery, currentPage) {
    APIRequest.abort(updateTeamMemberRequest);

    AppDispatcher.dispatch({
      type: TeamMembersActionTypes.UPDATE_TEAM_MEMBER,
      teamMember,
    });

    updateTeamMemberRequest = APIRequest.put({
      resource: `/v1/team/members/${teamMember.user.id}`,
      data:     teamMember,
    });

    updateTeamMemberRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMembersActionTypes.UPDATE_TEAM_MEMBER_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:       TeamMembersActionTypes.UPDATE_TEAM_MEMBER_DONE,
        teamMember: response.body,
      });

      TeamMembersActions.loadTeamMembers(memberRole, { q: searchQuery, page: currentPage });
    });
  },

  moveTeamMember(teamMember, dest, memberRole, searchQuery, currentPage) {
    APIRequest.abort(updateTeamMemberRequest);

    AppDispatcher.dispatch({
      type: TeamMembersActionTypes.MOVE_TEAM_MEMBER,
      teamMember,
    });

    updateTeamMemberRequest = APIRequest.post({
      resource: `/v1/team/members/${teamMember.id}/move`,
      data:     {
        dest,
      },
    });

    updateTeamMemberRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMembersActionTypes.MOVE_TEAM_MEMBER_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:       TeamMembersActionTypes.MOVE_TEAM_MEMBER_DONE,
        teamMember: response.body,
      });

      TeamMembersActions.loadTeamMembers(memberRole, { q: searchQuery, page: currentPage });
    });
  },

  deleteTeamMember(teamMember, memberRole, searchQuery, currentPage) {
    APIRequest.abort(deleteTeamMemberRequest);

    AppDispatcher.dispatch({
      type: TeamMembersActionTypes.DELETE_TEAM_MEMBER,
      teamMember,
    });

    deleteTeamMemberRequest = APIRequest.delete({
      resource: `/v1/team/members/${teamMember.id}`,
      data:     teamMember,
    });

    deleteTeamMemberRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: TeamMembersActionTypes.DELETE_TEAM_MEMBER_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:       TeamMembersActionTypes.DELETE_TEAM_MEMBER_DONE,
        teamMember: response.body,
      });

      TeamMembersActions.loadTeamMembers(memberRole, { q: searchQuery, page: currentPage });
    });
  },
};

export default TeamMembersActions;
