import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import update              from 'immutability-helper';
import { Input }           from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit                          from '~/lib/brokerbit';
import ErrorMessage                       from '~/components/forms/ErrorMessage';
import Select                             from '~/components/forms/select';
import SearchQueryConditionFieldSelect    from '~/components/forms/search_query_condition_field_select';
import SearchQueryConditionOperatorSelect from '~/components/forms/search_query_condition_operator_select';

const booleanValueOptions = [
  { value: '1',  label: 'TRUE' },
  { value: '0', label: 'FALSE' },
];

class SearchQueryConditionForm extends React.Component {
  constructor(props) {
    super(props);

    const { condition } = props;

    this.state = {
      search_field_id:    condition.search_field_id || '',
      search_operator_id: condition.search_operator_id || '',
      value:              condition.value || '',
      typeField:          'string',
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { product, conditionIdx, updateCondition } = this.props;

    if (prevProps.product !== product) {
      const newState = update(this.state, {
        search_field_id:    { $set: '' },
        search_operator_id: { $set: '' },
        value:              { $set: '' },
        typeField:          { $set: 'string' },
      });

      this.setState(newState, () => {
        updateCondition(conditionIdx, newState);
      });
    }
  }

  handleConditionDelete(e) {
    e.preventDefault();

    const { conditionIdx, deleteCondition } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this condition?',
      callback: (ok) => {
        if (ok) { deleteCondition(conditionIdx); }
      },
    });
  }

  handleFieldIdChange(opt) {
    const { conditionIdx, updateCondition } = this.props;

    const newState = update(this.state, {
      search_field_id:    { $set: opt ? opt.value : '' },
      search_operator_id: { $set: '' },
      value:              { $set: '' },
      typeField:          { $set: opt ? opt.type_field : 'string' },
    });

    this.setState(newState, () => {
      updateCondition(conditionIdx, newState);
    });
  }

  handleOperatorIdChange(opt) {
    const { conditionIdx, updateCondition } = this.props;

    const newState = update(this.state, {
      search_operator_id: { $set: opt ? opt.value : '' },
    });

    this.setState(newState, () => {
      updateCondition(conditionIdx, newState);
    });
  }

  handleValueChange(e) {
    e.preventDefault();

    const { conditionIdx, updateCondition } = this.props;

    const newState = update(this.state, {
      value: { $set: e.target.value },
    });

    this.setState(newState, () => {
      updateCondition(conditionIdx, newState);
    });
  }

  handleValueSelect(opt) {
    const { conditionIdx, updateCondition } = this.props;

    const newState = update(this.state, {
      value: { $set: opt ? opt.value : '' },
    });

    this.setState(newState, () => {
      updateCondition(conditionIdx, newState);
    });
  }

  handleTypeField(value) {
    const newState = update(this.state, {
      typeField: { $set: value },
    });

    this.setState(newState);
  }

  renderValueComponent() {
    const { value, typeField } = this.state;
    const { errors, conditionIdx } = this.props;

    let component;

    if (typeField === 'number') {
      component = (
        <Input
          className={classNames({ 'has-error': errors[conditionIdx] ? errors[conditionIdx].value : '' })}
          value={value}
          onChange={this.handleValueChange}
          type="number"
        />
      );
    } else if (typeField === 'boolean') {
      component = (
        <Select
          className={classNames({ 'has-error': errors[conditionIdx] ? errors[conditionIdx].value : '' })}
          value={value}
          options={booleanValueOptions}
          placeholder="--- Select ---"
          onChange={this.handleValueSelect}
        />
      );
    } else {
      component = (
        <Input
          className={classNames({ 'has-error': errors[conditionIdx] ? errors[conditionIdx].value : '' })}
          value={value}
          onChange={this.handleValueChange}
        />
      );
    }

    return (
      <>
        {component}
        <div>{errors[conditionIdx] && <ErrorMessage message={errors[conditionIdx].value} />}</div>
      </>
    );
  }

  render() {
    const { search_field_id, search_operator_id } = this.state;
    const { errors, conditionIdx, product } = this.props;

    return (
      <div className="row my-2">
        <div className="col my-1 text-center">
          <SearchQueryConditionFieldSelect
            product={product}
            value={search_field_id}
            onChange={this.handleFieldIdChange}
            handleTypeField={this.handleTypeField}
            placeholder="Select Field"
            className={classNames({ 'has-error': errors[conditionIdx] ? errors[conditionIdx].search_field_id : '' })}
          />
          <div>{errors[conditionIdx] && <ErrorMessage message={errors[conditionIdx].search_field_id} />}</div>
        </div>

        <div className="col my-1 text-center">
          <SearchQueryConditionOperatorSelect
            value={search_operator_id}
            onChange={this.handleOperatorIdChange}
            placeholder="Select Operator"
            className={classNames({ 'has-error': errors[conditionIdx] ? errors[conditionIdx].search_operator_id : '' })}
            fieldId={search_field_id}
          />
          <div>{errors[conditionIdx] && <ErrorMessage message={errors[conditionIdx].search_operator_id} />}</div>
        </div>

        <div className="col my-1 text-center">
          {this.renderValueComponent()}
        </div>

        <div className="col-lg-1 my-1 text-center">
          <button type="button" className="btn btn-outline-danger mr5" onClick={this.handleConditionDelete}>
            <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
          </button>
        </div>
      </div>
    );
  }
}

SearchQueryConditionForm.defaultProps = {
  condition:       {},
  product:         '',
  errors:          {},
  conditionIdx:    null,
  updateCondition: () => false,
  deleteCondition: () => false,
};

SearchQueryConditionForm.propTypes = {
  condition:       PropTypes.shape({}),
  product:         PropTypes.string,
  errors:          PropTypes.shape({}),
  conditionIdx:    PropTypes.number,
  updateCondition: PropTypes.func,
  deleteCondition: PropTypes.func,
};

export default SearchQueryConditionForm;
