import autoBind       from 'react-autobind';
import React          from 'react';
import { PropTypes }  from 'prop-types';
import { withRouter } from 'react-router-dom';

import DuplicateItem from './duplicate_item';

class DuplicateItems extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderGroupRows() {
    const { duplicates, duplicatesFilter, pagination } = this.props;

    const duplicateGroups = [];
    duplicates.forEach((group, idx) => {
      duplicateGroups.push(
        <DuplicateItem
          key={`duplicate-group-${idx}`}
          group={group}
          duplicatesFilter={duplicatesFilter}
          pagination={pagination}
        />,
      );
    });

    return duplicateGroups;
  }

  render() {
    return (
      <div>
        {this.renderGroupRows()}
      </div>
    );
  }
}

DuplicateItems.defaultProps = {
  loading:          false,
  duplicates:       [],
  pagination:       {},
  duplicatesFilter: null,
};

DuplicateItems.propTypes = {
  history:          PropTypes.shape({}).isRequired,
  loading:          PropTypes.bool,
  duplicates:       PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  pagination:       PropTypes.shape({}),
  duplicatesFilter: PropTypes.string,
};

export default withRouter(DuplicateItems);
