import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const SkeletonText = ({
  width = '100px',
  height = '1em',
  className = '',
}) => (
  <p
    key={uuidv4()}
    className={className}
    style={{ width, height }}
  >
    &zwnj;
  </p>
);

export const SkeletonRows = ({
  rowCount = 3,
  widths = [],
  height = '1em',
}) => {
  const rows = [...Array(rowCount).keys()].map((key) => (
    <SkeletonText
      key={key}
      className="mb-2"
      width={widths[key] || '100px'}
    />
  ));

  return <>{rows}</>;
};

export const SkeletonAvatar = ({
  width = 35,
  height = 35,
  className = 'rounded-circle',
}) => (
  <p
    key={uuidv4()}
    className={className}
    style={{ width: `${width}px`, height: `${height}px` }}
  >
    &zwnj;
  </p>
);
