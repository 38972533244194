import autoBind            from 'react-autobind';
import React               from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BlankPage       from '~/components/pages/blank_page';
import AccountSideBar  from '~/components/navigation/account_side_bar';
import LandingPageForm from '~/components/pages/account/forms/landing_page_form';
import AccountStore    from '~/stores/account_store';

class LandingPageAccountSetting extends React.Component {
  constructor(props) {
    super(props);

    const { currentTeam } = Rails.helpers;

    this.state = {
      landingPage: currentTeam.landing_page,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.listener = AccountStore.addListener(this.onAccountStoreChange);
  }

  componentWillUnmount() {
    if (this.listener) this.listener.remove();
  }

  onAccountStoreChange() {
    const accountState = AccountStore.getState();
    const { landingPage } = accountState;

    const newState = {
      accountState,
    };

    if (landingPage) {
      newState.landingPage = landingPage;
    }

    this.setState(newState);
  }

  render() {
    const { abilities } = Rails;
    const { currentUser, currentTeam } = Rails.helpers;

    if (!currentTeam) return null;

    const { landingPage } = this.state;
    const personalLandingPageUrl = `${landingPage.url}/${currentUser.id}`;

    return (
      <BlankPage>
        <div className="container mt30">
          <div className="row mb15">
            <div className="col-md-3">
              <AccountSideBar team={currentTeam} />
            </div>

            <div className="col-md-9">
              { currentTeam && abilities.manageCurrentTeam && (
                <div className="card mb15">
                  <div className="card-block">
                    <LandingPageForm landingPage={landingPage} />
                  </div>
                </div>
              )}

              <div className="card">
                <div className="card-block">
                  <h4 className="mb10">My Landing Page</h4>
                  <p className="text-grey mb10">Use this in email signatures and any personal marketing. New leads will automatically add you as the referrer.</p>
                  <a href={personalLandingPageUrl} target="_blank" rel="noopener noreferrer" className="mr10">
                    <strong>{personalLandingPageUrl}</strong>
                  </a>
                  <a href={personalLandingPageUrl} target="_blank" rel="noopener noreferrer" className="ml10 btn btn-primary">
                    <FontAwesomeIcon icon={['far', 'fa-external-link-alt']} className="mr5" />
                    {' '}
                    Preview
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlankPage>
    );
  }
}

export default LandingPageAccountSetting;
