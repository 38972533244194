import autoBind             from 'react-autobind';
import React                from 'react';
import { PropTypes }        from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import GlobalContainer from '~/components/global_container';
import CampaignActions from '~/actions/campaign_actions';
import CampaignForm    from '~/components/pages/account/forms/campaign_form';

class ComposingCampaign extends React.Component {
  constructor(props) {
    super(props);

    const { campaignID } = props;

    this.state = {
      loadingCampaign:    !!campaignID,
      validCampaign:      true,
      selectingTemplate:  true,
      campaign:           null,
      campaignErrors:     null,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { campaignID } = this.props;

    if (campaignID) {
      CampaignActions.loadCampaign(campaignID, this.onCampaignLoad);
    }
  }

  onCampaignLoad(campaign) {
    this.setState({
      loadingCampaign:   false,
      selectingTemplate: false,
      campaign,
    });
  }

  onCampaignStateChange(stateChange) {
    this.setState(stateChange);
  }

  setCampaignErrors(errors) {
    this.setState({ campaignErrors: errors });
  }

  renderCampaignForm() {
    const {
      loadingCampaign, campaign, validCampaign, selectingTemplate,
    } = this.state;
    const { history } = this.props;

    if (loadingCampaign) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    return (
      <CampaignForm
        campaign={campaign}
        validCampaign={validCampaign}
        selectingTemplate={selectingTemplate}
        onCampaignStateChange={this.onCampaignStateChange}
        history={history}
        setCampaignErrors={this.setCampaignErrors}
      />
    );
  }

  render() {
    const {
      campaign, validCampaign, selectingTemplate, campaignErrors,
    } = this.state;
    const campaigns_uri = GlobalContainer.productUri('/campaigns');

    return (
      <div className="card campaign">
        <div className="card-block">
          <h4 className="mb15">
            <div className="pull-right mb-3">
              <Link to={campaigns_uri} className="btn btn-secondary mr5">Cancel and Go Back</Link>
              { selectingTemplate === false && (
                <button
                  type="submit"
                  className="btn btn-primary"
                  form="compose_campaign_form"
                  disabled={!validCampaign}
                >
                  Save Campaign
                </button>
              )}
            </div>
            <span className="text-nowrap">
              Compose a Campaign
            </span>
          </h4>
          <div className="mb10">
            <div className="mt-4">
              {campaignErrors}
            </div>

            <p className="mt-3">Note:</p>
            <small className="text-grey">
              <ul>
                {!campaign?.new_engine && (
                  <li className="mt-1">
                    Drip emails and texts are sent at randomized times between 9am-12pm
                    (
                    {Moment().tz(Rails.helpers.currentTeam.timezone).format('z')}
                    ) to make them seem more personal.
                  </li>
                )}
                <li className="mt-1">
                  Existing smart campaign subscriptions will be paused whenever a Lead or Agent is moved between the states of Recruiting, Retention or Archived.
                </li>
              </ul>
            </small>
          </div>

          {this.renderCampaignForm()}
        </div>
      </div>
    );
  }
}

ComposingCampaign.defaultProps = {
  campaignID: null,
};

ComposingCampaign.propTypes = {
  campaignID: PropTypes.string,
  history:    PropTypes.shape({}).isRequired,
};

export default withRouter(ComposingCampaign);
