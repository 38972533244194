import MSDocViewer from '~/helpers/MSDocViewer'
import PdfDocViewer from '~/helpers/PdfDocViewer'
import ImageDocViewer from '~/helpers/ImageDocViewer'
import NoPreviewDocViewer from '~/helpers/NoPreviewDocViewer'

const DocumentComponentMap = {
  'word_document':       { Component: MSDocViewer },
  'word_resume':         { Component: MSDocViewer },
  'excel_document':      { Component: MSDocViewer },
  'powerpoint_document': { Component: MSDocViewer },
  'image_document':      { Component: ImageDocViewer },
  'pdf_document':        { Component: PdfDocViewer },
  'pdf_resume':          { Component: PdfDocViewer },
  'other_document':      { Component: NoPreviewDocViewer },
}

export default DocumentComponentMap
