import AppDispatcher   from '~/dispatchers/app_dispatcher';
import APIRequest      from '~/lib/api_request';
import BulkActionTypes from '~/types/bulk_action_types';

let createBulkActionRequest;

const BulkActions = {
  createAction(kind) {
    APIRequest.abort(createBulkActionRequest);

    AppDispatcher.dispatch({
      type: BulkActionTypes.CREATE_BULK_ACTION,
    });

    createBulkActionRequest = APIRequest.post({
      resource: '/v1/bulk_actions',
      data:     { kind },
    });

    createBulkActionRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: BulkActionTypes.CREATE_BULK_ACTION_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: BulkActionTypes.CREATE_BULK_ACTION_DONE,
      });
    });
  },
};

export default BulkActions;
