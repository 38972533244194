import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import MlsServiceRequest from '~/lib/mls_service_request';
import Select            from '~/components/forms/select';

class SearchQueryConditionFieldSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      options: [],
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.product !== this.props.product) {
      this.loadOptions();
    }
  }

  componentDidMount() {
    this.loadOptions();
  }

  loadOptions() {
    const { product, handleTypeField, value } = this.props;
    const FIELDS = [];
    let options = [];

    if (product) {
      this.setState({ loading: true });

      MlsServiceRequest.get({
        resource: '/search_fields',
        data:     { type_of_product: product },
      }).end((error, response) => {
        this.setState({ loading: false }, () => {
          let customFields = [];

          if (!error) {
            customFields = response.body.map((field) => ({
              value:      field.id,
              label:      `${field.name}`,
              type_field: field.type_field,
            }));
          }

          const staticFields = _lodash.map(FIELDS, (e) =>  ({ value: e, label: e }));

          options = [...customFields, ...staticFields];

          if (this.props.blank) options = [{ value: '-blank-', label: '-blank-' }, ...options];

          this.setState({ options }, () => {
            const option = _lodash.find(options, (t) => t.value === value);

            if (option) {
              handleTypeField(option.type_field);
            }
          });
        });
      });
    } else {
      this.setState({ options });
    }
  }

  render() {
    const { loading, options } = this.state;

    return (
      <Select
        {...this.props}
        value={this.props.value}
        onChange={this.props.onChange}
        options={options}
        isLoading={loading}
      />
    );
  }
}

SearchQueryConditionFieldSelect.defaultProps = {
  name:            '',
  product:         'recruiting',
  value:           '',
  onChange:        () => false,
  handleTypeField: () => false,
  blank:           false,
};

SearchQueryConditionFieldSelect.propTypes = {
  name:            PropTypes.string,
  product:         PropTypes.string,
  value:           PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:        PropTypes.func,
  handleTypeField: PropTypes.func,
  blank:           PropTypes.bool,
};

export default SearchQueryConditionFieldSelect;
