import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import autoBind            from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit          from '~/lib/brokerbit';
import LeadSourceActions  from '~/actions/lead_source_actions';
import LeadSourceStore    from '~/stores/lead_source_store';
import ErrorMessage       from '~/components/forms/ErrorMessage';
import AppModal           from './app_modal';

class EditLeadSourceModal extends React.Component {
  constructor(props) {
    super(props);

    const { sourceID } = props;

    this._isMounted = false;

    this.state = {
      loadingSource: !!sourceID,
      source:        {},
      errors:        {},
      submitting:    false,
      sourceName:    '',
    };

    autoBind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    // listen to leadSourceStoreListener changes
    this.leadSourceStoreListener = LeadSourceStore.addListener(this.onLeadSourceStoreChange);

    const { sourceID } = this.props;

    if (sourceID && this._isMounted) {
      LeadSourceActions.loadLeadSource(sourceID, this.onLeadSourceLoad);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    // remove listener to leadSourceStoreListener changes on Unmount
    if (this.leadSourceStoreListener) this.leadSourceStoreListener.remove();
  }

  handleNameChange = (e) => {
    e.preventDefault();

    const { value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      source: {
        ...prevState.source,
        name: value,
      },
    }));
  };

  handleSubmit(e) {
    e.preventDefault();

    const { source, sourceName } = this.state;

    this.setState({ submitting: true }, () => {
      brokerbit.confirmBox({
        message:  `Are you sure you want to change (${sourceName}) to (${source.name})?`,
        callback: (ok) => {
          if (ok) {
            LeadSourceActions.updateLeadSource({ ...source, id: source.id });
          } else {
            this.setState({ submitting: false });
          }
        },
      });
    });
  }

  onLeadSourceLoad(source) {
    this.setState({
      loadingSource: false,
      sourceName:    source.name,
      source,
    });
  }

  // when onLeadSourceStoreChange changes...
  onLeadSourceStoreChange() {
    const leadSourceState = LeadSourceStore.getState();
    const { source, errors, lastLeadSourceStoreAction } = leadSourceState;
    const $modal = $(this.appModal.modal);

    let nextState = { leadSourceState };

    if (lastLeadSourceStoreAction === 'updateLeadSourceDone') {
      $modal.modal('hide');
      GlobalContainer.notify(`Source "${source.name}" updated.`, 'success');
    }

    if (lastLeadSourceStoreAction === 'updateLeadSourceFail') {
      if (errors) {
        nextState = { ...nextState, errors, submitting: false };
      }
    }

    this.setState(nextState);
  }

  renderLeadSourceForm() {
    const {
      loadingSource, source, errors, responseErrors,
    } = this.state;
    const { leadSources } = this.props;

    if (loadingSource) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse />
        </div>
      );
    }

    return (
      <div className="form-group">
        <label htmlFor="source_name" className="label">Source Name</label>
        <input
          id="source_name"
          type="text"
          name="source[name]"
          placeholder="Type a Source Name"
          className={classNames('form-control', { 'has-error': errors.name })}
          value={source.name}
          onChange={this.handleNameChange}
        />
        {errors.name && <ErrorMessage message={errors.name} />}
      </div>
    );
  }

  render() {
    const { source, submitting } = this.state;

    return (
      <AppModal ref={(appModal) => this.appModal = appModal}>
        <form method="PUT" action={`/lead_sources/${source.id}`} onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Edit Source
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {this.renderLeadSourceForm()}
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Updating ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Update</button>
            )}
          </div>
        </form>
      </AppModal>
    );
  }
}

EditLeadSourceModal.defaultProps = {};

EditLeadSourceModal.propTypes = {
  sourceID:    PropTypes.number.isRequired,
  leadSources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default EditLeadSourceModal;
