import React               from 'react';
import { PropTypes }       from 'prop-types';
import classNames          from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AgentActions    from '~/actions/agent_actions';
import LabelSelect     from '~/components/forms/lead_fields/label_select';
import APIRequest      from '~/lib/api_request';
import AppModal        from './app_modal';

class AddLabelModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:     {},
      submitting: false,
      formData:   {
        assign_label_id: null,
      },
    };
  }

  handleLabelSelect = (selectedItem) => {
    const labelID = selectedItem ? selectedItem.value : null;

    this.setState({
      formData: { assign_label_id: labelID },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const $modal = $(this.appModal.modal);

    this.setState({ submitting: true }, () => {
      const {
        table,
        records,
        searchData,
        bulkSelecting,
        selectedItems,
        unSelectedItems,
      } = this.props;

      let selectedLeads = Object.values(selectedItems);
      const unselectedLeads = Object.values(unSelectedItems);

      let labelData;

      if (bulkSelecting) {
        selectedLeads = 'all';
        labelData = { ...searchData, ids: 'all', unselected_ids: _lodash.map(unselectedLeads, 'id') };
      } else {
        labelData = { ...searchData, ids: _lodash.map(selectedLeads, 'id') };
      }

      if (this.validate()) {
        APIRequest.post({
          resource: '/v1/lead_bulk_labels',
          data:     { ...labelData, ...this.state.formData },
        }).end((error) => {
          this.setState({ submitting: false }, () => {
            if (!error) {
              const selectedItemsCount = this.props.countSelectedItems();

              GlobalContainer.notify(`Label will be add to ${selectedItemsCount} agents`, 'success');

              $modal.modal('hide');

              this.props.reloadLeads();
            }
          });
        });
      } else {
        this.setState({ submitting: false });
      }
    });
  }

  validate() {
    const errors = {};
    const { formData } = this.state;

    if (!formData.assign_label_id) {
      errors.assign_label_id = "Can't be empty";
    }

    this.setState({ errors });

    return _lodash.size(errors) === 0;
  }

  render() {
    const { errors, submitting } = this.state;

    const selectedItemsCount = this.props.countSelectedItems();

    const spinnerImg = <FontAwesomeIcon icon="far fa-spinner" pulse />;
    const saveButtonClass = classNames('btn btn-primary', { disabled: submitting });

    return (
      <AppModal ref={(appModal) => this.appModal = appModal}>
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Add Label to
              {' '}
              <small>
                {selectedItemsCount}
                {' '}
                agents
              </small>
              {' '}
              {submitting && spinnerImg}
            </h5>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group mr5">
              <label>Labels</label>
              <LabelSelect
                placeholder="Select a Label..."
                onChange={this.handleLabelSelect}
                className={errors.assign_label_id ? 'has-error' : ''}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" className={saveButtonClass} disabled={submitting}>
              Add
            </button>
          </div>
        </form>
      </AppModal>
    );
  }
}

AddLabelModal.defaultProps = {
  bulkSelecting:      false,
  countSelectedItems: () => {},
  records:            {},
  searchData:         {},
  selectedItems:      {},
  table:              {},
  unSelectedItems:    [],
};

AddLabelModal.propTypes = {
  bulkSelecting:      PropTypes.bool,
  countSelectedItems: PropTypes.func,
  records:            PropTypes.shape({}),
  searchData:         PropTypes.shape({}),
  selectedItems:      PropTypes.shape({}),
  table:              PropTypes.shape({}),
  unSelectedItems:    PropTypes.shape({}),
};

export default AddLabelModal;
