import AppDispatcher       from '~/dispatchers/app_dispatcher';
import APIRequest          from '~/lib/api_request';
import CampaignActionTypes from '~/types/campaign_action_types';

let campaignsRequest;
let loadCampaignRequest;
let createCampaignRequest;
let updateCampaignRequest;
let deleteCampaignRequest;
let donateCampaignRequest;

const CampaignActions = {
  loadCampaign(
    campaignID,
    onDone = () => false,
    onFail = () => false,
  ) {
    APIRequest.abort(loadCampaignRequest);

    campaignsRequest = APIRequest.get({
      resource: `/v1/campaigns/${campaignID}`,
    });

    campaignsRequest.end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  updateCampaignStatus(
    campaignID,
    action,
    onDone = () => false,
    onFail = () => false,
  ) {
    APIRequest.put({
      resource: `/v1/campaigns/${campaignID}/${action}`,
    }).end((error, response) => {
      if (error) {
        onFail(error, response);
        return;
      }

      onDone(response.body);
    });
  },

  runCampaign(
    campaignID,
    onDone = () => false,
    onFail = () => false,
  ) {
    this.updateCampaignStatus(campaignID, 'run', onDone, onFail);
  },

  pauseCampaign(
    campaignID,
    onDone = () => false,
    onFail = () => false,
  ) {
    this.updateCampaignStatus(campaignID, 'pause', onDone, onFail);
  },

  endCampaign(
    campaignID,
    onDone = () => false,
    onFail = () => false,
  ) {
    this.updateCampaignStatus(campaignID, 'end', onDone, onFail);
  },

  donateCampaign(campaignId, origin) {
    APIRequest.abort(donateCampaignRequest);

    AppDispatcher.dispatch({
      type: CampaignActionTypes.DONATE_CAMPAIGN,
      campaignId,
    });

    donateCampaignRequest = APIRequest.post({
      resource: `/v1/campaigns/${campaignId}/donate`,
      data:     { campaign: { id: campaignId, origin } },
    });

    donateCampaignRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type:     CampaignActionTypes.DONATE_CAMPAIGN_FAIL,
          error,
          response: response.body,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     CampaignActionTypes.DONATE_CAMPAIGN_DONE,
        response: response.body,
      });
    });
  },

  loadCampaigns() {
    APIRequest.abort(campaignsRequest);

    AppDispatcher.dispatch({
      type: CampaignActionTypes.LOAD_CAMPAIGNS,
    });

    campaignsRequest = APIRequest.get({
      resource: '/v1/campaigns',
    });

    campaignsRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignActionTypes.LOAD_CAMPAIGNS_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:      CampaignActionTypes.LOAD_CAMPAIGNS_DONE,
        campaigns: response.body,
      });
    });
  },

  createCampaign(campaign) {
    APIRequest.abort(createCampaignRequest);

    AppDispatcher.dispatch({
      type: CampaignActionTypes.CREATE_CAMPAIGN,
      campaign,
    });

    createCampaignRequest = APIRequest.post({
      resource: '/v1/campaigns',
      data:     { campaign },
    });

    createCampaignRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignActionTypes.CREATE_CAMPAIGN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     CampaignActionTypes.CREATE_CAMPAIGN_DONE,
        campaign: response.body,
      });

      CampaignActions.loadCampaigns();
    });
  },

  updateCampaign(campaign) {
    APIRequest.abort(updateCampaignRequest);

    AppDispatcher.dispatch({
      type: CampaignActionTypes.UPDATE_CAMPAIGN,
      campaign,
    });

    updateCampaignRequest = APIRequest.put({
      resource: `/v1/campaigns/${campaign.id}`,
      data:     { campaign },
    });

    updateCampaignRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignActionTypes.UPDATE_CAMPAIGN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type:     CampaignActionTypes.UPDATE_CAMPAIGN_DONE,
        campaign: response.body,
      });

      CampaignActions.loadCampaigns();
    });
  },

  deleteCampaign(campaign) {
    APIRequest.abort(deleteCampaignRequest);

    AppDispatcher.dispatch({
      type: CampaignActionTypes.DELETE_CAMPAIGN,
      campaign,
    });

    deleteCampaignRequest = APIRequest.delete({
      resource: `/v1/campaigns/${campaign.id}`,
    });

    deleteCampaignRequest.end((error, response) => {
      if (error) {
        AppDispatcher.dispatch({
          type: CampaignActionTypes.DELETE_CAMPAIGN_FAIL,
          error,
        });

        return;
      }

      AppDispatcher.dispatch({
        type: CampaignActionTypes.DELETE_CAMPAIGN_DONE,
      });

      CampaignActions.loadCampaigns();
    });
  },
};

export default CampaignActions;
