import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Stats from './stats';

const DownloadStep = ({
  templateUrl,
  templateButtonTitle,
  secondTemplateUrl,
  secondTemplateButtonTitle,
  step,
  ...props
}) => (
  <div>
    <a href={templateUrl} className="btn btn-success btn-block text-wrap">
      <FontAwesomeIcon icon={['far', 'fa-arrow-down']} />
      {' '}
      {templateButtonTitle}
    </a>

    {secondTemplateUrl && (
      <a href={secondTemplateUrl} className="btn btn-success btn-block mt-2 text-wrap">
        <FontAwesomeIcon icon={['far', 'fa-arrow-down']} />
        {' '}
        {secondTemplateButtonTitle}
      </a>
    )}

    <Stats step={step} {...props} />
  </div>
);

DownloadStep.defaultProps = {
  templateButtonTitle:       'Download Template',
  secondTemplateUrl:         '',
  secondTemplateButtonTitle: 'Download Template',
};

DownloadStep.propTypes = {
  importType:                PropTypes.string.isRequired,
  templateUrl:               PropTypes.string.isRequired,
  secondTemplateUrl:         PropTypes.string,
  templateButtonTitle:       PropTypes.string,
  secondTemplateButtonTitle: PropTypes.string,
};

export default DownloadStep;
