const TeamMlsActionTypes = {
  LOAD_TEAM_MLSES:        'LOAD_TEAM_MLSES',
  LOAD_TEAM_MLSES_DONE:   'LOAD_TEAM_MLSES_DONE',
  LOAD_TEAM_MLSES_FAIL:   'LOAD_TEAM_MLSES_FAIL',

  UPDATE_TEAM_MLSES:      'UPDATE_TEAM_MLSES',
  UPDATE_TEAM_MLSES_DONE: 'UPDATE_TEAM_MLSES_DONE',
  UPDATE_TEAM_MLSES_FAIL: 'UPDATE_TEAM_MLSES_FAIL',

  UPDATE_TEAM_MLS:        'UPDATE_TEAM_MLS',
  UPDATE_TEAM_MLS_DONE:   'UPDATE_TEAM_MLS_DONE',
  UPDATE_TEAM_MLS_FAIL:   'UPDATE_TEAM_MLS_FAIL',
};

export default TeamMlsActionTypes;
