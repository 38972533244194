import request from 'superagent';
import qs      from 'qs';

const MlsServiceRequest = {
  apiRequiredHeaders() {
    const headers = {};

    if (Rails.helpers.currentTeam) headers.team = Rails.helpers.currentTeam.id;

    // if (Rails.helpers.authToken) {
    //  headers.Authentication = Rails.helpers.authToken;
    // }
    return headers;
  },

  setDefaultOptions(options) {
    const defaultOpts = {};

    return _lodash.merge(options, defaultOpts);
  },

  ajaxRequest(resource, method, data) {
    return request(method, `${Rails.mlsServiceUrl}/v1${resource}`)
      .set(this.apiRequiredHeaders())
      .type('application/json')
      .accept('application/json');
  },

  get(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'GET').query(
      qs.stringify(mergedOptions.data, { arrayFormat: 'brackets' }),
    );
  },

  post(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'POST').send(
      mergedOptions.data,
    );
  },

  put(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'PUT').send(
      mergedOptions.data,
    );
  },

  patch(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'PATCH').send(
      mergedOptions.data,
    );
  },

  delete(options) {
    const mergedOptions = this.setDefaultOptions(options);
    return this.ajaxRequest(mergedOptions.resource, 'DELETE').send(
      mergedOptions.data,
    );
  },

  abort(req) {
    if (req) {
      req.abort();
    }
  },
};

export default MlsServiceRequest;
