const LeadLinkActionTypes = {
  LOAD_LEAD_LINKS_DONE: 'LOAD_LEAD_LINKS_DONE',

  CREATE_LEAD_LINK:      'CREATE_LEAD_LINK',
  CREATE_LEAD_LINK_DONE: 'CREATE_LEAD_LINK_DONE',
  CREATE_LEAD_LINK_FAIL: 'CREATE_LEAD_LINK_FAIL',

  UPDATE_LEAD_LINK:      'UPDATE_LEAD_LINK',
  UPDATE_LEAD_LINK_DONE: 'UPDATE_LEAD_LINK_DONE',
  UPDATE_LEAD_LINK_FAIL: 'UPDATE_LEAD_LINK_FAIL',

  DELETE_LEAD_LINK:      'DELETE_LEAD_LINK',
  DELETE_LEAD_LINK_DONE: 'DELETE_LEAD_LINK_DONE',
  DELETE_LEAD_LINK_FAIL: 'DELETE_LEAD_LINK_FAIL',

  UPDATE_FROM_FULLCONTACT:      'UPDATE_FROM_FULLCONTACT',
  UPDATE_FROM_FULLCONTACT_DONE: 'UPDATE_FROM_FULLCONTACT_DONE',
  UPDATE_FROM_FULLCONTACT_FAIL: 'UPDATE_FROM_FULLCONTACT_FAIL',
};

export default LeadLinkActionTypes;
