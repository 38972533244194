const TaskActionTypes = {
  LOAD_TASKS:      'LOAD_TASKS',
  LOAD_TASKS_DONE: 'LOAD_TASKS_DONE',
  LOAD_TASKS_FAIL: 'LOAD_TASKS_FAIL',

  LOAD_TASK:      'LOAD_TASK',
  LOAD_TASK_DONE: 'LOAD_TASK_DONE',
  LOAD_TASK_FAIL: 'LOAD_TASK_FAIL',

  CREATE_TASK:      'CREATE_TASK',
  CREATE_TASK_DONE: 'CREATE_TASK_DONE',
  CREATE_TASK_FAIL: 'CREATE_TASK_FAIL',

  UPDATE_TASK:      'UPDATE_TASK',
  UPDATE_TASK_DONE: 'UPDATE_TASK_DONE',
  UPDATE_TASK_FAIL: 'UPDATE_TASK_FAIL',

  DELETE_TASK:      'DELETE_TASK',
  DELETE_TASK_DONE: 'DELETE_TASK_DONE',
  DELETE_TASK_FAIL: 'DELETE_TASK_FAIL',
};

export default TaskActionTypes;
