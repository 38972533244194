import autoBind            from 'react-autobind';
import classNames          from 'classnames';
import update              from 'immutability-helper';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LabelActions from '~/actions/label_actions';
import LabelStore from '~/stores/label_store';
import AutosuggestInput from '~/components/forms/autosuggest_input';
import ErrorMessage from '~/components/forms/ErrorMessage';

class LabelForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      errors:     {},
      submitting: false,
      label:      {
        id:   null,
        name: '',
      },
    };
  }

  componentDidMount() {
    // listen to labelStoreListener changes
    this.labelStoreListener = LabelStore.addListener(this.onLabelStoreChange);
  }

  componentWillUnmount() {
    // remove listener to LabelStore changes on Unmount
    if (this.labelStoreListener) this.labelStoreListener.remove();
  }

  // when LabelStore changes...
  onLabelStoreChange() {
    const labelStoreState = LabelStore.getState();
    const {
      label, lastLabelStoreAction, errors, submitting,
    } = labelStoreState;

    let nextState = { labelStoreState };

    if (lastLabelStoreAction === 'createLabelDone') {
      GlobalContainer.notify(`Label "${label.name}" created.`, 'success');
      nextState = { label: { id: null, name: '' }, errors, submitting };
    }

    if (lastLabelStoreAction === 'createLabelFail') {
      nextState = { ...nextState, errors, submitting };
    }

    this.setState(nextState);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { label } = this.state;

    this.setState({ submitting: true }, () => {
      LabelActions.createLabel({ ...label });
    });
  }

  handleNameChange(value) {
    this.setState(
      update(this.state, {
        label: {
          name: { $set: value },
        },
      }),
    );
  }

  render() {
    const { label, errors, submitting } = this.state;
    const { labels } =  this.props;

    return (
      <div>
        <form method="POST" action="/labels" className="form-inline" onSubmit={this.handleFormSubmit}>
          <div className="form-group ml-3">
            <AutosuggestInput
              suggestions={labels}
              id="label_name"
              name="label[name]"
              placeholder="Type a Label Name"
              onChange={this.handleNameChange}
              styles={`${classNames('form-control', { 'has-error': errors.name })}`}
              value={label.name}
            />
          </div>
          <div className="form-group mx-3">
            { submitting ? (
              <button type="submit" className="btn btn-primary disabled" disabled>
                <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
                {' '}
                Saving ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">Create New Label</button>
            )}
          </div>
        </form>
        <div className="ml-3">{errors.name && <ErrorMessage message={errors.name} />}</div>
      </div>
    );
  }
}

LabelForm.defaultProps = {
  labels: [],
};

LabelForm.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object),
};

export default LabelForm;
