import React      from 'react';
import classNames from 'classnames';
import {
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { PropTypes }       from 'prop-types';
import PhoneInput          from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import brokerbit   from '~/lib/brokerbit';
import APIRequest  from '~/lib/api_request';
import LeadHelpers from '~/helpers/lead_helpers';
import Tooltipable from '~/components/effects/tooltipable';

class SmsNumberEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:      {},
      phoneNumber: props.phoneNumber,
    };
  }

  _handleFormSubmit = (e) => {
    e.preventDefault();

    const { phoneNumber } = this.state;
    const { onPhoneSelect } = this.props;

    APIRequest.patch({
      resource: `/v1/sms_numbers/${phoneNumber.id}`,
      data:     {
        id:             phoneNumber.id,
        forward_number: phoneNumber.forward_number,
      },
    }).end((err, res) => {
      if (res.body.errors) {
        this.setState({ errors: res.body.errors });
      } else {
        this.setState({ errors: {} }, () => {
          onPhoneSelect(phoneNumber);
          GlobalContainer.notify('Forwarding number updated');
        });
      }
    });
  };

  handleButtonRelease = (e) => {
    e.preventDefault();

    const { phoneNumber } = this.state;
    const { onPhoneSelect, loadReleasedNumbers } = this.props;

    brokerbit.confirmBox({
      message:  'Are you sure you want to release this BK service number?',
      callback: (ok) => {
        if (ok) {
          APIRequest.delete({ resource: `/v1/sms_numbers/${phoneNumber.id}` })
            .end((err, res) => {
              onPhoneSelect({});
              loadReleasedNumbers();
              GlobalContainer.notify('BK Service Number released');
            });
        }
      },
    });
  };

  render() {
    const { phoneNumber, errors } = this.state;

    return (
      <Form onSubmit={this._handleFormSubmit}>
        <input
          type="hidden"
          name="id"
          id="id"
          value={phoneNumber.id}
        />

        <FormGroup className="mb-3">
          <Label for="service_number" className="col-form-label">
            Brokerkit Number
          </Label>
          <div className="input-group mb-3">
            <div>
              <Input
                type="text"
                disabled
                name="service_number"
                id="service_number"
                value={LeadHelpers.nationalFormatPhoneNumber(phoneNumber.service_number)}
              />
            </div>
            <div className="input-group-append mr-2">
              <button type="button" className="btn btn-danger" onClick={this.handleButtonRelease}>
                Release
              </button>
            </div>
            {phoneNumber.verified_calling && (
              <span className="align-self-center">
                <FontAwesomeIcon icon={['fas', 'fa-check-circle']} className="mr-2 text-success align-middle" />
                Verified Calling
                <Tooltipable className="ml-2" text="Verified calling means the number has been registered for SHAKEN/STIR calling">
                  <FontAwesomeIcon icon={['fas', 'fa-question-circle']} className="text-secondary align-middle" />
                </Tooltipable>
              </span>
            )}
          </div>
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="forward_number" className="col-form-label">Forwarding Number</Label>
          <div className="input-group mb-3">
            <PhoneInput
              country="us"
              onlyCountries={['us']}
              disableCountryCode
              disableDropdown
              placeholder=""
              defaultMask="(...) ...-...."
              value={LeadHelpers.nationalFormatPhoneNumber(phoneNumber.forward_number)}
              onChange={(v) => { phoneNumber.forward_number = v; }}
              specialLabel={false}
              inputProps={{
                name:      'forward_number',
                id:        'forward_number',
                className: classNames('phone form-control', { 'has-error': !!errors.forward_number }),
              }}
            />
            <div className="input-group-append">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
          {errors.forward_number && (
            <small className="text-red">
              {errors.forward_number.join(', ')}
            </small>
          )}
        </FormGroup>
      </Form>
    );
  }
}

SmsNumberEditForm.defaultProps = {
  onPhoneSelect: () => false,
};

SmsNumberEditForm.propTypes = {
  phoneNumber:   PropTypes.shape({}).isRequired,
  onPhoneSelect: PropTypes.func,
};

export default SmsNumberEditForm;
