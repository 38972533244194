import { ReduceStore }        from 'flux/utils';
import AppDispatcher          from '~/dispatchers/app_dispatcher';
import SearchQueryActionTypes from '~/types/search_query_action_types';

class SearchQueryStore extends ReduceStore {
  constructor() {
    super(AppDispatcher);
  }

  getInitialState() {
    return {
      loadingSearchQueries:       false,
      searchQueries:              [],
      searchQuery:                null,
      lastSearchQueryStoreAction: null,
      errors:                     {},
      submitting:                 false,
    };
  }

  loadSearcQueries(state, action) {
    const newState = { ...state };

    newState.loadingSearchQueries = true;
    newState.lastSearchQueryStoreAction = 'loadSearcQueries';

    return newState;
  }

  loadSearcQueriesDone(state, action) {
    const newState = { ...state };

    newState.loadingSearchQueries = false;
    newState.searchQueries = action.searchQueries;
    newState.lastSearchQueryStoreAction = 'loadSearcQueriesDone';

    return newState;
  }

  loadSearcQueriesFail(state, action) {
    const newState = { ...state };

    newState.loadingSearchQueries = false;
    newState.lastSearchQueryStoreAction = 'loadSearcQueriesFail';

    return newState;
  }

  createSearchQuery(state, action) {
    const newState = { ...state };

    newState.lastSearchQueryStoreAction = 'createSearchQuery';

    return newState;
  }

  createSearchQueryDone(state, action) {
    const newState = { ...state };

    newState.searchQuery = action.searchQuery;
    newState.lastSearchQueryStoreAction = 'createSearchQueryDone';
    newState.submitting = false;
    newState.errors     = {};

    return newState;
  }

  createSearchQueryFail(state, action) {
    const newState = { ...state };

    newState.lastSearchQueryStoreAction = 'createSearchQueryFail';
    newState.submitting = false;
    newState.errors     = action.error.response.body.errors;

    return newState;
  }

  updateSearchQuery(state, action) {
    const newState = { ...state };

    newState.lastSearchQueryStoreAction = 'updateSearchQuery';

    return newState;
  }

  updateSearchQueryDone(state, action) {
    const newState = { ...state };

    newState.searchQuery = action.searchQuery;
    newState.lastSearchQueryStoreAction = 'updateSearchQueryDone';
    newState.submitting = false;
    newState.errors     = {};

    return newState;
  }

  updateSearchQueryFail(state, action) {
    const newState = { ...state };

    newState.lastSearchQueryStoreAction = 'updateSearchQueryFail';
    newState.submitting = false;
    newState.errors     = action.error.response.body.errors;

    return newState;
  }

  reduce(state, action) {
    switch (action.type) {
      case SearchQueryActionTypes.LOAD_SEARCH_QUERIES:
        return this.loadSearcQueries(state, action);

      case SearchQueryActionTypes.LOAD_SEARCH_QUERIES_DONE:
        return this.loadSearcQueriesDone(state, action);

      case SearchQueryActionTypes.LOAD_SEARCH_QUERIES_FAIL:
        return this.loadSearcQueriesFail(state, action);

      case SearchQueryActionTypes.CREATE_SEARCH_QUERY:
        return this.createSearchQuery(state, action);

      case SearchQueryActionTypes.CREATE_SEARCH_QUERY_DONE:
        return this.createSearchQueryDone(state, action);

      case SearchQueryActionTypes.CREATE_SEARCH_QUERY_FAIL:
        return this.createSearchQueryFail(state, action);

      case SearchQueryActionTypes.UPDATE_SEARCH_QUERY:
        return this.updateSearchQuery(state, action);

      case SearchQueryActionTypes.UPDATE_SEARCH_QUERY_DONE:
        return this.updateSearchQueryDone(state, action);

      case SearchQueryActionTypes.UPDATE_SEARCH_QUERY_FAIL:
        return this.updateSearchQueryFail(state, action);

      default:
        return state;
    }
  }
}

export default new SearchQueryStore();
