import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import ReactDOM            from 'react-dom';
import { PropTypes }       from 'prop-types';
import { Editor }          from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import VideosModal          from '~/components/modals/videos/VideosModal';
import AssistModal          from '~/components/modals/AiAssist/Modal';
import MentionsEditor       from '~/components/forms/HtmlEditors/MentionsEditor';
import {
  TINYMCE_SCRIPT_PATH,
  TASK_EDITOR_PLUGINS,
  TASK_EDITOR_TOOLBAR,
  TASK_CAMPAIGN_EDITOR_TOOLBAR,
  IMAGES_UPLOAD_HANDLER,
} from '~/constants/TinyMCE';
import { initWebSpellChecker } from '~/lib/web_spell_checker';
import EditorHelpers           from '~/helpers/editor_helpers';

const TaskEditor = ({
  id,
  value,
  className,
  placeholder,
  onChange,
  isCampaingStep,
  spellCheck,
  linksToInsert,
  clearLinksToInsert,
}) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [hasCheckedFeature, setHasCheckedFeature] = useState(false);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const editorRef = useRef(null);
  const init = {
    placeholder,
    license_key:                   'gpl',
    plugins:                       TASK_EDITOR_PLUGINS,
    toolbar:                       isCampaingStep ? TASK_CAMPAIGN_EDITOR_TOOLBAR : TASK_EDITOR_TOOLBAR,
    toolbar_mode:                  'wrap',
    branding:                      false,
    menubar:                       false,
    file_picker_types:             'image',
    min_height:                    350,
    relative_urls:                 false,
    remove_script_host:            false,
    document_base_url:             Rails.baseUrl,
    forced_root_block:             'div',
    entity_encoding:               'named',
    content_style:                 'body { font-family: Arial; font-size: 13px; }',
    paste_as_text:                 false,
    contextmenu:                   false,
    paste_remove_styles_if_webkit: false,
    statusbar:                     true,
    images_upload_handler:         IMAGES_UPLOAD_HANDLER,
    setup:                         (editor) => {
      editor.ui.registry.addIcon('videoIcon', '<span class="fas fa-video-plus" />');
      editor.ui.registry.addIcon('usersIcon', '<span class="fas fa-users" />');

      editor.ui.registry.addButton('videos', {
        icon:     'videoIcon',
        tooltip:  'Insert Video',
        onAction: () => {
          ReactDOM.render(
            <VideosModal
              containerID="secondary-modal"
              modalClass="modal modal-overlay"
              dialogClass="modal-dialog modal-lg"
              editor={editor}
            />, document.getElementById('secondary-modal'),
          );
        },
      });

      editor.ui.registry.addMenuButton('variables', {
        icon:    'usersIcon',
        text:    'Variables...',
        tooltip: 'Variables',
        fetch:   (callback) => {
          const items = [
            { type: 'menuitem', text: 'Lead First Name',    onAction: () => { editor.insertContent('{{firstName}}'); } },
            { type: 'menuitem', text: 'Lead Last Name',     onAction: () => { editor.insertContent('{{lastName}}'); } },
            { type: 'menuitem', text: 'Lead Full Name',     onAction: () => { editor.insertContent('{{fullName}}'); } },
            { type: 'menuitem', text: 'Lead Company',       onAction: () => { editor.insertContent('{{company}}'); } },
            { type: 'menuitem', text: "Agent's Volume",     onAction: () => { editor.insertContent('{{Volume}}'); } },
            { type: 'menuitem', text: 'Referrer First',     onAction: () => { editor.insertContent('{{referrerFirst}}'); } },
            { type: 'menuitem', text: 'Referrer Last',      onAction: () => { editor.insertContent('{{referrerLast}}'); } },
            { type: 'menuitem', text: 'Referrer Full Name', onAction: () => { editor.insertContent('{{referrerName}}'); } },
          ];

          callback(items);
        },
      });

      editor.ui.registry.addButton('ai-assist', {
        icon:     'ai',
        tooltip:  'AI Assist',
        onAction: () => {
          ReactDOM.render(
            <AssistModal
              containerID="ai-assist-modal"
              modalClass="modal modal-overlay"
              dialogClass="modal-dialog"
              editor={editor}
            />,
            document.getElementById('ai-assist-modal'),
          );
        },
      });

      EditorHelpers.addVisitLink(editor);
    },
  };

  useEffect(() => {
    const handlePosthogReady = () => {
      const isAccessAllowed = checkFeatureFlag(process.env.ALLOW_MENTIONS_FLAG);
      setIsFeatureEnabled(isAccessAllowed);
      setHasCheckedFeature(true);
    };

    if (window.isPosthogInitialized) {
      handlePosthogReady();
    } else {
      window.addEventListener('posthogReady', handlePosthogReady);
    }

    return () => {
      window.removeEventListener('posthogReady', handlePosthogReady);
    };
  }, []);

  useEffect(() => {
    if (linksToInsert?.length) {
      let newContent = content;
      linksToInsert.forEach((link) => {
        const { text, href } = link;
        const linkHtml = `<br/><a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>&nbsp;`;

        newContent += linkHtml;
      });
      setContent(newContent);
      clearLinksToInsert();
    }
  }, [linksToInsert, clearLinksToInsert]);

  useEffect(() => {
    initSpellCheckerForEditor();
  }, [spellCheck]);

  const initSpellCheckerForEditor = () => {
    const editor = editorRef.current;

    if (spellCheck && editor) {
      initWebSpellChecker(editor.iframeElement);
    }
  };

  const handleEditorChange = (_value, editor) => {
    const newContent = editor.getContent({ format: 'html' });
    setContent(newContent);
    onChange(newContent);
  };

  const handleOnInit = (_event, editor) => {
    setLoading(false);

    if (value) {
      editor.setContent(value, { format: 'html' });
    }

    editorRef.current = editor;
    initSpellCheckerForEditor();
  };

  return (
    <>
      <div className={className}>
        {loading && (
          <div className="text-center">
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
            {' '}
            Loading
          </div>
        )}

        {isCampaingStep || (hasCheckedFeature && !isFeatureEnabled) ? (
          <Editor
            id={id}
            tinymceScriptSrc={TINYMCE_SCRIPT_PATH}
            scriptLoading={{ async: true }}
            value={content}
            onEditorChange={handleEditorChange}
            onInit={handleOnInit}
            init={init}
          />
        ) : (
          <MentionsEditor
            id={id}
            value={content}
            onEditorChange={handleEditorChange}
            onInit={handleOnInit}
            init={init}
            activityType="task"
          />
        )}
      </div>
    </>
  );
};

TaskEditor.defaultProps = {
  value:          '',
  className:      '',
  id:             '',
  placeholder:    'Enter text or insert / drop images ...',
  onChange:       () => false,
  isCampaingStep: false,
  spellCheck:     true,
  linksToInsert:  [],
  clearLinksToInsert: () => false,
};

TaskEditor.propTypes = {
  value:          PropTypes.string,
  className:      PropTypes.string,
  id:             PropTypes.string,
  placeholder:    PropTypes.string,
  onChange:       PropTypes.func,
  isCampaingStep: PropTypes.bool,
  spellCheck:     PropTypes.bool,
  linksToInsert:  PropTypes.array,
  clearLinksToInsert: PropTypes.func,
};

export default TaskEditor;
