const MlsActionTypes = {
  LOAD_MLS_OPTIONS:                       'LOAD_MLS_OPTIONS',
  LOAD_MLS_OPTIONS_DONE:                  'LOAD_MLS_OPTIONS_DONE',
  LOAD_MLS_OPTIONS_FAIL:                  'LOAD_MLS_OPTIONS_FAIL',

  LOAD_TEAM_MLSES_OPTIONS:                'LOAD_TEAM_MLSES_OPTIONS',
  LOAD_TEAM_MLSES_OPTIONS_DONE:           'LOAD_TEAM_MLSES_OPTIONS_DONE',
  LOAD_TEAM_MLSES_OPTIONS_FAIL:           'LOAD_TEAM_MLSES_OPTIONS_FAIL',

  LOAD_ACTIVATED_TEAM_MLSES_OPTIONS:      'LOAD_ACTIVATED_TEAM_MLSES_OPTIONS',
  LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_DONE: 'LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_DONE',
  LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_FAIL: 'LOAD_ACTIVATED_TEAM_MLSES_OPTIONS_FAIL',
};

export default MlsActionTypes;
