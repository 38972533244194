import autoBind from 'react-autobind';
import React from 'react';
import shadowRoot from 'react-shadow';
import {
  Popover,
  PopoverTitle,
  PopoverContent,
} from 'reactstrap';

import TimelineActivity from '~/components/drawers/timeline/activity';

class TimelineActivityEmail extends TimelineActivity {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onTogglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  renderView() {
    const { activity } = this.state;
    let subject;
    let body;
    let id;

    if (activity.email) {
      // Always refer to the email, it's the best source of info.
      id = activity.email.id;
      subject = activity.email.subject;
      body = activity.email.body;
    } else {
      // Just use activity body, if any
      body = activity.body;
    }

    return (
      <div id={`${id}-Email`}>
        {subject && <h5 className="mt10 mb5">{subject}</h5> }
        {body && (
          <shadowRoot.div className="mt-2 form-control font-activity">
            <div className="content" dangerouslySetInnerHTML={{ __html: body || '' }} />
          </shadowRoot.div>
        )}
      </div>
    );
  }
}

TimelineActivityEmail.defaultProps = {
  editable:       true,
  deletable:      true,
  title:          'Email Note',
  icon:           'envelope',
  prefix:         'fas',
  color:          'text-blue',
  alwaysPublic:   false,
};

export default TimelineActivityEmail;
