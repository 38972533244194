import { axiosInstance } from '~/lib/api_request';

export const TINYMCE_SCRIPT_PATH = '/packs/dist/tinymce/tinymce.min.js';

export const SIGNATURE_EDITOR_PLUGINS = 'autoresize code advlist lists preview emoticons link autolink image wordcount table visualblocks charmap media';
export const SIGNATURE_EDITOR_TOOLBAR = 'undo redo | blocks fontfamily fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | image videos link table | charmap emoticons | forecolor backcolor | removeformat | preview | ai-assist | code';

export const LANDING_PAGE_EDITOR_PLUGINS = 'autoresize code advlist lists preview emoticons link autolink image wordcount table visualblocks charmap media';
export const LANDING_PAGE_EDITOR_TOOLBAR = 'undo redo | blocks fontfamily fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | image videos media link table | charmap emoticons | forecolor backcolor | removeformat | preview | ai-assist | code';

export const ACTIVITY_EDITOR_PLUGINS = 'autoresize advlist lists preview emoticons link autolink image wordcount table charmap';
export const ACTIVITY_EDITOR_TOOLBAR = 'undo redo | blocks fontfamily fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | image link table | charmap emoticons | forecolor backcolor | removeformat | preview | ai-assist';

export const APPT_EDITOR_PLUGINS = 'autoresize preview emoticons wordcount charmap advlist lists link autolink';
export const APPT_EDITOR_TOOLBAR = 'undo redo | bold italic | numlist bullist | link | charmap emoticons | variables | removeformat | preview | ai-assist';

export const TASK_EDITOR_PLUGINS = 'autoresize advlist lists preview emoticons link charmap';
export const TASK_EDITOR_TOOLBAR = 'undo redo | bold italic underline | numlist bullist | link | charmap emoticons | forecolor backcolor | preview | ai-assist';
export const TASK_CAMPAIGN_EDITOR_TOOLBAR = 'undo redo | bold italic underline | numlist bullist | link | charmap emoticons | forecolor backcolor | variables | preview | ai-assist';

export const EMAIL_EDITOR_PLUGINS = 'autoresize code advlist lists preview emoticons link autolink image wordcount table visualblocks charmap';
export const EMAIL_EDITOR_TOOLBAR = 'undo redo | blocks fontfamily fontsize | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | image videos link table | charmap emoticons | forecolor backcolor | variables | removeformat | preview | code | ai-assist';

export const SMS_TEXT_EDITOR_PLUGINS = 'autoresize preview emoticons wordcount charmap';
export const SMS_TEXT_EDITOR_TOOLBAR = 'undo redo | charmap emoticons | images videos | variables | preview | ai-assist';

export const IMAGES_UPLOAD_HANDLER = (blobInfo, progress) => new Promise((resolve, reject) => {
  const formData = new FormData();

  formData.append('image[image]', blobInfo.blob(), blobInfo.filename());

  axiosInstance.post('/v1/images', formData, {
    headers:          { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      progress(percentCompleted);
    },
  }).then((response) => {
    if (response.data && response.data.image && response.data.image.url) {
      resolve(response.data.image.url);
    } else {
      reject(new Error('Invalid response, URL missing.'));
    }
  }).catch((error) => {
    if (error.response) {
      reject(new Error(`HTTP Error: ${error.response.status}, ${error.response.data}`));
    } else if (error.request) {
      reject(new Error('No response received'));
    } else {
      reject(error.message);
    }
  });
});
