import React       from 'react';
import PropTypes   from 'prop-types';

import Select      from '~/components/forms/select';
import APIRequest  from '~/lib/api_request';

class OrganizationTeamsSelect extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      options: [],
      value:   props.value,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    APIRequest.get({
      resource: '/v1/teams/organization_teams',
    }).end((error, response) => {
      if (this._isMounted) {
        this.setState({ loading: false }, () => {
          if (!error) {
            const options = response.body.map((team) => ({
              label: `${team.name}`,
              value: team.id,
            }));

            this.setState({ options });
          }
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSelectChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value || selectedOption : null;
    const { onChange } = this.props;

    this.setState({ value }, () => {
      onChange(selectedOption);
    });
  }

  render() {
    const { placeholder, clearable } = this.props;

    const { value, loading, options } = this.state;

    return (
      <Select
        clearable={clearable}
        placeholder={placeholder}
        value={value}
        onChange={this.handleSelectChange}
        options={options}
        isLoading={loading}
      />
    );
  }
}

OrganizationTeamsSelect.defaultProps = {
  onChange:    () => {},
  placeholder: 'Select a Team...',
  style:       {},
  value:       null,
  clearable:   false,
};

OrganizationTeamsSelect.propTypes = {
  onChange:    PropTypes.func,
  placeholder: PropTypes.string,
  style:       PropTypes.shape({}),
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  clearable:   PropTypes.bool,
};

export default OrganizationTeamsSelect;
