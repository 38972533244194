import autoBind      from 'react-autobind';
import React               from 'react';
import { PropTypes }       from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltipable   from '~/components/effects/tooltipable';

class CampaignStepNew extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  addNewStep(stepName) {
    const { addStep, stepIdx } = this.props;

    addStep(stepName, stepIdx);
  }

  renderStepLabel() {
    const { nested } = this.props;

    let stepLabel = 'Add the first step...';
    if (nested) stepLabel = 'Add another step...';

    return <span className="mr15">{stepLabel}</span>;
  }

  render() {
    const { subscription } = Rails;
    const { nested, onClose } = this.props;

    return (
      <div className="campaign-step">
        {!nested && (
          <>
            <span className="icon fa-stack fa-lg">
              <FontAwesomeIcon icon={['fas', 'fa-circle']} className="fa-stack-2x text-grey" />
              <FontAwesomeIcon icon={['far', 'fa-bullhorn']} inverse className="fa-stack-1x" />
            </span>
          </>
        )}

        <div className={`body pl-1 ${nested ? 'd-flex justify-content-center' : ''}`}>
          <div className="form-inline mb15">
            {this.renderStepLabel()}

            <span
              className="btn-group btn-group-selectable btn-log"
              role="group"
            >
              <a
                href="#email"
                id="btn-email"
                tabIndex="-1"
                className="btn btn-secondary"
                data-tooltip="Email"
                onClick={(e) => {
                  e.preventDefault();
                  this.addNewStep('email');
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-envelope']} className="mr5" />
                {' '}
                Email
              </a>

              {subscription && subscription.can_sms ? (
                <a
                  href="#text-message"
                  id="btn-text-message"
                  tabIndex="-1"
                  className="btn btn-secondary"
                  data-tooltip="Text"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addNewStep('sms');
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'fa-comment']} className="mr5" />
                  {' '}
                  Text
                </a>
              ) : (
                <Tooltipable text="Add texting to your Brokerkit subscription to enable texting in campaigns.">
                  <a
                    href="#text-message"
                    tabIndex="-1"
                    className="btn btn-secondary disabled"
                    disabled
                  >
                    <FontAwesomeIcon icon={['far', 'fa-comment']} className="mr5" />
                    {' '}
                    Text
                  </a>
                </Tooltipable>
              )}

              <a
                href="#task"
                id="btn-task"
                tabIndex="-1"
                className="btn btn-secondary"
                data-tooltip="Task"
                onClick={(e) => {
                  e.preventDefault();
                  this.addNewStep('task');
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-tasks']} className="mr5" />
                {' '}
                Task
              </a>
            </span>
          </div>
          { nested && (
          <div className="d-flex mb15">
            <a className="d-flex align-items-center close-step pl-3 pt-1 color-grey-dark" onClick={onClose}>
              <FontAwesomeIcon icon="far fa-times-circle" size="lg" />
            </a>
          </div>
          )}
        </div>
      </div>
    );
  }
}

CampaignStepNew.defaultProps = {
  nested:         false,
  stepIdx:        0,
  addStep:        () => false,
  onClose:        () => false,
};

CampaignStepNew.propTypes = {
  nested:     PropTypes.bool,
  stepIdx:    PropTypes.number,
  addStep:    PropTypes.func,
  onClose:    PropTypes.func,
};

export default CampaignStepNew;
