import autoBind      from 'react-autobind';
import React         from 'react';
import { PropTypes } from 'prop-types';

import Select from '~/components/forms/select';

class EngagementSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  loadOptions(input, callback) {
    const options = [];

    options.push({ value: 'email_clicks',          label: 'Email Clicks' });
    options.push({ value: 'email_opens',           label: 'Email Opens' });
    options.push({ value: 'email_unsubscribes',    label: 'Email Unsubscribes' });
    options.push({ value: 'email_bounces',         label: 'Email Bounces' });
    options.push({ value: 'email_spam_complaints', label: 'Email Spam Complaints' });
    options.push({ value: 'text_replies',          label: 'Text replies' });
    options.push({ value: 'text_unsubscribes',     label: 'Text Unsubscribes' });
    options.push({ value: 'clicks_opens_replies',  label: 'Email Opens Clicks Text Replies' });

    return options;
  }

  render() {
    return (
      <Select
        {...this.props}
        onChange={this.props.onChange}
        options={this.loadOptions()}
      />
    );
  }
}

EngagementSelect.defaultProps = {
  name:     '',
  value:    '',
  onChange: () => false,
};

EngagementSelect.propTypes = {
  name:        PropTypes.string,
  value:       PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange:    PropTypes.func,
};

export default EngagementSelect;
